import { Grid, makeStyles, MenuItem } from '@material-ui/core';

import { LeaseInventoryUnitKey, LeaseInventoryUnitKeyType } from '@rentguru/commons-utils';
import { CustomSimpleDialog, TextDetailEditable } from '@up2rent/ui';
import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { InventoryOfFixturesFormik } from 'src/components/LeaseInventory/EditInventory/EditInventory';
import FormikCustomizedSelect from 'src/components/ui/FormikCustomizedSelect';

const useStyles = makeStyles({
  root: {
    '& > *': {
      width: 280,
    },
  },
});

interface AddKeyDialogProps {
  open: boolean;
  onClose: () => void;
}

const AddKeyDialog: React.FC<AddKeyDialogProps> = ({ open, onClose }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { values: parentValues, setFieldValue: setParentFieldValue }: FormikProps<InventoryOfFixturesFormik> =
    useFormikContext();
  const initialValues: LeaseInventoryUnitKey = { keyType: LeaseInventoryUnitKeyType.APARTMENT_KEY };
  const handleAddKey = (values: LeaseInventoryUnitKey) => {
    const newKeys = parentValues.leaseInventory.keys ? [...parentValues.leaseInventory.keys, values] : [values];
    const newLeaseInventory = { ...parentValues.leaseInventory, keys: newKeys };
    setParentFieldValue('leaseInventory', newLeaseInventory);
    onClose();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleAddKey}>
      {({ values, isSubmitting, handleSubmit }) => {
        return (
          <Form>
            <CustomSimpleDialog
              open={open}
              onClose={onClose}
              onActionButtonClick={handleSubmit}
              actionButtonLabel={formatMessage({
                id: 'save',
              })}
              actionButtonLoading={isSubmitting}
              title={formatMessage({ id: 'leaseInventory.technics.addKey' })}
              dividerBelowTitle
              formatMessage={formatMessage}
            >
              <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                <FormikCustomizedSelect
                  label={formatMessage({
                    id: 'leaseInventory.technics.keyType',
                  })}
                  value={values.keyType}
                  className={classes.root}
                  fieldName="keyType"
                >
                  <MenuItem value={LeaseInventoryUnitKeyType.APARTMENT_KEY}>
                    {formatMessage({ id: 'enums.leaseInventoryKeyTypes.APARTMENT_KEY' })}
                  </MenuItem>
                  <MenuItem value={LeaseInventoryUnitKeyType.BUILDING_KEY}>
                    {formatMessage({ id: 'enums.leaseInventoryKeyTypes.BUILDING_KEY' })}
                  </MenuItem>
                  <MenuItem value={LeaseInventoryUnitKeyType.BASEMENT_KEY}>
                    {formatMessage({ id: 'enums.leaseInventoryKeyTypes.BASEMENT_KEY' })}
                  </MenuItem>
                  <MenuItem value={LeaseInventoryUnitKeyType.HOUSE_KEY}>
                    {formatMessage({ id: 'enums.leaseInventoryKeyTypes.HOUSE_KEY' })}
                  </MenuItem>
                  <MenuItem value={LeaseInventoryUnitKeyType.ROOM_KEY}>
                    {formatMessage({ id: 'enums.leaseInventoryKeyTypes.ROOM_KEY' })}
                  </MenuItem>
                  <MenuItem value={LeaseInventoryUnitKeyType.APARTMENT_BADGE}>
                    {formatMessage({ id: 'enums.leaseInventoryKeyTypes.APARTMENT_BADGE' })}
                  </MenuItem>
                  <MenuItem value={LeaseInventoryUnitKeyType.BUILDING_BADGE}>
                    {formatMessage({ id: 'enums.leaseInventoryKeyTypes.BUILDING_BADGE' })}
                  </MenuItem>
                  <MenuItem value={LeaseInventoryUnitKeyType.BASEMENT_BADGE}>
                    {formatMessage({ id: 'enums.leaseInventoryKeyTypes.BASEMENT_BADGE' })}
                  </MenuItem>
                  <MenuItem value={LeaseInventoryUnitKeyType.HOUSE_BADGE}>
                    {formatMessage({ id: 'enums.leaseInventoryKeyTypes.HOUSE_BADGE' })}
                  </MenuItem>
                  <MenuItem value={LeaseInventoryUnitKeyType.ROOM_BADGE}>
                    {formatMessage({ id: 'enums.leaseInventoryKeyTypes.ROOM_BADGE' })}
                  </MenuItem>
                </FormikCustomizedSelect>
              </Grid>
              <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <TextDetailEditable
                  name="keyId"
                  title={`${formatMessage({ id: 'leaseInventory.technics.keyId' })} ${formatMessage({
                    id: 'optional',
                  })}`}
                  type="string"
                  editMode
                />
              </Grid>
            </CustomSimpleDialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddKeyDialog;
