/* eslint-disable react/no-array-index-key */
/* eslint-disable no-promise-executor-return */
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import {
  Colors,
  CommunicationChannel,
  CommunicationRecipientType,
  CommunicationStatus,
  Contact,
  ContactType,
  EntityType,
  FileCategory,
  File as FileModel,
  getContactNameFromObject,
  getEmailsOfContacts,
  getPhoneNumbersOfContacts,
  isNilOrEmpty,
  NewFile,
  S3Object,
  TemplateSubType,
} from '@rentguru/commons-utils';
import { EditorState } from 'draft-js';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { CustomMenuItemType } from 'src/components/ui/ComboBox/TextComboBox';
import TemplateEditor from 'src/components/ui/TemplateEditor';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { getS3ObjectUrls, useFiles } from 'src/hooks/FilesContext';
import { useInvoices } from 'src/hooks/InvoicesContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { useSignatureDocuments } from 'src/hooks/SignatureDocumentContext';
import { CommunicationWithTemplate, isLetterCommunication } from 'src/utils/communicationUtils';
import CommunicationDetailsContentAddress from './CommunicationDetailsContentAddress';
import CommunicationDetailsContentHeader from './CommunicationDetailsContentHeader';
import CommunicationDetailsContentRecipient from './CommunicationDetailsContentRecipient';
import CommunicationDetailsFiles from './CommunicationDetailsFiles';

const useStyles = makeStyles({
  divider: {
    marginLeft: -30,
    marginRight: -20,
  },
  recipientsGrid: {
    padding: '0px 24px 12px 24px',
  },
  textWarning: {
    fontSize: 14,
    color: Colors.BURNING_ORANGE,
  },
});
interface CommunicationDetailContentProps {
  communication: CommunicationWithTemplate;
  recipients: (CustomMenuItemType | string)[];
  setRecipients: (recipient: (CustomMenuItemType | string)[]) => void;
  recipientsInCc: (CustomMenuItemType | string)[];
  setRecipientsInCc: (recipient: (CustomMenuItemType | string)[]) => void;
  recipientsInBcc: (CustomMenuItemType | string)[];
  setRecipientsInBcc: (recipient: (CustomMenuItemType | string)[]) => void;
  files: (S3Object | NewFile)[];
  setFiles: (value: (S3Object | NewFile)[]) => void;
  bodyEditor: EditorState;
  setBody: (body: EditorState) => void;
  titleEditor: EditorState;
  setTitle: (edit: EditorState) => void;
  editable?: boolean;
  emailError: boolean;
  phoneNumberError: boolean;
  smsLengthError: boolean;
}

const getFilteredInvoices = (invoices: FileModel[], templateSubType?: TemplateSubType) => {
  if (templateSubType === TemplateSubType.LEASE_PAYMENT_RECEIPT) {
    return invoices.filter((invoice) => invoice.category?.fileCategory === FileCategory.INVOICE_RECEIPT);
  }
  return invoices.filter((invoice) => invoice.category?.fileCategory !== FileCategory.INVOICE_RECEIPT);
};

const CommunicationDetailContent: React.FC<CommunicationDetailContentProps> = ({
  communication,
  recipients,
  setRecipients,
  recipientsInCc,
  setRecipientsInCc,
  recipientsInBcc,
  setRecipientsInBcc,
  files,
  setFiles,
  bodyEditor,
  setBody,
  titleEditor,
  setTitle,
  emailError,
  phoneNumberError,
  smsLengthError,
  editable = false,
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { getAllContactsOwnersFromLease, getLease } = useLeases();
  const { getContact } = useContacts();
  const linkedOwnersContacts = !isNil(communication.lease) ? getAllContactsOwnersFromLease(communication.lease.id) : [];
  const { loading: invoicesLoading } = useInvoices();
  const { getBuilding } = useBuildings();
  const { loading: signatureDocumentsLoading, getSignatureDocument } = useSignatureDocuments();
  const { fetchFileFromId, getFiles, loading: fileLoading } = useFiles();

  let linkedTenantsContacts: Contact[] = [];
  let linkedGuarantorsContacts: Contact[] = [];
  if (communication.lease && communication.lease.id) {
    const lease = getLease(communication.lease.id);
    linkedTenantsContacts = lease?.tenants ?? [];
    linkedGuarantorsContacts = lease?.guarantors || [];
  }

  useEffect(() => {
    const buildingInvoicesWithFiles = async () => {
      const invoiceFiles = communication.invoiceId ? getFiles(EntityType.INVOICE, [communication.invoiceId]) : [];
      const statementFiles = communication.statementId
        ? getFiles(EntityType.STATEMENT, [communication.statementId])
        : [];
      const communicationFiles = communication.id ? getFiles(EntityType.COMMUNICATION, [communication.id]) : [];
      const leasePriceHistoryFiles = communication.leasePriceHistoryId
        ? getFiles(EntityType.LEASE_PRICE_HISTORY, [communication.leasePriceHistoryId])
        : [];
      const invoices = getFilteredInvoices(invoiceFiles, communication?.template?.subType);
      const s3Objects = await getS3ObjectUrls([
        ...invoices,
        ...statementFiles,
        ...communicationFiles,
        ...leasePriceHistoryFiles,
      ]);
      setFiles(s3Objects);
    };
    if (!invoicesLoading && !fileLoading) buildingInvoicesWithFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesLoading, fileLoading]);

  const signatureDocument =
    communication.signatureDocument ?? getSignatureDocument(communication.signatureDocumentId ?? '');

  useEffect(() => {
    const buildingFiles = async () => {
      if (!communication.signatureDocumentId) {
        return;
      }

      if (!signatureDocument || !signatureDocument.fileIds) {
        return;
      }

      const filesPromises = signatureDocument.fileIds.map((fileId) => fetchFileFromId(fileId));
      const s3Objects = await Promise.all(filesPromises);
      setFiles([...files, ...s3Objects.flat()]);
    };
    if (!signatureDocumentsLoading) buildingFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureDocumentsLoading, communication.signatureDocumentId, communication.id]);

  if (isNil(communication.id)) {
    return null;
  }

  const getRecipientValue = (channel: CommunicationChannel, contact: Contact) => {
    switch (channel) {
      case CommunicationChannel.WHATSAPP:
      case CommunicationChannel.SMS:
        return getPhoneNumbersOfContacts([getContact(contact.id)!]);
      case CommunicationChannel.LETTER:
      case CommunicationChannel.REGISTERED_LETTER:
        return getContactNameFromObject(contact);
      default:
        return getEmailsOfContacts([contact]);
    }
  };

  const getCustomMenuItemForContactsWithType = (
    contacts: Contact[],
    type: ContactType,
    channel: CommunicationChannel
  ) =>
    contacts.reduce((acc: CustomMenuItemType[], contact: Contact) => {
      const recipientValue = getRecipientValue(channel, contact);
      if (!isNilOrEmpty(recipientValue)) {
        const itemToPush: CustomMenuItemType = {
          primary: getContactNameFromObject(contact),
          secondary: recipientValue,
          value: recipientValue,
          group: formatMessage({ id: `contact.type.${type.toLowerCase()}` }),
        };
        if (!acc.find((item) => item.value === itemToPush.value)) {
          acc.push(itemToPush);
        }
      }
      return acc;
    }, []);

  const autoCompleteOptions: CustomMenuItemType[] = [
    ...getCustomMenuItemForContactsWithType(linkedOwnersContacts, ContactType.OWNER, communication.channel),
    ...getCustomMenuItemForContactsWithType(linkedTenantsContacts, ContactType.TENANT, communication.channel),
    ...getCustomMenuItemForContactsWithType(linkedGuarantorsContacts, ContactType.GUARANTOR, communication.channel),
  ];

  const toSend = communication.status === CommunicationStatus.TO_SEND;
  const dateLabelId = `communications.list.${toSend ? 'startDate' : 'sentDate'}`;
  const dateToUse = toSend ? communication.createdAt : communication.sentAt;
  const isLetter = isLetterCommunication(communication.channel as CommunicationChannel);
  const building = communication.buildingId ? getBuilding(communication.buildingId) : undefined;

  return (
    <>
      <CommunicationDetailsContentHeader
        date={dateToUse}
        dateLabelId={dateLabelId}
        signatureDocument={signatureDocument}
        channel={communication.channel}
        gridItemsStyle={{ paddingTop: 12, paddingLeft: 24 }}
        lease={communication.lease}
        building={building}
        status={communication.status as CommunicationStatus}
        blockedRecipients={communication.blockedRecipients ?? []}
        linkedCommunicationsIds={communication.linkedCommunicationsIds ?? []}
      />
      <Divider className={classes.divider} style={{ marginBottom: 12, marginTop: 12 }} />
      {isLetter && (
        <>
          <Grid className={classes.recipientsGrid}>
            <CommunicationDetailsContentAddress communicationId={communication.id} editable={editable} />
          </Grid>
          <Divider className={classes.divider} style={{ marginBottom: 12 }} />
        </>
      )}
      <Grid className={classes.recipientsGrid}>
        <CommunicationDetailsContentRecipient
          recipients={recipients}
          setRecipients={setRecipients}
          editable={editable}
          autoCompleteOptions={autoCompleteOptions}
          recipientType={CommunicationRecipientType.TO}
          communicationChannel={communication.channel}
        />
        {communication.channel === CommunicationChannel.EMAIL && (
          <Grid>
            <Grid style={{ marginBottom: 20 }} />
            <CommunicationDetailsContentRecipient
              recipients={recipientsInCc}
              setRecipients={setRecipientsInCc}
              editable={editable}
              autoCompleteOptions={autoCompleteOptions}
              recipientType={CommunicationRecipientType.CC}
              communicationChannel={communication.channel}
            />
            <Grid style={{ marginBottom: 20 }} />
            <CommunicationDetailsContentRecipient
              recipients={recipientsInBcc}
              setRecipients={setRecipientsInBcc}
              editable={editable}
              autoCompleteOptions={autoCompleteOptions}
              recipientType={CommunicationRecipientType.BCC}
              communicationChannel={communication.channel}
            />
            {emailError && (
              <Typography className={classes.textWarning}>
                {formatMessage({ id: 'communications.detail.emailWarning' })}
              </Typography>
            )}
            {phoneNumberError && (
              <Typography className={classes.textWarning}>
                {formatMessage({ id: 'communications.detail.phoneWarning' })}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
      <Divider className={classes.divider} />

      <TemplateEditor
        title={!isLetter ? titleEditor : undefined}
        setTitle={!isLetter ? setTitle : undefined}
        body={bodyEditor}
        setBody={setBody}
        editable={editable}
        editMode={editable}
        dividerStyle={{ marginLeft: -30, marginRight: -20 }}
        editorPadding={'0 0 12px 24px'}
        hideTextStyle={isLetter}
      />
      {smsLengthError && (
        <Typography className={classes.textWarning} style={{ padding: '12px 24px' }}>
          {formatMessage({ id: 'communications.detail.smsLengthWarning' })}
        </Typography>
      )}
      {(!isEmpty(files) || (editable && communication.channel !== CommunicationChannel.SMS)) && (
        <Grid>
          <Divider style={{ marginLeft: -30, marginRight: -20, marginBottom: 10 }} />
          <CommunicationDetailsFiles
            files={files}
            setFiles={setFiles}
            canAddFile={editable && communication.channel !== CommunicationChannel.SMS}
            communicationChannel={communication.channel}
          />
        </Grid>
      )}
    </>
  );
};

export default CommunicationDetailContent;
