import { useMediaQuery } from '@material-ui/core';
import {
  DocumentType,
  LeaseExtended,
  LeaseStatus,
  S3Object,
  SignatureDocumentStatus,
  isNilOrEmpty,
} from '@rentguru/commons-utils';
import { MOBILE_MAX_WIDTH } from '@up2rent/ui';
import { isNil } from 'lodash';
import React from 'react';
import { LeaseAction } from 'src/components/Leases/Details/ActionsMenu/useLeaseActionsMenuUtils';
import TicketDetailTenant from 'src/components/TenantView/Tickets/TicketDetailTenant';
import Dialog from 'src/components/ui/Dialog';
import { useUser } from 'src/hooks/UserContext';
import { useTenants } from 'src/tenantHooks/TenantContext';
import PreviewFileDialogs from '../../ui/PreviewFileDialog';
import RejectDocumentForm from './RejectDocumentForm';

interface LeaseActionMenuDialogsProps {
  lease: LeaseExtended;
  type: LeaseAction | null;
  onCloseDialog: () => void;
  leaseContract: S3Object | null | undefined;
}

export interface RejectDocumentBundle {
  type: DocumentType;
  documentId: string;
}

const TenantLeaseActionMenuDialogs: React.FC<LeaseActionMenuDialogsProps> = ({
  lease,
  type,
  onCloseDialog,
  leaseContract,
}) => {
  const { tenantId } = useUser();
  const currentLeaseContact = lease.contacts!.find((contact) => contact.contact!.id === tenantId);
  const { tenantSignatureDocuments } = useTenants();
  const selectCurrentLeaseSignatureDocumentIfLeaseNeedsToBeSigned = tenantSignatureDocuments.find(
    (tenantSignatureDocument) =>
      tenantSignatureDocument.foreignTableName === 'Lease' &&
      tenantSignatureDocument.foreignKey === lease.id &&
      tenantSignatureDocument.status === SignatureDocumentStatus.PENDING
  );
  const isMobileLayout = useMediaQuery(MOBILE_MAX_WIDTH);

  return (
    <>
      {leaseContract && type === LeaseAction.VIEW_PDF && (
        <PreviewFileDialogs
          open={type === LeaseAction.VIEW_PDF}
          file={leaseContract}
          onClose={onCloseDialog}
          withSignatures={lease.status === LeaseStatus.Active}
        />
      )}
      {type === LeaseAction.ADD_TICKET && (
        <Dialog
          open
          onClose={onCloseDialog}
          PaperProps={{
            style: isMobileLayout ? { width: 'auto' } : { minWidth: 620, width: 620, maxWidth: 620 },
          }}
        >
          <TicketDetailTenant leaseId={lease.id} onCloseDialog={onCloseDialog} />
        </Dialog>
      )}
      {type === LeaseAction.REJECT_DOCUMENT_SIGNATURE &&
        !isNil(currentLeaseContact) &&
        !isNilOrEmpty(selectCurrentLeaseSignatureDocumentIfLeaseNeedsToBeSigned?.id) && (
          <Dialog
            open={type === LeaseAction.REJECT_DOCUMENT_SIGNATURE && !isNil(currentLeaseContact)}
            onClose={onCloseDialog}
            scroll="body"
            disableBackdropClick
            disableEscapeKeyDown
          >
            <RejectDocumentForm
              onClose={onCloseDialog}
              contactId={currentLeaseContact.contact!.id}
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              documentId={selectCurrentLeaseSignatureDocumentIfLeaseNeedsToBeSigned?.id!}
              type={DocumentType.Lease}
            />
          </Dialog>
        )}
    </>
  );
};

export default TenantLeaseActionMenuDialogs;
