import { Collapse, FormControlLabel, Grid, InputAdornment, MenuItem, RadioGroup, Typography } from '@material-ui/core';
import {
  BaseIndexType,
  BaseYear,
  Colors,
  INDEX_TYPE_BASE_YEARS,
  IndexType,
  LeaseMonthlyChargesType,
  LeaseType,
  UnitType,
  getSettingValueForAutomaticIndexation,
  getUTCDateWithoutTime,
  shouldShowBaseIndexDateWarning,
} from '@rentguru/commons-utils';
import { CustomizedSwitch, TextDetailEditable } from '@up2rent/ui';
import { isBefore, isToday } from 'date-fns';
import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { AddLeaseFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import { resolveMainUnitAndSubUnitIndexAndPrefix } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import FormAlertBox from 'src/components/ui//FormAlertBox';
import TitledSwitch from 'src/components/ui//TitledSwitch';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import CustomDatePicker from 'src/components/ui/CustomDatePicker';
import FormikCustomizedSelect from 'src/components/ui/FormikCustomizedSelect';
import StyledRadio from 'src/components/ui/StyledRadio';
import { useSettings } from 'src/hooks/SettingsContext';
import { getTranslatedEnumFormatMessage } from 'src/utils/enumTypes';

export interface RentIndexationFormValues {
  initialRentalPrice: number;
  initialChargePrice: number;
  rentalIndexation: boolean;
  chargeIndexation: boolean;
  advancedIndexation: boolean;
  indexType?: IndexType | null;
  baseYear?: BaseYear | null;
  baseIndexDate?: string | null;
  baseIndexType?: BaseIndexType | null;
}

interface RentIndexationProps {
  unitId: string;
}

const RentIndexation: React.FC<RentIndexationProps> = ({ unitId }) => {
  const { values, setFieldValue, handleChange, handleBlur, errors, touched } = useFormikContext<AddLeaseFormValues>();
  const { formatMessage } = useIntl();
  const { settings } = useSettings();
  const settingIndexation = getSettingValueForAutomaticIndexation(settings);
  const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);

  useEffect(() => {
    if (get(values, `${prefixFieldName}advancedIndexation`)) {
      const signatureDate = get(values, `${prefixFieldName}signatureDate`);
      const baseIndexType = get(values, `${prefixFieldName}baseIndexType`);
      const baseIndexDate = get(values, `${prefixFieldName}baseIndexDate`);
      if (!baseIndexType) {
        setFieldValue(`${prefixFieldName}baseIndexType`, BaseIndexType.MONTH_BEFORE_SIGNATURE_DATE);
      }
      if (!baseIndexDate) {
        const oneMonthBefore = getUTCDateWithoutTime(signatureDate);
        if (oneMonthBefore) {
          oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);
          oneMonthBefore.setDate(1);
          setFieldValue(`${prefixFieldName}baseIndexDate`, oneMonthBefore);
        }
      }
    } else {
      if (get(values, `${prefixFieldName}baseIndexType`)) {
        setFieldValue(`${prefixFieldName}baseIndexType`, null);
      }
      if (get(values, `${prefixFieldName}baseIndexDate`)) {
        setFieldValue(`${prefixFieldName}baseIndexDate`, null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get(values, `${prefixFieldName}advancedIndexation`)]);

  const getBaseYearsForIndexType = (indexType: string): BaseYear[] =>
    INDEX_TYPE_BASE_YEARS[indexType as keyof typeof INDEX_TYPE_BASE_YEARS] ?? [];

  const handleIndexTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newIndexType = event.target.value as string;
    setFieldValue(`${prefixFieldName}indexType`, newIndexType);
    const validBaseYears = getBaseYearsForIndexType(newIndexType);
    if (!isEmpty(validBaseYears)) {
      setFieldValue(`${prefixFieldName}baseYear`, validBaseYears[validBaseYears.length - 1]);
    }
  };

  function handleBaseIndexTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
    handleChange(e);
    const selectedValue = e.target.value;
    const signatureDate = get(values, `${prefixFieldName}signatureDate`);
    const leaseStartDate = get(values, `${prefixFieldName}startDate`);
    const oneMonthBefore =
      selectedValue === BaseIndexType.MONTH_BEFORE_SIGNATURE_DATE && signatureDate
        ? getUTCDateWithoutTime(signatureDate)
        : selectedValue === BaseIndexType.MONTH_BEFORE_START_DATE && leaseStartDate
        ? getUTCDateWithoutTime(leaseStartDate)
        : undefined;
    if (oneMonthBefore) {
      oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);
      oneMonthBefore.setDate(1);
      setFieldValue(`${prefixFieldName}baseIndexDate`, oneMonthBefore);
    }
  }

  const handleBaseYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newBaseYear = event.target.value as number;
    setFieldValue(`${prefixFieldName}baseYear`, newBaseYear);
  };

  const getValidBaseYears = () => {
    const indexType = get(values, `${prefixFieldName}indexType`);
    if (!indexType) return [];
    return getBaseYearsForIndexType(indexType) || [];
  };
  const leaseType = get(values, 'type');
  const unitType = get(values, `${prefixFieldName}unit.type`);
  const shouldShowAdvancedIndexation =
    [UnitType.WAREHOUSE, UnitType.OFFICE, UnitType.COMMERCIAL].includes(unitType!) &&
    [LeaseType.WAREHOUSE, LeaseType.OFFICE, LeaseType.COMMERCIAL].includes(leaseType! as LeaseType);

  const shouldShowWarningForBaseIndexDate = get(values, `${prefixFieldName}advancedIndexation`)
    ? shouldShowBaseIndexDateWarning(
        get(values, `${prefixFieldName}baseIndexType`) ?? BaseIndexType.MONTH_BEFORE_SIGNATURE_DATE,
        getUTCDateWithoutTime(get(values, `${prefixFieldName}startDate`)!),
        getUTCDateWithoutTime(get(values, `${prefixFieldName}baseIndexDate`)!)
      )
    : false;

  return (
    <>
      <Grid container style={{ margin: '10px 10px 0px 30px', maxWidth: 580 }}>
        <TitledSwitch
          titleMessageId="lease.addLease.rentIndexation"
          fieldName={`${prefixFieldName}indexation`}
          switchOnTextId="lease.addLease.includeInLease"
          switchOffTextId="lease.addLease.ignore"
        />
      </Grid>
      <Collapse in={!settingIndexation && get(values, `${prefixFieldName}indexation`)}>
        <FormAlertBox
          messagesGridStyle={{ marginRight: 30 }}
          message1={formatMessage({ id: 'lease.addLease.inactiveIndexationInSettings' })}
        />
      </Collapse>
      <Collapse in={get(values, `${prefixFieldName}indexation`)} style={{ marginTop: 10 }}>
        <Grid style={{ textAlign: 'left', marginLeft: 30 }}>
          <Grid style={{ width: 580 }}>
            <FormControlLabel
              style={{ width: '50%' }}
              control={
                <CustomCheckBox
                  onCheck={(event) => {
                    setFieldValue(`${prefixFieldName}rentalIndexation`, event.target.checked);
                  }}
                  isChecked={get(values, `${prefixFieldName}rentalIndexation`)}
                />
              }
              label={
                <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                  {formatMessage({ id: 'lease.addLease.rentalIndexation' })}
                </Typography>
              }
            />
            <Collapse
              in={
                get(values, `${prefixFieldName}rentalIndexation`) &&
                isBefore(
                  new Date(!values.allDatesAreSame ? get(values, `${prefixFieldName}startDate`)! : values.startDate),
                  new Date()
                ) &&
                !isToday(
                  new Date(!values.allDatesAreSame ? get(values, `${prefixFieldName}startDate`)! : values.startDate)
                )
              }
            >
              <Grid container style={{ margin: '0px 10px 0px 0px', maxWidth: 580 }}>
                <Grid item xs={6} style={{ textAlign: 'left' }}>
                  <TextDetailEditable
                    editMode
                    title={formatMessage({
                      id: `lease.indexation.${values.applyVat ? 'initialRentalPriceNoVAT' : 'initialRentalPrice'}`,
                    })}
                    name={`${prefixFieldName}initialRentalPrice`}
                    type="number"
                    style={{ width: 280 }}
                    min={0}
                    endAdornment={<InputAdornment position="end">EUR</InputAdornment>}
                    typeNumberNoEndArrow
                    error={Boolean(get(errors, `${prefixFieldName}initialRentalPrice`))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextDetailEditable
                    editMode
                    title={formatMessage({
                      id: `lease.addLease.${values.applyVat ? 'rentalPriceNoVat' : 'rentalPrice'}`,
                    })}
                    name={`${prefixFieldName}rentalPrice`}
                    type="number"
                    style={{ width: 280 }}
                    min={0}
                    endAdornment={<InputAdornment position="end">EUR</InputAdornment>}
                    typeNumberNoEndArrow
                    error={Boolean(
                      get(errors, `${prefixFieldName}rentalPrice`) && get(touched, `${prefixFieldName}rentalPrice`)
                    )}
                    disabled
                  />
                </Grid>
              </Grid>
            </Collapse>
            <Collapse
              in={
                get(values, `${prefixFieldName}includeMonthlyCharge`) &&
                get(values, `${prefixFieldName}monthlyChargesFields.monthlyChargesType`) ===
                  LeaseMonthlyChargesType.FixedPrice
              }
            >
              <FormControlLabel
                control={
                  <CustomCheckBox
                    onCheck={(event) => {
                      setFieldValue(`${prefixFieldName}chargeIndexation`, event.target.checked);
                    }}
                    isChecked={get(values, `${prefixFieldName}chargeIndexation`)}
                  />
                }
                label={
                  <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                    {formatMessage({ id: 'lease.addLease.chargeIndexation' })}
                  </Typography>
                }
              />
              <Collapse
                in={
                  get(values, `${prefixFieldName}chargeIndexation`) &&
                  isBefore(
                    new Date(!values.allDatesAreSame ? get(values, `${prefixFieldName}startDate`)! : values.startDate),
                    new Date()
                  ) &&
                  !isToday(
                    new Date(!values.allDatesAreSame ? get(values, `${prefixFieldName}startDate`)! : values.startDate)
                  )
                }
              >
                <Grid container style={{ margin: '0px 10px 0px 0px', maxWidth: 580 }}>
                  <Grid item xs={6} style={{ textAlign: 'left' }}>
                    <TextDetailEditable
                      editMode
                      title={formatMessage({
                        id: `lease.indexation.${values.applyVat ? 'initialChargePriceNoVAT' : 'initialChargePrice'}`,
                      })}
                      name={`${prefixFieldName}initialChargePrice`}
                      type="number"
                      style={{ width: 280 }}
                      min={0}
                      endAdornment={<InputAdornment position="end">EUR</InputAdornment>}
                      typeNumberNoEndArrow
                      error={Boolean(get(errors, `${prefixFieldName}initialChargePrice`))}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextDetailEditable
                      editMode
                      title={formatMessage({
                        id: `lease.addLease.${values.applyVat ? 'rentalChargeNoVat' : 'chargePrice'}`,
                      })}
                      name={`${prefixFieldName}monthlyChargesFields.monthlyCharges`}
                      type="number"
                      style={{ width: 280 }}
                      min={0}
                      endAdornment={<InputAdornment position="end">EUR</InputAdornment>}
                      typeNumberNoEndArrow
                      error={Boolean(
                        get(errors, `${prefixFieldName}monthlyChargesFields.monthlyCharges`) &&
                          get(touched, `${prefixFieldName}monthlyChargesFields.monthlyCharges`)
                      )}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Collapse>
          </Grid>
        </Grid>
        {shouldShowAdvancedIndexation && (
          <>
            <Grid container style={{ margin: '10px 10px 0px 30px', maxWidth: 580 }}>
              <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                {formatMessage({ id: 'lease.addLease.advancedIndexation' })}
              </Typography>
              <CustomizedSwitch
                checked={get(values, `${prefixFieldName}advancedIndexation`)}
                onChange={handleChange}
                onBlur={handleBlur}
                aria-label="Collapse"
                color="primary"
                disableRipple
                id="advancedIndexation"
                name="advancedIndexation"
              />
            </Grid>
            <Collapse in={get(values, `${prefixFieldName}advancedIndexation`)}>
              <Grid
                container
                style={{
                  margin: '10px 10px 0px 30px',
                  maxWidth: 580,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Grid item xs={9} style={{ textAlign: 'left' }}>
                  <FormikCustomizedSelect
                    value={get(values, `${prefixFieldName}indexType`)}
                    SelectProps={{
                      onChange: handleIndexTypeChange,
                      onBlur: handleBlur,
                    }}
                    FormControlProps={{
                      required: get(values, `${prefixFieldName}advancedIndexation`),
                      style: { width: 390 },
                    }}
                    label={formatMessage({ id: 'lease.addLease.indexType' })}
                    error={Boolean(get(errors, `${prefixFieldName}indexType`))}
                  >
                    {getTranslatedEnumFormatMessage(formatMessage, 'IndexType').map((menuItem) => (
                      <MenuItem value={menuItem.value} key={menuItem.value}>
                        {menuItem.label}
                      </MenuItem>
                    ))}
                  </FormikCustomizedSelect>
                </Grid>
                <Grid item xs={3}>
                  <FormikCustomizedSelect
                    value={get(values, `${prefixFieldName}baseYear`)}
                    SelectProps={{
                      onChange: handleBaseYearChange,
                      onBlur: handleBlur,
                    }}
                    FormControlProps={{
                      required: get(values, `${prefixFieldName}advancedIndexation`),
                      style: { width: 130 },
                    }}
                    label={formatMessage({ id: 'lease.addLease.baseYear' })}
                    error={Boolean(get(errors, `${prefixFieldName}baseYear`))}
                  >
                    {getValidBaseYears().map((menuItem) => (
                      <MenuItem value={menuItem} key={menuItem}>
                        {menuItem}
                      </MenuItem>
                    ))}
                  </FormikCustomizedSelect>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  margin: '10px 10px 0px 30px',
                  maxWidth: 580,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Grid item xs={9} style={{ textAlign: 'left' }}>
                  <Typography variant="subtitle2" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    {formatMessage({ id: 'lease.addLease.baseIndexDate' })}
                  </Typography>
                  <RadioGroup
                    name={`${prefixFieldName}baseIndexType`}
                    value={get(values, `${prefixFieldName}baseIndexType`)}
                    defaultValue={BaseIndexType.MONTH_BEFORE_SIGNATURE_DATE}
                    onChange={handleBaseIndexTypeChange}
                    onBlur={handleBlur}
                  >
                    <FormControlLabel
                      value={BaseIndexType.MONTH_BEFORE_SIGNATURE_DATE}
                      control={<StyledRadio />}
                      label={formatMessage({ id: 'enums.BaseIndexType.MONTH_BEFORE_SIGNATURE_DATE' })}
                      style={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                      value={BaseIndexType.MONTH_BEFORE_START_DATE}
                      control={<StyledRadio />}
                      label={formatMessage({ id: 'enums.BaseIndexType.MONTH_BEFORE_START_DATE' })}
                      style={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                      value={BaseIndexType.CUSTOM_DATE}
                      control={<StyledRadio />}
                      label={formatMessage({ id: 'enums.BaseIndexType.CUSTOM_DATE' })}
                      style={{ marginLeft: 0 }}
                    />
                  </RadioGroup>

                  <Collapse in={get(values, `${prefixFieldName}baseIndexType`) === BaseIndexType.CUSTOM_DATE}>
                    <Grid container style={{ marginTop: '10px' }}>
                      <CustomDatePicker
                        name={`${prefixFieldName}baseIndexDate`}
                        label={formatMessage({ id: 'lease.addLease.baseIndexDate' })}
                        value={get(values, `${prefixFieldName}baseIndexDate`, null)}
                        onChange={(date) => {
                          if (date) {
                            const selectedMonth = getUTCDateWithoutTime(date);
                            selectedMonth.setDate(1);
                            setFieldValue(`${prefixFieldName}baseIndexDate`, selectedMonth);
                          }
                        }}
                        format="MM/yyyy"
                        views={['year', 'month']}
                        style={{ width: '200px' }}
                      />
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
              <Collapse in={shouldShowWarningForBaseIndexDate && get(values, `${prefixFieldName}advancedIndexation`)}>
                <Grid container style={{ marginTop: '10px' }}>
                  <FormAlertBox
                    message1={formatMessage({ id: 'lease.addLease.noIndexationUntilbaseIndexDateReached' })}
                    gridStyle={{ backgroundColor: Colors.YELLOW_WARNING }}
                  />
                </Grid>
              </Collapse>
            </Collapse>
          </>
        )}
      </Collapse>
    </>
  );
};

export default RentIndexation;
