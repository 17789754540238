import { Chip, Collapse, Grid, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Colors, INTEGRATION_CONFIG_OCR_AUTOMATIC_PROCESS, IntegrationName } from '@rentguru/commons-utils';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';

import { ActionButton, CustomizedSwitch, LoaderButton } from '@up2rent/ui';
import { Form, Formik, FormikHelpers } from 'formik';
import Dialog from 'src/components/ui/Dialog';
import { useIntegrations } from 'src/hooks/IntegrationsContext';
import { IntegrationStatus } from 'src/models';
import IntegrationLogo from './IntegrationLogo';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: Colors.PORCELAIN_GREY_1,
    padding: 15,
    paddingLeft: 30,
  },
  paragraph: {
    fontSize: '14px',
    lineHeight: '20px',
    color: Colors.CHARCOAL_GREY,
    textAlign: 'left',
  },
  configuration: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'bold',
    color: Colors.CHARCOAL_GREY,
    textAlign: 'left',
  },
  integrationName: {
    fontFamily: 'Mulish',
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: Colors.CLASSICAL_BLACK,
  },
  errorText: {
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: Colors.SLATE_GREY,
    textAlign: 'left',
  },
});

interface OcrValues {
  enabled: boolean;
  automaticProcess: boolean;
}

interface OcrIntegrationDialogProps {
  open: boolean;
  onClose: () => void;
  id?: string | null;
}

const OcrIntegrationDialog: React.FC<OcrIntegrationDialogProps> = ({ open, onClose, id }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, error } = useIntegrations();
  const { getIntegration, createIntegration, updateIntegration } = useIntegrations();

  const integration = getIntegration(id ?? '');
  if (loading) {
    return <Typography>{formatMessage({ id: 'loading' })}</Typography>;
  }
  if (error) {
    return <Typography>{error}</Typography>;
  }
  const onSubmit = async (values: OcrValues, { setSubmitting, setStatus }: FormikHelpers<OcrValues>) => {
    setStatus(true);
    setSubmitting(false);
    const status = values.enabled ? IntegrationStatus.ACTIVE : IntegrationStatus.INACTIVE;
    const configurations = [
      { name: INTEGRATION_CONFIG_OCR_AUTOMATIC_PROCESS, value: values.automaticProcess.toString() },
    ];
    if (!integration) {
      await createIntegration({
        name: IntegrationName.OCR,
        status,
        configurations,
      });
    } else {
      await updateIntegration({ ...integration, status, configurations });
    }
    enqueueSnackbar(formatMessage({ id: 'settings.changesSaved' }), { variant: 'success' });
    onClose();
  };

  const initialValues: OcrValues = {
    automaticProcess:
      integration?.configurations?.find((config) => config.name === INTEGRATION_CONFIG_OCR_AUTOMATIC_PROCESS)?.value ===
      'true',
    enabled: integration?.status === IntegrationStatus.ACTIVE,
  };

  const integrationName = formatMessage({ id: `settings.integrations.IntegrationName.${IntegrationName.OCR}` });

  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <div className={classes.root}>
        <IntegrationLogo name={IntegrationName.OCR} height={30} />
      </div>
      <Grid
        container
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingRight: 30,
          paddingLeft: 30,
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <Grid item>
          <Typography className={classes.integrationName}>{integrationName}</Typography>
        </Grid>
        <Grid item style={{ marginLeft: 10, display: 'flex', justifyContent: 'flex-end' }}>
          <Chip
            label={formatMessage({ id: 'settings.credits.name' })}
            style={{
              color: Colors.LIGHT_BLUE_GREY,
              fontFamily: 'Mulish',
              fontSize: 14,
              lineHeight: 18,
              fontWeight: 600,
            }}
          />
        </Grid>
      </Grid>
      <Grid style={{ paddingLeft: 30, paddingRight: 30 }}>
        <Typography paragraph classes={{ root: classes.paragraph }}>
          {formatMessage({ id: `settings.integrations.description.${IntegrationName.OCR}` })}
        </Typography>
      </Grid>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {({ isSubmitting, status, values, setFieldValue }) => (
          <Form>
            <Grid style={{ marginLeft: 30, marginRight: 30 }}>
              <Grid style={{ display: 'flex', alignItems: 'center' }}>
                <CustomizedSwitch
                  checked={values.enabled}
                  onChange={() => setFieldValue('enabled', !values.enabled)}
                  aria-label="Collapse"
                  color="primary"
                  disableRipple
                  switchOnText={formatMessage({ id: 'enable' })}
                  switchOffText={formatMessage({ id: 'disable' })}
                />
                <Typography style={{ paddingLeft: 8 }} classes={{ root: classes.configuration }}>
                  {formatMessage({ id: 'settings.integrations.ocr.enable' })}
                </Typography>
              </Grid>
              <Collapse in={values.enabled}>
                <Grid style={{ display: 'flex', alignItems: 'center' }}>
                  <CustomizedSwitch
                    checked={values.automaticProcess}
                    onChange={() => setFieldValue('automaticProcess', !values.automaticProcess)}
                    aria-label="Collapse"
                    color="primary"
                    disableRipple
                    switchOnText={formatMessage({ id: 'enable' })}
                    switchOffText={formatMessage({ id: 'disable' })}
                  />
                  <Typography style={{ paddingLeft: 8 }} classes={{ root: classes.configuration }}>
                    {formatMessage({ id: 'settings.integrations.ocr.enableAutomaticProcessing' })}
                  </Typography>
                  <Tooltip
                    title={formatMessage({ id: 'settings.integrations.ocr.automaticProcessingDescription' })}
                    placement="bottom"
                  >
                    <Grid>
                      <InfoSvg style={{ marginLeft: 10, fill: Colors.SILVER }} />
                    </Grid>
                  </Tooltip>
                </Grid>
              </Collapse>
            </Grid>
            <Grid
              style={{
                paddingRight: 30,
                paddingLeft: 30,
                marginBottom: 20,
                marginTop: 20,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ActionButton
                onClick={onClose}
                style={{
                  background: 'none',
                  color: Colors.DARK_SLATE_GREY,
                  marginRight: 20,
                  textTransform: 'uppercase',
                }}
              >
                {formatMessage({ id: 'cancel' })}
              </ActionButton>
              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({
                  id: 'save',
                })}
              </LoaderButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default OcrIntegrationDialog;
