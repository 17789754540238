import { Grid, Typography } from '@material-ui/core';
import { isSubscriptionMandateInProblem } from '@rentguru/commons-utils';
import { ElevatedPaper, LoaderButton } from '@up2rent/ui';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ACCOUNT_SEPA_CONFIG_CACHE } from 'src/App';
import { useClient } from 'src/hooks/ClientContext';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { removeCacheItem } from 'src/utils/cacheUtils';
import { RouteDestination } from '../Routes/Routes';
import IconnedRemark from '../ui/IconnedRemark';
import { authStyles } from './authStyles';

const ConfigureMandate = () => {
  const { formatMessage } = useIntl();
  const styles = authStyles();
  const { applicationSubscription, initiatePayment } = useClient();
  const [mandateLoading, setMandateLoading] = useState<boolean>(false);
  const [hostedUiUrl, setHostedUiUrl] = useState<string | undefined | null>(null);

  const currentPath = window.location.pathname;
  const isRedirectedFromMandateProvider = currentPath === RouteDestination.PAYMENT_MANDATE_CALLBACK;

  useEffect(() => {
    if (!mandateLoading && isRedirectedFromMandateProvider) {
      setMandateLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRedirectedFromMandateProvider]);

  const hasSubscriptionProblem = isSubscriptionMandateInProblem(applicationSubscription);

  useEffect(() => {
    const initiateMandate = async () => {
      setMandateLoading(true);
      const response = await initiatePayment();
      setHostedUiUrl(response?.redirectUrl);
      setMandateLoading(false);
    };

    if (!isRedirectedFromMandateProvider) {
      initiateMandate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRedirectedFromMandateProvider]);

  useEffect(() => {
    const redirectAfterMandateConfiguration = async () => {
      // SEPA is done, we can now go to dashboard
      await removeCacheItem(ACCOUNT_SEPA_CONFIG_CACHE);
      window.location.href = '/';
    };

    if (isRedirectedFromMandateProvider && !hasSubscriptionProblem) {
      redirectAfterMandateConfiguration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRedirectedFromMandateProvider, hasSubscriptionProblem]);

  return (
    <Grid alignContent="center" alignItems="center" container direction="column">
      <ElevatedPaper className={styles.createAccountContainer}>
        <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <LoaderButton
            href={hostedUiUrl ?? ''}
            loading={mandateLoading}
            style={{ textTransform: 'none', width: 280, paddingTop: 20 }}
          >
            {formatMessage({ id: 'settings.subscription.configureMandate' })}
          </LoaderButton>
          <IconnedRemark
            Icon={InfoSvg}
            style={{ marginLeft: 0, marginTop: 20 }}
            message={
              <Grid>
                <Typography>{formatMessage({ id: 'settings.subscription.mandateInfo' })}</Typography>
                <Typography>{formatMessage({ id: 'settings.subscription.priceInfo' })}</Typography>
              </Grid>
            }
          />
        </Grid>
      </ElevatedPaper>
    </Grid>
  );
};

export default ConfigureMandate;
