import { Grid, Tooltip, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import {
  Colors,
  DEFAULT_LANGUAGE,
  formatStandardizedUTCDate,
  getLocaleFromLanguage,
  getOverlappingEvents,
  getUnitEvents,
  LeaseExtended,
  Unit,
  UnitType,
} from '@rentguru/commons-utils';
import { TextDetail, UnitTypeIcon } from '@up2rent/ui';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import { CustomCheckBoxGroupContentProps } from 'src/components/ui/CustomCheckBoxGroup';
import { UnitContext } from 'src/hooks/UnitsContext';
import { useUser } from 'src/hooks/UserContext';
import { ReactComponent as ArrowRight } from 'src/icons/arrow-right.svg';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { getDetailedOverlappingError } from 'src/utils/unitsUtils';
import { ExtendLeaseFormValues } from './ExtendLeaseForm';

export interface ExtendUnitCheckBoxItemProps extends CustomCheckBoxGroupContentProps<Unit> {
  lease: LeaseExtended;
  getUnit: UnitContext['getUnit'];
}

const ExtendUnitCheckBoxItem: React.FC<ExtendUnitCheckBoxItemProps> = ({
  isWarning,
  isChecked,
  isDisabled,
  onCheck,
  item,
  lease,
  getUnit,
}) => {
  const { values } = useFormikContext<ExtendLeaseFormValues>();
  const { formatMessage } = useIntl();
  const { language } = useUser();
  const locale = getLocaleFromLanguage(language ?? DEFAULT_LANGUAGE);

  const newLeaseEndDate = values.endDate;
  const unitLease = lease.units?.find((currentUnitLease) => currentUnitLease.unit?.id === item.id);
  const differentDates = unitLease?.startDate !== lease.startDate || unitLease?.endDate !== lease.endDate;
  const startDate = formatStandardizedUTCDate(new Date(unitLease?.startDate ?? ''), 'dd/MM/yyyy', {
    locale,
  });
  const endDate = formatStandardizedUTCDate(new Date(unitLease?.endDate ?? ''), 'dd/MM/yyyy', {
    locale,
  });

  const events = getOverlappingEvents(
    new Date(unitLease!.endDate).toISOString(),
    newLeaseEndDate.toISOString(),
    getUnitEvents(item.id, getUnit)
  );

  const overlappingError =
    !isEmpty(events) && events[0] ? getDetailedOverlappingError(events[0], formatMessage, locale) : '';

  return (
    <>
      <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <CustomCheckBox
          checkedColor={isWarning ? Colors.BURNING_ORANGE : Colors.DODGER_BLUE}
          isChecked={isChecked}
          disabled={isDisabled}
          onCheck={() => onCheck(item)}
        />
        <Grid style={{ marginLeft: 10, marginRight: 10 }}>
          <UnitTypeIcon unitType={item.type as UnitType} />
        </Grid>
        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography style={{ fontSize: 12, fontWeight: 500, ...(isWarning && { color: Colors.BURNING_ORANGE }) }}>
            {item.name}
          </Typography>
          {unitLease?.mainUnit && (
            <Typography style={{ fontSize: 12, fontWeight: 300, color: Colors.SLATE_GREY }}>
              ({formatMessage({ id: 'lease.addLease.mainUnit' })})
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {!isEmpty(overlappingError) && (
          <Tooltip title={overlappingError} placement="top">
            <Grid>
              <InfoSvg fill={Colors.CARNATION_RED} />
            </Grid>
          </Tooltip>
        )}
      </Grid>
      <Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        {differentDates && (
          <TextDetail
            title={formatMessage({ id: 'lease.endExtendLease.differentDates' })}
            icon={<Warning style={{ width: 20, height: 20, color: Colors.LIGHT_BLUE_GREY }} />}
          >
            <Grid style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={{ textAlign: 'left', color: Colors.DARK, marginRight: 10 }}>{startDate}</Typography>
              <ArrowRight fill={Colors.DARK} />
              <Typography style={{ textAlign: 'left', color: Colors.DARK, marginLeft: 10 }}>{endDate}</Typography>
            </Grid>
          </TextDetail>
        )}
      </Grid>
    </>
  );
};

export default ExtendUnitCheckBoxItem;
