import { Grid, ListItem, Typography } from '@material-ui/core';
import { Colors, CustomSubscription, getLocaleFromLanguage } from '@rentguru/commons-utils';
import { ReactComponent as DownloadFolder } from 'src/icons/downloadZip.svg';
import NotificationDeleteIconButton from 'src/components/ui/CustomIconButtons/NotificiationDeleteIconButton';
import { useCustomSubscriptions } from 'src/hooks/CustomSubscriptionContext';
import { useNotificationListStyle } from './NotificationsList';
import { useIntl } from 'react-intl';
import { isNil } from 'lodash';
import { formatDistanceToNow } from 'date-fns';
import { useUser } from 'src/hooks/UserContext';

interface NotificationsZipItemProps {
  url: string;
  customSubscription: CustomSubscription;
}

const NotificationsZipItem: React.FC<NotificationsZipItemProps> = ({ url, customSubscription }) => {
  const { deleteCustomSubscription } = useCustomSubscriptions();
  const { formatMessage } = useIntl();
  const { language } = useUser();
  const localStyles = useNotificationListStyle();

  return (
    <ListItem className={localStyles.listItem}>
      <Grid>
        <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <DownloadFolder
              style={{
                width: 45,
                height: 45,
                transform: 'scale(0.6)',
              }}
            />
            <Typography variant="h6" style={{ color: Colors.CLASSICAL_BLACK }}>
              {formatMessage({ id: 'accountMenu.downloadReadyPrimary' })}
            </Typography>
          </Grid>
          <NotificationDeleteIconButton
            onClick={() => {
              deleteCustomSubscription(customSubscription);
            }}
          />
        </Grid>
        {!isNil(customSubscription.createdAt) && (
          <Typography style={{ color: Colors.BLUEY, paddingBottom: 5 }}>
            {formatDistanceToNow(new Date(customSubscription.createdAt!), {
              locale: getLocaleFromLanguage(language || 'en'),
            })}
          </Typography>
        )}
        <Typography style={{ color: Colors.SLATE_GREY }}>
          {formatMessage({ id: 'accountMenu.downloadReady1' })}{' '}
          <span
            style={{
              color: Colors.DODGER_BLUE,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={async () => {
              const a = document.createElement('a');
              a.href = url;
              a.download = 'untitled';
              const clickHandler = () => {
                setTimeout(() => {
                  URL.revokeObjectURL(url);
                  a.removeEventListener('click', clickHandler);
                }, 150);
              };
              a.addEventListener('click', clickHandler, false);
              a.click();
            }}
          >
            {formatMessage({ id: 'accountMenu.downloadReady2' })}
          </span>
        </Typography>
      </Grid>
    </ListItem>
  );
};

export default NotificationsZipItem;
