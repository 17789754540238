import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import React, { useState } from 'react';

export const useTextDetailStyles = makeStyles({
  typography: {
    fontSize: 16,
    fontWeight: 500,
  },
});

interface TextDetailProps {
  title: string;
  titleId?: string;
  text?: string | null | number;
  textId?: string;
  style?: React.CSSProperties;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  endAdornment?: string;
  subTitle?: string;
  error?: boolean;
  dataTest?: string;
  needTooltip?: boolean;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
}
export const TextDetail: React.FC<TextDetailProps> = ({
  title,
  titleId,
  text,
  textId,
  children,
  style,
  icon,
  endAdornment,
  subTitle,
  error = false,
  dataTest,
  needTooltip = false,
  onMouseEnter,
  onMouseLeave,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const classes = useTextDetailStyles();

  if (isNil(endAdornment)) {
    return (
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {icon && <Typography style={{ fill: Colors.LIGHT_BLUE_GREY, marginRight: 5 }}>{icon}</Typography>}
          <Typography id={titleId || 'textDetailTitle'} style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
            {title}
          </Typography>
        </div>
        <div>
          <Typography
            style={{
              color: Colors.LIGHT_BLUE_GREY,
              fontSize: 12,
            }}
          >
            {subTitle}
          </Typography>
        </div>
        {!children ? (
          <Grid style={{}}>
            <Typography
              className={classes.typography}
              id={textId || 'textDetailText'}
              onMouseEnter={() => setIsTooltipOpen(true)}
              onMouseLeave={() => setIsTooltipOpen(false)}
              style={{
                color: !error ? Colors.CLASSICAL_BLACK : 'red',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ...style,
              }}
              data-test={dataTest || 'textDetailDataTest'}
            >
              {text}
            </Typography>
            {needTooltip && isTooltipOpen && (
              <Paper
                elevation={8}
                style={{
                  position: 'absolute',
                  margin: 0,
                  zIndex: 1,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 10,
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <Typography
                  className={classes.typography}
                  style={{
                    color: Colors.CLASSICAL_BLACK,
                    whiteSpace: 'nowrap',
                    ...style,
                  }}
                >
                  {text}
                </Typography>
              </Paper>
            )}
          </Grid>
        ) : (
          children
        )}
      </div>
    );
  }
  // The new part is here in case the endAdornment is given and not empty
  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography id={titleId || 'textDetailTitle'} style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>
          {title}
        </Typography>
        <Typography style={{ fill: Colors.LIGHT_BLUE_GREY, marginLeft: 5, marginBottom: -5 }}>{icon}</Typography>
      </div>
      <div>
        <Typography style={{ color: Colors.LIGHT_BLUE_GREY, fontSize: 12 }}>{subTitle}</Typography>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {!children ? (
          <Typography
            className={classes.typography}
            id={textId || 'textDetailText'}
            style={{ color: !error ? Colors.CLASSICAL_BLACK : 'red', ...style }}
            data-test={dataTest || 'textDetailDataTest'}
          >
            {text}
          </Typography>
        ) : (
          children
        )}
        {endAdornment ? (
          <Typography
            className={classes.typography}
            style={{ color: Colors.CLASSICAL_BLACK, whiteSpace: 'pre-wrap', ...style }}
          >
            {` ${endAdornment}`}
          </Typography>
        ) : null}
      </div>
    </div>
  );
};
