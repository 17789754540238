import { Grid, Typography } from '@material-ui/core';
import { Colors, formatNumber, Invoice, InvoiceWithPostings, Transaction } from '@rentguru/commons-utils';
import { ColoredBox, OverflowableTypography, TextDetail } from '@up2rent/ui';
import { max, min } from 'date-fns';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { getLeaseInvoicesWithPostings } from 'src/components/Accounting/Transactions/utils';
import { useTransactions } from 'src/hooks/TransactionsContext';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { getPrintableDate } from 'src/utils/dates';

interface LeaseBalancePopoverLineProps {
  title: string;
  date: string;
  amount: number;
  amountColor: string;
  language: string;
}

const LeaseBalancePopoverLine: React.FC<LeaseBalancePopoverLineProps> = ({
  title,
  date,
  amount,
  amountColor,
  language,
}) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <OverflowableTypography
          text={title}
          style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'normal', letterSpacing: 0 }}
        />
      </Grid>
      <Grid item xs={5}>
        <Typography style={{ fontSize: 16, fontWeight: 'normal', fontStyle: 'normal', letterSpacing: 0 }}>
          {date}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <ColoredBox boxColor={amountColor}>
          {formatNumber(amount, language, { style: 'currency', currency: 'EUR' })}
        </ColoredBox>
      </Grid>
    </Grid>
  );
};

const getTransactionColor = (amount: number, isCreditNote: boolean | null = false) => {
  if (isCreditNote || amount > 0) {
    return Colors.TURQUOISE_BLUE;
  }
  if (amount < 0) {
    return Colors.CARNATION_RED;
  }
  return Colors.SILVER;
};

const sortInvoicesAndTransactionsByDate = (invoicesAndTransactions: (InvoiceWithPostings | Transaction)[]) =>
  invoicesAndTransactions.sort((a, b) => {
    const isAInvoice = a.hasOwnProperty('invoiceDate');
    const isBInvoice = b.hasOwnProperty('invoiceDate');
    const aDate = isAInvoice ? getFirstDateFrom(a as InvoiceWithPostings) : (a as Transaction).operationDate;
    const bDate = isBInvoice ? getFirstDateFrom(b as InvoiceWithPostings) : (b as Transaction).operationDate;
    if (aDate && !bDate) {
      return -1;
    }
    if (!aDate && bDate) {
      return 1;
    }
    if (aDate && bDate) {
      return new Date(bDate).getTime() - new Date(aDate).getTime();
    }
    return 0;
  });

const getFirstDateFrom = (invoiceWithPostings: InvoiceWithPostings) => {
  const allFromDates = invoiceWithPostings.postings.map((posting) => new Date(posting.periodFrom));
  const minFromDate = min(allFromDates);
  return minFromDate;
};

const getLastDateTo = (invoiceWithPostings: InvoiceWithPostings) => {
  const allToDates = invoiceWithPostings.postings.map((posting) => new Date(posting.periodTo));
  const maxToDate = max(allToDates);
  return maxToDate;
};

const getInvoicePeriod = (invoiceWithPostings: InvoiceWithPostings) => {
  const minFromDate = getFirstDateFrom(invoiceWithPostings);
  const maxToDate = getLastDateTo(invoiceWithPostings);
  return `${getPrintableDate(minFromDate)} → ${getPrintableDate(maxToDate)}`;
};

const getRowLabelId = (invoiceOrTransaction: Invoice | Transaction) => {
  const isInvoice = invoiceOrTransaction.hasOwnProperty('invoiceDate');
  if (!isInvoice) {
    return `enums.TransactionType.TRANSACTION`;
  }

  const invoice: Invoice = invoiceOrTransaction as Invoice;
  if (invoice.creditNote) {
    return `enums.CreditNoteInvoiceType.${invoice.type}`;
  }

  return `enums.InvoiceType.${invoice.type}`;
};
interface LeaseBalancePopoverColumnProps {
  leaseId: string;
}
const LeaseBalancePopoverColumn: React.FC<LeaseBalancePopoverColumnProps> = ({ leaseId }) => {
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const { transactionsLoading, transactionsError, getTransactionsForLease } = useTransactions();
  const [invoices, setInvoices] = useState<InvoiceWithPostings[] | null>(null);
  const transactions = getTransactionsForLease(leaseId, true);

  useEffect(() => {
    const fetchInvoicesWithPostings = async () => {
      const invoicesFromLease = await getLeaseInvoicesWithPostings(leaseId);
      setInvoices(invoicesFromLease as InvoiceWithPostings[]);
    };
    fetchInvoicesWithPostings();
  }, [leaseId]);

  const loading = transactionsLoading || isNil(invoices);
  const error = transactionsError;
  const sortedInvoicesAndTransactions = !loading
    ? sortInvoicesAndTransactionsByDate([...transactions, ...invoices])
    : [];

  return (
    <>
      <Typography style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 16 }}>
        {formatMessage({
          id: 'menu.transactions',
        })}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <TextDetail title={formatMessage({ id: 'communications.filter.type' })} />
        </Grid>
        <Grid item xs={5}>
          <TextDetail title={formatMessage({ id: 'transactions.list.date' })} />
        </Grid>
        <Grid item xs={3}>
          <TextDetail title={formatMessage({ id: 'transactions.list.amount' })} />
        </Grid>
      </Grid>
      {loading && <Typography>{formatMessage({ id: 'loading' })}</Typography>}
      {error && <Typography>{`${transactionsError}`}</Typography>}
      {!loading &&
        !error &&
        sortedInvoicesAndTransactions &&
        sortedInvoicesAndTransactions.slice(0, 6).map((invoiceOrTransaction) => {
          const isInvoice = invoiceOrTransaction.hasOwnProperty('invoiceDate');
          const amountColor = isInvoice
            ? getTransactionColor(-invoiceOrTransaction.amount, (invoiceOrTransaction as Invoice).creditNote)
            : getTransactionColor(invoiceOrTransaction.amount);
          const invoiceOrTransactionDate =
            getPrintableDate((invoiceOrTransaction as Transaction).operationDate) ||
            getInvoicePeriod(invoiceOrTransaction as InvoiceWithPostings);

          const invoiceOrTransactionLabelId = getRowLabelId(invoiceOrTransaction);

          return (
            <LeaseBalancePopoverLine
              key={invoiceOrTransaction.id}
              title={formatMessage({ id: invoiceOrTransactionLabelId })}
              language={language}
              date={invoiceOrTransactionDate}
              amount={invoiceOrTransaction.amount}
              amountColor={amountColor}
            />
          );
        })}
    </>
  );
};

export default LeaseBalancePopoverColumn;
