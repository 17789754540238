import { CustomizedSelect, CustomizedSelectProps } from '@up2rent/ui';
import { useFormikContext } from 'formik';
import React from 'react';
import { ReactComponent as DropDown } from '../../icons/drop-down.svg';

const FormikCustomizedSelect: React.FC<CustomizedSelectProps> = (props) => {
  const { handleChange, handleBlur } = useFormikContext();
  return <CustomizedSelect IconComponent={DropDown} {...props} handleChange={handleChange} onBlur={handleBlur} />;
};

export default FormikCustomizedSelect;
