/* eslint-disable react/no-unstable-nested-components */
import { Grid, Typography } from '@material-ui/core';
import {
  AccountLabel,
  Colors,
  CreateAccountLabelInput,
  CustomLabel,
  Language,
  ModelWithVersion,
  UpdateAccountLabelInput,
} from '@rentguru/commons-utils';
import { CustomSimpleDialog, TextDetailEditable } from '@up2rent/ui';
import { Form, Formik, FormikHelpers } from 'formik';
import isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import TranslateTextDetailEditable, {
  TranslateDialogBundle,
} from 'src/components/ui/Forms/FormField/Translators/TranslateTextDetailEditable';
import HelpIconPopover from 'src/components/ui/HelpIconPopover';
import * as Yup from 'yup';

const CLASS_ALREADY_EXISTS_ERROR = 'Class already exists!';

const AddEditAccountLabelSchema = Yup.object().shape({
  class: Yup.string().min(5).max(6).required(),
  labels: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        language: Yup.string().required(),
      })
    )
    .required(),
});

interface NumberFormatCustomProps extends NumberFormatProps {
  inputRef: (el: HTMLInputElement) => void;
  onChange: (value: NumberTargetValue) => void;
  name: string;
}

interface NumberTargetValue {
  target: {
    value: string;
    name: string;
  };
}

interface AddEditAccountLabelDialogValues {
  class: string;
  labels: { label: string; language: string }[];
}

interface AddEditAccountLabelDialogProps {
  open: boolean;
  onClose: () => void;
  afterSubmit: (accountLabel: AccountLabel) => void;
  createAccountLabel: (input: Omit<CreateAccountLabelInput, 'clientId' | 'readId'>) => Promise<AccountLabel>;
  updateAccountLabel: (updates: UpdateAccountLabelInput) => Promise<AccountLabel>;
  title?: string;
  accountLabel?: AccountLabel | null;
  allAccountLabels: AccountLabel[];
  topClass?: number;
}

const AddEditAccountLabelDialog: React.FC<AddEditAccountLabelDialogProps> = ({
  open,
  onClose,
  afterSubmit,
  createAccountLabel,
  updateAccountLabel,
  title,
  accountLabel,
  allAccountLabels,
  topClass,
}) => {
  const { formatMessage } = useIntl();
  const [languageSelected, setLanguage] = useState<Language>(Language.EN);
  const [textsToTranslate, setTextsToTranslate] = useState<TranslateDialogBundle | null>(null);
  const topClassPrefix = topClass ? `${Math.round(topClass / 100000)}` : undefined;
  const editingAccountLabel = !isNil(accountLabel);

  const handleCreateUpdate = async (
    values: AddEditAccountLabelDialogValues,
    { setSubmitting, setStatus, setErrors }: FormikHelpers<AddEditAccountLabelDialogValues>
  ) => {
    const { labels, class: classValue } = values;
    let cleanClass: number;
    if (classValue.length === 5 && topClassPrefix) {
      cleanClass = Number(`${topClassPrefix}${classValue}`);
    } else if (classValue.length === 6) {
      cleanClass = Number(classValue);
    } else {
      setErrors({ class: 'Incorrect class !' });
      return;
    }
    let newAccount: AccountLabel;
    if (isNil(accountLabel)) {
      const classAlreadyExist = allAccountLabels.some((acl) => !acl.disabled && acl.class === cleanClass);
      if (classAlreadyExist) {
        setErrors({ class: CLASS_ALREADY_EXISTS_ERROR });
        return;
      }
      newAccount = await createAccountLabel({
        class: cleanClass,
        topClass: topClass || Math.floor(cleanClass / 100000) * 1000000,
        labels,
      });
    } else {
      newAccount = await updateAccountLabel({
        id: accountLabel.id,
        // class: cleanClass,
        labels,
        _version: (accountLabel as ModelWithVersion<AccountLabel>)._version,
      });
    }

    setStatus(true);
    setSubmitting(false);
    afterSubmit(newAccount);
  };

  const NumberFormatCustom = (props: NumberFormatCustomProps) => {
    const { inputRef, onChange, name, ...other } = props;
    return (
      <NumberFormat
        {...other}
        allowEmptyFormatting
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
              name,
            },
          });
        }}
        format={topClassPrefix ? `${topClassPrefix}#####` : '######'}
        mask="_"
      />
    );
  };

  const initialValues: AddEditAccountLabelDialogValues = {
    class: editingAccountLabel
      ? topClass
        ? accountLabel!.class.toString().slice(1, 6)
        : accountLabel!.class.toString()
      : '',
    labels:
      accountLabel && accountLabel.labels
        ? accountLabel.labels
        : [
            { language: Language.EN, label: '' },
            { language: Language.FR, label: '' },
            { language: Language.NL, label: '' },
          ],
  };


  const getIndexOfLabel = (values: AddEditAccountLabelDialogValues, language: Language) => {
    return values.labels?.findIndex((label) => label.language === language) || 0;
  };

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={AddEditAccountLabelSchema} onSubmit={handleCreateUpdate}>
        {({ values, errors, touched, isSubmitting, setFieldValue, handleSubmit }) => {

          const handleAfterSubmit = (newTexts: CustomLabel[]) => setFieldValue('labels', newTexts);

          return (
            <Form>
              <CustomSimpleDialog
                open={open}
                onClose={onClose}
                onActionButtonClick={handleSubmit}
                actionButtonLabel={editingAccountLabel ? formatMessage({ id: 'save' }) : formatMessage({ id: 'add' })}
                actionButtonLoading={isSubmitting}
                dividerBelowTitle
                title={
                  title ||
                  (editingAccountLabel
                    ? formatMessage({ id: 'accounting.accountLabel.titleEdit' })
                    : formatMessage({ id: 'accounting.accountLabel.titleAdd' }))
                }
                formatMessage={formatMessage}
              >
                <Grid
                  container
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}
                >
                  <Grid item xs={12}>
                    <TranslateTextDetailEditable
                      textsToTranslate={textsToTranslate}
                      languageSelected={languageSelected}
                      onDialogClose={() => setTextsToTranslate(null)}
                      onLanguageChange={(language) => setLanguage(language)}
                      actionButtonProps={{
                        onClick: () =>
                          setTextsToTranslate({
                            texts: values.labels,
                            afterSubmit: handleAfterSubmit,
                          }),
                      }}
                      textDetailEditableProps={{
                        title: formatMessage({ id: 'settings.addVariousOperation.label' }),
                        name: `labels[${getIndexOfLabel(values, languageSelected)}].label`,
                        editMode: true,
                        style: { width: '100%', maxWidth: 'none' },
                        error: Boolean(errors.labels && touched.labels),
                      }}
                    />
                  </Grid>
                  {errors.labels && (
                    <Grid item xs={12}>
                      <Typography style={{ color: Colors.BURNING_ORANGE }}>
                        {formatMessage({ id: 'accounting.accountLabel.translationsMissings' })}
                      </Typography>{' '}
                    </Grid>
                  )}
                </Grid>

                <Grid style={{ display: 'flex', alignItems: 'center' }}>
                  <TextDetailEditable
                    title={formatMessage({ id: 'accounting.accountLabel.class' })}
                    editMode
                    name="class"
                    type="text"
                    error={Boolean(errors.class && touched.class)}
                    inputProps={{ inputComponent: NumberFormatCustom }}
                    style={{ marginTop: 10 }}
                    disabled={editingAccountLabel}
                    noMaxWidth
                  />
                  {editingAccountLabel && (
                    <HelpIconPopover
                      helperText={formatMessage({ id: 'accounting.accountLabel.editChargeTypeHelpIcon' })}
                      paperStyle={{ maxWidth: 400 }}
                      iconStyle={{ marginLeft: 10 }}
                    />
                  )}
                </Grid>
                {errors.class === CLASS_ALREADY_EXISTS_ERROR && (
                  <Typography style={{ color: Colors.BURNING_ORANGE }}>
                    {formatMessage({ id: 'accounting.accountLabel.classAlreadyExists' })}
                  </Typography>
                )}
              </CustomSimpleDialog>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddEditAccountLabelDialog;
