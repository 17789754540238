import { Divider, Grid, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
  Colors,
  CommunicationTimeFrame,
  getSettingValueForCommunicationTimeFrame,
  NO_ENABLED_TIMEFRAME,
  NotificationOption,
  SettingType,
} from '@rentguru/commons-utils';
import { ContentHeader, ElevatedPaper, LoaderButton } from '@up2rent/ui';
import { Form, Formik, FormikHelpers } from 'formik';
import { isEmpty, isNil } from 'lodash';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useSettings } from 'src/hooks/SettingsContext';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import * as Yup from 'yup';
import AuthorizedTimeFrameRow, { UpdateAuthorizedTimeFramesValues } from './AuthorizedTimeFrameRow';

const getNotificationOptionFromTimeFrame = (
  authorizedTimeFrames: CommunicationTimeFrame[],
  timeFrame: CommunicationTimeFrame
) => (authorizedTimeFrames.includes(timeFrame) ? NotificationOption.ENABLED : NotificationOption.DISABLED);

const getNotificationOptionsFromTimeFrames = (authorizedTimeFrames: CommunicationTimeFrame[]) => ({
  [CommunicationTimeFrame.MORNING]: getNotificationOptionFromTimeFrame(
    authorizedTimeFrames,
    CommunicationTimeFrame.MORNING
  ),
  [CommunicationTimeFrame.AFTERNOON]: getNotificationOptionFromTimeFrame(
    authorizedTimeFrames,
    CommunicationTimeFrame.AFTERNOON
  ),
  [CommunicationTimeFrame.EVENING]: getNotificationOptionFromTimeFrame(
    authorizedTimeFrames,
    CommunicationTimeFrame.EVENING
  ),
  [CommunicationTimeFrame.NIGHT]: getNotificationOptionFromTimeFrame(
    authorizedTimeFrames,
    CommunicationTimeFrame.NIGHT
  ),
});

const UpdateAuthorizedTimeFramesSchema = Yup.object().shape({
  [CommunicationTimeFrame.MORNING]: Yup.string().required(),
  [CommunicationTimeFrame.AFTERNOON]: Yup.string().required(),
  [CommunicationTimeFrame.EVENING]: Yup.string().required(),
  [CommunicationTimeFrame.NIGHT]: Yup.string().required(),
});

const CommunicationsProfilesSettings = () => {
  const { formatMessage } = useIntl();
  const { settings, loading, updateSetting, createSetting } = useSettings();
  const { enqueueSnackbar } = useSnackbar();

  if (loading || isNil(settings)) {
    return (
      <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
        <Typography>{formatMessage({ id: 'loading' })}</Typography>
      </ElevatedPaper>
    );
  }

  const settingsTimeFrames = getSettingValueForCommunicationTimeFrame(settings);
  const initialValues = getNotificationOptionsFromTimeFrames(settingsTimeFrames);

  const handleTimeFrameSubmit = async (
    values: UpdateAuthorizedTimeFramesValues,
    { setSubmitting, setStatus }: FormikHelpers<UpdateAuthorizedTimeFramesValues>
  ) => {
    const newAuthorizedTimeFrames = (Object.keys(values) as CommunicationTimeFrame[]).filter(
      (key) => values[key] === NotificationOption.ENABLED
    );

    const oldSettingsTimeFrames = settings.find((setting) => setting.name === SettingType.COMMUNICATION_TIME_FRAME);
    const stringValue = !isEmpty(newAuthorizedTimeFrames) ? newAuthorizedTimeFrames.toString() : NO_ENABLED_TIMEFRAME;
    if (oldSettingsTimeFrames) {
      await updateSetting(oldSettingsTimeFrames, { value: stringValue });
    } else {
      await createSetting({
        name: SettingType.COMMUNICATION_TIME_FRAME,
        value: stringValue,
      });
    }

    setStatus(true);
    setSubmitting(false);
    enqueueSnackbar(formatMessage({ id: 'settings.changesSaved' }), { variant: 'success' });
  };

  return (
    <>
      <ElevatedPaper style={{ margin: 20, maxWidth: 640, paddingBottom: 0 }}>
        <ContentHeader
          title={formatMessage({ id: 'settings.communications.timeFramesMenu' })}
          toolbarStyle={{ justifyContent: 'flex-start' }}
        >
          <Tooltip title={formatMessage({ id: 'settings.communications.timeFramesInfo' })}>
            <Grid>
              <InfoSvg style={{ fill: Colors.SILVER }} />
            </Grid>
          </Tooltip>
        </ContentHeader>
        <Divider style={{ marginBottom: 20 }} />
        <Formik
          initialValues={initialValues}
          validationSchema={UpdateAuthorizedTimeFramesSchema}
          onSubmit={handleTimeFrameSubmit}
        >
          {({ isSubmitting, status }) => (
            <Form>
              {(Object.keys(initialValues) as CommunicationTimeFrame[]).map((timeFrame) => (
                <AuthorizedTimeFrameRow key={timeFrame} title={timeFrame} />
              ))}
              <Divider style={{ marginTop: 20 }} />

              <Grid
                style={{
                  marginRight: 30,
                  marginTop: 20,
                  marginBottom: 20,
                  display: 'flex',
                  float: 'right',
                }}
              >
                <LoaderButton loading={isSubmitting} success={status}>
                  {formatMessage({ id: 'save' })}
                </LoaderButton>
              </Grid>
            </Form>
          )}
        </Formik>
      </ElevatedPaper>
    </>
  );
};

export default CommunicationsProfilesSettings;
