import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '@rentguru/commons-utils';
import { TextDetailEditable, TextDetailEditableProps } from '@up2rent/ui';
import React from 'react';

const useLocalStyles = makeStyles({
  overLap: {
    backgroundColor: 'transparent',
    opacity: '1',
    // width: '280px',
    height: '47px',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    pointerEvents: 'none',
    borderRadius: 6,
    overflow: 'hidden',
  },
  coloredBanner: {
    width: 10,
    height: 30,
    transform: 'rotate(45deg)',
    position: 'absolute',
    backgroundColor: Colors.TURQUOISE_BLUE,
    top: -14,
  },
});

interface TextDetailEditablePropsWithImportance extends TextDetailEditableProps {
  importance?: 'mandatory' | 'recommended' | 'normal';
  children?: React.ReactNode;
}

const TextDetailEditableWithImportance: React.FC<TextDetailEditablePropsWithImportance> = ({
  title,
  text,
  children,
  editMode,
  name,
  error = false,
  type = 'text',
  endAdornment,
  endAdornmentStyle,
  idInArray,
  arrayName,
  selectable = false,
  selectableValues = [],
  style = {},
  inputProps = {},
  noMaxWidth = false,
  onChangeObserver,
  disabled = false,
  min,
  max,
  step,
  helperText,
  multiline,
  rows,
  rowsMax,
  importance = 'normal',
}) => {
  const localClasses = useLocalStyles();

  let bannerColor = '';
  if (importance === 'recommended') {
    bannerColor = Colors.TURQUOISE_BLUE;
  } else if (importance === 'mandatory') {
    bannerColor = Colors.CARNATION_RED;
  } else {
    bannerColor = 'transparent';
  }

  const customStyles = {
    width: 280,
    marginTop: 8,
    ...style,
  };

  return (
    <div style={{ position: 'relative', width: customStyles.width }}>
      <TextDetailEditable
        title={title}
        text={text}
        // eslint-disable-next-line react/no-children-prop
        children={children}
        editMode={editMode}
        name={name}
        error={error}
        type={type}
        endAdornment={endAdornment}
        endAdornmentStyle={endAdornmentStyle}
        idInArray={idInArray}
        arrayName={arrayName}
        selectable={selectable}
        selectableValues={selectableValues}
        style={style}
        inputProps={inputProps}
        noMaxWidth={noMaxWidth}
        onChangeObserver={onChangeObserver}
        disabled={disabled}
        min={min}
        max={max}
        step={step}
        helperText={helperText}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
      />
      <div className={localClasses.overLap} style={customStyles}>
        <div className={localClasses.coloredBanner} style={{ backgroundColor: bannerColor }} />
      </div>
    </div>
  );
};

export default TextDetailEditableWithImportance;
