import React from 'react';
import { Typography } from '@material-ui/core';
import {
  Colors,
  formatNumber,
  getFromPartAccountOfTransaction,
  getFromPartBankNameOfTransaction,
  getFromPartNameOfTransaction,
  getPrintableRemittanceInformation,
  getToPartAccountOfTransaction,
  getToPartBankNameOfTransaction,
  getToPartNameOfTransaction,
  Transaction,
} from '@rentguru/commons-utils';
import { friendlyFormatIBAN } from 'ibantools';
import { CustomTableCell } from '@up2rent/ui';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { getPrintableDate } from 'src/utils/dates';

interface TransactionBasicColumnsProps {
  transaction: Transaction;
  isPartiallyReconcilated?: boolean;
}
const TransactionBasicColumns: React.FC<TransactionBasicColumnsProps> = ({
  transaction,
  isPartiallyReconcilated = false,
}) => {
  const { language } = useLocale();
  const { amount, operationDate, remittanceInformation, remittanceInformationType } = transaction;

  const transactionColor = isPartiallyReconcilated
    ? Colors.CARROT_ORANGE
    : amount > 0
    ? Colors.TURQUOISE_BLUE
    : amount < 0
    ? Colors.CARNATION_RED
    : Colors.SILVER;
  const fontWeightFrom = amount < 0 ? 600 : 300;
  const fontWeightTo = amount > 0 ? 600 : 300;
  return (
    <>
      <CustomTableCell>
        <Typography style={{ fontWeight: 300, color: Colors.SLATE_GREY, fontSize: 14, marginBottom: 4 }}>
          {getPrintableDate(operationDate)}
        </Typography>
        <Typography style={{ color: Colors.BLUEY, fontSize: 12, lineHeight: '15px' }}>
          {getPrintableRemittanceInformation(remittanceInformation, remittanceInformationType)}
        </Typography>
      </CustomTableCell>
      <CustomTableCell component="th" scope="row" padding="none">
        <div
          style={{
            borderRadius: 4,
            border: `1px solid ${transactionColor}`,
            color: transactionColor,
            fontWeight: 'bold',
            height: 30,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            marginRight: 20,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '100px',
            overflow: 'hidden',
            padding: 1,
          }}
        >
          {formatNumber(amount, language, { style: 'currency', currency: 'EUR' })}
        </div>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <Typography
          style={{ fontWeight: fontWeightFrom, color: Colors.CLASSICAL_BLACK, fontSize: 14, marginBottom: 4 }}
        >
          {getFromPartNameOfTransaction(transaction)}
        </Typography>
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 12, lineHeight: '15px' }}>
          {friendlyFormatIBAN(getFromPartAccountOfTransaction(transaction), ' ')}
        </Typography>
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 12, lineHeight: '15px' }}>
          {getFromPartBankNameOfTransaction(transaction)}
        </Typography>
      </CustomTableCell>
      <CustomTableCell scope="row" padding="none">
        <Typography style={{ fontWeight: fontWeightTo, color: Colors.CLASSICAL_BLACK, fontSize: 14, marginBottom: 4 }}>
          {getToPartNameOfTransaction(transaction)}
        </Typography>
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 12, lineHeight: '15px' }}>
          {friendlyFormatIBAN(getToPartAccountOfTransaction(transaction), ' ')}
        </Typography>
        <Typography style={{ color: Colors.SLATE_GREY, fontSize: 12, lineHeight: '15px' }}>
          {getToPartBankNameOfTransaction(transaction)}
        </Typography>
      </CustomTableCell>
    </>
  );
};

export default TransactionBasicColumns;
