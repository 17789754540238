/* eslint-disable @typescript-eslint/no-shadow */
import { AppBar, Grid, makeStyles, Toolbar } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {
  Address,
  Advertisement,
  AdvertisementUnitSubType,
  AdvertisementUnitType,
  BuildingOwner,
  CertificateOfConformityType,
  Colors,
  CommunicationSettingsProfile,
  DelimitedZoneType,
  DocumentCategory,
  EntityType,
  FileCategory,
  File as FileModel,
  FloodProneAreaType,
  HeatingType,
  isNilOrEmpty,
  S3Object,
  Technic,
  TechnicType,
  UnitInventory,
  UpdateAdvertisementInput,
} from '@rentguru/commons-utils';
import { ContentHeader, CustomIconButton, LoaderButton } from '@up2rent/ui';
import { isAfter, parseISO } from 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import { isEmpty, isEqual, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { handleCreationAndUpdateAndDeletionOfUnitInventories } from 'src/components/Leases/AddLease/AddLeaseUtils';
import RouteDiscardChanges from 'src/components/ui/Dialogs/DiscardChanges/RouteDiscardChanges';
import {
  getUnitInventoriesGroupedByType,
  UnitInventoriesFormValues,
  UnitInventoriesSchema,
} from 'src/components/ui/Forms/FormField/UnitStructureFields';
import SelectionMenu from 'src/components/ui/SelectionMenu';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';
import { useDocumentCategory } from 'src/hooks/FileCategoryContext';
import { getS3ObjectUrls, useFiles } from 'src/hooks/FilesContext';
import { useLeaseInventories } from 'src/hooks/LeaseInventoryContext';
import { useAdvertisement } from 'src/hooks/PublicationsContext';
import {
  FileCategoryTypeForm,
  FileCategoryTypeForm as FilesByCategories,
  fromFileCategoryToTechnic,
  useTechnics,
} from 'src/hooks/TechnicsContext';
import { useUnitInventories } from 'src/hooks/UnitInventoryContext';
import { useUnits } from 'src/hooks/UnitsContext';
import * as Yup from 'yup';
import DescriptionEdits from './DescriptionEdits/DescriptionEdits';
import { StateInterface } from './EditPublication';
import EditAdvertisementLoader from './EditPublicationLoader';
import EnergyEdits from './EnergyEdits/EnergyEdits';
import GeneralEdits from './GeneralEdits/GeneralEdits';
import InteriorEdits from './InteriorEdits/InteriorEdits';
import MoreEdits from './MoreEdits/MoreEdits';
import OutsideEdits from './OutsideEdits/OutsideEdits';
import {
  formValuesToBuilding,
  formValuesToCreateAdvertisementInput,
  formValuesToUnit,
} from './utils/EditPublicationFormTypeMapper';

interface AdvertisementForm
  extends Omit<
    Advertisement,
    | 'readId'
    | 'clientId'
    | 'firstOccupancy'
    | 'furnished'
    | 'wheelChairAccessible'
    | 'resort'
    | 'planningPermission'
    | 'rightOfPreemption'
    | 'subdivisionAuthorization'
    | 'citationForPlanningOffence'
    | 'type'
    | 'subType'
  > {
  firstOccupancy: string;
  furnished: string;
  wheelChairAccessible: string;
  resort: string;
  planningPermission: string;
  rightOfPreemption: string;
  subdivisionAuthorization: string;
  citationForPlanningOffence: string;
  type: AdvertisementUnitType | undefined | '';
  subType: AdvertisementUnitSubType | undefined;
}

export type EditAdvertisementFormValues = {
  unit: {
    id: string;
    advertisedMonthlyCharges: number | undefined | null;
    advertisedRentalPrice: number | undefined | null;
    floor: number | undefined | null;
    cadastralIncome: number | undefined | null;
    propertyTax: number | undefined | null;
    publishAddress: boolean | undefined;
  };
  advertisement: AdvertisementForm;
  building: {
    residenceName: string | undefined;
    constructionYear: number | undefined;
    buildingState: string | undefined;
    amountOfFacades: number | undefined;
    amountOfHousings: number | undefined;
    janitor: string;
    address: Address;
    amountOfFloors: number | undefined;
    owners: BuildingOwner[] | undefined | null;
    cadastralIncome: number | undefined | null;
    propertyTax: number | undefined | null;
  };
  originalUnitInventories: UnitInventory[];
  filesByCategories: FileCategoryTypeForm[];
  originalFilesByCategories: FileCategoryTypeForm[];
} & UnitInventoriesFormValues;

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
}));

const AdvertisementSchema = Yup.object()
  .shape({
    unit: Yup.object()
      .shape({
        advertisedMonthlyCharges: Yup.number().min(1).nullable(),
        advertisedRentalPrice: Yup.number().min(1).required(),
        floor: Yup.number().nullable().notRequired(),
        publishAddress: Yup.boolean().nullable().notRequired(),
      })
      .required(),
    advertisement: Yup.object()
      .shape({
        type: Yup.string().required(),
        subType: Yup.string().nullable().notRequired(),
        availabilityDate: Yup.date().nullable().notRequired(),
        startDate: Yup.date().nullable().notRequired(),
        // description
        amountOfFacades: Yup.number().min(1).nullable().notRequired(),
        length: Yup.number().min(1).nullable().notRequired(),
        width: Yup.number().min(1).nullable().notRequired(),
        landArea: Yup.number().min(1).nullable().notRequired(),
        // nearbyComodities
        amenities: Yup.array().of(Yup.string()).nullable().notRequired(),
        delimitedZone: Yup.string().nullable().notRequired(),
        floodProneArea: Yup.string().nullable().notRequired(),
        certificateOfConformity: Yup.string().nullable().notRequired(),
        resort: Yup.string().nullable().notRequired(),
        furnished: Yup.string().nullable().notRequired(),
        firstOccupancy: Yup.string().nullable().notRequired(),
        rightOfPreemption: Yup.string().nullable().notRequired(),
        planningPermission: Yup.string().nullable().notRequired(),
        wheelChairAccessible: Yup.string().nullable().notRequired(),
        subdivisionAuthorization: Yup.string().nullable().notRequired(),
        citationForPlanningOffence: Yup.string().nullable().notRequired(),
        // amenities
        // landTerrain
        // heatingAmenities
        heatingType: Yup.string().nullable().notRequired(),
        publishCadastralIncome: Yup.boolean().nullable().notRequired(),
      })
      .required(),
    unitStructures: Yup.array()
      .of(
        Yup.object().shape({
          roomType: Yup.string().min(0).required(),
          unitInventories: Yup.array()
            .of(
              Yup.object().shape({
                roomType: Yup.string().min(0).required(),
                roomName: Yup.string().min(0).required(),
                inside: Yup.boolean().nullable(),
                surface: Yup.number().min(1).nullable(),
              })
            )
            .notRequired(),
        })
      )
      .notRequired(),
    building: Yup.object().shape({
      residenceName: Yup.string().nullable().notRequired(),
      constructionYear: Yup.number()
        .min(0)
        .max(new Date().getFullYear() + 50)
        .nullable()
        .notRequired(),
      buildingState: Yup.string().nullable().notRequired(),
      amountOfFacades: Yup.number().min(1).nullable().notRequired(),
      amountOfHousings: Yup.number().min(0).nullable().notRequired(),
      janitor: Yup.string().nullable().notRequired(),
      amountOfFloors: Yup.number().min(0).nullable().notRequired(),
      // cadastralIncome: Yup.number().min(0).required(),
      // propertyTax: Yup.number().min(0).required(),
    }),
  })
  .required();

const AdvertisementCompleteSchema = AdvertisementSchema.concat(UnitInventoriesSchema);

export const getBooleanValueFromString = (value: string) => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return undefined;
};

const EditAdvertisementForm: React.FC<StateInterface> = ({ unit, location }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const classes = useStyles();
  const [selectedMenu, setSelectedMenu] = useState<string>('');
  const {
    getUnitInventoriesFor,
    updateUnitInventory,
    unitInventoriesLoading,
    createUnitInventory,
    deleteUnitInventory,
  } = useUnitInventories();
  const { deepDeleteLeaseInventoryEncoding } = useLeaseInventories();
  const {
    advertisement,
    loading: advertisementLoading,
    updateAdvertisement,
    createAdvertisement,
  } = useAdvertisement(true, unit?.unitAdvertisementId ?? '');
  const unitInventories = getUnitInventoriesFor(unit.id);
  const { updateBuilding, getBuilding, buildingsLoading } = useBuildings();
  const { updateUnit } = useUnits();
  const { loading: filesLoading, createFile, deleteFile, updateFile, getFiles } = useFiles();
  const { technics, technicsLoading, createTechnic } = useTechnics();
  const { getDocumentCategoryByFileCategory } = useDocumentCategory();
  const { getDefaultTechnicCommunicationSettingsProfiles, loading: communicationSettingsProfilesLoading } =
    useCommunicationSettingsProfiles();

  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(true);
  const [filesByCategories, setFilesByCategories] = useState<FilesByCategories[] | undefined>(undefined);
  const [heating, setHeating] = useState<Technic | undefined>(undefined);
  const [technicCommunicationSettingsProfiles, setTechnicCommunicationSettingsProfiles] = useState<
    CommunicationSettingsProfile[]
  >([]);
  const generalLoading =
    isNil(filesByCategories) ||
    advertisementLoading ||
    filesLoading ||
    technicsLoading ||
    unitInventoriesLoading ||
    buildingsLoading ||
    communicationSettingsProfilesLoading;

  const technicMaintenanceFiles = getFiles(
    EntityType.TECHNIC,
    technics.map((technic) => technic.id)
  );

  const advertisementFiles = advertisement ? getFiles(EntityType.ADVERTISEMENT, [advertisement.id]) : [];

  useEffect(() => {
    const fetchFilesAndTechnics = async () => {
      const [epbInDB, heating] = technics.reduce(
        (acc: [Technic | undefined, Technic | undefined], technic) => {
          if (!isNil(technic.unit) && technic.unit.id === unit.id) {
            if (
              technic.type === TechnicType.PEB &&
              (isNil(acc[0]) || isAfter(parseISO(technic.PebValidityDate!), parseISO(acc[0].PebValidityDate!)))
            ) {
              acc[0] = technic;
            } else {
              if (
                (isNil(acc[1]) ||
                  isAfter(parseISO(technic.heatingLastControlDate!), parseISO(acc[1].heatingLastControlDate!))) &&
                technic.type === TechnicType.HEATING
              ) {
                acc[1] = { ...technic, files: technicMaintenanceFiles };
              }
            }
          }
          return acc;
        },
        [undefined, undefined]
      );

      const epbDocumentCategory = getDocumentCategoryByFileCategory(FileCategory.PEB);
      const advertisementPictureDocumentCategory = getDocumentCategoryByFileCategory(
        FileCategory.ADVERTISEMENT_PICTURE
      );

      const technicAndAdvertisementFiles = [...technicMaintenanceFiles, ...advertisementFiles];
      const s3Objects = await getS3ObjectUrls(technicAndAdvertisementFiles);
      const s3FilesByCategories = s3Objects.reduce(
        (filesWithS3, currentFile) => {
          // Check & Get if filesWithS3 already has category
          const existingCategory = filesWithS3.find(
            (fileCategory) => fileCategory.category.id === currentFile.category?.id
          );

          if (currentFile.category?.fileCategory === FileCategory.PEB) {
            if (
              !isNil(epbInDB) &&
              currentFile.foreignKey === epbInDB.id &&
              currentFile.foreignTableName === EntityType.TECHNIC
            ) {
              const epb = {
                categoryId: currentFile.category.id,
                category: currentFile.category,
                ...epbInDB,
                files: [currentFile],
              };
              const index = filesWithS3.indexOf(existingCategory!);
              filesWithS3[index] = epb;
              return filesWithS3;
            }
            return filesWithS3;
          }
          // Already there -> Append
          if (
            !isNil(existingCategory) &&
            !isNil(advertisement) &&
            currentFile.foreignKey === advertisement.id &&
            currentFile.foreignTableName === EntityType.ADVERTISEMENT
          ) {
            existingCategory.files.push(currentFile);
            return filesWithS3;
          }
          return filesWithS3;
        },
        [
          { category: epbDocumentCategory, files: [] },
          { category: advertisementPictureDocumentCategory, files: [], ignore: false },
        ] as FilesByCategories[]
      );
      setFilesByCategories(s3FilesByCategories);
      setHeating(heating);
    };
    if (!technicsLoading && !advertisementLoading) {
      fetchFilesAndTechnics();
    }
    // unmounted?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [technicsLoading, technics, unit, advertisement, advertisementLoading]);

  useEffect(() => {
    if (communicationSettingsProfilesLoading) {
      return;
    }
    const technicProfiles = getDefaultTechnicCommunicationSettingsProfiles();
    setTechnicCommunicationSettingsProfiles(technicProfiles);
  }, [getDefaultTechnicCommunicationSettingsProfiles, communicationSettingsProfilesLoading]);

  if (generalLoading || isEmpty(technicCommunicationSettingsProfiles)) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <EditAdvertisementLoader />
      </div>
    );
  }

  const building = getBuilding(unit.building!.id);

  if (isNil(building)) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <EditAdvertisementLoader />
      </div>
    );
  }

  // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
  const defaultTechnicProfile =
    !isEmpty(technicCommunicationSettingsProfiles) && technicCommunicationSettingsProfiles[0];

  const getHeatingTypeAccordingToTechnics = ():
    | 'NOT_COMMUNICATED'
    | HeatingType
    | 'HEATING_OIL'
    | 'GAS'
    | 'ELECTRICITY' => {
    if (!isNil(heating) && !isNil(heating.heatingType)) {
      return heating.heatingType as HeatingType;
    }
    return HeatingType.GAS;
  };

  const initialValues: EditAdvertisementFormValues = {
    unit: {
      id: unit.id,
      advertisedMonthlyCharges: unit.advertisedMonthlyCharges,
      advertisedRentalPrice: unit.advertisedRentalPrice,
      floor: unit.floor,
      cadastralIncome: isNil(building.owners) ? unit.cadastralIncome : building.cadastralIncome,
      propertyTax: isNil(building.owners) ? unit.propertyTax : building.propertyTax,
      publishAddress: Boolean(unit.publishAddress),
    },
    advertisement: {
      ...(advertisement || {}),
      type: (advertisement?.type as AdvertisementUnitType) ?? AdvertisementUnitType.APARTMENT,
      subType: advertisement?.subType as AdvertisementUnitSubType,
      id: !isNil(advertisement) ? advertisement.id : '',
      availabilityDate: advertisement?.availabilityDate,
      amountOfFacades: advertisement?.amountOfFacades || undefined,
      descriptions: advertisement?.descriptions || [
        {
          language: 'fr',
          description: ``,
        },
        {
          language: 'en',
          description: ``,
        },
        {
          language: 'nl',
          description: ``,
        },
      ],
      width: advertisement?.width,
      length: advertisement?.length,
      landArea: advertisement?.landArea,
      nearbyComodities: advertisement?.nearbyComodities,
      delimitedZone: advertisement?.delimitedZone || DelimitedZoneType.NOT_COMMUNICATED,
      floodProneArea: advertisement?.floodProneArea || FloodProneAreaType.NOT_COMMUNICATED,
      certificateOfConformity: advertisement?.certificateOfConformity || CertificateOfConformityType.NO,
      resort: `${!isNil(advertisement?.resort) ? advertisement?.resort : 'none'}`,
      furnished: `${!isNil(advertisement?.furnished) ? String(advertisement?.furnished) : 'none'}`,
      firstOccupancy: `${!isNil(advertisement?.firstOccupancy) ? String(advertisement?.firstOccupancy) : 'none'}`,
      rightOfPreemption: `${!isNil(advertisement?.rightOfPreemption) ? advertisement?.rightOfPreemption : 'none'}`,
      planningPermission: `${!isNil(advertisement?.planningPermission) ? advertisement?.planningPermission : 'none'}`,
      wheelChairAccessible: `${
        !isNil(advertisement?.wheelChairAccessible) ? advertisement?.wheelChairAccessible : 'none'
      }`,
      subdivisionAuthorization: `${
        !isNil(advertisement?.subdivisionAuthorization) ? advertisement?.subdivisionAuthorization : 'none'
      }`,
      citationForPlanningOffence: `${
        !isNil(advertisement?.citationForPlanningOffence) ? advertisement?.citationForPlanningOffence : 'none'
      }`,
      amenities: advertisement?.amenities || [],
      landTerrain: advertisement?.landTerrain || [],
      heatingAmenities: advertisement?.heatingAmenities || [],
      heatingType: advertisement?.heatingType || (getHeatingTypeAccordingToTechnics() as HeatingType),
      publishCadastralIncome: !isNil(advertisement?.publishCadastralIncome)
        ? advertisement!.publishCadastralIncome
        : false,
    },
    unitStructures: getUnitInventoriesGroupedByType(unitInventories),
    originalUnitInventories: unitInventories,
    building: {
      residenceName: unit.building?.residenceName || '',
      constructionYear: unit.building?.constructionYear
        ? parseISO(unit.building!.constructionYear as string).getFullYear()
        : undefined,
      buildingState: unit.building?.buildingState || 'none',
      amountOfFacades: unit.building?.amountOfFacades || undefined,
      amountOfHousings: unit.building?.amountOfHousings || undefined,
      janitor: `${unit.building?.janitor || 'false'}`,
      address: building.address as Address,
      amountOfFloors: building.amountOfFloors || undefined,
      owners: building.owners,
      cadastralIncome:
        isNil(building.owners) || isEmpty(building.owners) ? unit.cadastralIncome : building.cadastralIncome,
      propertyTax: isNil(building.owners) || isEmpty(building.owners) ? unit.propertyTax : building.propertyTax,
    },
    filesByCategories: filesByCategories!,
    originalFilesByCategories: filesByCategories!,
  };

  const handleEditAdvertisement = async (
    values: EditAdvertisementFormValues,
    { setSubmitting, setStatus }: FormikHelpers<EditAdvertisementFormValues>
  ) => {
    const allOperationPromises: Promise<void | FileModel | null>[] = [];
    let newlyCreatedAdvertisement: Advertisement | null = null;
    const newAdvertisement = formValuesToCreateAdvertisementInput(values, advertisement);

    if (!isNilOrEmpty(advertisement)) {
      await updateAdvertisement(newAdvertisement as UpdateAdvertisementInput);
    } else {
      newlyCreatedAdvertisement = await createAdvertisement(newAdvertisement);
    }

    if (!isEqual(values.originalFilesByCategories, values.filesByCategories)) {
      const remainingClassicFiles: S3Object[] = [];
      let originalClassicFiles: S3Object[] = [];

      // First let us push all the original classic files
      values.originalFilesByCategories.forEach((originalFileByCategory) => {
        if (isNil(fromFileCategoryToTechnic(originalFileByCategory.category.fileCategory))) {
          originalClassicFiles = [...originalClassicFiles, ...(originalFileByCategory.files as S3Object[])];
        }
      });
      values.filesByCategories
        // We pass all the categories where we have at least one file.
        .filter((fileCategory) => !isNil(fileCategory.files))
        // for each cetegory, let's do the following:
        .map((fileCategory) => {
          const { category, files, ...rest } = fileCategory;
          const originalFilesByCategory = values.originalFilesByCategories.find((ofbc) => ofbc.category === category);
          const { unit: _unit, building: _building, lease: _lease, id: _id, ...restWithoutLink } = rest;
          const technicCategory = fromFileCategoryToTechnic(category.fileCategory);
          const filesModified = !isNil(originalFilesByCategory) && !isEqual(files, originalFilesByCategory.files);
          // The file category belongs to a technic category => create technic and link files to it
          if (!isNil(technicCategory)) {
            const technicPromises: Promise<Technic | void>[] = [];
            // There are files now && (they are different from those in the past || simply no files in the past)
            if (!isEmpty(files) && (isNil(originalFilesByCategory) || filesModified)) {
              technicPromises.push(
                createTechnic({
                  ...restWithoutLink,
                  // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
                  communicationSettingsProfileId: defaultTechnicProfile ? defaultTechnicProfile.id : 'mockId',
                  unitId: unit.id,
                  type: technicCategory,
                })
              );
            }
            // The files have been modified => delete previous technic and create new ones if there are new files

            const createTechnicsAndHandleFilesPromise = Promise.all(technicPromises).then((technicResult) => {
              for (const file of files) {
                if (!('url' in file)) {
                  // technics were created because of the new file, so use their ids
                  const technicUnitId = (technicResult[0] as Technic).id;
                  // The file is a brand new one added by the User. So create it and link it the new lease and technic
                  createFile(file.file, EntityType.TECHNIC, technicUnitId, fileCategory.category.id);
                }
              }

              if (!isNil(originalFilesByCategory)) {
                for (const originalFile of originalFilesByCategory.files) {
                  // The original file is not in the list of files anymore => it should be deleted
                  const fileShouldBeDeleted = isNil(files.find((f) => isEqual(f, originalFile)));
                  if (fileShouldBeDeleted) {
                    deleteFile(originalFile as S3Object);
                  }
                }
              }
            });
            allOperationPromises.push(createTechnicsAndHandleFilesPromise);
          } else {
            // Create the non-technic files i.e. the classic files
            for (const file of files) {
              if (!('id' in file)) {
                allOperationPromises.push(
                  createFile(
                    file.file!,
                    EntityType.ADVERTISEMENT,
                    newlyCreatedAdvertisement ? newlyCreatedAdvertisement.id : advertisement!.id,
                    fileCategory.category.id
                  )
                );
              } else {
                remainingClassicFiles.push({ ...file, category: fileCategory.category as DocumentCategory });
              }
            }
          }
          return fileCategory;
        });
      // Last thing to do is to handle the deletion of the classic files
      for (const originalFile of originalClassicFiles) {
        const formFile = remainingClassicFiles.find((f) => f.id === originalFile.id);
        // The original file is not in the remaining old files => delete
        if (isNil(formFile)) {
          allOperationPromises.push(deleteFile(originalFile));
        }
        // the original file is still in the remaining files => check if category has not been changed
        else if (formFile.category !== originalFile.category) {
          allOperationPromises.push(updateFile(originalFile, { category: formFile.category }));
        }
      }

      await Promise.all(allOperationPromises);
    }

    const updatedBuilding = formValuesToBuilding(values, building);
    const updatedUnit = formValuesToUnit(values, building, newlyCreatedAdvertisement, advertisement);

    const unitInventoriesPromise = handleCreationAndUpdateAndDeletionOfUnitInventories(
      new Date(),
      values.unitStructures,
      values.originalUnitInventories,
      createUnitInventory,
      deleteUnitInventory,
      deepDeleteLeaseInventoryEncoding,
      updateUnitInventory
    );
    await updateBuilding(unit.building!, updatedBuilding);
    await updateUnit(unit, updatedUnit);
    await unitInventoriesPromise;
    setSubmitting(true);
    setStatus(true);
    setShouldBlockNavigation(false);
    history.goBack();
  };

  let sectionItems: string[][];
  const sectionDescription = formatMessage({ id: 'rentalUnit.detail.description.description' });
  const sectionGeneral = formatMessage({ id: 'rentalUnit.detail.general.general' });
  const sectionInterior = formatMessage({ id: 'rentalUnit.detail.general.inside.inside' });
  const sectionOutside = formatMessage({ id: 'rentalUnit.detail.general.outside.outside' });
  const sectionEnergy = formatMessage({ id: 'rentalUnit.detail.general.energy.energy' });
  const sectionMore = formatMessage({ id: 'rentalUnit.detail.more.more' });

  // eslint-disable-next-line prefer-const
  sectionItems = [[sectionDescription, sectionGeneral, sectionInterior, sectionOutside, sectionEnergy, sectionMore]];
  const selectedItem = selectedMenu === '' ? sectionItems[0][location || 0] : selectedMenu;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleEditAdvertisement}
      validationSchema={AdvertisementCompleteSchema}
    >
      {({ isSubmitting, status, values, errors }) => {
        const errorNames = Object.keys(errors);
        return (
          <Form>
            <AppBar
              position="fixed"
              style={{
                width: '100%',
                backgroundColor: Colors.CLASSICAL_WHITE,
                minHeight: '80px',
              }}
            >
              <Grid container style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Grid item>
                  <Toolbar style={{ position: 'relative', top: 5 }}>
                    <>
                      <CustomIconButton
                        style={{ marginLeft: -12, marginRight: 20 }}
                        aria-label="Menu"
                        onClick={() => {
                          history.goBack();
                        }}
                        id="historyGoBackButton"
                        Icon={ArrowBack}
                      />
                      <ContentHeader title={formatMessage({ id: 'rentalUnit.detail.advertisement.edit' })} />
                    </>
                  </Toolbar>
                </Grid>
                <Grid item>
                  <LoaderButton
                    id="saveButton"
                    type="submit"
                    loading={isSubmitting}
                    success={status}
                    style={{ position: 'relative', height: 50, margin: '0 30px 0 0', top: 10 }}
                  >
                    {formatMessage({ id: 'save' })}
                  </LoaderButton>
                </Grid>
                <RouteDiscardChanges
                  when={!isEqual(initialValues, values)}
                  history={history}
                  onConfirm={() => {
                    console.error('Discarded...');
                  }}
                  shouldBlockNavigation={() => shouldBlockNavigation}
                />
              </Grid>
            </AppBar>

            <div className={classes.offset} style={{ marginTop: 10 }} />

            <Grid container direction="row">
              <Grid item xs={2} />
              <Grid item xs={8} style={{ display: 'flex', justifyContent: 'center' }}>
                <SelectionMenu
                  title={unit.name}
                  sections={[formatMessage({ id: 'rentalUnit.detail.editAdvertisementLeftMenu.section' })]}
                  sectionsItems={sectionItems}
                  selectedItem={selectedItem}
                  setSelectedFunction={setSelectedMenu}
                  errors={errorNames}
                  // sectionErrors={sectionErrors}
                />
                <div style={{ marginLeft: '20px', width: '640px' }}>
                  {selectedItem === sectionDescription && <DescriptionEdits />}
                  {selectedItem === sectionGeneral && <GeneralEdits />}
                  {selectedItem === sectionInterior && <InteriorEdits />}
                  {selectedItem === sectionOutside && <OutsideEdits />}
                  {selectedItem === sectionEnergy && <EnergyEdits />}
                  {selectedItem === sectionMore && <MoreEdits />}
                </div>
              </Grid>
              <Grid item xs={2} />
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditAdvertisementForm;
