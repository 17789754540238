import { Grid, Tooltip, Typography } from '@material-ui/core';
import { Colors, DocumentType } from '@rentguru/commons-utils';
import { CustomIconButton, SelectedComponentProps, useNumberOfSelectedItemsStyles } from '@up2rent/ui';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useIntl } from 'react-intl';
import { useFiles } from 'src/hooks/FilesContext';
import { ReactComponent as Download } from 'src/icons/download.svg';

const LeasesSelected: React.FC<SelectedComponentProps> = ({ selectedItems, handleUnselect }) => {
  const { generateDocument } = useFiles();
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useNumberOfSelectedItemsStyles();

  const generateAll = async () => {
    const generateDocumentPromises = selectedItems.map((leaseId) =>
      generateDocument({ id: leaseId, type: DocumentType.LeaseConsolidatedPaymentRequest })
    );
    await Promise.all(generateDocumentPromises);
  };

  return (
    <Grid className={classes.container}>
      <Typography style={{ color: Colors.CLASSICAL_WHITE, fontSize: 16 }}>
        {formatMessage({ id: 'lease.numberOfSelected' }, { number: selectedItems.length })}
      </Typography>
      <Grid>
        <Tooltip title={formatMessage({ id: 'lease.detail.action.generateConsolidatedPaymentRequest' })}>
          <CustomIconButton
            onClick={async (event) => {
              event.stopPropagation();
              enqueueSnackbar(
                formatMessage(
                  { id: 'accounting.reporting.documentGenerationMessage' },
                  { number: selectedItems.length }
                ),
                {
                  variant: 'info',
                  autoHideDuration: 6000,
                  preventDuplicate: true,
                  key: 'generateConsolidatedPaymentRequest',
                }
              );
              handleUnselect(selectedItems);
              await generateAll();
            }}
            size="small"
            Icon={Download}
            iconStyle={{ fill: Colors.CLASSICAL_WHITE }}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default LeasesSelected;
