import { Grid, MenuList, Typography } from '@material-ui/core';
import { Colors, isNilOrEmpty } from '@rentguru/commons-utils';
import { CenteredGrid, ContentHeader, ElevatedPaper } from '@up2rent/ui';
import isNil from 'lodash/isNil';
import React, { lazy } from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { retryLazy } from 'src/components/Routes/LazyErrorBoundary';
import MenuNotFound from 'src/components/Routes/MenuNotFound';
import { RouteDestination } from 'src/components/Routes/Routes';
import {
  TEMPLATE_CONTRACT_ROUTE,
  TEMPLATE_MAIL_ROUTE,
  TEMPLATE_SMS_ROUTE,
} from 'src/components/Templates/templateUtils';
import CustomizedMenuItem from 'src/components/ui/CustomizedMenuItem';
import { useUser } from 'src/hooks/UserContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import AccountLabelSettings from './AccountLabelSettings';
import AgencyRatesSettings from './AgencyRatesSettings';
import BankAccountsSettings from './BankAccountsSettings';
import CommunicationsSettings from './CommunicationSettings';
import GeneralSettings from './GeneralSettings';
import IndexationSettings from './IndexationSettings';
import IntegrationsSettings from './IntegrationsSettings';
import MembersSettings from './MembersSettings';
import RemindersSettings from './RemindersSettings';
import RolesSettings from './RolesSettings';
import SecuritySettings from './SecuritySettings';
import SendingSourceSettings from './SendingSourceSettings';
import SubscriptionSettings from './SubscriptionSettings';
import TeamsSettings from './TeamsSettings';
import VariousOperationsSettings from './VariousOperationsSettings';

const Templates = lazy(() => retryLazy(() => import('../Templates/Templates')));

const TO_PREFIX = '';
const MENU_PROFILE = 1;
const MENU_AUTOMATION = 2;
const MENU_ACCOUNTING = 3;
const MENU_ORGANIZATION = 4;

export enum SettingsRouteDestination {
  ACCOUNT_LABEL_ROUTE = 'accountLabels',
  AGENCY_RATE_ROUTE = 'agencyRates',
  BANK_ACCOUNT_ROUTE = 'bankAccounts',
  COMMUNICATION_ROUTE = 'communications',
  DOMAIN_ROUTE = 'domains',
  GENERAL_ROUTE = 'general',
  INDEXATION_ROUTE = 'indexation',
  INTEGRATION_ROUTE = 'integrations',
  MEMBER_ROUTE = 'members',
  REMINDER_ROUTE = 'reminders',
  ROLE_ROUTE = 'roles',
  SUBSCRIPTION_ROUTE = 'subscription',
  TEAM_ROUTE = 'teams',
  TEMPLATE_ROUTE = 'templates',
  VARIOUS_OPERATION_ROUTE = 'variousOperations',
}

const adminNavItems = [
  {
    to: `${TO_PREFIX}`,
    exact: true,
    labelId: 'settings.securityMenu',
    component: SecuritySettings,
    menu: MENU_PROFILE,
  },
  {
    to: `${TO_PREFIX}${SettingsRouteDestination.COMMUNICATION_ROUTE}`,
    labelId: 'settings.communicationsMenu',
    component: CommunicationsSettings,
    menu: MENU_AUTOMATION,
  },
  {
    to: `${TO_PREFIX}${SettingsRouteDestination.TEMPLATE_ROUTE}`,
    labelId: 'menu.templates',
    component: Templates,
    menu: MENU_AUTOMATION,
  },
  {
    to: `${TO_PREFIX}${SettingsRouteDestination.DOMAIN_ROUTE}`,
    labelId: 'settings.sendingSourceMenu',
    component: SendingSourceSettings,
    menu: MENU_AUTOMATION,
  },
  {
    to: `${TO_PREFIX}${SettingsRouteDestination.REMINDER_ROUTE}`,
    labelId: 'settings.remindersMenu',
    component: RemindersSettings,
    menu: MENU_AUTOMATION,
  },
  {
    to: `${TO_PREFIX}${SettingsRouteDestination.INTEGRATION_ROUTE}`,
    labelId: 'settings.integrationsMenu',
    component: IntegrationsSettings,
    menu: MENU_AUTOMATION,
  },
  {
    to: `${TO_PREFIX}${SettingsRouteDestination.INDEXATION_ROUTE}`,
    labelId: 'settings.indexationMenu',
    component: IndexationSettings,
    menu: MENU_AUTOMATION,
  },
  {
    to: `${TO_PREFIX}${SettingsRouteDestination.BANK_ACCOUNT_ROUTE}`,
    labelId: 'settings.bankAccountsMenu',
    component: BankAccountsSettings,
    menu: MENU_ACCOUNTING,
  },
  {
    to: `${TO_PREFIX}${SettingsRouteDestination.ACCOUNT_LABEL_ROUTE}`,
    labelId: 'settings.chargeAccountMenu',
    component: AccountLabelSettings,
    menu: MENU_ACCOUNTING,
  },
  {
    to: `${TO_PREFIX}${SettingsRouteDestination.VARIOUS_OPERATION_ROUTE}`,
    labelId: 'settings.variousOperationsMenu',
    component: VariousOperationsSettings,
    menu: MENU_ACCOUNTING,
  },
  {
    to: `${TO_PREFIX}${SettingsRouteDestination.GENERAL_ROUTE}`,
    labelId: 'settings.general',
    component: GeneralSettings,
    menu: MENU_ORGANIZATION,
  },
  {
    to: `${TO_PREFIX}${SettingsRouteDestination.SUBSCRIPTION_ROUTE}`,
    labelId: 'settings.subscription.name',
    component: SubscriptionSettings,
    menu: MENU_ORGANIZATION,
  },
  {
    to: `${TO_PREFIX}${SettingsRouteDestination.AGENCY_RATE_ROUTE}`,
    labelId: 'settings.agencyRates',
    component: AgencyRatesSettings,
    menu: MENU_ORGANIZATION,
  },
  {
    to: `${TO_PREFIX}${SettingsRouteDestination.TEAM_ROUTE}`,
    labelId: 'settings.team',
    component: TeamsSettings,
    menu: MENU_ORGANIZATION,
  },
  {
    to: `${TO_PREFIX}${SettingsRouteDestination.MEMBER_ROUTE}`,
    labelId: 'settings.members',
    component: MembersSettings,
    menu: MENU_ORGANIZATION,
  },
  {
    to: `${TO_PREFIX}${SettingsRouteDestination.ROLE_ROUTE}`,
    labelId: 'settings.roles.title',
    component: RolesSettings,
    menu: MENU_ORGANIZATION,
  },
];

interface SettingMenuProps {
  title: string;
  order: number;
  menuIndexToSkip?: number[];
}

const SettingMenu: React.FC<SettingMenuProps> = ({ title, order, menuIndexToSkip = [] }) => {
  const { formatMessage } = useIntl();
  const match = useRouteMatch<{ detailName: string }>({
    path: `${RouteDestination.SETTINGS}/:detailName?`,
  });

  if (isNil(match)) {
    return null;
  }
  
  return (
    <>
      <Typography
        variant="subtitle1"
        style={{
          color: Colors.LIGHT_BLUE_GREY,
          fontSize: 10,
          letterSpacing: '1px',
          lineHeight: '12px',
          textTransform: 'uppercase',
          marginTop: 30,
          marginLeft: 15,
          textAlign: 'start',
        }}
      >
        {title}
      </Typography>
      <MenuList>
        {adminNavItems
          .filter((m) => m.menu === order)
          .map((m, index) => {
            if (menuIndexToSkip.includes(index)) {
              return null;
            }
            const to = !isNil(match.params.detailName)
              ? match.url.replace(match.params.detailName, m.to)
              : match.url + (match.url.charAt(match.url.length - 1) === '/' ? '' : '/') + m.to;
            const selected =
              (!isNil(match.params.detailName) && match.params.detailName === m.to) ||
              (m.to === TO_PREFIX && isNil(match.params.detailName));

            return (
              <CustomizedMenuItem data-test={to} key={m.to} to={to} selected={selected}>
                {formatMessage({ id: m.labelId })}
              </CustomizedMenuItem>
            );
          })}
      </MenuList>
    </>
  );
};

const SettingsRouteSwitch = () => {
  const { settingsManagementWrite, settingsAutomationRead, financialTransactionsRead, agencyRateRead } =
    usePermissions();
  const { isAgency } = useUser();
  const match = useRouteMatch<{ detailName: string; subDetailName?: string; subSubDetailName?: string }>({
    path: `${RouteDestination.SETTINGS}/:detailName?/:subDetailName?/:subSubDetailName?`,
  });

  return (
    <Switch>
      {adminNavItems.map((m) => {
        if (!settingsAutomationRead && m.menu === MENU_AUTOMATION) {
          return null;
        }
        if (!financialTransactionsRead && m.menu === MENU_ACCOUNTING) {
          return null;
        }

        if (m.menu === MENU_ORGANIZATION && m.labelId === 'settings.agencyRates' && (!isAgency || !agencyRateRead)) {
          return null;
        }
        if (
          m.menu === MENU_ORGANIZATION &&
          m.labelId !== 'settings.agencyRates' &&
          agencyRateRead &&
          !settingsManagementWrite
        ) {
          return null;
        }

        const path = match?.path?.replace(':detailName?/:subDetailName?/:subSubDetailName?', m.to);
        const Comp = m.component as React.FC;
        if (m.component === null) return null;
        return (
          <Route
            path={path}
            key={m.to}
            exact={m.exact}
            render={() => {
              return <Comp />;
            }}
          />
        );
      })}

      <Route
        path="*"
        render={() => {
          return (
            <ElevatedPaper style={{ margin: 20, maxWidth: 640 }}>
              <MenuNotFound />
            </ElevatedPaper>
          );
        }}
      />
    </Switch>
  );
};

const Settings = () => {
  const { formatMessage } = useIntl();
  const { settingsManagementWrite, settingsAutomationRead, financialTransactionsRead, agencyRateRead } =
    usePermissions();
  const { isAgency } = useUser();
  const match = useRouteMatch<{ detailName: string; subDetailName?: string; subSubDetailName?: string }>({
    path: `${RouteDestination.SETTINGS}/:detailName?/:subDetailName?/:subSubDetailName?`,
  });
  if (isNil(match)) {
    return null;
  }

  const isTemplateDetail =
    match.params.detailName === SettingsRouteDestination.TEMPLATE_ROUTE &&
    match.params.subDetailName &&
    [TEMPLATE_MAIL_ROUTE, TEMPLATE_SMS_ROUTE, TEMPLATE_CONTRACT_ROUTE].includes(match.params.subDetailName) &&
    !isNilOrEmpty(match.params.subSubDetailName);

  if (isTemplateDetail) {
    return <SettingsRouteSwitch />;
  }

  return (
    <CenteredGrid>
      <Grid container style={{ flexGrow: 1, height: '100%', overflow: 'auto' }}>
        <Grid
          item
          style={{
            height: '100%',
            width: 240,
            overflow: 'visible',
          }}
        >
          <ElevatedPaper style={{ margin: 20 }}>
            <ContentHeader
              title={formatMessage({
                id: 'settings.title',
              })}
            />
            <SettingMenu
              title={formatMessage({
                id: 'settings.profile',
              })}
              order={MENU_PROFILE}
            />
            {settingsAutomationRead && (
              <SettingMenu
                title={formatMessage({
                  id: 'settings.automation',
                })}
                order={MENU_AUTOMATION}
              />
            )}
            {financialTransactionsRead && (
              <SettingMenu
                title={formatMessage({
                  id: 'settings.accounting',
                })}
                order={MENU_ACCOUNTING}
              />
            )}
            {(settingsManagementWrite || agencyRateRead) && (
              <SettingMenu
                title={formatMessage({
                  id: 'settings.organisation',
                })}
                order={MENU_ORGANIZATION}
                menuIndexToSkip={!isAgency || !agencyRateRead ? [1] : []}
              />
            )}
          </ElevatedPaper>
        </Grid>
        <Grid style={{ marginLeft: 30, marginTop: 0, flexGrow: 1, overflow: 'auto' }}>
          <SettingsRouteSwitch />
        </Grid>
      </Grid>
    </CenteredGrid>
  );
};

export default Settings;
