import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {
  getSettingObjectIfExist,
  getSettingValueForChimneyReminder,
  getSettingValueForDetectorReminder,
  getSettingValueForFuelTankReminder,
  getSettingValueForHeatingReminder,
  getSettingValueForPEBReminder,
  Notification,
  NotificationOption,
  Setting,
  SettingType,
} from '@rentguru/commons-utils';
import { ConfirmDialog, LoaderButton } from '@up2rent/ui';
import { Form, Formik, FormikHelpers } from 'formik';
import { isEmpty } from 'lodash';
import isNil from 'lodash/isNil';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNotifications } from 'src/hooks/NotificationsContext';
import { useSettings } from 'src/hooks/SettingsContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import * as Yup from 'yup';
import MaintenanceRemindersForm from './MaintenanceRemindersForm';
import { findNotificationsToDelete } from './remindersUtils';

const ChangeRemindersSchema = Yup.object().shape({
  [SettingType.HEATING_REMINDER]: Yup.string().min(1).required(),
  [SettingType.EPB_REMINDER]: Yup.string().min(1).required(),
  [SettingType.TANK_REMINDER]: Yup.string().min(1).required(),
  [SettingType.CHIMNEY_REMINDER]: Yup.string().min(1).required(),
  [SettingType.DETECTOR_REMINDER]: Yup.string().min(1).required(),
});

export type ChangeRemindersValues = {
  [SettingType.HEATING_REMINDER]: NotificationOption;
  [SettingType.EPB_REMINDER]: NotificationOption;
  [SettingType.TANK_REMINDER]: NotificationOption;
  [SettingType.CHIMNEY_REMINDER]: NotificationOption;
  [SettingType.DETECTOR_REMINDER]: NotificationOption;
};

const RemindersForm: React.FC = () => {
  const { settingsAutomationWrite } = usePermissions();
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { settings, loading: settingsLoading, createSetting, updateSetting } = useSettings();
  const { notifications, notificationsLoading, deleteNotification } = useNotifications();
  const [notificationsToDelete, setNotificationsToDelete] = useState<Notification[]>([]);

  if (settingsLoading || notificationsLoading || isNil(settings)) {
    return <Typography>{formatMessage({ id: 'loading' })}</Typography>;
  }

  const handleReminderSubmit = async (
    values: ChangeRemindersValues,
    { setSubmitting, setStatus }: FormikHelpers<ChangeRemindersValues>
  ) => {
    const promiseAddSetting: Promise<Setting>[] = [];
    const promiseUpdateSetting: Promise<Setting>[] = [];

    // Heating
    const settingHeatingReminder = getSettingObjectIfExist(SettingType.HEATING_REMINDER, settings);
    if (isNil(settingHeatingReminder)) {
      promiseAddSetting.push(
        createSetting({
          name: SettingType.HEATING_REMINDER,
          value: values[SettingType.HEATING_REMINDER],
        })
      );
    } else if (settingHeatingReminder.value !== values[SettingType.HEATING_REMINDER]) {
      promiseUpdateSetting.push(
        updateSetting(settingHeatingReminder as Setting, {
          id: settingHeatingReminder.id,
          name: SettingType.HEATING_REMINDER,
          value: values[SettingType.HEATING_REMINDER],
        })
      );
    }

    // PEB
    const settingEpbReminder = getSettingObjectIfExist(SettingType.EPB_REMINDER, settings);
    if (isNil(settingEpbReminder)) {
      promiseAddSetting.push(
        createSetting({
          name: SettingType.EPB_REMINDER,
          value: values[SettingType.EPB_REMINDER],
        })
      );
    } else if (settingEpbReminder.value !== values[SettingType.EPB_REMINDER]) {
      promiseUpdateSetting.push(
        updateSetting(settingEpbReminder as Setting, {
          id: settingEpbReminder.id,
          name: SettingType.EPB_REMINDER,
          value: values[SettingType.EPB_REMINDER],
        })
      );
    }

    // Tank
    const settingTankReminder = getSettingObjectIfExist(SettingType.TANK_REMINDER, settings);
    if (isNil(settingTankReminder)) {
      promiseAddSetting.push(
        createSetting({
          name: SettingType.TANK_REMINDER,
          value: values[SettingType.TANK_REMINDER],
        })
      );
    } else if (settingTankReminder.value !== values[SettingType.TANK_REMINDER]) {
      promiseUpdateSetting.push(
        updateSetting(settingTankReminder as Setting, {
          id: settingTankReminder.id,
          name: SettingType.TANK_REMINDER,
          value: values[SettingType.TANK_REMINDER],
        })
      );
    }

    // Chimney
    const settingChimneyReminder = getSettingObjectIfExist(SettingType.CHIMNEY_REMINDER, settings);
    if (isNil(settingChimneyReminder)) {
      promiseAddSetting.push(
        createSetting({
          name: SettingType.CHIMNEY_REMINDER,
          value: values[SettingType.CHIMNEY_REMINDER],
        })
      );
    } else if (settingChimneyReminder.value !== values[SettingType.CHIMNEY_REMINDER]) {
      promiseUpdateSetting.push(
        updateSetting(settingChimneyReminder as Setting, {
          id: settingChimneyReminder.id,
          name: SettingType.CHIMNEY_REMINDER,
          value: values[SettingType.CHIMNEY_REMINDER],
        })
      );
    }

    // Detector
    const settingDetectorReminder = getSettingObjectIfExist(SettingType.DETECTOR_REMINDER, settings);
    if (isNil(settingDetectorReminder)) {
      promiseAddSetting.push(
        createSetting({
          name: SettingType.DETECTOR_REMINDER,
          value: values[SettingType.DETECTOR_REMINDER],
        })
      );
    } else if (settingDetectorReminder.value !== values[SettingType.DETECTOR_REMINDER]) {
      promiseUpdateSetting.push(
        updateSetting(settingDetectorReminder as Setting, {
          id: settingDetectorReminder.id,
          name: SettingType.DETECTOR_REMINDER,
          value: values[SettingType.DETECTOR_REMINDER],
        })
      );
    }

    await Promise.all([...promiseAddSetting, ...promiseUpdateSetting]);
    const notificationToDelete = findNotificationsToDelete(notifications, values);
    if (!isEmpty(notificationToDelete)) setNotificationsToDelete(notificationToDelete);
    setStatus(true);
    setSubmitting(false);
    enqueueSnackbar(formatMessage({ id: 'settings.changesSaved' }), { variant: 'success' });
  };

  const disableForm = !settingsAutomationWrite;
  return (
    <>
      <Formik
        initialValues={
          {
            [SettingType.HEATING_REMINDER]: getSettingValueForHeatingReminder(settings),
            [SettingType.EPB_REMINDER]: getSettingValueForPEBReminder(settings),
            [SettingType.TANK_REMINDER]: getSettingValueForFuelTankReminder(settings),
            [SettingType.CHIMNEY_REMINDER]: getSettingValueForChimneyReminder(settings),
            [SettingType.DETECTOR_REMINDER]: getSettingValueForDetectorReminder(settings),
          } as ChangeRemindersValues
        }
        validationSchema={ChangeRemindersSchema}
        onSubmit={handleReminderSubmit}
      >
        {({ isSubmitting, status }) => (
          <Form>
            <div style={{ marginLeft: 30, marginRight: 30 }}>
              <MaintenanceRemindersForm disabled={disableForm} />
            </div>

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            {!disableForm && (
              <div
                style={{
                  marginRight: 30,
                  marginBottom: 20,
                  display: 'flex',
                  float: 'right',
                }}
              >
                <LoaderButton loading={isSubmitting} success={status}>
                  {formatMessage({ id: 'save' })}
                </LoaderButton>
              </div>
            )}
          </Form>
        )}
      </Formik>
      {!isEmpty(notificationsToDelete) && (
        <ConfirmDialog
          open={!isEmpty(notificationsToDelete)}
          confirmText={formatMessage({ id: 'delete' })}
          confirmAction={async () => {
            const deleteNotificationPromises = notificationsToDelete.map(async (notification) => {
              return deleteNotification(notification.id);
            });
            await Promise.all(deleteNotificationPromises);
            setNotificationsToDelete([]);
          }}
          cancelAction={() => {
            setNotificationsToDelete([]);
          }}
          // eslint-disable-next-line max-len
          mainText="It appears there are some notifications in conflict with your new reminder settings, do you want to delete them?"
          formatMessage={formatMessage}
        />
      )}
    </>
  );
};

export default RemindersForm;
