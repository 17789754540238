/* eslint-disable react/forbid-prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Divider, Grid, ListItem, ListItemText, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Colors, isNilOrEmpty } from '@rentguru/commons-utils';
import { ActionButtonSecond, actionButtonSecondStyles, CustomIconButton, CustomPopover } from '@up2rent/ui';
import { isEmpty, isNil } from 'lodash';
import React, { KeyboardEvent, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import { useFieldFilterReset } from 'src/components/ui/FieldFilterSelector/FieldFilterResetHook';
import FlatButton from 'src/components/ui/FieldFilterSelector/FlatButton';
import { ReactComponent as CloseIcon } from 'src/icons/close.svg';
import { ReactComponent as DeleteIcon } from 'src/icons/delete.svg';
import { ReactComponent as SearchIcon } from 'src/icons/search.svg';
import { NoResultPlaceholder } from './NoResultPlaceholder';
import ScrollableComponent from './ScrollableComponent';
import { areEqual } from './utils';

export const useStyles = makeStyles({
  primary: {
    fontSize: 14,
    fontWeight: 600,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: Colors.CLASSICAL_BLACK,
  },
});

export const useSecondaryStyles = makeStyles({
  primary: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: Colors.SLATE_GREY,
    marginTop: 5,
    paddingLeft: 2,
  },
});

const MIN_POPOVER_WIDTH = 280;
export interface FieldFilterSelectorProps {
  initialSelectedItems?: any[] | null;
  disabled?: boolean | null;
  showSearchField?: boolean | null;
  onChange: (
    selectedItems: any[],
    filterName: string,
    fieldName: string,
    menuClosed: boolean
  ) => void | null | Promise<void>;
  onReset?: null | ((filterName: string, fieldName: string) => void);
  filterName: string;
  fieldName: string;
  keepSearchTextOnSelect?: boolean | null;
  multipleSelection?: boolean | null;
  children:
    | ReactNode[]
    | ReactNode
    | ReactElement
    | null
    | { label: string; value: string }[]
    | { label: string; value: string };
  searchFieldAutocompleteFilter?: any | ((searchText: string, label: string) => boolean);
  textSearchField?: string | null;
  textSelectAll?: string | null;
  textReset?: string | null;
  textNoMatchFound?: string | null;
  selected?: boolean | null;
  label: string;
  minSelectorWidth?: number | null;
  minSelectorHeight?: number | null;
  showResetButton: boolean | null;
  showSelectAllButton?: boolean | null;
  elementHeight?: number | null;
  anchorOrigin?: any;
  numberOfElementsVisible?: number | null;
  indicator?: boolean;
  scrollable?: boolean;
  scrollableInitialLimit?: number;
  scrollPercentageThreshold?: number;
  popoverWidth?: number;
}

export interface SelectorChildrenProps {
  label: string;
  value: string;
}

export const FilterCheckBox = ({ isChecked, onCheck }: { isChecked: boolean; onCheck?: () => void }) => (
  <CustomCheckBox
    isChecked={isChecked}
    override={{ style: { marginTop: 0 }, tabIndex: -1, disableFocusRipple: true, disableRipple: true }}
    onCheck={onCheck}
  />
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const FieldFilterSelector: React.FC<FieldFilterSelectorProps> = ({
  initialSelectedItems = null,
  disabled = false,
  showSearchField = true,
  onChange,
  onReset = () => {},
  filterName,
  fieldName,
  keepSearchTextOnSelect = false,
  multipleSelection = false,
  children,
  searchFieldAutocompleteFilter = (searchText: string, text: string): boolean => {
    if (!text || (typeof text !== 'string' && typeof text !== 'number')) return false;
    if (typeof searchText !== 'string' && typeof searchText !== 'number') return false;
    return `${text}`.toLowerCase().includes(searchText.toLowerCase());
  },
  textSearchField = '',
  textSelectAll = '',
  textReset = '',
  textNoMatchFound = '',
  selected = false,
  minSelectorWidth = 500,
  minSelectorHeight = 250,
  showResetButton = false,
  showSelectAllButton = false,
  elementHeight = 36,
  label,
  numberOfElementsVisible = 5,
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
  indicator = true,
  scrollable = false,
  scrollableInitialLimit = 50,
  scrollPercentageThreshold = 85,
  popoverWidth,
  ...rest
}) => {
  const actionButtonClasses = actionButtonSecondStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const searchFieldRef: any = useRef(null);
  const selectorRef: any = useRef<HTMLDivElement>(null);
  const { setCustomResetFilterObj } = useFieldFilterReset();
  const classes = useStyles();
  const menuItemRefs = [];

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  useEffect(() => {
    setCustomResetFilterObj(filterName, reset);
    if (!isNilOrEmpty(initialSelectedItems)) {
      setSelectedItems(initialSelectedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function focusTextField(): void {
    if (searchFieldRef === null) {
      return;
    }
    if (searchFieldRef !== null && searchFieldRef.current !== null && showSearchField) {
      searchFieldRef.current.focus();
    }
  }

  function openMenu(): void {
    setOpen(true);
    focusTextField();
  }

  function closeMenu(reason?: string): void {
    setOpen(false);
    setSearchText('');
    if (selectorRef !== null && !reason) selectorRef.current.focus();
    onChange(selectedItems, filterName, fieldName, true);
  }

  function handleSelectorClick(): void {
    if (!disabled) {
      openMenu();
    }
  }

  function handleKeyDown(event: KeyboardEvent): void {
    if (!disabled && /ArrowDown|Enter/.test(event.key)) {
      openMenu();
    }
  }

  function clearTextField(): void {
    if (keepSearchTextOnSelect !== null && !keepSearchTextOnSelect) {
      setSearchText('');
    }
  }

  const getSelected = (updatedItems: any[] = []): void => {
    if (onChange !== null) {
      onChange(updatedItems, filterName, fieldName, false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleMenuSelection = (selectedItem: any) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    const selectedItemExists = selectedItems.some((obj) => areEqual(obj.value, selectedItem.value));
    const updatedValues = selectedItemExists
      ? selectedItems.filter((obj) => !areEqual(obj.value, selectedItem.value))
      : [...selectedItems, selectedItem];
    setSelectedItems(updatedValues);
    if (multipleSelection) {
      getSelected(updatedValues);
    } else {
      closeMenu();
    }
  };

  const handleSearchTextFiltering = (event: any): void => {
    setSearchText(event.target.value);
  };

  /**
   * Menu Header methods
   */
  const selectAll = (): void => {
    const fixedChildren: any[] = Array.isArray(children) ? children : [children];
    const passesFilter = (labelFilter: string, valueFilter: string): boolean => {
      return searchFieldAutocompleteFilter(searchText, labelFilter || valueFilter);
    };
    const selectedItemsNew = fixedChildren.reduce((nodes: any[], child: any) => {
      if (child === null) {
        return nodes;
      }
      const { type } = child as any;
      if (typeof child === 'object' && type !== null && typeof type === 'undefined') {
        // Not a react component
        return [...nodes, child];
      }
      const {
        props: { value: childValue, label: childLabel },
      } = child as any;
      if (type !== 'optgroup' && passesFilter(childLabel, childValue)) {
        return [...nodes, { childValue, childLabel }];
      }
      return nodes;
    }, [] as any[]);
    setSelectedItems(selectedItemsNew);
    getSelected(selectedItemsNew);
  };

  const reset = (): void => {
    if (onReset !== null && typeof onReset !== 'undefined') {
      onReset(filterName, fieldName);
    }
    setSelectedItems([]);
    setSearchText('');
    getSelected();
    focusTextField();
  };

  const getMenuItems = (onlySelectedMenuItems: boolean): ReactNode[] | ReactElement[] => {
    return !disabled && !isEmpty(fixedChildren) && open
      ? // @ts-ignore
        fixedChildren.reduce((nodes: ReactNode[] | ReactElement[], child: ReactNode | ReactElement, index: number) => {
          const { value: childValue, label: childLabel, type } = child as any;

          const isSelected = selectedItems.some((obj) => !isNil(obj) && areEqual(obj.value, childValue));
          if (onlySelectedMenuItems && !isSelected) return nodes;

          if (
            type === MenuItem ||
            type === ListItem ||
            type === Divider ||
            (typeof type === 'function' && typeof child === 'object')
          ) {
            // If it's a special menu that doesn't need more info
            return [...nodes, child];
          }
          if (!searchFieldAutocompleteFilter(searchText, childLabel || childValue)) {
            return nodes;
          }

          const checkBox =
            multipleSelection && !onlySelectedMenuItems ? (
              <FilterCheckBox isChecked={isSelected} />
            ) : (
              <Grid style={{ width: 20, height: 20, padding: 9 }} />
            );

          const canBeDeleted = multipleSelection && onlySelectedMenuItems;

          return [
            ...nodes,
            <ListItem
              key={++index}
              tabIndex={index}
              ref={(ref) => {
                menuItemRefs[++index] = ref;
              }}
              button
              onClick={handleMenuSelection({ value: childValue, childLabel })}
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <Grid xs={3} item>
                {checkBox}
              </Grid>
              <Grid xs={canBeDeleted ? 7 : 9} item>
                <ListItemText primary={childLabel} classes={classes} style={{ overflowWrap: 'break-word' }} />
              </Grid>
              {canBeDeleted && (
                <Grid xs={2} item style={{ justifyContent: 'center', display: 'flex' }}>
                  <CustomIconButton size="small" Icon={CloseIcon} iconStyle={{ fill: Colors.BLUEY }} />
                </Grid>
              )}
            </ListItem>,
          ];
        }, [] as ReactNode[] | ReactElement[])
      : [
          <ListItem
            key={1}
            disabled
            style={{
              cursor: 'default',
              padding: '10px 16px',
            }}
          >
            <ListItemText primary={textNoMatchFound} classes={classes} />
          </ListItem>,
        ];
  };

  // Build Displayable menuItems
  const fixedChildren = (Array.isArray(children) ? children : [children]) as ReactNode[] | ReactElement[];
  const selectedMenuItems: ReactNode[] | ReactElement[] = getMenuItems(true);
  const menuItems: ReactNode[] | ReactElement[] = getMenuItems(false);

  // Elements size for popover
  const autoCompleteHeight = showSearchField ? 53 : 0;
  const headerHeight = multipleSelection && (showResetButton || showSelectAllButton) ? 36 : 0;
  const noMatchFoundHeight = 36;
  if (numberOfElementsVisible === null) {
    numberOfElementsVisible = 5;
  }
  if (elementHeight === null) {
    elementHeight = 36;
  }
  if (minSelectorHeight === null) {
    minSelectorHeight = 255;
  }
  if (minSelectorWidth === null) {
    minSelectorWidth = 500;
  }
  const optionsContainerHeight =
    elementHeight * (numberOfElementsVisible < menuItems.length ? numberOfElementsVisible : menuItems.length);
  let popoverHeight = autoCompleteHeight + headerHeight + (optionsContainerHeight || noMatchFoundHeight) + 6;
  popoverHeight = popoverHeight < minSelectorHeight ? minSelectorHeight : popoverHeight;
  const adaptedPopoverWidth =
    popoverWidth ??
    (selectorRef ? (selectorRef.clientWidth < minSelectorWidth ? minSelectorWidth : selectorRef.clientWidth) : null);

  const popoverAndPaperWidth =
    !adaptedPopoverWidth || adaptedPopoverWidth < MIN_POPOVER_WIDTH ? MIN_POPOVER_WIDTH : adaptedPopoverWidth;
  const hasNoItem = isEmpty(menuItems);

  return (
    <Grid ref={selectorRef} onKeyDown={(e: KeyboardEvent) => handleKeyDown(e as KeyboardEvent)} {...rest}>
      <FlatButton
        isOpen={open}
        active={!disabled}
        onClick={() => handleSelectorClick()}
        selected={!isEmpty(selectedItems) || (selected !== null && selected)}
        data-test={filterName}
      >
        {label}
      </FlatButton>
      {indicator && <Divider style={{ marginLeft: 5, marginRight: 15 }} />}
      <CustomPopover
        anchorEl={selectorRef.current}
        anchorOrigin={anchorOrigin}
        onClose={(_event, reason) => {
          closeMenu(reason);
        }}
        open={open}
        paperPadding={0}
        paperHeight={popoverHeight}
        paperWidth={popoverAndPaperWidth}
        paperBorderRadius={8}
        enablePointerEvents
      >
        {showSearchField && !isEmpty(fixedChildren) && (
          <>
            <TextField
              ref={searchFieldRef}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              placeholder={textSearchField === null ? '' : textSearchField}
              onChange={handleSearchTextFiltering}
              InputProps={{
                style: { paddingLeft: 20, paddingRight: 20, paddingBottom: 10, paddingTop: 10 },
                startAdornment: <SearchIcon style={{ fill: Colors.BLUEY, paddingRight: 10 }} />,
                disableUnderline: true,
                endAdornment: <CustomIconButton onClick={clearTextField} Icon={DeleteIcon} />,
              }}
              value={searchText}
              fullWidth
            />
            <Divider />
          </>
        )}
        {(multipleSelection || showResetButton || showSelectAllButton) && !isEmpty(fixedChildren) && (
          <header style={{ display: 'flex', alignItems: 'center', paddingTop: 10, marginBottom: 10 }}>
            <Grid style={{ flex: '50%', display: 'flex', justifyContent: 'end' }}>
              {showSelectAllButton && (
                <ActionButtonSecond
                  className={actionButtonClasses.buttonWithMargin}
                  onClick={selectAll}
                  id="filterSelectAll"
                  style={{ textTransform: 'none' }}
                >
                  {textSelectAll}
                </ActionButtonSecond>
              )}
            </Grid>
            <Grid style={{ flex: '50%', display: 'flex', justifyContent: 'end' }}>
              {showResetButton && (
                <ActionButtonSecond
                  className={actionButtonClasses.buttonWithMargin}
                  onClick={() => reset()}
                  id="filterReset"
                  style={{ textTransform: 'none' }}
                >
                  {textReset}
                </ActionButtonSecond>
              )}
            </Grid>
          </header>
        )}
        {multipleSelection && !isEmpty(selectedMenuItems) && !isEmpty(fixedChildren) && (
          <>
            <Grid>{selectedMenuItems}</Grid>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          </>
        )}
        {!scrollable && (hasNoItem ? <NoResultPlaceholder /> : <Grid>{menuItems}</Grid>)}
        {scrollable && (
          <ScrollableComponent
            // eslint-disable-next-line react/jsx-key
            datas={hasNoItem ? [<NoResultPlaceholder />] : menuItems}
            scrollPercentageThreshold={scrollPercentageThreshold}
            scrollableInitialLimit={scrollableInitialLimit}
          />
        )}
      </CustomPopover>
    </Grid>
  );
};

export default FieldFilterSelector;
