import {
  CommunicationSendingRule,
  CommunicationSettingRecipient,
  CommunicationSettings,
  CommunicationSettingsProfile,
  CommunicationSettingsProfileReferent,
  CommunicationType,
  areCommunicationSettingsDefault,
  getCommunicationSettings,
  getDefaultSetting,
} from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import { CommunicationChannel } from 'src/API';
import * as Yup from 'yup';
import { BaseCommunicationSettingsFormValue, BasicSettingsSchema } from './CommunicationSettingsSharedUtils';

export enum CommunicationSettingsProfileType {
  DEFAULT,
  CUSTOM,
}

export interface CommunicationSettingsProfileFormValues extends LeaseCommunicationSettingsFormValues {
  communicationSettingsProfileType: CommunicationSettingsProfileType;
  communicationSettingsProfileId: string;
}

export enum LeaseSettableCommunicationType {
  // RENT PAYMENT REQUESTS
  LEASE_PAYMENT_REQUEST = CommunicationType.LEASE_PAYMENT_REQUEST,
  LEASE_CREDIT_NOTE = CommunicationType.LEASE_CREDIT_NOTE,
  LEASE_OPERATION_PAYMENT_REQUEST = CommunicationType.LEASE_OPERATION_PAYMENT_REQUEST,
  LEASE_PAYMENT_RECEIPT = CommunicationType.LEASE_PAYMENT_RECEIPT,

  // RENT PAYMENT REMINDERS
  LEASE_UNPAID_AMOUNT_REMINDER = CommunicationType.LEASE_UNPAID_AMOUNT_REMINDER,
  LEASE_UNPAID_AMOUNT_SECOND_REMINDER = CommunicationType.LEASE_UNPAID_AMOUNT_SECOND_REMINDER,
  LEASE_UNPAID_AMOUNT_FORMAL_NOTICE = CommunicationType.LEASE_UNPAID_AMOUNT_FORMAL_NOTICE,
  LEASE_UNPAID_CONSOLIDATED_PAYMENT_REMINDER = CommunicationType.LEASE_UNPAID_CONSOLIDATED_PAYMENT_REMINDER,

  // SENDING OF STATEMENTS
  LEASE_TENANT_STATEMENT = CommunicationType.LEASE_TENANT_STATEMENT,

  // CONTRACT TERMINATION/EXTENSION
  LEASE_CONTRACT_EXTENDED = CommunicationType.LEASE_CONTRACT_EXTENDED,
  LEASE_CONTRACT_REJECTED = CommunicationType.LEASE_CONTRACT_REJECTED,
  LEASE_CONTRACT_RETRACTED = CommunicationType.LEASE_CONTRACT_RETRACTED,
  LEASE_CONTRACT_REVOKED = CommunicationType.LEASE_CONTRACT_REVOKED,
  LEASE_TERMINATION = CommunicationType.LEASE_TERMINATION,

  // LEASE RENTAL ADAPTATION
  LEASE_RENT_INDEXATION = CommunicationType.LEASE_RENT_INDEXATION,
  LEASE_RENT_INDEXATION_SKIPPED = CommunicationType.LEASE_RENT_INDEXATION_SKIPPED,
  LEASE_RENTAL_ADAPTATION = CommunicationType.LEASE_RENTAL_ADAPTATION,
  LEASE_CHARGES_ADJUSTMENT = CommunicationType.LEASE_CHARGES_ADJUSTMENT,

  // LEASE OPERATION MOVEMENTS
  LEASE_DISCOUNT_CREATED = CommunicationType.LEASE_DISCOUNT_CREATED,
  LEASE_DISCOUNT_DELETED = CommunicationType.LEASE_DISCOUNT_DELETED,
  LEASE_DISCOUNT_UPDATED = CommunicationType.LEASE_DISCOUNT_UPDATED,
  LEASE_OPERATION_CREATED = CommunicationType.LEASE_OPERATION_CREATED,
  LEASE_OPERATION_DELETED = CommunicationType.LEASE_OPERATION_DELETED,
  LEASE_OPERATION_UPDATED = CommunicationType.LEASE_OPERATION_UPDATED,

  // LEASE INFORMATION UPDATE
  LEASE_ALLOW_PROFESSIONAL_ACTIVITY_ON = CommunicationType.LEASE_ALLOW_PROFESSIONAL_ACTIVITY_ON,
  LEASE_BANKING_INFO_UPDATE = CommunicationType.LEASE_BANKING_INFO_UPDATE,

  // DOCUMENT SIGNATURES
  DOCUMENT_SIGNATURE_REMINDER = CommunicationType.DOCUMENT_SIGNATURE_REMINDER,
  DOCUMENT_SIGNED = CommunicationType.DOCUMENT_SIGNED,
  LEASE_CONTRACT_SIGNATURE_REMINDER = CommunicationType.LEASE_CONTRACT_SIGNATURE_REMINDER,
  LEASE_CONTRACT_SIGNED = CommunicationType.LEASE_CONTRACT_SIGNED,
}

export interface LeaseCommunicationSettingsFormValues {
  // RENT PAYMENT REQUESTS
  [LeaseSettableCommunicationType.LEASE_PAYMENT_REQUEST]: BaseCommunicationSettingsFormValue & {
    daysBeforeDueDate: number;
  };
  [LeaseSettableCommunicationType.LEASE_CREDIT_NOTE]: BaseCommunicationSettingsFormValue;
  [LeaseSettableCommunicationType.LEASE_OPERATION_PAYMENT_REQUEST]: BaseCommunicationSettingsFormValue;
  [LeaseSettableCommunicationType.LEASE_PAYMENT_RECEIPT]: BaseCommunicationSettingsFormValue;

  // RENT PAYMENT REMINDERS
  [LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_REMINDER]: BaseCommunicationSettingsFormValue & {
    daysOverdue: number;
  };
  [LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_SECOND_REMINDER]: BaseCommunicationSettingsFormValue & {
    daysOverdue: number;
  };
  [LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_FORMAL_NOTICE]: BaseCommunicationSettingsFormValue & {
    daysOverdue: number;
  };
  [LeaseSettableCommunicationType.LEASE_UNPAID_CONSOLIDATED_PAYMENT_REMINDER]: BaseCommunicationSettingsFormValue;

  // SENDING OF STATEMENTS
  [LeaseSettableCommunicationType.LEASE_TENANT_STATEMENT]: BaseCommunicationSettingsFormValue;

  // CONTRACT TERMINATION/EXTENSION
  [LeaseSettableCommunicationType.LEASE_CONTRACT_EXTENDED]: BaseCommunicationSettingsFormValue;
  [LeaseSettableCommunicationType.LEASE_CONTRACT_REJECTED]: BaseCommunicationSettingsFormValue;
  [LeaseSettableCommunicationType.LEASE_CONTRACT_RETRACTED]: BaseCommunicationSettingsFormValue;
  [LeaseSettableCommunicationType.LEASE_CONTRACT_REVOKED]: BaseCommunicationSettingsFormValue;
  [LeaseSettableCommunicationType.LEASE_TERMINATION]: BaseCommunicationSettingsFormValue;

  // LEASE RENTAL ADAPTATION
  [LeaseSettableCommunicationType.LEASE_RENT_INDEXATION]: BaseCommunicationSettingsFormValue;
  [LeaseSettableCommunicationType.LEASE_RENT_INDEXATION_SKIPPED]: BaseCommunicationSettingsFormValue;
  [LeaseSettableCommunicationType.LEASE_RENTAL_ADAPTATION]: BaseCommunicationSettingsFormValue;
  [LeaseSettableCommunicationType.LEASE_CHARGES_ADJUSTMENT]: BaseCommunicationSettingsFormValue;

  // LEASE OPERATION MOVEMENTS
  [LeaseSettableCommunicationType.LEASE_DISCOUNT_CREATED]: BaseCommunicationSettingsFormValue;
  [LeaseSettableCommunicationType.LEASE_DISCOUNT_DELETED]: BaseCommunicationSettingsFormValue;
  [LeaseSettableCommunicationType.LEASE_DISCOUNT_UPDATED]: BaseCommunicationSettingsFormValue;
  [LeaseSettableCommunicationType.LEASE_OPERATION_CREATED]: BaseCommunicationSettingsFormValue;
  [LeaseSettableCommunicationType.LEASE_OPERATION_DELETED]: BaseCommunicationSettingsFormValue;
  [LeaseSettableCommunicationType.LEASE_OPERATION_UPDATED]: BaseCommunicationSettingsFormValue;

  // LEASE INFORMATION UPDATE
  [LeaseSettableCommunicationType.LEASE_ALLOW_PROFESSIONAL_ACTIVITY_ON]: BaseCommunicationSettingsFormValue;
  [LeaseSettableCommunicationType.LEASE_BANKING_INFO_UPDATE]: BaseCommunicationSettingsFormValue;

  // DOCUMENT SIGNATURES
  [LeaseSettableCommunicationType.DOCUMENT_SIGNATURE_REMINDER]: BaseCommunicationSettingsFormValue & {
    daysOverdue: number;
  };
  [LeaseSettableCommunicationType.DOCUMENT_SIGNED]: BaseCommunicationSettingsFormValue;
  [LeaseSettableCommunicationType.LEASE_CONTRACT_SIGNATURE_REMINDER]: BaseCommunicationSettingsFormValue & {
    daysOverdue: number;
  };
  [LeaseSettableCommunicationType.LEASE_CONTRACT_SIGNED]: BaseCommunicationSettingsFormValue;
}
export interface LeaseCommunicationSettingsProfileFormValues extends LeaseCommunicationSettingsFormValues {
  defaultName: string;
}

export const getLeaseCommunicationSettingsFromValues = (values: LeaseCommunicationSettingsProfileFormValues) => {
  const newLeaseSettings: CommunicationSettings[] = [];

  const addSettingsIfNeeded = (communicationType: LeaseSettableCommunicationType) => {
    const settings: CommunicationSettings = {
      communicationType: communicationType as unknown as CommunicationType,
      ...values[communicationType],
    };
    if (!areCommunicationSettingsDefault(settings, CommunicationSettingsProfileReferent.LEASE)) {
      newLeaseSettings.push(settings);
    }
  };

  Object.values(LeaseSettableCommunicationType).forEach((leaseSettableCommunicationType) => {
    addSettingsIfNeeded(leaseSettableCommunicationType);
  });

  return newLeaseSettings;
};

export const LeaseCommunicationSettingsSchema = Yup.object().shape({
  // RENT PAYMENT REQUESTS
  [LeaseSettableCommunicationType.LEASE_PAYMENT_REQUEST]: BasicSettingsSchema.concat(
    Yup.object().shape({
      daysBeforeDueDate: Yup.number().min(0).max(10).required(),
    })
  ),
  [LeaseSettableCommunicationType.LEASE_CREDIT_NOTE]: BasicSettingsSchema,
  [LeaseSettableCommunicationType.LEASE_OPERATION_PAYMENT_REQUEST]: BasicSettingsSchema,
  [LeaseSettableCommunicationType.LEASE_PAYMENT_RECEIPT]: BasicSettingsSchema,

  // RENT PAYMENT REMINDERS
  [LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_REMINDER]: BasicSettingsSchema.concat(
    Yup.object().shape({
      daysOverdue: Yup.number().min(0).required(),
    })
  ),
  [LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_SECOND_REMINDER]: BasicSettingsSchema.concat(
    Yup.object().shape({
      daysOverdue: Yup.number().min(0).required(),
    })
  ),
  [LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_FORMAL_NOTICE]: BasicSettingsSchema.concat(
    Yup.object().shape({
      daysOverdue: Yup.number().min(0).required(),
    })
  ),
  [LeaseSettableCommunicationType.LEASE_UNPAID_CONSOLIDATED_PAYMENT_REMINDER]: BasicSettingsSchema,

  // SENDING OF STATEMENTS
  [LeaseSettableCommunicationType.LEASE_TENANT_STATEMENT]: BasicSettingsSchema,

  // CONTRACT TERMINATION/EXTENSION
  [LeaseSettableCommunicationType.LEASE_CONTRACT_EXTENDED]: BasicSettingsSchema,
  [LeaseSettableCommunicationType.LEASE_CONTRACT_REJECTED]: BasicSettingsSchema,
  [LeaseSettableCommunicationType.LEASE_CONTRACT_RETRACTED]: BasicSettingsSchema,
  [LeaseSettableCommunicationType.LEASE_CONTRACT_REVOKED]: BasicSettingsSchema,
  [LeaseSettableCommunicationType.LEASE_TERMINATION]: BasicSettingsSchema,

  // LEASE RENTAL ADAPTATION
  [LeaseSettableCommunicationType.LEASE_RENT_INDEXATION]: BasicSettingsSchema,
  [LeaseSettableCommunicationType.LEASE_RENT_INDEXATION_SKIPPED]: BasicSettingsSchema,
  [LeaseSettableCommunicationType.LEASE_RENTAL_ADAPTATION]: BasicSettingsSchema,
  [LeaseSettableCommunicationType.LEASE_CHARGES_ADJUSTMENT]: BasicSettingsSchema,

  // LEASE OPERATION MOVEMENTS
  [LeaseSettableCommunicationType.LEASE_DISCOUNT_CREATED]: BasicSettingsSchema,
  [LeaseSettableCommunicationType.LEASE_DISCOUNT_DELETED]: BasicSettingsSchema,
  [LeaseSettableCommunicationType.LEASE_DISCOUNT_UPDATED]: BasicSettingsSchema,
  [LeaseSettableCommunicationType.LEASE_OPERATION_CREATED]: BasicSettingsSchema,
  [LeaseSettableCommunicationType.LEASE_OPERATION_DELETED]: BasicSettingsSchema,
  [LeaseSettableCommunicationType.LEASE_OPERATION_UPDATED]: BasicSettingsSchema,

  // LEASE INFORMATION UPDATE
  [LeaseSettableCommunicationType.LEASE_ALLOW_PROFESSIONAL_ACTIVITY_ON]: BasicSettingsSchema,
  [LeaseSettableCommunicationType.LEASE_BANKING_INFO_UPDATE]: BasicSettingsSchema,

  // DOCUMENT SIGNATURES
  [LeaseSettableCommunicationType.DOCUMENT_SIGNATURE_REMINDER]: BasicSettingsSchema.concat(
    Yup.object().shape({
      daysOverdue: Yup.number().min(0).required(),
    })
  ),
  [LeaseSettableCommunicationType.DOCUMENT_SIGNED]: BasicSettingsSchema,
  [LeaseSettableCommunicationType.LEASE_CONTRACT_SIGNATURE_REMINDER]: BasicSettingsSchema.concat(
    Yup.object().shape({
      daysOverdue: Yup.number().min(0).required(),
    })
  ),
  [LeaseSettableCommunicationType.LEASE_CONTRACT_SIGNED]: BasicSettingsSchema,
});

export const defaultLeaseSettings = Object.values(LeaseSettableCommunicationType).reduce(
  (acc, leaseSettableCommunicationType) => {
    const defaultSettings = getDefaultSetting(
      CommunicationSettingsProfileReferent.LEASE,
      leaseSettableCommunicationType as unknown as CommunicationType
    );
    acc[leaseSettableCommunicationType] = {
      sendingRule: defaultSettings.sendingRule,
      channel: defaultSettings.channel,
      recipients: defaultSettings.recipients,
      recipientsInBcc: defaultSettings.recipientsInBcc,
      recipientsInCopy: defaultSettings.recipientsInCopy,
    };
    if (!isNil(defaultSettings.daysBeforeDueDate)) {
      acc[leaseSettableCommunicationType]!.daysBeforeDueDate = defaultSettings.daysBeforeDueDate;
    }
    if (!isNil(defaultSettings.daysOverdue)) {
      acc[leaseSettableCommunicationType]!.daysOverdue = defaultSettings.daysOverdue;
    }
    return acc;
  },
  {} as {
    [key in LeaseSettableCommunicationType]?: {
      sendingRule: CommunicationSendingRule;
      channel: CommunicationChannel;
      recipients: CommunicationSettingRecipient[];
      recipientsInBcc: CommunicationSettingRecipient[];
      recipientsInCopy: string[];
      daysBeforeDueDate?: number | null;
      daysOverdue?: number | null;
    };
  }
) as LeaseCommunicationSettingsFormValues;

export const getFormValuesFromProfile = (leaseCommunicationSettingsProfile: CommunicationSettingsProfile) => {
  const initialSettings = Object.values(LeaseSettableCommunicationType).reduce(
    (acc, leaseSettableCommunicationType) => {
      const customSettings = getCommunicationSettings(
        leaseSettableCommunicationType as unknown as CommunicationType,
        leaseCommunicationSettingsProfile
      );
      acc[leaseSettableCommunicationType] = {
        sendingRule: customSettings.sendingRule,
        channel: customSettings.channel,
        recipients: customSettings.recipients,
        recipientsInBcc: customSettings.recipientsInBcc,
        recipientsInCopy: customSettings.recipientsInCopy,
      };
      if (!isNil(customSettings.daysBeforeDueDate)) {
        acc[leaseSettableCommunicationType]!.daysBeforeDueDate = customSettings.daysBeforeDueDate;
      }
      if (!isNil(customSettings.daysOverdue)) {
        acc[leaseSettableCommunicationType]!.daysOverdue = customSettings.daysOverdue;
      }
      return acc;
    },
    {} as {
      [key in LeaseSettableCommunicationType]?: {
        sendingRule: CommunicationSendingRule;
        channel: CommunicationChannel;
        recipients: CommunicationSettingRecipient[];
        recipientsInBcc: CommunicationSettingRecipient[];
        recipientsInCopy: string[];
        daysBeforeDueDate?: number | null;
        daysOverdue?: number | null;
      };
    }
  ) as LeaseCommunicationSettingsFormValues;

  const initialValues: LeaseCommunicationSettingsProfileFormValues = {
    defaultName: leaseCommunicationSettingsProfile.defaultName!,
    ...initialSettings,
  };

  return initialValues;
};
