/* eslint-disable @typescript-eslint/no-shadow */
import { Collapse, Divider, Grid, MenuItem, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Colors,
  CommunicationSettingsProfile,
  LeaseStatus,
  LooseObject,
  MessagesEN,
  TankState,
  TankType,
  TankWallType,
  Technic,
  TechnicType,
} from '@rentguru/commons-utils';
import { ActionButton, CustomizedSwitch, LoaderButton, TextDetailEditable } from '@up2rent/ui';
import { Form, Formik, FormikHelpers } from 'formik';
import { isEmpty } from 'lodash';
import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import FormikCustomizedSelect from 'src/components/ui/FormikCustomizedSelect';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { useBuildings } from '../../../hooks/BuildingsContext';
import { useTechnics } from '../../../hooks/TechnicsContext';
import { useUnits } from '../../../hooks/UnitsContext';
import FormikDatePicker from '../../ui/FormikDatePicker';
import { includeComplementaryDataSchema } from '../AddTechnicDialog';
import AddTechnicDialogComplementaryData, {
  AddTechnicDialogComplementaryDataFormValues,
} from '../AddTechnicDialogComplementaryData';
import { getLinks } from '../Heatings/AddHeatingForm';
import useTechnicsUtils from '../useTechnicsUtils';

interface AddTankProps {
  id: string;
  entity: 'building' | 'unit' | 'leaseBuilding' | 'leaseUnit';
  afterSubmit: (technics?: Technic[]) => void;
  cancelSubmit: () => void;
  showTitle?: boolean;
  showComplementaryData?: boolean;
  shouldRedirectAfterCreate?: boolean;
}

export interface TankValues extends AddTechnicDialogComplementaryDataFormValues {
  region?: string | null;
  tankType: TankType;
  tankCapacity?: number;
  tankState: TankState;
  tankLastControlDate: Date;
  tankWallType?: TankWallType | undefined;
  tankEncuvement?: boolean | undefined;
  tankLeakDetection?: boolean | undefined;
  tankInstallationDate?: Date | undefined;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& > *': {
        width: 280,
      },
    },
    fullWidth: {
      '& > *': {
        width: 580,
      },
    },
  })
);

const AddTankForm: React.FC<AddTankProps> = ({
  id,
  entity,
  afterSubmit,
  cancelSubmit,
  showTitle = true,
  showComplementaryData = false,
  shouldRedirectAfterCreate = false,
}) => {
  const { formatMessage } = useIntl();
  const { createTechnic } = useTechnics();
  const { getBuilding } = useBuildings();
  const { getUnit } = useUnits();
  const { getLease } = useLeases();
  const classes = useStyles();
  const unit = getUnit(id);
  const building = getBuilding(id);
  const region = building ? building?.address?.region : getBuilding(unit?.building?.id ?? '')?.address?.region;
  const { getPropertyId } = useTechnicsUtils();
  const history = useHistory();
  const { getDefaultTechnicCommunicationSettingsProfiles, loading: communicationSettingsProfilesLoading } =
    useCommunicationSettingsProfiles();
  const [technicCommunicationSettingsProfiles, setTechnicCommunicationSettingsProfiles] = useState<
    CommunicationSettingsProfile[]
  >([]);

  useEffect(() => {
    if (communicationSettingsProfilesLoading) {
      return;
    }
    const technicProfiles = getDefaultTechnicCommunicationSettingsProfiles();
    setTechnicCommunicationSettingsProfiles(technicProfiles);
  }, [getDefaultTechnicCommunicationSettingsProfiles, communicationSettingsProfilesLoading]);

  if (communicationSettingsProfilesLoading || isEmpty(technicCommunicationSettingsProfiles)) {
    return (
      <Grid container style={{ justifyContent: 'center' }}>
        <Typography style={{ margin: 20 }}>{formatMessage({ id: 'loading' })}</Typography>
      </Grid>
    );
  }

  // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
  const defaultTechnicProfile =
    !isEmpty(technicCommunicationSettingsProfiles) && technicCommunicationSettingsProfiles[0];

  const TankSchema = Yup.object().shape({
    tankType: Yup.string().required(),
    tankCapacity: Yup.number().required(),
    tankState: Yup.string().required(),
    tankLastControlDate: Yup.date().required(),
    tankWallType: Yup.string().when(['tankType', 'region', 'tankCapacity', 'tankState', 'tankLeakDetection'], {
      is: (val: (TankType | TankState | number | boolean | string)[]) => {
        return (
          (val[0] === TankType.AERIAL &&
            val[1] === MessagesEN.enums.AddressRegion.Wallonia &&
            (val[2] as number) > 3000) ||
          (val[0] === TankType.AERIAL &&
            val[1] === MessagesEN.enums.AddressRegion.Wallonia &&
            (val[2] as number) > 3000 &&
            val[3] === TankState.EXISTING) ||
          (val[0] === TankType.UNDERGROUND &&
            val[1] === MessagesEN.enums.AddressRegion.Wallonia &&
            (val[2] as number) > 3000 &&
            val[4] === true)
        );
      },
      then: Yup.string().required(),
      otherwise: Yup.string().nullable(),
    }),
    tankEncuvement: Yup.boolean().when(['tankType', 'region', 'tankCapacity', 'tankState', 'tankWallType'], {
      is: (val: (TankType | TankState | number | boolean | string)[]) => {
        return (
          (val[0] === TankType.AERIAL &&
            val[1] === MessagesEN.enums.AddressRegion.Wallonia &&
            (val[2] as number) > 3000 &&
            val[4] === TankWallType.SIMPLE) ||
          (val[0] === TankType.AERIAL &&
            val[1] === MessagesEN.enums.AddressRegion.Wallonia &&
            (val[2] as number) > 3000 &&
            val[3] === TankState.EXISTING &&
            val[4] === TankWallType.SIMPLE)
        );
      },
      then: Yup.boolean().required(),
      otherwise: Yup.boolean().nullable(),
    }),
    tankLeakDetection: Yup.boolean().when(['tankType', 'region', 'tankCapacity', 'tankState'], {
      is: (val: (TankType | TankState | number | boolean | string)[]) => {
        return (
          (val[0] === TankType.UNDERGROUND &&
            val[1] === MessagesEN.enums.AddressRegion.Wallonia &&
            (val[2] as number) > 3000) ||
          (val[0] === TankType.UNDERGROUND &&
            val[1] === MessagesEN.enums.AddressRegion.Wallonia &&
            val[3] === TankState.EXISTING)
        );
      },
      then: Yup.boolean().required(),
      otherwise: Yup.boolean().nullable(),
    }),
    tankInstallationDate: Yup.date().when(['tankType', 'region', 'tankCapacity', 'tankLeakDetection', 'tankWallType'], {
      is: (val: (TankType | TankState | number | boolean | string)[]) => {
        return (
          val[0] === TankType.UNDERGROUND &&
          val[1] === MessagesEN.enums.AddressRegion.Wallonia &&
          (val[2] as number) > 3000 &&
          val[3] === true &&
          val[4] === TankWallType.SIMPLE
        );
      },
      then: Yup.date().required(),
      otherwise: Yup.date().nullable(),
    }),
    region: Yup.string().nullable(),
  });

  const checkLeakDetectionValues = (values: TankValues) => {
    if (!values.tankCapacity) {
      return false;
    }
    if (
      (values.tankType === TankType.UNDERGROUND &&
        region === MessagesEN.enums.AddressRegion.Wallonia &&
        values.tankCapacity > 3000) ||
      (values.tankType === TankType.UNDERGROUND &&
        region === MessagesEN.enums.AddressRegion.Wallonia &&
        values.tankState === TankState.EXISTING)
    ) {
      if (isNil(values.tankLeakDetection)) {
        values.tankLeakDetection = false;
      }
      return true;
    }
    return false;
  };

  const checkTankEncuvementValues = (values: TankValues) => {
    if (!values.tankCapacity) {
      return false;
    }
    if (
      (values.tankType === TankType.AERIAL &&
        region === MessagesEN.enums.AddressRegion.Wallonia &&
        values.tankCapacity > 3000 &&
        values.tankWallType === TankWallType.SIMPLE) ||
      (values.tankType === TankType.AERIAL &&
        region === MessagesEN.enums.AddressRegion.Wallonia &&
        values.tankCapacity > 3000 &&
        values.tankState === TankState.EXISTING &&
        values.tankWallType === TankWallType.SIMPLE)
    ) {
      if (isNil(values.tankEncuvement)) {
        values.tankEncuvement = false;
      }
      return true;
    }
    return false;
  };

  const handleTankCreate = async (values: TankValues, { setSubmitting, setStatus }: FormikHelpers<TankValues>) => {
    const {
      region: _region,
      onBuilding: _onBuilding,
      tankLastControlDate,
      tankInstallationDate,
      ...remainingValues
    } = values;
    const unit = getUnit(values.unitId ?? '');
    const building = getBuilding(unit?.building?.id ?? '');
    const buildingId = building?.id;
    const leasesOfUnit = (unit?.leases ?? []).map((lease) => {
      return getLease(lease?.lease?.id ?? '');
    });
    let createdTechnics: Technic[] = [];

    const activeLease = leasesOfUnit.find((lease) => lease?.status === LeaseStatus.Active);
    const technics: Omit<Technic, 'id' | 'clientId' | 'readId'>[] = [
      {
        type: TechnicType.FUELTANK,
        // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
        communicationSettingsProfileId: defaultTechnicProfile ? defaultTechnicProfile.id : 'mockId',
        tankLastControlDate: tankLastControlDate.toISOString(),
        tankInstallationDate: !isNil(tankInstallationDate) ? tankInstallationDate.toISOString() : undefined,
        ...getLinks(values, id, entity, buildingId ?? ''),
        ...remainingValues,
      },
      ...(!values.onBuilding && !isNil(activeLease) && !entity.startsWith('lease')
        ? [
            {
              type: TechnicType.FUELTANK,
              // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
              communicationSettingsProfileId: defaultTechnicProfile ? defaultTechnicProfile.id : 'mockId',
              tankLastControlDate: tankLastControlDate.toISOString(),
              tankInstallationDate: !isNil(tankInstallationDate) ? tankInstallationDate.toISOString() : undefined,
              leaseId: activeLease.id,
              unitId: unit?.id ?? '',
              ...remainingValues,
            },
          ]
        : []),
    ];
    if (entity === 'unit' || entity === 'building') {
      createdTechnics = await Promise.all(technics.map((technic) => createTechnic(technic)));
    } else {
      technics.forEach((technic) => {
        (technic as LooseObject).id = `NEW-${uuidv4()}`;
      });
    }

    setStatus(true);
    setSubmitting(false);
    afterSubmit(technics as Technic[]);
    if (shouldRedirectAfterCreate) {
      const unit = getUnit(values.onBuilding ? '' : values.unitId ?? '');
      const unitId = `unit-${unit?.id}`;
      history.push({
        pathname: `${RouteDestination.PROPERTIES}/${getPropertyId()}/${!isNil(unit) ? unitId : 'technics'}/${
          !isNil(unit) ? 'technics' : ''
        }`,
        state: { id: createdTechnics?.[0].id ?? '', defaultTab: TechnicType.FUELTANK },
      });
    }
  };

  const finalSchema = includeComplementaryDataSchema(TankSchema, showComplementaryData);

  return (
    <Formik
      initialValues={
        {
          tankType: TankType.AERIAL,
          tankCapacity: undefined,
          tankState: TankState.NEW,
          tankLastControlDate: new Date(),
          region,
          onBuilding: ['building', 'leaseBuilding'].includes(entity),
          unitId: ['unit', 'leaseUnit'].includes(entity) ? id : '',
        } as TankValues
      }
      validationSchema={finalSchema}
      onSubmit={handleTankCreate}
    >
      {({ values, errors, touched, isSubmitting, status, setFieldValue }) => (
        <Form>
          {showTitle && (
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 25, marginBottom: 25 }}>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  textAlign: 'left',
                }}
              >
                {formatMessage({ id: 'technic.fuelTankTab.addFuelTank' })}
              </Typography>
            </div>
          )}
          <Divider style={{ marginBottom: 10 }} />
          <div style={{ alignItems: 'baseline', marginLeft: 20, marginRight: 20 }}>
            {showComplementaryData && (
              <AddTechnicDialogComplementaryData messageId="lease.detail.action.addTankExample" />
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormikCustomizedSelect
                label={formatMessage({
                  id: 'technic.fuelTankTab.tankType',
                })}
                error={Boolean(errors.tankType && touched.tankType)}
                value={values.tankType}
                FormControlProps={{ required: true }}
                className={classes.root}
                fieldName="tankType"
              >
                <MenuItem id="AERIAL" key="AERIAL" value={TankType.AERIAL}>
                  {formatMessage({ id: 'technic.fuelTankTab.aerial' })}
                </MenuItem>
                <MenuItem id="UNDERGROUND" key="UNDERGROUND" value={TankType.UNDERGROUND}>
                  {formatMessage({ id: 'technic.fuelTankTab.underground' })}
                </MenuItem>
              </FormikCustomizedSelect>

              <TextDetailEditable
                name="tankCapacity"
                title={formatMessage({ id: 'technic.fuelTankTab.tankCapacity' })}
                type="number"
                min={0}
                editMode={true}
                error={Boolean(errors.tankCapacity && touched.tankCapacity)}
                typeNumberNoEndArrow
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormikCustomizedSelect
                error={Boolean(errors.tankState && touched.tankState)}
                label={formatMessage({
                  id: 'technic.fuelTankTab.tankState',
                })}
                value={values.tankState}
                FormControlProps={{ required: true }}
                className={classes.root}
                fieldName="tankState"
              >
                <MenuItem id="NEW" key="NEW" value={TankState.NEW}>
                  {formatMessage({ id: 'technic.fuelTankTab.new' })}
                </MenuItem>
                <MenuItem id="EXISTING" key="EXISTING" value={TankState.EXISTING}>
                  {formatMessage({ id: 'technic.fuelTankTab.existing' })}
                </MenuItem>
              </FormikCustomizedSelect>

              <FormikDatePicker
                name="tankLastControlDate"
                label={formatMessage({ id: 'technic.lastControlledDate' })}
                value={values.tankLastControlDate}
                style={{ width: 280 }}
                error={Boolean(errors.tankLastControlDate && touched.tankLastControlDate)}
              />
            </div>
            <Collapse in={checkLeakDetectionValues(values)}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 10,
                  marginBottom: 10,
                  marginLeft: 100,
                  marginRight: 100,
                }}
              >
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                  {formatMessage({ id: 'technic.fuelTankTab.tankLeakDetection' })}
                </Typography>
                <CustomizedSwitch
                  name="tankLeakDetection"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('tankLeakDetection', event.target.checked);
                  }}
                />
              </div>
            </Collapse>
            <Grid container>
              <Collapse
                in={
                  !isNil(values.tankCapacity) &&
                  ((values.tankType === TankType.AERIAL &&
                    region === MessagesEN.enums.AddressRegion.Wallonia &&
                    values.tankCapacity > 3000) ||
                    (values.tankType === TankType.AERIAL &&
                      region === MessagesEN.enums.AddressRegion.Wallonia &&
                      values.tankCapacity > 3000 &&
                      values.tankState === TankState.EXISTING) ||
                    (values.tankType === TankType.UNDERGROUND &&
                      region === MessagesEN.enums.AddressRegion.Wallonia &&
                      values.tankCapacity > 3000 &&
                      values.tankLeakDetection === true))
                }
              >
                <Grid item xs={6} style={{ marginRight: 40 }}>
                  <FormikCustomizedSelect
                    label={formatMessage({ id: 'technic.fuelTankTab.tankWallType' })}
                    error={Boolean(errors.tankWallType && touched.tankWallType)}
                    className={classes.root}
                    value={values.tankWallType ? values.tankWallType : ''}
                    fieldName="tankWallType"
                  >
                    <MenuItem value={TankWallType.SIMPLE}>
                      {formatMessage({ id: 'technic.fuelTankTab.simple' })}
                    </MenuItem>
                    <MenuItem value={TankWallType.DOUBLE}>
                      {formatMessage({ id: 'technic.fuelTankTab.double' })}
                    </MenuItem>
                  </FormikCustomizedSelect>
                </Grid>
              </Collapse>
              <Collapse
                in={
                  !isNil(values.tankCapacity) &&
                  values.tankType === TankType.UNDERGROUND &&
                  region === MessagesEN.enums.AddressRegion.Wallonia &&
                  values.tankCapacity > 3000 &&
                  values.tankLeakDetection === true &&
                  values.tankWallType === TankWallType.SIMPLE
                }
              >
                <Grid item xs={6}>
                  <FormikDatePicker
                    name="tankInstallationDate"
                    label={formatMessage({ id: 'technic.fuelTankTab.tankInstallationDate' })}
                    value={new Date().toISOString()}
                    style={{ width: 280 }}
                    error={Boolean(errors.tankInstallationDate && touched.tankInstallationDate)}
                    required={false}
                  />
                </Grid>
              </Collapse>
            </Grid>
            <Collapse in={checkTankEncuvementValues(values)}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 10,
                  marginBottom: 10,
                  marginLeft: 100,
                  marginRight: 100,
                }}
              >
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                  {formatMessage({ id: 'technic.fuelTankTab.tankEncuvement' })}
                </Typography>
                <CustomizedSwitch
                  checked={values.tankEncuvement ? values.tankEncuvement : false}
                  name="tankEncuvement"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('tankEncuvement', event.target.checked);
                  }}
                />
              </div>
            </Collapse>
          </div>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <div
            style={{
              marginBottom: 20,
              marginRight: 30,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ActionButton
              onClick={cancelSubmit}
              style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
            >
              {toUpper(formatMessage({ id: 'cancel' }))}
            </ActionButton>
            <LoaderButton loading={isSubmitting} success={status}>
              {formatMessage({
                id: 'save',
              })}
            </LoaderButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddTankForm;
