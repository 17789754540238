import { Collapse, FormControl, FormControlLabel, Grid, MenuItem, RadioGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  Colors,
  DEFAULT_LANGUAGE,
  Lease,
  LeaseAction,
  LeaseExtended,
  LeaseType,
  Unit,
  getLocaleFromLanguage,
  getOverlappingEvents,
  getUTCDate,
  getUnitEvents,
  getUnitsOfLeaseExtended,
  isDateValid,
} from '@rentguru/commons-utils';
import { CustomizedSelect, TextDetailEditable } from '@up2rent/ui';
import { addDays, addMonths, addYears, differenceInCalendarMonths, differenceInYears, isAfter } from 'date-fns';
import { useFormikContext } from 'formik';
import { capitalize, isEmpty, toNumber } from 'lodash';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { CommunicationSettingsInfoRemark } from 'src/components/Communications/CommunicationSettingsInfoRemark';
import CustomCheckBox from 'src/components/ui/CustomCheckBox';
import CustomCheckBoxGroup, { CustomCheckBoxGroupContentProps } from 'src/components/ui/CustomCheckBoxGroup';
import FormikDatePicker from 'src/components/ui/FormikDatePicker';
import IconnedRemark from 'src/components/ui/IconnedRemark';
import StyledRadio from 'src/components/ui/StyledRadio';
import { useLeaseActionHistories } from 'src/hooks/LeaseActionHistoriesContext';
import { useLeaseVariousOperations } from 'src/hooks/LeaseVariousOperationsContext';
import { UnitContext, useUnits } from 'src/hooks/UnitsContext';
import { useUser } from 'src/hooks/UserContext';
import { ReactComponent as DropDown } from 'src/icons/drop-down.svg';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { getDetailedOverlappingError } from 'src/utils/unitsUtils';
import * as Yup from 'yup';
import ExtendUnitCheckBoxItem from './ExtendUnitCheckBoxItem';

export const getExtendLeaseSchema = (oldEndDate: string) =>
  Yup.object()
    .shape({
      action: Yup.string().required(),
      endDate: Yup.date()
        .required()
        .test((value) => isDateValid(value) && isAfter(value!, new Date(oldEndDate))),
      includeOperations: Yup.boolean().required(),
    })
    .required();

export type ExtendLeaseFormValues = {
  action: 'FOR' | 'TO';
  actionForSelection: 'MONTHS' | 'YEARS';
  endDate: Date;
  includeOperations: boolean;
  unitsToExtend: Unit[];
  overlappingEvents: { startDate: string; endDate: string }[];
  adjustCommercialPrice: boolean;
  adaptSetEndDate: boolean;
};

const getBaseExtendYears = (lease: Lease): number => {
  if (
    [LeaseType.MAIN_RESIDENCE_9_YEARS, LeaseType.MAIN_RESIDENCE_INFINITE, LeaseType.MAIN_RESIDENCE_LONG].includes(
      lease.type as LeaseType
    )
  ) {
    return 3;
  }
  if (LeaseType.COMMERCIAL === lease.type) {
    return 9;
  }

  // Default is 1 year for student, parking, short term
  return 1;
};
const useExtendLeaseStyles = makeStyles({
  actionRadioText: {
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: Colors.CLASSICAL_BLACK,
  },
  actionInnerCollapse: {
    backgroundColor: Colors.PORCELAIN_GREY_1,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 20,
  },
  infoMessageText: {
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: Colors.SLATE_GREY,
    textAlign: 'left',
  },
});

interface ExtendLeaseFormProps {
  lease: LeaseExtended;
}
export const getExtendLeaseInitialValues = (lease: LeaseExtended): ExtendLeaseFormValues => {
  const endDate = getUTCDate(new Date(lease.endDate));
  const baseExtendYears = getBaseExtendYears(lease);
  const newEndDate = addYears(endDate, baseExtendYears);

  const units: Unit[] = getUnitsOfLeaseExtended(lease);
  const unitsToExtend = units.filter((unit) => {
    const unitLease = lease.units?.find((currentUnitLease) => currentUnitLease.unit?.id === unit.id);

    return unitLease?.endDate === lease.endDate;
  });

  return {
    action: 'FOR',
    actionForSelection: 'YEARS',
    endDate: newEndDate,
    includeOperations: true,
    unitsToExtend,
    overlappingEvents: [],
    adjustCommercialPrice: false,
    adaptSetEndDate: true,
  };
};

export const isNumberOfExtendsReached = (startDate: Date, endDate: Date, leaseType: LeaseType) => {
  if (leaseType !== LeaseType.MAIN_RESIDENCE_SHORT) {
    return false;
  }

  // Date-fns differenceInYears is already returning 3 for same end day 3 years later.
  const durationFromStartToNewEndDate = differenceInYears(endDate, startDate);
  return durationFromStartToNewEndDate >= 3;
};

const getOverlappingEventsForActiveUnitsInLease = (
  lease: LeaseExtended,
  newEndDate: Date,
  unitsToExtend: Unit[],
  getUnit: UnitContext['getUnit']
) => {
  return unitsToExtend
    .map((unit) => {
      const unitLease = lease.units?.find((currentUnitLease) => currentUnitLease.unit?.id === unit.id);
      const events = getOverlappingEvents(
        new Date(unitLease!.endDate).toISOString(),
        newEndDate.toISOString(),
        getUnitEvents(unit.id, getUnit)
      );
      return events;
    })
    .flat();
};

const ExtendLeaseForm: React.FC<ExtendLeaseFormProps> = ({ lease }) => {
  const { formatMessage } = useIntl();
  const { language } = useUser();
  const locale = getLocaleFromLanguage(language ?? DEFAULT_LANGUAGE);
  const classes = useExtendLeaseStyles();
  const { values, setFieldValue, handleBlur, errors, touched } = useFormikContext<ExtendLeaseFormValues>();
  const { getUnit } = useUnits();
  const { getActiveLeaseVariousOperationsFromLease } = useLeaseVariousOperations();
  const { getLeaseActionHistoriesOfLease, loading: leaseActionHistoriesLoading } = useLeaseActionHistories();
  const leaseActionHistories = getLeaseActionHistoriesOfLease(lease.id);

  useEffect(() => {
    if (!isDateValid(values.endDate)) {
      return;
    }
    const overlappingEvents = getOverlappingEventsForActiveUnitsInLease(
      lease,
      values.endDate,
      values.unitsToExtend,
      getUnit
    );
    setFieldValue('overlappingEvents', overlappingEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.unitsToExtend, values.endDate]);

  const isUnitDisabled = (unit: Unit) => {
    const unitLease = lease.units?.find((currentUnitLease) => currentUnitLease.unit?.id === unit.id);
    return Boolean(unitLease?.mainUnit);
  };

  const renderUnitCheckBoxItem = (props: CustomCheckBoxGroupContentProps<Unit>) => (
    <ExtendUnitCheckBoxItem {...props} lease={lease} getUnit={getUnit} />
  );

  const units = getUnitsOfLeaseExtended(lease);
  const leaseVariousOperations = getActiveLeaseVariousOperationsFromLease(lease.id);

  const newEndDate = new Date(values.endDate);
  const durationDifference = differenceInCalendarMonths(newEndDate, getUTCDate(new Date(lease.endDate)));
  const durationValue =
    values.actionForSelection === 'YEARS' ? Math.floor(durationDifference / 12) : durationDifference;

  const shouldDisplayNumberOfExtendReached = isNumberOfExtendsReached(
    getUTCDate(new Date(lease.startDate)),
    newEndDate,
    lease.type as LeaseType
  );
  const shouldDisplayOperationsCheck = !isEmpty(leaseVariousOperations);
  const shouldDisplayAdjustEndDateCheckbox =
    !leaseActionHistoriesLoading &&
    leaseActionHistories.some((leaseActionHistory) => leaseActionHistory.action === LeaseAction.END_LEASE);

  const isCommercial = lease.type === LeaseType.COMMERCIAL;

  return (
    <Grid style={{ width: '100%' }}>
      <Typography
        style={{
          fontSize: 14,
          fontWeight: 'bold',
          fontStyle: 'normal',
          letterSpacing: 0,
          color: Colors.CLASSICAL_BLACK,
        }}
      >
        {formatMessage({ id: `lease.endExtendLease.extendLease` })}
      </Typography>
      <FormControl component="fieldset" style={{ paddingTop: 10, width: '100%' }}>
        <RadioGroup
          value={values.action}
          onChange={(_e, value) => {
            setFieldValue('action', value);
          }}
        >
          <FormControlLabel
            value="FOR"
            control={<StyledRadio />}
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.actionRadioText}>
                  {formatMessage({ id: `lease.endExtendLease.extendFor` })}
                </Typography>
                <TextDetailEditable
                  onChangeObserver={(value) => {
                    const cleanedValue = toNumber(value);
                    const months = values.actionForSelection === 'MONTHS' ? cleanedValue : cleanedValue * 12;

                    const endDate = getUTCDate(new Date(lease.endDate));
                    const updatedEndDate = addMonths(endDate, months);

                    setFieldValue('endDate', updatedEndDate);
                  }}
                  type="number"
                  min={1}
                  editMode={true}
                  style={{ width: 80, marginRight: 10, marginLeft: 10 }}
                  name="duration"
                  forcedValue={durationValue}
                  disabled={values.action === 'TO'}
                  title={formatMessage({ id: `lease.detail.variousOperations.duration` })}
                  error={Boolean(errors.endDate && touched.endDate) && values.action === 'FOR'}
                />
                <CustomizedSelect
                  fieldName="actionForSelection"
                  value={values.actionForSelection}
                  error={Boolean(errors.actionForSelection && touched.actionForSelection)}
                  FormControlProps={{ required: true, style: { width: 120 } }}
                  disabled={values.action === 'TO'}
                  SelectProps={{
                    onChange: (e) => {
                      const type = e.target.value as string;
                      setFieldValue('actionForSelection', type);
                    },
                    onBlur: handleBlur,
                  }}
                  IconComponent={DropDown}
                >
                  <MenuItem value="MONTHS">
                    {capitalize(formatMessage({ id: `lease.detail.variousOperations.months` }))}
                  </MenuItem>
                  <MenuItem value="YEARS">{capitalize(formatMessage({ id: `rentalUnit.filter.years` }))}</MenuItem>
                </CustomizedSelect>
              </div>
            }
          />

          <FormControlLabel
            value="TO"
            control={<StyledRadio />}
            label={
              <Grid style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.actionRadioText}>
                  {formatMessage({ id: `lease.endExtendLease.extendUntil` })}
                </Typography>
                <FormikDatePicker
                  name="endDate"
                  label={formatMessage({ id: 'lease.detail.general.endDate' })}
                  value={values.endDate}
                  style={{ width: 180, maxWidth: 180, marginLeft: 10 }}
                  error={Boolean(errors.endDate && touched.endDate) && values.action === 'TO'}
                  views={['year', 'month', 'date']}
                  minDate={addDays(new Date(lease.endDate), 1)}
                  disabled={values.action === 'FOR'}
                />
              </Grid>
            }
          />
        </RadioGroup>
      </FormControl>
      {shouldDisplayAdjustEndDateCheckbox && (
        <Grid style={{ marginTop: 10, marginBottom: 10 }}>
          <FormControlLabel
            control={
              <CustomCheckBox
                onCheck={(event) => {
                  setFieldValue(`adaptSetEndDate`, event.target.checked);
                }}
                override={{ style: { marginLeft: 2 } }}
                isChecked={values.adaptSetEndDate}
                checkedColor={Colors.DODGER_BLUE}
              />
            }
            label={
              <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                {formatMessage({ id: 'lease.endExtendLease.adjustEndDate' })}
              </Typography>
            }
          />
        </Grid>
      )}
      <Collapse in={shouldDisplayNumberOfExtendReached}>
        <IconnedRemark
          Icon={InfoSvg}
          noFixWidth
          style={{
            marginRight: 0,
            marginTop: 0,
            marginBottom: 10,
            marginLeft: 0,
          }}
          iconStyles={{ fill: Colors.CARNATION_RED }}
          message={
            <Typography className={classes.infoMessageText} style={{ color: Colors.CARNATION_RED }}>
              {formatMessage({ id: 'lease.endExtendLease.numberOfExtendsReachedWarning' })}
            </Typography>
          }
        />
      </Collapse>

      <Collapse in={!isEmpty(values.overlappingEvents) && units.length <= 1}>
        {values.overlappingEvents[0] && (
          <IconnedRemark
            Icon={InfoSvg}
            noFixWidth
            style={{
              marginRight: 0,
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 20,
              paddingLeft: 0,
              paddingBottom: 0,
              paddingRight: 0,
              backgroundColor: 'none',
            }}
            iconStyles={{ fill: Colors.CARNATION_RED }}
            message={
              <Typography className={classes.infoMessageText} style={{ color: Colors.CARNATION_RED }}>
                {getDetailedOverlappingError(values.overlappingEvents[0], formatMessage, locale)}
              </Typography>
            }
          />
        )}
      </Collapse>

      <Collapse in={shouldDisplayOperationsCheck}>
        <Typography
          style={{
            paddingTop: 20,
            fontSize: 14,
            fontWeight: 'bold',
            fontStyle: 'normal',
            letterSpacing: 0,
            color: Colors.CLASSICAL_BLACK,
          }}
        >
          {formatMessage({ id: `settings.variousOperationsMenu` })}
        </Typography>
        <Grid style={{ paddingTop: 10 }}>
          <FormControlLabel
            control={
              <CustomCheckBox
                onCheck={(event) => {
                  setFieldValue(`includeOperations`, event.target.checked);
                }}
                isChecked={values.includeOperations}
                secondary
              />
            }
            label={
              <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                {formatMessage({ id: 'lease.endExtendLease.servicesAndExceptionalCharges' })}
              </Typography>
            }
          />
        </Grid>
      </Collapse>

      {isDateValid(values.endDate) && units.length > 1 && (
        <>
          <Typography
            style={{
              paddingTop: 20,
              paddingBottom: 10,
              fontSize: 14,
              fontWeight: 'bold',
              fontStyle: 'normal',
              letterSpacing: 0,
              color: Colors.CLASSICAL_BLACK,
            }}
          >
            {formatMessage({ id: `lease.endExtendLease.pickUnit` })}
          </Typography>
          <CustomCheckBoxGroup
            items={units}
            itemsFieldName="unitsToExtend"
            selectedItems={values.unitsToExtend}
            isItemDisabled={isUnitDisabled}
            CustomCheckBoxGroupContentComponent={renderUnitCheckBoxItem}
          />
        </>
      )}

      <Collapse in={isCommercial}>
        <Typography
          style={{
            paddingTop: 20,
            paddingBottom: 10,
            fontSize: 14,
            fontWeight: 'bold',
            fontStyle: 'normal',
            letterSpacing: 0,
            color: Colors.CLASSICAL_BLACK,
          }}
        >
          {formatMessage({ id: `lease.endExtendLease.changeFinancialTerms` })}
        </Typography>
        <Grid>
          <FormControlLabel
            control={
              <CustomCheckBox
                onCheck={(event) => {
                  setFieldValue(`adjustCommercialPrice`, event.target.checked);
                }}
                isChecked={values.adjustCommercialPrice}
                secondary
              />
            }
            label={
              <Typography style={{ fontSize: 15, fontWeight: 500 }}>
                {formatMessage({ id: 'lease.endExtendLease.changeAmount' })}
              </Typography>
            }
          />
        </Grid>
      </Collapse>

      <CommunicationSettingsInfoRemark
        lease={lease}
        iconnedRemarkStyle={{
          marginRight: 0,
          marginTop: 20,
          marginBottom: 10,
          marginLeft: 0,
        }}
      />
      {[LeaseType.MAIN_RESIDENCE_LONG, LeaseType.MAIN_RESIDENCE_9_YEARS, LeaseType.MAIN_RESIDENCE_SHORT].includes(
        lease.type as LeaseType
      ) && (
        <IconnedRemark
          Icon={InfoSvg}
          noFixWidth
          style={{
            marginRight: 0,
            marginBottom: 10,
            marginLeft: 0,
          }}
          message={
            <Typography className={classes.infoMessageText}>
              {formatMessage({ id: 'settings.addVariousOperation.renewalTacitInformation' })}
            </Typography>
          }
        />
      )}
    </Grid>
  );
};

export default ExtendLeaseForm;
