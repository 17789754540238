/* eslint-disable @typescript-eslint/no-shadow */
import { cloneDeep, isEqual, isNil } from 'lodash';
import { useLeasePriceHistories } from 'src/hooks/LeasePriceHistoriesContext';
import {
  LeaseAmountUpdateStatus,
  LeaseMonthlyChargesType,
  LeasePriceHistoryStatus,
  LeasePriceHistoryType,
  UpdateLeasePriceHistoryInput,
  LeasePriceHistory,
  LeasePriceHistoryDetail,
  UnitLease,
  Lease,
  BodyDetailType,
  shouldUpdate,
} from '@rentguru/commons-utils';
import { IndexationFormValues, ClickActionType, getMinAdjustmentOrIndexationDate } from './leasePriceHistoryUtils';
import { useLeases } from 'src/hooks/LeasesContext';
import { isSameDay } from 'date-fns';
import { useFormikContext } from 'formik';

enum SourceType {
  ORIGINAL,
  FORMIK,
}

export const useIndexationFormikUtils = () => {
  const { values } = useFormikContext<IndexationFormValues>();
  const { getLease } = useLeases();
  const { getLeasePriceHistory } = useLeasePriceHistories();

  type LeasePriceHistoryDataFromFormikValuesType = {
    amountDetailsFromFormikValues: LeasePriceHistoryDetail[];
    leasePriceHistoryFromFormikValues: LeasePriceHistory;
    originalLeasePriceHistory: LeasePriceHistory | null | undefined;
    originalLeasePriceHistoryTypeChecks: {
      [LeasePriceHistoryType.ADJUSTMENT]: boolean;
      [LeasePriceHistoryType.INDEXATION]: boolean;
      [LeasePriceHistoryType.TERMS_CHANGE]: boolean;
      [LeasePriceHistoryType.INITIAL_PRICE]: boolean;
    };
    isDraft: boolean;
    lease: Lease;
    leasePriceHistoryFieldToSet: string;
    getOriginalAmountDetail: (unitLease: UnitLease) => LeasePriceHistoryDetail | undefined;
    getAmountDetailFromFormikValues: (unitLease: UnitLease) => LeasePriceHistoryDetail | undefined;
    getAmountDetailIndexFromFormikValues: (unitLease: UnitLease) => number;
    getAmountDetailFieldToSet: (unitLease: UnitLease) => string;
  };
  const getLeasePriceHistoryDataFromFormikValues = () => {
    const leasePriceHistoryIndexFromFormikValues = values.leasePriceHistories.findIndex(
      (leasePriceHistory) => leasePriceHistory.id === values.selectedId
    );

    const leasePriceHistoryFromFormikValues = values.leasePriceHistories[leasePriceHistoryIndexFromFormikValues];
    const originalLeasePriceHistory = getLeasePriceHistory(leasePriceHistoryFromFormikValues?.id ?? '');

    const originalLeasePriceHistoryTypeChecks = {
      [LeasePriceHistoryType.ADJUSTMENT]: originalLeasePriceHistory?.type === LeasePriceHistoryType.ADJUSTMENT,
      [LeasePriceHistoryType.INDEXATION]: originalLeasePriceHistory?.type === LeasePriceHistoryType.INDEXATION,
      [LeasePriceHistoryType.TERMS_CHANGE]: originalLeasePriceHistory?.type === LeasePriceHistoryType.TERMS_CHANGE,
      [LeasePriceHistoryType.INITIAL_PRICE]: originalLeasePriceHistory?.type === LeasePriceHistoryType.INITIAL_PRICE,
    };

    const isDraft = originalLeasePriceHistory?.status === LeaseAmountUpdateStatus.DRAFT;

    const lease = getLease(leasePriceHistoryFromFormikValues?.lease?.id ?? '');

    const amountDetailsFromFormikValues = leasePriceHistoryFromFormikValues?.amountDetails ?? [];

    const getAmountDetail = (unitLease: UnitLease, source: SourceType) => {
      const leasePriceHistory =
        source === SourceType.ORIGINAL ? originalLeasePriceHistory : leasePriceHistoryFromFormikValues;
      return (leasePriceHistory?.amountDetails ?? []).find(
        (amountDetail) => amountDetail.unitId === unitLease.unit?.id
      );
    };

    const getAmountDetailIndexFromFormikValues = (unitLease: UnitLease) => {
      return (leasePriceHistoryFromFormikValues?.amountDetails ?? []).findIndex(
        (amountDetail) => amountDetail.unitId === unitLease.unit?.id
      );
    };

    const getOriginalAmountDetail = (unitLease: UnitLease) => {
      return getAmountDetail(unitLease, SourceType.ORIGINAL);
    };
    const getAmountDetailFromFormikValues = (unitLease: UnitLease) => {
      return getAmountDetail(unitLease, SourceType.FORMIK);
    };

    const getAmountDetailFieldToSet = (unitLease: UnitLease) => {
      const amountDetailIndex = getAmountDetailIndexFromFormikValues(unitLease);
      return `leasePriceHistories[${leasePriceHistoryIndexFromFormikValues}].amountDetails[${amountDetailIndex}]`;
    };

    const leasePriceHistoryFieldToSet = `leasePriceHistories[${leasePriceHistoryIndexFromFormikValues}]`;

    return {
      amountDetailsFromFormikValues,
      leasePriceHistoryFromFormikValues,
      originalLeasePriceHistory,
      originalLeasePriceHistoryTypeChecks,
      isDraft,
      lease,
      leasePriceHistoryFieldToSet,
      getOriginalAmountDetail,
      getAmountDetailFromFormikValues,
      getAmountDetailFieldToSet,
      getAmountDetailIndexFromFormikValues,
    } as LeasePriceHistoryDataFromFormikValuesType;
  };
  return {
    getLeasePriceHistoryDataFromFormikValues,
  };
};
export const useIndexationUtils = () => {
  const { getLease } = useLeases();
  const { updateLeasePriceHistory } = useLeasePriceHistories();

  const orderLeasePriceHistoriesByYear = (leasePriceHistories: LeasePriceHistory[]) => {
    const orderedLeasePriceHistories = leasePriceHistories.reduce((acc, currentLeasePriceHistory) => {
      const applicationDate = new Date(currentLeasePriceHistory.applicationDate);
      const yearIndex = acc.findIndex(
        // Check first element to see if year is ok.
        (accElement) => new Date(accElement[0].applicationDate).getFullYear() === applicationDate.getFullYear()
      );
      // not yet in array
      if (yearIndex === -1) {
        acc.push([currentLeasePriceHistory]);
      } else {
        acc[yearIndex].push(currentLeasePriceHistory);
      }
      return acc;
    }, [] as LeasePriceHistory[][]);

    return orderedLeasePriceHistories;
  };

  const getRentalStatus = (
    type: BodyDetailType,
    amountDetail: LeasePriceHistoryDetail,
    historyType: LeasePriceHistoryType,
    clickedAction: ClickActionType,
    leaseId: string
  ) => {
    const lease = getLease(leaseId);
    const unitLease = lease?.units?.find((unit) => unit.unit!.id === amountDetail.unitId);
    if (isNil(unitLease)) {
      return;
    }
    const isRent = type === BodyDetailType.RENT;
    const currentStatus = isRent ? amountDetail.rentalStatus : amountDetail.monthlyChargesStatus;
    const newAmount = isRent ? amountDetail.newRentalPrice : amountDetail.newMonthlyChargesPrice;
    const maxIndexedValue = isRent
      ? amountDetail.rentIndexedPriceAfterNewEpbReduction ?? amountDetail.optimalNewRentalPrice
      : amountDetail.chargesIndexedPriceAfterNewEpbReduction ?? amountDetail.optimalNewMonthlyChargesPrice;

    if (
      [LeasePriceHistoryStatus.NON_INDEXED, LeasePriceHistoryStatus.NOT_APPLICABLE].includes(
        currentStatus as LeasePriceHistoryStatus
      )
    ) {
      return currentStatus;
    }

    if (clickedAction === ClickActionType.REFUSE) {
      return LeasePriceHistoryStatus.NON_INDEXED;
    }

    const isTermsChange = historyType === LeasePriceHistoryType.TERMS_CHANGE;
    if (newAmount === maxIndexedValue || isTermsChange) {
      return LeasePriceHistoryStatus.INDEXED;
    }
    return LeasePriceHistoryStatus.INDEXED_WITH_CUSTOM_AMOUNT;
  };

  const getComparableLeasePriceHistories = (values: IndexationFormValues, initialValues: IndexationFormValues) => {
    const valuesLeasePriceHistoryIndex = values.leasePriceHistories.findIndex(
      (leasePriceHistory) => leasePriceHistory.id === values.selectedId
    );

    const { ...selectedLeasePriceHistoryFromFormikValues } = cloneDeep(
      values.leasePriceHistories[valuesLeasePriceHistoryIndex]
    );
    const { ...initialSelectedLeasePriceHistory } = cloneDeep(
      initialValues.leasePriceHistories[valuesLeasePriceHistoryIndex]
    );
    delete selectedLeasePriceHistoryFromFormikValues.lease;
    delete initialSelectedLeasePriceHistory.lease;
    delete selectedLeasePriceHistoryFromFormikValues.communication;
    delete initialSelectedLeasePriceHistory.communication;
    return { selectedLeasePriceHistoryFromFormikValues, initialSelectedLeasePriceHistory };
  };

  const shouldLeasePriceHistoryBeUpdated = (
    values: IndexationFormValues,
    initialValues: IndexationFormValues,
    clickedAction: ClickActionType
  ) => {
    const { selectedLeasePriceHistoryFromFormikValues, initialSelectedLeasePriceHistory } =
      getComparableLeasePriceHistories(values, initialValues);

    const areAmountDetailsEqual = selectedLeasePriceHistoryFromFormikValues.amountDetails?.every(
      (amountDetail, index) => {
        return isEqual(amountDetail, initialSelectedLeasePriceHistory.amountDetails![index]);
      }
    );

    const valuesLeasePriceHistoryIndex = values.leasePriceHistories.findIndex(
      (leasePriceHistory) => leasePriceHistory.id === values.selectedId
    );
    const lease = values.leasePriceHistories[valuesLeasePriceHistoryIndex].lease!;
    const applicationDateFromFormikValues = new Date(selectedLeasePriceHistoryFromFormikValues.applicationDate);
    const minDate = getMinAdjustmentOrIndexationDate(
      selectedLeasePriceHistoryFromFormikValues,
      applicationDateFromFormikValues,
      lease
    );

    const isDateChanged = () => {
      const sameDateAsInitial = isSameDay(
        applicationDateFromFormikValues,
        new Date(initialSelectedLeasePriceHistory.applicationDate)
      );
      if (!sameDateAsInitial) {
        return !isSameDay(applicationDateFromFormikValues, minDate);
      }
      return false;
    };
    return (
      shouldUpdate(initialSelectedLeasePriceHistory, selectedLeasePriceHistoryFromFormikValues) ||
      isDateChanged() ||
      !areAmountDetailsEqual ||
      clickedAction === ClickActionType.ACCEPT ||
      clickedAction === ClickActionType.REFUSE
    );
  };

  const getUpdateStatus = (clickedAction: ClickActionType) => {
    if (clickedAction === ClickActionType.DRAFT) return LeaseAmountUpdateStatus.DRAFT;
    if (clickedAction === ClickActionType.ACCEPT) return LeaseAmountUpdateStatus.TO_PROCESS_CLIENT_ACCEPTED;
    return LeaseAmountUpdateStatus.TO_PROCESS_CLIENT_REFUSED;
  };

  const handleUpdateOrCreateLeasePriceHistories = async (
    values: IndexationFormValues,
    initialValues: IndexationFormValues,
    clickedAction: ClickActionType,
    leaseId: string
  ) => {
    if (clickedAction === ClickActionType.NONE) return;

    const { selectedLeasePriceHistoryFromFormikValues } = getComparableLeasePriceHistories(values, initialValues);
    if (
      selectedLeasePriceHistoryFromFormikValues.status !== LeaseAmountUpdateStatus.DRAFT ||
      !shouldLeasePriceHistoryBeUpdated(values, initialValues, clickedAction)
    ) {
      return;
    }
    const type = selectedLeasePriceHistoryFromFormikValues.type as LeasePriceHistoryType;

    const updates = {
      ...selectedLeasePriceHistoryFromFormikValues,
      status: getUpdateStatus(clickedAction),
      amountDetails: selectedLeasePriceHistoryFromFormikValues.amountDetails?.map((amountDetail) => {
        return {
          ...amountDetail,
          rentalStatus: getRentalStatus(BodyDetailType.RENT, amountDetail, type, clickedAction, leaseId),
          monthlyChargesStatus: getRentalStatus(BodyDetailType.CHARGES, amountDetail, type, clickedAction, leaseId),
          monthlyChargesType: amountDetail.monthlyChargesType as LeaseMonthlyChargesType,
        };
      }),
      type,
    } as UpdateLeasePriceHistoryInput;

    await updateLeasePriceHistory(updates);
  };

  const groupLeasePriceHistoryWithCorrespondingUnitFromUnitLease = (
    leasePriceHistory: LeasePriceHistory,
    unitLeases: UnitLease[]
  ) => {
    const groupedElements = (leasePriceHistory.amountDetails ?? []).reduce(
      (acc, currentAmountDetail) => {
        const accElement: {
          leasePriceHistory: LeasePriceHistory;
          amountDetail: LeasePriceHistoryDetail;
          id: string;
          leaseUnit: UnitLease | null | undefined;
        } = {
          leasePriceHistory,
          amountDetail: currentAmountDetail,
          leaseUnit: null,
          id: currentAmountDetail.unitId,
        };
        const correspondingUnitLease = unitLeases.find(
          (unitLease) => unitLease!.unit!.id === currentAmountDetail.unitId
        );
        if (!isNil(correspondingUnitLease)) {
          accElement.leaseUnit = correspondingUnitLease;
          acc.push(accElement);
        }
        return acc;
      },
      [] as {
        leasePriceHistory: LeasePriceHistory;
        amountDetail: LeasePriceHistoryDetail;
        id: string;
        leaseUnit: UnitLease | null | undefined;
      }[]
    );
    return groupedElements;
  };

  return {
    orderLeasePriceHistoriesByYear,
    groupLeasePriceHistoryWithCorrespondingUnitFromUnitLease,
    handleUpdateOrCreateLeasePriceHistories,
    shouldLeasePriceHistoryBeUpdated,
  };
};
