/* eslint-disable react/no-array-index-key */
import { Grid } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import {
  Colors,
  countries,
  getCountryCodeAndNameOfClient,
  getCountryCodeFromDialCode,
  getDialCodeCodeFromCountryCode,
  getSafeValueInObject,
} from '@rentguru/commons-utils';
import { CustomIconButton, TextDetailEditable } from '@up2rent/ui';
import { FormikProps, useFormikContext } from 'formik';
import { parsePhoneNumber, CountryCode as PhoneCountryCode, PhoneNumber } from 'libphonenumber-js';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { CountryCode, CountryResultToDisplay } from 'src/components/ui/CountrySelector';
import FormikCountrySelectorBox from 'src/components/ui/CountrySelector/FormikCountrySelector';
import useDeepCompareEffect from 'src/hooks/useDeepCompareEffect';
import { AddressFormValues } from './AddressFields';

export interface PhoneNumbersFieldsFormValues {
  phoneNumbers: { countryCode: string; number: string }[];
}
interface PhoneNumbersFieldsProps {
  noClearButton?: boolean;
  marginLeftRight?: number;
}

const PhoneNumbersFields: React.FC<PhoneNumbersFieldsProps> = ({ noClearButton = false, marginLeftRight }) => {
  const { values, errors, setFieldValue }: FormikProps<PhoneNumbersFieldsFormValues> = useFormikContext();
  const { formatMessage } = useIntl();

  const decreaseNumbers = (index: number) => {
    setFieldValue(
      'phoneNumbers',
      values.phoneNumbers.filter((_m, idx) => index !== idx)
    );
  };
  let valuesWithAddress = values as unknown as AddressFormValues;
  valuesWithAddress =
    !isNil(valuesWithAddress) && !isNil(valuesWithAddress.address) && !isNil(valuesWithAddress.address.country)
      ? valuesWithAddress
      : { address: { country: '' as CountryCode, box: '', city: '', number: '', postalCode: '', street: '' } };

  const setCountryForAllPhones = (countryCode: CountryCode) => {
    const dialNumber = getDialCodeCodeFromCountryCode(countryCode);
    if (!isNil(values.phoneNumbers)) {
      values.phoneNumbers.forEach((phoneNumber, index) => {
        if (isEmpty(phoneNumber.number) && phoneNumber.countryCode !== dialNumber) {
          setFieldValue(`phoneNumbers[${index}].countryCode`, dialNumber);
        }
      });
    }
  };

  useDeepCompareEffect(() => {
    if (!isNil(valuesWithAddress.address.country)) {
      setCountryForAllPhones(valuesWithAddress.address.country);
    }
  }, [valuesWithAddress.address.country]);

  useEffect(() => {
    let unmounted = false;

    const getCountryCodeAndSet = async () => {
      try {
        const countryObject = await getCountryCodeAndNameOfClient();
        if (!unmounted && countryObject.countryCode) {
          setCountryForAllPhones(countryObject.countryCode);
        }
      } catch (err) {
        // Don't show error
      }
    };
    getCountryCodeAndSet();
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {values.phoneNumbers &&
        values.phoneNumbers.map((_phoneNumber, index) => {
          return (
            <Grid
              container
              spacing={1}
              key={`phone${index}`}
              style={{
                display: 'flex',
                width: '100%',
                marginTop: 4,
                marginLeft: !isNil(marginLeftRight) ? marginLeftRight : 30,
                marginRight: !isNil(marginLeftRight) ? marginLeftRight : 30,
              }}
            >
              <Grid item xs={4} style={{ paddingLeft: 0 }}>
                <FormikCountrySelectorBox
                  placeholder={formatMessage({
                    id: 'contact.addContact.countryLabel',
                  })}
                  formStyle={{ minWidth: 0, width: '100%', marginBottom: errors.phoneNumbers ? 25 : 0 }}
                  fieldToReturn="dial_code"
                  name={`phoneNumbers[${index}].countryCode`}
                  selectedCountry={
                    getCountryCodeFromDialCode(
                      getSafeValueInObject(values['phoneNumbers'][index], `countryCode`)
                    ) as CountryCode
                  }
                  resultToDisplay={CountryResultToDisplay.DialCode}
                  error={Boolean(!isNil(errors.phoneNumbers) && errors.phoneNumbers[index])}
                />
              </Grid>
              <Grid item xs={noClearButton ? 8 : 7} style={{ paddingRight: 0 }}>
                <TextDetailEditable
                  editMode={true}
                  title={`${formatMessage({
                    id: 'contact.addContact.phoneLabel',
                  })} ${formatMessage({
                    id: 'optional',
                  })}`}
                  style={{ maxWidth: 'none' }}
                  name={`phoneNumbers[${index}].number`}
                  type="text"
                  onChangeObserver={(value) => {
                    const oldValue = values.phoneNumbers[index].number;
                    let countryCode = values.phoneNumbers[index].countryCode;
                    if (value.startsWith('+') && value.length >= 2) {
                      const countriesMatched = Object.values(countries).filter((country) =>
                        value.startsWith(country.dial_code)
                      );
                      const countryMatched = countriesMatched[0];
                      if (
                        countriesMatched.length === 1 &&
                        !isNil(countryMatched) &&
                        values.phoneNumbers[index].countryCode !== countryMatched.dial_code
                      ) {
                        setFieldValue(`phoneNumbers[${index}].countryCode`, countryMatched.dial_code);
                        countryCode = countryMatched.dial_code;
                      }
                    }
                    const country = getCountryCodeFromDialCode(countryCode) as PhoneCountryCode;
                    let parsedPhoneNumber: PhoneNumber | null;
                    try {
                      parsedPhoneNumber = parsePhoneNumber(value, country);
                      const numberFormated = parsedPhoneNumber.format('NATIONAL', { fromCountry: country });

                      if (
                        // Be sure user didnt remove a whitespace
                        parsedPhoneNumber.isValid() &&
                        /\s/g.test(numberFormated) &&
                        oldValue.replace(/\s/g, '') !== value.replace(/\s/g, '')
                      ) {
                        setFieldValue(`phoneNumbers[${index}].number`, numberFormated);
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  error={Boolean(!isNil(errors.phoneNumbers) && errors.phoneNumbers[index])}
                />
              </Grid>

              {!noClearButton && (
                <Grid item xs={1}>
                  <CustomIconButton
                    id="removePhoneNumber"
                    Icon={Clear}
                    style={{ color: Colors.BLUE_GREY, marginTop: 8, marginBottom: errors.phoneNumbers ? 25 : 0 }}
                    onClick={() => decreaseNumbers(index)}
                  />
                </Grid>
              )}
            </Grid>
          );
        })}
    </>
  );
};

export default PhoneNumbersFields;
