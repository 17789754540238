import { Grid, Typography } from '@material-ui/core';
import {
  DEFAULT_LANGUAGE,
  formatOperand,
  OperandType,
  OperationParameters,
  OperationType,
} from '@rentguru/commons-utils';
import React from 'react';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { calculationStyles } from '../../../leasePriceHistoryStyles';
import BasicCalculationFormula, { BasicCalculationOperator } from './BasicCalculationFormula';
import CorrectionFactorFormula from './CorrectionFactorFormula';
import IndexationMultiplicationFormula from './IndexationFormula';

export const OperationFormula: React.FC<OperationParameters> = ({
  operation,
  firstOperand,
  firstOperandType,
  secondOperand,
  secondOperandType,
  thirdOperand,
  thirdOperandType,
  textFormatted,
}) => {
  const { language = DEFAULT_LANGUAGE } = useLocale();
  const styles = calculationStyles();
  if (operation === OperationType.INDEXOPERATION) {
    const indexationFirstOperand = formatOperand(firstOperandType ?? OperandType.CURRENCY, firstOperand ?? 0, language);
    const indexationMultiplier = formatOperand(secondOperandType ?? OperandType.NONE, secondOperand ?? 0, language);
    const indexationDivider = formatOperand(thirdOperandType ?? OperandType.NONE, thirdOperand ?? 1, language);

    return (
      <IndexationMultiplicationFormula
        multipliedNumber={indexationFirstOperand}
        multiplier={indexationMultiplier}
        divider={indexationDivider}
      />
    );
  }

  if (operation === OperationType.CORRECTION_FACTOR_WALLONIA_075) {
    const correctionFactorFirstOperand = formatOperand(
      firstOperandType ?? OperandType.NONE,
      firstOperand ?? 0,
      language
    );
    const correctionFactorSecondOperand = formatOperand(
      secondOperandType ?? OperandType.NONE,
      secondOperand ?? 0,
      language
    );
    const correctionFactorMultiplier = formatOperand(OperandType.CORRECTION_FACTOR, 0.25, language);
    const correctionFactorSecondMultiplier = formatOperand(OperandType.NONE, 0.75, language);
    const correctionFactorDivider = formatOperand(thirdOperandType ?? OperandType.NONE, thirdOperand ?? 1, language);
    return (
      <CorrectionFactorFormula
        firstOperand={correctionFactorFirstOperand}
        secondOperand={correctionFactorSecondOperand}
        multiplier={correctionFactorMultiplier}
        secondMultiplier={correctionFactorSecondMultiplier}
        divider={correctionFactorDivider}
      />
    );
  }
  if (
    operation === OperationType.CORRECTION_FACTOR_FIRST_DEGREE ||
    operation === OperationType.CORRECTION_FACTOR_WALLONIA_05
  ) {
    const correctionFactorFirstOperand = formatOperand(
      firstOperandType ?? OperandType.NONE,
      firstOperand ?? 0,
      language
    );
    const correctionFactorSecondOperand = formatOperand(
      secondOperandType ?? OperandType.NONE,
      secondOperand ?? 0,
      language
    );
    const correctionFactorMultiplier = formatOperand(OperandType.NONE, 0.5, language);
    const correctionFactorDivider = formatOperand(thirdOperandType ?? OperandType.NONE, thirdOperand ?? 1, language);
    return (
      <CorrectionFactorFormula
        firstOperand={correctionFactorFirstOperand}
        secondOperand={correctionFactorSecondOperand}
        multiplier={correctionFactorMultiplier}
        divider={correctionFactorDivider}
      />
    );
  }
  if (
    operation === OperationType.CORRECTION_FACTOR_SECOND_DEGREE ||
    operation === OperationType.CORRECTION_FACTOR_WALLONIA_0
  ) {
    const correctionFactorFirstOperand = formatOperand(
      firstOperandType ?? OperandType.NONE,
      firstOperand ?? 0,
      language
    );
    const correctionFactorDivider = formatOperand(secondOperandType ?? OperandType.NONE, secondOperand ?? 1, language);
    return <CorrectionFactorFormula firstOperand={correctionFactorFirstOperand} divider={correctionFactorDivider} />;
  }
  if (operation === OperationType.MULTIPLICATION || operation === OperationType.SUBTRACTION) {
    const basicCalculationFirstOperand = formatOperand(
      firstOperandType ?? OperandType.NONE,
      firstOperand ?? 0,
      language
    );
    const basicCalculationSecondOperand = formatOperand(
      secondOperandType ?? OperandType.NONE,
      secondOperand ?? 0,
      language
    );

    return (
      <BasicCalculationFormula
        firstOperand={basicCalculationFirstOperand}
        secondOperand={basicCalculationSecondOperand}
        operator={
          operation === OperationType.MULTIPLICATION
            ? BasicCalculationOperator.MULTIPLICATION
            : BasicCalculationOperator.SUBTRACTION
        }
      />
    );
  }
  if (operation === OperationType.TEXT) {
    return <Typography className={styles.formulaText}>{textFormatted}</Typography>;
  }
  return <Grid />;
};

export default OperationFormula;
