import { downloadData } from 'aws-amplify/storage';
import { compact, first, isEmpty, isNil, last, uniqBy } from 'lodash';
import React, { Reducer, createContext, useContext, useEffect, useMemo, useReducer } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  CHARGE_TABLE_NAME,
  Contact,
  ContactType,
  DocumentCategory,
  DocumentCategoryWithoutReadIdAndClientId,
  DocumentModel,
  DownloadFoldersInput,
  EntityType,
  ExtendedSearchModel,
  FileCategory,
  File as FileModel,
  FolderBooleanAttributes,
  FolderDownloadOptions,
  FolderEntity,
  FolderErrors,
  FolderModel,
  FolderSortInvoiceOptions,
  getDownloadZipFileUrl as GetDownloadZipFileUrlMutation,
  GetDownloadZipFileUrlMutationVariables,
  INVOICE_TABLE_NAME,
  ROOT_PARENT_FOLDER_ID,
  S3Object,
  StatusResult,
  UNIT_OWNER_TABLE_NAME,
  UploadFile,
  calculateAllFolders,
  calculateEntityFolders,
  calculateUniqueCharges,
  canDelete,
  canDrag,
  checkFolderHasFiles,
  contactMostImportantType,
  filterCurrentLevelCategories,
  getAllFilesInCategory,
  getAllFilesInFolder,
  getCategoriesToWorkWith,
  getCategoryDeptArray,
  getContactNameFromObject,
  getFileNamesInFolder,
  getFilesInFolder,
  getFolderDepth,
  getFolderDepthString,
  getFolderDepthStringArray,
  getFoldersToReplace,
  getLeaseExtendedName,
  getMappedS3File,
  getParentFolder,
  getTableClientId,
  getTranslatedCategory,
  getTypedLanguage,
  getUniqueCategoryNameParameter,
  getUniqueFileName,
  getZipObjectForFolders,
  isNilOrEmpty,
  setFolderList,
} from '@rentguru/commons-utils';

import { CustomLabel } from 'src/models';

import { mutation } from '@up2rent/fetch-utils';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { useSnackbar } from 'notistack';
import { IntlFormatters } from 'react-intl';
import {
  GetUniqueFilesInputs,
  filterFoldersOnName,
  getUniqueFiles,
  useQuery,
} from 'src/components/Folders/FolderComponents/folderUtils';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { fetchChargesForFolders } from 'src/hooks/ChargesContext';
import { FetchByType } from 'src/hooks/CommunicationsContext';
import { ConfirmationDialogOptions, useConfirmationDialog } from 'src/hooks/ConfirmationDialogContext';
import { fetchUnitOwnersForFolders, useContacts } from 'src/hooks/ContactsContext';
import { useDocumentCategory } from 'src/hooks/FileCategoryContext';
import { getS3ObjectUrls, useFiles } from 'src/hooks/FilesContext';
import { fetchInvoicesForFolders, useInvoices } from 'src/hooks/InvoicesContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { useSignatureDocuments } from 'src/hooks/SignatureDocumentContext';
import { useTechnics } from 'src/hooks/TechnicsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { useUser } from 'src/hooks/UserContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { dateLocaleMap } from 'src/i18n/IntlProviderWrapper';
import { Action, FolderState, documentReducer, initialState } from './FolderReducer';

const basicCreateFolderObject: Partial<FolderModel> = {
  id: undefined,
  size: 0,
  updatedAt: '',
  documentModel: undefined,
};

export interface FoldersContext extends FolderState {
  calculatePageFolder: () => Promise<void>;
  renameFile: (folderToRename: FolderModel, newName: string) => void;
  renameFolder: (folder: FolderModel, newLabels: CustomLabel[]) => void;
  addFilesToFolder: (files: FileModel[], targetFolder: FolderModel, filenamesToReplace?: string[]) => Promise<void>;
  setFilteredFolders: (
    foldersToWorkWith: FolderModel[],
    documentsCategory?: DocumentCategoryWithoutReadIdAndClientId
  ) => void;
  setNavigation: (fromNavigation: boolean, folder?: FolderModel) => void;
  changeSelectedFolder: (add: boolean, folderId: string[]) => void;
  changeDragging: (dragging: boolean) => void;
  setSelectedFolders: (selected: boolean, folderIds: string[]) => void;
  deselectFolders: () => void;
  dropFolder: (
    targetFolderId: string,
    currentFolderId: string,
    formatMessage: IntlFormatters['formatMessage']
  ) => Promise<FolderErrors>;
  addNewFolder: (
    formatMessage: IntlFormatters['formatMessage'],
    labels: CustomLabel[],
    selectedCategory?: DocumentCategoryWithoutReadIdAndClientId,
    files?: File[],
    filesToReplace?: string[]
  ) => void;
  setDraggedFolders: (draggedFolders: string[]) => void;
  deleteFolders: (folderIds: string[], formatMessage: IntlFormatters['formatMessage']) => Promise<FolderErrors>;
  selectSearchFolder: (folder: FolderModel) => void;
  setIsBusy: (contextIsBusy: boolean) => void;
  changeVisibilityAllFolders: () => void;
  setIdAndType: (id: string, type: EntityType) => void;
  cleanup: () => void;
  getCategoriesForEntity: (entityType: EntityType) => DocumentCategoryWithoutReadIdAndClientId[];
  setSearchFolders: (searchFolders: FolderModel[]) => void;
  cancelSearch: () => void;
  setExtendSearchProperties: (extendSearchProperties: ExtendedSearchModel, searchText: string) => void;
  getListOfExtensionTypes: () => string[];
  setSortType: (sortType: string) => void;
  setSortInvoices: (sortInvoiceType: FolderSortInvoiceOptions) => void;
  downloadFolders: (
    selectedFolders: FolderModel[],
    downloadOption: FolderDownloadOptions,
    invoiceStructure?: boolean
  ) => Promise<{ result?: StatusResult; zippedData?: Record<string, Uint8Array> }>;
  calculateDownloadFolders: (selectedFoldersId: string[]) => {
    fullSelectedFolder: FolderModel[];
    downloadOption: FolderDownloadOptions;
  };
  setOnPage: (onPage: boolean) => void;
  setUploadFiles: (uploadFiles: UploadFile[]) => void;
}

// eslint-disable-next-line no-redeclare
export const FoldersContext = createContext<FoldersContext | null>(null);

export const DocumentsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { getTechnicsFor, technics, technicsLoading } = useTechnics();
  const { loading: signatureLoading, signatureDocuments } = useSignatureDocuments();
  const {
    fileCategories,
    createFileCategory,
    updateFileCategory,
    deleteFileCategory,
    loading: documentCategoryLoading,
  } = useDocumentCategory();
  const { clientId, userId, language } = useUser();
  const {
    deleteFile,
    createFile,
    updateFile,
    getFiles,
    shouldFetch,
    filesCollection,
    loading: filesLoading,
  } = useFiles();
  const { buildings, loading: buildingLoading } = useBuildings();
  const { leasesLoading, getLeaseFromUnitLease, leases } = useLeases();
  const { loading: unitsLoading } = useUnits();
  const { getUnit, units } = useUnits();
  const { contacts, contactsLoading } = useContacts();
  const { getConfirmation } = useConfirmationDialog();
  const { settingsManagementWrite } = usePermissions();
  const { loading: invoicesLoading } = useInvoices();
  const { enqueueSnackbar } = useSnackbar();

  const [state, dispatch] = useReducer<Reducer<FolderState, Action>>(documentReducer, initialState);

  const loadingOthers =
    (technicsLoading ?? false) ||
    signatureLoading ||
    buildingLoading ||
    unitsLoading ||
    (leasesLoading ?? false) ||
    contactsLoading ||
    documentCategoryLoading ||
    invoicesLoading ||
    filesLoading ||
    !isEmpty(shouldFetch);

  const query = useQuery();
  const urlDepth = query.get('depth') || null;

  useEffect(() => {
    const fetch = async () => {
      await fetchAll(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchEverything = async () => {
      await fetchAll(true);
    };
    if (!loadingOthers) {
      fetchEverything();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingOthers]);

  useEffect(() => {
    const calculate = async () => {
      if (state.onPage) {
        await calculatePageFolder();
      } else {
        if (state.id && state.entityType) {
          await calculateFolders(state.id, state.entityType);
        }
      }
    };

    if (
      state.isInitialLoading &&
      !isEmpty(state.files) &&
      !isEmpty(state.categories) &&
      !loadingOthers &&
      !isNil(state.onPage)
    ) {
      calculate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isInitialLoading, state.files, state.categories, loadingOthers, state.id, state.entityType, state.onPage]);

  useEffect(() => {
    const fetch = async () => {
      await fetchAllFiles(true);
    };
    if (!state.isInitialLoading) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesCollection]);

  useEffect(() => {
    const fetch = async () => {
      await fetchAllDocumentCategories(true);
    };
    if (!documentCategoryLoading && !state.isInitialLoading) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileCategories]);

  useEffect(() => {
    setFilteredFolders(state.folders, last(state.folderNavigation)?.documentCategory, last(state.folderNavigation));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.LOADING_OTHER_CONTEXTS], value: loadingOthers },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingOthers]);

  const fetchAllFiles = async (shouldDispatch: boolean) => {
    const entitiesArray = [
      EntityType.BUILDING,
      EntityType.UNIT,
      EntityType.LEASE,
      EntityType.CONTACT,
      EntityType.TECHNIC,
      EntityType.INVOICE,
      EntityType.CHARGE,
    ];

    const allFiles = entitiesArray.reduce<FileModel[]>((acc, entity) => {
      acc.push(...getFiles(entity));
      return acc;
    }, []);

    let allClientS3 = await getS3ObjectUrls(allFiles);
    if (!isNilOrEmpty(signatureDocuments)) {
      const filesWithSignatures = signatureDocuments.reduce<Set<string>>((acc, doc) => {
        if (doc.fileIds) {
          doc.fileIds.forEach((fileId) => acc.add(fileId));
        }
        return acc;
      }, new Set<string>());

      allClientS3 = allClientS3.map((file: DocumentModel) => {
        if (filesWithSignatures.has(file.id)) {
          file.hasSignature = true;
        }
        return file;
      });
    }

    const s3Files = entitiesArray.reduce<{ [key: string]: DocumentModel[] }>((acc, entity) => {
      acc[entity] = allClientS3.filter((file) => file.foreignTableName === entity && !isNil(file.category));
      return acc;
    }, {});

    if (shouldDispatch) {
      dispatch({
        type: 'SET_FILES',
        payload: {
          files: s3Files,
        },
      });
    }
  };

  const fetchAllDocumentCategories = async (shouldDispatch: boolean) => {
    const categoriesArray = [EntityType.BUILDING, EntityType.UNIT, EntityType.LEASE, EntityType.CONTACT];

    const allCategories = categoriesArray.reduce<{ [key: string]: DocumentCategoryWithoutReadIdAndClientId[] }>(
      (acc, entity) => {
        acc[entity] = fileCategories.reduce<DocumentCategoryWithoutReadIdAndClientId[]>((entityAcc, category) => {
          if (category.entity === entity || isNil(category.entity)) {
            entityAcc.push({
              ...category,
              entity: category.entity ?? entity,
            });
          }
          return entityAcc;
        }, []);
        return acc;
      },
      {}
    );
    if (shouldDispatch) {
      dispatch({
        type: 'SET_CATEGORIES',
        payload: {
          categories: allCategories,
        },
      });
    }
  };

  const fetchAll = async (shouldDispatch: boolean) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: true },
    });

    await fetchAllFiles(shouldDispatch);
    await fetchAllDocumentCategories(shouldDispatch);

    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
    });
  };

  const setIdAndType = (id: string, type: EntityType) => {
    dispatch({ type: 'SET_ID_AND_TYPE', payload: { id, type } });
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.INITIAL_LOADING, FolderBooleanAttributes.FOLDER_ADDING], value: true },
    });
  };

  const fetchEntitySpecificFiles = async (type: EntityType, id: string): Promise<DocumentModel[]> => {
    const s3Files: DocumentModel[] = [];

    if (type === EntityType.LEASE) {
      const leaseInvoices = await fetchInvoicesForFolders(FetchByType.BY_LEASE, id);
      leaseInvoices.forEach((invoice) => {
        const invoiceFiles = state.files[EntityType.INVOICE].filter((file) => file.foreignKey === invoice.id);
        s3Files.push(...invoiceFiles);
      });
    }

    if (type === EntityType.BUILDING || type === EntityType.UNIT) {
      const [charges, unitOwners] = await Promise.all([
        fetchChargesForFolders(getTableClientId(clientId!, CHARGE_TABLE_NAME)),
        fetchUnitOwnersForFolders(getTableClientId(clientId!, UNIT_OWNER_TABLE_NAME)),
      ]);

      const { buildingCharges: uniqueBuildingCharges, unitCharges: uniqueUnitCharges } = calculateUniqueCharges(
        charges,
        unitOwners
      );

      const relevantCharges = type === EntityType.BUILDING ? uniqueBuildingCharges : uniqueUnitCharges;

      const filteredCharges = relevantCharges.filter((charge) => charge.entityId === id);
      filteredCharges.forEach((charge) => {
        const chargeFiles = state.files[EntityType.CHARGE].filter((file) => file.id === charge.documentId);
        s3Files.push(...chargeFiles);
      });
    }

    return s3Files;
  };

  const calculateFolders = async (id: string, type: EntityType) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY, FolderBooleanAttributes.INITIAL_LOADING], value: true },
    });
    dispatch({ type: 'SET_FOLDER_ATTRIBUTES', payload: { key: [FolderBooleanAttributes.ON_PAGE], value: false } });
    dispatch({ type: 'SET_FOLDER_NAVIGATION', payload: [undefined] });
    dispatch({ type: 'SET_SELECTED_FOLDERS', payload: [] });

    const technicsForId = getTechnicsFor(id);

    const s3Files: DocumentModel[] = [];
    technicsForId.forEach((technic) => {
      s3Files.push(...state.files[EntityType.TECHNIC].filter((file) => file.foreignKey === technic.id));
    });

    s3Files.push(...(await fetchEntitySpecificFiles(type, id)));

    s3Files.push(...getDocumentsListForEntity(type).filter((file) => file.foreignKey === id)!);

    const mappedS3Files = s3Files.map((file) => getMappedS3File(file, getTypedLanguage(language)));

    dispatch({ type: 'INITIALIZE_FOLDERS', payload: [] });

    const folders = calculateEntityFolders(
      id,
      type,
      getCategoriesForEntity(type),
      mappedS3Files,
      getDocumentsListForEntity(EntityType.CONTACT),
      getCategoriesForEntity(EntityType.CONTACT),
      language,
      contacts
    );

    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.FOLDER_ADDING], value: false },
    });
    if (!isNil(folders)) {
      dispatch({ type: 'ADD_FOLDERS', payload: { foldersToAdd: folders, foldersToDelete: [] } });
      urlDepth ? getFoldersFromUrl(folders) : setFilteredFolders(folders);
      dispatch({
        type: 'SET_FOLDER_ATTRIBUTES',
        payload: { key: [FolderBooleanAttributes.INITIAL_LOADING], value: false },
      });
    }
  };

  const calculatePageFolder = async () => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: {
        key: [FolderBooleanAttributes.CONTEXT_IS_BUSY, FolderBooleanAttributes.INITIAL_LOADING],
        value: true,
      },
    });

    dispatch({ type: 'SET_FOLDER_NAVIGATION', payload: [undefined] });
    dispatch({ type: 'INITIALIZE_FOLDERS', payload: [] });

    const [invoices, charges, unitOwners] = await Promise.all([
      fetchInvoicesForFolders(FetchByType.BY_CLIENT, getTableClientId(clientId!, INVOICE_TABLE_NAME)),
      fetchChargesForFolders(getTableClientId(clientId!, CHARGE_TABLE_NAME)),
      fetchUnitOwnersForFolders(getTableClientId(clientId!, UNIT_OWNER_TABLE_NAME)),
    ]);

    const { folders, entityCategories } = calculateAllFolders(
      language,
      contacts,
      buildings,
      units,
      leases,
      technics,
      invoices,
      charges,
      unitOwners,
      state.categories,
      state.files
    );

    dispatch({ type: 'SET_ENTITY_DOCUMENT_CATEGORIES', payload: entityCategories });
    dispatch({ type: 'ADD_FOLDERS', payload: { foldersToAdd: folders, foldersToDelete: [] } });

    const foldersToUse = [...state.folders, ...folders];

    urlDepth ? getFoldersFromUrl(foldersToUse) : setFilteredFolders(foldersToUse);

    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: {
        key: [FolderBooleanAttributes.CONTEXT_IS_BUSY, FolderBooleanAttributes.INITIAL_LOADING],
        value: false,
      },
    });
  };

  const getFoldersFromUrl = (folders: FolderModel[]) => {
    const folderIds = urlDepth?.split('_');
    if (isNil(folderIds)) return;

    let currentParentFolderId: string = 'rootParentFolder';
    const foldersToNavigate = folderIds.reduce<FolderModel[]>((acc, folderId) => {
      const folder = folders.find((stateFolder) => stateFolder.entityId === folderId);
      if (isNil(folder)) {
        const customFolder = folders.find(
          (stateFolder) =>
            stateFolder.parentFolderId === currentParentFolderId &&
            stateFolder.documentCategory?.id === folderId &&
            stateFolder.folderEntity !== FolderEntity.FILE
        );
        if (isNil(customFolder)) return acc;
        acc.push(customFolder);
        return acc;
      }
      currentParentFolderId = folder.parentFolderId;
      acc.push(folder);
      return acc;
    }, [] as FolderModel[]);

    dispatch({ type: 'SET_FOLDER_NAVIGATION', payload: [...state.folderNavigation, ...foldersToNavigate] });
    setFilteredFolders(folders, last(foldersToNavigate)?.documentCategory, last(foldersToNavigate));
  };

  interface UpdateContacts {
    foldersToAdd: FolderModel[];
    foldersToDelete: FolderModel[];
  }

  const updateContactsWhenRenameFile = (
    parentFolder: FolderModel,
    documentToRename: DocumentModel,
    newFolder: FolderModel
  ): UpdateContacts => {
    const foldersToAdd: FolderModel[] = [];
    const foldersToDelete: FolderModel[] = [];
    const jointFolders = state.folders.filter(
      (stateFolder) => stateFolder.entityId === parentFolder?.entityId && stateFolder.id !== parentFolder?.id
    );

    jointFolders.forEach((jointFolder) => {
      const folderToDelete = state.folders.find(
        (stateFolder) =>
          stateFolder.documentModel?.id === documentToRename.id && stateFolder.parentFolderId === jointFolder.id
      );
      foldersToDelete.push(folderToDelete!);
      const depthString = `${jointFolder.folderDepthString}/${jointFolder.translatedLabel}`;
      const depth = `${jointFolder.folderDepth}.${jointFolder.documentCategory.id}`;

      foldersToAdd.push({
        ...newFolder,
        id: uuidv4(),
        folderDepth: newFolder.folderDepth?.replace(
          `${parentFolder?.folderDepth}.${parentFolder.documentCategory.id}`,
          depth
        ),
        folderDepthString: newFolder.folderDepthString?.replace(
          `${parentFolder?.folderDepthString}/${parentFolder.translatedLabel}`,
          depthString
        ),
      });
    });

    return { foldersToAdd, foldersToDelete };
  };

  const renameFile = async (folderToRename: FolderModel, newName: string) => {
    if (isNil(folderToRename.documentModel)) return;

    const documentToRename = folderToRename.documentModel;
    if (isNil(documentToRename.mimeType)) {
      return;
    }

    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: true },
    });

    const newFile = { ...documentToRename, fileName: newName };

    const blobRes = await downloadData({ key: documentToRename.key }).result;
    const blobToCopy = await blobRes.body.blob();
    const newFileToUpload = new File([blobToCopy], encodeURIComponent(newName), {
      type: documentToRename.mimeType,
    });

    const parentFolder = getParentFolder(state.folders, folderToRename);
    const entityType = state.onPage ? (parentFolder?.documentCategory.entity as EntityType) : state.entityType;
    const entityId = state.onPage ? parentFolder?.entityId : state.id;

    await createFile(
      newFileToUpload,
      entityType!,
      entityId!,
      documentToRename.category!.id,
      undefined,
      state.contact ? contactMostImportantType(state.contact) : undefined,
      state.contact ? state.contact.id : undefined
    );
    await deleteFile(documentToRename);

    const newDocuments = getDocumentsListForEntity(state.entityType!).map((document) => {
      if (document.id === documentToRename.id) {
        return newFile;
      }
      return document;
    });
    const foldersToAdd: FolderModel[] = [];
    const foldersToDelete: FolderModel[] = [];

    const changedFolder = state.folders.find(
      (stateFolder) =>
        stateFolder.documentModel?.id === documentToRename.id &&
        stateFolder.parentFolderId === folderToRename.parentFolderId
    );
    foldersToDelete.push(changedFolder!);

    const newFolder = { ...changedFolder, documentModel: newFile, translatedLabel: newName } as FolderModel;
    foldersToAdd.push(newFolder);

    if (parentFolder?.folderEntity === FolderEntity.CONTACT) {
      const updatedFolders = updateContactsWhenRenameFile(parentFolder, documentToRename, newFolder);
      foldersToAdd.push(...updatedFolders.foldersToAdd);
      foldersToDelete.push(...updatedFolders.foldersToDelete);
    }

    dispatch({ type: 'UPDATE_FILES', payload: { files: newDocuments, type: state.entityType! } });
    dispatch({
      type: 'ADD_FOLDERS',
      payload: {
        foldersToAdd,
        foldersToDelete,
      },
    });

    const filteredFolders = state.folders.filter((stateFolder) => !foldersToDelete.includes(stateFolder));
    filteredFolders.push(...foldersToAdd);

    setFilteredFolders(filteredFolders, last(state.folderNavigation)?.documentCategory, last(state.folderNavigation));
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
    });
  };

  const getParentFolderOfAddedFiles = (
    targetFolder: FolderModel,
    folderCategoryIdsToCheck: string[]
  ): FolderModel | undefined => {
    const foldersToCheck = compact(
      folderCategoryIdsToCheck.map((folderCategoryId) => {
        return state.folders.find(
          (stateFolder) =>
            stateFolder.documentCategory?.id === folderCategoryId &&
            stateFolder.parentFolderId === targetFolder?.parentFolderId &&
            stateFolder.folderEntity !== FolderEntity.FILE
        );
      })
    );
    return foldersToCheck.find((folder) => folder.id === targetFolder.parentFolderId);
  };

  const calculateFoldersToAdd = (
    parentFolder: FolderModel,
    targetFolder: FolderModel,
    folderCategoryIdsToCheck: string[],
    s3Files: DocumentModel[]
  ): FolderModel[] => {
    const index = folderCategoryIdsToCheck?.findIndex((folderId) => folderId === parentFolder.documentCategory?.id);
    folderCategoryIdsToCheck = folderCategoryIdsToCheck?.slice(0, index);

    const foldersToAdd = s3Files.map((file) => {
      const fileName = decodeURIComponent(file.fileName);
      const translatedCategory = getTranslatedCategory(file.category!, getTypedLanguage(language));

      const category = file.category;
      if (!isNil(category) && isNil(category.parentId)) {
        file.category = { ...category, parentId: parentFolder.documentCategory.id };
      }

      return {
        ...basicCreateFolderObject,
        folderEntity: FolderEntity.FILE,
        id: uuidv4(),
        size: file.size ?? 0,
        updatedAt: file.updatedAt ?? '',
        translatedLabel: fileName,
        documentModel: {
          ...file,
          fileName,
          canDrag: canDrag(file.category!.fileCategory!),
          translatedCategory,
        },
        documentCategory: file.category!,
        folderDepth: targetFolder ? `${targetFolder.folderDepth}.${targetFolder.documentCategory?.id}` : 'rootFolder',
        folderDepthString: targetFolder
          ? `${targetFolder.folderDepthString}/${targetFolder.translatedLabel}`.replace('//', '/')
          : '/',
        parentFolderId: targetFolder.parentFolderId ?? ROOT_PARENT_FOLDER_ID,
      } as FolderModel;
    });

    const size = s3Files.reduce<number>((acc, file) => {
      return acc + (file.size ?? 0);
    }, 0);

    folderCategoryIdsToCheck?.forEach((folderCategoryId) => {
      const folder = state.folders.find(
        (stateFolder) =>
          stateFolder.documentCategory?.id === folderCategoryId &&
          stateFolder.parentFolderId === parentFolder.parentFolderId &&
          stateFolder.folderEntity !== FolderEntity.FILE
      );
      if (!isNil(folder)) return;
      const category = getCategoriesForEntity(parentFolder.documentCategory.entity as EntityType).find(
        (categoryForEntity) => categoryForEntity.id === folderCategoryId
      );
      if (isNil(category)) return;
      const categoryDepthArray = getCategoryDeptArray(
        category!,
        getCategoriesForEntity(parentFolder.documentCategory.entity as EntityType)
      );

      let categoryDepth = !isNil(parentFolder)
        ? `${parentFolder.folderDepth}.${parentFolder.documentCategory.id}`
        : 'rootfolder.';
      let categoryDepthString = !isNil(parentFolder)
        ? `${parentFolder.folderDepthString}/${parentFolder.translatedLabel}`
        : 'rootfolder.';
      if (!isNil(categoryDepthArray) && !isNil(category.parentId)) {
        categoryDepthArray.forEach((categoryId) => {
          categoryDepth += `.${categoryId}`;
          const categoryToWorkWith = getCategoriesForEntity(parentFolder.documentCategory.entity as EntityType).find(
            (categoryForEntity) => categoryForEntity.id === categoryId
          );
          categoryDepthString += `/${getTranslatedCategory(categoryToWorkWith!, getTypedLanguage(language))}`;
        });
      }

      const newFolder = {
        ...basicCreateFolderObject,
        id: uuidv4(),
        folderEntity: FolderEntity.FOLDER,
        size,
        translatedLabel: getTranslatedCategory(category!, getTypedLanguage(language)),
        documentCategory: {
          ...category,
          parentId: !isNil(category.parentId) ? category.parentId : parentFolder.documentCategory.id,
        },
        folderDepth: categoryDepth,
        folderDepthString: categoryDepthString.replace('//', '/'),
        parentFolderId: parentFolder.parentFolderId ?? ROOT_PARENT_FOLDER_ID,
      } as FolderModel;
      foldersToAdd.push(newFolder);
    });
    return foldersToAdd;
  };

  const addedFilesOnContacts = (parentFolder: FolderModel, foldersToAdd: FolderModel[]): FolderModel[] => {
    const jointFolders = state.folders.filter(
      (folder) => folder.entityId === parentFolder.entityId && folder.id !== parentFolder.id
    );

    const jointFoldersToAdd = jointFolders.reduce<FolderModel[]>((acc, jointFolder) => {
      const depthString = `${jointFolder.folderDepthString}/${jointFolder.translatedLabel}`;
      const depth = `${jointFolder.folderDepth}.${jointFolder.documentCategory.id}`;
      const newFoldersToAdd = foldersToAdd.map((file) => {
        return {
          ...file,
          id: uuidv4(),
          folderDepth: file.folderDepth?.replace(
            `${parentFolder.folderDepth}.${parentFolder.documentCategory.id}`,
            depth
          ),
          folderDepthString: file.folderDepthString?.replace(
            `${parentFolder.folderDepthString}/${parentFolder.translatedLabel}`,
            depthString
          ),
          parentFolderId: jointFolder.id,
          documentCategory: {
            ...file.documentCategory,
            parentId:
              file.documentCategory.parentId === parentFolder.documentCategory.id
                ? jointFolder.documentCategory.id
                : file.documentCategory.parentId,
          },
        };
      });
      acc.push(...newFoldersToAdd);
      return acc;
    }, []);
    return jointFoldersToAdd;
  };

  const AddFilesToFolderWhenOnPage = (
    targetFolder: FolderModel,
    s3Files: DocumentModel[],
    foldersToDelete: FolderModel[],
    filesToDelete: FileModel[]
  ) => {
    const folderCategoryIdsToCheck = targetFolder.folderDepth?.split('.');
    folderCategoryIdsToCheck.push(targetFolder.documentCategory?.id ?? '');
    folderCategoryIdsToCheck.reverse();

    const parentFolder = getParentFolderOfAddedFiles(targetFolder, folderCategoryIdsToCheck);

    if (isNil(parentFolder)) return;
    const foldersToAdd = calculateFoldersToAdd(parentFolder, targetFolder, folderCategoryIdsToCheck, s3Files);

    if (parentFolder.folderEntity === FolderEntity.CONTACT) {
      foldersToAdd.push(...addedFilesOnContacts(parentFolder, foldersToAdd));
    }

    dispatch({
      type: 'ADD_FOLDERS',
      payload: {
        foldersToAdd,
        foldersToDelete,
      },
    });

    const filteredFolders = [...state.folders, ...foldersToAdd].filter((folder) => !foldersToDelete.includes(folder));
    const lastFolderInNavigation = last(state.folderNavigation);
    setFilteredFolders(filteredFolders, lastFolderInNavigation?.documentCategory, lastFolderInNavigation);

    dispatch({
      type: 'UPDATE_FILES',
      payload: {
        files: [...getDocumentsListForEntity(state.entityType!), ...s3Files].filter(
          (file) => filesToDelete.findIndex((fileToDelete) => fileToDelete.id === file.id) === -1
        ),
        type: parentFolder.documentCategory.entity as EntityType,
      },
    });
  };

  const deleteFilesToReplace = async (
    targetFolder: FolderModel,
    filesToReplace: string[]
  ): Promise<{ foldersToDelete: FolderModel[]; files: FileModel[] }> => {
    const foldersToDelete: FolderModel[] = [];
    const foldersToReplace = getFoldersToReplace(targetFolder, state.folders, filesToReplace);
    const filesToDelete = foldersToReplace.reduce((acc, folder) => {
      const folderToDelete = state.folders.find((stateFolder) => stateFolder.id === folder);
      if (isNil(folderToDelete)) return acc;
      foldersToDelete.push(folderToDelete);
      acc.push(deleteFile(folderToDelete.documentModel!));
      return acc;
    }, [] as Promise<FileModel>[]);

    const files = await Promise.all(filesToDelete);

    return { foldersToDelete, files };
  };

  const addFilesToFolder = async (files: FileModel[], targetFolder: FolderModel, filesToReplace?: string[]) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: true },
    });
    const foldersToDelete: FolderModel[] = [];
    const filesToDelete: FileModel[] = [];

    if (!isNil(filesToReplace)) {
      const toDelete = await deleteFilesToReplace(targetFolder, filesToReplace);
      foldersToDelete.push(...toDelete.foldersToDelete);
      filesToDelete.push(...toDelete.files);
    }

    const s3Files = await getS3ObjectUrls(files);

    if (state.onPage) {
      AddFilesToFolderWhenOnPage(targetFolder, s3Files, foldersToDelete, filesToDelete);
      return;
    }

    const foldersToAdd = s3Files.map((file) => {
      const fileName = decodeURIComponent(file.fileName);
      const translatedCategory = getTranslatedCategory(file.category!, getTypedLanguage(language));

      return {
        ...basicCreateFolderObject,
        id: uuidv4(),
        folderEntity: FolderEntity.FILE,
        size: file.size ?? 0,
        updatedAt: file.updatedAt ?? '',
        translatedLabel: fileName,
        documentModel: {
          ...file,
          fileName,
          canDrag: canDrag(file.category!.fileCategory!),
          translatedCategory,
        },
        documentCategory: file.category!,
        folderDepth: targetFolder ? `${targetFolder.folderDepth}.${targetFolder.documentCategory.id}` : 'rootFolder',
        folderDepthString: targetFolder
          ? `${targetFolder.folderDepthString}/${targetFolder.translatedLabel}`.replace('//', '/')
          : '/',
        parentFolderId: targetFolder.parentFolderId ?? ROOT_PARENT_FOLDER_ID,
      } as FolderModel;
    });

    //If the targetfolder is not in the state folder, it means that it is an invisible folder and need to be added
    const checkTargetFolder = state.folders.find((stateFolder) => stateFolder.id === targetFolder.id);

    if (isNil(checkTargetFolder)) {
      foldersToAdd.push(targetFolder);
    }

    dispatch({
      type: 'ADD_FOLDERS',
      payload: {
        foldersToAdd,
        foldersToDelete,
      },
    });

    const filteredFolders = [...state.folders, ...foldersToAdd].filter((folder) => !foldersToDelete.includes(folder));
    const lastFolderInNavigation = last(state.folderNavigation);
    setFilteredFolders(filteredFolders, lastFolderInNavigation?.documentCategory, lastFolderInNavigation);

    dispatch({
      type: 'UPDATE_FILES',
      payload: {
        files: [...getDocumentsListForEntity(state.entityType!), ...s3Files].filter(
          (file) => filesToDelete.findIndex((fileToDelete) => fileToDelete.id === file.id) === -1
        ),
        type: targetFolder?.documentCategory.entity as EntityType,
      },
    });
  };

  const calculateFolderInfo = (
    foldersToWorkWith: FolderModel[],
    folder: FolderModel,
    folderDepth?: string
  ): { size: number; uploadedAt: string } =>
    foldersToWorkWith.reduce<{ size: number; uploadedAt: string }>(
      (acc, stateFolder) => {
        if (
          !isNil(folder.documentCategory) &&
          stateFolder.folderDepth?.includes(folderDepth ?? '') &&
          folder.parentFolderId === stateFolder.parentFolderId &&
          stateFolder.folderEntity === FolderEntity.FILE
        ) {
          // Only update size and check uploadedAt for matching folders
          acc.size += stateFolder.size;
          if (stateFolder.updatedAt > acc.uploadedAt) {
            acc.uploadedAt = stateFolder.updatedAt;
          }
        }
        // Simply return accumulator without creating new object
        return acc;
      },
      { size: 0, uploadedAt: '' }
    );

  const setFilteredFolders = (
    foldersToWorkWith: FolderModel[],
    documentsCategory?: DocumentCategoryWithoutReadIdAndClientId,
    folderModel?: FolderModel,
    categories?: DocumentCategoryWithoutReadIdAndClientId[]
  ): void => {
    dispatch({ type: 'SET_CATEGORY_FOLDERS', payload: { folders: [] } });
    const folderModelDepth = getFolderDepthStringArray(folderModel?.folderDepth ?? '');

    const filteredFolders = foldersToWorkWith.filter((folder) => {
      const rootCategory = isNil(documentsCategory) ? folder.folderDepth === 'rootFolder' : false;
      let folderCheck = false;
      if (!rootCategory && !isNil(documentsCategory)) {
        const folderDepth = getFolderDepthStringArray(folder.folderDepth);
        let entity = true;
        if (!isNil(folderModel) && folder.folderDepthString) {
          entity = folder.folderDepth.includes(folderModel.folderDepth ?? '');
        }

        folderCheck = Boolean(
          last(folderDepth)?.includes(documentsCategory.id) &&
            entity &&
            folderModelDepth.length + 1 === folderDepth.length
        );
      }

      return rootCategory || folderCheck;
    });

    const foldersToDelete: FolderModel[] = [];
    const mappedFilteredFolders = filteredFolders.map((folder) => {
      const folderDepth = `${folder.folderDepth}.${folder.documentCategory?.id}`;
      if (!(folder.folderEntity === FolderEntity.FOLDER)) {
        return folder;
      }

      const folderInfos = calculateFolderInfo(foldersToWorkWith, folder, folderDepth);

      if (folderInfos.size === 0) {
        foldersToDelete.push(folder);
        return;
      }

      return {
        ...folder,
        size: folderInfos.size,
        updatedAt: folderInfos.uploadedAt,
      };
    });

    if (state.seeAllFolders) {
      const parentFolder = getParentFolder(state.folders, folderModel);
      changeCategoryFolders(compact(mappedFilteredFolders), parentFolder, folderModel, undefined, categories);
    }

    dispatch({ type: 'SET_FILTERED_FOLDERS', payload: compact(mappedFilteredFolders) });
    dispatch({ type: 'ADD_FOLDERS', payload: { foldersToAdd: [], foldersToDelete } });
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
    });
  };

  const setDraggedFolders = (draggedFolders: string[]) => {
    dispatch({ type: 'SET_DRAGGED_FOLDERS', payload: draggedFolders });
  };

  const setNavigation = (fromNavigation: boolean, folder?: FolderModel): void => {
    dispatch({ type: 'SET_SELECTED_FOLDERS', payload: [] });
    if (fromNavigation) {
      const index = state.folderNavigation.findIndex((folderNavigation) => folderNavigation?.id === folder?.id);
      const newNavigation = state.folderNavigation.slice(0, index + 1);
      dispatch({ type: 'SET_FOLDER_NAVIGATION', payload: newNavigation });
      const lastNewNavigationFolder = last(newNavigation);
      setFilteredFolders(state.folders, lastNewNavigationFolder?.documentCategory, lastNewNavigationFolder);
      return;
    }

    if (state.searchFolders) {
      selectSearchFolder(folder!);
      return;
    }
    dispatch({ type: 'SET_FOLDER_NAVIGATION', payload: [...state.folderNavigation, folder] });

    setFilteredFolders(state.folders, folder?.documentCategory, folder);
  };

  const changeSelectedFolder = (add: boolean, folderId: string[]) => {
    dispatch({
      type: 'CHANGE_SELECTED_FOLDERS',
      payload: {
        selectedFoldersToAdd: add ? folderId : [],
        selectedFoldersToDelete: add ? [] : folderId,
      },
    });
  };

  const changeDragging = (dragging: boolean) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.FOLDER_DRAGGING], value: dragging },
    });
  };

  const setSelectedFolders = (selected: boolean, folderIds: string[]) => {
    if (selected) {
      dispatch({ type: 'SET_SELECTED_FOLDERS', payload: folderIds });
    } else {
      dispatch({ type: 'SET_SELECTED_FOLDERS', payload: [] });
    }
  };

  const deselectFolders = () => {
    dispatch({ type: 'SET_SELECTED_FOLDERS', payload: [] });
  };

  const addFileOnDropAction = async (
    targetFolder: FolderModel | undefined,
    file: DocumentModel,
    entityType: EntityType,
    newName?: string
  ): Promise<S3Object> => {
    const key = newName ? file.key.replace(file.fileName, newName) : file.key;
    const updatedFile = await updateFile(file, { categoryId: targetFolder?.documentCategory?.id, key });
    const s3Files = await getS3ObjectUrls([updatedFile]);
    const canDragFile = updatedFile.category ? canDrag(updatedFile.category.fileCategory!) : false;
    const translatedCategory = updatedFile.category
      ? getTranslatedCategory(updatedFile.category, getTypedLanguage(language))
      : '';
    const updatedList = getDocumentsListForEntity(state.entityType!).map((doc) => {
      if (doc.id === file.id) {
        return { ...s3Files[0], canDrag: canDragFile, translatedCategory };
      }
      return doc;
    });

    dispatch({
      type: 'UPDATE_FILES',
      payload: {
        files: updatedList,
        type: entityType,
      },
    });
    return s3Files[0];
  };

  const updateFolders = (
    foreignKey: string,
    entityFolder: FolderModel,
    folderDepth: string,
    folderDepthString: string,
    newCategories: DocumentCategoryWithoutReadIdAndClientId[],
    entityType: EntityType
  ) => {
    const foldersToDelete: FolderModel[] = [];
    const files = compact(
      state.folders.map((folder) => {
        if (folder.documentModel?.foreignKey === foreignKey) {
          foldersToDelete.push(folder);
          return folder.documentModel!;
        }
        if (
          folder.documentCategory.entity === entityType &&
          folder.folderDepth?.includes(entityFolder.documentCategory.id)
        ) {
          foldersToDelete.push(folder);
        }
      })
    );
    dispatch({ type: 'ADD_FOLDERS', payload: { foldersToAdd: [], foldersToDelete } });
    const categoriesToWorkWith = [
      ...getCategoriesToWorkWith(newCategories, entityFolder.documentCategory.id),
      entityFolder.documentCategory,
    ];
    if (isEmpty(files)) return;
    setFolderList(
      [],
      files,
      categoriesToWorkWith,
      language,
      entityFolder?.documentCategory.id,
      folderDepth,
      folderDepthString,
      entityFolder.parentFolderId,
      entityFolder?.documentCategory.id
    );
  };

  const updateBuildingsFolders = (
    newCategories: DocumentCategoryWithoutReadIdAndClientId[],
    currentContextFolder: FolderModel
  ) => {
    buildings.forEach((building) => {
      const buildingFolder = state.folders.find((folder) => folder.entityId === building.id);
      if (isNil(buildingFolder) || buildingFolder.id === currentContextFolder.id) return;
      updateFolders(
        building.id,
        buildingFolder,
        `rootFolder.${buildingFolder?.documentCategory.id}`,
        `/${building.name}`,
        newCategories,
        EntityType.BUILDING
      );
    });
  };

  const updateUnitFolders = (
    newCategories: DocumentCategoryWithoutReadIdAndClientId[],
    currentContextFolder: FolderModel
  ) => {
    buildings.forEach((building) => {
      const buildingFolder = state.folders.find((folder) => folder.entityId === building.id);
      if (isNil(buildingFolder)) return;
      building.units?.forEach((unit) => {
        const unitFolder = state.folders.find((folder) => folder.entityId === unit.id);
        if (isNil(unitFolder) || unitFolder.id === currentContextFolder.id) return;
        updateFolders(
          unit.id,
          unitFolder,
          `rootFolder.${buildingFolder.documentCategory.id}.${unitFolder?.documentCategory.id}`,
          `/${building.name}/${unit.name}`,
          newCategories,
          EntityType.UNIT
        );
      });
    });
  };

  const updateLeaseFolders = (
    newCategories: DocumentCategoryWithoutReadIdAndClientId[],
    currentContextFolder: FolderModel
  ) => {
    buildings.forEach((building) => {
      const buildingFolder = state.folders.find((folder) => folder.entityId === building.id);
      if (isNil(buildingFolder)) return;
      building.units?.forEach((unit) => {
        const unitFolder = state.folders.find((folder) => folder.entityId === unit.id);
        if (isNil(unitFolder)) return;
        const pageUnits = getUnit(unit.id);
        pageUnits?.leases?.forEach((unitLease) => {
          const lease = getLeaseFromUnitLease(unitLease.id);
          if (isNil(lease)) return;
          const leaseFolder = state.folders.find((folder) => folder.entityId === unitLease.leaseId);
          if (isNil(leaseFolder) || leaseFolder.id === currentContextFolder.id) return;
          updateFolders(
            unitLease.leaseId,
            leaseFolder,
            // eslint-disable-next-line max-len
            `rootFolder.${buildingFolder.documentCategory.id}.${unitFolder?.documentCategory.id}.${leaseFolder.documentCategory.id}`,
            `/${building.name}/${unit.name}/${getLeaseExtendedName(lease, dateLocaleMap[getTypedLanguage(language)!])}`,
            newCategories,
            EntityType.LEASE
          );
        });
      });
    });
  };

  const updateContacts = (
    newCategories: DocumentCategoryWithoutReadIdAndClientId[],
    currentContextFolder: FolderModel
  ) => {
    const filteredContacts = contacts.filter((contact) =>
      contact.types.some((contactType) =>
        [
          ContactType.CONTRACTOR,
          ContactType.GUARANTOR,
          ContactType.JOINT_OWNERSHIP,
          ContactType.OWNER,
          ContactType.TENANT,
        ].includes(contactType)
      )
    );
    filteredContacts.forEach((contact) => {
      const filteredContactFolder = state.folders.filter((folder) => folder.entityId === contact.id);
      filteredContactFolder.forEach((contactFolder) => {
        if (isNil(contactFolder) || contactFolder.id === currentContextFolder.id) {
          return;
        }
        setContactFiles(contact, contactFolder, newCategories, currentContextFolder);
      });
    });
  };

  const setContactFiles = (
    contact: Contact,
    currentContactFolder: FolderModel,
    newCategories: DocumentCategoryWithoutReadIdAndClientId[],
    currentContextFolder: FolderModel
  ) => {
    const foldersToDelete: FolderModel[] = [];
    const files = compact(
      state.folders.map((folder) => {
        if (
          folder.documentModel?.foreignKey === contact.id &&
          folder.parentFolderId === currentContactFolder.parentFolderId
        ) {
          foldersToDelete.push(folder);
          return folder.documentModel!;
        }
        if (
          folder.documentCategory.entity === EntityType.CONTACT &&
          folder.folderDepth?.includes(currentContactFolder.documentCategory.id) &&
          folder.parentFolderId !== currentContextFolder.id &&
          (folder.folderEntity === FolderEntity.FOLDER || folder.folderEntity === FolderEntity.FILE)
        ) {
          foldersToDelete.push(folder);
        }
      })
    );

    dispatch({ type: 'ADD_FOLDERS', payload: { foldersToAdd: [], foldersToDelete } });
    const categoriesToWorkWith = [
      ...getCategoriesToWorkWith(newCategories, currentContactFolder.documentCategory.id),
      currentContactFolder.documentCategory,
    ];
    if (isEmpty(files)) return;
    setFolderList(
      [],
      uniqBy(files, 'id'),
      categoriesToWorkWith,
      language,
      currentContactFolder.documentCategory.id,
      `${currentContactFolder.folderDepth}.${currentContactFolder.documentCategory.id}`,
      `${currentContactFolder.folderDepthString}/${getContactNameFromObject(contact)}`,
      currentContactFolder.parentFolderId,
      currentContactFolder.documentCategory.id
    );
  };

  const localUpdateCategories = (
    newCategories: DocumentCategoryWithoutReadIdAndClientId[],
    entityType: EntityType,
    currentContextFolder: FolderModel
  ) => {
    if (entityType === EntityType.BUILDING) {
      updateBuildingsFolders(newCategories, currentContextFolder);
      return;
    }
    if (entityType === EntityType.UNIT) {
      updateUnitFolders(newCategories, currentContextFolder);
    }
    if (entityType === EntityType.LEASE) {
      updateLeaseFolders(newCategories, currentContextFolder);
    }
    if (entityType === EntityType.CONTACT) {
      updateContacts(newCategories, currentContextFolder);
    }
  };

  const whenDroppedAFolder = async (
    folder: FolderModel,
    clientFolder: FolderModel,
    targetFolder: FolderModel,
    entityType: EntityType,
    currentFolder: FolderModel
  ): Promise<
    | {
        foldersToAdd: FolderModel[];
        foldersToDelete: FolderModel[];
        newCategories: DocumentCategoryWithoutReadIdAndClientId[];
      }
    | undefined
  > => {
    const uniqueCategoryParameter = getUniqueCategoryParameter(
      folder.documentCategory.labels,
      entityType,
      targetFolder.documentCategory
    );

    const documentLabels = folder.documentCategory.labels.map((label) => {
      return {
        language: getTypedLanguage(label.language),
        label: uniqueCategoryParameter !== 0 ? `${label.label} (${uniqueCategoryParameter})` : label.label,
      };
    });

    const foldersToDelete: FolderModel[] = [];
    const newCategories: DocumentCategoryWithoutReadIdAndClientId[] = [];
    const stateFoldersToDelete = state.folders.filter(
      (stateFolder) =>
        !isNil(folder.documentCategory) &&
        (!isNil(clientFolder)
          ? stateFolder.parentFolderId === folder.parentFolderId
          : stateFolder.parentFolderId === ROOT_PARENT_FOLDER_ID) &&
        stateFolder.folderDepth?.includes(folder.documentCategory.id)
    );
    if (isNil(folder.documentCategory)) return;
    const updatedDocumentCategory = await updateFileCategory(folder.documentCategory, {
      parentId: targetFolder?.folderEntity === FolderEntity.FOLDER ? targetFolder?.documentCategory?.id : undefined,
      labels: documentLabels,
    });

    const entityCategories = getCategoriesForEntity(entityType);
    const newEntityCategoryIndex = entityCategories.findIndex((category) => category.id === updatedDocumentCategory.id);
    entityCategories[newEntityCategoryIndex] = updatedDocumentCategory;
    newCategories.push(...entityCategories);

    dispatch({ type: 'UPDATE_CATEGORY', payload: { category: entityCategories, type: entityType } });
    foldersToDelete.push(folder);
    if (isEmpty(stateFoldersToDelete)) return { foldersToAdd: [], foldersToDelete, newCategories };
    const foldersToAdd = stateFoldersToDelete.map((folderToDelete) => {
      const folderDepth = folderToDelete.folderDepth?.replace(
        currentFolder.folderDepth,
        targetFolder ? getFolderDepth(targetFolder) : state.onPage ? getFolderDepth(clientFolder!) : 'rootFolder'
      );
      const folderDepthString = folderToDelete.folderDepthString
        ?.replace(
          currentFolder.folderDepthString!,
          (targetFolder
            ? `${getFolderDepthString(getTypedLanguage(language), targetFolder)}/`
            : state.onPage
            ? `${getFolderDepthString(getTypedLanguage(language), clientFolder)}/`
            : '/'
          ).replace('//', '/')
        )
        .replace('//', '/');
      return { ...folderToDelete, folderDepth, folderDepthString };
    });
    const folderToAdd: FolderModel = {
      ...basicCreateFolderObject,
      id: uuidv4(),
      folderEntity: FolderEntity.FOLDER,
      translatedLabel: getTranslatedCategory(updatedDocumentCategory, getTypedLanguage(language)),
      documentCategory: updatedDocumentCategory,
      folderDepth: targetFolder
        ? getFolderDepth(targetFolder)
        : state.onPage
        ? getFolderDepth(clientFolder!)
        : 'rootFolder',
      folderDepthString: (targetFolder
        ? getFolderDepthString(getTypedLanguage(language), targetFolder)
        : state.onPage
        ? getFolderDepthString(getTypedLanguage(language), clientFolder!)
        : '/'
      ).replace('//', '/'),
      parentFolderId: clientFolder?.parentFolderId ?? ROOT_PARENT_FOLDER_ID,
    } as FolderModel;

    foldersToAdd.push(folderToAdd);
    foldersToDelete.push(...stateFoldersToDelete);
    return { foldersToAdd, foldersToDelete, newCategories };
  };

  const droppedFolderLinkedToContact = (
    clientFolder: FolderModel,
    newCategories: DocumentCategoryWithoutReadIdAndClientId[]
  ): UpdateContacts => {
    const foldersToDelete: FolderModel[] = [];
    const foldersToAdd: FolderModel[] = [];
    let contactId = state.id;
    if (state.onPage) {
      const contactToTest = contacts.find((contact) => contact.id === clientFolder?.id);
      if (!isNil(contactToTest)) {
        if (contactMostImportantType(contactToTest) === ContactType.JOINT_OWNERSHIP) {
          contactId = clientFolder?.id;
        } else {
          const currentJointContactFolder = state.folders.find(
            (folder) => folder.documentCategory.id === clientFolder?.documentCategory.parentId
          );
          if (!isNil(currentJointContactFolder) && currentJointContactFolder.entityId !== 'contacts') {
            contactId = currentJointContactFolder.entityId!;
          }
        }
      }
    }

    const contact = contacts.find((stateContact) => stateContact.id === contactId);
    if (isNil(contact) || contactMostImportantType(contact) !== ContactType.JOINT_OWNERSHIP)
      return { foldersToAdd, foldersToDelete };

    const jointFolders = state.folders.filter(
      (stateFolder) =>
        contact.jointOwners?.some((jointOwner) => jointOwner.contactId === stateFolder.entityId!) &&
        stateFolder.id !== clientFolder?.id
    );

    const jointFoldersToAdd = jointFolders.reduce<FolderModel[]>((acc, jointFolder) => {
      const foldersToChange = state.folders.filter(
        (stateFolder) =>
          stateFolder.parentFolderId === jointFolder.id &&
          [FolderEntity.FOLDER, FolderEntity.FILE].includes(stateFolder.folderEntity)
      );

      foldersToDelete.push(...foldersToChange);

      const newFoldersToAdd = foldersToChange.map((file) => {
        const categoryDepthArray = getCategoryDeptArray(file.documentCategory, newCategories).reduce<string>(
          (prevCategories, category) => {
            if (file.documentCategory.id === category) {
              return prevCategories;
            }
            if (isEmpty(prevCategories)) {
              return category;
            }
            return `${prevCategories}.${category}`;
          },
          ''
        );

        const newDepthBase = isNil(clientFolder)
          ? `rootFolder.${jointFolder.documentCategory.id}`
          : `${clientFolder.folderDepth}.${jointFolder.documentCategory.id}`;

        const newDepth = isEmpty(categoryDepthArray) ? newDepthBase : `${newDepthBase}.${categoryDepthArray}`;

        const depthStringArray = getFolderDepthStringArray(newDepth);
        const newDepthString = depthStringArray
          .reduce<string>((prevDepthString, categoryId) => {
            if (isEmpty(prevDepthString) && categoryId === 'rootFolder') {
              return '/';
            }
            let categoryFolder = state.folders.find(
              (stateFolder) =>
                stateFolder.documentCategory.id === categoryId && stateFolder.parentFolderId === jointFolder.id
            );
            if (isNil(categoryFolder)) {
              categoryFolder = state.categoryFolders.find(
                (stateCategoryFolder) => stateCategoryFolder.documentCategory.id === categoryId
              );
            }

            if (!isNil(categoryFolder?.documentCategory))
              return `${prevDepthString}/${getTranslatedCategory(
                categoryFolder.documentCategory,
                getTypedLanguage(language)
              )}`;
            return prevDepthString;
          }, '')
          .replace('//', '/');

        return {
          ...file,
          id: uuidv4(),
          folderDepth: file.folderEntity === FolderEntity.FILE ? `${newDepth}.${file.documentCategory.id}` : newDepth,
          folderDepthString:
            file.folderEntity === FolderEntity.FILE
              ? `${newDepthString}/${getTranslatedCategory(file.documentCategory, getTypedLanguage(language))}`
              : newDepthString,
        };
      });
      acc.push(...newFoldersToAdd);
      return acc;
    }, []);

    if (!state.onPage || isNil(clientFolder)) return { foldersToAdd: jointFoldersToAdd, foldersToDelete };

    const rootFoldersToChange = state.folders.filter(
      (stateFolder) => stateFolder.parentFolderId === ROOT_PARENT_FOLDER_ID
    );

    foldersToDelete.push(...rootFoldersToChange);

    const rootFoldersToAdd = rootFoldersToChange.map((file) => {
      const categoryDepthArray = getCategoryDeptArray(file.documentCategory, newCategories).reduce<string>(
        (prevCategories, category) => {
          if (file.documentCategory.id === category) {
            return prevCategories;
          }
          if (isEmpty(prevCategories)) {
            return category;
          }
          return `${prevCategories}.${category}`;
        },
        ''
      );

      const newDepth = isEmpty(categoryDepthArray) ? 'rootFolder' : `rootFolder.${categoryDepthArray}`;
      const depthStringArray = getFolderDepthStringArray(newDepth);

      const newDepthString = depthStringArray
        .reduce<string>((prevDepthString, categoryId) => {
          if (isEmpty(prevDepthString) && categoryId === 'rootFolder') {
            return '/';
          }
          const category = state.categories[EntityType.CONTACT].find((stateFolder) => stateFolder.id === categoryId);

          if (!isNil(category))
            return `${prevDepthString}/${getTranslatedCategory(category, getTypedLanguage(language))}`;
          return prevDepthString;
        }, '')
        .replace('//', '/');

      return {
        ...file,
        id: uuidv4(),
        folderDepth: file.folderEntity === FolderEntity.FILE ? `${newDepth}.${file.documentCategory.id}` : newDepth,
        folderDepthString:
          file.folderEntity === FolderEntity.FILE
            ? `${newDepthString}/${getTranslatedCategory(file.documentCategory, getTypedLanguage(language))}`
            : newDepthString,
      };
    }, '');

    foldersToAdd.push(...rootFoldersToAdd);
    foldersToAdd.push(...jointFoldersToAdd);

    return { foldersToAdd, foldersToDelete };
  };

  const dropFolderAction = async (
    folders: FolderModel[],
    entityType: EntityType,
    currentFolder: FolderModel,
    formatMessage: IntlFormatters['formatMessage'],
    targetFolder?: FolderModel,
    targetFolderVisible?: boolean,
    clientFolder?: FolderModel
  ) => {
    const foldersToAdd: FolderModel[] = [];
    const filesToChange: DocumentModel[] = [];
    const filesToDelete: string[] = [];
    const foldersToDelete: FolderModel[] = [];
    const newCategories: DocumentCategoryWithoutReadIdAndClientId[] = [];
    let recalculateFolders = false;

    const filesInFolder = getFileNamesInFolder(targetFolder!, state.folders);
    const filesToReplace: string[] = [];
    for (const folder of folders) {
      if (folder.folderEntity === FolderEntity.FILE) {
        const file = getDocumentsListForEntity(entityType as EntityType).find(
          (doc) => doc.id === folder.documentModel!.id
        );
        if (file) {
          let uniqueFileName = file.fileName;
          if (filesInFolder.includes(file.fileName)) {
            const result = await getConfirmation({
              title: formatMessage({ id: 'folders.fileActions.duplicateFolderTitle' }),
              text: formatMessage({ id: 'folders.fileActions.duplicateFolder' }, { fileName: file.fileName }),
              actionButtonLabel: formatMessage({ id: 'folders.fileActions.replace' }),
              thirdButtonLabel: formatMessage({ id: 'folders.fileActions.keepBoth' }),
              formatMessage,
            });
            if (result === ConfirmationDialogOptions.THIRD_BUTTON) {
              uniqueFileName = getUniqueFileName(filesInFolder, file.fileName);
            }
            if (result === ConfirmationDialogOptions.ACTION_BUTTON) {
              uniqueFileName = file.fileName;
              filesToReplace.push(file.fileName);
            }
            if (isNil(result)) {
              continue;
            }
          }

          const addedFile = await addFileOnDropAction(targetFolder, file, entityType as EntityType, uniqueFileName);
          const folderToAdd: FolderModel = {
            ...basicCreateFolderObject,
            id: uuidv4(),
            folderEntity: FolderEntity.FILE,
            size: addedFile.size ?? 0,
            updatedAt: addedFile.updatedAt ?? '',
            translatedLabel: decodeURIComponent(addedFile.fileName),
            documentModel: addedFile,
            documentCategory: addedFile.category!,
            folderDepth: `${targetFolder?.folderDepth}.${targetFolder?.documentCategory?.id}`,
            folderDepthString: `${targetFolder?.folderDepthString}/${targetFolder?.translatedLabel}`.replace('//', '/'),
            parentFolderId: clientFolder?.parentFolderId ?? ROOT_PARENT_FOLDER_ID,
          } as FolderModel;

          filesToDelete.push(file.id);
          filesToChange.push(addedFile);
          foldersToAdd.push(folderToAdd);
          foldersToDelete.push(folder);
        }
        continue;
      }
      recalculateFolders = true;
      const result = await whenDroppedAFolder(folder, clientFolder!, targetFolder!, entityType, currentFolder);
      if (isNil(result)) continue;
      foldersToAdd.push(...result.foldersToAdd);
      foldersToDelete.push(...result.foldersToDelete);
      newCategories.push(...result.newCategories);
    }

    if (filesToReplace.length > 0) {
      const toDelete = await deleteFilesToReplace(targetFolder!, filesToReplace);
      foldersToDelete.push(...toDelete.foldersToDelete);
      filesToDelete.push(...toDelete.files.map((file) => file.id));
    }

    if (state.onPage && recalculateFolders) {
      localUpdateCategories(newCategories, entityType, clientFolder!);
    }

    if (filesToChange.length > 0) {
      const files = getDocumentsListForEntity(entityType as EntityType);
      const newFiles = files.filter((file) => !filesToDelete.includes(file.id));
      dispatch({
        type: 'UPDATE_FILES',
        payload: { files: [...newFiles, ...filesToChange], type: entityType as EntityType },
      });
    }

    if (foldersToAdd.find((folder) => folder.folderEntity === FolderEntity.FILE && !targetFolderVisible)) {
      foldersToAdd.push(targetFolder!);
    }

    if (entityType === EntityType.CONTACT && recalculateFolders) {
      const contactUpdates = droppedFolderLinkedToContact(clientFolder!, newCategories);
      foldersToAdd.push(...contactUpdates.foldersToAdd);
      foldersToDelete.push(...contactUpdates.foldersToDelete);
    }

    dispatch({ type: 'ADD_FOLDERS', payload: { foldersToAdd, foldersToDelete } });

    const filteredFolders = state.folders.filter(
      (folder) => !foldersToDelete.map((folderToDelete) => folderToDelete.id).includes(folder.id)
    );
    filteredFolders.push(...foldersToAdd);

    setFilteredFolders(
      filteredFolders,
      last(state.folderNavigation)?.documentCategory,
      last(state.folderNavigation),
      isEmpty(newCategories) ? getCategoriesForEntity(entityType as EntityType) : newCategories
    );
  };

  const droppedOnRootFolder = (
    categories: DocumentCategoryWithoutReadIdAndClientId[],
    entityType: EntityType,
    targetFolderCategoryId: string,
    parentFolder: FolderModel | undefined
  ) => {
    let targetFolder;
    let targetFolderVisible;

    const targetCategory = categories.find((category) => category.id === targetFolderCategoryId);
    if (!isNil(targetCategory)) {
      const clientCategories = getCategoriesForEntity(entityType);

      const categoryDepthArray = getCategoryDeptArray(targetCategory, clientCategories).filter(
        (category) => category !== targetCategory.id
      );

      let folderDepth = 'rootFolder';
      if (!isNil(parentFolder)) {
        folderDepth = `${parentFolder?.folderDepth}.${parentFolder?.documentCategory?.id}`;
      }
      if (!isEmpty(categoryDepthArray)) {
        folderDepth = `${folderDepth}.${categoryDepthArray.join('.')}`;
      }

      const folderDepthArray = getFolderDepthStringArray(folderDepth);

      let folderDepthString = folderDepthArray.reduce<string>((acc, folderId) => {
        if (folderId === 'rootFolder') return acc;
        const category = categories.find((categoryToCheck) => categoryToCheck.id === folderId);
        if (category) {
          acc = `${acc}/${getTranslatedCategory(category, getTypedLanguage(language))}`;
          return acc;
        }
        const folder = state.folders.find((folderToCheck) => folderToCheck.documentCategory?.id === folderId);
        if (folder) {
          acc = `${acc}/${folder.translatedLabel}`;
        }
        return acc;
      }, '');

      if (folderDepthString === '') {
        folderDepthString = '/';
      }

      folderDepthString = folderDepthString.replace('//', '/');

      const newTargetCategory =
        targetCategory.parentId && state.onPage
          ? targetCategory
          : { ...targetCategory, parentId: parentFolder?.documentCategory.id };

      const folderToAdd: FolderModel = {
        ...basicCreateFolderObject,
        id: uuidv4(),
        folderEntity: FolderEntity.FOLDER,
        translatedLabel: getTranslatedCategory(targetCategory, getTypedLanguage(language)),
        documentCategory: newTargetCategory,
        folderDepth,
        folderDepthString,
        parentFolderId: parentFolder?.id ?? ROOT_PARENT_FOLDER_ID,
      } as FolderModel;

      targetFolderVisible = false;
      targetFolder = folderToAdd;
    }
    if (parentFolder?.documentCategory.id === targetFolderCategoryId) {
      targetFolder = parentFolder;
    }
    return { targetFolder, targetFolderVisible };
  };

  const dropFolder = async (
    targetFolderCategoryId: string,
    currentFolderId: string,
    formatMessage: IntlFormatters['formatMessage']
  ): Promise<FolderErrors> => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: true },
    });

    let currentFolder = state.folders.find((folder) => folder.id === currentFolderId);
    if (isNil(currentFolder)) {
      currentFolder = state.categoryFolders.find((folder) => folder.id === currentFolderId);
    }

    if (currentFolder?.documentCategory.id === targetFolderCategoryId) {
      dispatch({
        type: 'SET_FOLDER_ATTRIBUTES',
        payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
      });
      return FolderErrors.FALSE_ERROR;
    }

    if (isNil(currentFolder)) {
      dispatch({
        type: 'SET_FOLDER_ATTRIBUTES',
        payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
      });
      return FolderErrors.FOLDER_NOT_FOUND;
    }

    const foldersToWorkWith: FolderModel[] = [];
    //When moving a folder that is not selected we need only to move the folder and not the selected folders
    let resetSelected = false;
    if (state.selectedFoldersIds?.includes(currentFolderId)) {
      foldersToWorkWith.push(...state.folders.filter((folder) => state.selectedFoldersIds.includes(folder.id)));
      foldersToWorkWith.push(...state.categoryFolders.filter((file) => state.selectedFoldersIds.includes(file.id)));
      resetSelected = true;
    } else {
      foldersToWorkWith.push(currentFolder);
    }
    if (foldersToWorkWith.find((folder) => folder.documentCategory?.fileCategory !== FileCategory.CUSTOM)) {
      dispatch({
        type: 'SET_FOLDER_ATTRIBUTES',
        payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
      });
      return FolderErrors.CUSTOM_FOLDER_ERROR;
    }
    const lastInFolderNavigation = last(state.folderNavigation);
    const parentFolder = state.folders.find((folder) => folder.id === lastInFolderNavigation?.parentFolderId);

    const entityType = state.onPage ? (parentFolder?.documentCategory.entity as EntityType) : state.entityType;

    const categories = getCategoriesForEntity(entityType as EntityType);

    let targetFolder = state.folders.find(
      (folder) =>
        folder.folderEntity === FolderEntity.FOLDER &&
        folder.documentCategory.id === targetFolderCategoryId &&
        (!isNil(parentFolder)
          ? folder.parentFolderId === parentFolder?.id
          : folder.parentFolderId === ROOT_PARENT_FOLDER_ID)
    );

    let targetFolderVisible = true;

    //If the target folder is nil, it means that it is dropped on a folder that is not visible
    if (isNil(targetFolder)) {
      const targetFolderObject = droppedOnRootFolder(
        categories,
        entityType as EntityType,
        targetFolderCategoryId,
        parentFolder
      );

      targetFolder = targetFolderObject.targetFolder;
      targetFolderVisible = targetFolderObject.targetFolderVisible ?? true;
    }
    await dropFolderAction(
      foldersToWorkWith,
      entityType as EntityType,
      currentFolder,
      formatMessage,
      targetFolder,
      targetFolderVisible,
      parentFolder
    );

    if (resetSelected) dispatch({ type: 'SET_SELECTED_FOLDERS', payload: [] });

    return FolderErrors.NONE;
  };

  const createCategoryWhenLabelsAreFilled = async (
    labels: CustomLabel[],
    entityType: EntityType,
    selectedCategoryId: string | undefined
  ): Promise<DocumentCategoryWithoutReadIdAndClientId> => {
    const newDocumentCategory = await createFileCategory(labels, entityType as EntityType, selectedCategoryId);
    dispatch({
      type: 'SET_CATEGORIES',
      payload: {
        categories: {
          ...state.categories,
          [entityType]: [...state.categories[entityType], newDocumentCategory],
        },
      },
    });
    return newDocumentCategory;
  };

  const createFilesForJointContacts = (folderClient: FolderModel, fileFolders: FolderModel[]): FolderModel[] => {
    const foldersToAdd: FolderModel[] = [];
    const jointFolders = state.folders.filter(
      (folder) => folder.entityId === folderClient?.entityId && folder.id !== folderClient?.id
    );

    jointFolders.forEach((jointFolder) => {
      const depthString = `${jointFolder.folderDepthString}/${jointFolder.translatedLabel}`;
      const depth = `${jointFolder.folderDepth}.${jointFolder.documentCategory.id}`;

      const newFoldersToAdd = fileFolders.map((file) => {
        return {
          ...file,
          id: uuidv4(),
          folderDepth: file.folderDepth?.replace(
            `${folderClient?.folderDepth}.${folderClient.documentCategory.id}`,
            depth
          ),
          folderDepthString: file.folderDepthString?.replace(
            `${folderClient?.folderDepthString}/${folderClient.translatedLabel}`,
            depthString
          ),
          parentFolderId: jointFolder.id,
          documentCategory: {
            ...file.documentCategory,
            parentId:
              file.documentCategory.parentId === folderClient?.documentCategory.id
                ? jointFolder.documentCategory.id
                : file.documentCategory.parentId,
          },
        };
      });
      foldersToAdd.push(...newFoldersToAdd);
    });
    return foldersToAdd;
  };

  const createFiles = async (
    files: File[],
    entityType: EntityType,
    entityId: string,
    categoryId: string,
    folderClient: FolderModel,
    fileFolderDepth: string,
    fileFolderDepthString: string,
    foldersToAdd: FolderModel[],
    formatMessage: IntlFormatters['formatMessage'],
    targetFolder: FolderModel,
    fileFoldersToDelete?: string[]
  ) => {
    let contact = state.contact;
    const lastFolderInNavigation = last(state.folderNavigation);

    const lastInNavigationParentFolder = state.folders.find(
      (stateFolder) => stateFolder.id === lastFolderInNavigation?.parentFolderId
    );
    if (!isNil(lastInNavigationParentFolder)) {
      contact = contacts.find((stateContact) => stateContact.id === lastInNavigationParentFolder?.entityId);
    }
    const filesInFolder = getFileNamesInFolder(targetFolder, state.folders);

    const uniqueFilesInputs: GetUniqueFilesInputs = {
      files,
      filesInFolder,
      entityTypeCreateFolder: entityType,
      entityId,
      folderDocumentCategoryId: categoryId,
      contact,
      getConfirmation,
      formatMessage,
      enqueueSnackbar,
      createFile,
      setUploadFiles,
    };
    const { filesToAdd, filesToReplace } = await getUniqueFiles(uniqueFilesInputs);

    const s3Files = await getS3ObjectUrls(filesToAdd);

    const fileFolders: FolderModel[] = s3Files.map((file) => {
      if (state.onPage && isNil(file.category?.parentId)) {
        file.category = { ...file.category!, parentId: folderClient?.documentCategory.id };
      }
      const fileName = decodeURIComponent(file.fileName);
      const translatedCategory = getTranslatedCategory(file.category!, getTypedLanguage(language));
      return {
        ...basicCreateFolderObject,
        id: uuidv4(),
        folderEntity: FolderEntity.FILE,
        size: file.size ?? 0,
        updatedAt: file.updatedAt ?? '',
        translatedLabel: fileName,
        documentModel: {
          ...file,
          fileName,
          canDrag: canDrag(file.category!.fileCategory),
          translatedCategory,
        },
        documentCategory: file.category!,
        folderDepth: fileFolderDepth,
        folderDepthString: fileFolderDepthString,
        parentFolderId: lastInNavigationParentFolder?.parentFolderId ?? ROOT_PARENT_FOLDER_ID,
      } as FolderModel;
    });

    if (folderClient?.folderEntity === FolderEntity.CONTACT) {
      const contactFolders = createFilesForJointContacts(folderClient, fileFolders);
      fileFolders.push(...contactFolders);
    }

    const toDelete = await deleteFilesToReplace(targetFolder, [...filesToReplace, ...(fileFoldersToDelete || [])]);

    dispatch({
      type: 'UPDATE_FILES',
      payload: {
        files: [...getDocumentsListForEntity(entityType as EntityType), ...s3Files].filter(
          (file) => toDelete.files.findIndex((fileToDelete) => fileToDelete.id === file.id) === -1
        ),
        type: entityType as EntityType,
      },
    });

    dispatch({
      type: 'ADD_FOLDERS',
      payload: {
        foldersToAdd: fileFolders,
        foldersToDelete: toDelete.foldersToDelete,
      },
    });

    setFilteredFolders(
      [...state.folders, ...foldersToAdd, ...fileFolders].filter(
        (folder) => !toDelete.foldersToDelete.includes(folder)
      ),
      lastFolderInNavigation?.documentCategory,
      lastFolderInNavigation
    );
  };

  const addNewFolderForJointContacts = (parentFolder: FolderModel, folderToAdd: FolderModel): FolderModel[] => {
    const foldersToAdd: FolderModel[] = [];
    const jointFolders = state.folders.filter(
      (folder) => folder.entityId === parentFolder?.entityId && folder.id !== parentFolder?.id
    );

    jointFolders.forEach((jointFolder) => {
      const depthString = `${jointFolder.folderDepthString}/${jointFolder.translatedLabel}`;
      const depth = `${jointFolder.folderDepth}.${jointFolder.documentCategory.id}`;

      foldersToAdd.push({
        ...folderToAdd,
        id: uuidv4(),
        folderDepth: folderToAdd.folderDepth?.replace(
          `${parentFolder?.folderDepth}.${parentFolder.documentCategory.id}`,
          depth
        ),
        folderDepthString: folderToAdd.folderDepthString?.replace(
          `${parentFolder?.folderDepthString}/${parentFolder.translatedLabel}`,
          depthString
        ),
        parentFolderId: jointFolder.id,
        documentCategory: {
          ...folderToAdd.documentCategory,
          parentId:
            folderToAdd.documentCategory.parentId === parentFolder?.documentCategory.id
              ? jointFolder.documentCategory.id
              : folderToAdd.documentCategory.parentId,
        },
      });
    });
    return foldersToAdd;
  };

  const addNewFolderWithoutTargetFolder = (
    parentFolder: FolderModel,
    selectedCategory: DocumentCategoryWithoutReadIdAndClientId,
    currentEntityFolders: FolderModel[],
    entityType: EntityType,
    newDocumentCategory: DocumentCategoryWithoutReadIdAndClientId,
    labels: CustomLabel[],
    files: boolean
  ) => {
    const lastFolderInNavigation = last(state.folderNavigation);
    const foldersToAdd: FolderModel[] = [];

    let createFolderDepth: string = parentFolder ? getFolderDepth(parentFolder) : 'rootFolder';
    let createFolderDepthString: string = parentFolder
      ? getFolderDepthString(getTypedLanguage(language), parentFolder)
      : '/';
    if (!isNil(selectedCategory)) {
      let categoriesToWorkWith: string[] = [];
      if (!isNil(selectedCategory.parentId)) {
        const clientCategories = state.onPage
          ? getCategoriesForEntity(
              isNil(parentFolder)
                ? (lastFolderInNavigation?.documentCategory.entity as EntityType)
                : (parentFolder.documentCategory.entity! as EntityType)
            )
          : getCategoriesForEntity(state.entityType!);
        categoriesToWorkWith = [...getCategoryDeptArray(selectedCategory, clientCategories)];
      }

      categoriesToWorkWith?.forEach((categoryId) => {
        const currentCategoryFolder = currentEntityFolders.find(
          (folder) => folder.documentCategory.id === categoryId && folder.folderEntity === FolderEntity.FOLDER
        );
        const currentCategory = getCategoriesForEntity(entityType).find(
          (stateCategory) => stateCategory.id === categoryId
        );

        if (isNil(currentCategoryFolder) && !isNil(currentCategory)) {
          const folderToAdd = {
            ...basicCreateFolderObject,
            id: uuidv4(),
            folderEntity: FolderEntity.FOLDER,
            translatedLabel: getTranslatedCategory(currentCategory!, getTypedLanguage(language)),
            documentCategory: currentCategory!,
            folderDepth: createFolderDepth,
            folderDepthString: createFolderDepthString,
            parentFolderId: parentFolder?.id ?? ROOT_PARENT_FOLDER_ID,
          } as FolderModel;
          foldersToAdd.push(folderToAdd);
        }
        if (!isNil(selectedCategory.parentId)) {
          createFolderDepth = getFolderDepth(undefined, createFolderDepth, categoryId);
          createFolderDepthString = getFolderDepthString(
            getTypedLanguage(language),
            undefined,
            createFolderDepthString,
            currentCategory!
          );
        }
      });
    }

    const categoryToWorkWith = !isNilOrEmpty(labels?.[0].label) ? newDocumentCategory! : selectedCategory;

    const category = {
      ...categoryToWorkWith,
      parentId:
        state.onPage && isNil(categoryToWorkWith?.parentId)
          ? parentFolder?.documentCategory?.id
          : categoryToWorkWith?.parentId,
    } as DocumentCategoryWithoutReadIdAndClientId;

    if (!isNil(newDocumentCategory) && !isNil(selectedCategory)) {
      createFolderDepth = getFolderDepth(undefined, createFolderDepth, selectedCategory.id);
      createFolderDepthString = getFolderDepthString(
        getTypedLanguage(language),
        undefined,
        createFolderDepthString,
        selectedCategory
      );
    }
    const folderToAdd: FolderModel = {
      ...basicCreateFolderObject,
      id: uuidv4(),
      folderEntity: FolderEntity.FOLDER,
      translatedLabel: getTranslatedCategory(category, getTypedLanguage(language)),
      documentCategory: category as DocumentCategory,
      folderDepth: createFolderDepth,
      folderDepthString: createFolderDepthString,
      parentFolderId: parentFolder?.id ?? ROOT_PARENT_FOLDER_ID,
    } as FolderModel;

    if (!files) return { foldersToAdd, folderDepth: '', folderDepthString: '' };
    foldersToAdd.push(folderToAdd);

    const folderDepth = getFolderDepth(folderToAdd);
    const folderDepthString = getFolderDepthString(getTypedLanguage(language), folderToAdd);
    if (parentFolder?.folderEntity === FolderEntity.CONTACT) {
      const jointFolders = addNewFolderForJointContacts(parentFolder, folderToAdd);
      foldersToAdd.push(...jointFolders);
    }
    dispatch({ type: 'ADD_FOLDERS', payload: { foldersToAdd, foldersToDelete: [] } });
    return { foldersToAdd, folderDepth, folderDepthString };
  };

  const getUniqueCategoryParameter = (
    labels: CustomLabel[],
    entityType: EntityType,
    categoryToWorkWith: DocumentCategoryWithoutReadIdAndClientId | undefined
  ): number => {
    const selectedCategoryLevels = getCategoriesForEntity(entityType as EntityType).filter(
      (category) => category.parentId === (categoryToWorkWith?.id || null)
    );

    const uniqueCategoryParameter = getUniqueCategoryNameParameter(selectedCategoryLevels, labels!);

    return uniqueCategoryParameter;
  };

  const addNewFolder = async (
    formatMessage: IntlFormatters['formatMessage'],
    labels?: CustomLabel[],
    selectedCategory?: DocumentCategoryWithoutReadIdAndClientId,
    files?: File[],
    foldersToReplace?: string[]
  ) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: true },
    });

    let currentEntityFolders: FolderModel[] = [];
    let parentFolder: FolderModel | undefined;
    const lastFolderInNavigation = last(state.folderNavigation);

    let entityType = state.entityType;
    let entityId = state.id;

    const lastInNavigationParentFolder = state.folders.find(
      (stateFolder) => stateFolder.id === lastFolderInNavigation?.parentFolderId
    );

    if (!isNil(lastInNavigationParentFolder)) {
      parentFolder = lastInNavigationParentFolder;
      entityType = lastInNavigationParentFolder.documentCategory.entity as EntityType;
      entityId = lastInNavigationParentFolder.entityId;
    }

    state.onPage
      ? currentEntityFolders.push(...state.folders.filter((folder) => folder.parentFolderId === parentFolder?.id))
      : currentEntityFolders.push(...state.folders);

    if (parentFolder?.folderEntity === FolderEntity.CONTACT && state.onPage) {
      const contact = contacts.find((stateContact) => stateContact.id === parentFolder?.entityId);
      const type = contactMostImportantType(contact!);
      if (type === ContactType.JOINT_OWNERSHIP) {
        contact?.jointOwners?.forEach((jointOwner) => {
          const jointContact = contacts.find((stateContact) => stateContact.id === jointOwner.contactId);
          currentEntityFolders = currentEntityFolders.filter(
            (entityFolder) => !entityFolder.folderDepthString.includes(getContactNameFromObject(jointContact))
          );
        });
      }
    }
    let targetFolder = currentEntityFolders.find(
      (folder) =>
        folder.documentCategory.id === selectedCategory?.id &&
        folder.folderEntity === FolderEntity.FOLDER &&
        folder.parentFolderId === (parentFolder?.id ?? ROOT_PARENT_FOLDER_ID)
    );

    const fileFolderDepth: { folderDepth: string; folderDepthString: string } = {
      folderDepth: '',
      folderDepthString: '',
    };

    let newDocumentCategory: DocumentCategoryWithoutReadIdAndClientId | undefined;
    if (!isNilOrEmpty(labels?.[0].label)) {
      const uniqueCategoryParameter = getUniqueCategoryParameter(labels!, entityType as EntityType, selectedCategory);

      const mappedLabels = labels?.map((label) => {
        return {
          ...label,
          label: uniqueCategoryParameter !== 0 ? `${label.label} (${uniqueCategoryParameter})` : label.label,
        };
      });

      newDocumentCategory = await createCategoryWhenLabelsAreFilled(
        mappedLabels!,
        entityType as EntityType,
        selectedCategory?.id
      );

      targetFolder = undefined;
      if (!files) {
        seeAllFolders(newDocumentCategory);
        dispatch({
          type: 'SET_FOLDER_ATTRIBUTES',
          payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
        });
        if (!state.seeAllFolders) {
          dispatch({
            type: 'SET_FOLDER_ATTRIBUTES',
            payload: { key: [FolderBooleanAttributes.SEE_ALL_FOLDERS], value: true },
          });
          return;
        }
      }
    }

    const foldersToAdd: FolderModel[] = [];
    if (isNil(targetFolder)) {
      const addFolder = addNewFolderWithoutTargetFolder(
        parentFolder!,
        selectedCategory!,
        currentEntityFolders,
        entityType as EntityType,
        newDocumentCategory!,
        labels!,
        !isNilOrEmpty(files)
      );
      targetFolder = addFolder.foldersToAdd[0];
      foldersToAdd.push(...addFolder.foldersToAdd);
      fileFolderDepth.folderDepth = addFolder.folderDepth;
      fileFolderDepth.folderDepthString = addFolder.folderDepthString;
    }

    if (files) {
      await createFiles(
        files,
        entityType as EntityType,
        entityId ?? '',
        newDocumentCategory ? newDocumentCategory.id : selectedCategory!.id,
        parentFolder!,
        targetFolder ? getFolderDepth(targetFolder) : fileFolderDepth.folderDepth,
        targetFolder
          ? getFolderDepthString(getTypedLanguage(language), targetFolder)
          : fileFolderDepth.folderDepthString,
        foldersToAdd,
        formatMessage,
        targetFolder,
        foldersToReplace
      );
    }
  };

  const calculateNewCategoryFolders = (foldersToDelete: FolderModel[]): FolderModel[] => {
    const existingCategoryIds = state.categoryFolders.map((folder) => folder.documentCategory.id);

    const newFolders = foldersToDelete.reduce<FolderModel[]>((acc, folder) => {
      if (folder.folderEntity === FolderEntity.FOLDER && !existingCategoryIds.includes(folder.documentCategory.id)) {
        acc.push({ ...folder, size: 0 });
      }
      return acc;
    }, []);

    return state.categoryFolders.concat(newFolders);
  };

  const calculateDeleteSize = (folder: FolderModel, fileFoldersToDelete: FolderModel[]): number =>
    fileFoldersToDelete.reduce<number>(
      (total, deletedItem) =>
        deletedItem.folderDepth.includes(folder.folderDepth) ? total + (deletedItem.size || 0) : total,
      0
    );

  const calculateNewFilteredFolders = (fileFoldersToDelete: FolderModel[]) => {
    const emptyFoldersToDelete: FolderModel[] = [];

    const newFilteredFolders = state.filteredFolders.reduce<FolderModel[]>((acc, folder) => {
      const isMarkedForDeletion = fileFoldersToDelete.some((deleteFolder) => deleteFolder.id === folder.id);
      if (isMarkedForDeletion) return acc;

      if (folder.folderEntity === FolderEntity.FILE) {
        acc.push(folder);
        return acc;
      }

      if (folder.folderEntity === FolderEntity.FOLDER) {
        const deleteSize = calculateDeleteSize(folder, fileFoldersToDelete);
        const newSize = Math.max(0, (folder.size || 0) - deleteSize);

        if (newSize === 0) {
          emptyFoldersToDelete.push(folder);
          return acc;
        }

        acc.push({ ...folder, size: newSize });
      }

      return acc;
    }, []);
    return { newFilteredFolders, emptyFoldersToDelete };
  };

  const getFileFoldersToDelete = (foldersToDelete: FolderModel[]): FolderModel[] => {
    const seenIds: string[] = [];

    return foldersToDelete.reduce<FolderModel[]>((acc, folder) => {
      if (seenIds.includes(folder.id)) {
        return acc;
      }

      seenIds.push(folder.id);

      if (folder.folderEntity === FolderEntity.FILE) {
        acc.push(folder);
        return acc;
      }

      const filesInFolder = getFilesInFolder(folder, state.folders).filter((file) => {
        const isNewFile = !seenIds.includes(file.id);
        if (isNewFile) {
          seenIds.push(file.id);
        }
        return isNewFile;
      });

      acc.push(...filesInFolder);
      return acc;
    }, []);
  };

  const onlyDeleteFiles = async (foldersToDelete: FolderModel[], documents: DocumentModel[], entity: EntityType) => {
    const fileFoldersToDelete = getFileFoldersToDelete(foldersToDelete);
    const fileIdsToDelete = fileFoldersToDelete.map((folder) => folder.documentModel!.id);
    const newDocuments = documents.filter((document) => !fileIdsToDelete.includes(document.id));
    const { newFilteredFolders, emptyFoldersToDelete } = calculateNewFilteredFolders(fileFoldersToDelete);
    const newCategoryFolders = calculateNewCategoryFolders(foldersToDelete);
    const promises = fileFoldersToDelete.map((folder) => deleteFile(folder.documentModel!));

    await Promise.all(promises);

    dispatch({
      type: 'ADD_FOLDERS',
      payload: {
        foldersToAdd: [],
        foldersToDelete: [...fileFoldersToDelete, ...emptyFoldersToDelete],
      },
    });
    dispatch({ type: 'UPDATE_FILES', payload: { files: newDocuments, type: entity as EntityType } });
    dispatch({ type: 'SET_FILTERED_FOLDERS', payload: newFilteredFolders });
    dispatch({
      type: 'SET_CATEGORY_FOLDERS',
      payload: {
        folders: newCategoryFolders,
      },
    });
    dispatch({ type: 'SET_SELECTED_FOLDERS', payload: [] });
  };

  const collectFoldersToDelete = (foldersToDelete: FolderModel[], entity: EntityType) => {
    const fileIds: string[] = [];
    const categoryIds: string[] = [];

    return foldersToDelete.reduce(
      (acc, folder) => {
        const isNewFile = !fileIds.includes(folder.id);
        const isNewCategory = !categoryIds.includes(folder.documentCategory.id);

        if (folder.folderEntity === FolderEntity.FILE && isNewFile) {
          fileIds.push(folder.id);
          acc.filesToDelete.push(folder.documentModel!);
          return acc;
        }

        if (folder.folderEntity === FolderEntity.FOLDER && isNewCategory) {
          categoryIds.push(folder.documentCategory.id);
          acc.categoriesToDelete.push(folder.documentCategory);

          const { files: filesInCategory, categories: categoriesInCategory } = getAllFilesInCategory(
            state.files[entity],
            folder.documentCategory,
            state.categories[entity]
          );

          filesInCategory.forEach((file) => {
            if (!fileIds.includes(file.id)) {
              fileIds.push(file.id);
              acc.filesToDelete.push(file);
            }
          });

          categoriesInCategory.forEach((category) => {
            if (!categoryIds.includes(category.id)) {
              categoryIds.push(category.id);
              acc.categoriesToDelete.push(category);
            }
          });
        }

        return acc;
      },
      { filesToDelete: [] as FileModel[], categoriesToDelete: [] as DocumentCategoryWithoutReadIdAndClientId[] }
    );
  };

  const deleteAllFoldersFromAllEntities = async (foldersToDelete: FolderModel[], entityType: EntityType) => {
    const { filesToDelete, categoriesToDelete } = collectFoldersToDelete(foldersToDelete, entityType);

    const filesDeletePromises = filesToDelete.map((file) => deleteFile(file));

    const categoriesDeletePromises = categoriesToDelete.map((category) => deleteFileCategory(category));
    await Promise.all([...filesDeletePromises, ...categoriesDeletePromises]);

    const availableDocuments = getDocumentsListForEntity(entityType).filter((document: DocumentModel) => {
      if (!document.id) return false;
      const isDocumentMarkedForDeletion = foldersToDelete.some((folder) => folder.documentModel?.id === document.id);
      return !isDocumentMarkedForDeletion;
    });

    //This is the fastest way to get the unique categories to delete and filter with this
    const categoryIdsToDelete = new Set(categoriesToDelete.map((category) => category.id));
    const fileIdsToDelete = new Set(filesToDelete.map((file) => file.id));

    const newCategoryFolders = state.categoryFolders.filter(
      (categoryFolder) => !categoryIdsToDelete.has(categoryFolder.documentCategory.id)
    );

    const newFilteredFolders = state.filteredFolders.filter(
      (folder) =>
        !(folder.folderEntity === FolderEntity.FILE && fileIdsToDelete.has(folder.documentModel!.id)) &&
        !(folder.folderEntity === FolderEntity.FOLDER && categoryIdsToDelete.has(folder.documentCategory.id))
    );

    const newCategories = state.categories[entityType].filter(
      (category) => !categoriesToDelete.some((folder) => folder.id === category.id)
    );

    const allFoldersToDelete = state.folders.filter((folder) => {
      if (folder.folderEntity === FolderEntity.FILE) {
        return filesToDelete.some((fileToDelete) => fileToDelete.id === folder.documentModel?.id);
      }
      if (folder.folderEntity === FolderEntity.FOLDER) {
        return categoriesToDelete.some((categoryToDelete) => categoryToDelete.id === folder.documentCategory.id);
      }
      return false;
    });

    dispatch({
      type: 'ADD_FOLDERS',
      payload: { foldersToAdd: [], foldersToDelete: allFoldersToDelete },
    });
    dispatch({
      type: 'SET_CATEGORY_FOLDERS',
      payload: {
        folders: newCategoryFolders,
      },
    });
    dispatch({ type: 'SET_FILTERED_FOLDERS', payload: newFilteredFolders });
    dispatch({ type: 'UPDATE_FILES', payload: { files: availableDocuments, type: entityType as EntityType } });
    dispatch({ type: 'SET_CATEGORIES', payload: { categories: { [entityType]: newCategories } } });
    dispatch({ type: 'SET_SELECTED_FOLDERS', payload: [] });
  };

  const dispatchNotBusyAndReturn = (action: FolderErrors) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
    });
    return action;
  };

  const askForDeleteConfirmation = async (text: string, formatMessage: IntlFormatters['formatMessage']) =>
    await getConfirmation({
      title: formatMessage({ id: 'folders.fileActions.deleteFolderTitle' }),
      text,
      actionButtonLabel: formatMessage({ id: 'folders.fileActions.deleteAll' }),
      thirdButtonLabel: formatMessage({ id: 'folders.fileActions.deleteOnlyFiles' }),
      formatMessage,
    });

  const deleteFolders = async (
    folderIdsToDelete: string[],
    formatMessage: IntlFormatters['formatMessage']
  ): Promise<FolderErrors> => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: true },
    });

    const folderCategoriesToDelete: FolderModel[] = [];
    const filesToDelete: string[] = [];

    const foldersToDelete = folderIdsToDelete.reduce<FolderModel[]>((acc, folderId) => {
      const folder = state.folders.find((stateFolder) => stateFolder.id === folderId);
      if (isNil(folder)) {
        const folderCategory = state.categoryFolders.find((stateFolder) => stateFolder.id === folderId);
        if (folderCategory) {
          folderCategoriesToDelete.push(folderCategory);
        }
        return acc;
      }
      if (folder?.folderEntity === FolderEntity.FILE) {
        filesToDelete.push(folder.documentModel!.id);
        acc.push(folder);
        return acc;
      }
      acc.push(folder);
      return acc;
    }, []);

    if (
      foldersToDelete.find(
        (folderToDelete) =>
          !canDelete(folderToDelete.documentCategory.fileCategory) ||
          (folderToDelete?.documentCategory.fileCategory !== FileCategory.CUSTOM &&
            folderToDelete.folderEntity === FolderEntity.FOLDER) ||
          ![FolderEntity.FILE, FolderEntity.FOLDER].includes(folderToDelete.folderEntity)
      )
    ) {
      return dispatchNotBusyAndReturn(FolderErrors.CUSTOM_FOLDER_ERROR);
    }

    const parentFolder = getParentFolder(state.folders, last(state.folderNavigation));
    const entity: EntityType = state.onPage
      ? (parentFolder?.documentCategory.entity as EntityType)
      : (state.entityType as EntityType);

    const documents = getDocumentsListForEntity(entity);
    const categoriesToDelete = foldersToDelete.filter((folder) => folder.folderEntity === FolderEntity.FOLDER);

    const currentFolderFiles = foldersToDelete.reduce<DocumentModel[]>((acc, folder) => {
      if (folder.folderEntity === FolderEntity.FILE && folder.documentModel) {
        acc.push(folder.documentModel);
      }
      return acc;
    }, []);

    const currentContextFiles = first(foldersToDelete)?.parentFolderId
      ? state.folders.reduce((acc, folder) => {
          if (
            folder.parentFolderId === first(foldersToDelete)?.parentFolderId &&
            folder.folderEntity === FolderEntity.FILE &&
            folder.documentModel?.id
          ) {
            acc.push(folder.documentModel.id);
          }
          return acc;
        }, [] as string[])
      : [];

    const hasFoldersOnOtherEntities = checkFolderHasFiles(
      [...categoriesToDelete, ...folderCategoriesToDelete],
      currentFolderFiles,
      getCategoriesForEntity(entity as EntityType),
      getDocumentsListForEntity(entity as EntityType).filter((document) => !currentContextFiles.includes(document.id))
    );

    if (hasFoldersOnOtherEntities) {
      if (settingsManagementWrite) {
        const result = await askForDeleteConfirmation(
          formatMessage({ id: 'folders.fileActions.deleteEveryFile' }),
          formatMessage
        );
        if (isNil(result)) return dispatchNotBusyAndReturn(FolderErrors.NONE);
        if (result === ConfirmationDialogOptions.THIRD_BUTTON) {
          await onlyDeleteFiles(foldersToDelete, documents, entity as EntityType);
          return dispatchNotBusyAndReturn(FolderErrors.NONE);
        }
        if (result === ConfirmationDialogOptions.ACTION_BUTTON) {
          await deleteAllFoldersFromAllEntities(
            [...foldersToDelete, ...folderCategoriesToDelete],
            entity as EntityType
          );
          return dispatchNotBusyAndReturn(FolderErrors.NONE);
        }
      }

      await onlyDeleteFiles(foldersToDelete, documents, entity as EntityType);
      return dispatchNotBusyAndReturn(FolderErrors.NONE);
    }

    if (
      foldersToDelete.some((folder) => folder.folderEntity === FolderEntity.FOLDER) ||
      !isEmpty(folderCategoriesToDelete)
    ) {
      const result = await askForDeleteConfirmation(
        formatMessage({ id: 'folders.fileActions.deleteFolderMessage' }),
        formatMessage
      );

      if (result === ConfirmationDialogOptions.THIRD_BUTTON) {
        await onlyDeleteFiles(foldersToDelete, documents, entity as EntityType);
        return dispatchNotBusyAndReturn(FolderErrors.NONE);
      }
      if (isNil(result)) {
        return dispatchNotBusyAndReturn(FolderErrors.FALSE_ERROR);
      }
    }

    await deleteAllFoldersFromAllEntities([...foldersToDelete, ...folderCategoriesToDelete], entity as EntityType);
    return dispatchNotBusyAndReturn(FolderErrors.NONE);
  };

  const selectSearchFolder = (folder: FolderModel) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: true },
    });

    setFilteredFolders(
      state.folders,
      folder.documentCategory,
      folder.folderEntity === FolderEntity.FILE ? last(state.folderNavigation) : folder
    );

    const depth = getFolderDepthStringArray(folder.folderDepth);

    const depthFolders = depth?.map((id, index) =>
      state.folders.find((stateFolder) => {
        let folderInDepth;
        if (index >= 0) {
          folderInDepth = stateFolder.folderDepth?.includes(depth[index - 1]);
        }
        return stateFolder.documentCategory?.id === id && folderInDepth;
      })
    );

    if (folder.folderEntity !== FolderEntity.FILE) {
      depthFolders?.push(folder);
    }

    dispatch({ type: 'SET_FOLDER_NAVIGATION', payload: depthFolders ?? [undefined] });
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY, FolderBooleanAttributes.SEARCH_FOLDERS], value: false },
    });
  };

  const setIsBusy = (contextIsBusy: boolean) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: contextIsBusy },
    });
  };

  const setCategoryFolders = (
    categoriesToWorkWith: DocumentCategoryWithoutReadIdAndClientId[],
    folders: FolderModel[],
    newFolder?: FolderModel,
    parentFolder?: FolderModel,
    entityId?: string
  ) => {
    const foldersToAdd: FolderModel[] = [];
    const currentLevelCategories = filterCurrentLevelCategories(categoriesToWorkWith, newFolder);
    currentLevelCategories.forEach((category) => {
      const currentCategoryFolder = folders.find(
        (stateFolder) =>
          stateFolder.documentCategory?.id === category.id &&
          stateFolder.parentFolderId === (isNil(parentFolder) ? ROOT_PARENT_FOLDER_ID : parentFolder.parentFolderId) &&
          (isNil(stateFolder.documentModel) ? true : stateFolder.documentModel.foreignKey === entityId) &&
          stateFolder.folderEntity === FolderEntity.FOLDER
      );
      if (!isNil(currentCategoryFolder)) return;

      const folderToAdd: FolderModel = {
        ...basicCreateFolderObject,
        id: uuidv4(),
        folderEntity: FolderEntity.FOLDER,
        translatedLabel: getTranslatedCategory(category, getTypedLanguage(language)),
        documentCategory: category,
        folderDepth: newFolder ? `${newFolder.folderDepth}.${newFolder.documentCategory?.id}` : 'rootFolder',
        folderDepthString: newFolder
          ? `${newFolder.folderDepthString === '/' ? '' : newFolder.folderDepthString}/${newFolder.translatedLabel}`
          : '/',
        parentFolderId: parentFolder?.id ?? ROOT_PARENT_FOLDER_ID,
      } as FolderModel;

      foldersToAdd.push(folderToAdd);
    });
    dispatch({
      type: 'SET_CATEGORY_FOLDERS',
      payload: {
        folders: foldersToAdd,
      },
    });
  };

  const changeCategoryFolders = (
    folders: FolderModel[],
    parentFolder?: FolderModel,
    documentFolder?: FolderModel,
    categoriesToAdd?: DocumentCategoryWithoutReadIdAndClientId,
    categoriesToWorkWith?: DocumentCategoryWithoutReadIdAndClientId[]
  ) => {
    if (state.onPage) {
      if (isNil(documentFolder) && isNil(parentFolder)) {
        dispatch({
          type: 'SET_CATEGORY_FOLDERS',
          payload: {
            folders: [],
          },
        });
        return;
      }
      if (isNil(documentFolder)) return;

      const parentClientFolder = state.folders.find((folder) => folder.id === documentFolder?.parentFolderId);
      const documentsCategories =
        categoriesToWorkWith ??
        getCategoriesForEntity(
          isNil(parentClientFolder)
            ? (documentFolder.documentCategory.entity as EntityType)
            : (parentClientFolder.documentCategory.entity! as EntityType)
        );
      let categoryFolders = documentsCategories;
      if (!isNil(categoriesToAdd)) {
        categoryFolders = [...documentsCategories, categoriesToAdd];
      }
      setCategoryFolders(categoryFolders, folders, documentFolder, parentFolder);
      return;
    }
    let categoryFolders = categoriesToWorkWith ?? getCategoriesForEntity(state.entityType!);
    if (!isNil(categoriesToAdd)) {
      categoryFolders = [...categoryFolders, categoriesToAdd];
    }
    setCategoryFolders(categoryFolders, folders, documentFolder, parentFolder);
  };

  const changeVisibilityAllFolders = () => {
    if (state.seeAllFolders || (state.onPage && state.folderNavigation.length === 1)) {
      dispatch({
        type: 'SET_CATEGORY_FOLDERS',
        payload: {
          folders: [],
        },
      });
    }

    if (!state.seeAllFolders) {
      seeAllFolders();
    }
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.SEE_ALL_FOLDERS], value: !state.seeAllFolders },
    });
  };

  const seeAllFolders = (categories?: DocumentCategoryWithoutReadIdAndClientId) => {
    const lastInFolderNavigation = last(state.folderNavigation);
    const showCategoryFolders =
      isNil(lastInFolderNavigation) || lastInFolderNavigation.documentCategory.fileCategory === FileCategory.CUSTOM;
    if (!showCategoryFolders) return;
    if (!state.onPage) {
      const currentFolder = lastInFolderNavigation;
      changeCategoryFolders(state.folders, currentFolder, currentFolder, categories);
    }
    if (state.onPage && state.folderNavigation.length > 1) {
      const parentFolder = state.folders.find((folder) => folder.id === lastInFolderNavigation?.parentFolderId);
      const documentsCategories = getCategoriesForEntity(parentFolder?.documentCategory.entity as EntityType);
      let categoriesForFolders = documentsCategories;
      if (!isNil(categories)) {
        categoriesForFolders = [...categoriesForFolders, categories];
      }
      setCategoryFolders(
        categoriesForFolders,
        state.folders,
        lastInFolderNavigation,
        parentFolder,
        parentFolder?.entityId
      );
    }
  };

  const renameFolder = async (folder: FolderModel, newLabels: CustomLabel[]) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: true },
    });
    if (isNil(folder.documentCategory)) return;
    const updatedDocumentCategory = await updateFileCategory(folder.documentCategory, {
      labels: newLabels,
    });

    const folderIdsToChange: string[] = [];

    folderIdsToChange.push(folder.id);

    const updatedFolders = state.folders.map((stateFolder) => {
      if (
        stateFolder.documentCategory?.id === folder.documentCategory.id &&
        stateFolder.folderEntity === FolderEntity.FOLDER
      ) {
        return {
          ...stateFolder,
          documentCategory: updatedDocumentCategory,
          translatedLabel: getTranslatedCategory(updatedDocumentCategory, getTypedLanguage(language)),
        };
      }
      return stateFolder;
    });

    dispatch({
      type: 'INITIALIZE_FOLDERS',
      payload: updatedFolders,
    });

    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
    });
    const lastFolderInNavigation = last(state.folderNavigation);
    setFilteredFolders(updatedFolders, lastFolderInNavigation?.documentCategory, lastFolderInNavigation);
  };

  const getDocumentsListForEntity = (entityType: EntityType): DocumentModel[] => {
    if (isNil(state.files[entityType])) return [];
    return [...state.files[entityType]];
  };

  const getCategoriesForEntity = (entityType: EntityType): DocumentCategoryWithoutReadIdAndClientId[] => {
    if (isNil(state.categories[entityType])) return [];
    return [...state.categories[entityType]];
  };

  const cleanup = () => {
    dispatch({ type: 'INITIALIZE_FOLDERS', payload: [] });
    dispatch({ type: 'SET_FILTERED_FOLDERS', payload: [] });
  };

  const setSearchFolders = (folders: FolderModel[]) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.SEARCH_FOLDERS], value: true },
    });
    const searchFolders = folders.map((folder) => {
      if (folder.folderEntity === FolderEntity.FOLDER) {
        const folderInfos = calculateFolderInfo(
          state.folders,
          folder,
          `${folder.folderDepth}.${folder.documentCategory.id}`
        );
        return {
          ...folder,
          size: folderInfos.size,
          updatedAt: folderInfos.uploadedAt,
        };
      }
      return folder;
    });

    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.SEE_ALL_FOLDERS], value: false },
    });
    dispatch({ type: 'SET_CATEGORY_FOLDERS', payload: { folders: [] } });
    dispatch({ type: 'SET_FILTERED_FOLDERS', payload: searchFolders });
  };

  const cancelSearch = () => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.SEARCH_FOLDERS], value: false },
    });
    dispatch({ type: 'SET_EXTENDED_SEARCH_PROPERTIES', payload: { extendedSearch: {} } });
    setNavigation(true, last(state.folderNavigation));
  };

  const getFilteredFolders = (folders: FolderModel[], filters: ExtendedSearchModel) => {
    return folders.filter((folder) => {
      const filterByFolderCategory = !isNil(filters.folderCategory)
        ? folder.documentCategory.id === filters.folderCategory!.id
        : true;

      const filterByFolderEntity = !isNil(filters.folderEntity)
        ? folder.documentCategory.entity === filters.folderEntity
        : true;

      let filterByDate = true;
      if (isNil(folder.updatedAt)) return false;
      if (!isNil(filters.fromDate) && !isNil(filters.toDate)) {
        filterByDate =
          (isAfter(new Date(folder.updatedAt), filters.fromDate) ||
            isSameDay(new Date(folder.updatedAt), filters.toDate)) &&
          (isBefore(new Date(folder.updatedAt), filters.toDate) ||
            isSameDay(new Date(folder.updatedAt), filters.fromDate));
      }
      if (!isNil(filters.fromDate)) {
        filterByDate =
          isAfter(new Date(folder.updatedAt), filters.fromDate) ||
          isSameDay(new Date(folder.updatedAt), filters.fromDate);
      }
      if (!isNil(filters.toDate)) {
        filterByDate =
          isBefore(new Date(folder.updatedAt), filters.toDate) || isSameDay(new Date(folder.updatedAt), filters.toDate);
      }

      return filterByFolderCategory && filterByFolderEntity && filterByDate;
    });
  };

  const setExtendSearchProperties = (extendSearchProperties: ExtendedSearchModel, searchText: string) => {
    dispatch({ type: 'SET_EXTENDED_SEARCH_PROPERTIES', payload: { extendedSearch: extendSearchProperties } });
    const foldersToWorkWith = Object.values(extendSearchProperties).every((filter) => filter === undefined)
      ? state.folders
      : getFilteredFolders(state.folders, extendSearchProperties);
    dispatch({ type: 'SET_FOLDERS_TO_SEARCH_IN', payload: { foldersToSearchIn: foldersToWorkWith } });

    if (!isEmpty(searchText)) {
      const filteredFolders = filterFoldersOnName(foldersToWorkWith, searchText);
      setSearchFolders(filteredFolders);
      return;
    }

    if (Object.values(extendSearchProperties).every((value) => isNil(value))) {
      cancelSearch();
      return;
    }
    setSearchFolders(foldersToWorkWith);
    dispatch({ type: 'SET_SELECTED_FOLDERS', payload: [] });
  };

  const getListOfExtensionTypes = () => {
    const filteredFoldersMimeTypes = compact(
      uniqBy(state.filteredFolders, (folder) => folder.documentModel?.mimeType).reduce<string[]>((acc, folder) => {
        if (!isNil(folder.documentModel?.mimeType)) {
          if (acc.includes('image/*') && folder.documentModel?.mimeType.includes('image')) {
            return acc;
          }

          if (folder.documentModel?.mimeType.includes('image') && !acc.includes('image')) {
            acc.push('image/*');
            return acc;
          }

          acc.push(folder.documentModel?.mimeType);
        }
        return acc;
      }, [])
    );

    state.sortType.forEach((sortType) => {
      if (!filteredFoldersMimeTypes.includes(sortType)) {
        filteredFoldersMimeTypes.push(sortType);
      }
    });
    return filteredFoldersMimeTypes;
  };

  const setSortType = (sortType: string) => {
    dispatch({ type: 'SET_SORT_TYPE', payload: { sortType } });
  };

  const setSortInvoices = (sortInvoiceType: FolderSortInvoiceOptions) => {
    dispatch({ type: 'SET_INVOICE_SORT_TYPE', payload: { sortInvoice: sortInvoiceType } });
  };

  const calculateDownloadFolders = (
    selectedFoldersId: string[]
  ): { fullSelectedFolder: FolderModel[]; downloadOption: FolderDownloadOptions } => {
    const fullSelectedFolders = compact(
      selectedFoldersId.map((folder) => {
        const selectedFolder = state.folders.find((stateFolder) => stateFolder.id === folder);
        if (!selectedFolder) return;
        return selectedFolder;
      })
    );

    let size = 0;
    let numberOfFiles = 0;

    fullSelectedFolders.forEach((folder) => {
      if (folder.folderEntity === FolderEntity.FILE) {
        size += folder.size;
        numberOfFiles += 1;
        return;
      } else {
        const files = getAllFilesInFolder(state.folders, folder);
        files.forEach((file) => {
          size += file.size;
          numberOfFiles += 1;
        });
      }
    });

    const convertSize = size / 1048576;
    if (convertSize < 150 && numberOfFiles < 150) {
      return { fullSelectedFolder: fullSelectedFolders, downloadOption: FolderDownloadOptions.LOCAL };
    }
    return { fullSelectedFolder: fullSelectedFolders, downloadOption: FolderDownloadOptions.BACKEND };
  };

  const downloadFolders = async (
    selectedFolders: FolderModel[],
    downloadOption: FolderDownloadOptions,
    invoiceStructure?: boolean
  ): Promise<{ result?: StatusResult; zippedData?: Record<string, Uint8Array> }> => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: true },
    });

    if (downloadOption === FolderDownloadOptions.LOCAL && !invoiceStructure) {
      const zip = await getZipObjectForFolders(selectedFolders, state.folders, state.searchFolders);
      dispatch({
        type: 'SET_FOLDER_ATTRIBUTES',
        payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
      });

      return { zippedData: zip };
    }
    const input: DownloadFoldersInput = {
      selectedFolders: [
        ...selectedFolders.map((folder) => {
          return { translatedName: folder.translatedLabel, folderDepth: folder.folderDepthString };
        }),
      ],
      clientId: clientId!,
      userId: userId!,
      language: language ?? 'EN',
      onPage: state.onPage!,
      entityId: state.id,
      entityType: state.entityType,
      isSearching: state.searchFolders,
      isOnlyDownloadingInvoices: invoiceStructure,
    };

    const result = await mutation<StatusResult, GetDownloadZipFileUrlMutationVariables>(GetDownloadZipFileUrlMutation, {
      input,
    });

    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.CONTEXT_IS_BUSY], value: false },
    });

    return { result };
  };

  const setOnPage = (onPage: boolean) => {
    dispatch({
      type: 'SET_FOLDER_ATTRIBUTES',
      payload: { key: [FolderBooleanAttributes.ON_PAGE], value: onPage },
    });

    if (onPage) {
      dispatch({
        type: 'SET_FOLDER_ATTRIBUTES',
        payload: { key: [FolderBooleanAttributes.INITIAL_LOADING], value: true },
      });
    }
  };

  const setUploadFiles = (uploadFiles: UploadFile[]) => {
    dispatch({
      type: 'SET_UPLOAD_FILES',
      payload: uploadFiles,
    });
  };

  const values = useMemo(
    () => ({
      ...state,
      calculateFolders,
      renameFile,
      renameFolder,
      setFilteredFolders,
      setNavigation,
      addFilesToFolder,
      changeSelectedFolder,
      changeDragging,
      setSelectedFolders,
      deselectFolders,
      dropFolder,
      addNewFolder,
      setDraggedFolders,
      deleteFolders,
      selectSearchFolder,
      setIsBusy,
      changeVisibilityAllFolders,
      calculatePageFolder,
      setIdAndType,
      cleanup,
      getCategoriesForEntity,
      setSearchFolders,
      cancelSearch,
      setExtendSearchProperties,
      getListOfExtensionTypes,
      setSortType,
      setSortInvoices,
      downloadFolders,
      calculateDownloadFolders,
      setOnPage,
      setUploadFiles,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );
  return <FoldersContext.Provider value={values}>{children}</FoldersContext.Provider>;
};
export const useDocuments = (): FoldersContext => {
  const context = useContext<FoldersContext | null>(FoldersContext);
  if (!context) {
    throw new Error('useDocuments must be used within a DocumentsContextProvider');
  }

  return context as FoldersContext;
};
