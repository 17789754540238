import {
  DocumentType,
  EntityType,
  FEATURE_INVENTORY,
  FileCategory,
  hasLeaseAlreadyBeenEnded,
  isFeatureEnabled,
  LeaseActionHistory,
  LeaseStatus,
  S3Object,
  SignatureDocument,
  SignatureDocumentResult,
  SignatureDocumentStatus,
} from '@rentguru/commons-utils';
import { useEffect, useState } from 'react';
import { LeaseExtended, useLeases } from 'src/hooks/LeasesContext';
import { useSignatureDocuments } from 'src/hooks/SignatureDocumentContext';
import {
  hasEnoughRightsToDeepDeleteLease,
  PermissionsContext,
  usePermissions,
} from 'src/hooks/utils/PermissionsContext';
// Normal icons:
import { ReactComponent as SetToDraftIcon } from 'src/icons/Back_To_Draft_Action.svg';
import { ReactComponent as DeleteIcon } from 'src/icons/Lease_Action_Trash.svg';
import { ReactComponent as EditIcon } from 'src/icons/Lease_Edit_Action.svg';
import { ReactComponent as RetractIcon } from 'src/icons/Retract_Lease_Action.svg';
import { ReactComponent as AddInventoryOfFixture } from 'src/icons/icon-add-inventory-fixture.svg';
import { ReactComponent as AddInventoryOfFurniture } from 'src/icons/icon-add-inventory-furniture.svg';
import { ReactComponent as AddMeterIcon } from 'src/icons/icon-add-meter.svg';
import { ReactComponent as AddTechnicIcon } from 'src/icons/icon-add-technic.svg';
import { ReactComponent as AddTicketIcon } from 'src/icons/icon-add-ticket.svg';
import { ReactComponent as AddChargeIcon } from 'src/icons/lease-action-addcharge.svg';
import { ReactComponent as AddServiceIcon } from 'src/icons/lease-action-addservice.svg';
import { ReactComponent as ChangeBankIcon } from 'src/icons/lease-action-bank.svg';
import { ReactComponent as ChangeChargeIcon } from 'src/icons/lease-action-charge.svg';
import { ReactComponent as AddCreditNoteIcon } from 'src/icons/lease-action-creditnote.svg';
import { ReactComponent as AddDiscountIcon } from 'src/icons/lease-action-discount.svg';
import { ReactComponent as ExtendIcon } from 'src/icons/lease-action-extend.svg';
import { ReactComponent as ChangeProfessionalIcon } from 'src/icons/lease-action-professional.svg';
import { ReactComponent as SignIcon } from 'src/icons/lease-action-sign.svg';
import { ReactComponent as ChangeTenantsIcon } from 'src/icons/lease-action-tenant.svg';
import { ReactComponent as ChangeUnitsIcon } from 'src/icons/lease-action-units.svg';
import { ReactComponent as ViewPDFIcon } from 'src/icons/lease-action-view-pdf.svg';
import { ReactComponent as ReceiptIcon } from 'src/icons/receipt-with-circle.svg';
// Icons without circle :
import { ReactComponent as CreateInventoryFixtureIconWithoutCircle } from 'src/icons/icon-add-inventory-fixture-nocircle.svg';
import { ReactComponent as CreateInventoryFurnitureIconWithoutCircle } from 'src/icons/icon-add-inventory-furniture-nocircle.svg';
import { ReactComponent as AddMeterIconWithoutCircle } from 'src/icons/icon-add-meter-nocircle.svg';
import { ReactComponent as AddTechnicIconWithoutCircle } from 'src/icons/icon-add-technic-nocircle.svg';
import { ReactComponent as AddIssueIconWithoutCircle } from 'src/icons/icon-add-ticket-nocircle.svg';
import { ReactComponent as AddChargeIconWithoutCircle } from 'src/icons/lease-action-addcharge-nocircle.svg';
import { ReactComponent as AddServiceIconWithoutCircle } from 'src/icons/lease-action-addservice-nocircle.svg';
import { ReactComponent as BankIconWithoutCircle } from 'src/icons/lease-action-bank-nocircle.svg';
import { ReactComponent as AddCreditNoteIconWithoutCircle } from 'src/icons/lease-action-creditnote-nocircle.svg';
import { ReactComponent as AddDiscountIconWithoutCircle } from 'src/icons/lease-action-discount-nocircle.svg';
import { ReactComponent as ExtendIconWithoutCircle } from 'src/icons/lease-action-extend-nocircle.svg';
import { ReactComponent as GeneratePDFIcon } from 'src/icons/lease-action-pdf.svg';
import { ReactComponent as ProfessionalIconWithoutCircle } from 'src/icons/lease-action-professional-nocircle.svg';
import { ReactComponent as ViewPDFIconWithoutCircle } from 'src/icons/lease-action-view-pdf-nocircle.svg';
import { ReactComponent as ReceiptIconWithoutCircle } from 'src/icons/receipt.svg';

import { addDays, isAfter } from 'date-fns';
import { isEmpty, isNil } from 'lodash';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import { getS3ObjectUrls, useFiles } from 'src/hooks/FilesContext';
import { useLeaseActionHistories } from 'src/hooks/LeaseActionHistoriesContext';
import { useUser } from 'src/hooks/UserContext';
import useLeaseInventoryUtils from 'src/hooks/useLeaseInventoryUtils';
import useBaseLeaseActionMenuUtils from './useBaseLeaseActionMenuUtils';

// eslint-disable-next-line no-shadow
export enum LeaseAction {
  ADD_SERVICE,
  ADD_CHARGE,
  ADD_DISCOUNT,
  ADD_CUSTOM_INVOICE,
  ADD_CREDIT_NOTE,
  EXTEND,
  END,
  CHANGE_UNITS,
  CHANGE_BANK,
  CHANGE_TENANTS,
  ADJUST_CHARGE,
  CHANGE_PROFESSIONAL_ACTIVITY,
  ADD_DOCUMENT,
  SET_TO_DRAFT,
  EDIT,
  GENERATE_PDF,
  RETRACT,
  SIGN,
  DELETE,
  VIEW_PDF,
  ADD_TECHNIC,
  ADD_METER,
  ADD_TICKET,
  ADD_INVENTORY_OF_FIXTURE,
  ADD_FURNITURE_INVENTORY,
  ADD_TRANSACTION,
  ADD_LEASE,
  REJECT_DOCUMENT_SIGNATURE,
}

export interface QuickAction {
  type: LeaseAction;
  // eslint-disable-next-line no-undef
  iconComponent: JSX.Element;
  // eslint-disable-next-line no-undef
  iconComponentWithoutCircle?: JSX.Element;
  helperLabelId: string;
  textLabelId: string;
  hasEnoughRights: boolean;
  order: number;
}

const tenantOperations = [
  {
    type: LeaseAction.VIEW_PDF,
    iconComponent: <ViewPDFIcon style={{ marginRight: 0 }} />,
    iconComponentWithoutCircle: <ViewPDFIconWithoutCircle style={{ display: 'block' }} />,
    helperLabelId: '',
    textLabelId: 'lease.detail.action.openPdf',
    order: 1,
    hasEnoughRights: true,
  },
  {
    type: LeaseAction.SIGN,
    iconComponent: <SignIcon style={{ display: 'block' }} />,
    helperLabelId: '',
    textLabelId: 'lease.detail.action.signOnline',
    order: 1,
    hasEnoughRights: true,
  },
  {
    type: LeaseAction.ADD_TICKET,
    iconComponent: <AddTicketIcon style={{ margin: 0 }} />,
    iconComponentWithoutCircle: <AddIssueIconWithoutCircle />,
    helperLabelId: '',
    textLabelId: 'lease.detail.action.addTicket',
    order: 6,
    hasEnoughRights: true,
  },
  {
    type: LeaseAction.REJECT_DOCUMENT_SIGNATURE,
    iconComponent: <RetractIcon style={{ margin: 0 }} />,
    helperLabelId: '',
    textLabelId: 'lease.detail.action.reject',
    order: 6,
    hasEnoughRights: true,
  },
];

const accountingOperations = [
  {
    type: LeaseAction.ADD_SERVICE,
    iconComponent: <AddServiceIcon />,
    iconComponentWithoutCircle: <AddServiceIconWithoutCircle />,
    helperLabelId: 'lease.detail.action.addServiceHelp',
    textLabelId: 'lease.detail.action.addService',
    order: 1,
  },
  {
    type: LeaseAction.ADD_CHARGE,
    iconComponent: <AddChargeIcon />,
    iconComponentWithoutCircle: <AddChargeIconWithoutCircle />,
    helperLabelId: 'lease.detail.action.addChargeHelp',
    textLabelId: 'lease.detail.action.addCharge',
    order: 1,
  },
  {
    type: LeaseAction.ADD_DISCOUNT,
    iconComponent: <AddDiscountIcon />,
    iconComponentWithoutCircle: <AddDiscountIconWithoutCircle />,
    helperLabelId: '',
    textLabelId: 'lease.detail.action.addDiscount',
    order: 1,
  },
  {
    type: LeaseAction.ADD_CUSTOM_INVOICE,
    iconComponent: <ReceiptIcon />,
    iconComponentWithoutCircle: <ReceiptIconWithoutCircle />,
    helperLabelId: '',
    textLabelId: 'lease.detail.addCustomInvoice.title',
    order: 1,
  },
  {
    type: LeaseAction.ADD_CREDIT_NOTE,
    iconComponent: <AddCreditNoteIcon />,
    iconComponentWithoutCircle: <AddCreditNoteIconWithoutCircle />,
    helperLabelId: '',
    textLabelId: 'lease.detail.action.addCreditNote',
    order: 1,
  },
];

const leaseOperations = [
  {
    type: LeaseAction.EXTEND,
    iconComponent: <ExtendIcon />,
    iconComponentWithoutCircle: <ExtendIconWithoutCircle />,
    helperLabelId: '',
    textLabelId: 'lease.detail.action.extendLease',
    order: 2,
  },
  {
    type: LeaseAction.END,
    iconComponent: <ExtendIcon />,
    iconComponentWithoutCircle: <ExtendIconWithoutCircle />,
    helperLabelId: '',
    textLabelId: 'lease.detail.action.endLease',
    order: 2,
  },
  {
    type: LeaseAction.CHANGE_UNITS,
    iconComponent: <ChangeUnitsIcon />,
    helperLabelId: '',
    textLabelId: 'lease.detail.action.addRemoveUnit',
    order: 3,
  },
  {
    type: LeaseAction.CHANGE_BANK,
    iconComponent: <ChangeBankIcon />,
    iconComponentWithoutCircle: <BankIconWithoutCircle />,
    helperLabelId: '',
    textLabelId: 'lease.detail.action.changeBank',
    order: 4,
  },
  {
    type: LeaseAction.CHANGE_TENANTS,
    iconComponent: <ChangeTenantsIcon />,
    helperLabelId: '',
    textLabelId: 'lease.detail.action.changeTenant',
    order: 5,
  },
];

const adjustChargeOperation = {
  type: LeaseAction.ADJUST_CHARGE,
  iconComponent: <ChangeChargeIcon />,
  helperLabelId: '',
  textLabelId: 'lease.detail.action.rentChargesAdjustment',
  order: 1,
};

const professionalActivityOperation = {
  type: LeaseAction.CHANGE_PROFESSIONAL_ACTIVITY,
  iconComponent: <ChangeProfessionalIcon />,
  iconComponentWithoutCircle: <ProfessionalIconWithoutCircle />,
  helperLabelId: '',
  textLabelId: 'lease.detail.action.addProfessionalUse',
  order: 6,
};

const previewPdfAction = {
  type: LeaseAction.VIEW_PDF,
  iconComponent: <ViewPDFIcon style={{ display: 'block' }} />,
  iconComponentWithoutCircle: <ViewPDFIconWithoutCircle style={{ display: 'block' }} />,
  helperLabelId: '',
  textLabelId: 'lease.detail.action.openPdf',
  order: 1,
};
const signPdfAction = {
  type: LeaseAction.SIGN,
  iconComponent: <SignIcon style={{ display: 'block' }} />,
  helperLabelId: '',
  textLabelId: 'lease.detail.action.signOnline',
  order: 1,
};

const setToDraftAction = {
  type: LeaseAction.SET_TO_DRAFT,
  iconComponent: <SetToDraftIcon style={{ display: 'block' }} />,
  helperLabelId: '',
  textLabelId: 'lease.detail.action.changeAsDraft',
  order: 2,
};

const editAction = {
  type: LeaseAction.EDIT,
  iconComponent: <EditIcon style={{ display: 'block' }} />,
  helperLabelId: '',
  textLabelId: 'edit',
  order: 2,
};

const deleteAction = {
  type: LeaseAction.DELETE,
  iconComponent: <DeleteIcon style={{ display: 'block' }} />,
  helperLabelId: '',
  textLabelId: 'delete',
  order: 3,
};

const retractAction = {
  type: LeaseAction.RETRACT,
  iconComponent: <RetractIcon style={{ display: 'block' }} />,
  helperLabelId: '',
  textLabelId: 'lease.detail.action.retract',
  order: 3,
};

const generatePdfAction = {
  type: LeaseAction.GENERATE_PDF,
  iconComponent: <GeneratePDFIcon style={{ display: 'block' }} />,
  helperLabelId: '',
  textLabelId: 'lease.detail.action.generatePdf',
  order: 4,
};

const addTechnicAction = {
  type: LeaseAction.ADD_TECHNIC,
  iconComponent: <AddTechnicIcon style={{ margin: 0, padding: 0 }} />,
  iconComponentWithoutCircle: <AddTechnicIconWithoutCircle />,
  helperLabelId: '',
  textLabelId: 'lease.detail.action.addTechnic',
  order: 5,
};

const addMeterAction = {
  type: LeaseAction.ADD_METER,
  iconComponent: <AddMeterIcon style={{ margin: 0 }} />,
  iconComponentWithoutCircle: <AddMeterIconWithoutCircle />,
  helperLabelId: '',
  textLabelId: 'lease.detail.action.addMeter',
  order: 5,
};

const addTicketAction = {
  type: LeaseAction.ADD_TICKET,
  iconComponent: <AddTicketIcon style={{ margin: 0 }} />,
  iconComponentWithoutCircle: <AddIssueIconWithoutCircle />,
  helperLabelId: '',
  textLabelId: 'lease.detail.action.addTicket',
  order: 6,
};

const addInventoryOfFixtureAction = {
  type: LeaseAction.ADD_INVENTORY_OF_FIXTURE,
  iconComponent: <AddInventoryOfFixture style={{ margin: 0 }} />,
  iconComponentWithoutCircle: <CreateInventoryFixtureIconWithoutCircle />,
  helperLabelId: '',
  textLabelId: 'lease.detail.action.addInventoryOfFixture',
  order: 7,
};

const addInventoryOfFurnitureAction = {
  type: LeaseAction.ADD_FURNITURE_INVENTORY,
  iconComponent: <AddInventoryOfFurniture style={{ margin: 0 }} />,
  iconComponentWithoutCircle: <CreateInventoryFurnitureIconWithoutCircle />,
  helperLabelId: '',
  textLabelId: 'lease.detail.action.addInventoryOfFurniture',
  order: 8,
};

const EXTENSION_DAYS_LIMIT_AFTER_END = 30;

const canStillSign = (userSignatureResult: SignatureDocumentResult | null | undefined) => {
  return userSignatureResult && userSignatureResult.signatureLink && !userSignatureResult.signed;
};

export const getQuickActionsForStatusAndRights = (
  lease: LeaseExtended,
  permissions: PermissionsContext,
  features: string[],
  isOwner: boolean,
  leaseContract: S3Object | null | undefined,
  userSignatureResult: SignatureDocumentResult | null | undefined,
  isTenant = false,
  leaseActionHistories: LeaseActionHistory[] = []
): QuickAction[] => {
  const {
    leasesCreationWrite,
    financialTransactionsWrite,
    ticketsDetailsWrite,
    inventoryOfFixturesCreationWrite,
    furnituresInventoryCreationWrite,
    leasesDetailsWrite,
  } = permissions;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { hasBothFixtureEntranceAndExitInventory, hasBothFurnitureEntranceAndExitInventory } = useLeaseInventoryUtils(
    lease.id,
    isTenant
  );
  if (isTenant) {
    if (leaseContract) {
      switch (lease.status) {
        case LeaseStatus.Cancelled:
        case LeaseStatus.Rejected:
          return tenantOperations;
        case LeaseStatus.OutForSignature:
          if (!canStillSign(userSignatureResult)) {
            return tenantOperations.filter(
              (operation) => ![LeaseAction.REJECT_DOCUMENT_SIGNATURE, LeaseAction.SIGN].includes(operation.type)
            );
          }
          return tenantOperations;
        default:
          break;
      }
    }
    return tenantOperations.filter((operation) => operation.type !== LeaseAction.VIEW_PDF);
  }
  /* Owner */
  if (isOwner) {
    const actions = [];
    if (
      [
        LeaseStatus.Rejected,
        LeaseStatus.Active,
        LeaseStatus.Ended,
        LeaseStatus.Draft,
        LeaseStatus.OutForSignature,
      ].includes(lease.status as LeaseStatus)
    ) {
      // Preview
      actions.push({ ...previewPdfAction, hasEnoughRights: Boolean(leaseContract) });
    }
    if (lease.status === LeaseStatus.OutForSignature && canStillSign(userSignatureResult)) {
      // Check for sign button
      actions.push({ ...signPdfAction, hasEnoughRights: true });
    }

    return actions;
  }

  /* Member */
  // Active and Ended
  if ([LeaseStatus.Active, LeaseStatus.Ended].includes(lease.status)) {
    const canAdjustCharge = Boolean(lease.units?.some((unitLease) => unitLease.allowChargeAdjustment));
    const canAddProfessionalActivity = Boolean(
      lease.units?.some((unitLease) => !unitLease.authorizeProfessionalActivity)
    );
    const isLeaseAlreadyEnded = hasLeaseAlreadyBeenEnded(leaseActionHistories);

    const actions = [];
    actions.push(
      ...accountingOperations.map((operation) => ({ ...operation, hasEnoughRights: financialTransactionsWrite }))
    );

    const leaseOperationsFiltered = leaseOperations.filter((operation) => {
      if (lease.status === LeaseStatus.Ended) {
        if ([LeaseAction.CHANGE_BANK, LeaseAction.END].includes(operation.type)) {
          return false;
        }
        if (
          operation.type === LeaseAction.EXTEND &&
          isAfter(new Date(), addDays(new Date(lease.endDate), EXTENSION_DAYS_LIMIT_AFTER_END))
        ) {
          return false;
        }
      }
      return !isLeaseAlreadyEnded || (isLeaseAlreadyEnded && LeaseAction.END !== operation.type);
    });

    actions.push(
      ...leaseOperationsFiltered.map((operation) => ({ ...operation, hasEnoughRights: leasesCreationWrite }))
    );

    if (canAdjustCharge) {
      actions.push({ ...adjustChargeOperation, hasEnoughRights: leasesDetailsWrite });
    }
    if (canAddProfessionalActivity) {
      actions.push({ ...professionalActivityOperation, hasEnoughRights: true });
    }

    actions.push({ ...addTicketAction, hasEnoughRights: ticketsDetailsWrite });

    if (isFeatureEnabled(features, FEATURE_INVENTORY) && !hasBothFixtureEntranceAndExitInventory)
      actions.push({ ...addInventoryOfFixtureAction, hasEnoughRights: inventoryOfFixturesCreationWrite });
    if (isFeatureEnabled(features, FEATURE_INVENTORY) && !hasBothFurnitureEntranceAndExitInventory)
      actions.push({ ...addInventoryOfFurnitureAction, hasEnoughRights: furnituresInventoryCreationWrite });

    if (!isNil(leaseContract)) actions.push({ ...previewPdfAction, hasEnoughRights: true });
    if (lease.status === LeaseStatus.Ended) {
      actions.push({ ...previewPdfAction, hasEnoughRights: true });
    }
    actions.push({ ...deleteAction, hasEnoughRights: hasEnoughRightsToDeepDeleteLease(permissions) });

    return actions;
  }

  // Other status
  const actions = [];
  if (
    [LeaseStatus.Rejected, LeaseStatus.Active, LeaseStatus.Draft, LeaseStatus.OutForSignature].includes(lease.status) &&
    Boolean(leaseContract)
  ) {
    // Preview
    actions.push({ ...previewPdfAction, hasEnoughRights: true });
  }
  if ([LeaseStatus.Rejected, LeaseStatus.Cancelled].includes(lease.status as LeaseStatus)) {
    actions.push({ ...setToDraftAction, hasEnoughRights: leasesCreationWrite });
  }
  if (lease.status === LeaseStatus.OutForSignature) {
    actions.push({ ...retractAction, hasEnoughRights: leasesCreationWrite });
    if (canStillSign(userSignatureResult)) {
      actions.push({ ...signPdfAction, hasEnoughRights: true });
    }
  }
  if ([LeaseStatus.Rejected, LeaseStatus.Draft].includes(lease.status as LeaseStatus)) {
    actions.push({ ...deleteAction, hasEnoughRights: hasEnoughRightsToDeepDeleteLease(permissions) });
  }

  if (lease.status === LeaseStatus.Draft) {
    actions.push({ ...editAction, hasEnoughRights: leasesCreationWrite });
    actions.push({ ...generatePdfAction, hasEnoughRights: leasesCreationWrite });
  }

  return actions;
};

const useLeaseActionsMenuUtils = (lease: LeaseExtended) => {
  const history = useHistory();
  const { getSignatureDocumentsForEntity, setSignatureResultToZero } = useSignatureDocuments();
  const { getLeaseActionHistoriesOfLease } = useLeaseActionHistories();
  const { updateLease } = useLeases();
  const { isOwner, ownerId, memberId } = useUser(); // Owner has less lease actions than admin
  const { generateDocument, getFiles, loading: filesLoading } = useFiles();
  const [leaseContract, setLeaseContract] = useState<S3Object | null | undefined>(undefined); // Undefined = loading

  const connectedUserEntityId = isOwner ? ownerId : memberId;
  const pendingSignatureDocuments = getSignatureDocumentsForEntity('Lease', lease.id, SignatureDocumentStatus.PENDING);
  const currentSignatureResult =
    pendingSignatureDocuments && pendingSignatureDocuments.length > 0
      ? pendingSignatureDocuments[0].signatureResults.find((result) => result.contactId === connectedUserEntityId)
      : null;

  const leaseActionHistories = getLeaseActionHistoriesOfLease(lease.id);

  useEffect(() => {
    let unmounted = false;
    const fetchNetworkData = async () => {
      const files = getFiles(EntityType.LEASE, [lease.id]);
      const contract = await getS3ObjectUrls(
        files.filter((file) => file.category?.fileCategory === FileCategory.LEASE_CONTRACT)
      );
      if (!unmounted) {
        if (!isEmpty(contract)) {
          setLeaseContract(contract[0]);
        } else {
          setLeaseContract(null);
        }
      }
    };
    fetchNetworkData();
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesLoading]);

  const { actions } = useBaseLeaseActionMenuUtils(
    lease,
    getQuickActionsForStatusAndRights,
    currentSignatureResult,
    false,
    leaseActionHistories,
    leaseContract
  );

  const generatePdfForLease = async () => {
    await generateDocument({ id: lease.id, type: DocumentType.Lease });
  };

  const sortedActions = actions.sort((a, b) => a.order - b.order);

  const editLease = () => {
    history.push({
      pathname: RouteDestination.EDIT_LEASE,
      state: { id: lease.id },
    });
  };

  const setLeaseToDraft = async () => {
    const signaturePromises: Promise<SignatureDocument>[] = [];
    pendingSignatureDocuments?.forEach((document) => {
      signaturePromises.push(setSignatureResultToZero(document));
    });
    await updateLease(lease, { status: LeaseStatus.Draft });
    await Promise.all(signaturePromises);
  };

  return {
    actions: sortedActions,
    leaseContract,
    editLease,
    setLeaseToDraft,
    generatePdfForLease,
    signatureResult: currentSignatureResult,
  };
};

export const usePropertyActionsUtils = () => {
  const permissions = usePermissions();
  const { features } = useUser();
  const {
    buildingsUnitsTechnicsWrite,
    ticketsDetailsWrite,
    inventoryOfFixturesCreationWrite,
    furnituresInventoryCreationWrite,
  } = permissions;
  const propertyActions = [
    { ...addTechnicAction, hasEnoughRights: buildingsUnitsTechnicsWrite },
    { ...addMeterAction, hasEnoughRights: buildingsUnitsTechnicsWrite },
    { ...addTicketAction, hasEnoughRights: ticketsDetailsWrite },
  ];

  if (isFeatureEnabled(features, FEATURE_INVENTORY)) {
    propertyActions.push({ ...addInventoryOfFixtureAction, hasEnoughRights: inventoryOfFixturesCreationWrite });
    propertyActions.push({ ...addInventoryOfFurnitureAction, hasEnoughRights: furnituresInventoryCreationWrite });
  }
  return {
    actions: propertyActions,
  };
};

export default useLeaseActionsMenuUtils;
