import { Grid, Tooltip, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import { TextDetailEditable } from '@up2rent/ui';
import { useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import TextFieldArea from 'src/components/ui/TextFieldArea';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import EndLeaseFileField from './EndLeaseFileField';
import { EndLeaseFormValues, useEndLeaseStyles } from './EndLeaseForm';
import EndLeaseIndemnityField, { IndemnityFieldProps } from './EndLeaseIndemnityField';

const EndLeaseNotarialOrCourtFields = (props: IndemnityFieldProps) => {
  const { formatMessage } = useIntl();
  const { values } = useFormikContext<EndLeaseFormValues>();
  const classes = useEndLeaseStyles();

  return (
    <>
      <EndLeaseFileField />
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
        <Typography className={classes.informationFillInTitle}>
          {formatMessage({ id: `lease.endExtendLease.compensation` })}
        </Typography>
        <Tooltip title={formatMessage({ id: 'lease.endExtendLease.compensationInfo' })} placement="right">
          <Grid>
            <InfoSvg style={{ marginLeft: 10, fill: Colors.SILVER }} />
          </Grid>
        </Tooltip>
      </div>
      <TextDetailEditable
        editMode
        title={formatMessage({
          id: `lease.detail.variousOperations.label`,
        })}
        name="amountDue.totalAmountLabel"
        style={{ width: 540, maxWidth: 'none' }}
      />
      <EndLeaseIndemnityField {...props} />

      <Typography
        className={classes.informationFillInTitle}
        style={{
          marginTop: 20,
        }}
      >
        {formatMessage({ id: `lease.endExtendLease.internalNote` })}
      </Typography>
      <TextFieldArea
        name="comment"
        rows={3}
        rowsMax={3}
        placeholder={formatMessage({
          id: `lease.endExtendLease.comment`,
        })}
        inputProps={{ style: { textAlign: 'left', bottom: 0, padding: 20 } }}
        className={classes.textField}
        value={values.comment}
        onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
      />
    </>
  );
};

export default EndLeaseNotarialOrCourtFields;
