import { Collapse, FormControl, FormControlLabel, Grid, RadioGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  Colors,
  CommunicationChannel,
  getUTCDate,
  InvoiceWithPostings,
  isDateValid,
  Language,
  LeaseActionEndInitiator,
  LeaseActionEndReason,
  LeaseExtended,
  LeaseType,
  NewFile,
  Template,
} from '@rentguru/commons-utils';
import { differenceInDays, isAfter } from 'date-fns';
import { useFormikContext } from 'formik';
import { isNil } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import StyledRadio from 'src/components/ui/StyledRadio';
import { useInvoices } from 'src/hooks/InvoicesContext';
import { fetchPostings } from 'src/hooks/TransactionsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { hasEnoughRightsToDeepDeleteLease, usePermissions } from 'src/hooks/utils/PermissionsContext';
import * as Yup from 'yup';
import EndLeaseEndDateFields from './EndLeaseEndDateFields';
import EndLeaseLessorFields from './EndLeaseLessorFields';
import EndLeaseMutualFields from './EndLeaseMutualFields';
import EndLeaseNotarialOrCourtFields from './EndLeaseNotarialOrCourtFields';
import EndLeaseTenantFields from './EndLeaseTenantFields';
import { calculateMaxDate, getNumberOfMonthsForIndemnity, isInFlanders, isShortTermsLease } from './utils';

const NUMBER_OF_DAYS_TO_DELETE_LEASE = 20;

export const getEndLeaseSchema = (startDate: string) =>
  Yup.object()
    .shape({
      initiator: Yup.string().required(),
      endDate: Yup.date()
        .required()
        .test((value) => isDateValid(value) && isAfter(value!, new Date(startDate))),
      comment: Yup.string().nullable(),
      amountDue: Yup.object().shape({
        selectedTab: Yup.string().required(),
        totalAmount: Yup.number().required(),
        userTotalAmount: Yup.number().required(),
        amountOfMonths: Yup.number().required(),
        totalAmountLabel: Yup.string().notRequired(),
      }),
      studentValidEndReason: Yup.boolean().required(),
      lessorEndNotice: Yup.object().shape({
        responsible: Yup.string().required(),
        channel: Yup.string().required(),
        sendDate: Yup.string().required(),
        endReason: Yup.string().required(),
        endReasonOwnUseName: Yup.string().notRequired(),
        endReasonOwnUseRelation: Yup.string().notRequired(),
      }),
    })
    .required();

export type EndLeaseFormValues = {
  initiator?: LeaseActionEndInitiator;
  endDate: string;
  comment?: string;
  files: NewFile[];
  amountDue: {
    selectedTab: 'amountOfMonths' | 'amountInEur';
    totalAmount: number;
    userTotalAmount: number;
    amountOfMonths: number;
    totalAmountLabel: string;
  };
  studentValidEndReason: boolean;
  lessorEndNotice: {
    responsible: 'LESSOR' | 'UP2RENT';
    channel: CommunicationChannel;
    sendDate: string;
    endReason: LeaseActionEndReason;
    endReasonOwnUseName: string;
    endReasonOwnUseRelation: string;
  };
  shouldOpenAddStatementDialog: boolean;
  redirectToStatement: boolean;
  template?: Template;
};

export const useEndLeaseStyles = makeStyles({
  actionRadioText: {
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: Colors.CLASSICAL_BLACK,
  },
  actionInnerCollapse: {
    backgroundColor: Colors.PORCELAIN_GREY_1,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    paddingBottom: 20,
  },
  infoMessageText: {
    fontFamily: 'Mulish',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: Colors.SLATE_GREY,
    textAlign: 'left',
  },
  textField: {
    backgroundColor: Colors.PORCELAIN_GREY_3,
    fontSize: 14,
    fontWeight: 600,
    width: 540,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottom: `2px solid ${Colors.TOWER_GREY}`,
    color: Colors.BLUE_GREY,
    marginTop: 10,
  },
  informationFillInTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: Colors.CLASSICAL_BLACK,
  },
  alertBoxDiv: { display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 },
  informationBlackTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: Colors.CLASSICAL_BLACK,
  },
});

export const iconnedRemarkMargins = {
  marginRight: 0,
  marginTop: 15,
  marginBottom: 0,
  marginLeft: 0,
};

interface EndLeaseFormProps {
  lease: LeaseExtended;
}

export const getEndLeaseInitialValues = (lease: LeaseExtended, template?: Template): EndLeaseFormValues => {
  const leaseEndDate = getUTCDate(new Date(lease.endDate)).toISOString();

  return {
    endDate: leaseEndDate,
    files: [],
    amountDue: {
      selectedTab: 'amountOfMonths',
      totalAmount: 0,
      userTotalAmount: 0,
      amountOfMonths: 0,
      totalAmountLabel: '',
    },
    studentValidEndReason: true,
    lessorEndNotice: {
      responsible: 'LESSOR',
      channel: CommunicationChannel.LETTER,
      sendDate: leaseEndDate,
      endReason: LeaseActionEndReason.NONE,
      endReasonOwnUseName: '',
      endReasonOwnUseRelation: '',
    },
    shouldOpenAddStatementDialog: false,
    redirectToStatement: false,
    template,
  };
};

const EndLeaseForm: React.FC<EndLeaseFormProps> = ({ lease }) => {
  const { formatMessage } = useIntl();
  const { values, setValues } = useFormikContext<EndLeaseFormValues>();
  const classes = useEndLeaseStyles();

  const { getInvoicesOfLease, loading: invoicesLoading } = useInvoices();
  const { getUnit } = useUnits();
  const permissions = usePermissions();

  const [invoicesWithPostings, setInvoicesWithPostings] = useState<InvoiceWithPostings[] | null>(null);
  const loading = invoicesLoading;

  const invoices = useMemo(() => {
    return getInvoicesOfLease(lease.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    const buildInvoicesWithPostings = async () => {
      const invoicesWithPostingsResult = await Promise.all(
        invoices.map(async (invoice) => {
          const invoicesPostings = await fetchPostings('byInvoice', invoice.id);
          return { ...invoice, postings: invoicesPostings };
        })
      );
      setInvoicesWithPostings(invoicesWithPostingsResult);
    };

    if (!loading) {
      buildInvoicesWithPostings();
    }
  }, [invoices, loading]);

  useEffect(() => {
    if (values.initiator !== LeaseActionEndInitiator.MUTUAL && values.initiator !== LeaseActionEndInitiator.TENANT)
      setValues({ ...values, endDate: leaseEndDate.toISOString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.initiator]);

  if (loading) {
    return null;
  }

  const leaseStartDate = getUTCDate(new Date(lease.startDate));
  const leaseEndDate = getUTCDate(new Date(lease.endDate));
  const leaseCreatedAtDate = getUTCDate(new Date(lease.createdAt ?? ''));
  const hasPaidPostings = invoicesWithPostings?.some((invoice) => invoice.postings?.some((p) => p.invoicePostingId));
  const daysSinceLeaseCreated = differenceInDays(new Date(), leaseCreatedAtDate);
  const hasEnoughRightsToDeleteAll = hasEnoughRightsToDeepDeleteLease(permissions);
  const canStillDeleteLease =
    hasEnoughRightsToDeleteAll && daysSinceLeaseCreated < NUMBER_OF_DAYS_TO_DELETE_LEASE && !hasPaidPostings;
  const isCommercial = lease.type === LeaseType.COMMERCIAL;
  const isShortTerms = isShortTermsLease(lease.type as LeaseType);
  const region = getUnit(lease.units?.find((unitLease) => unitLease.mainUnit)?.unit?.id ?? '')?.building?.address
    ?.region;
  const isFlanders = isInFlanders(region);
  const totalRent = lease.totalRentalPrice;

  return (
    <Grid style={{ width: '100%' }}>
      <Typography className={classes.informationFillInTitle}>
        {formatMessage({ id: `lease.endExtendLease.whoIsEnding` })}
      </Typography>
      <FormControl component="fieldset" style={{ paddingTop: 10, width: '100%' }}>
        <RadioGroup
          value={values.initiator}
          onChange={(_e, value) => {
            const typedValue = value as LeaseActionEndInitiator;
            if (
              [LeaseActionEndInitiator.LESSOR, LeaseActionEndInitiator.TENANT, LeaseActionEndInitiator.MUTUAL].includes(
                typedValue
              )
            ) {
              const newEndDate = new Date(values.endDate);
              const numberOfMonths = getNumberOfMonthsForIndemnity(
                typedValue,
                isShortTerms,
                isCommercial,
                isFlanders,
                leaseStartDate,
                newEndDate
              );
              const totalAmount = numberOfMonths * totalRent;

              setValues({
                ...values,
                initiator: typedValue,
                amountDue: {
                  selectedTab: 'amountOfMonths',
                  totalAmount,
                  userTotalAmount: totalAmount,
                  amountOfMonths: numberOfMonths,
                  totalAmountLabel: '',
                },
                lessorEndNotice: {
                  ...values.lessorEndNotice,
                  responsible: 'LESSOR',
                  sendDate: new Date().toISOString(),
                  channel: CommunicationChannel.LETTER,
                },
              });

              return;
            }

            // Reset field for other initiator
            setValues({
              ...values,
              initiator: typedValue,
              amountDue: {
                selectedTab: 'amountOfMonths',
                totalAmount: 0,
                userTotalAmount: 0,
                amountOfMonths: 0,
                totalAmountLabel: '',
              },
            });
          }}
        >
          {Object.values(LeaseActionEndInitiator)
            .filter((initiator) => isCommercial || initiator !== LeaseActionEndInitiator.NOTARIAL_ACT)
            .map((initiator) => (
              <FormControlLabel
                style={{ width: 'fit-content' }}
                key={initiator}
                value={initiator}
                control={<StyledRadio style={{ paddingTop: 2, paddingBottom: 2 }} />}
                label={
                  <Typography className={classes.actionRadioText}>
                    {formatMessage({ id: `lease.endExtendLease.LeaseActionEndInitiator.${initiator}` })}
                  </Typography>
                }
              />
            ))}
        </RadioGroup>
      </FormControl>
      <Collapse in={!isNil(values.initiator) || !canStillDeleteLease}>
        <EndLeaseEndDateFields
          leaseEndDate={leaseEndDate}
          leaseStartDate={leaseStartDate}
          maxLeaseEndDate={calculateMaxDate(
            leaseStartDate,
            leaseEndDate,
            values?.initiator as LeaseActionEndInitiator,
            lease.type as LeaseType
          )}
          leaseLastInvoiceDate={lease.lastInvoiceDate ? new Date(lease.lastInvoiceDate) : undefined}
        />
      </Collapse>
      <Collapse in={values.initiator && values.initiator === LeaseActionEndInitiator.LESSOR}>
        <EndLeaseLessorFields
          leaseType={lease.type as LeaseType}
          region={region}
          totalRent={totalRent}
          leaseStartDate={leaseStartDate}
          leaseEndDate={leaseEndDate}
          language={lease.language as Language}
          lease={lease}
        />
      </Collapse>
      <Collapse in={values.initiator && values.initiator === LeaseActionEndInitiator.TENANT}>
        <EndLeaseTenantFields
          leaseType={lease.type as LeaseType}
          region={region}
          totalRent={totalRent}
          leaseStartDate={leaseStartDate}
          leaseEndDate={leaseEndDate}
          lease={lease}
        />
      </Collapse>
      <Collapse in={values.initiator && values.initiator === LeaseActionEndInitiator.MUTUAL}>
        <EndLeaseMutualFields leaseType={lease.type as LeaseType} region={region} totalRent={totalRent} lease={lease} />
      </Collapse>
      <Collapse
        in={
          values.initiator &&
          [LeaseActionEndInitiator.NOTARIAL_ACT, LeaseActionEndInitiator.COURT_DECISION].includes(
            values.initiator as LeaseActionEndInitiator
          )
        }
      >
        <EndLeaseNotarialOrCourtFields leaseType={lease.type as LeaseType} region={region} totalRent={totalRent} />
      </Collapse>
    </Grid>
  );
};

export default EndLeaseForm;
