import { Divider, Grid } from '@material-ui/core';
import { Address } from '@rentguru/commons-utils';
import { OverflowableTypography, TextDetail, useTextDetailStyles } from '@up2rent/ui';
import { isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import TextDetailCountry from 'src/components/ui/TextDetailCountry';

interface ContactAddressDetailsProps {
  address?: Address | null;
  divider?: boolean;
}

const ContactAddressDetails: React.FC<ContactAddressDetailsProps> = ({ address, divider = true }) => {
  const { formatMessage } = useIntl();
  const textDetailClasses = useTextDetailStyles();

  if (isNil(address)) {
    return null;
  }

  return (
    <>
      {divider && <Divider style={{ marginTop: 30, marginBottom: 30, marginLeft: -30, marginRight: -30 }} />}
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        {!isNil(address.country) && (
          <Grid item xs={4}>
            <TextDetailCountry
              title={formatMessage({ id: 'contact.detail.general.countryLabel' })}
              text={address.country}
            />
          </Grid>
        )}
        {!isNil(address.postalCode) && (
          <Grid item xs={4}>
            <TextDetail
              title={formatMessage({ id: 'contact.detail.general.postCodeLabel' })}
              text={address.postalCode}
            />
          </Grid>
        )}
        {!isNil(address.city) && (
          <Grid item xs={4}>
            <TextDetail title={formatMessage({ id: 'contact.detail.general.townLabel' })}>
              <OverflowableTypography className={textDetailClasses.typography} text={address.city} />
            </TextDetail>
          </Grid>
        )}
        {!isNil(address.street) && (
          <Grid item xs={4}>
            <TextDetail title={formatMessage({ id: 'contact.detail.general.streetLabel' })}>
              <OverflowableTypography className={textDetailClasses.typography} text={address.street} />
            </TextDetail>
          </Grid>
        )}
        {!isNil(address.number) && (
          <Grid item xs={4}>
            <TextDetail title={formatMessage({ id: 'contact.detail.general.numberLabel' })} text={address.number} />
          </Grid>
        )}
        {!isNil(address.box) && (
          <Grid item xs={4}>
            <TextDetail title={formatMessage({ id: 'contact.detail.general.boxLabel' })} text={address.box} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ContactAddressDetails;
