/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import { Avatar, Grid, GridSize, makeStyles, TextField, Typography } from '@material-ui/core';
import { Add, Clear, Launch, Warning } from '@material-ui/icons';
import {
  Address,
  Colors,
  Contact,
  contactContainsType,
  contactContainsTypes,
  contactMostImportantType,
  ContactType,
  getContactNameFromObject,
  getFirstLettersUppercase,
  getSafeValueInObject,
  isNilOrEmpty,
  isSectionWithAccessOn,
  LooseObject,
  roundAtSecondDecimal,
  stringToColor,
  UserRight,
  UserRightAccessType,
  UserRightSection,
} from '@rentguru/commons-utils';
import { ActionButton, CustomIconButton } from '@up2rent/ui';
import { get, isEmpty, isNil, toUpper } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import AddContact from 'src/components/Contacts/AddContact';
import EditContact from 'src/components/Contacts/EditContact';
import CustomInputComboBox from 'src/components/ui/ComboBox/FormikFullCustomComboBox';
import { CustomInputItemType } from 'src/components/ui/ComboBox/FullCustomComboBox';
import Dialog from 'src/components/ui/Dialog';
import FormAlertBox from 'src/components/ui/FormAlertBox';
import EditOrAddIconButton from 'src/components/ui/SimpleSelectors/utils/EditOrAddIconButton';
import { useContacts } from 'src/hooks/ContactsContext';
import { useUsers } from 'src/hooks/UsersContext';

const useStyles = makeStyles({
  addContact: {
    background: Colors.CLASSICAL_WHITE,
    color: Colors.DARK_SLATE_GREY,
    '&:hover': {
      background: Colors.PORCELAIN_GREY_2,
    },
  },
  label: {
    color: Colors.LIGHT_BLUE_GREY,
  },
  underline: {
    '&:before': {
      borderBottom: `2px solid ${Colors.TOWER_GREY}`,
    },
  },
  select: {
    height: '1.1875em',
  },
});

export interface SelectedContact {
  id: string;
  type: ContactType;
  percent?: number;
  addressId?: string;
  linkId?: string;
}

interface ContactSelectFormProps {
  values: LooseObject;
  errors: LooseObject;
  touched?: LooseObject;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  setFieldValue: (fieldName: string, value: any) => void;
  fieldLabel: string;
  addLabel: string;
  type: ContactType | keyof typeof ContactType | (ContactType | keyof typeof ContactType)[];
  fieldName: string;
  showPercent: boolean;
  textContactMissingAddressPhone?: string;
  textStakesError?: string;
  multiple?: boolean;
  fieldStyle?: LooseObject;
  style?: React.CSSProperties;
  width?: number;
  checkAdress?: boolean;
  warningAddress?: boolean;
  buildingId?: string;
  unitId?: string;
  grouped?: boolean;
  minNumber?: number;
  disabled?: boolean;
  dataTest?: string;
  memberWithSpecificRight?: { section: UserRightSection; access: UserRightAccessType };
  canAdd?: boolean;
  canEdit?: boolean;
  proposedAddress?: Address;
}

const ContactSelectForm: React.FC<ContactSelectFormProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  fieldLabel,
  addLabel = '',
  type,
  fieldName,
  showPercent = true,
  textContactMissingAddressPhone = '',
  textStakesError = '',
  multiple = true,
  style = {},
  fieldStyle = {},
  checkAdress = true,
  warningAddress = false,
  buildingId,
  unitId,
  grouped = false,
  minNumber = 0,
  disabled = false,
  memberWithSpecificRight,
  dataTest = 'contactSelectorForm',
  canAdd = true,
  canEdit = true,
  proposedAddress,
}) => {
  const [dialogAddContactOpen, setDialogAddContactOpen] = useState<number | null>(null);
  const [dialogEditContactId, setDialogEditContactId] = useState<string>('');
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { contacts: contactsContext, members } = useContacts();
  const { users } = useUsers();
  const { getContact } = useContacts();

  const currentMultipleContactsNumber = getSafeValueInObject(values, fieldName).length;

  const increaseNumberOfContacts = () => {
    const numberOfContacts = getSafeValueInObject(values, fieldName).length;
    let newPercent = 100 / (numberOfContacts + 1);
    const oldPercent = 100 / numberOfContacts;
    // Check if we already update percent on existing contact
    const existingTotalPercent = getSafeValueInObject(values, fieldName)
      .filter((v: SelectedContact) => v.percent !== oldPercent)
      .reduce((currentTotal: number, currentContact: SelectedContact) => {
        if (isNil(currentContact.percent)) {
          return currentTotal;
        }
        return currentTotal + currentContact.percent;
      }, 0);
    if (existingTotalPercent !== 0) {
      newPercent = 100 - existingTotalPercent;
    }
    setFieldValue(fieldName, [
      ...getSafeValueInObject(values, fieldName).map((v: SelectedContact) => {
        return {
          ...v,
          percent: v.percent === oldPercent ? newPercent : v.percent,
        } as SelectedContact;
      }),
      { id: '', type: Array.isArray(type) ? type[0] : type, percent: newPercent, addressId: '' } as SelectedContact,
    ]);
  };

  const decreaseNumberOfContacts = (index: number) => {
    const numberOfContacts = getSafeValueInObject(values, fieldName).length;
    const newPercent = 100 / (numberOfContacts - 1);
    const oldPercent = 100 / numberOfContacts;
    setFieldValue(
      fieldName,
      getSafeValueInObject(values, fieldName)
        .filter((_m: SelectedContact, idx: number) => index !== idx)
        .map((v: SelectedContact) => {
          return {
            ...v,
            percent: v.percent === oldPercent ? newPercent : v.percent,
          } as SelectedContact;
        })
    );
  };

  const handleAddContactOpen = (position: number) => {
    setDialogAddContactOpen(position);
  };

  const handleAddContactClose = () => {
    setDialogAddContactOpen(null);
  };

  const handleEditContactOpen = (contactId: string) => {
    setDialogEditContactId(contactId);
  };

  const handleEditContactClose = () => {
    setDialogEditContactId('');
  };

  const contactsToRender = [];

  const alreadySelectedContact = getSafeValueInObject(values, fieldName)
    .map((c: SelectedContact) => c.id)
    .filter((c: string) => !isEmpty(c));

  // Check if we need to filter for signature user
  let signingUsers: Contact[] = members.filter(
    (member) => (!isNil(member.firstName) && !isNil(member.lastName)) || !isNil(member.companyName)
  );
  const checkMemberWithSpecificRight =
    !isNil(memberWithSpecificRight) &&
    ((!Array.isArray(type) && toUpper(ContactType.MEMBER) === toUpper(type)) ||
      (Array.isArray(type) && type.includes(ContactType.MEMBER)));

  if (checkMemberWithSpecificRight) {
    signingUsers = signingUsers.reduce((usersWithSigningRights, currentMember) => {
      // Get user related to member
      const user = users.find((u) => u.contact && u.contact.id === currentMember.id);
      if (isNil(user)) {
        return usersWithSigningRights;
      }
      if (user.rootUser) {
        usersWithSigningRights.push(currentMember);
        return usersWithSigningRights;
      }
      if (isNil(user.userRole) || isNil(user.userRole.userRights)) {
        return usersWithSigningRights;
      }

      if (
        isSectionWithAccessOn(
          user.userRole.userRights as UserRight[],
          memberWithSpecificRight!.section,
          memberWithSpecificRight!.access
        )
      ) {
        usersWithSigningRights.push(currentMember);
        return usersWithSigningRights;
      }

      return usersWithSigningRights;
    }, [] as Contact[]);
  }

  for (let i = 0; i < getSafeValueInObject(values, fieldName).length; i++) {
    const fullContact = getContact(getSafeValueInObject(values, fieldName)[i].id);
    // Map  contacts
    let sortedContacts = contactsContext
      .filter(
        (contact) =>
          (!Array.isArray(type) && contactContainsType(contact, type as ContactType)) ||
          (Array.isArray(type) && contactContainsTypes(contact, type as ContactType[]))
      )
      .filter(
        (contact) =>
          getSafeValueInObject(values, fieldName)[i].id === contact.id || !alreadySelectedContact.includes(contact.id)
      )
      .filter((contact) => !contactContainsType(contact, ContactType.MEMBER) || signingUsers.includes(contact))
      .sort((a, b) => {
        const nameToCompare1 = !isNil(a.companyName)
          ? a.companyName.toLowerCase()
          : `${!isNil(a.firstName) ? a.firstName : ''}${!isNil(a.lastName) ? a.lastName : ''}`.toLowerCase();
        const nameToCompare2 = !isNil(b.companyName)
          ? b.companyName.toLowerCase()
          : `${!isNil(b.firstName) ? b.firstName : ''}${!isNil(b.lastName) ? b.lastName : ''}`.toLowerCase();
        const typeToCompare1 = contactMostImportantType(a);
        const typeToCompare2 = contactMostImportantType(b);
        // If multiple type, and grouped, sort them by type
        if (Array.isArray(type) && grouped) {
          if (typeToCompare1 === typeToCompare2) {
            return nameToCompare1 < nameToCompare2 ? -1 : nameToCompare1 > nameToCompare2 ? 1 : 0;
          }
          return typeToCompare1 < typeToCompare2 ? -1 : 1;
        }

        if (nameToCompare1 < nameToCompare2) {
          return -1;
        }
        if (nameToCompare1 > nameToCompare2) {
          return 1;
        }
        return 0;
      });
    // filter contacts by building and/or unit
    if ((!isNil(buildingId) && buildingId !== '') || (!isNil(unitId) && unitId !== '')) {
      const filterBuilding = !isNil(buildingId) && buildingId !== '';
      const filterUnit = !isNil(unitId) && unitId !== '';
      sortedContacts = sortedContacts.reduce((results, currentContact) => {
        if (contactContainsType(currentContact, ContactType.MEMBER)) {
          return [...results, currentContact];
        }
        const someBuilding = !isNil(currentContact.buildings)
          ? currentContact.buildings.some((building) => building.building!.id === buildingId)
          : false;
        const someUnit = !isNil(currentContact.units)
          ? currentContact.units.some((unit) => unit.unit!.id === unitId)
          : false;
        if (filterBuilding && !filterUnit && someBuilding) {
          // Only building
          return [...results, currentContact];
        }
        if (!filterBuilding && filterUnit && someUnit) {
          // Only unit
          return [...results, currentContact];
        }
        if (filterBuilding && filterUnit && (someUnit || someBuilding)) {
          return [...results, currentContact];
        }
        return results;
      }, [] as Contact[]);
    }
    const contactsMenuItems = sortedContacts.map((c) => {
      const name = getContactNameFromObject(c);
      const color = stringToColor(name);
      return {
        name,
        color,
        value: c.id,
        id: c.id,
        percent: getSafeValueInObject(values, fieldName)[i].percent,
        type: contactMostImportantType(c),
        addressId: !isNil(c.address) ? c.address.id : null,
      };
    });

    // error handling via missingValue being true or false
    let missingValue;
    if ((!checkAdress && !touched) || (checkAdress && !touched)) {
      missingValue = false;
    }

    if (checkAdress && touched) {
      missingValue = isNilOrEmpty(getSafeValueInObject(values, fieldName)[i].id);
      // ||
      // isNil(contact) ||
      // isNil(contact.address);
    }
    if (!checkAdress && touched) {
      missingValue = isNilOrEmpty(getSafeValueInObject(values, fieldName)[i].id);
    }

    // red color of the label
    if (missingValue) {
      if (!isNil(fieldStyle.color)) fieldStyle.color = 'red';
    }
    const emptyContact = { id: '', percent: getSafeValueInObject(values, fieldName)[i].percent } as any;
    let value = getSafeValueInObject(values, fieldName)[i];
    // Handle the case of initial values that don't have all the fields => only need their id and do the rest
    if (isNil(value.value) && !isEmpty(value.id)) {
      const c = contactsContext.find((c) => c.id === value.id);
      if (!isNil(c)) {
        const name = getContactNameFromObject(c);
        const color = stringToColor(name);
        value = {
          ...value,
          value: value.id,
          name,
          color,
          addressId: !isNil(c.address) ? c.address.id : null,
        };
      }
    }

    const getInputEndAdornment = () => {
      const isAlreadySelected =
        !isNil(getSafeValueInObject(values, fieldName)[i]) && !isEmpty(getSafeValueInObject(values, fieldName)[i].id);
      const displayEndAdornment = (canEdit && isAlreadySelected) || canAdd;

      if (!displayEndAdornment) {
        return undefined;
      }
      return (
        <EditOrAddIconButton
          disabled={disabled}
          onClick={() => {
            if (isAlreadySelected) {
              handleEditContactOpen(getSafeValueInObject(values, fieldName)[i].id);
            } else {
              handleAddContactOpen(i);
            }
          }}
          isSelected={isAlreadySelected}
        />
      );
    };

    const customComboBoxGridSize =
      9 + (showPercent ? 0 : 2) + (multiple && currentMultipleContactsNumber > minNumber ? 0 : 1);

    contactsToRender[i] = (
      <div key={i}>
        <Grid
          container
          style={{
            alignItems: 'center',
            display: 'flex',

            ...style,
          }}
          key={`${fieldName}${i}`}
        >
          <Grid item xs={customComboBoxGridSize as GridSize}>
            <CustomInputComboBox
              name={`${fieldName}[${i}]`}
              disabled={disabled}
              value={value}
              label={fieldLabel}
              options={contactsMenuItems}
              noOptionsText={formatMessage({ id: 'comboBox.noContacts2' })}
              emptyValue={emptyContact}
              dataTest={dataTest}
              renderOption={(value: CustomInputItemType) => (
                <Grid
                  container
                  alignContent="space-between"
                  alignItems="center"
                  style={{ marginBottom: 5, marginTop: 5 }}
                >
                  <Grid item xs={10} style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      style={{
                        color: `${value.color}`,
                        backgroundColor: Colors.TRANSPARENT,
                        border: `1.5px ${value.color} solid`,
                        fontSize: 10,
                        fontWeight: 'bold',
                        width: 20,
                        height: 20,
                        marginRight: 10,
                      }}
                    >
                      {getFirstLettersUppercase(value.name)}
                    </Avatar>
                    {value.name}
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }}>
                    <Typography
                      style={{
                        color: Colors.BLUE_GREY,
                        fontFamily: 'Mulish',
                        fontSize: 9.5,
                        letterSpacing: 0.6,
                      }}
                    >
                      {value.type}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              renderInput={(value: CustomInputItemType) => (
                <>
                  <Avatar
                    style={{
                      color: `${value.color}`,
                      backgroundColor: Colors.TRANSPARENT,
                      border: `1.5px ${value.color} solid`,
                      fontSize: 8,
                      fontWeight: 'bold',
                      width: 15,
                      height: 15,
                      marginRight: 10,
                    }}
                  >
                    {getFirstLettersUppercase(value.name)}
                  </Avatar>
                  {value.name}
                </>
              )}
              inputAdornment={getInputEndAdornment()}
              stringToCompare={(value: CustomInputItemType) => value.name}
              error={Boolean(
                errors &&
                  getSafeValueInObject(errors, fieldName) &&
                  getSafeValueInObject(errors, fieldName)[i] &&
                  !isNil(getSafeValueInObject(errors, fieldName)[i].id)
              )}
            />
          </Grid>
          {showPercent && (
            <Grid item xs={2}>
              <TextField
                label="%"
                id={`${fieldName}[${i}].percent`}
                name={`${fieldName}[${i}].percent`}
                type="number"
                margin="dense"
                variant="filled"
                value={getSafeValueInObject(values, fieldName)[i].percent}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{
                  width: 80,
                  marginLeft: 20,
                }}
                error={
                  errors &&
                  getSafeValueInObject(errors, fieldName) &&
                  getSafeValueInObject(errors, fieldName)[i] &&
                  !isNil(getSafeValueInObject(errors, fieldName)[i].percent)
                }
                InputProps={{ classes: { underline: classes.underline } }}
                InputLabelProps={{ className: classes.label }}
              />
            </Grid>
          )}

          {multiple && currentMultipleContactsNumber > minNumber && (
            <Grid item xs={1}>
              <CustomIconButton
                data-test={`decreaseContact-${value.name}`}
                disabled={disabled}
                style={{ color: Colors.BLUE_GREY, marginLeft: 5 }}
                size="small"
                onClick={() => decreaseNumberOfContacts(i)}
                Icon={Clear}
              />
            </Grid>
          )}
        </Grid>
        {missingValue && textContactMissingAddressPhone && !isEmpty(getSafeValueInObject(values, fieldName)[i].id) && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Warning style={{ color: Colors.BURNING_ORANGE, marginRight: 10 }} />
            <Typography style={{ color: Colors.BURNING_ORANGE }}>{textContactMissingAddressPhone}</Typography>
            <CustomIconButton
              style={{ color: Colors.BLUE_GREY }}
              onClick={() => handleEditContactOpen(getSafeValueInObject(values, fieldName)[i].id)}
              Icon={Launch}
            />
          </div>
        )}
        {warningAddress && !isNilOrEmpty(fullContact?.id) && isNil(fullContact?.addressId) && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FormAlertBox
              message1={formatMessage({ id: 'lease.addLease.warningAddress' })}
              removeLeftMargin
              gridStyle={{ marginRight: 30, marginLeft: 30, width: '100%', backgroundColor: Colors.YELLOW_WARNING }}
              messagesGridStyle={{ width: '95%' }}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      {contactsToRender}
      {multiple && (
        <div
          style={{
            marginRight: 30,
            marginLeft: 20,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <ActionButton
            data-test="increaseContact"
            onClick={increaseNumberOfContacts}
            style={{
              marginRight: 20,
            }}
            className={classes.addContact}
          >
            <Add />
            {toUpper(addLabel)}
          </ActionButton>
          {!isEmpty(textStakesError) && (
            <Typography style={{ fontFamily: 'Mulish', color: Colors.BURNING_ORANGE }} data-test="percent-error">
              {textStakesError}
            </Typography>
          )}
        </div>
      )}
      <Dialog
        open={!isNil(dialogAddContactOpen)}
        onClose={handleAddContactClose}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <AddContact
          afterSubmit={(contact: Contact) => {
            if (!isNil(contact)) {
              const name = getContactNameFromObject(contact);
              const completeFieldName = `${fieldName}[${dialogAddContactOpen}]`;
              const percent = showPercent
                ? getSafeValueInObject(values, completeFieldName)?.percent ??
                  roundAtSecondDecimal(100 / get(values, fieldName).length)
                : 0;
              const color = stringToColor(name);
              setFieldValue(completeFieldName, {
                id: contact.id,
                name,
                color,
                language: contact.language,
                addressId: !isNil(contact.address) ? contact.address.id : null,
                ...(showPercent && { percent }),
              });
            }
            handleAddContactClose();
          }}
          type={type as ContactType[]}
          proposedAddress={proposedAddress}
        />
      </Dialog>
      <Dialog
        open={!isEmpty(dialogEditContactId)}
        onClose={handleEditContactClose}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <EditContact
          afterSubmit={(contact?: Contact) => {
            if (!isNil(contact)) {
              const contactIndex = values[fieldName].findIndex((c: { id: string }) => c.id === dialogEditContactId);
              const name = getContactNameFromObject(contact);
              const completeFieldName = `${fieldName}[${contactIndex}]`;
              const percent = showPercent ? getSafeValueInObject(values, completeFieldName).percent : 0;
              const color = stringToColor(name);
              if (contactIndex > -1) {
                const oldContact = values[fieldName][contactIndex];
                setFieldValue(completeFieldName, {
                  ...oldContact,
                  name,
                  color,
                  language: contact.language,
                  addressId: !isNil(contact.address) ? contact.address.id : null,
                  ...(showPercent && { percent }),
                });
              }
            }
            handleEditContactClose();
          }}
          id={dialogEditContactId}
        />
      </Dialog>
    </>
  );
};

export default ContactSelectForm;
