/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import {
  cleanInputCreate,
  cleanInputUpdate,
  createSetting as createMutation,
  CreateSettingInput,
  CreateSettingMutationVariables,
  deleteSetting as deleteMutation,
  DeleteSettingMutationVariables,
  getSetting as getQuery,
  getReadId,
  getTableClientId,
  Setting,
  SETTING_TABLE_NAME,
  syncSettings,
  updateSetting as updateMutation,
  UpdateSettingInput,
} from '@rentguru/commons-utils';
import { deleteEntityWithFetchBefore, list, mutation, NUMBER_OF_MINUTES_FOR_REFETCH } from '@up2rent/fetch-utils';
import { differenceInMinutes } from 'date-fns';
import { isEmpty } from 'lodash';
import React, { Reducer, useContext, useEffect, useMemo, useReducer } from 'react';
import { useUser } from './UserContext';
import { usePermissions } from './utils/PermissionsContext';

export interface SettingContext extends State {
  createSetting: (input: Omit<CreateSettingInput, 'clientId' | 'readId'>) => Promise<Setting>;
  updateSetting: (original: Setting, updates: Partial<UpdateSettingInput>) => Promise<Setting>;
  deleteSetting: (setting: Setting) => Promise<Setting | null>;
}

interface State {
  loading: boolean;
  shouldFetch: boolean;
  lastSettingsFetch: Date | null;
  settings: Setting[];
}

type Action =
  | {
      type: 'FETCH_SETTINGS';
    }
  | {
      type: 'FETCHED';
      payload: { settings: Setting[] };
    }
  | {
      type: 'ADD' | 'UPDATE';
      payload: { setting: Setting };
    }
  | {
      type: 'DELETE';
      payload: { id: string };
    };

const initialState: State = {
  loading: false,
  shouldFetch: true,
  settings: [],
  lastSettingsFetch: null,
};

const contextReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'FETCH_SETTINGS':
      // Check lastFetch
      if (
        state.loading ||
        state.shouldFetch ||
        (state.lastSettingsFetch &&
          differenceInMinutes(new Date(), state.lastSettingsFetch) < NUMBER_OF_MINUTES_FOR_REFETCH)
      ) {
        return state;
      }
      return {
        ...state,
        shouldFetch: true,
        loading: true,
      };
    case 'FETCHED':
      // Build List with merged object
      const newSettings = [
        ...action.payload.settings,
        ...(state.settings ?? []).filter(
          (oldSetting) => !action.payload.settings.some((newSetting) => newSetting.id === oldSetting.id)
        ),
      ];

      return {
        ...state,
        loading: !isEmpty(state.shouldFetch),
        shouldFetch: false,
        lastSettingsFetch: new Date(),
        settings: newSettings,
      };
    case 'ADD':
      if (!state.settings) {
        return state;
      }
      const entity = action.payload.setting;
      return {
        ...state,
        settings: [...state.settings, entity],
      };
    case 'UPDATE':
      if (!state.settings) {
        return state;
      }

      return {
        ...state,
        settings: state.settings.map((item) => {
          if (item.id === action.payload.setting.id) {
            return action.payload.setting;
          }
          return item;
        }),
      };
    case 'DELETE':
      if (!state.settings) {
        return state;
      }

      return {
        ...state,
        settings: state.settings.filter((item) => item.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export const SettingContext = React.createContext<SettingContext | null>(null);

export const SettingContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { settingsAutomationWrite } = usePermissions();
  const { clientId } = useUser();
  const [state, dispatch] = useReducer<Reducer<State, Action>>(contextReducer, initialState);

  useEffect(() => {
    let unmounted = false;
    const fetchAndSet = async () => {
      const settings = await fetchSettings();

      if (!unmounted) {
        dispatch({
          type: 'FETCHED',
          payload: { settings },
        });
      }
    };

    if (state.shouldFetch) {
      fetchAndSet();
    }
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetch]);

  const fetchSettings = async (): Promise<Setting[]> =>
    await list<Setting>(syncSettings, 'clientId', getTableClientId(clientId!, SETTING_TABLE_NAME));

  const createSetting = async (
    input: CreateSettingInput | Omit<CreateSettingInput, 'clientId' | 'readId'>
  ): Promise<Setting> => {
    const setting = await mutation<Setting, CreateSettingMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateSettingInput),
        clientId: getTableClientId(clientId!, SETTING_TABLE_NAME),
        readId: getReadId(clientId!, SETTING_TABLE_NAME),
      },
    });
    dispatch({ type: 'ADD', payload: { setting } });
    return setting;
  };

  const updateSetting = async (original: Setting, updates: Partial<UpdateSettingInput>): Promise<Setting> => {
    const setting = await mutation<Setting>(updateMutation, {
      input: { ...cleanInputUpdate({ id: original.id, _version: original._version, ...updates }, false) },
    });
    dispatch({ type: 'UPDATE', payload: { setting } });
    return setting;
  };

  const deleteSetting = async (setting: Setting): Promise<Setting | null> => {
    if (!settingsAutomationWrite) {
      return null;
    }
    const profileToDelete = await mutation<Setting>(updateMutation, {
      input: {
        ...cleanInputUpdate({ id: setting.id, _version: setting._version }, false),
      },
    });

    const result = await deleteEntityWithFetchBefore<Setting, DeleteSettingMutationVariables>(
      profileToDelete,
      getQuery,
      deleteMutation
    );
    dispatch({ type: 'DELETE', payload: { id: setting.id } });
    return result;
  };

  const values = useMemo(
    () => ({
      ...state,
      createSetting,
      updateSetting,
      deleteSetting,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <SettingContext.Provider value={values}>{children}</SettingContext.Provider>;
};

export const useSettings = (): SettingContext => {
  const context = useContext<SettingContext | null>(SettingContext);

  if (!context) {
    throw new Error('`useSettings` hook must be used within a `SettingContextProvider` component');
  }
  return context;
};
