import { Grid, Menu, MenuItem } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  Colors,
  EntityType,
  FileCategory,
  LeaseExtended,
  LeaseInventory,
  LeaseInventoryType,
  LeaseStatus,
  S3Object,
  SignatureDocument,
  SignatureDocumentStatus,
} from '@rentguru/commons-utils';
import { ConfirmDialog, CustomPopover, MenuItemText, TextDetail } from '@up2rent/ui';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import SendForSignatureDialog from 'src/components/ui/Dialogs/SendForSignatureDialog';
import PreviewFileDialogs from 'src/components/ui/PreviewFileDialog';
import TextButton from 'src/components/ui/TextButton';
import { getS3ObjectUrls, useFiles } from 'src/hooks/FilesContext';
import { useLeaseInventories } from 'src/hooks/LeaseInventoryContext';
import { useSignatureDocuments } from 'src/hooks/SignatureDocumentContext';
import { useUnitInventories } from 'src/hooks/UnitInventoryContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { useUser } from 'src/hooks/UserContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { ReactComponent as DropDownIcon } from 'src/icons/drop-down.svg';
import { getLeaseInventoryDocumentType } from 'src/utils/inventoryUtils';
import { exitFurnitureInventoryHasUncheckedEncodings } from 'src/utils/leaseInventoryUtils';

interface LeaseInventoryActionBarProps {
  leaseInventory: LeaseInventory;
  lease: LeaseExtended;
}

const LeaseInventoryActionBar: React.FC<LeaseInventoryActionBarProps> = ({ leaseInventory, lease }) => {
  const {
    inventoryOfFixturesCreationWrite,
    inventoryOfFixturesCreationDelete,
    furnituresInventoryCreationWrite,
    furnituresInventoryCreationDelete,
    leasesSignatureWrite,
  } = usePermissions();
  const isFixturesInventory = !!(
    leaseInventory.inventoryType === LeaseInventoryType.FIXTURES_ENTRANCE ||
    leaseInventory.inventoryType === LeaseInventoryType.FIXTURES_EXIT
  );
  const { formatMessage } = useIntl();
  const { generateDocument, getFiles, loading: filesLoading } = useFiles();
  const { updateLeaseInventory, deepDeleteLeaseInventory } = useLeaseInventories();
  const { getEncodingsOfLeaseInventory } = useUnitInventories();
  const { getSignatureDocumentsForEntity, setSignatureResultToZero } = useSignatureDocuments();
  const { getUnit } = useUnits();
  const [leaseInventoryContract, setLeaseInventoryContract] = useState<S3Object | null>(null);
  const [networkDataLoading, setNetworkDataLoading] = useState(true);
  const history = useHistory();
  const [anchorE1, setAnchorE1] = useState<null | HTMLElement>(null);
  const [anchorElPreview, setAnchorE1Preview] = useState<null | HTMLElement>(null);
  const [sign, setSign] = useState<boolean>(false);
  const [anchorElWarningSignature, setAnchorElWarningSignature] = useState<HTMLElement | null>(null);
  const { memberId } = useUser();
  const [deleteLeaseInventory, setDeleteLeaseInventory] = useState<boolean>(false);
  const files = getFiles(EntityType.LEASE_INVENTORY, [leaseInventory.id]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorElWarningSignature(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElWarningSignature(null);
  };

  useEffect(() => {
    let unmounted = false;
    const fetchNetworkData = async () => {
      const contract = await getS3ObjectUrls(
        files.filter((file) => file.category?.fileCategory === FileCategory.LEASE_INVENTORY_CONTRACT)
      );
      if (!unmounted) {
        if (!isEmpty(contract)) setLeaseInventoryContract(contract[0]);
        setNetworkDataLoading(false);
      }
    };
    fetchNetworkData();
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesLoading]);

  const tenantWithNoEmail = lease.tenants.some((tenant) => isNil(tenant.email) || tenant.email === '');
  const furnitureExitWithUncheckedEncodings = exitFurnitureInventoryHasUncheckedEncodings(
    leaseInventory,
    getEncodingsOfLeaseInventory
  );
  let disabledSignature = tenantWithNoEmail || furnitureExitWithUncheckedEncodings;
  // REN-1275
  if (process.env.NODE_ENV === 'production') {
    disabledSignature = true;
  }

  const unitId = lease.units!.find((unitLease) => unitLease.mainUnit)!.unit!.id!;
  const pendingSignatureDocuments = getSignatureDocumentsForEntity(
    'LeaseInventory',
    leaseInventory.id,
    SignatureDocumentStatus.PENDING
  );
  const currentSignatureResult =
    pendingSignatureDocuments && pendingSignatureDocuments.length > 0
      ? pendingSignatureDocuments[0].signatureResults.find((result) => result.contactId === memberId)
      : null;

  const DeleteButton = (
    <TextButton onClick={() => setDeleteLeaseInventory(true)}>{formatMessage({ id: 'delete' })}</TextButton>
  );
  const PreviewButton = networkDataLoading ? (
    <Skeleton style={{ width: '70%' }} />
  ) : (
    <TextButton onClick={(event) => setAnchorE1Preview(event.currentTarget)} disabled={isNil(leaseInventoryContract)}>
      {formatMessage({ id: 'lease.detail.action.openPdf' })}
    </TextButton>
  );
  const ChangeDraftButton = (
    <TextButton
      onClick={async () => {
        const signaturePromises: Promise<SignatureDocument>[] = [];
        pendingSignatureDocuments?.forEach((document) => {
          signaturePromises.push(setSignatureResultToZero(document));
        });

        await updateLeaseInventory(leaseInventory, { status: LeaseStatus.Draft });
        await Promise.all(signaturePromises);
      }}
    >
      {formatMessage({ id: 'lease.detail.action.changeAsDraft' })}
    </TextButton>
  );
  const RetractButton = (
    <TextButton
      onClick={async () => {
        const signaturePromises: Promise<SignatureDocument>[] = [];
        pendingSignatureDocuments?.forEach((document) => {
          signaturePromises.push(setSignatureResultToZero(document));
        });
        await updateLeaseInventory(leaseInventory, {
          status: LeaseStatus.Draft,
        });
        await Promise.all(signaturePromises);
      }}
    >
      {formatMessage({ id: 'lease.detail.action.retract' })}
    </TextButton>
  );

  const SignatureButton = (
    <TextButton
      onClick={(event) => {
        setAnchorE1(event.currentTarget);
      }}
    >
      {formatMessage({ id: 'lease.detail.action.signature' })}
      <DropDownIcon
        style={{
          verticalAlign: 'middle',
          fill: Colors.DARK_SLATE_GREY,
          transform: 'translateY(-1px)',
        }}
      />
    </TextButton>
  );

  const SignButton = (
    <TextButton
      target="_blank"
      href={currentSignatureResult && currentSignatureResult.signatureLink ? currentSignatureResult.signatureLink : '#'}
      disabled={Boolean(currentSignatureResult && currentSignatureResult.signed)}
    >
      {formatMessage({ id: 'lease.detail.action.signOnline' })}
    </TextButton>
  );

  const EditButton = (
    <TextButton
      onClick={() => {
        history.push({
          pathname: isFixturesInventory ? RouteDestination.EDIT_INVENTORY : RouteDestination.EDIT_FURNITURE_INVENTORY,
          state: {
            goBackUrl: history.location,
            leaseInventory,
            unitId,
          },
        });
      }}
    >
      {formatMessage({ id: 'edit' })}
    </TextButton>
  );

  const generatePDFButton = networkDataLoading ? (
    <Skeleton style={{ width: '70%' }} />
  ) : (
    <TextButton
      onClick={async () => {
        setNetworkDataLoading(true);
        await generateDocument({
          id: leaseInventory.id,
          type: getLeaseInventoryDocumentType(leaseInventory),
        });
        setNetworkDataLoading(false);
      }}
    >
      {formatMessage({ id: 'lease.detail.action.generatePdf' })}
    </TextButton>
  );

  let toReturn: React.ReactNode = null;
  if (leaseInventory.status === LeaseStatus.Active || leaseInventory.status === LeaseStatus.Ended) {
    toReturn = (
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item xs={2} />
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {PreviewButton}
        </Grid>
      </Grid>
    );
  } else if (leaseInventory.status === LeaseStatus.OutForSignature) {
    toReturn = (
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item xs={2}>
          {(isFixturesInventory ? inventoryOfFixturesCreationWrite : furnituresInventoryCreationWrite) && RetractButton}
        </Grid>
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {PreviewButton}
        </Grid>
        {currentSignatureResult && (
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
            {leasesSignatureWrite && SignButton}
          </Grid>
        )}
      </Grid>
    );
  } else if (leaseInventory.status === LeaseStatus.Rejected) {
    toReturn = (
      <Grid container>
        <Grid item xs={2}>
          {(isFixturesInventory ? inventoryOfFixturesCreationDelete : furnituresInventoryCreationDelete) &&
            DeleteButton}
        </Grid>
        <Grid item xs={5} />
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {PreviewButton}
        </Grid>
        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
          {(isFixturesInventory ? inventoryOfFixturesCreationWrite : furnituresInventoryCreationWrite) &&
            ChangeDraftButton}
        </Grid>
      </Grid>
    );
  } else if (leaseInventory.status === LeaseStatus.Cancelled) {
    toReturn = (
      <Grid container>
        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
          {(isFixturesInventory ? inventoryOfFixturesCreationWrite : furnituresInventoryCreationWrite) &&
            ChangeDraftButton}
        </Grid>
      </Grid>
    );
  } else if (leaseInventory.status === LeaseStatus.Draft) {
    toReturn = (
      <>
        <Grid container>
          <Grid item xs={2}>
            {(isFixturesInventory ? inventoryOfFixturesCreationDelete : furnituresInventoryCreationDelete) &&
              DeleteButton}
          </Grid>
          <Grid item xs={4}>
            {(isFixturesInventory ? inventoryOfFixturesCreationWrite : furnituresInventoryCreationWrite) && EditButton}
          </Grid>
          <Grid item xs={2}>
            {(isFixturesInventory ? inventoryOfFixturesCreationWrite : furnituresInventoryCreationWrite) &&
              generatePDFButton}
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {PreviewButton}
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
            {(isFixturesInventory ? inventoryOfFixturesCreationWrite : furnituresInventoryCreationWrite) &&
              SignatureButton}
          </Grid>
          <Menu id="action-menu" anchorEl={anchorE1} open={Boolean(anchorE1)} onClose={() => setAnchorE1(null)}>
            <div
              onMouseEnter={disabledSignature ? handlePopoverOpen : undefined}
              onMouseLeave={disabledSignature ? handlePopoverClose : undefined}
            >
              <MenuItem
                onClick={async () => {
                  setAnchorE1(null);
                  setSign(true);
                }}
                disabled={!!disabledSignature}
              >
                <MenuItemText primary={formatMessage({ id: 'lease.detail.action.sendForSignature' })} />

                <CustomPopover
                  id="mouse-over-popover"
                  open={!isNil(anchorElWarningSignature)}
                  anchorEl={anchorElWarningSignature}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                  paperBorderRadius={10}
                >
                  <TextDetail
                    title={formatMessage({ id: 'optionUnavailable' })}
                    // REN-1275
                    text={
                      process.env.NODE_ENV === 'production'
                        ? formatMessage({ id: 'comingSoon' })
                        : tenantWithNoEmail
                        ? formatMessage({ id: 'lease.detail.action.notAllEmailsWarning' })
                        : furnitureExitWithUncheckedEncodings
                        ? formatMessage({ id: 'lease.detail.action.notAllFurnituresCheckWarning' })
                        : ''
                    }
                  />
                </CustomPopover>
              </MenuItem>
            </div>
          </Menu>
        </Grid>
        <ConfirmDialog
          open={deleteLeaseInventory}
          confirmText={formatMessage({ id: 'leaseInventory.confirmDelete' })}
          confirmAction={async () => {
            const unit = getUnit(unitId);
            setDeleteLeaseInventory(false);
            history.push({
              pathname: `${RouteDestination.PROPERTIES}/${unit?.building?.id}/unit-${unitId}/inventory`,
              state: { goBackUrl: RouteDestination.PROPERTIES },
            });
            // should repush from origin
            await deepDeleteLeaseInventory(leaseInventory, pendingSignatureDocuments, setSignatureResultToZero);
          }}
          cancelAction={() => setDeleteLeaseInventory(false)}
          mainText={formatMessage({ id: 'leaseInventory.deleteWarning' })}
          formatMessage={formatMessage}
        />
      </>
    );
  }
  return (
    <>
      {toReturn}
      <PreviewFileDialogs
        open={!isNil(anchorElPreview)}
        file={leaseInventoryContract}
        withSignatures={leaseInventory.status === LeaseStatus.Active}
        onClose={() => setAnchorE1Preview(null)}
      />
      <SendForSignatureDialog open={sign} lease={lease} onClose={() => setSign(false)} file={leaseInventoryContract} />
    </>
  );
};

export default LeaseInventoryActionBar;
