import { Grid, Paper, Typography } from '@material-ui/core';
import {
  Colors,
  Transaction,
  TransactionStatus,
  formatNumber,
  getTransactionAmountReconciledInLease,
  getTransactionsInPendingBalance,
} from '@rentguru/commons-utils';
import { ActionButton } from '@up2rent/ui';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import { useTransactions } from 'src/hooks/TransactionsContext';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as ArrowRightIcon } from 'src/icons/arrow-right.svg';
import { ReactComponent as ExternalIcon } from 'src/icons/external.svg';

interface PendingButtonProps {
  leaseId: string;
}

const getPendingCounter = (transactionsToReconcile: Transaction[], transactionsInPendingBalance: Transaction[]) => {
  if (isEmpty(transactionsInPendingBalance)) {
    return transactionsToReconcile.length;
  }
  const transactionsIdsInPendingBalance = transactionsInPendingBalance.map((transaction) => transaction.id);
  const filteredTransactions = transactionsToReconcile.filter(
    (transaction) => !transactionsIdsInPendingBalance.includes(transaction.id)
  );
  // We do +1 for the pending balance itself
  return filteredTransactions.length + 1;
};

export const PendingButton: React.FC<PendingButtonProps> = ({ leaseId }) => {
  const [showPendingPaper, setShowPendingPaper] = useState<boolean>(false);
  const { language } = useLocale();
  const history = useHistory();
  const { getTransactionsForLease } = useTransactions();
  const { formatMessage } = useIntl();

  const allLeaseTransactions = getTransactionsForLease(leaseId);
  const transactionsToReconcile = allLeaseTransactions.filter((transaction) =>
    [TransactionStatus.TO_RECONCILE, TransactionStatus.PARTIALLY_RECONCILED].includes(transaction.status)
  );
  const pendingCounter = getPendingCounter(
    transactionsToReconcile,
    getTransactionsInPendingBalance(allLeaseTransactions)
  );
  const hasPending = pendingCounter > 0;
  const unreconciledAmount = transactionsToReconcile.reduce((acc, transaction) => {
    const currentReconciledAmount = getTransactionAmountReconciledInLease(transaction, leaseId);
    const currentUnreconciledAmount = Math.abs(transaction.amount) - currentReconciledAmount;
    acc += currentUnreconciledAmount;
    return acc;
  }, 0);

  return (
    <Grid style={{ marginTop: 8 }}>
      <ActionButton
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          history.push({
            pathname: RouteDestination.RECONCILE_TRANSACTION,
            state: { leaseIds: [leaseId] },
          });
        }}
        onMouseEnter={() => setShowPendingPaper(true)}
        onMouseLeave={() => setShowPendingPaper(false)}
      >
        <Grid style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
          {hasPending ? (
            <ArrowRightIcon width={15} height={15} style={{ fill: Colors.CLASSICAL_WHITE }} />
          ) : (
            <ExternalIcon width={15} height={15} style={{ fill: Colors.CLASSICAL_WHITE }} />
          )}
          <Typography>
            {hasPending
              ? formatMessage({ id: 'lease.detail.general.pendingCounter' }, { number: pendingCounter })
              : formatMessage({ id: 'lease.detail.action.reconciliationScreen' })}
          </Typography>
        </Grid>
      </ActionButton>
      {showPendingPaper && (
        <Paper
          elevation={8}
          style={{
            position: 'absolute',
            margin: 0,
            zIndex: 1,
            minHeight: 16,
            padding: 15,
            borderRadius: 10,
            display: 'flex',
            alignItems: 'center',
            left: 5,
            right: 5,
          }}
        >
          <Typography>{formatMessage({ id: 'transactions.lettering.unreconciledTransactions' })}:</Typography>
          <Typography style={{ marginLeft: 5, color: Colors.CARNATION_RED, fontWeight: 'bold' }}>
            {formatNumber(unreconciledAmount, language, { style: 'currency', currency: 'EUR' })}
          </Typography>
        </Paper>
      )}
    </Grid>
  );
};
