import { Collapse, Grid, InputAdornment, MenuItem, Typography } from '@material-ui/core';
import { LeaseMonthlyChargesType } from '@rentguru/commons-utils';
import { CustomizedSwitch, TextDetailEditable } from '@up2rent/ui';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { AddLeaseFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import { resolveMainUnitAndSubUnitIndexAndPrefix } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import { getTranslatedEnumFormatMessage } from 'src/utils/enumTypes';
import FormikCustomizedSelect from '../../FormikCustomizedSelect';
import HelpIconPopover from '../../HelpIconPopover';

interface ChargesFieldsProps {
  unitId: string;
}

export interface MonthlyChargesFieldsFormValues {
  monthlyChargesFields: {
    monthlyChargesType: string | undefined;
    monthlyCharges: string | undefined;
    allowChargeAdjustment: boolean;
  };
}

const ChargesFields: React.FC<ChargesFieldsProps> = ({ unitId }) => {
  const { values, errors, touched, handleBlur, handleChange } = useFormikContext<AddLeaseFormValues>();
  const { formatMessage } = useIntl();
  const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);

  return (
    <>
      <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <FormikCustomizedSelect
          label={formatMessage({
            id: 'lease.addLease.monthlyChargesType',
          })}
          fieldName={`${prefixFieldName}monthlyChargesFields.monthlyChargesType`}
          value={get(values, `${prefixFieldName}monthlyChargesFields.monthlyChargesType`)}
          FormControlProps={{ required: true, style: { width: 260 } }}
          error={Boolean(errors.monthlyChargesType && touched.monthlyChargesType)}
          SelectProps={{
            onChange: handleChange,
            onBlur: handleBlur,
          }}
          dataTest="ChargesType"
        >
          {getTranslatedEnumFormatMessage(formatMessage, 'LeaseMonthlyChargesType').map((mi) => (
            <MenuItem value={mi.value} key={mi.value}>
              {mi.label}
            </MenuItem>
          ))}
        </FormikCustomizedSelect>
        <HelpIconPopover
          helperText={formatMessage({ id: 'lease.addLease.monthlyChargesTypeHelper' })}
          paperStyle={{ maxWidth: 400 }}
          iconStyle={{ marginLeft: 10 }}
        />
        <TextDetailEditable
          editMode
          title={`${formatMessage({
            id: `lease.addLease.${values.applyVat ? 'monthlyChargesNoVat' : 'monthlyCharges'}`,
          })} ${formatMessage({
            id: 'optional',
          })}`}
          name={`${prefixFieldName}monthlyChargesFields.monthlyCharges`}
          type="number"
          style={{ width: 280, marginLeft: 5 }}
          error={Boolean(
            get(errors, `${prefixFieldName}monthlyChargesFields.monthlyCharges`) &&
              get(touched, `${prefixFieldName}monthlyChargesFields.monthlyCharges`)
          )}
          min={0}
          endAdornment={<InputAdornment position="end">EUR</InputAdornment>}
          typeNumberNoEndArrow
          noMaxWidth
          data-test="monthlyChargesDataTest"
        />
      </Grid>

      <Collapse
        in={
          get(values, `${prefixFieldName}monthlyChargesFields.monthlyChargesType`) ===
          LeaseMonthlyChargesType.MonthlyProvisioned
        }
      >
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 15,
          }}
          data-test="chargeAdjustment"
        >
          <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
            {formatMessage({ id: 'accounting.chargeAdjustments.allowChargeAdjustment' })}
          </Typography>
          <Grid style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <CustomizedSwitch
              checked={get(values, `${prefixFieldName}monthlyChargesFields.allowChargeAdjustment`)}
              onChange={handleChange}
              onBlur={handleBlur}
              aria-label="Collapse"
              color="primary"
              disableRipple
              id={`${prefixFieldName}monthlyChargesFields.allowChargeAdjustment`}
              name={`${prefixFieldName}monthlyChargesFields.allowChargeAdjustment`}
            />
            <HelpIconPopover
              helperText={formatMessage({ id: 'accounting.chargeAdjustments.allowChargeAdjustmentHelper' })}
              paperStyle={{ maxWidth: 400 }}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default ChargesFields;
