import { Paper, Typography } from '@material-ui/core';
import { Colors, formatNumber } from '@rentguru/commons-utils';
import React from 'react';
import { useUser } from 'src/hooks/UserContext';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import LeaseBalancePopoverColumn from './LeaseBalancePopoverColumn';

interface LeaseBalanceColumnProps {
  leaseId: string;
  balance?: number | null;
  open?: boolean;
}

const LeaseBalanceColumn: React.FC<LeaseBalanceColumnProps> = ({ leaseId, balance, open }) => {
  const { language } = useLocale();
  const { isOwner } = useUser();

  const balanceColor = !balance
    ? Colors.TURQUOISE_BLUE
    : balance > 0
    ? Colors.TURQUOISE_BLUE
    : balance < 0
    ? Colors.CARNATION_RED
    : Colors.SILVER;

  return (
    <>
      <div
        style={{
          borderRadius: 4,
          border: `1px solid ${balanceColor}`,
          color: balanceColor,
          fontWeight: 'bold',
          height: 30,
          width: 115,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Typography>
          {formatNumber(balance ?? 0, language, {
            style: 'currency',
            currency: 'EUR',
          })}
        </Typography>
      </div>
      {open && !isOwner && (
        <Paper
          elevation={8}
          style={{
            position: 'absolute',
            paddingLeft: 20,
            paddingRight: 30,
            paddingTop: 10,
            paddingBottom: 20,
            borderRadius: 10,
            zIndex: 1,
            minWidth: 525,
            minHeight: 16,
            transform: 'translateX(-450px)',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <LeaseBalancePopoverColumn leaseId={leaseId} />
        </Paper>
      )}
    </>
  );
};

export default LeaseBalanceColumn;
