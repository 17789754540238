/* eslint-disable react/no-this-in-sfc */
/* eslint-disable func-names */
import { Divider, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import { ActionButton, ContentHeader, LoaderButton, TextDetailEditable } from '@up2rent/ui';
import { Form, Formik, FormikHelpers } from 'formik';
import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useIntl } from 'react-intl';
import { verifyDisposableEmail } from 'src/components/Contacts/AddContact';
import RoleSelectorForm from 'src/components/ui/Forms/FormSection/RoleSelectorForm';
import TeamSelectorInput from 'src/components/ui/Forms/FormSection/TeamSelectorInput';
import { useTeams } from 'src/hooks/TeamsContext';
import { useUsers } from 'src/hooks/UsersContext';
import { EMAIL_REGEX } from 'src/utils/communicationUtils';
import * as Yup from 'yup';

interface AddMemberFormValues {
  email: string;
  teamId: string;
  roleId: string;
}

interface AddMemberFormProps {
  onClose?: (userId?: string) => void;
}

const initialValues: AddMemberFormValues = {
  email: '',
  teamId: '',
  roleId: '',
};

const AddMemberForm: React.FC<AddMemberFormProps> = ({ onClose }) => {
  const { formatMessage } = useIntl();
  const { inviteMemberToAccount } = useUsers();
  const { teams, loading, error } = useTeams();
  const { enqueueSnackbar } = useSnackbar();

  const handleCreate = async (
    values: AddMemberFormValues,
    { setSubmitting, setStatus }: FormikHelpers<AddMemberFormValues>
  ) => {
    const { id: userId, status } = await inviteMemberToAccount(values.email, values.roleId, values.teamId);
    if (status !== true) {
      enqueueSnackbar(formatMessage({ id: 'error.UsernameExistsException' }), { variant: 'error' });
    } else {
      enqueueSnackbar(formatMessage({ id: 'settings.addMember.sent' }), { variant: 'success' });
    }
    setStatus(true);
    setSubmitting(false);
    if (!isNil(onClose)) {
      onClose(userId);
    }
  };

  if (loading) {
    return <Typography>{formatMessage({ id: 'loading' })}</Typography>;
  }
  if (error) {
    return <Typography>{error}</Typography>;
  }

  const AddMemberSchema = Yup.object()
    .shape({
      teamId: Yup.string().min(1).required(),
      roleId: Yup.string().min(1).required(),
      email: Yup.string()
        .email()
        .required()
        .matches(EMAIL_REGEX, formatMessage({ id: 'settings.sendingSources.invalidEmail' }))
        .test('Checking email validity', 'disposable email error', async function (value) {
          if (isNil(value)) return false;
          return await verifyDisposableEmail(value, formatMessage, this.createError);
        }),
    })
    .required();

  const showTeams = teams.length !== 1;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddMemberSchema}
      onSubmit={handleCreate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ errors, touched, values, status, isSubmitting, setFieldValue }) => {
        if (!showTeams && values.teamId === '') {
          setFieldValue('teamId', teams[0].id);
        }
        return (
          <Form>
            <ContentHeader title={formatMessage({ id: 'settings.addMember.menu' })} />
            <div style={{ marginLeft: 30, marginRight: 30 }}>
              <div style={{ alignItems: 'baseline' }}>
                <TextDetailEditable
                  data-test="userTeam"
                  title={formatMessage({ id: 'settings.addMember.email' })}
                  editMode={true}
                  name="email"
                  type="email"
                  helperText={errors.email && touched.email ? errors.email : ''}
                  style={{ width: 280, maxWidth: 'none' }}
                  error={Boolean(errors.email && touched.email)}
                />
              </div>
              {showTeams && (
                <div style={{ alignItems: 'baseline' }}>
                  <TeamSelectorInput
                    label={formatMessage({ id: 'settings.addMember.team' })}
                    onChange={(teamId: string) => setFieldValue('teamId', teamId)}
                  />
                </div>
              )}
              <div style={{ alignItems: 'baseline' }}>
                <RoleSelectorForm fieldName="roleId" required inputStyle={{ width: 280, maxWidth: 'none' }} />
              </div>
            </div>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div
              style={{
                marginRight: 30,
                marginBottom: 20,
                display: 'flex',
                float: 'right',
                alignItems: 'center',
              }}
            >
              <ActionButton
                onClick={() => {
                  if (!isNil(onClose)) {
                    onClose();
                  }
                }}
                style={{
                  background: 'none',
                  color: Colors.DARK_SLATE_GREY,
                  marginRight: 20,
                }}
              >
                {toUpper(
                  formatMessage({
                    id: 'cancel',
                  })
                )}
              </ActionButton>
              <LoaderButton data-test="createUser" loading={isSubmitting} success={status}>
                {formatMessage({ id: 'save' })}
              </LoaderButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddMemberForm;
