/* eslint-disable func-names */
import { Collapse, Typography, makeStyles } from '@material-ui/core';
import {
  Colors,
  DocumentCategoryWithoutReadIdAndClientId,
  FileCategory,
  NewFile,
  S3Object,
  getTranslatedCategory,
  isNilOrEmpty,
  isStringDateValid,
} from '@rentguru/commons-utils';
import { FormikProps, useFormikContext } from 'formik';
import { get, isEmpty, isNil } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { resolveMainUnitAndSubUnitIndexAndPrefix } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import ResetIcon from 'src/components/ui/ResetIcon';
import { FileCategoryTypeForm as FileFieldsCategory } from 'src/hooks/TechnicsContext';
import { useUser } from 'src/hooks/UserContext';
import { isValidString } from 'src/utils/formutils';
import * as Yup from 'yup';
import FormSubHeaderWithSwitch from '../../FormSubHeaderWithSwitch';
import DropZoneField from './DropZoneField';
import FieldsDeleteIconButton from './utils/FieldsDeleteIconButton';

export interface FileFormValues {
  filesByCategories: FileFieldsCategory[];
  originalFilesByCategories: FileFieldsCategory[];
}

export interface FileFieldsProps {
  documentCategory: DocumentCategoryWithoutReadIdAndClientId;
  maxFiles?: number;
  categoryWithSwitchInitialValue?: boolean;
  categoryWithSwitchCallback?: (checked: boolean) => void;
  categoryType: 'withSwitch' | 'custom' | 'noHeader';
  customCategoryDeleteCallback?: () => void;
  resetFunction?: () => void;
  centerSelectedFile?: boolean;
  switchOnText?: string;
  switchOffText?: string;
  dropZoneStyle?: React.CSSProperties;
  dropBoxStyle?: React.CSSProperties;
  displayingPictures?: boolean;
  unitId?: string;
  additionalMimeTypes?: string[];
  children?: React.ReactNode;
  indexFromParent?: number;
  setIndexFromParent?: (index: number) => void;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
    paddingLeft: 30,
    paddingRight: 30,
  },
  title: {
    fontWeight: 'bold',
    fontFamily: 'Mulish',
    lineHeight: 1.57,
    fontSize: 14,
  },
});

export const isEpbEnergeticPerformanceValid = (energeticPerformance?: string | null) => {
  return (
    isValidString(energeticPerformance!) &&
    energeticPerformance!.length <= 3 &&
    energeticPerformance![0].toLocaleLowerCase() >= 'a' &&
    energeticPerformance![0].toLocaleLowerCase() <= 'g' &&
    (isNil(energeticPerformance![1]) || energeticPerformance![1] === '+' || energeticPerformance![1] === '-') &&
    (isNil(energeticPerformance![2]) || energeticPerformance![2] === energeticPerformance![1])
  );
};

export const isEpbValidityDateValid = (epbValidityDate?: string | null) => isStringDateValid(epbValidityDate ?? '');

export const isEpbIssueDateValid = (epbIssueDate?: string | null) => isStringDateValid(epbIssueDate ?? '');

export const isEpbReportNumberValid = (epbReportNumber?: string | null) => isValidString(epbReportNumber);

export const FileSchema = Yup.object()
  .shape({
    filesByCategories: Yup.array()
      .of(
        Yup.object().shape({
          category: Yup.object({
            fileCategory: Yup.string().min(1).required(),
          }),
          files: Yup.array().notRequired(),

          PebValidityDate: Yup.string()
            .nullable()
            .test('If one PEB field filled, it should be there', 'PEB but no file error', function (value) {
              const parent = this.parent;
              const isPeb = parent.category.fileCategory === FileCategory.PEB;
              const isOneOtherFieldFilledIn =
                !isEmpty(parent.files) ||
                !isNilOrEmpty(parent.PebEnergeticPerformance) ||
                !isNilOrEmpty(parent.PebIssueDate) ||
                !isNilOrEmpty(parent.PebReportNumber);

              return !isPeb || isEpbValidityDateValid(value) || !isOneOtherFieldFilledIn;
            }),
          PebIssueDate: Yup.string()
            .nullable()
            .test('If one PEB field filled, it should be there', 'PEB but no file error', function (value) {
              const parent = this.parent;
              const isPeb = parent.category.fileCategory === FileCategory.PEB;
              const isOneOtherFieldFilledIn =
                !isEmpty(parent.files) ||
                !isNilOrEmpty(parent.PebEnergeticPerformance) ||
                !isNilOrEmpty(parent.PebValidityDate) ||
                !isNilOrEmpty(parent.PebReportNumber);

              return !isPeb || isEpbIssueDateValid(value) || !isOneOtherFieldFilledIn;
            }),
          PebEnergeticPerformance: Yup.string()
            .nullable()
            .test('If one PEB field filled, it should be there', 'PEB but no file error', function (value) {
              const parent = this.parent;
              const isPeb = parent.category.fileCategory === FileCategory.PEB;
              const isOneOtherFieldFilledIn =
                !isEmpty(parent.files) ||
                !isNilOrEmpty(parent.PebIssueDate) ||
                !isNilOrEmpty(parent.PebValidityDate) ||
                !isNilOrEmpty(parent.PebReportNumber);

              return isEpbEnergeticPerformanceValid(value) || !isPeb || !isOneOtherFieldFilledIn;
            }),
          PebReportNumber: Yup.string().nullable(),
        })
      )
      .notRequired(),
  })
  .required();

const FileFields: React.FC<FileFieldsProps> = ({
  maxFiles = Infinity,
  documentCategory,
  categoryWithSwitchInitialValue,
  categoryWithSwitchCallback,
  categoryType,
  customCategoryDeleteCallback,
  resetFunction,
  children,
  switchOnText,
  switchOffText,
  centerSelectedFile = false,
  dropZoneStyle = {},
  displayingPictures = false,
  unitId,
  additionalMimeTypes = [],
  dropBoxStyle,
  indexFromParent,
  setIndexFromParent,
}) => {
  const [displayCategory, setDisplayCategory] = useState<boolean>(
    !isNil(categoryWithSwitchInitialValue) ? categoryWithSwitchInitialValue : true
  );
  const { values, setFieldValue }: FormikProps<FileFormValues> = useFormikContext();
  const { formatMessage } = useIntl();
  const { language } = useUser();
  const classes = useStyles();

  const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);
  const prefixedFilesByCategoriesField = `${prefixFieldName}filesByCategories`;
  const prefixedFilesByCategories = get(values, prefixedFilesByCategoriesField, []) as FileFieldsCategory[];
  const getIndexFromParentOrFindIndex = useCallback(() => {
    let index;
    if (indexFromParent !== undefined) {
      index = indexFromParent;
    } else {
      index = prefixedFilesByCategories.findIndex(
        (fileCategory: FileFieldsCategory) => fileCategory.category?.fileCategory === documentCategory?.fileCategory
      );
    }
    if (index === -1) {
      return addNewCategory();
    }
    return index;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexFromParent, values, documentCategory?.fileCategory]);

  const addNewCategory = useCallback(() => {
    const newCategory = {
      category: documentCategory,
      files: [],
      unitId,
      ignore: false,
    };

    const updatedCategories = [...prefixedFilesByCategories, newCategory];
    setFieldValue(prefixedFilesByCategoriesField, updatedCategories);
    const newIndex = updatedCategories.length - 1;
    if (setIndexFromParent) {
      setIndexFromParent(newIndex);
    }
    return newIndex;
  }, [
    documentCategory,
    unitId,
    prefixedFilesByCategories,
    setFieldValue,
    prefixedFilesByCategoriesField,
    setIndexFromParent,
  ]);

  const onChangeChecked = useCallback(
    (checked: boolean) => {
      setDisplayCategory(checked);
      if (categoryWithSwitchCallback) {
        categoryWithSwitchCallback(checked);
      }
    },
    [categoryWithSwitchCallback]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // do nothing if no files
      if (isEmpty(acceptedFiles)) {
        return;
      }
      const index = getIndexFromParentOrFindIndex();
      if (index === -1) {
        return;
      }
      const filesToAdd = acceptedFiles.map((acceptedFile: File) => {
        return {
          mimeType: acceptedFile.type,
          name: acceptedFile.name,
          size: acceptedFile.size,
          file: acceptedFile,
        };
      });

      const currentFiles = get(values, `${prefixedFilesByCategoriesField}[${index}].files`, []);
      setFieldValue(`${prefixedFilesByCategoriesField}[${index}].files`, [...currentFiles, ...filesToAdd]);
    },
    // eslint-disable-next-line
    [getIndexFromParentOrFindIndex, get(values, `${prefixedFilesByCategoriesField}`), setFieldValue]
  );

  const removeFileIndex = (index: number) => {
    const indexFromValue = getIndexFromParentOrFindIndex();
    setFieldValue(
      `${prefixedFilesByCategoriesField}[${indexFromValue}].files`,
      (get(values, `${prefixedFilesByCategoriesField}[${[indexFromValue]}].files`)! as (S3Object | NewFile)[]).filter(
        (_file, i) => i !== index
      )
    );
  };

  const categoryRealName = formatMessage({ id: `enums.FileCategory.${documentCategory?.fileCategory}` });

  return (
    <>
      {categoryType === 'withSwitch' && (
        <FormSubHeaderWithSwitch
          title={categoryRealName}
          checkedSwitch={displayCategory!}
          setCheckedSwitch={onChangeChecked}
          subHeaderStyle={{ paddingLeft: 30, paddingRight: 30 }}
          switchOnText={switchOnText}
          switchOffText={switchOffText}
        />
      )}
      {categoryType === 'custom' && (
        <div className={classes.root}>
          <Typography className={classes.title}>
            {documentCategory ? getTranslatedCategory(documentCategory, language) : ''}
          </Typography>
          <FieldsDeleteIconButton
            onClick={() => {
              if (customCategoryDeleteCallback) customCategoryDeleteCallback();
            }}
          />
        </div>
      )}
      <Collapse in={displayCategory} mountOnEnter unmountOnExit>
        {resetFunction && (
          <ResetIcon style={{ fill: Colors.BLUE_GREY, float: 'right', marginRight: 40 }} onClick={resetFunction} />
        )}
        <DropZoneField
          acceptedMimeTypes={[
            ...additionalMimeTypes,
            'image/*',
            'text/plain',
            'application/vnd.ms-excel',
            'application/pdf',
            'application/msword',
            'text/csv',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ]}
          onDrop={onDrop}
          currentFiles={get(values, `${prefixedFilesByCategoriesField}[${getIndexFromParentOrFindIndex()}].files`, [])}
          maxFiles={maxFiles}
          centerSelectedFile={centerSelectedFile}
          removeFileByIndex={removeFileIndex}
          style={dropZoneStyle}
          dropBoxStyle={dropBoxStyle}
          displayingPictures={displayingPictures}
        />
        {children}
      </Collapse>
    </>
  );
};

export default FileFields;
