/* eslint-disable react/no-array-index-key */
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { createStyles, makeStyles, withStyles } from '@material-ui/styles';
import {
  AgencyRate,
  AgencyRateAmountDetailType,
  AgencyRateType,
  clampAgencyRateTotalFees,
  Colors,
  formatNumber,
  getAmountToUseForThisBracketCalulation,
  getFeeAmountWithVATForThisBracketCalculation,
  getSettingValueForVatAgencyRate,
  getTotalFeesAmountWithoutVAT,
  getTotalFeesAmountWithVAT,
} from '@rentguru/commons-utils';
import { TextDetailEditable } from '@up2rent/ui';
import { FormikProps, useFormikContext } from 'formik';
import { isNil } from 'lodash';
import { useIntl } from 'react-intl';
import { useSettings } from 'src/hooks/SettingsContext';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { AddEditAgencyRateValues } from '../AddEditAgencyRateForm';

const useStyles = makeStyles({
  table: {
    minWidth: 510,
    marginTop: 20,
  },
});
const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: Colors.PORCELAIN_GREY_1,
      fontSize: 12,
      fontWeight: 'normal',
      fontStyle: 'normal',
      letterSpacing: 0,
      color: Colors.BLUE_GREY,
    },
    body: {
      fontSize: 14,
      fontWeight: 'normal',
      fontStyle: 'normal',
      letterSpacing: 0,
      color: Colors.SLATE_GREY,
    },
  })
)(TableCell);

const AgencyRateSimulationField = () => {
  const { values }: FormikProps<AddEditAgencyRateValues> = useFormikContext();
  const { settings } = useSettings();
  const { formatMessage } = useIntl();
  const { language } = useLocale();
  const classes = useStyles();

  const agencyFeesVat = getSettingValueForVatAgencyRate(settings);

  const totalAmountWithoutVAT = getTotalFeesAmountWithoutVAT(
    values as unknown as AgencyRate,
    values.amountDetails,
    values.simulationAmount
  );
  const totalAmountWithVAT = getTotalFeesAmountWithVAT(
    values as unknown as AgencyRate,
    values.amountDetails,
    agencyFeesVat,
    values.simulationAmount
  );
  const hideVAT = isNil(agencyFeesVat);
  const clampedTotalFeesWithVAT = clampAgencyRateTotalFees(values.amountMinimumFees, totalAmountWithVAT, agencyFeesVat);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 'bold',
            fontStyle: 'normal',
            letterSpacing: 0,
            color: Colors.CLASSICAL_BLACK,
          }}
        >
          {formatMessage({ id: 'agencyRates.detail.simulation' })}
        </Typography>
        <Typography
          style={{
            fontSize: 12,
            fontWeight: 'normal',
            fontStyle: 'normal',
            letterSpacing: 0,
            color: Colors.BLUE_GREY,
            marginLeft: 5,
          }}
        >
          {formatMessage({ id: 'optional' })}
        </Typography>
      </div>
      <div style={{ marginTop: 20, border: `1px solid ${Colors.SILVER}`, borderRadius: 10, padding: 30 }}>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 'bold',
            fontStyle: 'normal',
            letterSpacing: 0,
            color: Colors.CLASSICAL_BLACK,
          }}
        >
          {!hideVAT
            ? formatMessage({ id: 'accounting.vat.amountWithoutVAT' })
            : formatMessage({ id: 'lease.detail.variousOperations.amount' })}
        </Typography>
        <TextDetailEditable
          title={
            !hideVAT
              ? formatMessage({ id: 'accounting.vat.amountWithoutVAT' })
              : formatMessage({ id: 'lease.detail.variousOperations.amount' })
          }
          editMode={true}
          name="simulationAmount"
          type="number"
          style={{ width: 200, maxWidth: 'none' }}
          endAdornment="€"
        />

        <Table className={classes.table} aria-label="simulation table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ borderRadius: 8 }}>
                {formatMessage({ id: 'agencyRates.detail.bracket' })}
              </StyledTableCell>
              <StyledTableCell>{formatMessage({ id: 'lease.detail.general.rentalPrice' })}</StyledTableCell>
              <StyledTableCell>{formatMessage({ id: 'agencyRates.detail.bracketFees' })}</StyledTableCell>
              {!hideVAT && <StyledTableCell>{formatMessage({ id: 'signup.vat' })}</StyledTableCell>}
              <StyledTableCell>
                {hideVAT
                  ? formatMessage({ id: 'agencyRates.detail.fee' })
                  : formatMessage({ id: 'agencyRates.detail.feeWithVAT' })}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.amountDetails.map((amount, index) => {
              const amountForCalculation = getAmountToUseForThisBracketCalulation(
                values.type as AgencyRateType,
                amount,
                values.simulationAmount
              );
              if (!amountForCalculation) {
                return null;
              }

              const amountWithVat = getFeeAmountWithVATForThisBracketCalculation(
                amount,
                agencyFeesVat,
                amountForCalculation
              );

              return (
                <TableRow key={`${amount.startAmount}_${index}`}>
                  <StyledTableCell component="th" scope="row">
                    {formatNumber(amount.startAmount, language, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                    -
                    {amount.endAmount
                      ? formatNumber(amount.endAmount, language, {
                          style: 'currency',
                          currency: 'EUR',
                        })
                      : ''}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatNumber(amountForCalculation, language, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatNumber(
                      amount.amountType === AgencyRateAmountDetailType.PERCENTAGE
                        ? amount.feeApplied / 100
                        : amount.feeApplied,
                      language,
                      amount.amountType === AgencyRateAmountDetailType.PERCENTAGE
                        ? { style: 'percent', maximumSignificantDigits: 3 }
                        : { style: 'currency', currency: 'EUR' }
                    )}
                  </StyledTableCell>
                  {!hideVAT && (
                    <StyledTableCell>
                      {formatNumber(agencyFeesVat! / 100, language, { style: 'percent', maximumSignificantDigits: 3 })}
                    </StyledTableCell>
                  )}
                  <StyledTableCell>
                    {formatNumber(amountWithVat, language, {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </StyledTableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <StyledTableCell component="th" scope="row" style={{ borderBottom: 'none' }} />
              <StyledTableCell style={{ fontWeight: 'bold', borderBottom: 'none' }}>
                {formatNumber(values.simulationAmount, language, {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold', borderBottom: 'none' }}>
                {formatNumber(totalAmountWithoutVAT, language, {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </StyledTableCell>
              {!hideVAT && <StyledTableCell style={{ borderBottom: 'none' }} />}
              <StyledTableCell style={{ fontWeight: 'bold', borderBottom: 'none' }}>
                {formatNumber(clampedTotalFeesWithVAT, language, {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default AgencyRateSimulationField;
