import React, { useEffect } from 'react';
import DashboardTransactions from './DashboardTransactions';
import DashboardCommunications from './DashboardCommunications';
import isNil from 'lodash/isNil';
import DashboardTickets from './DashboardTickets';
import DashboardDrafts from './DashboardDrafts';
import DashboardLeaseIndexations from './DashboardLeaseIndexations';
import DashboardLeaseActions from './DashboardLeaseOperations';
import DashboardMaintenanceNotifications from './DashboardMaintenance';
import DashboardCustomNotifications from './DashboardCustomNotifications';
import { useUser } from 'src/hooks/UserContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import {
  TRANSACTION_BOX_KEY,
  COMMUNICATION_BOX_KEY,
  MAINTENANCE_BOX_KEY,
  TICKET_TO_DO_BOX_KEY,
  DRAFT_BOX_KEY,
  INDEXATION_BOX_KEY,
  LEASE_OPERATION_BOX_KEY,
  CUSTOM_NOTIFICATION_BOX_KEY,
  TENANT_STATEMENTS_TO_VALIDATE_KEY,
  OWNER_STATEMENTS_TO_VALIDATE_KEY,
} from '@rentguru/commons-utils';
import { useDashboardFilters } from 'src/hooks/DashboardFiltersContext';
import DashboardTenantStatements from './DashboardTenantStatements';
import DashboardOwnerStatements from './DashboardOwnerStatements';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { CustomGridItemComponent } from '../DashboardMetrics/DashboardMetrics';
import { useNotifications } from 'src/hooks/NotificationsContext';
import { useTransactions } from 'src/hooks/TransactionsContext';
import { useLeases } from 'src/hooks/LeasesContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { useCommunications } from 'src/hooks/CommunicationsContext';
import { useTemplates } from 'src/hooks/TemplatesContext';
import { useTickets } from 'src/hooks/TicketsContext';
import { useLeaseInventories } from 'src/hooks/LeaseInventoryContext';
import { useLeasePriceHistories } from 'src/hooks/LeasePriceHistoriesContext';
import { useSignatureDocuments } from 'src/hooks/SignatureDocumentContext';
import { useStatements } from 'src/hooks/StatementContext';
import { Grid } from '@material-ui/core';
import TodosLoading from './TodosLoading';
import { range } from 'lodash';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export interface DashboardTodoProps {
  forceUpdateLayout: React.Dispatch<React.SetStateAction<Layout[]>>;
}

const DashboardTodos: React.FC = () => {
  const { isOwner } = useUser();
  const {
    buildingsUnitsTechnicsRead,
    financialTransactionsRead,
    communicationsDetailsRead,
    notificationsDetailsRead,
    ticketsDetailsRead,
    leasesCreationRead,
    leasesDetailsRead,
    inventoryOfFixturesCreationRead,
    furnituresInventoryCreationRead,
  } = usePermissions();
  const { layoutToDo, setLayoutToDoDidMount, setLayoutOnLayoutChange } = useDashboardFilters();
  const { notificationsLoading } = useNotifications();
  const { transactionsLoading } = useTransactions();
  const { leasesLoading } = useLeases();
  const { unitsLoading } = useUnits();
  const { contactsLoading } = useContacts();
  const { communicationsLoading } = useCommunications();
  const { templatesLoading } = useTemplates();
  const { ticketsLoading } = useTickets();
  const { loading: leaseInventoriesLoading } = useLeaseInventories();
  const { loading: leaseIndexationLoading } = useLeasePriceHistories();
  const { loading: signatureLoading } = useSignatureDocuments();
  const { statementsLoading } = useStatements();

  const dataLoading =
    leasesLoading ||
    contactsLoading ||
    unitsLoading ||
    transactionsLoading ||
    notificationsLoading ||
    communicationsLoading ||
    templatesLoading ||
    ticketsLoading ||
    leaseInventoriesLoading ||
    leaseIndexationLoading ||
    signatureLoading ||
    statementsLoading;
  const items = [
    {
      element: <DashboardCustomNotifications />,
      i: CUSTOM_NOTIFICATION_BOX_KEY,
      permission: !isOwner && notificationsDetailsRead,
    },
    {
      element: <DashboardTransactions />,
      i: TRANSACTION_BOX_KEY,
      permission: !isOwner && financialTransactionsRead,
    },
    {
      element: <DashboardCommunications />,
      i: COMMUNICATION_BOX_KEY,
      permission: !isOwner && communicationsDetailsRead,
    },
    {
      element: <DashboardMaintenanceNotifications />,
      i: MAINTENANCE_BOX_KEY,
      permission: !isOwner && notificationsDetailsRead && buildingsUnitsTechnicsRead,
    },
    {
      element: <DashboardTickets />,
      i: TICKET_TO_DO_BOX_KEY,
      permission: ticketsDetailsRead,
    },
    {
      element: <DashboardDrafts />,
      i: DRAFT_BOX_KEY,
      permission:
        !isOwner && (leasesCreationRead || inventoryOfFixturesCreationRead || furnituresInventoryCreationRead),
    },
    {
      element: <DashboardLeaseIndexations />,
      i: INDEXATION_BOX_KEY,
      permission: !isOwner && leasesDetailsRead,
    },
    {
      element: <DashboardLeaseActions />,
      i: LEASE_OPERATION_BOX_KEY,
      permission: leasesDetailsRead || inventoryOfFixturesCreationRead || furnituresInventoryCreationRead,
    },
    {
      element: <DashboardTenantStatements />,
      i: TENANT_STATEMENTS_TO_VALIDATE_KEY,
      permission: !isOwner && financialTransactionsRead,
    },
    {
      element: <DashboardOwnerStatements />,
      i: OWNER_STATEMENTS_TO_VALIDATE_KEY,
      permission: !isOwner && financialTransactionsRead,
    },
  ];

  useEffect(() => {
    setLayoutToDoDidMount(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (dataLoading) {
    return (
      <Grid>
        {range(0, 12).map((num) => (
          <TodosLoading key={`todos-loading-${num}`} />
        ))}
      </Grid>
    );
  }
  // eslint-disable-next-line no-undef
  const orderedItems = layoutToDo.reduce((acc: { element: JSX.Element; i: string; permission: boolean }[], boxKey) => {
    const item = items.find((i) => i.i === boxKey.i);
    if (isNil(item) || !item.permission) {
      return acc;
    }
    acc.push(item);
    return acc;
  }, []);
  let initialChange = false;

  return (
    <>
      {/* @ts-ignore */}
      <ResponsiveReactGridLayout
        className="layout"
        breakpoints={{ lg: 1200 }}
        cols={{ lg: 1 }}
        rowHeight={1}
        layouts={{ lg: layoutToDo }}
        onLayoutChange={(currentLayout: Layout[]) => {
          if (initialChange) {
            setLayoutOnLayoutChange(currentLayout);
          }
          initialChange = true;
        }}
        isResizable={false}
      >
        {orderedItems.map(({ i, element }) => {
          return (
            <div key={i} data-test={i}>
              <CustomGridItemComponent>{element}</CustomGridItemComponent>
            </div>
          );
        })}
      </ResponsiveReactGridLayout>
    </>
  );
};

export default DashboardTodos;
