import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import {
  Colors,
  Contact,
  getContactNameFromObject,
  LeaseExtended,
  LeaseInventory,
  LeaseInventoryType,
} from '@rentguru/commons-utils';
import { CustomIconButton, TextDetail } from '@up2rent/ui';
import { format, parseISO } from 'date-fns';
import { isEmpty } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import { dateLocaleMap, dateLocaleMapType, useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as ExternalIcon } from 'src/icons/external.svg';
import LeaseInventoryStepper from './LeaseInventoryStepper';

interface GeneralDetailsProps {
  leaseInventory: LeaseInventory;
  lease: LeaseExtended;
}

export const useStyles = makeStyles({
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const GeneralDetails: React.FC<GeneralDetailsProps> = ({ leaseInventory, lease }) => {
  const { language } = useLocale();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const classes = useStyles();

  return (
    <div style={{ flexGrow: 1, marginRight: 8 }}>
      <LeaseInventoryStepper leaseInventory={leaseInventory} style={{ marginTop: 10, marginBottom: 40 }} />
      <Typography variant="h6" style={{ marginBottom: 10 }}>
        {formatMessage({ id: 'rentalUnit.detail.menu.general' })}
      </Typography>
      {lease.tenants && !isEmpty(lease.tenants) && (
        <>
          <Typography style={{ marginTop: 30, fontWeight: 'bold', fontSize: 14 }}>
            {formatMessage({ id: 'leaseInventory.parties' })}
          </Typography>
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            {lease.lessors.map((lessor: Contact, index: number) => (
              <Grid key={lessor.id} item xs={4}>
                <TextDetail
                  title={`${formatMessage({ id: 'lease.addLease.lessor' })} ${index + 1}`}
                  text={getContactNameFromObject(lessor)}
                />
              </Grid>
            ))}
            {lease.tenants.map((tenant: Contact, index: number) => (
              <Grid key={tenant.id} item xs={4}>
                <TextDetail title={`${formatMessage({ id: 'lease.detail.general.tenant' })} ${index + 1}`}>
                  <Typography
                    style={{ color: Colors.CLASSICAL_BLACK, fontSize: 16, fontWeight: 500, display: 'inline-block' }}
                  >
                    {getContactNameFromObject(tenant)}
                  </Typography>
                  <CustomIconButton
                    size="small"
                    onClick={() =>
                      history.push({
                        pathname: `${RouteDestination.CONTACTS}/${tenant.id}`,
                        state: { goBackUrl: history.location },
                      })
                    }
                    className={classes.button}
                    Icon={ExternalIcon}
                    iconStyle={{ fill: Colors.DODGER_BLUE, marginLeft: '5px' }}
                  />
                </TextDetail>
              </Grid>
            ))}
            {lease.guarantors.map((guarantor: Contact, index: number) => (
              <Grid key={guarantor.id} item xs={4}>
                <TextDetail title={`${formatMessage({ id: 'lease.detail.general.guarantor' })} ${index + 1}`}>
                  <Typography
                    style={{ color: Colors.CLASSICAL_BLACK, fontSize: 16, fontWeight: 500, display: 'inline-block' }}
                  >
                    {getContactNameFromObject(guarantor)}
                  </Typography>
                  <CustomIconButton
                    size="small"
                    onClick={() =>
                      history.push({
                        pathname: `${RouteDestination.CONTACTS}/${guarantor.id}`,
                        state: { goBackUrl: history.location },
                      })
                    }
                    className={classes.button}
                    Icon={ExternalIcon}
                    iconStyle={{ fill: Colors.DODGER_BLUE, marginLeft: '5px' }}
                  />
                </TextDetail>
              </Grid>
            ))}
          </Grid>
        </>
      )}
      <Divider style={{ marginRight: 30, marginTop: 30, marginBottom: 30 }} />
      <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>
        {formatMessage({ id: 'leaseInventory.inventoryInfo' })}
      </Typography>
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        <Grid item xs={4}>
          <TextDetail
            title={formatMessage({ id: 'leaseInventory.editMenu.type' })}
            text={
              leaseInventory.inventoryType === LeaseInventoryType.FIXTURES_ENTRANCE
                ? formatMessage({ id: 'leaseInventory.entranceInventory' })
                : leaseInventory.inventoryType === LeaseInventoryType.FIXTURES_EXIT
                ? formatMessage({ id: 'leaseInventory.exitInventory' })
                : leaseInventory.inventoryType === LeaseInventoryType.FURNITURES_ENTRANCE
                ? formatMessage({ id: 'furnitureInventory.entrance' })
                : formatMessage({ id: 'furnitureInventory.exit' })
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextDetail
            title={formatMessage({ id: 'leaseInventory.creationDate' })}
            text={format(parseISO(leaseInventory.createdAt!), 'dd/MM/yyyy', {
              locale: (dateLocaleMap as dateLocaleMapType)[language],
            })}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default GeneralDetails;
