import { Grid, Typography } from '@material-ui/core';
import { Building, getSafeValueInObject } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as BuildingIcon } from '../../../icons/buildingPlaceholder.svg';
import CustomInputComboBox from '../ComboBox/FormikFullCustomComboBox';
import { CustomInputItemType } from '../ComboBox/FullCustomComboBox';
import { useCustomizedComboStyles } from '../ComboBox/TextComboBox';

interface SimpleBuildingSelectorProps {
  buildings: Building[];
  defaultValue?: Building | null;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  fieldName: string;
  inputWidth?: number;
  paperClass?: string;
  disabled?: boolean;
  onChangeObserver?: (
    valueToReturn: CustomInputItemType,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => void;
  noOptionText?: string;
}

const SimpleBuildingSelector: React.FC<SimpleBuildingSelectorProps> = ({
  buildings,
  defaultValue,
  style,
  inputStyle,
  fieldName,
  inputWidth = 560,
  noOptionText,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const comboBoxClasses = useCustomizedComboStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, errors, touched } = useFormikContext<any>();

  const buildingToCustomComboItem = (building: Building) => {
    return {
      primary: building.name,
      secondary: !isNil(building.address) ? building.address.city : '',
      value: building,
    };
  };
  const emptyObject = !isNil(defaultValue)
    ? buildingToCustomComboItem(defaultValue)
    : { primary: '', secondary: '', value: null };
  const buildingSelected = getSafeValueInObject(values, fieldName);

  return (
    <div
      style={{
        alignItems: 'baseline',
        display: 'flex',
        ...style,
      }}
    >
      <CustomInputComboBox
        name={fieldName}
        value={
          !isNil(buildingSelected) && !isEmpty(buildingSelected)
            ? buildingToCustomComboItem(buildingSelected)
            : emptyObject
        }
        label={formatMessage({ id: 'rentalUnit.addUnit.building' })}
        options={buildings.map((b) => buildingToCustomComboItem(b))}
        fieldToReturn="value"
        emptyValue={emptyObject}
        inputStyle={{ width: inputWidth, ...inputStyle }}
        renderOption={(value: CustomInputItemType) => {
          return (
            <Grid container alignContent="space-between" style={{ marginBottom: '5px', marginTop: '5px' }}>
              <Grid item xs={2}>
                <BuildingIcon
                  style={{
                    width: 15,
                    height: 15,
                    marginRight: 10,
                    fill: 'blue',
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography className={comboBoxClasses.primaryText}>{value.primary}</Typography>
              </Grid>
              <Grid item xs={5} style={{ textAlign: 'right' }}>
                {!isNil(value.secondary) && (
                  <Typography className={comboBoxClasses.secondaryText}>{value.secondary}</Typography>
                )}
              </Grid>
            </Grid>
          );
        }}
        renderInput={(value: CustomInputItemType) => {
          return (
            <>
              <BuildingIcon
                style={{
                  width: 15,
                  height: 15,
                  marginRight: 10,
                  fill: 'blue',
                }}
              />
              <Typography
                style={{
                  fontSize: 12,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: `calc(${inputWidth}px - 85px)`,
                }}
              >{`${value.primary} - ${value.secondary}`}</Typography>
            </>
          );
        }}
        error={Boolean(getSafeValueInObject(errors, fieldName) && getSafeValueInObject(touched, fieldName))}
        noOptionsText={noOptionText ?? formatMessage({ id: 'comboBox.noBuildings' })}
        stringToCompare={(value: CustomInputItemType) => `${value.primary} - ${value.secondary}`}
        {...rest}
      />
    </div>
  );
};

export default SimpleBuildingSelector;
