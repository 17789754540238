import { File as FileModel, Invoice, Lease } from '@rentguru/commons-utils';
import { list } from '@up2rent/fetch-utils';
import { isNil } from 'lodash';

export const syncLeases = /* GraphQL */ `
  query SyncLeases($filter: ModelLeaseFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncLeases(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        balance
        status
        language
        startDate
        signatureDate
        type
        name
        endDate
        totalRentalPrice
        inventoryOfFixturesExpert
        inventoryOfFixturesMethod
        inventoryOfFixturesType
        totalMonthlyCharges
        remittanceInformation
        lastInvoiceDate
        paymentFrequency
        paymentInvoicePeriod
        paymentInvoicePeriodCustomDay
        communicationSettingsProfileId
        _deleted
        _version
        bankAccount {
          contactId
          contact {
            id
            firstName
            lastName
            companyName
          }
          number
        }
        contacts {
          items {
            id
            leaseId
            contactId
            contactRole
            contact {
              id
              firstName
              lastName
              companyName
              email
              types
            }
          }
        }
        files {
          items {
            id
            bucket
            categoryId
            key
            mimeType
            size
            createdAt
          }
        }
        units {
          items {
            id
            allowChargeAdjustment
            authorizeProfessionalActivity
            chargesIndexation
            createdAt
            endDate
            furnishedRental
            furnishedRentalInsuranceByTenant
            furnishedRentalRentalPricePercentage
            initialMonthlyCharges
            initialRentalPrice
            indexation
            leaseId
            mainUnit
            monthlyChargesType
            monthlyCharges
            rentalPrice
            signatureDate
            startDate
            unitId
            unit {
              id
              name
              type
              advertisedRentalPrice
              surface
              advertisedMonthlyCharges
              acquisitionAdditionalCostsValue
              acquisitionAdvertisedValue
              acquisitionValue
              cadastralIncome
              createdAt
              floor
              jointOwnershipId
              managementDate
              predictedPrice
              propertyTax
              publishAddress
              buildingId
              unitAdvertisementId
              building {
                address {
                  city
                  street
                  number
                  region
                  id
                  box
                  postalCode
                  country
                  latitude
                  longitude
                }
              }
            }
          }
        }
        leaseInventories {
          items {
            id
            inventoryType
            status
            createdAt
            note
            signatureMethods
            additionalMessage
            file {
              id
              bucket
              categoryId
              key
              mimeType
              size
              createdAt
            }
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const syncLightCommunications = /* GraphQL */ `
  query SyncCommunications(
    $filter: ModelCommunicationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCommunications(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        channel
        recipient
        recipientInBcc
        recipientInCc
        subject
        status
        snoozedDate
        communicationTemplateId
        buildingId
        leaseId
        unitId
        invoiceId
        statementId
        contactId
        toSend
        createdAt
        sentAt
        type
        blockedRecipients {
          recipient
        }
        lease {
          id
          name
        }
        signatureDocument {
          id
          status
        }
        signatureDocumentId
        updatedAt
        _version
        _deleted
        _ttl
      }
      nextToken
      startedAt
    }
  }
`;

export const syncCommunications = /* GraphQL */ `
  query SyncCommunications(
    $filter: ModelCommunicationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCommunications(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        channel
        sender
        recipient
        createdAt
        sentAt
        subject
        body
        status
        snoozedDate
        communicationTemplateId
        files {
          nextToken
          startedAt
        }
        leaseId
        lease {
          id
          balance
          canBeExtended
          createdAt
          endDate
          includeRealEstateInLeaseContract
          invoiceSenderId
          inventoryOfFixturesExpert
          inventoryOfFixturesMethod
          inventoryOfFixturesType
          ipiNumber
          language
          lastIndexationProcessDate
          lastInvoiceDate
          legalCompetentAuthority
          name
          paymentFrequency
          paymentInvoicePeriod
          paymentInvoicePeriodCustomDay
          realEstateAgency
          realEstateTax
          remittanceInformation
          rentalGuarantyAmount
          rentalGuarantyFinancialInstitution
          rentalGuarantyType
          signatureDate
          startDate
          status
          totalInitialMonthlyCharges
          totalInitialRentalPrice
          totalMonthlyCharges
          totalRentalPrice
          type
          vatRateCharge
          vatRateRent
          bankAccountId
          readers
          readId
          clientId
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitId
        invoiceId
        statementId
        signatureDocumentId
        signatureDocument {
          id
          additionalMessage
          createdAt
          fileIds
          foreignKey
          foreignTableName
          signatureMethods
          status
          readers
          readId
          clientId
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        leasePriceHistoryId
        leasePriceHistory {
          id
          previousTotalRentalPrice
          previousTotalMonthlyCharge
          totalRentalPrice
          totalMonthlyCharges
          applicationDate
          status
          comment
          type
          communicationId
          leaseId
          createdAt
          readers
          readId
          clientId
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        toSend
        readId
        clientId
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const syncLeasePriceHistories = /* GraphQL */ `
  query SyncLeasePriceHistories(
    $filter: ModelLeasePriceHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeasePriceHistories(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        previousTotalRentalPrice
        previousTotalMonthlyCharge
        totalRentalPrice
        totalMonthlyCharges
        applicationDate
        status
        comment
        amountDetails {
          unitId
          indexationBaseIndex
          indexationNewIndex
          previousRentalPrice
          newRentalPrice
          rentalStatus
          previousMonthlyChargesPrice
          newMonthlyChargesPrice
          monthlyChargesStatus
          monthlyChargesType
        }
        type
        communicationId
        leaseId
        lease {
          id
          balance
          canBeExtended
          createdAt
          endDate
          includeRealEstateInLeaseContract
          invoiceSenderId
          inventoryOfFixturesExpert
          inventoryOfFixturesMethod
          inventoryOfFixturesType
          ipiNumber
          language
          lastIndexationProcessDate
          lastInvoiceDate
          legalCompetentAuthority
          name
          paymentFrequency
          paymentInvoicePeriod
          paymentInvoicePeriodCustomDay
          realEstateAgency
          realEstateTax
          remittanceInformation
          rentalGuarantyAmount
          rentalGuarantyFinancialInstitution
          rentalGuarantyType
          signatureDate
          startDate
          status
          totalInitialMonthlyCharges
          totalInitialRentalPrice
          totalMonthlyCharges
          totalRentalPrice
          type
          vatRateCharge
          vatRateRent
          bankAccountId
          readers
          readId
          clientId
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        readers
        readId
        clientId
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const syncTickets = /* GraphQL */ `
  query SyncTickets($filter: ModelTicketFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncTickets(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        status
        subject
        content
        sourceType
        createdAt
        plannedAt
        updatedAt
        parentId
        unread
        clientId
        _version
        _deleted
        contactId
        contact {
          id
          firstName
          lastName
          companyName
        }
        attachment {
          nextToken
          startedAt
        }
        conversations {
          items {
            id
            subject
            unread
            content
            createdAt
            subject
            parentId
            clientId
            _version
            _deleted
            contact {
              id
              firstName
              lastName
              companyName
              email
            }
          }
          nextToken
          startedAt
        }
        unitId
        unit {
          id
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const syncTechnics = /* GraphQL */ `
  query SyncTechnics($filter: ModelTechnicFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncTechnics(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        type
        contractNumber
        maintenanceHistory {
          date
          contactId
          fileId
        }
        utilityType
        utilityEanCode
        utilityMeterNumber
        utilityDistributorId
        utilityHistory {
          reading
          date
          reason
        }
        PebValidityDate
        PebEnergeticPerformance
        PebReportNumber
        detectorType
        detectorSerialNumber
        detectorBrand
        detectorLastControlDate
        detectorUnitInventoryId
        heatingBrand
        heatingModel
        heatingInstallationDate
        heatingType
        heatingLastControlDate
        tankType
        tankCapacity
        tankState
        tankLastControlDate
        tankLeakDetection
        tankEncuvement
        tankWallType
        tankInstallationDate
        chimneyLastControlDate
        chimneyUnitInventoryId
        buildingId
        unitId
        leaseId
        contactId
      }
      nextToken
      startedAt
    }
  }
`;

export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      firstName
      lastName
      companyName
      businessNumber
      types
      vat
      email
      birthDate
      birthPlace
      nationalRegister
      civility
      readId
      clientId
    }
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      firstName
      lastName
      cognitoSub
      googleSub
      facebookSub
      rootUser
      refreshToken
      userRoleId
      contactId
      status
      readId
      clientId
    }
  }
`;

export const createTicket = /* GraphQL */ `
  mutation CreateTicket($input: CreateTicketInput!, $condition: ModelTicketConditionInput) {
    createTicket(input: $input, condition: $condition) {
      id
      status
      subject
      content
      sourceType
      createdAt
      plannedAt
      updatedAt
      parentId
      unread
      clientId
      _version
      _deleted
      building {
        id
      }
      contactId
      contact {
        id
        firstName
        lastName
        companyName
      }
      attachment {
        nextToken
        startedAt
      }
      conversations {
        items {
          id
          subject
          unread
          content
          createdAt
          subject
          parentId
          clientId
          _version
          _deleted
          contact {
            id
            firstName
            lastName
            companyName
            email
          }
        }
        nextToken
        startedAt
      }
      unit {
        id
      }
      readId
      _lastChangedAt
    }
  }
`;

export const onUpdateLease = /* GraphQL */ `
  subscription OnUpdateLease {
    onUpdateLease {
      id
      status
      language
      startDate
      endDate
      totalRentalPrice
      totalMonthlyCharges
      _deleted
      _version
      bankAccount {
        contactId
        contact {
          id
          firstName
          lastName
          companyName
        }
        number
      }
      contacts {
        items {
          id
          contactRole
          contact {
            id
            firstName
            lastName
            companyName
            email
            types
          }
        }
        nextToken
        startedAt
      }
      files {
        items {
          id
          bucket
          categoryId
          key
          mimeType
          size
          createdAt
        }
      }
      units {
        items {
          unit {
            id
            name
            type
            building {
              address {
                city
                street
                number
                region
              }
            }
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;

export const onUpdateLeaseContact = /* GraphQL */ `
  subscription OnUpdateLeaseContact {
    onUpdateLeaseContact {
      id
      contact {
        id
        firstName
        lastName
        companyName
        email
        types
      }
    }
  }
`;

export const onUpdateTicket = /* GraphQL */ `
  subscription OnUpdateTicket {
    onUpdateTicket {
      id
      status
      subject
      content
      sourceType
      createdAt
      plannedAt
      updatedAt
      parentId
      unread
      clientId
      _version
      _deleted
      contact {
        id
        firstName
        lastName
        companyName
      }
      attachment {
        nextToken
        startedAt
      }
      conversations {
        items {
          id
          subject
          unread
          content
          createdAt
          subject
          parentId
          clientId
          _version
          _deleted
          contact {
            id
            firstName
            lastName
            companyName
            email
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;

export const syncTransactions = /* GraphQL */ `
  query SyncTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTransactions(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        status
        statementDate
        operationDate
        createdAt
        remittanceInformationType
        remittanceInformation
        comment
        amount
        bankAccountId
        counterpartName
        counterpartAccountNumber
        manualEntry
        proposedLeaseId
        proposedUnitId
        proposedContractorId
        proposedMatch
        links {
          amount
          linkId
          linkType
        }
        bankAccount {
          id
          contact {
            id
            firstName
            lastName
            companyName
          }
          number
          bicNumber
          bankName
          disabled
          readers
          readId
          clientId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        postings {
          items {
            id
            createdAt
            class
            customLabel
            topClass
            type
            totalAmount
            periodFrom
            periodTo
            buildingId
            unitId
            invoiceId
            chargeId
            transactionId
            leaseId
            bankAccountId
            contactId
            leaseVariousOperationId
            statementId
            amountVatExcluded
            vatAmount
            vatRate
            foreignId
            foreignType
            invoicePostingId
            status
            chargeOwnerRepartition
            chargeRepartitionMetric
            chargeRepartitionValue
            chargeSplitNumber
            chargeType
            _deleted
            _version
          }
          nextToken
          startedAt
        }
        readers
        readId
        clientId
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const syncInvoices = /* GraphQL */ `
  query SyncInvoices($filter: ModelInvoiceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncInvoices(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        bankAccountId
        createdAt
        creditNote
        creditNoteLabel
        dueDate
        invoiceNumber
        leaseId
        reminderDates
        statementId
        unitId
        id
        invoiceDate
        amount
        paid
        remittanceInformation
        leaseId
        type
        lease {
          id
          balance
          canBeExtended
          createdAt
          endDate
          includeRealEstateInLeaseContract
          invoiceSenderId
          inventoryOfFixturesExpert
          inventoryOfFixturesMethod
          inventoryOfFixturesType
          ipiNumber
          language
          lastIndexationProcessDate
          lastInvoiceDate
          legalCompetentAuthority
          name
          paymentFrequency
          paymentInvoicePeriod
          paymentInvoicePeriodCustomDay
          realEstateAgency
          realEstateTax
          remittanceInformation
          rentalGuarantyAmount
          rentalGuarantyFinancialInstitution
          rentalGuarantyType
          signatureDate
          startDate
          status
          totalInitialMonthlyCharges
          totalInitialRentalPrice
          totalMonthlyCharges
          totalRentalPrice
          type
          vatRateCharge
          vatRateRent
          bankAccountId
          readers
          readId
          clientId
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const syncPostings = /* GraphQL */ `
  query SyncPostings($filter: ModelPostingFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncPostings(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        createdAt
        class
        customLabel
        topClass
        type
        totalAmount
        periodFrom
        periodTo
        buildingId
        unitId
        invoiceId
        transactionId
        leaseId
        contactId
        leaseVariousOperationId
        statementId
        amountVatExcluded
        vatAmount
        vatRate
        foreignId
        foreignType
        invoicePostingId
        reconciledAt
        status
        chargeOwnerRepartition
        chargeRepartitionMetric
        chargeRepartitionValue
        chargeSplitNumber
        chargeType
        readers
        readId
        clientId
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const syncSignatureDocuments = /* GraphQL */ `
  query SyncSignatureDocuments(
    $filter: ModelSignatureDocumentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSignatureDocuments(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        additionalMessage
        createdAt
        fileIds
        foreignKey
        foreignTableName
        signatureMethods
        signatureResults {
          openedDate
          signedDate
          signed
          signatureLink
          rejectReason
          lastSignInviteSentDate
          contactId
        }
        status
        readers
        readId
        clientId
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const syncFiles = /* GraphQL */ `
  query SyncFiles($filter: ModelFileFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncFiles(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        bucket
        key
        mimeType
        foreignKey
        size
        createdAt
      }
      nextToken
      startedAt
    }
  }
`;

export const syncLeaseVariousOperations = /* GraphQL */ `
  query SyncLeaseVariousOperations(
    $filter: ModelLeaseVariousOperationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeaseVariousOperations(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        amount
        contactId
        createdAt
        createdBy {
          id
          createdAt
          firstName
          lastName
          companyName
          commercialName
          businessNumber
          types
          vat
          email
          birthDate
          birthPlace
          nationalRegister
          civility
          addressId
          language
          agencyRateDisabled
          readers
          readId
          clientId
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        endDate
        label
        lastInvoiceDate
        leaseId
        lease {
          id
          balance
          canBeExtended
          createdAt
          endDate
          includeRealEstateInLeaseContract
          invoiceSenderId
          inventoryOfFixturesExpert
          inventoryOfFixturesMethod
          inventoryOfFixturesType
          ipiNumber
          language
          lastIndexationProcessDate
          lastInvoiceDate
          legalCompetentAuthority
          name
          paymentFrequency
          paymentInvoicePeriod
          paymentInvoicePeriodCustomDay
          realEstateAgency
          realEstateTax
          remittanceInformation
          rentalGuarantyAmount
          rentalGuarantyFinancialInstitution
          rentalGuarantyType
          signatureDate
          startDate
          status
          totalInitialMonthlyCharges
          totalInitialRentalPrice
          totalMonthlyCharges
          totalRentalPrice
          type
          vatRateCharge
          vatRateRent
          bankAccountId
          readers
          readId
          clientId
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        leaseVariousOperationHistories {
          periodFrom
          periodTo
          amount
        }
        operationType
        paymentFrequency
        paymentInvoicePeriod
        paymentInvoicePeriodCustomDay
        reason
        separateInvoice
        startDate
        type
        unitId
        vatRate
        readId
        readers
        clientId
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const syncFilesWithCategoryLabels = /* GraphQL */ `
  query SyncFiles($filter: ModelFileFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncFiles(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        bucket
        createdAt
        category {
          id
          fileCategory
          labels {
            language
            label
          }
          parentId
          readId
          clientId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryId
        foreignKey
        foreignTableName
        key
        mimeType
        region
        signaturePositions {
          top
          left
          page
          width
          height
          contactId
        }
        size
        readers
        readId
        clientId
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const fetchFilesOfInvoice = async (invoice: Invoice): Promise<FileModel[]> => {
  const invoiceFiles = await list<FileModel>(syncFiles, 'foreignKey', invoice.id);
  return invoiceFiles;
};

export const fetchFilesOfLease = async (lease: Lease | undefined): Promise<FileModel[]> => {
  if (isNil(lease)) return [];
  const leaseFiles = await list<FileModel>(syncFiles, 'foreignKey', lease.id);
  return leaseFiles;
};
