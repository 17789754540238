import { Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  Building,
  Colors,
  Communication,
  CommunicationBlockedRecipient,
  CommunicationChannel,
  getSignatureDocumentTrackingLink,
  getSignatureDocumentTrackingProvider,
  Lease,
  mergeRecipients,
  SignatureDocument,
  SignatureDocumentStatus,
} from '@rentguru/commons-utils';
import { capitalize, isEmpty } from 'lodash';
import React from 'react';
import { IntlFormatters, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { CommunicationStatus } from 'src/API';
import FormInfoBox from 'src/components/ui/FormInfoBox';
import { useCommunications } from 'src/hooks/CommunicationsContext';
import { getPrintableDate } from 'src/utils/dates';
import { RouteDestination } from '../Routes/Routes';
import FormAlertBox from '../ui/FormAlertBox';
import RegisteredLetterStatusBox from './RegisteredLetterStatusBox';

const useStyles = makeStyles({
  informationGrid: {
    display: 'flex',
    flexDirection: 'column',
  },
  informationTitle: { color: Colors.LIGHT_BLUE_GREY, fontSize: 12, paddingBottom: 12 },
  communicationLink: {
    color: Colors.SLATE_GREY,
    fontSize: 14,
    textDecoration: 'underline',
    '&:hover': {
      color: Colors.DARK_SKY_BLUE,
    },
  },
  bouncingCommunicationLink: {
    color: Colors.BURNING_ORANGE,
    fontSize: 14,
    textDecoration: 'underline',
    '&:hover': {
      color: Colors.DARK_SKY_BLUE,
    },
  },
  entityNameTypography: { color: Colors.SLATE_GREY, textDecoration: 'underline' },
});
interface BouncedRecipientsListProps {
  blockedRecipients: CommunicationBlockedRecipient[];
  formatMessage: IntlFormatters['formatMessage'];
}

const BouncedRecipientsList = ({ blockedRecipients, formatMessage }: BouncedRecipientsListProps) => (
  <Grid>
    {blockedRecipients?.map((blockedRecipient) => (
      <Typography key={blockedRecipient.recipient} style={{ color: Colors.BURNING_ORANGE, fontSize: 14 }}>
        • {blockedRecipient.recipient} : {formatMessage({ id: `enums.BounceTypes.${blockedRecipient.bounceType}` })}
      </Typography>
    ))}
  </Grid>
);
interface LinkedCommunicationsListProps {
  communications: Communication[];
  className: string;
}

const BouncingLinkedCommunicationsList = ({
  communications: bouncingLinkedCommunications,
  className,
}: LinkedCommunicationsListProps) => (
  <Grid>
    {bouncingLinkedCommunications?.map((bouncingLinkedCommunication) => {
      const allRecipients = mergeRecipients([
        bouncingLinkedCommunication.recipient,
        bouncingLinkedCommunication.recipientInBcc,
        bouncingLinkedCommunication.recipientInCc,
      ]);
      return (
        <Typography key={bouncingLinkedCommunication.id} style={{ color: Colors.BURNING_ORANGE, fontSize: 14 }}>
          {'• '}
          <RouterLink
            to={{
              pathname: `${RouteDestination.COMMUNICATIONS}`,
              state: { communication: bouncingLinkedCommunication.id },
            }}
            className={className}
          >
            {allRecipients.replaceAll(',', ', ')}
          </RouterLink>
        </Typography>
      );
    })}
  </Grid>
);

const SentLinkedCommunicationsList = ({
  communications: sentLinkedCommunications,
  className,
}: LinkedCommunicationsListProps) => (
  <Grid>
    {sentLinkedCommunications?.map((sentLinkedCommunication) => {
      const allRecipients = mergeRecipients([
        sentLinkedCommunication.recipient,
        sentLinkedCommunication.recipientInBcc,
        sentLinkedCommunication.recipientInCc,
      ]);

      return (
        <Typography key={sentLinkedCommunication.id} style={{ color: Colors.SLATE_GREY, fontSize: 14 }}>
          {'• '}
          <RouterLink
            to={{
              pathname: `${RouteDestination.COMMUNICATIONS}${'/communications-history'}`,
              state: { communication: sentLinkedCommunication.id },
            }}
            className={className}
          >
            {allRecipients.replaceAll(',', ', ')}
          </RouterLink>
        </Typography>
      );
    })}
  </Grid>
);

interface CommunicationDetailsContentHeaderProps {
  signatureDocument: SignatureDocument | null | undefined;
  channel: CommunicationChannel;
  dateLabelId: string;
  date: string | undefined | null;
  status?: CommunicationStatus;
  blockedRecipients?: CommunicationBlockedRecipient[];
  linkedCommunicationsIds?: string[];
  dateStyle?: React.CSSProperties;
  gridContainerStyle?: React.CSSProperties;
  gridItemsStyle?: React.CSSProperties;
  lease?: Lease | null;
  building?: Building | null;
}

const CommunicationDetailsContentHeader = ({
  signatureDocument,
  channel,
  dateLabelId,
  date,
  status,
  blockedRecipients,
  linkedCommunicationsIds,
  dateStyle = {},
  gridContainerStyle = {},
  gridItemsStyle = {},
  lease,
  building,
}: CommunicationDetailsContentHeaderProps) => {
  const { formatMessage } = useIntl();
  const { getCommunication } = useCommunications();
  const detailClasses = useStyles();
  const trackingLink = getSignatureDocumentTrackingLink(signatureDocument);
  const trackingProvider = getSignatureDocumentTrackingProvider(signatureDocument);
  const isLetterInError =
    signatureDocument &&
    [SignatureDocumentStatus.REJECTED, SignatureDocumentStatus.ERROR].includes(
      signatureDocument.status as SignatureDocumentStatus
    );
  const communicationIsBouncing = status === CommunicationStatus.BOUNCE_ERROR;
  const bouncingLinkedCommunications: Communication[] = [];
  const sentLinkedCommunications: Communication[] = [];

  linkedCommunicationsIds?.forEach((linkedCommunicationId) => {
    const linkedCommunication = getCommunication(linkedCommunicationId) as Communication;
    if (linkedCommunication?.status === CommunicationStatus.SENT) {
      sentLinkedCommunications.push(linkedCommunication);
    }
    if (
      linkedCommunication?.status === CommunicationStatus.BOUNCE_ERROR ||
      linkedCommunication?.status === CommunicationStatus.TO_SEND
    ) {
      bouncingLinkedCommunications.push(linkedCommunication);
    }
  });

  return (
    <Grid container style={gridContainerStyle}>
      {isLetterInError && (
        <Grid item xs={12}>
          <Grid style={{ display: 'flex', alignItems: 'center', paddingBottom: 20 }}>
            <FormAlertBox
              messagesGridStyle={{ marginRight: 30, fontSize: 14 }}
              gridStyle={{ width: '100%', marginRight: 30, marginLeft: 20 }}
              message1={formatMessage({
                id: `communications.detail.${
                  channel === CommunicationChannel.REGISTERED_LETTER ? 'registeredLetterError' : 'letterError'
                }`,
              })}
            />
          </Grid>
        </Grid>
      )}
      {communicationIsBouncing && (
        <Grid item xs={12}>
          <Grid style={{ display: 'flex', alignItems: 'center', paddingBottom: 20 }}>
            <FormAlertBox
              messagesGridStyle={{ marginRight: 30, fontSize: 14 }}
              gridStyle={{ width: '100%', marginRight: 30, marginLeft: 20 }}
              message1={formatMessage({
                id: 'communications.detail.bounceIntro',
              })}
              subContent={
                <BouncedRecipientsList blockedRecipients={blockedRecipients ?? []} formatMessage={formatMessage} />
              }
            />
          </Grid>
        </Grid>
      )}
      {!isEmpty(sentLinkedCommunications) && (
        <Grid item xs={12}>
          <Grid style={{ display: 'flex', alignItems: 'center', paddingBottom: 20 }}>
            <FormInfoBox
              message={formatMessage({
                id: 'communications.detail.linkedCommunicationAlreadySentIntro',
              })}
              subContent={
                <SentLinkedCommunicationsList
                  communications={sentLinkedCommunications}
                  className={detailClasses.communicationLink}
                />
              }
            />
          </Grid>
        </Grid>
      )}
      {!isEmpty(bouncingLinkedCommunications) && (
        <Grid item xs={12}>
          <Grid style={{ display: 'flex', alignItems: 'center', paddingBottom: 20 }}>
            <FormAlertBox
              messagesGridStyle={{ marginRight: 30, fontSize: 14 }}
              gridStyle={{ width: '100%', marginRight: 30, marginLeft: 20 }}
              message1={formatMessage({
                id: 'communications.detail.linkedCommunicationNotSentIntro',
              })}
              subContent={
                <BouncingLinkedCommunicationsList
                  communications={bouncingLinkedCommunications}
                  className={detailClasses.bouncingCommunicationLink}
                />
              }
            />
          </Grid>
        </Grid>
      )}
      {date && (
        <Grid className={detailClasses.informationGrid} item xs={3} style={gridItemsStyle}>
          <Typography className={detailClasses.informationTitle}>{formatMessage({ id: dateLabelId })}</Typography>
          <Typography variant="body2" style={dateStyle}>
            {getPrintableDate(date)}
          </Typography>
        </Grid>
      )}
      {signatureDocument && signatureDocument.expirationDate && (
        <Grid className={detailClasses.informationGrid} item xs={3} style={gridItemsStyle}>
          <Typography className={detailClasses.informationTitle}>
            {formatMessage({ id: 'communications.list.dateOfSending' })}
          </Typography>
          <Typography variant="body2" style={dateStyle}>
            {getPrintableDate(signatureDocument.expirationDate)}
          </Typography>
        </Grid>
      )}
      {lease?.name && (
        <Grid className={detailClasses.informationGrid} item xs={3} style={gridItemsStyle}>
          <Typography className={detailClasses.informationTitle}>
            {formatMessage({ id: 'communications.list.lease' })}
          </Typography>
          <Typography variant="body2" className={detailClasses.entityNameTypography}>
            <RouterLink
              to={`${RouteDestination.LEASES}/detail/${lease.id}`}
              className={detailClasses.entityNameTypography}
            >
              {lease.name}
            </RouterLink>
          </Typography>
        </Grid>
      )}
      {building?.name && (
        <Grid className={detailClasses.informationGrid} item xs={3} style={gridItemsStyle}>
          <Typography className={detailClasses.informationTitle}>
            {formatMessage({ id: 'communications.list.building' })}
          </Typography>
          <Typography variant="body2" className={detailClasses.entityNameTypography}>
            <RouterLink
              to={`${RouteDestination.PROPERTIES}/${building.id}`}
              className={detailClasses.entityNameTypography}
            >
              {building.name}
            </RouterLink>
          </Typography>
        </Grid>
      )}
      {[CommunicationChannel.REGISTERED_LETTER, CommunicationChannel.LETTER].includes(channel) && signatureDocument && (
        <>
          <Grid item xs={3} className={detailClasses.informationGrid} style={gridItemsStyle}>
            <Typography className={detailClasses.informationTitle}>
              {formatMessage({ id: `lease.addLease.currentStatus` })}
            </Typography>
            <RegisteredLetterStatusBox signatureDocument={signatureDocument} />
          </Grid>
          {trackingLink && (
            <Grid item xs={3} className={detailClasses.informationGrid} style={gridItemsStyle}>
              <Typography className={detailClasses.informationTitle}>
                {formatMessage({ id: `communications.detail.tracking` })}
              </Typography>
              <Link href={trackingLink} style={{ textDecoration: 'none' }} target="_blank">
                <Typography
                  style={{
                    color: Colors.SLATE_GREY,
                    textDecoration: 'underline',
                  }}
                >
                  {capitalize(trackingProvider)}
                </Typography>
              </Link>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default CommunicationDetailsContentHeader;
