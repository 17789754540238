import { Collapse, Typography } from '@material-ui/core';
import {
  ACCOUNT_TURNOVER_FIXED_CHARGES_CLASS,
  ACCOUNT_TURNOVER_FURNISHED_RENT_CLASS,
  ACCOUNT_TURNOVER_RENT_CLASS,
  Colors,
  getDateWithShortMonth,
  getMonthYear,
  getPostingLabel,
  getSafeValueInObject,
  InvoiceWithPostings,
  isPostingARentDiscount,
  Lease,
  LeaseExtended,
  Posting,
  sortPostingsByUnitAndClass,
  Unit,
} from '@rentguru/commons-utils';
import { TextDetailEditable } from '@up2rent/ui';
import { format, isSameDay } from 'date-fns';
import { useFormikContext } from 'formik';
import { isNil, maxBy, minBy } from 'lodash';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useTransactions } from 'src/hooks/TransactionsContext';
import { dateLocaleMap, useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as ArrowRightIcon } from 'src/icons/arrow-right.svg';
import { ReactComponent as PostingIcon } from 'src/icons/receipt.svg';
import { toLowerCase } from '../../../RentalUnits/Details/Publication/PublicationDescription/PublicationDescriptionGeneral';
import CustomCheckBox from '../../CustomCheckBox';
import StyledRadio from '../../StyledRadio';

const getFormattedPeriods = (periodFrom: Date, periodTo: Date, language: string) => {
  return `${format(periodFrom, 'dd/MM/yyyy', {
    locale: dateLocaleMap[language],
  })} - ${format(periodTo, 'dd/MM/yyyy', {
    locale: dateLocaleMap[language],
  })}`;
};

export interface InvoicePosting {
  posting: Posting;
  amount: number;
}
interface InvoiceAndPostingsCheckBoxesProps {
  allInvoicesWithPostings: InvoiceWithPostings[];
  invoicePostingsName: string;
  lease: LeaseExtended;
}

const InvoiceAndPostingsCheckBoxes: React.FC<InvoiceAndPostingsCheckBoxesProps> = ({
  allInvoicesWithPostings,
  invoicePostingsName,
  lease,
}) => {
  const { values, setFieldValue } = useFormikContext<unknown>();
  const { formatMessage } = useIntl();
  const { language } = useLocale();

  const selectedInvoicePostingRaw: InvoicePosting[] | '' = getSafeValueInObject(values, invoicePostingsName);

  const selectedInvoicePosting = selectedInvoicePostingRaw !== '' ? selectedInvoicePostingRaw : [];
  let previousMonthYear = '';
  const hasOnlyOneInvoice = allInvoicesWithPostings.length === 1;
  useEffect(() => {
    if (!hasOnlyOneInvoice) {
      return;
    }
    const invoiceWithPostings = allInvoicesWithPostings[0];
    setFieldValue(
      invoicePostingsName,
      invoiceWithPostings.postings.reduce((acc: InvoicePosting[], posting) => {
        if (!isPostingARentDiscount(posting)) {
          acc.push({ posting, amount: posting.totalAmount });
        }
        return acc;
      }, [])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allInvoicesWithPostings]);
  const allUnitsOfLeases = lease.units?.map((leaseUnit) => leaseUnit.unit) as Unit[] | undefined;

  return (
    <>
      {!hasOnlyOneInvoice && (
        <Typography style={{ fontSize: 16, fontWeight: 700, marginBottom: 20 }}>
          {formatMessage({ id: 'accounting.creditNote.selectInvoice' })}
        </Typography>
      )}
      {allInvoicesWithPostings.map((invoiceWithPostings, index) => {
        const invoiceSelected =
          selectedInvoicePosting.some(
            (posting) => posting.posting.invoiceId && posting.posting.invoiceId === invoiceWithPostings.id
          ) || hasOnlyOneInvoice;
        const minPeriodFrom = minBy(invoiceWithPostings.postings, 'periodFrom')?.periodFrom ?? new Date();
        const maxPeriodTo = maxBy(invoiceWithPostings.postings, 'periodTo')?.periodTo ?? new Date();
        const invoicePeriod = { periodFrom: new Date(minPeriodFrom), periodTo: new Date(maxPeriodTo) };
        const dateLocale = dateLocaleMap[language];
        const targetMonthYear =
          invoiceWithPostings.type === 'RENT'
            ? getMonthYear(minPeriodFrom, dateLocale)
            : getMonthYear(invoiceWithPostings.invoiceDate, dateLocale);
        const isMonthSubtitleNeeded = previousMonthYear !== targetMonthYear;
        previousMonthYear = targetMonthYear;
        return (
          <div key={invoiceWithPostings.id}>
            {isMonthSubtitleNeeded && (
              <Typography
                style={{ fontSize: 16, fontWeight: 700, marginBottom: 20, marginTop: 20, textAlign: 'left' }}
                key={targetMonthYear}
              >
                {toLowerCase(targetMonthYear)}
              </Typography>
            )}
            <div key={invoiceWithPostings.id}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  border: `1px solid ${Colors.GEYSER_GREY}`,
                  borderRadius: 10,
                  alignItems: 'center',
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginTop: index === 0 ? 0 : 10,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setFieldValue(
                    invoicePostingsName,
                    invoiceWithPostings.postings.reduce((acc: InvoicePosting[], posting) => {
                      if (!isPostingARentDiscount(posting)) {
                        acc.push({ posting, amount: posting.totalAmount });
                      }
                      return acc;
                    }, [])
                  );
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <StyledRadio checked={invoiceSelected} />
                  <PostingIcon style={{ marginLeft: 10, marginRight: 10, fill: Colors.TOWER_GREY }} />
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      color: Colors.CLASSICAL_BLACK,
                      textAlign: 'left',
                    }}
                  >
                    {formatMessage({ id: `enums.InvoiceType.${invoiceWithPostings.type}` })}
                  </Typography>
                </div>
                {invoiceWithPostings.type === 'RENT' ? (
                  <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                    <Typography style={{ fontSize: 16 }}>
                      {getDateWithShortMonth(minPeriodFrom, dateLocale, true)}
                    </Typography>
                    <ArrowRightIcon style={{ fill: Colors.BLUEY }} key="icon" />
                    <Typography style={{ fontSize: 16 }}>
                      {getDateWithShortMonth(maxPeriodTo, dateLocale, true)}
                    </Typography>
                  </div>
                ) : (
                  <Typography style={{ fontSize: 16 }}>
                    {getDateWithShortMonth(invoiceWithPostings.invoiceDate, dateLocale)}
                  </Typography>
                )}
              </div>
              <Collapse in={invoiceSelected} mountOnEnter unmountOnExit>
                {(sortPostingsByUnitAndClass(invoiceWithPostings.postings, allUnitsOfLeases ?? []) as Posting[]).map(
                  (posting: Posting) => {
                    const isSamePeriod =
                      isSameDay(invoicePeriod.periodFrom, new Date(posting.periodFrom)) &&
                      isSameDay(invoicePeriod.periodTo, new Date(posting.periodTo));
                    return (
                      <PostingCheckBox
                        key={posting.id}
                        name={invoicePostingsName}
                        posting={posting}
                        selectedInvoicePosting={selectedInvoicePosting}
                        lease={lease}
                        isSamePeriod={isSamePeriod}
                      />
                    );
                  }
                )}
              </Collapse>
            </div>
          </div>
        );
      })}
    </>
  );
};

interface PostingCheckBoxProps {
  selectedInvoicePosting: InvoicePosting[];
  posting: Posting;
  name: string;
  lease: Lease;
  isSamePeriod: boolean;
}

const PostingCheckBox: React.FC<PostingCheckBoxProps> = ({
  selectedInvoicePosting,
  posting,
  name,
  lease,
  isSamePeriod,
}) => {
  const { formatMessage } = useIntl();
  const { accountLabels } = useTransactions();
  const { language } = useLocale();
  const { setFieldValue, errors } = useFormikContext<unknown>();

  const multiUnits = Boolean(lease.units && lease.units && lease.units.length > 1);

  let amountLabel;
  if (
    (posting.class === ACCOUNT_TURNOVER_RENT_CLASS || posting.class === ACCOUNT_TURNOVER_FURNISHED_RENT_CLASS) &&
    !isNil(lease.vatRateRent)
  ) {
    amountLabel = formatMessage({ id: 'accounting.charge.checkCharge.amountWithVAT' });
  } else if (posting.class === ACCOUNT_TURNOVER_FIXED_CHARGES_CLASS && !isNil(lease.vatRateCharge)) {
    amountLabel = formatMessage({ id: 'accounting.charge.checkCharge.amountWithVAT' });
  } else {
    amountLabel = formatMessage({ id: 'settings.addVariousOperation.amount' });
  }
  const postingSelectionIndex = selectedInvoicePosting.findIndex((sp) => sp.posting.id === posting.id);
  const isPostingSelected = postingSelectionIndex !== -1;
  const rentDiscount = isPostingARentDiscount(posting);
  let postingLabel = getPostingLabel(posting, language, accountLabels);
  if (multiUnits) {
    const unitOfPosting = lease.units?.find((unitLease) => unitLease.unit?.id === posting.unitId)?.unit;
    if (unitOfPosting) {
      const unitType = formatMessage({ id: `enums.UnitType.${unitOfPosting.type}` });
      postingLabel = `${postingLabel}: ${unitType} - ${unitOfPosting.name}`;
    }
  }
  postingLabel = `${postingLabel}${
    isSamePeriod ? '' : ` - ${getFormattedPeriods(new Date(posting.periodFrom), new Date(posting.periodTo), language)}`
  }`;

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CustomCheckBox
          checkedColor={Colors.DODGER_BLUE}
          isChecked={isPostingSelected}
          onCheck={() => {
            if (isPostingSelected) {
              setFieldValue(
                name,
                selectedInvoicePosting.filter((p) => p.posting.id !== posting.id)
              );
            } else {
              setFieldValue(name, [...selectedInvoicePosting, { posting, amount: posting.totalAmount }]);
            }
          }}
          disabled={Boolean(rentDiscount)}
        />
        <Typography style={{ fontSize: 14, fontWeight: 600, maxWidth: 280 }}>{postingLabel}</Typography>
      </div>
      <TextDetailEditable
        title={amountLabel}
        editMode={true}
        min={0}
        max={posting.totalAmount}
        endAdornment="€"
        name={`${name}[${postingSelectionIndex}].amount`}
        type="number"
        step={0.01}
        style={{ width: 190, maxWidth: 'none', marginLeft: 20 }}
        error={Boolean(getSafeValueInObject(errors, `${name}[${postingSelectionIndex}]`))}
        disabled={!isPostingSelected}
      />
    </div>
  );
};

export default InvoiceAndPostingsCheckBoxes;
