import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/styles/withStyles';
import { Colors } from '@rentguru/commons-utils';
import { TextDetailEditable } from '@up2rent/ui';
import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import { CountryCode, CountryResultToDisplay } from 'src/components/ui/CountrySelector';
import CountrySelector from 'src/components/ui/CountrySelector/FormikCountrySelector';
import * as Yup from 'yup';
import PlaceAutocompleteField from './PlaceAutocompleteField';

export interface AddressFormValues {
  address: Address;
}

export interface Address {
  country: CountryCode;
  postalCode: string;
  street: string;
  number: string;
  box: string;
  city: string;
}

export interface AddressFormValuesAttachedToBuilding {
  building: AddressFormValues;
}

interface AddressFieldsRowProps {
  classes: ClassNameMap;
  namePrefix: string;
  typedErrors: FormikErrors<AddressFormValues>;
  typedTouched: FormikTouched<AddressFormValues>;
}

interface AddressRowProps extends AddressFieldsRowProps {}

interface LocalityRowProps extends AddressFieldsRowProps {}

interface CountryRowProps extends AddressFieldsRowProps {
  typedValues: AddressFormValues;
  countrySelectorStyle?: React.CSSProperties;
}

interface AddressFieldsProps {
  containerStyle?: React.CSSProperties;
  placeAutocompleteStyle?: React.CSSProperties;
  placeAutocompleteMargin?: 'dense' | 'normal';
  countrySelectorStyle?: React.CSSProperties;
}

export const AddressSchema = Yup.object()
  .shape({
    address: Yup.object().shape({
      country: Yup.string().min(2).max(2).required(),
      postalCode: Yup.string().min(2).required(),
      street: Yup.string().min(2).required(),
      number: Yup.string().min(1).max(10).required(),
      box: Yup.string().max(10).nullable(),
      city: Yup.string().min(2).max(150).required(),
    }),
  })
  .required();

export const AddressSchemaAttachedToBuilding = Yup.object().shape({ building: AddressSchema });

const useStyles = makeStyles({
  wrapper: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    color: Colors.LIGHT_BLUE_GREY,
  },
  underline: {
    '&:before': {
      borderBottom: `2px solid ${Colors.TOWER_GREY}`,
    },
  },
  container: {
    borderRadius: 8,
    border: `1px solid ${Colors.SILVER}`,
    borderTop: 'none',
    marginTop: -11,
    padding: 20,
    justifyContent: 'center',
  },
  row: { display: 'flex', alignItems: 'center' },
  addressCategory: {
    color: Colors.BLUE_GREY,
    width: 80,
    fontSize: 16,
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'right',
  },
});

const AddressFields: React.FC<AddressFieldsProps> = ({
  containerStyle = { marginRight: 30, marginLeft: 30 },
  placeAutocompleteStyle,
  placeAutocompleteMargin,
  countrySelectorStyle,
}) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<
    AddressFormValues | AddressFormValuesAttachedToBuilding
  >();
  const classes = useStyles();

  const isAttachedToBuilding = !isNil((values as AddressFormValuesAttachedToBuilding).building);
  let typedValues: AddressFormValues = values as AddressFormValues;
  let typedErrors: FormikErrors<AddressFormValues> = errors as FormikErrors<AddressFormValues>;
  let typedTouched: FormikTouched<AddressFormValues> = touched as FormikTouched<AddressFormValues>;
  if (isAttachedToBuilding) {
    typedValues = (values as AddressFormValuesAttachedToBuilding).building as AddressFormValues;
    if (!isNil(errors) && !isNil((errors as FormikErrors<AddressFormValuesAttachedToBuilding>).building)) {
      typedErrors = (errors as FormikErrors<AddressFormValuesAttachedToBuilding>)
        .building as FormikErrors<AddressFormValues>;
    }
    if (!isNil(touched) && !isNil((touched as FormikErrors<AddressFormValuesAttachedToBuilding>).building)) {
      typedTouched = (touched as FormikTouched<AddressFormValuesAttachedToBuilding>)
        .building as FormikTouched<AddressFormValues>;
    }
  }
  const namePrefix = `${isAttachedToBuilding ? 'building.' : ''}`;

  const onAutocompleteResult = (address: Address) => {
    setFieldValue(`${namePrefix}address`, address);
  };

  return (
    <>
      <div className={classes.wrapper} style={containerStyle}>
        <PlaceAutocompleteField
          onChange={onAutocompleteResult}
          inputStyle={placeAutocompleteStyle}
          inputMargin={placeAutocompleteMargin}
        />
        <Grid container className={classes.container}>
          <AddressRow classes={classes} namePrefix={namePrefix} typedErrors={typedErrors} typedTouched={typedTouched} />
          <LocalityRow
            classes={classes}
            namePrefix={namePrefix}
            typedErrors={typedErrors}
            typedTouched={typedTouched}
          />
          <CountryRow
            classes={classes}
            countrySelectorStyle={countrySelectorStyle}
            namePrefix={namePrefix}
            typedErrors={typedErrors}
            typedTouched={typedTouched}
            typedValues={typedValues}
          />
        </Grid>
      </div>
    </>
  );
};

const AddressRow: React.FC<AddressRowProps> = ({ classes, namePrefix, typedErrors, typedTouched }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid container item xs={12} className={classes.row} spacing={1}>
      <Grid item xs={3}>
        <TextDetailEditable
          title={formatMessage({ id: 'contact.addContact.numberLabel' })}
          editMode={true}
          name={`${namePrefix}address.number`}
          type="text"
          error={Boolean(
            typedErrors.address && typedErrors.address!.number && typedTouched.address && typedTouched.address!.number
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <TextDetailEditable
          title={formatMessage({ id: 'contact.addContact.boxLabel' })}
          editMode={true}
          name={`${namePrefix}address.box`}
          type="text"
          error={Boolean(
            typedErrors.address && typedErrors.address!.box && typedTouched.address && typedTouched.address!.box
          )}
        />
      </Grid>
      <Grid item xs={7}>
        <TextDetailEditable
          title={formatMessage({ id: 'contact.addContact.streetLabel' })}
          editMode={true}
          name={`${namePrefix}address.street`}
          type="text"
          error={Boolean(
            typedErrors.address && typedErrors.address!.street && typedTouched.address && typedTouched.address!.street
          )}
          noMaxWidth
        />
      </Grid>
    </Grid>
  );
};

const LocalityRow: React.FC<LocalityRowProps> = ({ classes, namePrefix, typedErrors, typedTouched }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid container item xs={12} className={classes.row} spacing={1}>
      <Grid item xs={5}>
        <TextDetailEditable
          title={formatMessage({
            id: 'contact.addContact.postCodeLabel',
          })}
          editMode={true}
          name={`${namePrefix}address.postalCode`}
          type="text"
          error={Boolean(
            typedErrors.address &&
              typedErrors.address!.postalCode &&
              typedTouched.address &&
              typedTouched.address!.postalCode
          )}
        />
      </Grid>
      <Grid item xs={7}>
        <TextDetailEditable
          title={formatMessage({
            id: 'contact.addContact.townLabel',
          })}
          editMode={true}
          name={`${namePrefix}address.city`}
          type="text"
          error={Boolean(
            typedErrors.address && typedErrors.address!.city && typedTouched.address && typedTouched.address!.city
          )}
          noMaxWidth
        />
      </Grid>
    </Grid>
  );
};

const CountryRow: React.FC<CountryRowProps> = ({
  classes,
  countrySelectorStyle,
  namePrefix,
  typedErrors,
  typedTouched,
  typedValues,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Grid container item xs={12} className={classes.row} spacing={1}>
      <Grid item xs={12}>
        <CountrySelector
          placeholder={formatMessage({
            id: 'contact.addContact.countryLabel',
          })}
          required
          fieldToReturn="code"
          formStyle={countrySelectorStyle}
          fullWidth={true}
          name={`${namePrefix}address.country`}
          error={Boolean(
            !isNil(typedErrors.address) &&
              typedErrors.address!.country! &&
              !isNil(typedTouched.address) &&
              typedTouched.address &&
              typedTouched.address!.country!
          )}
          selectedCountry={typedValues.address.country}
          resultToDisplay={CountryResultToDisplay.Name}
        />
      </Grid>
    </Grid>
  );
};

export default AddressFields;
