import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { Colors } from '@rentguru/commons-utils';

export const StyledCheckbox = withStyles({
  root: {
    color: Colors.BLUE_GREY,
    '&$checked': {
      color: Colors.DODGER_BLUE,
      '&:hover': {
        backgroundColor: `${Colors.DODGER_BLUE}10`,
      },
    },
    '&$disabled': {
      color: `${Colors.DODGER_BLUE}80`,
    },
    '&:hover': {
      backgroundColor: `${Colors.DODGER_BLUE}10`,
    },
  },
  checked: {},
  disabled: {},
})(Checkbox);
