import { makeStyles } from '@material-ui/styles';

export const useNumberOfSelectedItemsStyles = makeStyles({
  container: {
    height: '100%',
    paddingLeft: 30,
    paddingRight: 40,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
});
