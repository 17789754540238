import { useUser } from 'src/hooks/UserContext';
import { useUsers } from 'src/hooks/UsersContext';
import AccountDeletionForm from './General/AccountDeletionForm';
import AccountTypeForm from './General/AccountTypeForm';
import CompanyContactForm from './General/CompanyContactForm';
import CompanyLogoForm from './General/CompanyLogoForm';

const GeneralSettings = () => {
  const { isAccountPrivate, loading } = useUsers();
  const { rootUser } = useUser();

  return (
    <>
      <CompanyLogoForm />
      {!loading && !isAccountPrivate() && <CompanyContactForm />}
      <AccountTypeForm />
      {rootUser && <AccountDeletionForm />}
    </>
  );
};

export default GeneralSettings;
