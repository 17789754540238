/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import { Typography } from '@material-ui/core';
import {
  Building,
  BuildingEvent,
  Colors,
  Unit,
  UnitEvent,
  UnitEventType,
  UnitLease,
  endOfUTCDay,
  getOverlappingEvents,
  getUTCDate,
  isNilOrEmpty,
  startOfUTCDay,
} from '@rentguru/commons-utils';
import { TextDetailEditable } from '@up2rent/ui';
import { addDays, isAfter } from 'date-fns';
import { FormikErrors, FormikProps, useFormikContext } from 'formik';
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import CustomDatePicker from 'src/components/ui/CustomDatePicker';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { dateLocaleMap } from 'src/i18n/IntlProviderWrapper';
import { getDetailedOverlappingError } from 'src/utils/unitsUtils';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import FieldsDeleteIconButton from './utils/FieldsDeleteIconButton';

export interface RenovationCostsFormValues {
  renovations: Event[];
}

export interface Event {
  id: string;
  startDate: string;
  endDate: string;
  totalCost?: number;
  note?: string;
}

export const emptyEvent: Event = {
  id: `new-${uuidv4()}`,
  startDate: startOfUTCDay(new Date()).toISOString(),
  endDate: endOfUTCDay(addDays(new Date(), 1)).toISOString(),
  totalCost: undefined,
};

export interface NewEvent {
  id: string;
  startDate: string;
  endDate: string;
}
/**
 * Given a building or a unit and some events that are encoded in the form. This function returns all the
 * events that will appear on the timeline and that can therefore not overlap.
 * If multiple is true, this means that the form is in a state where the user has access to all the renovations
 * events, and therefore, we don't need to add those from the unit/building.
 */
export const getAllOverlappableEvents = (
  entity: Unit | Building,
  formEvents: NewEvent[],
  multiple: boolean
): (UnitLease | UnitEvent | BuildingEvent | NewEvent)[] => {
  if ('managementDate' in entity) {
    // Unit
    let allUnitsEvents: (UnitLease | UnitEvent | NewEvent)[] = [...formEvents];
    if (!isNil(entity.events)) {
      entity.events.forEach((unitEvent) => {
        if (unitEvent.type === UnitEventType.WORK && !multiple) {
          const renovationAlreadyInForm = allUnitsEvents.some((uEvent) => uEvent.id === unitEvent.id);
          if (!renovationAlreadyInForm) allUnitsEvents.push(unitEvent);
        } else allUnitsEvents.push(unitEvent);
      });
    }
    if (!isNil(entity.leases)) {
      allUnitsEvents = [...allUnitsEvents, ...entity.leases];
    }
    return allUnitsEvents;
  }
  // Building
  if (!isNil(entity.events) && !multiple) {
    const allBuildingEvents = entity.events.reduce(
      (acc: (BuildingEvent | NewEvent)[], event) => {
        const renovationAlreadyInForm = acc.some((accEvent) => accEvent.id === event.id);
        if (!renovationAlreadyInForm) acc.push(event);
        return acc;
      },
      [...formEvents]
    );
    return allBuildingEvents;
  }

  return [];
};

export const getProblematicEvent = (
  formEvents: (UnitEvent | BuildingEvent | NewEvent)[],
  allEvents: (UnitLease | UnitEvent | BuildingEvent | NewEvent)[]
): { problemIndex: number; problemEvent: UnitLease | UnitEvent | BuildingEvent | Omit<NewEvent, 'id'> } | null => {
  let index = 0;
  for (const formEvent of formEvents) {
    const allOtherEvents = allEvents.filter((e) => e.id !== formEvent.id);
    const overlappingEvents = getOverlappingEvents(formEvent.startDate, formEvent.endDate, allOtherEvents);
    if (!isEmpty(overlappingEvents)) {
      return { problemIndex: index, problemEvent: overlappingEvents[0] };
    }
    index += 1;
  }
  return null;
};

export const StaticRenovationCostsSchema = Yup.object()
  .shape({
    renovations: Yup.array().of(
      Yup.object()
        .shape({
          startDate: Yup.string().required(),
          endDate: Yup.string()
            .required()
            .test('After start date', 'overlapping renovations dates error', function (value) {
              if (isNil(value)) return false;

              return isAfter(new Date(value), new Date(this.parent.startDate));
            }),
          totalCost: Yup.number().moreThan(0).required(),
          note: Yup.string().nullable(),
        })
        .notRequired()
    ),
  })
  .required();

export const getDynamicRenovationCostsSchema = (
  entity: Unit | Building,
  multiple: boolean, // Are we in the situations where we can manipulate all renovations
  formatMessage: (descriptor: MessageDescriptor, values?: any) => string,
  language: string
) => {
  return Yup.object()
    .shape({
      renovations: Yup.array()
        .of(
          Yup.object()
            .shape({
              id: Yup.string().min(1).required(),
              startDate: Yup.string().required(),
              endDate: Yup.string()
                .required()
                .test('After start date', 'overlapping renovations dates error', function (value) {
                  if (isNil(value)) return false;

                  return isAfter(new Date(value), new Date(this.parent.startDate));
                }),
              totalCost: Yup.number().moreThan(0).required(),
              note: Yup.string().nullable(),
            })
            .required()
        )
        .test('Events test', 'No overlapping events error', function (events) {
          if (isNil(events)) return true;
          const allEvents = getAllOverlappableEvents(entity, events, multiple);
          const problematicEvent = getProblematicEvent(events, allEvents);
          if (isNil(problematicEvent)) return true;

          const { problemIndex, problemEvent } = problematicEvent;
          return this.createError({
            path: `renovations[${problemIndex}].overlapping`,
            message: getDetailedOverlappingError(problemEvent, formatMessage, dateLocaleMap[language]),
          });
        }),
    })
    .required();
};

interface RenovationCostsFieldsProps {
  shouldDisplayEmptyRenovation?: boolean;
  multiple?: boolean;
}

const RenovationCostsFields: React.FC<RenovationCostsFieldsProps> = ({
  shouldDisplayEmptyRenovation = true,
  multiple = true,
}) => {
  const { financialValuationsAndCostsDelete } = usePermissions();
  const { values, errors, touched, setFieldValue }: FormikProps<RenovationCostsFormValues> = useFormikContext();

  const [addEmptyRenovation, setAddEmptyRenovation] = React.useState<boolean>(isNilOrEmpty(values.renovations));
  const { formatMessage } = useIntl();

  const removeRenovation = (index: number) => {
    setFieldValue(
      'renovations',
      values.renovations!.filter((_r, i) => i !== index)
    );
  };

  // This is to make sure there is one empty renovation inside values
  // if the user arrives on page and shouldDisplayEmptyRenovation is true
  if (addEmptyRenovation && shouldDisplayEmptyRenovation) {
    setFieldValue('renovations', [emptyEvent]);
    setAddEmptyRenovation(false);
  }
  return (
    <>
      {values.renovations &&
        values.renovations.map((renovation, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${renovation.id}-${index}`}>
              {multiple && (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: 30 }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: 14,
                      marginBottom: 10,
                      marginTop: 10,
                      marginLeft: 30,
                      textAlign: 'left',
                    }}
                  >
                    {formatMessage({ id: 'rentalUnit.detail.general.renovationCosts' })}
                  </Typography>
                  {financialValuationsAndCostsDelete && (
                    <FieldsDeleteIconButton
                      style={{ color: Colors.BLUE_GREY }}
                      onClick={() => removeRenovation(index)}
                    />
                  )}
                </div>
              )}
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CustomDatePicker
                  name={`renovations[${index}].startDate`}
                  label={formatMessage({ id: 'mortgage.startDate' })}
                  value={
                    values.renovations[index].startDate
                      ? getUTCDate(new Date(values.renovations[index].startDate))
                      : null
                  }
                  style={{ width: 280 }}
                  onChange={(date) => {
                    if (date) setFieldValue(`renovations[${index}].startDate`, startOfUTCDay(date).toISOString());
                  }}
                  safeDateFormat={true}
                  error={Boolean(
                    errors.renovations &&
                      errors.renovations[index] &&
                      (errors.renovations[index] as FormikErrors<Event>).startDate &&
                      touched.renovations &&
                      touched.renovations[index].startDate
                  )}
                />
                <CustomDatePicker
                  name={`renovations[${index}].endDate`}
                  label={formatMessage({ id: 'lease.addLease.endDate' })}
                  value={
                    values.renovations[index].endDate ? getUTCDate(new Date(values.renovations[index].endDate)) : null
                  }
                  style={{ width: 280, marginLeft: 20 }}
                  onChange={(date) => {
                    if (date) setFieldValue(`renovations[${index}].endDate`, endOfUTCDay(date).toISOString());
                  }}
                  safeDateFormat={true}
                  error={Boolean(
                    errors.renovations &&
                      errors.renovations[index] &&
                      (errors.renovations[index] as FormikErrors<Event>).endDate &&
                      touched.renovations &&
                      touched.renovations[index].endDate
                  )}
                  minDate={
                    !isNil(values.renovations[index].startDate)
                      ? new Date(values.renovations[index].startDate)
                      : undefined
                  }
                />
              </div>
              {Boolean(
                errors.renovations && errors.renovations[index] && (errors.renovations[index] as any).overlapping
              ) && (
                <div style={{ textAlign: 'center' }}>
                  <Typography style={{ color: Colors.BURNING_ORANGE }}>
                    {formatMessage({ id: 'event.overlapping' })}
                  </Typography>
                  <Typography style={{ color: Colors.BURNING_ORANGE }}>
                    {(errors.renovations![index] as any).overlapping}
                  </Typography>
                </div>
              )}
              <div style={{ alignItems: 'baseline' }}>
                <TextDetailEditable
                  editMode={true}
                  title={formatMessage({
                    id: 'mortgage.totalCost',
                  })}
                  name={`renovations[${index}].totalCost`}
                  type="number"
                  min={0}
                  step={0.01}
                  error={Boolean(
                    errors.renovations &&
                      errors.renovations[index] &&
                      (errors.renovations[index] as FormikErrors<Event>).totalCost
                  )}
                  typeNumberNoEndArrow
                  endAdornment="€"
                />
                <TextDetailEditable
                  editMode={true}
                  title={formatMessage({
                    id: 'mortgage.note',
                  })}
                  name={`renovations[${index}].note`}
                  type="text"
                  text={isNil(values.renovations[index].note) ? '' : values.renovations[index].note}
                  style={{ marginLeft: 20 }}
                  rows={1}
                  rowsMax={4}
                  multiline
                  error={Boolean(
                    errors.renovations &&
                      errors.renovations[index] &&
                      (errors.renovations[index] as FormikErrors<Event>).note &&
                      touched.renovations &&
                      touched.renovations[index].note
                  )}
                />
              </div>
            </div>
          );
        })}
    </>
  );
};

export default RenovationCostsFields;
