import { Tooltip } from '@material-ui/core';
import Switch, { type SwitchProps } from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import { useState } from 'react';
import { ReactComponent as Off } from 'src/icons/off.svg';
import { ReactComponent as On } from 'src/icons/on.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(0),
  },
  switchBase: {
    width: '100%',
    height: '100%',
    padding: 0,
    '&$checked': {
      transform: 'translateX(0px)',
      '& + $track': {
        opacity: 0,
        border: 'none',
      },
      '&:hover': {
        backgroundColor: `${Colors.DODGER_BLUE}10`,
      },
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 25,
    opacity: 0,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}));

const stopPropagation = (e: React.MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
};

interface CustomizedSwitchProps extends SwitchProps {
  switchOnText?: string;
  switchOffText?: string;
  disbaledClasses?: string;
  dataTest?: string;
  handleChange?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: React.ChangeEvent<any>): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <T = string | React.ChangeEvent<any>>(
      field: T
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): T extends React.ChangeEvent<any>
      ? void
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: string | React.ChangeEvent<any>) => void;
  };
}

export const CustomizedSwitch: React.FC<CustomizedSwitchProps> = ({
  switchOnText,
  switchOffText,
  disbaledClasses,
  handleChange,
  dataTest,
  ...rest
}) => {
  const classes = useStyles();
  const { checked } = rest;
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const closeTooltip = () => {
    if (showTooltip) setShowTooltip(false);
  };

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      onClose={closeTooltip}
      open={showTooltip}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={!isNil(switchOnText) && checked ? switchOnText : !isNil(switchOffText) && !checked ? switchOffText : ''}
    >
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        checkedIcon={<On />}
        icon={<Off />}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
          ...(isNil(disbaledClasses) ? {} : { disabled: disbaledClasses }),
        }}
        data-test={dataTest}
        onMouseDown={stopPropagation}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={closeTooltip}
        onChange={handleChange}
        {...rest}
      />
    </Tooltip>
  );
};
