const loadingLegends: string[] = [
  'Reticulating splines...',
  'Generating witty dialog...',
  'Swapping time and space...',
  'Bending the spoon...',
  'Filtering morale...',
  "Don't think of purple hippos...",
  'We need a new fuse...',
  'Have a good day.',
  'The architects are still drafting',
  "We're building the buildings as fast as we can",
  'Would you prefer chicken, steak, or tofu?',
  '...and enjoy the elevator music...',
  'Please wait while the little elves draw your map',
  'Checking the gravitational constant in your locale...',
  'Go ahead -- hold your breath!',
  "...at least you're not on hold...",
  'The server is powered by a lemon and two electrodes.',
  'Follow the white rabbit',
  "Why don't you order a sandwich?",
  'While the satellite moves into position',
  "Dig on the 'X' for buried treasure... ARRR!",
  'I should have had a V8 this morning.',
  "Testing on Timmy... We're going to need another Timmy.",
  'Just count to 10',
  "It's not you. It's me.",
  'Counting backwards from Infinity',
  "Don't panic...",
  'Embiggening Prototypes',
  'Do you come here often?',
  "Warning: Don't set yourself on fire.",
  "We're making you a cookie.",
  'Creating time-loop inversion field',
  'Spinning the wheel of fortune...',
  'Loading the enchanted bunny...',
  'Computing chance of success',
  "I'm sorry Dave, I can't do that.",
  'I feel like im supposed to be loading something. . .',
  'Is this Windows?',
  'Adjusting flux capacitor...',
  "Don't break your screen yet!",
  "I swear it's almost done.",
  "Let's take a mindfulness minute...",
  'Unicorns are at the end of this road, I promise.',
  'Listening for the sound of one hand clapping...',
  'Cleaning off the cobwebs...',
  "Making sure all the i's have dots...",
  'Connecting Neurotoxin Storage Tank...',
  'Granting wishes...',
  'Time flies when you’re having fun.',
  'Spinning the hamster…',
  '99 bottles of beer on the wall..',
  'Stay awhile and listen..',
  'You shall not pass! yet..',
  'Load it and they will come',
  'Convincing AI not to turn evil..',
  'There is no spoon. Because we are not done loading it',
  'Your left thumb points to the right and your right thumb points to the left.',
  'Computing the secret to life, the universe, and everything.',
  'When nothing is going right, go left!!...',
  "I love my job only when I'm on vacation...",
  "i'm not lazy, I'm just relaxed!!",
  'Never steal. The government hates competition....',
  'Why are they called apartments if they are all stuck together?',
  'Life is Short – Talk Fast!!!!',
  'Save water and shower together',
  'Whenever I find the key to success, someone changes the lock.',
  'I’ve got problem for your solution…..',
  'Where there’s a will, there’s a relative.',
  'I think I am, therefore, I am. I think.',
  'A kiss is like a fight, with mouths.',
  'Coffee, Chocolate, Men. The richer the better!',
  'May the forks be with you',
  'Constructing additional pylons...',
  'Roping some seaturtles...',
  'Locating Jebediah Kerman...',
  'Hello IT, have you tried turning it off and on again?',
  'If you type Google into Google you can break the internet',
  "They're fairly regular, the beatings, yes. I'd say we're on a bi-weekly beating.",
  'The Elders of the Internet would never stand for it.',
  'Space is invisible mind dust, and stars are but wishes.',
  "Didn't know paint dried so quickly.",
  'Everything sounds the same',
  "I'm going to walk the dog",
  "I didn't choose the engineering life. The engineering life chose me.",
  'Dividing by zero...',
  'Spawn more Overlord!',
  'If I’m not back in five minutes, just wait longer.',
  'Some days, you just can’t get rid of a bug!',
  'We’re going to need a bigger boat.',
  'Chuck Norris never git push. The repo pulls before.',
  'Web developers do it with <style>',
  'I need to git pull --my-life-together',
  'Making military-grade encryption...',
  'Simulating traveling salesman...',
  'Entangling superstrings...',
  'Twiddling thumbs...',
  'Searching for plot device...',
  'Trying to sort in O(n)...',
  'Looking for sense of humour, please hold on.',
  'Please wait while the intern refills his coffee.',
  'Please hold on as we reheat our coffee',
  'Kindly hold on as our intern quits vim...',
  'Winter is coming...',
  'Installing dependencies',
  'Switching to the latest JS framework...',
  'Distracted by cat gifs',
  'Finding someone to hold my beer',
  'BRB, working on my side project',
  "Let's hope it's worth the wait",
  'Aw, snap! Not..',
  'Ordering 1s and 0s...',
  'Updating dependencies...',
  'Please wait... Consulting the manual...',
  "It is dark. You're likely to be eaten by a grue.",
  'Loading funny message...',
  'Waiting Daenerys say all her titles...',
  'Feel free to spin in your chair',
  "What's under there?",
  'Go ahead, hold your breath and do an ironman plank till loading complete',
  "Help, I'm trapped in a loader!",
  'Please wait, while we purge the Decepticons for you. Yes, You can thanks us later!',
  'Chuck Norris doesn’t wear a watch. HE decides what time it is.',
  'Initializing the initializer...',
  'When was the last time you dusted around here?',
  'Optimizing the optimizer...',
  'Last call for the data bus! All aboard!',
  'Running swag sticker detection...',
  'When nothing is going right, go left!',
  "Never let a computer know you're in a hurry.",
  "Some things man was never meant to know. For everything else, there's Google.",
  "Unix is user-friendly. It's just very selective about who its friends are.",
  'Pushing pixels...',
  'Building a wall...',
  'Everything in this universe is either a potato or not a potato',
  'The severity of your issue is always lower than you expected.',
  'Updating Updater...',
  'Downloading Downloader...',
  'Debugging Debugger...',
  'Reading Terms and Conditions for you.',
  'Digested cookies being baked again.',
  'Live long and prosper.',
  "There is no cow level, but there's a goat one!",
  'You may call me Steve.',
  'You seem like a nice person...',
  "Coffee at my place, tomorrow at 10A.M. - don't be late!",
  'Work, work...',
  'Patience! This is difficult, you know...',
  'Time flies like an arrow; fruit flies like a banana',
  'Two men walked into a bar; the third ducked...',
  'Sooooo... Have you seen my vacation photos yet?',
  "Sorry we are busy catching em' all, we're done soon",
  'TODO: Insert elevator music',
  'Still faster than Windows update',
  'Loading... Making sure the house has a front door!',
  'Almost there... Just laying the last brick!',
  'Loading... Making sure the house has a roof!',
  'Loading... Convincing the neighbors to lower their prices!',
  'Hold on... Evicting the ghosts from the attic!',
  'Getting the virtual tour ready... Hope the owner’s cat cooperates!',
  'Loading... Making sure the backyard didn’t move overnight!',
  'Lighting up the place... Because dark houses aren’t great for rents!',
  'Loading... Negotiating with the door to open on command!',
];

export default loadingLegends;
