import { Divider, Grid, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import React from 'react';
import { calculationStyles } from '../../../leasePriceHistoryStyles';
import { ReactComponent as MultiplyIcon } from '../../../../../../icons/icon-multiply.svg';

interface CorrectionFactorFormulaProps {
  firstOperand: number | string;
  secondOperand?: number | string;
  divider: number | string;
  multiplier?: number | string;
  secondMultiplier?: number | string;
}

const CorrectionFactorFormula: React.FC<CorrectionFactorFormulaProps> = ({
  firstOperand,
  secondOperand,
  multiplier,
  secondMultiplier,
  divider,
}) => {
  const styles = calculationStyles();

  return (
    <Grid style={{ textAlign: 'center', width: '100%' }}>
      <Grid className={styles.formulaNumeratorGrid}>
        {multiplier ? (
          secondMultiplier ? (
            <>
              <Typography className={styles.calculationText}>{multiplier}</Typography>
              <MultiplyIcon style={{ width: 32 }} />
              <Typography className={styles.calculationText}>{firstOperand}</Typography>
              <MultiplyIcon style={{ width: 14, rotate: '45deg', marginLeft: 4, marginRight: 4 }} />
              <Typography className={styles.calculationText}>{secondMultiplier}</Typography>
              <MultiplyIcon style={{ width: 32 }} />
              <Typography className={styles.calculationText}>{secondOperand}</Typography>
            </>
          ) : (
            <>
              <Typography className={styles.calculationText}>{multiplier}</Typography>
              <MultiplyIcon style={{ width: 32 }} />
              <Typography className={styles.calculationText}>(</Typography>
              <Typography className={styles.calculationText}>{firstOperand}</Typography>
              <MultiplyIcon style={{ width: 14, rotate: '45deg', marginLeft: 4, marginRight: 4 }} />
              <Typography className={styles.calculationText}>{secondOperand}</Typography>
              <Typography className={styles.calculationText}>)</Typography>
            </>
          )
        ) : (
          <Typography className={styles.calculationText}>{firstOperand}</Typography>
        )}
      </Grid>
      <Divider style={{ color: Colors.SEPARATOR_GREY }} />
      <Grid className={styles.formulaDenominatorGrid}>
        <Typography className={styles.calculationText}>{divider}</Typography>
      </Grid>
    </Grid>
  );
};

export default CorrectionFactorFormula;
