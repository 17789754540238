import { Divider, Grid, Typography } from '@material-ui/core';
import {
  CommunicationChannel,
  CommunicationSettingsProfile,
  CommunicationSettingsProfileReferent,
} from '@rentguru/commons-utils';
import { useIntl } from 'react-intl';
import { OptionalSettings } from '../CommunicationSettingsProfileUtils/CommunicationSettingsCommonsUtils';
import { LeaseSettableCommunicationType } from '../CommunicationSettingsProfileUtils/LeaseCommunicationSettingsUtils';
import CommunicationSettingsFormItem from './CommunicationSettingsForm';
interface LeaseCommunicationSettingsFormProps {
  defaultCollapse?: boolean;
  previewProfile?: CommunicationSettingsProfile;
}

const COMMUNICATION_TYPES_GROUP = [
  {
    id: 'rentPaymentRequests',
    communicationTypesOptions: [
      {
        communicationType: LeaseSettableCommunicationType.LEASE_PAYMENT_REQUEST,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
        optionalSettings: [OptionalSettings.DAYS_BEFORE_DUE_DATE],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_CREDIT_NOTE,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_OPERATION_PAYMENT_REQUEST,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_PAYMENT_RECEIPT,
        possibleCommunicationChannels: [CommunicationChannel.EMAIL, CommunicationChannel.LETTER],
      },
    ],
  },
  {
    id: 'rentReminders',
    communicationTypesOptions: [
      {
        communicationType: LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_REMINDER,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
        optionalSettings: [OptionalSettings.DAYS_OVERDUE],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_SECOND_REMINDER,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
        optionalSettings: [OptionalSettings.DAYS_OVERDUE],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_UNPAID_AMOUNT_FORMAL_NOTICE,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.REGISTERED_LETTER,
        ],
        optionalSettings: [OptionalSettings.DAYS_OVERDUE],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_UNPAID_CONSOLIDATED_PAYMENT_REMINDER,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.REGISTERED_LETTER,
          CommunicationChannel.SMS,
        ],
      },
    ],
  },
  {
    id: 'statementsSending',
    communicationTypesOptions: [
      {
        communicationType: LeaseSettableCommunicationType.LEASE_TENANT_STATEMENT,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
      },
    ],
  },
  {
    id: 'contractTerminationExtension',
    communicationTypesOptions: [
      {
        communicationType: LeaseSettableCommunicationType.LEASE_CONTRACT_EXTENDED,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_CONTRACT_REJECTED,
        possibleCommunicationChannels: [CommunicationChannel.EMAIL, CommunicationChannel.LETTER],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_CONTRACT_RETRACTED,
        possibleCommunicationChannels: [CommunicationChannel.EMAIL, CommunicationChannel.LETTER],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_CONTRACT_REVOKED,
        possibleCommunicationChannels: [CommunicationChannel.EMAIL, CommunicationChannel.LETTER],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_TERMINATION,
        possibleCommunicationChannels: [CommunicationChannel.REGISTERED_LETTER],
      },
    ],
  },
  {
    id: 'rentalAdaptation',
    communicationTypesOptions: [
      {
        communicationType: LeaseSettableCommunicationType.LEASE_RENT_INDEXATION,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_RENT_INDEXATION_SKIPPED,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_RENTAL_ADAPTATION,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_CHARGES_ADJUSTMENT,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
      },
    ],
  },
  {
    id: 'leaseOperationMovements',
    communicationTypesOptions: [
      {
        communicationType: LeaseSettableCommunicationType.LEASE_DISCOUNT_CREATED,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_DISCOUNT_DELETED,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_DISCOUNT_UPDATED,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_OPERATION_CREATED,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_OPERATION_DELETED,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_OPERATION_UPDATED,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
      },
    ],
  },
  {
    id: 'leaseInfoUpdate',
    communicationTypesOptions: [
      {
        communicationType: LeaseSettableCommunicationType.LEASE_ALLOW_PROFESSIONAL_ACTIVITY_ON,
        possibleCommunicationChannels: [CommunicationChannel.EMAIL, CommunicationChannel.LETTER],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_BANKING_INFO_UPDATE,
        possibleCommunicationChannels: [CommunicationChannel.EMAIL, CommunicationChannel.LETTER],
      },
    ],
  },
  {
    id: 'documentSignatures',
    communicationTypesOptions: [
      {
        communicationType: LeaseSettableCommunicationType.DOCUMENT_SIGNATURE_REMINDER,
        possibleCommunicationChannels: [CommunicationChannel.EMAIL, CommunicationChannel.LETTER],
        optionalSettings: [OptionalSettings.DAYS_OVERDUE],
      },
      {
        communicationType: LeaseSettableCommunicationType.DOCUMENT_SIGNED,
        possibleCommunicationChannels: [CommunicationChannel.EMAIL, CommunicationChannel.LETTER],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_CONTRACT_SIGNATURE_REMINDER,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
        optionalSettings: [OptionalSettings.DAYS_OVERDUE],
      },
      {
        communicationType: LeaseSettableCommunicationType.LEASE_CONTRACT_SIGNED,
        possibleCommunicationChannels: [
          CommunicationChannel.EMAIL,
          CommunicationChannel.LETTER,
          CommunicationChannel.SMS,
        ],
      },
    ],
  },
];

const numberOfGroups = COMMUNICATION_TYPES_GROUP.length;

const LeaseCommunicationSettingsForm: React.FC<LeaseCommunicationSettingsFormProps> = ({
  defaultCollapse = false,
  previewProfile = null,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Grid>
      {COMMUNICATION_TYPES_GROUP.map((communicationTypesGroup, groupIndex) => {
        const isLastGroup = groupIndex === numberOfGroups - 1;
        return (
          <Grid key={communicationTypesGroup.id}>
            <Typography
              style={{ textAlign: 'start', fontWeight: 'bold', fontSize: 20, marginTop: 40, marginBottom: 20 }}
            >
              {formatMessage({ id: `settings.communications.communicationTypesGroups.${communicationTypesGroup.id}` })}
            </Typography>
            {communicationTypesGroup.communicationTypesOptions.map((communicationTypeOptions, typeIndex) => {
              const isLastType = typeIndex === communicationTypesGroup.communicationTypesOptions.length - 1;
              return (
                <Grid key={communicationTypeOptions.communicationType}>
                  <CommunicationSettingsFormItem
                    communicationType={communicationTypeOptions.communicationType}
                    possibleCommunicationChannels={communicationTypeOptions.possibleCommunicationChannels}
                    optionalSettings={communicationTypeOptions.optionalSettings}
                    referent={CommunicationSettingsProfileReferent.LEASE}
                    defaultCollapse={defaultCollapse}
                    previewProfile={previewProfile}
                  />
                  {!(isLastGroup && isLastType) && <Divider style={{ marginTop: 20, marginBottom: 20 }} />}
                </Grid>
              );
            })}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default LeaseCommunicationSettingsForm;
