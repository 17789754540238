/* eslint-disable no-console */
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Colors, CustomLabel, Language } from '@rentguru/commons-utils';
import { ActionButtonSecondProps } from '@up2rent/ui';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { EditAdvertisementFormValues } from 'src/components/RentalUnits/Details/Publication/EditPublication/EditPublicationForm';
import TextFieldArea, { TextFieldAreaProps } from 'src/components/ui/TextFieldArea';
import { TranslateDialogBundle } from './TranslateTextDetailEditable';
import TranslateWrapper from './TranslateWrapper';

const useLocalStyles = makeStyles({
  containerTabs: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  h6: {
    flex: 1,
    textAlign: 'left' as const,
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Muli',
    fontSize: '16px',
    fontWeight: 700,
  },
  textField: {
    backgroundColor: Colors.PORCELAIN_GREY_3,
    fontSize: 14,
    fontWeight: 600,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottom: `2px solid ${Colors.TOWER_GREY}`,
    marginBottom: 15,
    color: Colors.BLUE_GREY,
  },
});

interface TranslateTexFieldProps {
  textsToTranslate: TranslateDialogBundle | null;
  languageSelected: Language;
  onLanguageChange: (language: Language) => void;
  onDialogClose: () => void;
  afterSubmit?: (newTexts: CustomLabel[]) => void;
  actionButtonProps?: ActionButtonSecondProps;
  textFieldProps?: TextFieldAreaProps;
}

const TranslateTextField: React.FC<TranslateTexFieldProps> = ({
  textsToTranslate,
  languageSelected,
  onDialogClose,
  onLanguageChange,
  actionButtonProps,
  textFieldProps,
}) => {
  const localClasses = useLocalStyles();
  const { formatMessage } = useIntl();
  const { errors } = useFormikContext<EditAdvertisementFormValues>();

  const { className: textFieldClassName, ...restOfTextFieldProps } = textFieldProps ?? { className: undefined };

  return (
    <TranslateWrapper
      textsToTranslate={textsToTranslate}
      languageSelected={languageSelected}
      onLanguageChange={onLanguageChange}
      actionButtonProps={actionButtonProps}
      translateDialogProps={{
        onDialogClose: onDialogClose,
        titleText: formatMessage({ id: 'rentalUnit.editUnit.advertisement.descriptions' }, { value: 1 }),
        helperText: formatMessage({ id: 'rentalUnit.editUnit.advertisement.theDescription' }),
        warningText: formatMessage({ id: 'rentalUnit.editUnit.advertisement.ofTheDescription' }),
      }}
    >
      <Grid item xs={12}>
        <TextFieldArea
          placeholder={`...`}
          rows={7}
          rowsMax={7}
          inputProps={{ style: { textAlign: 'left', padding: 15 } }}
          className={clsx(localClasses.textField, textFieldClassName)}
          onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
          {...restOfTextFieldProps}
        />

        {errors.advertisement?.descriptions && (
          <Typography style={{ color: Colors.BURNING_ORANGE }}>
            {formatMessage({ id: 'accounting.accountLabel.translationsMissings' })}
          </Typography>
        )}
      </Grid>
    </TranslateWrapper>
  );
};

export default TranslateTextField;
