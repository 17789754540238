import { UnitType } from '@rentguru/commons-utils';
import { CSSProperties } from 'react';
import {
  UnitTypeCommercialIcon,
  UnitTypeOfficeIcon,
  UnitTypeParkingIcon,
  UnitTypeResidentialIcon,
  UnitTypeStudentIcon,
  UnitTypeWarehouseIcon,
} from '../../icons';

export const UnitTypeIcon = ({
  unitType,
  style = { width: 24, height: 24 },
}: {
  unitType: UnitType;
  style?: CSSProperties;
}) => {
  switch (unitType) {
    case UnitType.RESIDENTIAL:
      return <UnitTypeResidentialIcon style={style} />;
    case UnitType.STUDENT:
      return <UnitTypeStudentIcon style={style} />;
    case UnitType.COMMERCIAL:
      return <UnitTypeCommercialIcon style={style} />;
    case UnitType.OFFICE:
      return <UnitTypeOfficeIcon style={style} />;
    case UnitType.PARKING:
      return <UnitTypeParkingIcon style={style} />;
    case UnitType.WAREHOUSE:
      return <UnitTypeWarehouseIcon style={style} />;
    case UnitType.OTHER:
      return null;
    default:
      return <UnitTypeResidentialIcon style={style} />;
  }
};
