import { INDEXATION_BOX_KEY, LeaseAmountUpdateStatus, LeasePriceHistory } from '@rentguru/commons-utils';
import { compareAsc } from 'date-fns';
import { isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLeases } from 'src/hooks/LeasesContext';
import { useLeasePriceHistories } from '../../../hooks/LeasePriceHistoriesContext';
import LeaseIndexationHeader from './BoxCustomHeaders/LeaseIndexationHeader';
import DashboardLeaseIndexationsRow from './BoxCustomRows/LeaseIndexationRow';
import NotificationBox from './NotificationBox';
import SlicedContent from './SlicedContent';
import TodosLoading from './TodosLoading';

export const ROW_HEIGHT = 54;

const indexationSortFunction = (indexation1: LeasePriceHistory, indexation2: LeasePriceHistory): number => {
  if (isNil(indexation1.applicationDate)) return 1;
  if (isNil(indexation2.applicationDate)) return -1;
  return compareAsc(new Date(indexation1.applicationDate), new Date(indexation2.applicationDate));
};

const DashboardLeaseIndexations: React.FC = () => {
  const { formatMessage } = useIntl();
  // Otherwise, it will start by saying that the amount of leases to index is zero.
  const { leasesLoading } = useLeases();
  const { loading: leaseIndexationLoading, leasePriceHistories } = useLeasePriceHistories();

  const loading = leasesLoading || leaseIndexationLoading;

  if (loading) {
    return (
      <>
        <TodosLoading />
      </>
    );
  }
  const leaseIndexationsContent = (leasePriceHistories ?? [])
    .filter(
      (leaseIndexation) =>
        (leaseIndexation.status as LeaseAmountUpdateStatus) === LeaseAmountUpdateStatus.DRAFT ||
        (leaseIndexation.status as LeaseAmountUpdateStatus) === LeaseAmountUpdateStatus.ERROR
    )
    .sort(indexationSortFunction)
    .map((leaseIndexation: LeasePriceHistory) => {
      return <DashboardLeaseIndexationsRow key={leaseIndexation.id} leasePriceHistory={leaseIndexation} />;
    }, []);

  return (
    <NotificationBox
      boxKey={INDEXATION_BOX_KEY}
      title={`${formatMessage({ id: 'dashboard.leasesToIndex' })} (${leaseIndexationsContent.length})`}
    >
      <SlicedContent
        header={<LeaseIndexationHeader />}
        data={leaseIndexationsContent}
        limit={3}
        rowHeight={ROW_HEIGHT}
        boxKey={INDEXATION_BOX_KEY}
      />
    </NotificationBox>
  );
};

export default DashboardLeaseIndexations;
