import { Typography } from '@material-ui/core';
import { getTenantsName } from '@rentguru/commons-utils';
import {
  ActionButton,
  EnhancedTableScrollable,
  EnhancedTableSharedContextProvider,
  TablePlaceHolder,
} from '@up2rent/ui';
import { isEmpty } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import { useFieldFilterReset } from 'src/components/ui/FieldFilterSelector';
import { FilterEntity, useFilters } from 'src/hooks/FiltersContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import { ReactComponent as NoResultIcon } from 'src/icons/noResult.svg';
import { ReactComponent as Logo } from '../../../icons/unitPlaceholder.svg';
import { isConsolidatedPaymentRequestReportActivated, LeasesTableProps } from '../Leases';
import LeaseActiveRow from './LeaseActiveRow';
import { filterLeasesArchivedByFilters, isOneLeaseArchivedFilterSelected } from './leaseFiltersUtils';
import LeasesSelected from './LeasesSelected';
import { getSortableLeaseName } from './leaseTableUtils';

const CHECKBOX_COLUMN = {
  id: 'checkbox',
  checkbox: true,
  labelId: '',
  numeric: false,
  disablePadding: false,
  sortable: false,
};

const COLUMNS = [
  {
    id: 'name',
    labelId: 'rentalUnit.columnHeader.name',
    numeric: false,
    disablePadding: false,
    sortable: true,
    displayFunction: getSortableLeaseName,
    style: { paddingLeft: 30, minWidth: 150 },
  },
  {
    id: 'units',
    labelId: 'lease.addLease.mainUnit',
    numeric: false,
    disablePadding: false,
    sortable: false,
    style: { minWidth: 150, paddingLeft: 0 },
  },
  {
    id: 'endDate',
    labelId: 'financial.period',
    numeric: false,
    disablePadding: true,
    sortable: true,
    style: { minWidth: 250 },
  },
  {
    id: 'type',
    labelId: 'rentalUnit.columnHeader.type',
    numeric: false,
    disablePadding: false,
    sortable: true,
    style: { minWidth: 100, paddingLeft: 0 },
  },
  {
    id: 'tenants',
    labelId: 'lease.detail.general.tenants',
    numeric: false,
    disablePadding: false,
    sortable: true,
    displayFunction: getTenantsName,
    style: { minWidth: 100, paddingLeft: 0 },
  },
  {
    id: 'balance',
    labelId: 'rentalUnit.columnHeader.balance',
    numeric: false,
    disablePadding: true,
    sortable: true,
    style: { minWidth: 100 },
  },
];

const LeasesTableArchived: React.FC<LeasesTableProps> = ({ loading, leasesArchived: leases }) => {
  const { formatMessage } = useIntl();
  const { leasesDetailsRead, buildingsUnitsDetailsWrite, leasesCreationWrite } = usePermissions();
  const { units } = useUnits();

  const { leasesArchivedFilters, resetFilters } = useFilters();
  const { executeResetFilters } = useFieldFilterReset();

  const history = useHistory();
  const match = useRouteMatch<{ tabName?: string; id?: string }>({
    path: `${RouteDestination.LEASES}/:tabName?/:id?`,
  });

  if (!match) {
    return null;
  }

  const handleDetail = (leaseId: string) => {
    if (!leasesDetailsRead) {
      return;
    }
    history.push({
      pathname: `${RouteDestination.LEASES}/detail/${leaseId}`,
      state: { goBackUrl: `${RouteDestination.LEASES}/archived` },
    });
  };

  // Filtered list based on menu
  const isOneFilterSelected = isOneLeaseArchivedFilterSelected(leasesArchivedFilters);
  const filteredLeases = filterLeasesArchivedByFilters(leases, leasesArchivedFilters);

  const canCreateLease = units && !isEmpty(units) && leasesCreationWrite;
  const noUnitAndCanCreateUnit = units && !isEmpty(units) && buildingsUnitsDetailsWrite;
  const placeHolderSubText = canCreateLease
    ? formatMessage({ id: 'lease.detail.placeholderSubtext' })
    : noUnitAndCanCreateUnit
    ? formatMessage({ id: 'building.placeholder.subText' })
    : '';
  const placeHolderActionDestination = canCreateLease ? RouteDestination.ADD_LEASE : RouteDestination.ADD_UNIT;
  const placeHolderActionLabelId = canCreateLease ? 'lease.addLease.action' : 'rentalUnit.addUnit.action';

  const shouldShowCheckbox = isConsolidatedPaymentRequestReportActivated(filteredLeases);
  const columnsWithConfig = shouldShowCheckbox ? [CHECKBOX_COLUMN, ...COLUMNS] : COLUMNS;

  return (
    <EnhancedTableSharedContextProvider value={{ orderBy: 'name' }} datas={filteredLeases} handleDetail={handleDetail}>
      {loading && <Typography>{formatMessage({ id: 'loading' })}</Typography>}
      {!loading && (
        <EnhancedTableScrollable
          NoResultIcon={<NoResultIcon />}
          formatMessage={formatMessage}
          datas={filteredLeases}
          columns={columnsWithConfig}
          defaultOrderBy="name"
          rowComponent={LeaseActiveRow}
          placeHolder={
            <TablePlaceHolder
              Icon={<Logo width={72} />}
              mainText={formatMessage({ id: 'lease.detail.placeholderNoArchived' })}
              subText={placeHolderSubText}
              actionButton={
                canCreateLease || noUnitAndCanCreateUnit ? (
                  <ActionButton component={Link} to={placeHolderActionDestination}>
                    {formatMessage({ id: placeHolderActionLabelId })}
                  </ActionButton>
                ) : undefined
              }
            />
          }
          filtersSelected={isOneFilterSelected}
          resetFiltersFunction={() => {
            executeResetFilters();
            resetFilters(FilterEntity.LEASES_ARCHIVED);
          }}
          SelectedComponent={LeasesSelected}
        />
      )}
    </EnhancedTableSharedContextProvider>
  );
};

export default LeasesTableArchived;
