import { CustomSimpleDialog, DeleteCheckField } from '@up2rent/ui';
import { useState } from 'react';
import { useIntl } from 'react-intl';

interface AccountDeletionConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onActionButtonClick: () => void;
}

const AccountDeletionConfirmDialog: React.FC<AccountDeletionConfirmDialogProps> = ({
  open,
  onClose,
  onActionButtonClick,
}) => {
  const { formatMessage } = useIntl();
  const deleteLabel = formatMessage({ id: 'delete' }).toLowerCase();
  const [isDeleteLabelTyped, setIsDeleteLabelTyped] = useState<boolean>(false);

  return (
    <CustomSimpleDialog
      open={open}
      onClose={onClose}
      formatMessage={formatMessage}
      onActionButtonClick={onActionButtonClick}
      actionButtonDisabled={!isDeleteLabelTyped}
      actionButtonLabel={formatMessage({ id: 'delete' })}
      contentGridStyle={{ paddingTop: 10 }}
    >
      <DeleteCheckField
        deleteLabel={deleteLabel}
        setIsDeleteLabelTyped={setIsDeleteLabelTyped}
        formatMessage={formatMessage}
      />
    </CustomSimpleDialog>
  );
};

export default AccountDeletionConfirmDialog;
