/* eslint-disable react/no-array-index-key */
import { Collapse, makeStyles, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { AgencyRateAmountDetail, Colors } from '@rentguru/commons-utils';
import { ActionButton, CustomIconButton, TextDetailEditable } from '@up2rent/ui';
import { FormikErrors, FormikProps, useFormikContext } from 'formik';
import { toUpper } from 'lodash';
import { useIntl } from 'react-intl';
import { ReactComponent as AddIcon } from 'src/icons/add.svg';
import { ReactComponent as DeleteIcon } from 'src/icons/delete.svg';
import { AddEditAgencyRateValues, emptyAmountDetail } from '../AddEditAgencyRateForm';
import AmountTypeToogleSwitch from './AmountTypeToogleSwitch';

const useStyles = makeStyles({
  addButton: {
    background: Colors.CLASSICAL_WHITE,
    color: Colors.DARK_SLATE_GREY,
    '&:hover': {
      background: Colors.PORCELAIN_GREY_2,
    },
    marginTop: 20,
  },
});

const AgencyRateAmountDetailsFields = () => {
  const { values, errors, touched, setFieldValue }: FormikProps<AddEditAgencyRateValues> = useFormikContext();
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const deleteRow = (deleteIndex: number) => {
    const newSteps = values.amountDetails.filter((_, index) => index !== deleteIndex);
    setFieldValue('amountDetails', newSteps);
  };

  const addRow = () => {
    const newSteps = [...values.amountDetails, emptyAmountDetail];
    setFieldValue('amountDetails', newSteps);
  };

  return (
    <>
      <Typography
        style={{
          fontSize: 14,
          fontWeight: 'bold',
          fontStyle: 'normal',
          letterSpacing: 0,
          color: Colors.CLASSICAL_BLACK,
        }}
      >
        {formatMessage({ id: 'agencyRates.detail.amount' }, { value: values.amountDetails.length })}
      </Typography>

      {values.amountDetails.map((_, index) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: 0,
                textAlign: 'center',
                color: Colors.SLATE_GREY,
              }}
            >
              {formatMessage({ id: `agencyRates.detail.amountBetween` })}
            </Typography>
            <TextDetailEditable
              title=""
              editMode={true}
              name={`amountDetails[${index}].startAmount`}
              type="number"
              style={{ width: 100, maxWidth: 'none', marginLeft: 10, marginRight: 10 }}
              error={Boolean(
                errors.amountDetails &&
                  errors.amountDetails[index] &&
                  (errors.amountDetails[index] as FormikErrors<AgencyRateAmountDetail>).startAmount &&
                  touched.amountDetails &&
                  touched.amountDetails[index] &&
                  touched.amountDetails[index].startAmount
              )}
              disabled={index === 0}
              endAdornment="€"
              typeNumberNoEndArrow={true}
            />
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: 0,
                textAlign: 'center',
                color: Colors.SLATE_GREY,
              }}
            >
              {formatMessage({ id: `agencyRates.detail.amountAnd` })}
            </Typography>
            <TextDetailEditable
              title=""
              editMode={true}
              name={`amountDetails[${index}].endAmount`}
              type="number"
              style={{ width: 100, maxWidth: 'none', marginLeft: 10, marginRight: 10 }}
              error={Boolean(
                errors.amountDetails &&
                  errors.amountDetails[index] &&
                  (errors.amountDetails[index] as FormikErrors<AgencyRateAmountDetail>).endAmount &&
                  touched.amountDetails
              )}
              endAdornment="€"
              typeNumberNoEndArrow={true}
              disabled={index === values.amountDetails.length - 1}
            />
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: 0,
                textAlign: 'center',
                color: Colors.SLATE_GREY,
              }}
            >
              {formatMessage({ id: `agencyRates.detail.amountFee` })}
            </Typography>
            <TextDetailEditable
              title=""
              editMode={true}
              name={`amountDetails[${index}].feeApplied`}
              type="number"
              style={{ width: 60, maxWidth: 'none', marginLeft: 10, marginRight: 10 }}
              error={Boolean(
                errors.amountDetails &&
                  errors.amountDetails[index] &&
                  (errors.amountDetails[index] as FormikErrors<AgencyRateAmountDetail>).feeApplied &&
                  touched.amountDetails &&
                  touched.amountDetails[index] &&
                  touched.amountDetails[index].feeApplied
              )}
              typeNumberNoEndArrow={true}
              inputProps={{ min: 0, style: { textAlign: 'center' } }}
            />
            <AmountTypeToogleSwitch index={index} />
            {index !== 0 && (
              <CustomIconButton
                style={{ marginLeft: 10, fill: Colors.BLUE_GREY }}
                size="small"
                onClick={() => deleteRow(index)}
                Icon={DeleteIcon}
              />
            )}
          </div>
        );
      })}

      <Collapse in={Boolean(errors.amountDetails && touched.amountDetails)}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Warning style={{ color: Colors.SUN_YELLOW, marginRight: 10 }} />
          <Typography style={{ color: Colors.SUN_YELLOW }}>
            {formatMessage({ id: 'agencyRates.detail.amountWarning' })}
          </Typography>
        </div>
      </Collapse>

      <ActionButton onClick={() => addRow()} className={classes.addButton}>
        <AddIcon style={{ marginRight: 5 }} />
        <Typography
          style={{
            fontSize: 11,
            fontWeight: 'bold',
            fontStyle: 'normal',
            letterSpacing: 1.1,
            color: Colors.DARK_SLATE_GREY,
          }}
        >
          {toUpper(formatMessage({ id: 'agencyRates.detail.amountAdd' }))}
        </Typography>
      </ActionButton>
    </>
  );
};

export default AgencyRateAmountDetailsFields;
