import { Divider } from '@material-ui/core';
import { Colors, MINIMAL_RIGHTS, UserRole } from '@rentguru/commons-utils';
import { ActionButton, ContentHeader, LoaderButton, TextDetailEditable } from '@up2rent/ui';
import { Form, Formik, FormikHelpers } from 'formik';
import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import React from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { useUserRoles } from '../../../hooks/UserRolesContext';

interface AddUserRoleFormValues {
  name: string;
}

const AddUserRoleSchema = Yup.object()
  .shape({
    name: Yup.string().required(),
  })
  .required();

interface AddUserRoleFormProps {
  onClose?: (userRole?: UserRole) => void;
}

const initialValues: AddUserRoleFormValues = {
  name: '',
};

const AddUserRoleForm: React.FC<AddUserRoleFormProps> = ({ onClose }) => {
  const { formatMessage } = useIntl();
  const { createUserRole } = useUserRoles();

  const handleCreate = async (
    values: AddUserRoleFormValues,
    { setSubmitting, setStatus }: FormikHelpers<AddUserRoleFormValues>
  ) => {
    const userRole = await createUserRole({
      ...values,
      userRights: MINIMAL_RIGHTS,
    });

    setStatus(true);
    setSubmitting(false);
    if (!isNil(onClose)) {
      onClose(userRole);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddUserRoleSchema}
      onSubmit={handleCreate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ errors, touched, status, isSubmitting }) => (
        <Form>
          <ContentHeader title={formatMessage({ id: 'settings.addProfile.menu' })} />
          <div style={{ marginLeft: 30, marginRight: 30 }}>
            <div style={{ alignItems: 'baseline' }}>
              <TextDetailEditable
                title={formatMessage({ id: 'settings.addProfile.name' })}
                editMode={true}
                name="name"
                type="text"
                style={{ width: 280, maxWidth: 'none' }}
                error={Boolean(errors.name && touched.name)}
              />
            </div>
          </div>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <div
            style={{
              marginRight: 30,
              marginBottom: 20,
              display: 'flex',
              float: 'right',
              alignItems: 'center',
            }}
          >
            <ActionButton
              onClick={() => {
                if (!isNil(onClose)) {
                  onClose();
                }
              }}
              style={{
                background: 'none',
                color: Colors.DARK_SLATE_GREY,
                marginRight: 20,
              }}
            >
              {toUpper(
                formatMessage({
                  id: 'cancel',
                })
              )}
            </ActionButton>
            <LoaderButton loading={isSubmitting} success={status}>
              {formatMessage({
                id: 'save',
              })}
            </LoaderButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddUserRoleForm;
