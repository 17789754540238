import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import {
  Colors,
  DEFAULT_LANGUAGE,
  IndexationOperationStepsType,
  IndexationType,
  OperandType,
  OperationParameters,
  OperationType,
  UnitLease,
  formatNumberToEuroCurrency,
  formatNumberToPercent,
  getIndexationOperationSteps,
} from '@rentguru/commons-utils';
import HelpIconPopover from 'src/components/ui/HelpIconPopover';
import { calculationStyles } from '../../leasePriceHistoryStyles';

import React from 'react';
import { useIntl } from 'react-intl';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import { useIndexationFormikUtils } from '../../UseIndexationUtils';

import { OperationFormula } from './Formulas/OperationFormula';

interface CalculationTableProps {
  indexationType: IndexationType;
  isRent: boolean;
  unitLease: UnitLease;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOperationCalculationDefinition = (operationType: OperationType, formatMessage: any) => {
  switch (operationType) {
    case OperationType.CORRECTION_FACTOR_FIRST_DEGREE:
      return (
        <OperationFormula
          operation={operationType}
          firstOperand={formatMessage({ id: 'lease.detail.indexationGeneral.twoYearsAgoIndex' })}
          secondOperand={formatMessage({ id: 'lease.detail.indexationGeneral.previousYearIndex' })}
          thirdOperand={formatMessage({ id: 'lease.detail.indexationGeneral.previousYearIndex' })}
        />
      );
    case OperationType.CORRECTION_FACTOR_SECOND_DEGREE:
      return (
        <OperationFormula
          operation={operationType}
          firstOperand={formatMessage({ id: 'lease.detail.indexationGeneral.twoYearsAgoIndex' })}
          secondOperand={formatMessage({ id: 'lease.detail.indexationGeneral.previousYearIndex' })}
        />
      );
    case OperationType.CORRECTION_FACTOR_WALLONIA_0:
      return (
        <OperationFormula
          operation={operationType}
          firstOperand={formatMessage({ id: 'lease.detail.indexationGeneral.index2022' })}
          secondOperand={formatMessage({ id: 'lease.detail.indexationGeneral.index2023' })}
        />
      );
    case OperationType.CORRECTION_FACTOR_WALLONIA_05:
      return (
        <OperationFormula
          operation={operationType}
          firstOperand={formatMessage({ id: 'lease.detail.indexationGeneral.index2022' })}
          secondOperand={formatMessage({ id: 'lease.detail.indexationGeneral.index2023' })}
          thirdOperand={formatMessage({ id: 'lease.detail.indexationGeneral.index2023' })}
        />
      );
    case OperationType.CORRECTION_FACTOR_WALLONIA_075:
      return (
        <OperationFormula
          operation={operationType}
          firstOperand={formatMessage({ id: 'lease.detail.indexationGeneral.index2022' })}
          secondOperand={formatMessage({ id: 'lease.detail.indexationGeneral.index2023' })}
          thirdOperand={formatMessage({ id: 'lease.detail.indexationGeneral.index2023' })}
        />
      );
    case OperationType.INDEXOPERATION:
      return (
        <OperationFormula
          operation={operationType}
          firstOperand={formatMessage({ id: 'lease.indexation.initialRentalPrice' })}
          secondOperand={formatMessage({ id: 'lease.detail.indexationGeneral.newIndex' })}
          thirdOperand={formatMessage({ id: 'lease.detail.indexationGeneral.startIndex' })}
        />
      );
    default:
      return null;
  }
};

const CalculationTable: React.FC<CalculationTableProps> = ({ indexationType, isRent, unitLease }) => {
  const { formatMessage } = useIntl();
  const { language = DEFAULT_LANGUAGE } = useLocale();
  const styles = calculationStyles();
  const { getLeasePriceHistoryDataFromFormikValues } = useIndexationFormikUtils();

  const { getAmountDetailFromFormikValues } = getLeasePriceHistoryDataFromFormikValues();

  const amountDetailFromFormikValues = getAmountDetailFromFormikValues(unitLease);
  if (!amountDetailFromFormikValues) {
    return null;
  }

  const steps: IndexationOperationStepsType[] = getIndexationOperationSteps(
    indexationType,
    isRent,
    unitLease,
    amountDetailFromFormikValues
  );
  const tabRows = steps?.map(
    (rowStep) => {
      const resultAmount =
        rowStep.resType === OperandType.CURRENCY
          ? formatNumberToEuroCurrency(rowStep.result, language)
          : rowStep.resType === OperandType.PERCENT
          ? formatNumberToPercent(rowStep.result, language)
          : rowStep.result;
      const operationParameters =
        rowStep.operationParameters.operation === OperationType.TEXT
          ? ({
              operation: OperationType.TEXT,
              textFormatted: formatMessage(
                {
                  id: rowStep.operationParameters.text!.id,
                },
                { epb: rowStep.operationParameters.text!.epb }
              ),
            } as OperationParameters)
          : rowStep.operationParameters;

      const helperText = getOperationCalculationDefinition(operationParameters.operation, formatMessage);

      return {
        label: formatMessage({ id: rowStep.label }),
        helperText,
        resultAmount,
        operationParameters,
      };
    },
    [] as {
      label: string;
      resultAmount: string;
      operationParameters: OperationParameters;
    }[]
  );

  return (
    <Grid className={styles.table}>
      <Table>
        <TableHead className={styles.header}>
          <TableRow>
            <TableCell style={{ borderRight: `1px solid ${Colors.PORCELAIN_GREY_3}` }}> </TableCell>
            <TableCell className={styles.headerText} style={{ borderRight: `1px solid ${Colors.PORCELAIN_GREY_3}` }}>
              {formatMessage({ id: 'lease.detail.indexationGeneral.calculation' })}
            </TableCell>
            <TableCell className={styles.headerText} style={{ textAlign: 'right' }}>
              {formatMessage({ id: 'lease.detail.indexationGeneral.result' })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tabRows.map((tabRow) => {
            return (
              <TableRow key={tabRow.label}>
                <TableCell
                  className={styles.descriptionText}
                  style={{ borderRight: `1px solid ${Colors.GEYSER_GREY}` }}
                >
                  <div style={{ alignItems: 'center', display: 'flex' }}>
                    {tabRow.label}
                    {tabRow.helperText && (
                      <HelpIconPopover HelperBody={tabRow.helperText} iconStyle={{ width: 15, marginLeft: 8 }} />
                    )}
                  </div>
                </TableCell>
                <TableCell style={{ borderRight: `1px solid ${Colors.GEYSER_GREY}` }}>
                  <OperationFormula
                    operation={tabRow.operationParameters.operation}
                    firstOperand={tabRow.operationParameters.firstOperand}
                    firstOperandType={tabRow.operationParameters.firstOperandType}
                    secondOperand={tabRow.operationParameters.secondOperand}
                    secondOperandType={tabRow.operationParameters.secondOperandType}
                    thirdOperand={tabRow.operationParameters.thirdOperand}
                    thirdOperandType={tabRow.operationParameters.thirdOperandType}
                    textFormatted={tabRow.operationParameters.textFormatted}
                  />
                </TableCell>
                <TableCell className={styles.resultText}>{tabRow.resultAmount}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default CalculationTable;
