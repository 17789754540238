/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-redeclare */
import {
  ACCOUNT_BANK_TOPCLASS,
  ACCOUNT_CONTRACTOR_TOPCLASS,
  ACCOUNT_LEASE_TOPCLASS,
  AccountLabel,
  BankAccount,
  cleanInputCreate,
  cleanInputUpdate,
  Contact,
  CreateAccountLabelInput,
  createAccountLabel as createAccountLabelMutation,
  CreateAccountLabelMutationVariables,
  CreatePostingInput,
  createPosting as createPostingMutation,
  CreateTransactionInput,
  createTransaction as createTransactionMutation,
  CreateTransactionMutationVariables,
  CreateTransactionRuleInput,
  createTransactionRule as createTransactionRuleMutation,
  CreateTransactionRuleMutationVariables,
  deleteAccountLabel as deleteAccountLabelMutation,
  deletePosting as deletePostingMutation,
  deleteTransaction as deleteTransactionMutation,
  deleteTransactionRule as deleteTransactionRuleMutation,
  getAccountLabel as getAccountLabelQuery,
  getContactNameFromObject,
  getPosting as getPostingQuery,
  getReadId,
  getTableClientId,
  getTransaction as getTransactionQuery,
  getTransactionRule as getTransactionRuleQuery,
  isIdInList,
  isNilOrEmpty,
  LooseObject,
  ModelWithVersion,
  Posting,
  syncAccountLabels,
  syncPostings,
  syncTransactionRules,
  Transaction,
  TransactionLink,
  TransactionLinkType,
  TransactionRule,
  TransactionStatus,
  uniquePush,
  UpdateAccountLabelInput,
  updateAccountLabel as updateAccountLabelMutation,
  UpdatePostingInput,
  updatePosting as updatePostingMutation,
  UpdateTransactionInput,
  updateTransaction as updateTransactionMutation,
  UpdateTransactionRuleInput,
  updateTransactionRule as updateTransactionRuleMutation,
} from '@rentguru/commons-utils';
import { deleteAndHideEntityWithFetchBefore, get, list, mutation } from '@up2rent/fetch-utils';
import { isEmpty, isNil, maxBy, toNumber } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TruncatedTransaction } from 'src/tenantHooks/TenantContext';
import { syncTransactions } from 'src/tenantHooks/graphqlHelper';
import { useBankAccounts } from './BankAccountsContext';
import { LeaseExtended, useLeases } from './LeasesContext';
import { useUser } from './UserContext';
import { usePermissions } from './utils/PermissionsContext';

export interface ExtendedTransaction extends Transaction {
  postings: Posting[];
}

export const fetchPostings = async (
  by:
    | 'byCharge'
    | 'byLease'
    | 'byTransaction'
    | 'byAccountLabel'
    | 'byInvoice'
    | 'byContact'
    | 'byBuilding'
    | 'byBankAccount'
    | 'byUnit'
    | 'byForeign',
  byValue: string,
  additionalFilter?: LooseObject,
  syncPostingQuery?: string
): Promise<Posting[]> => {
  let indexName = 'chargeId';
  // eslint-disable-next-line default-case
  switch (by) {
    case 'byLease':
      indexName = 'leaseId';
      break;
    case 'byTransaction':
      indexName = 'transactionId';
      break;
    case 'byAccountLabel':
      indexName = 'accountLabelId';
      break;
    case 'byInvoice':
      indexName = 'invoiceId';
      break;
    case 'byContact':
      indexName = 'contactId';
      break;
    case 'byBuilding':
      indexName = 'buildingId';
      break;
    case 'byBankAccount':
      indexName = 'bankAccountId';
      break;
    case 'byUnit':
      indexName = 'unitId';
      break;
    case 'byForeign':
      indexName = 'foreignId';
      break;
  }
  return await list<Posting>(syncPostingQuery ?? syncPostings, indexName, byValue, additionalFilter);
};

export const getPosting = async (id: string) => {
  const result = await get<Posting>(getPostingQuery, id);
  return result;
};

export const getTransaction = async (id: string) => {
  const result = await get<Transaction>(getTransactionQuery, id);
  return result;
};

export const extendTransactions = async (transactions: Transaction[]) => {
  return await Promise.all(
    transactions.map(async (transaction) => {
      return await extendTransaction(transaction);
    })
  );
};

export const fetchAllTransactionPostings = async (transactionId: string) => {
  const directLinkedPostingsPromise = fetchPostings('byTransaction', transactionId);
  const unDirectLinkedPostingsPromise = fetchPostings('byForeign', transactionId);
  return (await Promise.all([directLinkedPostingsPromise, unDirectLinkedPostingsPromise])).flat();
};

export const extendTransaction = async (transaction: Transaction) => {
  return { ...transaction, postings: await fetchPostings('byTransaction', transaction.id) };
};

const getLeaseIdsForTransaction = (transaction: Transaction): string[] => {
  if (!transaction.links) {
    return [];
  }
  return transaction.links.reduce((acc: string[], link: TransactionLink) => {
    const { linkType, linkId } = link;
    if (linkType === TransactionLinkType.LEASE) {
      uniquePush(acc, linkId);
    }
    return acc;
  }, []);
};

const filterTransactionsByRightsAndLeases = (
  transactions: Transaction[],
  loading: boolean | undefined,
  error: string | undefined,
  rootUser: boolean,
  financialTransactionsRead: boolean,
  leases: LeaseExtended[]
): Transaction[] => {
  if (loading || error) {
    return [];
  }
  if (rootUser) {
    return transactions;
  }

  return transactions.reduce((result, currentTransaction) => {
    if (isNilOrEmpty(currentTransaction.links) && financialTransactionsRead) {
      result.push(currentTransaction);
      return result;
    }

    const transactionLeaseIds = getLeaseIdsForTransaction(currentTransaction);
    const hasLeaseId = transactionLeaseIds.some((leaseId) => isIdInList({ id: leaseId } as { id: string }, leases));
    if (hasLeaseId) {
      result.push(currentTransaction);
    }

    return result;
  }, [] as Transaction[]);
};

export interface TransactionContext {
  transactions: Transaction[];
  transactionsHistory: Transaction[];
  transactionsToCheck: Transaction[];
  transactionsExcluded: Transaction[];
  transactionsRules: TransactionRule[];
  accountLabels: AccountLabel[];
  transactionRefetch: () => Promise<void>;
  createTransaction: (
    input: CreateTransactionInput | Omit<CreateTransactionInput, 'clientId' | 'readId'>
  ) => Promise<Transaction>;
  updateTransaction: (updates: UpdateTransactionInput) => Promise<Transaction>;
  deleteTransaction: (transaction: Transaction | Pick<Transaction, 'id'>) => Promise<Transaction | null>;
  createTransactionRule: (
    input: CreateTransactionRuleInput | Omit<CreateTransactionRuleInput, 'clientId' | 'readId'>
  ) => Promise<TransactionRule>;
  updateTransactionRule: (updates: UpdateTransactionRuleInput) => Promise<TransactionRule>;
  deleteTransactionRule: (transactionRule: TransactionRule) => Promise<TransactionRule | null>;
  getTransactionRule: (id: string) => TransactionRule | undefined;
  getAccountLabel: (id: string) => AccountLabel | undefined;
  getAccountLabels: () => AccountLabel[];
  createAccountLabel: (input: Omit<CreateAccountLabelInput, 'clientId' | 'readId'>) => Promise<AccountLabel>;
  updateAccountLabel: (updates: UpdateAccountLabelInput) => Promise<AccountLabel>;
  deleteAccountLabel: (accountLabel: AccountLabel) => Promise<AccountLabel>;
  createPosting: (input: CreatePostingInput | Omit<CreatePostingInput, 'clientId' | 'readId'>) => Promise<Posting>;
  updatePosting: (updates: UpdatePostingInput) => Promise<Posting>;
  deletePosting: (posting: Posting) => Promise<Posting | null>;
  getTransaction: (id: string) => Transaction | undefined;
  getTransactionsForLease: (leaseId: string, filterIgnoredTransaction?: boolean) => Transaction[];
  getSplittedTransactionsForLease: (leaseId: string) => Promise<Transaction[]>;
  getAccountLabelForBankAccountOrInsert: (bankAccount: BankAccount) => Promise<AccountLabel>;
  getAccountLabelForLease: () => AccountLabel;
  getAccountLabelForContactOrInsert: (contractor: Contact) => Promise<AccountLabel>;
  transactionsLoading: boolean | undefined;
  transactionsError: string | undefined;
}
const classifyTransactions = (
  transactions: Transaction[]
): Pick<TransactionContext, 'transactionsHistory' | 'transactionsExcluded' | 'transactionsToCheck'> => {
  const [transactionsToCheck, transactionsHistory, transactionsExcluded] = transactions.reduce(
    (acc: [Transaction[], Transaction[], Transaction[]], transaction) => {
      if (transaction.status === TransactionStatus.TO_RECONCILE) {
        acc[0].push(transaction);
      } else if (
        transaction.status === TransactionStatus.RECONCILED ||
        transaction.status === TransactionStatus.PARTIALLY_RECONCILED
      ) {
        acc[1].push(transaction);
      } else if (transaction.status === TransactionStatus.EXCLUDED) {
        acc[2].push(transaction);
      }

      return acc;
    },
    [[], [], []]
  );
  return { transactionsHistory, transactionsToCheck, transactionsExcluded };
};

export const TransactionContext = React.createContext<TransactionContext | null>(null);

export const TransactionContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { clientId, isOwner, userId, rootUser, isFetchingUser } = useUser();
  const { leases, leasesLoading } = useLeases();
  const { getBankAccount, loading: bankAccountLoading } = useBankAccounts();
  const { financialTransactionsDelete, financialTransactionsRead, loading: permissionsLoading } = usePermissions();
  const [transactions, setTransactions] = useState<Transaction[] | null>(null);
  const [transactionsRules, setTransactionsRules] = useState<TransactionRule[]>([]);
  const [transactionsLoading, setTransactionsLoading] = useState<boolean | undefined>(undefined);
  const [transactionsError, setTransactionsError] = useState<string | undefined>(undefined);
  const [accountLabels, setAccountLabels] = useState<AccountLabel[]>([]);
  const { transactionsHistory, transactionsToCheck, transactionsExcluded } = classifyTransactions(transactions ?? []);
  const loading = bankAccountLoading || isFetchingUser || permissionsLoading || leasesLoading;

  const fetchAccountLabels = async (): Promise<AccountLabel[]> => {
    return await list<AccountLabel>(syncAccountLabels, 'clientId', getTableClientId(clientId!, 'AccountLabel'));
  };

  const fetchTransactions = async (): Promise<Transaction[]> => {
    const truncatedTransactions = await list<TruncatedTransaction>(
      syncTransactions,
      'clientId',
      getTableClientId(clientId!, 'Transaction')
    );
    const transactions: Transaction[] = truncatedTransactions.map((truncatedTransaction: TruncatedTransaction) => {
      const { postings, ...rest } = truncatedTransaction;
      return { postings: postings.items.filter((posting) => posting._deleted !== true), ...rest };
    }, []);
    return transactions;
  };

  const fetchTransaction = async (id: string): Promise<Transaction> => await get<Transaction>(getTransactionQuery, id);

  const fetchTransactionsRules = async (): Promise<TransactionRule[]> => {
    return await list<TransactionRule>(
      syncTransactionRules,
      'clientId',
      getTableClientId(clientId!, 'TransactionRule')
    );
  };

  const fetchTransactionRule = async (id: string): Promise<TransactionRule> =>
    await get<TransactionRule>(getTransactionRuleQuery, id);

  const transactionRefetch = async () => {
    const transactionPromise = fetchTransactions();
    const transactionsRulesPromise = fetchTransactionsRules();
    const accountLabelsPromise = fetchAccountLabels();
    const [transactionResult, transactionRulesResult, accountLabelsResult] = await Promise.all([
      transactionPromise,
      transactionsRulesPromise,
      accountLabelsPromise,
    ]);
    // Filter the transaction so that teams only see their transactions.
    // Teams can see all the transactions to check but only the history of those linked to their lease
    const filteredTransaction = filterTransactionsByRightsAndLeases(
      transactionResult,
      transactionsLoading,
      transactionsError,
      rootUser,
      financialTransactionsRead,
      leases
    );

    const filteredTransactionRules = rootUser
      ? transactionRulesResult
      : transactionRulesResult.filter((transactionRule) => {
          if (transactionRule.lease) return isIdInList(transactionRule.lease, leases);
          return true;
        });
    setTransactions(filteredTransaction);
    setTransactionsRules(filteredTransactionRules);
    setAccountLabels(accountLabelsResult);
  };

  useEffect(() => {
    let unmounted = false;
    const queryEntity = async () => {
      setTransactionsLoading(true);
      try {
        await transactionRefetch();
      } catch (err) {
        if (!unmounted) {
          setTransactionsError((err as Error).message);
        }
      } finally {
        setTransactionsLoading(false);
      }
    };

    if (!loading && isNil(transactions) && !isNilOrEmpty(leases)) queryEntity();
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, rootUser, leases]);

  const createTransaction = async (
    input: CreateTransactionInput | Omit<CreateTransactionInput, 'clientId' | 'readId'>
  ): Promise<Transaction> => {
    setTransactionsLoading(true);
    const truncatedTansaction = await mutation<TruncatedTransaction, CreateTransactionMutationVariables>(
      createTransactionMutation,
      {
        input: {
          ...(cleanInputCreate(input) as CreateTransactionInput),
          readId: getReadId(clientId!, 'Transaction'),
          clientId: getTableClientId(clientId!, 'Transaction'),
          ...(isOwner && { writers: [userId!] }),
        },
      }
    );
    const newTransaction = { ...truncatedTansaction, postings: truncatedTansaction.postings.items };
    setTransactions((allTransactions) => [...(allTransactions ?? []), newTransaction]);
    setTransactionsLoading(false);
    return newTransaction;
  };

  const updateTransaction = async (updates: UpdateTransactionInput): Promise<Transaction> => {
    const transaction = (await fetchTransaction(updates.id)) as ModelWithVersion<Transaction>;
    const result = await mutation<Transaction>(updateTransactionMutation, {
      input: cleanInputUpdate({ ...updates, _version: transaction._version }, false),
    });

    setTransactions((ts) =>
      (ts ?? []).map((t) => {
        if (t.id === updates.id) {
          return { ...t, ...(updates as Transaction) };
        }
        return t;
      })
    );
    return result;
  };

  const deleteTransaction = async (transaction: Transaction | Pick<Transaction, 'id'>): Promise<Transaction | null> => {
    if (!financialTransactionsDelete) {
      return null;
    }
    const result = await deleteAndHideEntityWithFetchBefore<Transaction>(
      transaction as Transaction,
      getTransactionQuery,
      deleteTransactionMutation,
      updateTransactionMutation
    );
    setTransactions((transactions ?? []).filter((t) => t.id !== transaction.id));
    return result;
  };

  const getTransaction = (id: string) => {
    if (transactionsLoading || transactionsError) {
      return undefined;
    }
    return (transactions ?? []).find((transaction) => transaction.id === id);
  };

  const createTransactionRule = async (
    input: CreateTransactionRuleInput | Omit<CreateTransactionRuleInput, 'clientId' | 'readId'>
  ): Promise<TransactionRule> => {
    const transactionRule = await mutation<TransactionRule, CreateTransactionRuleMutationVariables>(
      createTransactionRuleMutation,
      {
        input: {
          ...(cleanInputCreate(input) as CreateTransactionRuleInput),
          readId: getReadId(clientId!, 'TransactionRule'),
          clientId: getTableClientId(clientId!, 'TransactionRule'),
        },
      }
    );
    setTransactionsRules((t) => [...t, transactionRule]);
    return transactionRule;
  };

  const updateTransactionRule = async (updates: UpdateTransactionRuleInput): Promise<TransactionRule> => {
    const transactionRuleVersion = (await fetchTransactionRule(updates.id))?._version;
    const result = await mutation<TransactionRule>(updateTransactionRuleMutation, {
      input: { ...cleanInputUpdate(updates, false), _version: transactionRuleVersion },
    });
    setTransactionsRules((ts) =>
      ts.map((t) => {
        if (t.id === result.id) return result;
        return t;
      })
    );
    return result;
  };

  const deleteTransactionRule = async (transactionRule: TransactionRule): Promise<TransactionRule | null> => {
    if (!financialTransactionsDelete) {
      return null;
    }
    const result = await deleteAndHideEntityWithFetchBefore<TransactionRule>(
      transactionRule,
      getTransactionRuleQuery,
      deleteTransactionRuleMutation,
      updateTransactionRuleMutation
    );
    setTransactionsRules(transactionsRules.filter((t) => t.id !== transactionRule.id));
    return result;
  };

  const getTransactionRule = (id: string) => {
    if (transactionsLoading || transactionsError) {
      return undefined;
    }
    return transactionsRules.find((transactionRule) => transactionRule.id === id);
  };

  const getAccountLabel = (id: string): AccountLabel | undefined => {
    return accountLabels.find((accountLabel) => accountLabel.id === id);
  };

  const getAccountLabels = (): AccountLabel[] => {
    return accountLabels;
  };

  const createAccountLabel = async (
    input: Omit<CreateAccountLabelInput, 'clientId' | 'readId'>
  ): Promise<AccountLabel> => {
    const accountLabel = await mutation<AccountLabel, CreateAccountLabelMutationVariables>(createAccountLabelMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateAccountLabelInput),
        readId: getReadId(clientId!, 'AccountLabel'),
        clientId: getTableClientId(clientId!, 'AccountLabel'),
        ...(isOwner && { writers: [userId!] }),
      },
    });
    setAccountLabels((m) => [...m, accountLabel]);
    return accountLabel;
  };

  const updateAccountLabel = async (updates: UpdateAccountLabelInput): Promise<AccountLabel> => {
    const result = await mutation<AccountLabel>(updateAccountLabelMutation, {
      input: cleanInputUpdate(updates, false),
    });
    setAccountLabels((accountLabels) =>
      accountLabels.map((accountLabel) => {
        if (accountLabel.id === result.id) return result;
        return accountLabel;
      })
    );
    return result;
  };

  const deleteAccountLabel = async (accountLabel: AccountLabel): Promise<AccountLabel> => {
    const result = await deleteAndHideEntityWithFetchBefore<AccountLabel>(
      accountLabel,
      getAccountLabelQuery,
      deleteAccountLabelMutation,
      updateAccountLabelMutation
    );
    setAccountLabels(accountLabels.filter((m) => m.id !== accountLabel.id));
    return result;
  };

  const createPosting = async (
    input: CreatePostingInput | Omit<CreatePostingInput, 'clientId' | 'readId'>
  ): Promise<Posting> => {
    const result = await mutation<Posting>(createPostingMutation, {
      input: {
        ...(cleanInputCreate(input) as CreatePostingInput),
        readId: getReadId(clientId!, 'Posting'),
        clientId: getTableClientId(clientId!, 'Posting'),
      },
    });
    return result;
  };

  const updatePosting = async (updates: UpdatePostingInput): Promise<Posting> => {
    const result = await mutation<Posting>(updatePostingMutation, {
      input: cleanInputUpdate(updates, false),
    });
    return result;
  };

  const deletePosting = async (posting: Posting): Promise<Posting | null> => {
    if (!financialTransactionsDelete) {
      return null;
    }
    const result = await deleteAndHideEntityWithFetchBefore<Posting>(
      posting,
      getPostingQuery,
      deletePostingMutation,
      updatePostingMutation
    );
    return result;
  };

  const getTransactionsForLease = (leaseId: string, filterIgnoredTransaction = false): Transaction[] => {
    if (transactionsLoading || transactionsError) {
      return [];
    }
    return (transactions ?? []).filter((t) => {
      const ignore = filterIgnoredTransaction && t.status === TransactionStatus.IGNORE;
      return (
        !ignore &&
        t.links &&
        t.links.some((link) => link.linkType === TransactionLinkType.LEASE && link.linkId === leaseId)
      );
    });
  };

  const getSplittedTransactionsForLease = async (leaseId: string): Promise<ExtendedTransaction[]> => {
    const extendedSplittedTransactions: ExtendedTransaction[] = [];
    await Promise.all(
      (transactions ?? []).map(async (transaction) => {
        if (transaction.links) {
          const splitForLease = transaction.links.find(
            (t) => t.linkType === TransactionLinkType.LEASE && t.linkId === leaseId
          );
          if (splitForLease) {
            const extendedTransaction = await extendTransaction(transaction);
            const leasePostingsTransactions = extendedTransaction.postings.filter(
              (p) => isNil(p.lease) || p.lease.id === leaseId
            );
            extendedSplittedTransactions.push({
              ...transaction,
              amount: splitForLease.amount,
              postings: leasePostingsTransactions,
            });
          }
        }
      })
    );
    return extendedSplittedTransactions;
  };

  const getAccountLabelForBankAccountOrInsert = async (bankAccount: BankAccount) => {
    const completeBankAccount = getBankAccount(bankAccount.id);
    // Check if we need to create an account
    const accountLabelForBankAccount = accountLabels.find(
      (a) => !isNil(a.bankAccount) && a.bankAccount.id === bankAccount.id
    );
    if (!isNil(accountLabelForBankAccount)) {
      return accountLabelForBankAccount;
    }
    const accountsBank = accountLabels.filter((a) => !isNil(a.bankAccount) && a.topClass === ACCOUNT_BANK_TOPCLASS);
    let newClass = 550001;
    if (!isNil(accountsBank) && !isEmpty(accountsBank)) {
      const maxClass = maxBy(accountsBank, 'class');
      newClass = toNumber(maxClass!.class) + 1;
    }

    const bankAccountName = `${completeBankAccount ? getContactNameFromObject(completeBankAccount.contact) : ''} - ${
      bankAccount.number
    }`;
    const account = await createAccountLabel({
      class: newClass,
      topClass: ACCOUNT_BANK_TOPCLASS,
      bankAccountId: bankAccount!.id,
      labels: [
        { language: 'en', label: bankAccountName },
        { language: 'fr', label: bankAccountName },
        { language: 'nl', label: bankAccountName },
      ],
    });

    return account;
  };

  const getAccountLabelForLease = () => {
    const accountLabelForLease = accountLabels.find((a) => a.topClass === ACCOUNT_LEASE_TOPCLASS);
    if (!isNil(accountLabelForLease)) {
      return accountLabelForLease;
    }
    return accountLabelForLease!;
  };

  const getAccountLabelForContactOrInsert = async (contact: Contact) => {
    // Check if we need to create an account
    const accountForContractor = accountLabels.find(
      (a) => !isNil(a.contact) && a.contact.id === contact.id && a.topClass === ACCOUNT_CONTRACTOR_TOPCLASS
    );
    if (!isNil(accountForContractor)) {
      return accountForContractor;
    }
    const accountsContractor = accountLabels.filter(
      (a) => !isNil(a.contact) && a.topClass === ACCOUNT_CONTRACTOR_TOPCLASS
    );
    let newClass = 440001;
    if (!isNil(accountsContractor) && !isEmpty(accountsContractor)) {
      const maxClass = maxBy(accountsContractor, 'class');
      newClass = toNumber(maxClass!.class) + 1;
    }
    const contactAccountLabel = getContactNameFromObject(contact);
    const account = await createAccountLabel({
      class: newClass,
      topClass: ACCOUNT_CONTRACTOR_TOPCLASS,
      contactId: contact.id,
      labels: [
        { language: 'en', label: contactAccountLabel },
        { language: 'fr', label: contactAccountLabel },
        { language: 'nl', label: contactAccountLabel },
      ],
    });

    return account;
  };

  const values = useMemo(
    () => ({
      transactions: transactions ?? [],
      transactionsHistory,
      transactionsToCheck,
      transactionsExcluded,
      transactionsRules,
      transactionRefetch,
      createTransaction,
      updateTransaction,
      deleteTransaction,
      getTransaction,
      createTransactionRule,
      updateTransactionRule,
      deleteTransactionRule,
      accountLabels,
      getAccountLabel,
      getAccountLabels,
      createAccountLabel,
      updateAccountLabel,
      deleteAccountLabel,
      getTransactionRule,
      createPosting,
      updatePosting,
      deletePosting,
      getTransactionsForLease,
      getSplittedTransactionsForLease,
      getAccountLabelForBankAccountOrInsert,
      getAccountLabelForLease,
      getAccountLabelForContactOrInsert,
      transactionsError,
      transactionsLoading,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      transactions,
      transactionsHistory,
      transactionsToCheck,
      transactionsExcluded,
      transactionsRules,
      accountLabels,
      transactionsError,
      transactionsLoading,
    ]
  );

  return <TransactionContext.Provider value={values}>{children}</TransactionContext.Provider>;
};

export const useTransactions = (): TransactionContext => {
  const context = useContext<TransactionContext | null>(TransactionContext);

  if (context === undefined) {
    throw new Error('`useTransactions` hook must be used within a `TransactionContextProvider` component');
  }
  return context as TransactionContext;
};
