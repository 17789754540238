import { Collapse, Grid, Typography } from '@material-ui/core';
import { RemittanceType } from '@rentguru/commons-utils';
import { FormikProps, useFormikContext } from 'formik';
import { isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { TextDetailEditable } from '@up2rent/ui';
import { AddLeaseFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import BankAccountSelector from 'src/components/ui/SimpleSelectors/SimpleBankAccountSelector';
import { useBankAccounts } from 'src/hooks/BankAccountsContext';
import RemittanceInformationField from './RemittanceInformationField';

export interface BankAccountSelectorFormValues {
  bankAccountId: string;
  remittanceInformation: string;
  remittanceInformationType: RemittanceType;
}

export const BankAccountSelectorSchema = Yup.object()
  .shape({
    bankAccountId: Yup.string().required(),
  })
  .required();

interface BankAccountSelectorFieldsProps {
  allowGenerateStructuredRemittanceInformation?: boolean;
  bankAccountInputWidth?: number;
  containerXPadding?: number;
}

const BankAccountSelectorFields: React.FC<BankAccountSelectorFieldsProps> = ({
  allowGenerateStructuredRemittanceInformation = false,
  bankAccountInputWidth = 580,
  containerXPadding = 30,
}) => {
  const { values }: FormikProps<AddLeaseFormValues> = useFormikContext();
  const { formatMessage } = useIntl();
  const { loading, getBankAccount } = useBankAccounts();

  if (loading) {
    return <Typography>{formatMessage({ id: 'loading' })}</Typography>;
  }
  const bankAccount = getBankAccount(values.bankAccountId);

  return (
    <div style={{ paddingLeft: containerXPadding, paddingRight: containerXPadding }}>
      <BankAccountSelector
        fieldLabel={formatMessage({ id: 'lease.addLease.bankAccount' })}
        inputWidth={bankAccountInputWidth}
        ownerId={values.unitOwnerId!}
        groupValues
      />
      <Collapse in={!isNil(bankAccount)}>
        <Grid
          container
          alignContent="flex-start"
          alignItems="flex-start"
          style={{ marginBottom: '5px', marginTop: '5px', textAlign: 'left' }}
        >
          <Grid item xs={6}>
            <TextDetailEditable
              title={formatMessage({
                id: 'bankAccount.addBankAccount.iban',
              })}
              name="iban"
              text={bankAccount?.number ?? ''}
              editMode={false}
            />
          </Grid>
          <Grid item xs={6}>
            {bankAccount?.bicNumber && (
              <TextDetailEditable
                title={formatMessage({
                  id: 'bankAccount.addBankAccount.bic',
                })}
                name="bic"
                editMode={false}
                text={bankAccount?.bicNumber ?? ''}
              />
            )}
          </Grid>
        </Grid>
      </Collapse>
      <RemittanceInformationField
        remittanceInformationName="remittanceInformation"
        remittanceInformationTypeName="remittanceInformationType"
        style={{ marginBottom: 10, marginTop: 15 }}
        allowGenerateStructuredRemittanceInformation={allowGenerateStructuredRemittanceInformation}
      />
    </div>
  );
};

export default BankAccountSelectorFields;
