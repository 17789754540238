import { Divider, Grid, makeStyles, MenuItem } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import FormikCustomizedSelect from 'src/components/ui/FormikCustomizedSelect';
import { EditAdvertisementFormValues } from '../EditPublicationForm';

const useLocalStyles = makeStyles({
  rootSelect: {
    '& > *': {
      width: 280,
    },
  },
  title: {
    flex: 1,
    textAlign: 'left' as const,
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: 700,
    margin: '10px 0 10px 30px',
  },
});

const UrbanisticPrescriptionsEdit = () => {
  const { values } = useFormikContext<EditAdvertisementFormValues>();
  const { formatMessage } = useIntl();
  const classes = useLocalStyles();

  return (
    <>
      <Grid container style={{ justifyContent: 'space-evenly', margin: '0 0 0 0' }}>
        <Grid item>
          <FormikCustomizedSelect
            label={formatMessage({ id: 'rentalUnit.detail.more.urbanisticPrescriptions.planningPermission' })}
            fieldName="advertisement.planningPermission"
            value={values.advertisement.planningPermission}
            className={classes.rootSelect}
            error={false}
          >
            <MenuItem id="none" key="none" value="none">
              {formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.availability.none' })}
            </MenuItem>

            <MenuItem id="true" key="true" value="true">
              {formatMessage({ id: 'rentalUnit.detail.more.urbanisticPrescriptions.obtained' })}
            </MenuItem>

            <MenuItem id="false" key="false" value="false">
              {formatMessage({ id: 'rentalUnit.detail.more.urbanisticPrescriptions.notObtained' })}
            </MenuItem>
          </FormikCustomizedSelect>
        </Grid>
        <Grid item>
          <FormikCustomizedSelect
            label={formatMessage({ id: 'rentalUnit.detail.more.urbanisticPrescriptions.rightOfPreemption' })}
            fieldName="advertisement.rightOfPreemption"
            value={values.advertisement.rightOfPreemption}
            className={classes.rootSelect}
            error={false}
          >
            <MenuItem id="none" key="none" value="none">
              {formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.availability.none' })}
            </MenuItem>
            <MenuItem id="true" key="true" value="true">
              {formatMessage({ id: 'rentalUnit.detail.more.urbanisticPrescriptions.obtained' })}
            </MenuItem>

            <MenuItem id="false" key="false" value="false">
              {formatMessage({ id: 'rentalUnit.detail.more.urbanisticPrescriptions.notObtained' })}
            </MenuItem>
          </FormikCustomizedSelect>
        </Grid>
      </Grid>

      <Grid container style={{ justifyContent: 'space-evenly', margin: '0 0 20px 0' }}>
        <Grid item>
          <FormikCustomizedSelect
            label={formatMessage({ id: 'rentalUnit.detail.more.urbanisticPrescriptions.subdivisionAuthorization' })}
            fieldName="advertisement.subdivisionAuthorization"
            value={values.advertisement.subdivisionAuthorization}
            className={classes.rootSelect}
            error={false}
          >
            <MenuItem id="none" key="none" value="none">
              {formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.availability.none' })}
            </MenuItem>
            <MenuItem id="true" key="true" value="true">
              {formatMessage({ id: 'rentalUnit.detail.more.urbanisticPrescriptions.obtained' })}
            </MenuItem>

            <MenuItem id="false" key="false" value="false">
              {formatMessage({ id: 'rentalUnit.detail.more.urbanisticPrescriptions.notObtained' })}
            </MenuItem>
          </FormikCustomizedSelect>
        </Grid>
        <Grid item>
          <FormikCustomizedSelect
            label={formatMessage({ id: 'rentalUnit.detail.more.urbanisticPrescriptions.citationForPlanningOffence' })}
            fieldName="advertisement.citationForPlanningOffence"
            value={values.advertisement.citationForPlanningOffence}
            className={classes.rootSelect}
            error={false}
          >
            <MenuItem id="none" key="none" value="none">
              {formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.availability.none' })}
            </MenuItem>
            <MenuItem id="true" key="true" value="true">
              {formatMessage({ id: 'rentalUnit.detail.more.urbanisticPrescriptions.obtained' })}
            </MenuItem>

            <MenuItem id="false" key="false" value="false">
              {formatMessage({ id: 'rentalUnit.detail.more.urbanisticPrescriptions.notObtained' })}
            </MenuItem>
          </FormikCustomizedSelect>
        </Grid>
      </Grid>
      <Divider style={{ margin: '10px 0 10px 0' }} />
    </>
  );
};

export default UrbanisticPrescriptionsEdit;
