import { CircularProgress, Collapse, Grid, MenuItem } from '@material-ui/core';
import { ContactType, EnergeticPerformance, FileCategory } from '@rentguru/commons-utils';
import { TextDetailEditable } from '@up2rent/ui';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import { useContacts } from 'src/hooks/ContactsContext';
import { useDocumentCategory } from 'src/hooks/FileCategoryContext';
import { FileCategoryTypeForm as FileFieldsCategory } from 'src/hooks/TechnicsContext';
import FormAlertBox from '../../FormAlertBox';
import FormikCustomizedSelect from '../../FormikCustomizedSelect';
import FormikDatePicker from '../../FormikDatePicker';
import SimpleContactSelector from '../../SimpleSelectors/SimpleContactSelector';
import FileFields, { FileFormValues as ImpFormValues } from '../FormField/FileByCategoryFields';

interface PebRowBodyProps {
  isUnit: boolean;
  unitId?: string;
  prefixFieldName: string;
  pebIndex: number;
  setPebIndex: (index: number) => void;
}

const PebRowBody: React.FC<PebRowBodyProps> = ({ isUnit, unitId, prefixFieldName, pebIndex, setPebIndex }) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue, errors, touched, handleBlur } = useFormikContext<ImpFormValues>();
  const { getDocumentCategoryByFileCategory } = useDocumentCategory();
  const { contractors, contactsLoading } = useContacts();

  const pebDocumentCategory = getDocumentCategoryByFileCategory(FileCategory.PEB);

  if (contactsLoading) {
    return <CircularProgress />;
  }
  return (
    <Grid item xs={12}>
      <FileFields
        documentCategory={pebDocumentCategory!}
        categoryType="noHeader"
        maxFiles={1}
        dropZoneStyle={{ marginLeft: 14, width: 'auto' }}
        unitId={isUnit ? unitId : undefined}
        centerSelectedFile
        displayingPictures
        indexFromParent={pebIndex}
        setIndexFromParent={setPebIndex}
      />
      <Collapse
        in={Boolean(
          get(errors, `${prefixFieldName}filesByCategories[${pebIndex}].files`) &&
            get(touched, `${prefixFieldName}filesByCategories[${pebIndex}]`)
        )}
      >
        <FormAlertBox
          message1={formatMessage({
            id: 'lease.addLease.fileMissing',
          })}
        />
      </Collapse>
      <Grid style={{ alignItems: 'baseline', marginLeft: 20, marginRight: 20 }}>
        <Grid style={{ display: 'flex', justifyContent: 'space-around' }}>
          <FormikDatePicker
            name={`${prefixFieldName}filesByCategories[${pebIndex}].PebIssueDate`}
            label={formatMessage({ id: 'technic.epbTab.epbIssueDate' })}
            value={
              pebIndex === -1 ||
              isNil(
                (get(values, `${prefixFieldName}filesByCategories[${pebIndex}]`) as FileFieldsCategory)?.PebIssueDate
              )
                ? null
                : ((get(values, `${prefixFieldName}filesByCategories[${pebIndex}]`) as FileFieldsCategory)
                    .PebIssueDate as string)
            }
            style={{ width: 180 }}
            error={Boolean(
              get(errors, `${prefixFieldName}filesByCategories[${pebIndex}].PebIssueDate`) &&
                get(touched, `${prefixFieldName}filesByCategories[${pebIndex}]`)
            )}
            onBlur={handleBlur}
          />
          <FormikDatePicker
            name={`${prefixFieldName}filesByCategories[${pebIndex}].PebValidityDate`}
            label={formatMessage({ id: 'technic.epbTab.epbValidityDate' })}
            value={
              pebIndex === -1 ||
              isNil(
                (get(values, `${prefixFieldName}filesByCategories[${pebIndex}]`) as FileFieldsCategory)?.PebValidityDate
              )
                ? null
                : ((get(values, `${prefixFieldName}filesByCategories[${pebIndex}]`) as FileFieldsCategory)
                    .PebValidityDate as string)
            }
            style={{ width: 180 }}
            error={Boolean(
              get(errors, `${prefixFieldName}filesByCategories[${pebIndex}].PebValidityDate`) &&
                get(touched, `${prefixFieldName}filesByCategories[${pebIndex}]`)
            )}
            onBlur={handleBlur}
          />

          <FormikCustomizedSelect
            id="PebEnergeticPerformance"
            fieldName={`${prefixFieldName}filesByCategories[${pebIndex}].PebEnergeticPerformance`}
            value={
              pebIndex === -1 ||
              isNil(
                (get(values, `${prefixFieldName}filesByCategories[${pebIndex}]`) as FileFieldsCategory | null)
                  ?.PebEnergeticPerformance
              )
                ? ''
                : ((get(values, `${prefixFieldName}filesByCategories[${pebIndex}]`) as FileFieldsCategory)
                    .PebEnergeticPerformance as string)
            }
            onChange={(value) => {
              setFieldValue(`${prefixFieldName}filesByCategories[${pebIndex}].PebEnergeticPerformance`, value);
            }}
            onBlur={handleBlur}
            error={Boolean(
              get(errors, `${prefixFieldName}filesByCategories[${pebIndex}].PebEnergeticPerformance`) &&
                get(touched, `${prefixFieldName}filesByCategories[${pebIndex}]`)
            )}
            label={`${formatMessage({ id: 'technic.epbTab.energyPerformance' })}`}
            style={{ width: 180 }}
            dataTest="peb-energetic-performance-select"
          >
            {Object.keys(EnergeticPerformance).map((key) => (
              <MenuItem
                id={EnergeticPerformance[key as keyof typeof EnergeticPerformance]}
                value={EnergeticPerformance[key as keyof typeof EnergeticPerformance]}
                key={key}
              >
                {EnergeticPerformance[key as keyof typeof EnergeticPerformance]}
              </MenuItem>
            ))}
          </FormikCustomizedSelect>
        </Grid>
        <Grid style={{ display: 'flex', justifyContent: 'space-around' }}>
          <TextDetailEditable
            title={formatMessage({ id: 'rentalUnit.detail.energy.epbCertificate.epbReportNumber' })}
            editMode={true}
            name={`${prefixFieldName}filesByCategories[${pebIndex}].PebReportNumber`}
            type="text"
            style={{ width: 280, maxWidth: 'none' }}
            error={Boolean(
              get(errors, `${prefixFieldName}filesByCategories[${pebIndex}].PebReportNumber`) &&
                get(touched, `${prefixFieldName}filesByCategories[${pebIndex}]`)
            )}
          />
          <SimpleContactSelector
            fieldLabel={`${formatMessage({
              id: 'technic.contractorName',
            })} ${formatMessage({
              id: 'optional',
            })}`}
            contacts={contractors}
            fieldName={`${prefixFieldName}filesByCategories[${pebIndex}].contactId`}
            inputWidth={280}
            type={[ContactType.CONTRACTOR]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PebRowBody;
