import {
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Colors,
  CustomLabel,
  Language,
  LeasePaymentFrequency,
  LeaseVariousOperationType,
  VariousOperation,
  VariousOperationType,
} from '@rentguru/commons-utils';
import { ActionButton, ConfirmDialog, ContentHeader, TextDetailEditable, useTextFieldStyles } from '@up2rent/ui';
import { Form, Formik, FormikHelpers } from 'formik';
import { isNil, toNumber, toUpper } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import FormikCustomizedSelect from 'src/components/ui/FormikCustomizedSelect';
import TranslateTextDetailEditable, {
  TranslateDialogBundle,
} from 'src/components/ui/Forms/FormField/Translators/TranslateTextDetailEditable';
import StyledRadio from 'src/components/ui/StyledRadio';
import { useVariousOperations } from 'src/hooks/VariousOperationsContext';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import * as Yup from 'yup';

const AddEditVariousOperationSchema = Yup.object().shape({
  amount: Yup.number().required(),
  type: Yup.string().required(),
  labels: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required(),
      language: Yup.string().required(),
    })
  ),
  numberOfMonths: Yup.number().when('type', {
    is: VariousOperationType.RECURRING,
    then: Yup.number().positive().required(),
    otherwise: Yup.number().nullable(),
  }),
  paymentFrequency: Yup.string().required(),
});

interface AddEditVariousOperationProps {
  id?: string;
  operationType: LeaseVariousOperationType;
  afterSubmit: (variousOperation?: VariousOperation) => void;
}

type AddEditVariousOperationValues = Pick<
  VariousOperation,
  'amount' | 'type' | 'labels' | 'numberOfMonths' | 'paymentFrequency'
> & {
  id?: string;
};

const getRecurringMonths = (
  type: VariousOperationType | keyof VariousOperationType,
  numberOfMonths?: number | null
) => {
  if (type === VariousOperationType.ONE_TIME || type === VariousOperationType.END_WITH_LEASE) {
    return { numberOfMonths: null };
  }
  return {
    numberOfMonths: numberOfMonths!,
  };
};

const getIndexOfLabel = (values: AddEditVariousOperationValues, language: Language) => {
  return values.labels?.findIndex((label) => label.language === language) || 0;
};

const AddEditVariousOperation: React.FC<AddEditVariousOperationProps> = ({ id, operationType, afterSubmit }) => {
  const { formatMessage } = useIntl();
  const { createVariousOperation, updateVariousOperation, getVariousOperation, loading, error } =
    useVariousOperations();
  const [languageSelected, setLanguage] = useState<Language>(Language.EN);
  const [textsToTranslate, setTextsToTranslate] = useState<TranslateDialogBundle | null>(null);
  const [openMissingTranslationDialog, setOpenMissingTranslationDialog] = useState<TranslateDialogBundle | null>(null);
  const textFieldClasses = useTextFieldStyles();

  const handleCreateUpdate = async (
    values: AddEditVariousOperationValues,
    { setSubmitting, setStatus }: FormikHelpers<AddEditVariousOperationValues>
  ) => {
    const type = values.type as VariousOperationType;
    const paymentFrequency = values.paymentFrequency as LeasePaymentFrequency;
    let variousOperation;
    if (isNil(id)) {
      variousOperation = await createVariousOperation({
        ...values,
        type,
        operationType,
        paymentFrequency,
        ...getRecurringMonths(type, values.numberOfMonths),
      });
    } else {
      const oldVariousOperation = getVariousOperation(id);
      // Update
      variousOperation = await updateVariousOperation({
        ...oldVariousOperation,
        ...values,
        id: id!,
        type,
        operationType,
        paymentFrequency,
        ...getRecurringMonths(type, values.numberOfMonths),
      });
    }

    setStatus(true);
    setSubmitting(false);
    afterSubmit(variousOperation!);
  };

  let oldVariousOperation;
  if (!isNil(id)) {
    if (loading || error) {
      return null;
    }
    oldVariousOperation = getVariousOperation(id);
  }

  const initialValues: AddEditVariousOperationValues = {
    id,
    amount: oldVariousOperation?.amount || 0,
    type: oldVariousOperation?.type || VariousOperationType.ONE_TIME,
    labels: oldVariousOperation?.labels || [
      { language: Language.EN, label: '' },
      { language: Language.FR, label: '' },
      { language: Language.NL, label: '' },
    ],
    numberOfMonths: oldVariousOperation?.numberOfMonths,
    paymentFrequency: oldVariousOperation?.paymentFrequency || LeasePaymentFrequency.MONTHLY,
  };

  return (
    <>
      <ContentHeader
        toolbarStyle={{ display: 'flex', justifyContent: 'space-between' }}
        title={formatMessage({
          id: `settings.addVariousOperation.action${
            operationType === LeaseVariousOperationType.SERVICE ? 'Service' : 'Charge'
          }${!isNil(id) ? 'Edit' : ''}`,
        })}
      >
        <Tooltip title={formatMessage({ id: 'settings.addVariousOperation.helper' })} placement="bottom">
          <Grid>
            <InfoSvg style={{ fill: Colors.SILVER }} />
          </Grid>
        </Tooltip>
      </ContentHeader>
      <Divider style={{ marginBottom: 20 }} />
      <Formik
        initialValues={initialValues}
        validationSchema={AddEditVariousOperationSchema}
        onSubmit={handleCreateUpdate}
      >
        {({ values, setFieldValue, errors, touched, isSubmitting, submitForm, handleBlur }) => {
          return (
            <Form>
              <div style={{ marginLeft: 30, marginRight: 30 }}>
                <TranslateTextDetailEditable
                  textsToTranslate={textsToTranslate}
                  languageSelected={languageSelected}
                  onDialogClose={() => setTextsToTranslate(null)}
                  onLanguageChange={(language) => setLanguage(language)}
                  actionButtonProps={{
                    onClick: () =>
                      setTextsToTranslate({
                        texts: values.labels!,
                        afterSubmit: (newTexts: CustomLabel[]) => {
                          setFieldValue('labels', newTexts);
                        },
                      }),
                  }}
                  textDetailEditableProps={{
                    title: formatMessage({ id: 'settings.addVariousOperation.label' }),
                    editMode: true,
                    name: `labels[${getIndexOfLabel(values, languageSelected)}].label`,
                    type: 'text',
                    style: { width: 580, maxWidth: 'none' },
                    error: Boolean(errors.labels && touched.labels),
                  }}
                  translateDialogProps={{
                    entity: formatMessage({ id: 'settings.addVariousOperation.variousOperations' }, { value: 1 }),
                    theEntity: formatMessage({ id: 'settings.addVariousOperation.theVariousOperations' }),
                    ofTheEntity: formatMessage({ id: 'settings.addVariousOperation.ofTheVariousOperations' }),
                  }}
                />

                {errors.labels && (
                  <Typography style={{ color: Colors.BURNING_ORANGE }}>
                    {formatMessage({ id: 'accounting.accountLabel.translationsMissings' })}
                  </Typography>
                )}

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 10,
                  }}
                >
                  <TextField
                    title={formatMessage({
                      id: 'settings.addVariousOperation.amount',
                    })}
                    name="amount"
                    type="number"
                    margin="dense"
                    variant="filled"
                    value={values.amount.toString()}
                    onChange={(event) => setFieldValue('amount', toNumber(event.target.value))}
                    style={{ width: 280, marginTop: 10 }}
                    InputProps={{
                      endAdornment: '€',
                      classes: { underline: textFieldClasses.underline, input: textFieldClasses.noEndArrow },
                    }}
                    error={Boolean(errors.amount && touched.amount)}
                  />

                  <FormikCustomizedSelect
                    label={formatMessage({
                      id: 'lease.addLease.paymentFrequency',
                    })}
                    fieldName="paymentFrequency"
                    value={values.type === VariousOperationType.ONE_TIME ? values.type : values.paymentFrequency}
                    error={Boolean(errors.paymentFrequency && touched.paymentFrequency)}
                    FormControlProps={{ required: true, style: { width: 280 } }}
                    SelectProps={{
                      onChange: (e) => {
                        const type = e.target.value as string;
                        const isOneTime = type === VariousOperationType.ONE_TIME;

                        if (isOneTime) {
                          setFieldValue('paymentFrequency', LeasePaymentFrequency.MONTHLY);
                          setFieldValue('type', VariousOperationType.ONE_TIME);
                        } else {
                          setFieldValue('paymentFrequency', type);
                          setFieldValue('type', VariousOperationType.END_WITH_LEASE);
                        }
                      },
                      onBlur: handleBlur,
                    }}
                  >
                    <MenuItem id={VariousOperationType.ONE_TIME} value={VariousOperationType.ONE_TIME}>
                      {formatMessage({ id: `settings.variousOperations.ONE_TIME` })}
                    </MenuItem>
                    {[
                      LeasePaymentFrequency.MONTHLY,
                      LeasePaymentFrequency.QUARTERLY,
                      LeasePaymentFrequency.BI_ANNUAL,
                      LeasePaymentFrequency.YEARLY,
                    ].map((leasePaymentFrequency) => (
                      <MenuItem id={leasePaymentFrequency} key={leasePaymentFrequency} value={leasePaymentFrequency}>
                        {formatMessage({ id: `enums.LeasePaymentFrequency.${leasePaymentFrequency}` })}
                      </MenuItem>
                    ))}
                  </FormikCustomizedSelect>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 10,
                  }}
                >
                  <Collapse in={values.type !== VariousOperationType.ONE_TIME}>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: 'normal',
                        fontStyle: 'normal',
                        letterSpacing: 0,
                      }}
                    >
                      {formatMessage({ id: `lease.detail.variousOperations.endsOn` })}
                    </Typography>
                    <FormControl component="fieldset">
                      <RadioGroup
                        value={values.type}
                        onChange={(_e, value) => {
                          setFieldValue('type', value);
                        }}
                      >
                        <FormControlLabel
                          value={VariousOperationType.END_WITH_LEASE}
                          control={<StyledRadio />}
                          label={
                            <Typography
                              style={{
                                fontSize: 14,
                                fontWeight: 'normal',
                                fontStyle: 'normal',
                                letterSpacing: 0,
                                color: Colors.SLATE_GREY,
                              }}
                            >
                              {formatMessage({ id: `settings.variousOperations.END_WITH_LEASE` })}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value={VariousOperationType.RECURRING}
                          control={<StyledRadio />}
                          label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Typography
                                style={{
                                  fontSize: 14,
                                  fontWeight: 'normal',
                                  fontStyle: 'normal',
                                  letterSpacing: 0,
                                  color: Colors.SLATE_GREY,
                                }}
                              >
                                {formatMessage({ id: `lease.detail.variousOperations.after` })}
                              </Typography>

                              <TextDetailEditable
                                type="number"
                                min={1}
                                editMode={true}
                                style={{ width: 150, marginRight: 10, height: 48, marginLeft: 10 }}
                                name="numberOfMonths"
                                disabled={values.type === VariousOperationType.END_WITH_LEASE}
                                error={Boolean(errors.numberOfMonths && touched.numberOfMonths)}
                                title=""
                                dataTest="DurationInMonths"
                              />

                              <Typography
                                style={{
                                  fontSize: 14,
                                  fontWeight: 'normal',
                                  fontStyle: 'normal',
                                  letterSpacing: 0,
                                  color: Colors.SLATE_GREY,
                                }}
                              >
                                {formatMessage({ id: `lease.detail.variousOperations.occurrences` })}
                              </Typography>
                            </div>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Collapse>
                </div>
              </div>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <div
                style={{
                  marginRight: 30,
                  marginBottom: 20,
                  display: 'flex',
                  float: 'right',
                  alignItems: 'center',
                }}
              >
                <ActionButton
                  onClick={() => afterSubmit()}
                  style={{
                    background: 'none',
                    color: Colors.DARK_SLATE_GREY,
                    marginRight: 20,
                  }}
                >
                  {toUpper(
                    formatMessage({
                      id: 'cancel',
                    })
                  )}
                </ActionButton>
                {!isSubmitting && (
                  <ActionButton
                    style={{ minWidth: 120, height: 50, fontSize: 16, fontWeight: 800 }}
                    onClick={() => {
                      const oneLabelFilled = values.labels!.some((label) => label.label !== '');
                      const oneLabelNotFilled = values.labels!.some((label) => label.label === '');
                      if (oneLabelFilled && oneLabelNotFilled) {
                        setOpenMissingTranslationDialog({
                          texts: values.labels!,
                          afterSubmit: (newTexts: CustomLabel[]) => {
                            setFieldValue('labels', newTexts);
                          },
                        });
                      } else {
                        submitForm();
                      }
                    }}
                  >
                    {formatMessage({ id: 'save' })}
                  </ActionButton>
                )}
                {isSubmitting && <CircularProgress style={{ width: 40, height: 40 }} />}
              </div>
            </Form>
          );
        }}
      </Formik>

      {!isNil(openMissingTranslationDialog) && (
        <ConfirmDialog
          open={!isNil(openMissingTranslationDialog)}
          confirmText={formatMessage({ id: 'templates.translate.importAndTranslate' })}
          confirmAction={async () => {
            setOpenMissingTranslationDialog(null);
            setTextsToTranslate(openMissingTranslationDialog);
          }}
          cancelAction={() => {
            setOpenMissingTranslationDialog(null);
          }}
          mainText={formatMessage({ id: 'settings.addVariousOperation.missingTranslations' })}
          formatMessage={formatMessage}
        />
      )}
    </>
  );
};

export default AddEditVariousOperation;
