import { Grid, MenuItem, Typography } from '@material-ui/core';
import { Colors, CommunicationTimeFrame, NotificationOption } from '@rentguru/commons-utils';
import { CustomizedSelect } from '@up2rent/ui';
import { useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as DropDown } from 'src/icons/drop-down.svg';

interface AuthorizedTimeFrameRowProps {
  title: CommunicationTimeFrame;
}

export type UpdateAuthorizedTimeFramesValues = {
  [CommunicationTimeFrame.MORNING]: NotificationOption;
  [CommunicationTimeFrame.AFTERNOON]: NotificationOption;
  [CommunicationTimeFrame.EVENING]: NotificationOption;
  [CommunicationTimeFrame.NIGHT]: NotificationOption;
};

const AuthorizedTimeFrameRow: React.FC<AuthorizedTimeFrameRowProps> = ({ title }) => {
  const { formatMessage } = useIntl();
  const { handleChange, values } = useFormikContext();

  return (
    <Grid
      container
      style={{ alignItems: 'center', marginLeft: 30, marginRight: 30, justifyContent: 'space-between', maxWidth: 580 }}
    >
      <Grid style={{ marginRight: 30, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography style={{ fontSize: 14, fontWeight: 600 }}>
          {formatMessage({ id: `settings.communications.timeFrameOptions.${title}` })}
        </Typography>
        <Typography style={{ marginLeft: 5, color: Colors.SLATE_GREY, fontStyle: 'italic' }}>
          {formatMessage({ id: `settings.communications.timeFrameOptionValues.${title}` })}
        </Typography>
      </Grid>
      <CustomizedSelect
        IconComponent={DropDown}
        fieldName={title}
        value={(values as UpdateAuthorizedTimeFramesValues)[title]}
        handleChange={handleChange}
        style={{ width: 150 }}
      >
        <MenuItem key="ENABLED" value={NotificationOption.ENABLED}>
          {formatMessage({ id: 'settings.communications.enabled' })}
        </MenuItem>
        <MenuItem key="DISABLED" value={NotificationOption.DISABLED}>
          {formatMessage({ id: 'settings.communications.disabled' })}
        </MenuItem>
      </CustomizedSelect>
    </Grid>
  );
};

export default AuthorizedTimeFrameRow;
