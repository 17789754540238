import { Button, Collapse, Divider, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  BuildingEvent,
  Colors,
  LeaseMonthlyChargesType,
  Technic,
  Unit,
  UnitEvent,
  UnitInventory,
  UnitLease,
  UnitType,
  getOverlappingEvents,
  getUnitEvents,
  getUnitsOfOwnerSync,
  isNilOrEmpty,
  removeIndexFromArray,
} from '@rentguru/commons-utils';
import { CustomizedSwitch } from '@up2rent/ui';
import { areIntervalsOverlapping, isBefore, isToday } from 'date-fns/esm';
import { useFormikContext } from 'formik';
import { isEmpty, isNil, toNumber } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { AddLeaseFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import { getTechnicsAndFilesByCategoriesInitValuesForUnit } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import ConditionalParentElement from 'src/components/RentalUnits/Details/Publication/EditPublication/ConditionalParentElement';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { FileCategoryContext, useDocumentCategory } from 'src/hooks/FileCategoryContext';
import { useTechnics } from 'src/hooks/TechnicsContext';
import { useUnitInventories } from 'src/hooks/UnitInventoryContext';
import { useUnits } from 'src/hooks/UnitsContext';
import * as Yup from 'yup';
import { ReactComponent as Addicon } from '../../../../icons/add.svg';
import FormHeader from '../../FormHeader';
import { getArrayOfDiscountsSchema, getArrayOfOperationsSchema } from '../FormField/AddOperation';
import { FileFormValues, FileSchema } from '../FormField/FileByCategoryFields';
import { KeyDatesFieldValues } from '../FormField/KeyDatesFields';
import {
  isInitialChargesPriceValidWithIndexationSettingsAndValue,
  isInitialRentalPriceValidWithIndexationSettingsAndValue,
} from '../FormField/LeaseRentFields';
import { RentFieldFormValues } from '../FormField/RentFields';
import { canAddTechnicOnBuilding } from '../FormField/TechnicFields';
import { getUnitInventoriesGroupedByType } from '../FormField/UnitStructureFields';
import { SurfaceField } from '../FormField/UnitSurfaceFields';
import { HistoryElements } from './AddHistory';
import AdditionalUnitSelectorField from './AdditionalUnitSelectorField';
import { getDynamicSmokeDetectorsSchema } from './SmokeDetectorsForm';
import { UnitInventoriesFormValues } from './UnitStructureForm';

interface AddHistoryTransactionFormValues {
  addHistoryTransaction: {
    amountDue: number;
    transactionType: string;
    accountId: string;
    dueDate: Date;
  }[];
}

export interface AdditionalUnit
  extends RentFieldFormValues,
    AddHistoryTransactionFormValues,
    KeyDatesFieldValues,
    HistoryElements,
    FileFormValues,
    UnitInventoriesFormValues {
  id: string;
  buildingId: string;
  type: UnitType;
  indexation: boolean;
  overlappingNonEditedEvent:
    | null
    | UnitLease
    | UnitEvent
    | BuildingEvent
    | {
        startDate: string;
        endDate: string;
      };
  includeMonthlyCharge: boolean;
  smokeDetectors: Technic[];
  originalSmokeDetectors: Technic[];
  technics: Technic[];
  originalTechnics: Technic[];
  includeFuelTank: boolean;
  includeHeating: boolean;
  includeChimney: boolean;
  originalUtilityProviders: Technic[];
}

export const SelectSubUnitSchema = Yup.object().shape({
  subUnits: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(),
      type: Yup.string().required(),
    })
  ),
});

export const getSubUnitSchema = (_startDate?: Date, _endDate?: Date) => {
  return Yup.array().of(
    Yup.object()
      .shape({
        startDate: Yup.date().required(),
        endDate: Yup.date().required(),
        signatureDate: Yup.date().required(),
        overlappingNonEditedEvent: Yup.object()
          .nullable()
          // eslint-disable-next-line prefer-arrow-callback, func-names
          .test('no overlapping dates in subUnits', 'overlapping dates error in subUnits', function (value) {
            if (isNil(value)) {
              return true;
            }
            const parentValue = this.parent as AdditionalUnit;
            return !areIntervalsOverlapping(
              { start: new Date(parentValue.startDate), end: new Date(parentValue.endDate) },
              { start: new Date(value.startDate), end: new Date(value.endDate) }
            );
          }),
        history: Yup.array().of(Yup.object().shape({})),
        vatOnRent: Yup.object().shape({
          chargesVAT: Yup.number().required(),
          rentVAT: Yup.number().required(),
          vat: Yup.boolean().required(),
        }),
        furnishedRental: Yup.object().shape({
          funrituresShouldBeCoveredByInsurance: Yup.boolean().required(),
          furnishedRental: Yup.boolean().required(),
          percentageOfRentAssignedToFurnitures: Yup.number().required(),
        }),
        rentalIndexation: Yup.boolean().nullable(),
        chargeIndexation: Yup.boolean().nullable(),
        advancedIndexation: Yup.boolean().required(),
        indexType: Yup.string().when('advancedIndexation', {
          is: true,
          then: Yup.string().required(),
          otherwise: Yup.string().nullable(),
        }),
        baseYear: Yup.number().when('advancedIndexation', {
          is: true,
          then: Yup.number().required(),
          otherwise: Yup.number().nullable(),
        }),
        baseIndexType: Yup.string().when('advancedIndexation', {
          is: true,
          then: Yup.string().required(),
          otherwise: Yup.string().nullable(),
        }),
        baseIndexDate: Yup.string().when('advancedIndexation', {
          is: true,
          then: Yup.string().required(),
          otherwise: Yup.string().nullable(),
        }),
        indexation: Yup.boolean().nullable(),
        initialRentalPrice: Yup.number()
          .required() // eslint-disable-next-line func-names
          .test(function (value) {
            const parentValue = this.parent as AdditionalUnit;
            const indexationActive = Boolean(parentValue.indexation && parentValue.rentalIndexation);
            return isInitialRentalPriceValidWithIndexationSettingsAndValue(
              indexationActive,
              new Date(parentValue.startDate),
              toNumber(value),
              toNumber(parentValue.rentalPrice)
            );
          }),
        initialChargePrice: Yup.number()
          .when('includeMonthlyCharge', {
            is: true,
            then: Yup.number().required(),
            otherwise: Yup.number().nullable(),
          })
          // eslint-disable-next-line func-names
          .test(function (value) {
            const parentValue = this.parent as AdditionalUnit;

            const indexationActive = Boolean(
              parentValue.includeMonthlyCharge && parentValue.indexation && parentValue.chargeIndexation
            );
            return isInitialChargesPriceValidWithIndexationSettingsAndValue(
              indexationActive,
              new Date(parentValue.startDate),
              toNumber(value),
              toNumber(parentValue.monthlyChargesFields.monthlyCharges),
              parentValue.monthlyChargesFields.monthlyChargesType as LeaseMonthlyChargesType | undefined
            );
          }),
        leaseOperations: getArrayOfOperationsSchema(),
        discounts: getArrayOfDiscountsSchema(),
        rentalPrice: Yup.number().required(),
        profesionnalActivity: Yup.object().shape({
          profesionnalActivity: Yup.boolean().required(),
        }),
        monthlyChargesFields: Yup.object().shape({
          allowChargeAdjustment: Yup.boolean().nullable(),
          monthlyCharges: Yup.string().nullable(),
          monthlyChargesType: Yup.string().nullable(),
        }),
        addHistoryTransaction: Yup.array().of(Yup.object().shape({})),
        surface: SurfaceField,
      })
      .concat(FileSchema)
      .concat(getDynamicSmokeDetectorsSchema())
  );
};

export const getDynamicLeaseDurationSubUnitSchema = (
  subUnits: AdditionalUnit[],
  additionalUnit: boolean,
  startDate: Date
) => {
  return Yup.object()
    .shape({
      subUnits: Yup.array().of(
        Yup.object()
          .shape({
            overlappingNonEditedEvent: Yup.object()
              .nullable()
              // eslint-disable-next-line prefer-arrow-callback, func-names
              .test('no overlapping dates in subUnits', 'overlapping dates error in subUnits', function (value) {
                if (isNil(value) || !additionalUnit || (isBefore(startDate, new Date()) && !isToday(startDate))) {
                  return true;
                }
                const allOverlappingEvents = subUnits.reduce(
                  (acc, currentSubUnit) => {
                    if (!isNilOrEmpty(currentSubUnit.overlappingNonEditedEvent)) {
                      acc.push(currentSubUnit.overlappingNonEditedEvent!);
                    }
                    return acc;
                  },
                  [] as
                    | (
                        | UnitLease
                        | UnitEvent
                        | BuildingEvent
                        | {
                            startDate: string;
                            endDate: string;
                          }
                      )[]
                );
                return isNilOrEmpty(allOverlappingEvents);
              }),
          })
          .required()
      ),
    })
    .nullable();
};

export interface AddAdditionalUnitFormValues {
  additionalUnit: boolean;
  allDatesAreSame: boolean;
  allowedToCheckAllDatesAreSame: boolean;
  subUnits: AdditionalUnit[];
}

export const getSubUnitInitialValues = async (
  id: string | undefined,
  buildingId: string,
  type: UnitType | undefined,
  advertisedRentalPrice: number | undefined | null,
  advertisedMonthlyCharges: number | undefined | null,
  surface: number | undefined | null,
  values: AddLeaseFormValues,
  technics: Technic[],
  getUnitInventoriesFor: (unitId: string, date?: Date | undefined) => UnitInventory[],
  getDocumentCategoryByFileCategory: FileCategoryContext['getDocumentCategoryByFileCategory']
) => {
  const unitInventories = getUnitInventoriesFor(id!, new Date());
  const filesAndTechnics = await getTechnicsAndFilesByCategoriesInitValuesForUnit(
    technics,
    id ?? '',
    buildingId,
    undefined,
    !canAddTechnicOnBuilding(values, buildingId, values.subUnits.length - 1),
    false,
    getDocumentCategoryByFileCategory
  );

  const { startDate, endDate, signatureDate, includeMonthlyCharge, rentalPrice, monthlyCharges } = values;
  return {
    id,
    buildingId,
    type,
    startDate,
    endDate,
    signatureDate: signatureDate ?? startDate,
    overlappingNonEditedEvent: null,
    indexation: true,
    rentalIndexation: true,
    chargeIndexation: true,
    includeMonthlyCharge,
    surface,
    history: [],
    vatOnRent: {
      chargesVAT: 0,
      rentVAT: 0,
      vat: false,
    },
    furnishedRental: {
      funrituresShouldBeCoveredByInsurance: false,
      furnishedRental: false,
      percentageOfRentAssignedToFurnitures: 0,
    },
    initialRentalPrice: advertisedRentalPrice ?? rentalPrice,
    initialChargePrice: advertisedMonthlyCharges ?? monthlyCharges,
    leaseOperations: [],
    discounts: [],
    rentalPrice: advertisedRentalPrice ?? rentalPrice,
    profesionnalActivity: {
      profesionnalActivity: false,
    },
    advancedIndexation: false,
    monthlyChargesFields: {
      includeMonthlyCharges: true,
      allowChargeAdjustment: true,
      monthlyCharges: advertisedMonthlyCharges ?? monthlyCharges,
      monthlyChargesType: LeaseMonthlyChargesType.MonthlyProvisioned,
    },
    addHistoryTransaction: [],
    originalUnitInventories: unitInventories,
    unitStructures: getUnitInventoriesGroupedByType(unitInventories),
    ...filesAndTechnics,
  } as AdditionalUnit;
};

const areAllSubUnitsDatesTheSameAsMainUnit = (
  subUnits: AdditionalUnit[],
  leaseStartDate: Date,
  leaseEndDate: Date | undefined
) => {
  if (isEmpty(subUnits)) {
    return true;
  }

  return subUnits.every((unitLease) => unitLease.startDate === leaseStartDate && unitLease.endDate === leaseEndDate);
};

export const checkAndSetOverlappingEventsAndDatesValuesForSubUnit = (
  subUnits: AdditionalUnit[],
  currentLeaseId: string | undefined,
  leaseStartDate: Date,
  leaseEndDate: Date | undefined,
  getUnit: (id: string) => Unit | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  updateSubUnitsStartDate: boolean
) => {
  const allOverlappingEvents = subUnits.reduce(
    (acc, _currentSubUnit, index) => {
      const isUnitIdAndEndDatePresent = !isNil(subUnits[index].id) && !isNil(subUnits[index].endDate);
      const overlappingEventsMainUnit = isUnitIdAndEndDatePresent
        ? getOverlappingEvents(
            new Date(subUnits[index].startDate).toISOString(),
            new Date(subUnits[index].endDate).toISOString(),
            getUnitEvents(subUnits[index].id!, getUnit)
          )
        : [];

      const overlappingEventsEditUnit = overlappingEventsMainUnit.filter(
        (overlappingEvent) =>
          !overlappingEvent.hasOwnProperty('lease') ||
          (overlappingEvent as UnitLease).lease!.id !== (currentLeaseId ?? '')
      );

      if (!isNilOrEmpty(overlappingEventsEditUnit[0])) {
        acc.push(overlappingEventsEditUnit[0]);
      }

      setFieldValue(
        `subUnits[${index}].overlappingNonEditedEvent`,
        currentLeaseId ? overlappingEventsEditUnit[0] : overlappingEventsMainUnit[0]
      );
      if (subUnits[index].startDate !== leaseStartDate && updateSubUnitsStartDate) {
        setFieldValue(`subUnits[${index}].startDate`, leaseStartDate);
      }
      if (subUnits[index].endDate !== leaseEndDate && updateSubUnitsStartDate) {
        setFieldValue(`subUnits[${index}].endDate`, leaseEndDate);
      }

      return acc;
    },
    [] as (
      | UnitLease
      | UnitEvent
      | BuildingEvent
      | {
          startDate: string;
          endDate: string;
        }
    )[]
  );

  // Check if all dates are the same
  setFieldValue('allDatesAreSame', areAllSubUnitsDatesTheSameAsMainUnit(subUnits, leaseStartDate, leaseEndDate));
  if (!isNilOrEmpty(allOverlappingEvents[0])) {
    setFieldValue('allowedToCheckAllDatesAreSame', false);
  } else {
    setFieldValue('allowedToCheckAllDatesAreSame', true);
  }
};

const AddAdditionalUnits = () => {
  const { values, setFieldValue, setValues } = useFormikContext<AddLeaseFormValues>();
  const { units, unitsLoading, getUnit } = useUnits();
  const { getContact, contactsLoading } = useContacts();
  const { buildings, buildingsLoading } = useBuildings();
  const { formatMessage } = useIntl();
  const { getUnitInventoriesFor } = useUnitInventories();
  const { technics } = useTechnics();
  const { getDocumentCategoryByFileCategory } = useDocumentCategory();

  if (unitsLoading || buildingsLoading || contactsLoading) {
    return <Skeleton />;
  }

  const currentOwner = getContact(values.unitOwnerId!);
  const ownerUnits: Unit[] = !isNil(values.unitOwnerId) ? getUnitsOfOwnerSync(currentOwner!, buildings, units) : [];

  const selectableUnits = ownerUnits.reduce((acc, currentUnit) => {
    if (isNil(values.subUnits.find((subUnit) => subUnit.id === currentUnit.id)) && values.unit.id !== currentUnit.id) {
      acc.push(currentUnit);
    }
    return acc;
  }, [] as Unit[]);

  const onDelete = (index: number) => {
    const newAdditionalUnits = removeIndexFromArray(index, values.subUnits);
    setFieldValue('subUnits', newAdditionalUnits);
    checkAndSetOverlappingEventsAndDatesValuesForSubUnit(
      newAdditionalUnits,
      values.id,
      values.startDate,
      values.endDate,
      getUnit,
      setFieldValue,
      false
    );
  };

  const onChange = async (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (isNil(values.unitOwnerId)) {
      return;
    }
    // If we have no sub units selected so far.
    if (isEmpty(values.subUnits)) {
      const valueToAddToArray = await getSubUnitInitialValues(
        selectableUnits[0].id,
        selectableUnits[0].building!.id,
        selectableUnits[0].type as UnitType,
        selectableUnits[0].advertisedRentalPrice,
        selectableUnits[0].advertisedMonthlyCharges,
        selectableUnits[0].surface,
        values,
        technics,
        getUnitInventoriesFor,
        getDocumentCategoryByFileCategory
      );
      // If there is only one unit for that owner
      if (selectableUnits.length === 1) {
        // Set it by default.
        setFieldValue('subUnits', [valueToAddToArray]);

        // Check with overlapping event
        checkAndSetOverlappingEventsAndDatesValuesForSubUnit(
          [valueToAddToArray],
          values.id,
          values.startDate,
          values.endDate,
          getUnit,
          setFieldValue,
          true
        );

        // If there are multiple units and we just checked
      } else if (checked) {
        // Set an empty selector.
        setFieldValue('subUnits', [{}]);
      }
      // If we have some units seleted and we juste unchecked it
    } else if (!checked) {
      // Remove all additional units
      setValues({ ...values, subUnits: [], allDatesAreSame: true, allowedToCheckAllDatesAreSame: true });
    }
    setFieldValue('additionalUnit', !values.additionalUnit);
  };

  const onAddSubUnitCLick = async () => {
    if (selectableUnits.length === 1) {
      setFieldValue('subUnits', [
        ...values.subUnits,
        await getSubUnitInitialValues(
          selectableUnits[0].id,
          selectableUnits[0].building!.id,
          selectableUnits[0].type as UnitType,
          selectableUnits[0].advertisedRentalPrice,
          selectableUnits[0].advertisedMonthlyCharges,
          selectableUnits[0].surface,
          values,
          technics,
          getUnitInventoriesFor,
          getDocumentCategoryByFileCategory
        ),
      ]);
    } else {
      setFieldValue('subUnits', [...values.subUnits, {}]);
    }
  };

  // Let's not show the add unit button if we have as many sub units fields as the owner has sub units.
  // We do not want the use to have addes 20 units if the owner only has 5 units.
  // ownerUnits.length - 1 since the main unit does not count as a unit.
  const shouldAddSubunitButtonBeShown = ownerUnits.length - 1 !== values.subUnits.length && values.additionalUnit;

  return (
    <>
      <Grid style={{ display: 'flex', alignItems: 'center', paddingRight: 20 }}>
        <FormHeader
          title={formatMessage({
            id: 'lease.addLease.additionalUnits',
          })}
        />
        <CustomizedSwitch
          disabled={selectableUnits.length === 0 && values.subUnits.length === 0}
          checked={isNil(values.unitOwnerId) ? false : values.additionalUnit}
          onChange={onChange}
          dataTest="addAdditionalUnitSwitch"
        />
      </Grid>
      <ConditionalParentElement
        parentElement={!isNil(values.unitOwnerId)}
        // eslint-disable-next-line react/no-unstable-nested-components
        wrapper={(child: React.ReactNode) => <Collapse in={values.additionalUnit}>{child}</Collapse>}
      >
        {!isNil(values.unitOwnerId) && <Divider style={{ marginBottom: 10 }} />}
        {values.subUnits.map((subUnit, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={`${index} ${subUnit.id}`}>
              {!isNil(values.unitOwnerId) && (
                <AdditionalUnitSelectorField
                  index={index}
                  onDelete={onDelete}
                  id={subUnit.id}
                  units={selectableUnits}
                />
              )}
            </Grid>
          );
        })}

        {shouldAddSubunitButtonBeShown && (
          <Grid style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: 30, marginTop: 20 }}>
            <Button style={{ color: Colors.BLUE_GREY }} onClick={onAddSubUnitCLick}>
              <Addicon fill={Colors.BLUE_GREY} />{' '}
              <span style={{ marginLeft: 10 }}>{formatMessage({ id: 'lease.addLease.additionalUnit' })}</span>
            </Button>
          </Grid>
        )}
        {shouldAddSubunitButtonBeShown && isBefore(values.startDate, new Date()) && !isToday(values.startDate) && (
          <Grid style={{ marginTop: 10 }}>
            <Divider style={{ marginBottom: 10, marginTop: 10 }} />
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: 30,
                marginTop: 20,
                paddingRight: 20,
              }}
            >
              <Typography variant="h6" style={{ fontSize: 12, textAlign: 'left' }}>
                {formatMessage({ id: 'lease.addLease.signatureStartDateAndEndDateAreSame' })}
              </Typography>
              <CustomizedSwitch
                checked={values.allowedToCheckAllDatesAreSame ? values.allDatesAreSame : false}
                onChange={() => {
                  if (values.allowedToCheckAllDatesAreSame) setFieldValue('allDatesAreSame', !values.allDatesAreSame);
                }}
                disabled={!values.allowedToCheckAllDatesAreSame}
              />
            </Grid>
          </Grid>
        )}
      </ConditionalParentElement>
    </>
  );
};

export default AddAdditionalUnits;
