/* eslint-disable @typescript-eslint/no-shadow */
import { Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import {
  Building,
  Colors,
  ContactType,
  isAfterOrEqual,
  isDateValid,
  Lease,
  LeaseStatus,
  NotificationCreation,
  NotificationStatus,
  NotificationType,
  Technic,
  TechnicType,
  Unit,
  UnitLease,
} from '@rentguru/commons-utils';
import { ActionButton, ContentHeader, LoaderButton, TextDetailEditable } from '@up2rent/ui';
import { format } from 'date-fns';
import { Form, Formik, FormikHelpers } from 'formik';
import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';
import FormikDatePicker from 'src/components/ui/FormikDatePicker';
import NestedSelector, { NestedElement } from 'src/components/ui/NestedSelector/NestedSelector';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { LeaseExtended, useLeases } from 'src/hooks/LeasesContext';
import { useNotifications } from 'src/hooks/NotificationsContext';
import { useTechnics } from 'src/hooks/TechnicsContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { dateLocaleMap, useLocale } from 'src/i18n/IntlProviderWrapper';
import { ReactComponent as BuildingIcon } from 'src/icons/building.svg';
import { ReactComponent as ContactIcon } from 'src/icons/contact.svg';
import { ReactComponent as HomeIcon } from 'src/icons/home.svg';
import * as Yup from 'yup';

const AddNotificationSchema = Yup.object().shape({
  type: Yup.string(),
  message: Yup.string().min(1).max(50),
  popupDate: Yup.string().min(0).required(),
  expirationDate: Yup.string(),
  status: Yup.string(),
  createdAt: Yup.string(),
  building: Yup.object().nullable(),
  unit: Yup.object().nullable(),
  lease: Yup.object().nullable(),
  technic: Yup.object().nullable(),
});

interface AddNotificationValues {
  message: string;
  popupDate: Date;
  building: Building | null;
  unit: Unit | null;
  lease: Lease | null;
  technic: Technic | null;
}
interface AddNotificationProps {
  afterSubmit: () => void;
}

export interface SearchButtons {
  ButtonIcon?: React.ReactNode;
  buttonType: DataTypes;
}

export enum DataTypes { // Add new types as you want.
  BUILDING = 'BUILDING',
  UNIT = 'UNIT',
  TENANT = 'TENANT',
  TECHNIC = 'TECHNIC',
  LEASE = 'LEASE',
}

const fromStatusToValue = {
  [LeaseStatus.Active]: 5,
  [LeaseStatus.OutForSignature]: 4,
  [LeaseStatus.Draft]: 3,
  [LeaseStatus.Ended]: 2,
  [LeaseStatus.Cancelled]: 1,
  [LeaseStatus.Rejected]: 0,
};

const sortLease = (lease1: UnitLease, lease2: UnitLease): number => {
  if (fromStatusToValue[lease1.lease?.status as LeaseStatus] < fromStatusToValue[lease2.lease?.status as LeaseStatus])
    return 1;
  if (fromStatusToValue[lease1.lease?.status as LeaseStatus] > fromStatusToValue[lease2.lease?.status as LeaseStatus])
    return -1;
  return 0;
};

const getTechnicNestedElement = (
  technic: Technic,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatMessage: (value: any) => string,
  language: string,
  getBuilding: (id: string) => Building | undefined
): Omit<NestedElement, 'value' | 'type'> => {
  const building = getBuilding(technic.building?.id ?? '');
  if (technic.type === TechnicType.CHIMNEY) {
    return {
      primaryLabel: formatMessage({ id: `enums.TechnicType.${TechnicType.CHIMNEY}` }),
      secondaryLabel: `${formatMessage({ id: 'technic.lastControlledDate' })}: ${format(
        new Date(technic.chimneyLastControlDate as string),
        'yyyy/MM/dd',
        { locale: dateLocaleMap[language] }
      )}`,
    };
  }
  if (technic.type === TechnicType.DETECTOR) {
    return {
      primaryLabel: formatMessage({ id: `enums.TechnicType.${TechnicType.DETECTOR}` }),
      secondaryLabel: `${
        technic.detectorBrand ?? technic.detectorSerialNumber ?? technic.detectorLastControlDate
          ? `${formatMessage({ id: 'technic.lastControlledDate' })}: ${format(
              new Date(technic.detectorLastControlDate as string),
              'dd/MM/yyy',
              { locale: dateLocaleMap[language] }
            )}`
          : !isNil(building)
          ? building.address?.street
          : ''
      }`,
    };
  }
  if (technic.type === TechnicType.FUELTANK) {
    return {
      primaryLabel: formatMessage({ id: `enums.TechnicType.${TechnicType.FUELTANK}` }),
      secondaryLabel: `${
        technic.tankLastControlDate ?? technic.tankInstallationDate ?? technic.tankLastControlDate
          ? `${formatMessage({ id: 'technic.lastControlledDate' })}: ${format(
              new Date(technic.tankLastControlDate as string),
              'yyyy/MM/dd',
              { locale: dateLocaleMap[language] }
            )}`
          : !isNil(building)
          ? building.address?.street
          : ''
      }`,
    };
  }
  if (technic.type === TechnicType.HEATING) {
    return {
      primaryLabel: formatMessage({ id: `enums.TechnicType.${TechnicType.HEATING}` }),
      secondaryLabel: `${
        technic.heatingBrand ?? technic.heatingLastControlDate
          ? `${formatMessage({ id: 'technic.lastControlledDate' })}: ${format(
              new Date(technic.heatingLastControlDate as string),
              'dd/MM/yyy',
              { locale: dateLocaleMap[language] }
            )}`
          : technic.heatingInstallationDate
          ? `${formatMessage({ id: 'technic.heatingTab.heatingInstallationDate' })}: ${format(
              new Date(technic.heatingLastControlDate as string),
              'dd/MM/yyy',
              { locale: dateLocaleMap[language] }
            )}`
          : !isNil(building)
          ? building.address?.street
          : ''
      }`,
    };
  }
  if (technic.type === TechnicType.PEB) {
    return {
      primaryLabel: formatMessage({ id: `enums.TechnicType.${TechnicType.PEB}` }),
      secondaryLabel: `${
        technic.PebEnergeticPerformance ?? technic.PebReportNumber ?? technic.PebValidityDate
          ? `${formatMessage({ id: 'technic.epbTab.epbValidityDate' })}: ${format(
              new Date(technic.PebValidityDate as string),
              'dd/MM/yyy',
              { locale: dateLocaleMap[language] }
            )}`
          : !isNil(building)
          ? building.address?.street
          : ''
      }`,
    };
  }
  if (technic.type === TechnicType.UTILITY_PROVIDER) {
    return {
      primaryLabel: formatMessage({ id: `enums.TechnicType.${TechnicType.UTILITY_PROVIDER}` }),
      secondaryLabel: `${
        technic.utilityType
          ? formatMessage({ id: `enums.UtilityType.${technic.utilityType}` })
          : technic.utilityEanCode ?? technic.utilityMeterNumber ?? !isNil(building)
          ? building!.address?.street
          : ''
      }`,
    };
  }
  return { primaryLabel: '' };
};

const getCorrectTechnics = (
  technics: Technic[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatMessage: (value: any) => string,
  language: string,
  getLease: (id: string) => LeaseExtended | null | undefined,
  getBuilding: (id: string) => Building | undefined,
  optionnalBuilding?: Building,
  optionnalUnit?: Unit
) => {
  let correctTechnics: Technic[] = [];
  const leases: UnitLease[] = optionnalUnit?.leases as UnitLease[];

  if (!isNil(optionnalBuilding)) {
    correctTechnics = technics.filter(
      (technic) => !isNil(technic.building) && technic.building.id === optionnalBuilding.id
    );
  } else if (!isNil(optionnalUnit)) {
    correctTechnics = technics.filter((technic) => !isNil(technic.unit) && technic.unit.id === optionnalUnit.id);
  }

  const children: NestedElement[] = correctTechnics.map((technic) => {
    const child: NestedElement = {
      ...getTechnicNestedElement(technic, formatMessage, language, getBuilding),
      group: 1,
      selectable: true,
      value: { building: optionnalBuilding ?? optionnalUnit!.building, unit: optionnalUnit, technic },
      type: DataTypes.TECHNIC,
    };
    return child;
  });

  if (!isNil(leases)) {
    const filteredLeases = leases.sort(sortLease);
    filteredLeases.forEach((lease) => {
      const correctLease = getLease(lease.lease?.id as string);
      const tenants = correctLease?.contacts?.filter((contact) => contact.contactRole === ContactType.TENANT);
      tenants?.forEach((tenant) => {
        children.push({
          primaryLabel:
            !isNil(tenant.contact?.firstName) && !isNil(tenant.contact?.lastName)
              ? `${tenant.contact?.firstName} - ${tenant.contact?.lastName}`
              : `${correctLease?.status}`,
          secondaryLabel: correctLease?.name,
          group: 2,
          selectable: true,
          value: { building: optionnalBuilding ?? optionnalUnit!.building, unit: optionnalUnit, lease },
          type: DataTypes.TENANT,
        });
      });
    });
  }

  return children;
};

const getUnitsAccordingToBuilding = (
  building: Building,
  units: Unit[],
  technics: Technic[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatMessage: (value: any) => string,
  language: string,
  getBuilding: (id: string) => Building | undefined,
  getLease: (id: string) => LeaseExtended | null | undefined
): NestedElement[] => {
  units = units.filter((unit) => unit.building?.id === building.id);

  const children: NestedElement[] = [
    {
      primaryLabel: building.name,
      secondaryLabel: building.address!.street,
      group: 1,
      value: { building, unit: undefined, technic: undefined },
      children: getCorrectTechnics(technics, formatMessage, language, getLease, getBuilding, building),
      selectable: true,
      FrontIcon: <BuildingIcon fill="#546E79" />,
      type: DataTypes.BUILDING,
    },
  ];

  units.forEach((unit) => {
    const child: NestedElement = {
      primaryLabel: unit.name,
      secondaryLabel: building.name,
      value: { building, unit, technic: undefined },
      group: 2,
      children: getCorrectTechnics(technics, formatMessage, language, getLease, getBuilding, undefined, unit),
      selectable: true,
      FrontIcon: <HomeIcon fill="#546E79" />,
      type: DataTypes.UNIT,
    };

    // if (!isEmpty(child.children))
    children.push(child);
  });

  return children;
};

const buildDataStructureForNestedSelector = (
  buildings: Building[],
  units: Unit[],
  technics: Technic[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatMessage: (value: any) => string,
  language: string,
  getBuilding: (id: string) => Building | undefined,
  getLease: (id: string) => LeaseExtended | null | undefined
): NestedElement[] => {
  const data: NestedElement[] = buildings.map((building) => {
    const nestedElement: NestedElement = {
      primaryLabel: building.name,
      secondaryLabel: building.address!.street,
      group: 1,
      value: { building, unit: undefined, technic: undefined },
      children: getUnitsAccordingToBuilding(building, units, technics, formatMessage, language, getBuilding, getLease),
      selectable: true,
      FrontIcon: <BuildingIcon fill="#546E79" />,
      type: DataTypes.BUILDING,
    };

    return nestedElement;
  });

  return data;
};

const AddNotification: React.FC<AddNotificationProps> = ({ afterSubmit }) => {
  const [selectedSearchType, setSelectedSearchType] = useState<DataTypes>(DataTypes.TENANT);
  const { formatMessage } = useIntl();
  const { createNotification } = useNotifications();
  const [nestedSelectorValue, setNestedSelectorValue] = useState<NestedElement | null>(null);
  const { buildings } = useBuildings();
  const { units } = useUnits();
  const { technicsWithoutLeasesLink: technics } = useTechnics();
  const { language } = useLocale();
  const { getBuilding } = useBuildings();
  const { getLease } = useLeases();

  const searchButtonsList: SearchButtons[] = [
    {
      buttonType: DataTypes.BUILDING,
      ButtonIcon: (
        <BuildingIcon fill={selectedSearchType === DataTypes.BUILDING ? Colors.CARNATION_RED : Colors.SLATE_GREY} />
      ),
    },
    {
      buttonType: DataTypes.UNIT,
      ButtonIcon: <HomeIcon fill={selectedSearchType === DataTypes.UNIT ? Colors.CARNATION_RED : Colors.SLATE_GREY} />,
    },
    {
      buttonType: DataTypes.TENANT,
      ButtonIcon: (
        <ContactIcon fill={selectedSearchType === DataTypes.TENANT ? Colors.CARNATION_RED : Colors.SLATE_GREY} />
      ),
    },
  ];

  const data = buildDataStructureForNestedSelector(
    buildings,
    units,
    technics,
    formatMessage,
    language,
    getBuilding,
    getLease
  );
  const handleAfterSubmit = () => {
    if (!isNil(afterSubmit)) {
      afterSubmit();
    }
  };

  const minimumPopupDate = new Date();

  const handleCreate = async (
    values: AddNotificationValues,
    { setSubmitting, setStatus }: FormikHelpers<AddNotificationValues>
  ) => {
    const { lease, popupDate } = values;
    if (isDateValid(popupDate) && isAfterOrEqual(popupDate, minimumPopupDate)) {
      const newNotification = {
        createdBy: NotificationCreation.Manual,
        message: values.message,
        popupDate: popupDate.toJSON(),
        createdAt: new Date().toJSON(),
        status: NotificationStatus.Active,
        type: NotificationType.Custom,
        ...(!isNil(nestedSelectorValue?.value.technic) && {
          technicId: nestedSelectorValue?.value.technic.id,
        }),
        ...(isNil(nestedSelectorValue?.value.technic) &&
          !isNil(nestedSelectorValue?.value.unit) && { unitId: nestedSelectorValue?.value.unit.id }),
        ...(isNil(nestedSelectorValue?.value.technic) &&
          isNil(nestedSelectorValue?.value.unit) &&
          !isNil(nestedSelectorValue?.value.building) && {
            buildingId: nestedSelectorValue?.value.building.id,
          }),
        ...(isNil(nestedSelectorValue?.value.technic) && !isNil(lease) && { leaseId: lease.id }),
      };
      await createNotification(newNotification);

      handleAfterSubmit();
      setStatus(true);
    }
    setSubmitting(false);
  };

  const initialValues: AddNotificationValues = {
    message: '',
    popupDate: minimumPopupDate,
    building: null,
    unit: null,
    lease: null,
    technic: null,
  };

  return (
    <>
      <ContentHeader title={formatMessage({ id: 'dashboard.notifications.addNotification' })} />
      <Formik
        initialValues={initialValues}
        validationSchema={AddNotificationSchema}
        onSubmit={handleCreate}
        validateOnChange
      >
        {({ values, errors, touched, isSubmitting, status }) => {
          return (
            <Form style={{ width: 600 }}>
              <div
                style={{
                  marginLeft: 30,
                  marginRight: 30,
                  display: 'flex',
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <TextDetailEditable
                      title={formatMessage({ id: 'dashboard.notifications.message' })}
                      editMode={true}
                      name="message"
                      type="text"
                      noMaxWidth
                      multiline
                      rows={4}
                      rowsMax={8}
                      error={Boolean(errors.message && touched.message)}
                      style={{ padding: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs={6}>
                      <NestedSelector
                        datas={data}
                        onChange={setNestedSelectorValue}
                        value={nestedSelectorValue}
                        selectorLabel={formatMessage({ id: 'dashboard.nestedComponent.title' })}
                        searchButtons={searchButtonsList}
                        setSelectedSearchType={setSelectedSearchType}
                        selectedSearchType={selectedSearchType}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <FormikDatePicker
                        name="popupDate"
                        label={formatMessage({ id: 'dashboard.notifications.popupDate' })}
                        value={values.popupDate}
                        style={{ width: 260, marginBottom: 16 }}
                        required
                        minDate={minimumPopupDate}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginLeft: 30,
                  marginBottom: 20,
                  marginRight: 30,
                }}
              >
                <ActionButton
                  onMouseDown={stopPropagation}
                  onClick={handleAfterSubmit}
                  style={{
                    background: 'none',
                    color: Colors.DARK_SLATE_GREY,
                    marginRight: 20,
                  }}
                >
                  {toUpper(
                    formatMessage({
                      id: 'contact.addContact.cancelLabel',
                    })
                  )}
                </ActionButton>
                <LoaderButton loading={isSubmitting} success={status}>
                  {formatMessage({
                    id: 'add',
                  })}
                </LoaderButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddNotification;
