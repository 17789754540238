import {
  type FormControlProps,
  type SelectProps,
  FilledInput,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import { styled, withStyles } from '@material-ui/core/styles';
import { Colors } from '@rentguru/commons-utils';
import React, { CSSProperties, SVGProps } from 'react';

const MyFilledInput = withStyles({
  root: {
    '&$disabled:before': {
      borderBottomStyle: 'hidden',
    },
  },
  disabled: {
    backgroundColor: Colors.PORCELAIN_GREY_1,
  },
  underline: {
    '&:before': {
      borderBottom: `2px solid ${Colors.TOWER_GREY}`,
    },
  },
})(FilledInput);

const MyInputLabel = styled(InputLabel)({
  color: Colors.LIGHT_BLUE_GREY,
});

const MySelect = withStyles({
  icon: {
    fill: Colors.BLUEY,
  },
})(Select);

export interface CustomizedSelectProps {
  error?: boolean;
  label?: string;
  fieldName?: string;
  value: unknown;
  onChange?: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange?: (event: React.ChangeEvent<any>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (e: any) => void;
  FormControlProps?: Partial<FormControlProps>;
  SelectProps?: Partial<SelectProps>;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  className?: any;
  multiple?: boolean;
  margin?: 'normal' | 'dense';
  id?: string;
  dataTest?: string;
  inputLabelShrink?: boolean;
  style?: CSSProperties;
  children?: React.ReactNode;
  IconComponent?: React.ElementType<SVGProps<SVGSVGElement>, keyof React.JSX.IntrinsicElements>;
}

export const CustomizedSelect: React.FC<CustomizedSelectProps> = ({
  children,
  error,
  label,
  fieldName,
  onChange,
  handleChange,
  onBlur,
  FormControlProps = {},
  SelectProps = {},
  value,
  disabled = false,
  className,
  multiple = false,
  margin = 'dense',
  id,
  inputLabelShrink,
  style,
  dataTest = 'customizedSelect',
  IconComponent,
}) => (
  <FormControl
    id={id}
    variant="filled"
    margin={margin}
    component="div"
    error={error}
    disabled={disabled}
    className={className}
    data-test={dataTest}
    {...(onBlur && { onBlur })}
    {...FormControlProps}
  >
    <MyInputLabel {...(inputLabelShrink ? { shrink: true } : {})} htmlFor={fieldName}>
      {label}
    </MyInputLabel>
    <MySelect
      value={value}
      onChange={
        onChange
          ? (e) => {
              onChange(e.target.value as string);
            }
          : handleChange
      }
      input={<MyFilledInput name={fieldName} id={fieldName} />}
      {...SelectProps}
      IconComponent={IconComponent}
      multiple={multiple}
      style={style}
    >
      {children}
    </MySelect>
  </FormControl>
);
