/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
import { toUpper } from 'lodash';
import { Language } from '@rentguru/commons-utils';

const useStyles = makeStyles({
  tabStyle: {
    fontWeight: 400,
    textTransform: 'none', // Not in uppercase!!
    minWidth: 50,
  },
});

interface LanguageMenuProps {
  setLanguage: (language: Language) => void;
  selectedLanguage?: string;
}

const LanguageMenu: React.FC<LanguageMenuProps> = ({ setLanguage, selectedLanguage }) => {
  const [selectedTab, setSelectedTab] = useState<Language>((selectedLanguage as Language) ?? Language.EN);
  const handleTabChange = (_: React.ChangeEvent<{}>, newTab: Language) => {
    setSelectedTab(newTab);
    setLanguage(newTab);
  };
  const classes = useStyles();
  const { formatMessage } = useIntl();
  return (
    <Tabs
      TabIndicatorProps={{ style: { display: 'none' } }}
      value={selectedTab}
      onChange={handleTabChange}
      variant="standard"
    >
      <Tab
        className={classes.tabStyle}
        label={formatMessage({ id: `enums.LeaseLanguage.${toUpper(Language.EN)}` })}
        value={Language.EN}
        id="enTabLanguage"
      />
      <Tab
        className={classes.tabStyle}
        label={formatMessage({ id: `enums.LeaseLanguage.${toUpper(Language.FR)}` })}
        value={Language.FR}
        id="frTabLanguage"
      />
      <Tab
        className={classes.tabStyle}
        label={formatMessage({ id: `enums.LeaseLanguage.${toUpper(Language.NL)}` })}
        value={Language.NL}
        id="nlTabLanguage"
      />
    </Tabs>
  );
};

export default LanguageMenu;
