import { Divider, Grid, MenuItem, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Colors,
  CommunicationSettingsProfile,
  DetectorType,
  LeaseStatus,
  Technic,
  TechnicType,
  UnitInventory,
} from '@rentguru/commons-utils';
import { ActionButton, LoaderButton, TextDetailEditable } from '@up2rent/ui';
import { Form, Formik, FormikHelpers } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import FormikCustomizedSelect from 'src/components/ui/FormikCustomizedSelect';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';
import { useLeases } from 'src/hooks/LeasesContext';
import * as Yup from 'yup';
import { useBuildings } from '../../../hooks/BuildingsContext';
import { useTechnics } from '../../../hooks/TechnicsContext';
import { useUnitInventories } from '../../../hooks/UnitInventoryContext';
import { useUnits } from '../../../hooks/UnitsContext';
import FormikDatePicker from '../../ui/FormikDatePicker';
import UnitInventorySelectorField from '../../ui/Forms/FormField/UnitInventorySelectorField';
import { includeComplementaryDataSchema } from '../AddTechnicDialog';
import AddTechnicDialogComplementaryData, {
  AddTechnicDialogComplementaryDataFormValues,
} from '../AddTechnicDialogComplementaryData';
import { getLinks } from '../Heatings/AddHeatingForm';
import useTechnicsUtils from '../useTechnicsUtils';

interface AddDetectorProps {
  id: string;
  entity: 'building' | 'unit' | 'leaseBuilding' | 'leaseUnit';
  unitInventories?: UnitInventory[];
  afterSubmit?: ((id: string) => void) | ((technic: Omit<Technic, 'id' | 'clientId' | 'readId'>[]) => void);
  cancelSubmit?: () => void;
  showTitle?: boolean;
  showComplementaryData?: boolean;
  shouldRedirectAfterCreate?: boolean;
}

const DetectorSchema = Yup.object().shape({
  detectorType: Yup.string().required(),
  detectorSerialNumber: Yup.string().required(),
  detectorBrand: Yup.string().required(),
  detectorLastControlDate: Yup.date().required(),
  detectorUnitInventoryId: Yup.string(),
});

export interface DetectorInitialValuesType extends AddTechnicDialogComplementaryDataFormValues {
  detectorType: DetectorType;
  detectorSerialNumber: string;
  detectorBrand: string;
  detectorLastControlDate: Date;
  detectorUnitInventoryId: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& > *': {
        width: 280,
      },
    },
  })
);

const AddDetector: React.FC<AddDetectorProps> = ({
  id,
  entity,
  unitInventories,
  afterSubmit,
  cancelSubmit,
  showTitle = true,
  showComplementaryData = false,
  shouldRedirectAfterCreate = false,
}) => {
  const { formatMessage } = useIntl();
  const { createTechnic } = useTechnics();
  const { getUnit } = useUnits();
  const { getBuilding } = useBuildings();
  const { getUnitInventoriesFor } = useUnitInventories();
  const { getLease } = useLeases();
  const { getPropertyId } = useTechnicsUtils();
  const { getDefaultTechnicCommunicationSettingsProfiles, loading: communicationSettingsProfilesLoading } =
    useCommunicationSettingsProfiles();
  const history = useHistory();
  const classes = useStyles();
  const [technicCommunicationSettingsProfiles, setTechnicCommunicationSettingsProfiles] = useState<
    CommunicationSettingsProfile[]
  >([]);

  useEffect(() => {
    if (communicationSettingsProfilesLoading) {
      return;
    }
    const technicProfiles = getDefaultTechnicCommunicationSettingsProfiles();
    setTechnicCommunicationSettingsProfiles(technicProfiles);
  }, [getDefaultTechnicCommunicationSettingsProfiles, communicationSettingsProfilesLoading]);

  if (communicationSettingsProfilesLoading || isEmpty(technicCommunicationSettingsProfiles)) {
    return (
      <Grid container style={{ justifyContent: 'center' }}>
        <Typography style={{ margin: 20 }}>{formatMessage({ id: 'loading' })}</Typography>
      </Grid>
    );
  }

  // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
  const defaultTechnicProfile =
    !isEmpty(technicCommunicationSettingsProfiles) && technicCommunicationSettingsProfiles[0];

  let unitInventoryArray: UnitInventory[] = [];
  if (id && entity === 'unit') unitInventoryArray = getUnitInventoriesFor(id, new Date());
  else if (!isNil(unitInventories) && entity.startsWith('lease')) unitInventoryArray = unitInventories;

  const handleAfterSubmit = (technic: Omit<Technic, 'id' | 'clientId' | 'readId'>[]) => {
    if (!isNil(afterSubmit)) {
      if (!entity.startsWith('lease')) (afterSubmit as (idAfterSubmit: string) => void)(id);
      else (afterSubmit as (technicAfterSubmit: Omit<Technic, 'id' | 'clientId' | 'readId'>[]) => void)(technic);
    }
  };
  const handleCancel = () => {
    if (!isNil(cancelSubmit)) {
      cancelSubmit();
    }
  };

  const handleDetectorCreate = async (
    values: DetectorInitialValuesType,
    { setSubmitting, setStatus }: FormikHelpers<DetectorInitialValuesType>
  ) => {
    let createdTechnics: Technic[] = [];

    const unit = getUnit(values.unitId ?? '');
    const building = getBuilding(unit?.building?.id ?? '');
    const buildingId = building?.id;
    const leasesOfUnit = (unit?.leases ?? []).map((lease) => {
      return getLease(lease?.lease?.id ?? '');
    });
    const activeLease = leasesOfUnit.find((lease) => lease?.status === LeaseStatus.Active);
    const technics = [
      {
        id: '',
        type: TechnicType.DETECTOR,
        detectorType: values.detectorType,
        detectorSerialNumber: values.detectorSerialNumber,
        detectorBrand: values.detectorBrand,
        detectorLastControlDate: values.detectorLastControlDate.toJSON(),
        // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
        communicationSettingsProfileId: defaultTechnicProfile ? defaultTechnicProfile.id : 'mockId',

        ...(!isEmpty(values.detectorUnitInventoryId) && { detectorUnitInventoryId: values.detectorUnitInventoryId }),
        ...getLinks(values, id, entity, buildingId ?? ''),
      },
      // if the detector should be added on the unit and the unit has an active lease
      ...(!values.onBuilding && !isNil(activeLease) && !entity.startsWith('lease')
        ? [
            {
              id: '',
              type: TechnicType.DETECTOR,
              detectorType: values.detectorType,
              detectorSerialNumber: values.detectorSerialNumber,
              detectorBrand: values.detectorBrand,
              detectorLastControlDate: values.detectorLastControlDate.toJSON(),
              leaseId: activeLease.id,
              unitId: unit?.id ?? '',
              // TO UPDATE IN COMMUNICATION V2 NEXT TICKETS
              communicationSettingsProfileId: defaultTechnicProfile ? defaultTechnicProfile.id : 'mockId',

              ...(!isEmpty(values.detectorUnitInventoryId) && {
                detectorUnitInventoryId: values.detectorUnitInventoryId,
              }),
            },
          ]
        : []),
    ];
    if (entity === 'unit' || entity === 'building') {
      createdTechnics = await Promise.all(technics.map((technic) => createTechnic(technic)));
    }
    setStatus(true);
    setSubmitting(false);
    handleAfterSubmit(technics);
    if (shouldRedirectAfterCreate) {
      const currentUnit = getUnit(values.onBuilding ? '' : values.unitId ?? '');
      const unitId = `unit-${currentUnit?.id}`;
      history.push({
        pathname: `${RouteDestination.PROPERTIES}/${getPropertyId()}/${!isNil(currentUnit) ? unitId : 'technics'}/${
          !isNil(currentUnit) ? 'technics' : ''
        }`,
        state: { id: createdTechnics?.[0].id ?? '', defaultTab: TechnicType.DETECTOR },
      });
    }
  };

  const finalSchema = includeComplementaryDataSchema(DetectorSchema, showComplementaryData);

  return (
    <Formik
      initialValues={
        {
          detectorType: DetectorType.SMOKE as DetectorType,
          detectorSerialNumber: '',
          detectorBrand: '',
          detectorLastControlDate: new Date(),
          detectorUnitInventoryId: '',
          onBuilding: ['building', 'leaseBuilding'].includes(entity),
          unitId: ['unit', 'leaseUnit'].includes(entity) ? id : '',
        } as DetectorInitialValuesType
      }
      validationSchema={finalSchema}
      onSubmit={handleDetectorCreate}
    >
      {({ values, errors, touched, isSubmitting, status }) => (
        <Form>
          {showTitle && (
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 25, marginBottom: 25 }}>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  textAlign: 'left',
                }}
              >
                {formatMessage({ id: 'technic.detectorTab.addDetector' })}
              </Typography>
            </div>
          )}
          <Divider style={{ marginBottom: 10 }} />
          <div style={{ alignItems: 'baseline', marginLeft: 20, marginRight: 20 }}>
            {showComplementaryData && (
              <AddTechnicDialogComplementaryData messageId="lease.detail.action.addDetectorExample" />
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormikCustomizedSelect
                label={formatMessage({
                  id: 'technic.detectorTab.detectorType',
                })}
                fieldName="detectorType"
                value={values.detectorType}
                FormControlProps={{ required: true }}
                className={classes.root}
                error={Boolean(errors.detectorType && touched.detectorType)}
              >
                <MenuItem id="SMOKE" key="SMOKE" value={DetectorType.SMOKE}>
                  {formatMessage({ id: 'technic.detectorTab.smoke' })}
                </MenuItem>
                <MenuItem id="CO2" key="CO2" value={DetectorType.CO2}>
                  {formatMessage({ id: 'technic.detectorTab.co2' })}
                </MenuItem>
              </FormikCustomizedSelect>

              <TextDetailEditable
                name="detectorSerialNumber"
                title={formatMessage({ id: 'technic.detectorTab.detectorSerialNumber' })}
                type="text"
                text={values.detectorSerialNumber}
                editMode={true}
                error={Boolean(errors.detectorSerialNumber && touched.detectorSerialNumber)}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextDetailEditable
                name="detectorBrand"
                title={formatMessage({ id: 'technic.detectorTab.detectorBrand' })}
                type="text"
                text={values.detectorBrand}
                editMode={true}
                error={Boolean(errors.detectorBrand && touched.detectorBrand)}
              />
              <FormikDatePicker
                name="detectorLastControlDate"
                label={formatMessage({ id: 'technic.detectorTab.detectorLastControlDate' })}
                value={values.detectorLastControlDate}
                style={{ width: 280 }}
                error={Boolean(errors.detectorLastControlDate && touched.detectorLastControlDate)}
              />
            </div>
            {entity !== 'building' && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <UnitInventorySelectorField
                  unitsInventory={unitInventoryArray}
                  fieldName="detectorUnitInventoryId"
                  inputStyle={{ width: 280 }}
                />
              </div>
            )}
          </div>

          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <div
            style={{
              marginBottom: 20,
              marginRight: 30,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <ActionButton
              onClick={() => {
                handleCancel();
              }}
              style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
            >
              {toUpper(formatMessage({ id: 'cancel' }))}
            </ActionButton>
            <LoaderButton loading={isSubmitting} success={status}>
              {formatMessage({
                id: 'save',
              })}
            </LoaderButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddDetector;
