/* eslint-disable @typescript-eslint/no-shadow */
import {
  Charge,
  ChargeDTO,
  ChargeStatus,
  CreateChargeInput,
  CreateChargeMutationVariables,
  DeleteChargeMutationVariables as DeleteMutationVariables,
  Model,
  MutationStatus,
  OnCreateChargeSubscription,
  OnDeleteChargeSubscription,
  OnUpdateChargeSubscription,
  ProcessOcrMutationVariables,
  UpdateChargeInput,
  cleanInputCreate,
  cleanInputUpdate,
  createCharge as createMutation,
  deleteCharge as deleteMutation,
  getChargesFromDto,
  getCharge as getQuery,
  getReadId,
  getTableClientId,
  onCreateCharge,
  onDeleteCharge,
  onUpdateCharge,
  processOcr as processOcrMutation,
  syncCharges,
  updateCharge as updateMutation,
} from '@rentguru/commons-utils';
import {
  NUMBER_OF_MINUTES_FOR_REFETCH,
  deleteEntityWithFetchBefore,
  getFilterFieldNameForIndex,
  list,
  mutation,
  useSubscriptions,
} from '@up2rent/fetch-utils';
import { differenceInMinutes } from 'date-fns';
import { isNil } from 'lodash';
import React, { Reducer, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { useUser } from './UserContext';
import { syncCharges as syncChargesForFolders } from './utils/cleanedQueries';

const ENTITY_MODEL_NAME: Model = 'Charge';

export interface ChargeContext extends ChargeState {
  chargesInbox: Charge[];
  chargesToAffect: Charge[];
  chargesArchived: Charge[];
  processOcr: (chargeId: string) => Promise<MutationStatus>;
  getCharge: (id: string) => Charge | undefined;
  createCharge: (input: Omit<CreateChargeInput, 'clientId' | 'readId'>) => Promise<Charge>;
  updateCharge: (updates: UpdateChargeInput) => Promise<Charge>;
  deleteCharge: (charge: Charge) => Promise<Charge>;
  fetchAndSetCharges: () => Promise<void>;
  setFetchCharges: () => void;
}

interface ChargeState {
  charges: Charge[] | null;
  error: string | undefined;
  chargesLoading: boolean;
  shouldFetch: boolean;
  lastFetch: Date | null;
}

export type Action =
  | {
      type: 'SHOULD_FETCH' | 'IS_FETCHING';
    }
  | {
      type: 'ADD_CHARGE';
      payload: { charge: Charge };
    }
  | {
      type: 'FETCHED';
      payload: { charges: Charge[] };
    }
  | {
      type: 'UPDATE_CHARGE';
      payload: { charge: Charge };
    }
  | {
      type: 'DELETE_CHARGE';
      payload: { id: string };
    };

const classifyCharges = (
  charges: Charge[]
): Pick<ChargeContext, 'chargesInbox' | 'chargesToAffect' | 'chargesArchived'> => {
  return charges.reduce(
    (acc: { chargesInbox: Charge[]; chargesToAffect: Charge[]; chargesArchived: Charge[] }, charge) => {
      if (!isNil(charge)) {
        if (charge.status === ChargeStatus.AFFECTED) {
          acc.chargesArchived.push(charge);
        } else if (charge.status === ChargeStatus.TO_AFFECT) {
          acc.chargesToAffect.push(charge);
        } else {
          acc.chargesInbox.push(charge);
        }
      }
      return acc;
    },
    { chargesInbox: [], chargesToAffect: [], chargesArchived: [] }
  );
};

const initialState: ChargeState = {
  chargesLoading: false,
  error: undefined,
  charges: null,
  shouldFetch: false,
  lastFetch: null,
};

export const chargeReducer = (state: ChargeState, action: Action): ChargeState => {
  switch (action.type) {
    case 'SHOULD_FETCH':
      if (
        state.chargesLoading ||
        state.shouldFetch ||
        (state.lastFetch && differenceInMinutes(new Date(), state.lastFetch) < NUMBER_OF_MINUTES_FOR_REFETCH)
      ) {
        return state;
      }
      return {
        ...state,
        shouldFetch: true,
      };
    case 'IS_FETCHING':
      return { ...state, chargesLoading: true };
    case 'FETCHED':
      return {
        ...state,
        chargesLoading: false,
        shouldFetch: false,
        charges: action.payload.charges,
        lastFetch: new Date(),
      };
    case 'ADD_CHARGE':
      if (state.charges?.find((charge) => charge.id === action.payload.charge.id)) {
        return state;
      }

      return {
        ...state,
        charges: [...(state.charges ?? []), action.payload.charge],
      };
    case 'UPDATE_CHARGE':
      if (!state.charges) {
        return state;
      }

      return {
        ...state,
        charges: state.charges.map((charge) => {
          if (charge.id === action.payload.charge.id) {
            return action.payload.charge;
          }
          return charge;
        }),
      };
    case 'DELETE_CHARGE':
      if (!state.charges) {
        return state;
      }
      return {
        ...state,
        charges: state.charges.filter((charge) => charge.id !== action.payload.id),
      };

    default:
      return state;
  }
};

const fetchCharges = async (clientId: string, additionalFilter?: object): Promise<Charge[]> => {
  return await list<Charge>(
    syncCharges,
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME),
    additionalFilter
  );
};

export const fetchChargesForFolders = async (clientId: string): Promise<Charge[]> => {
  const charges = await list<ChargeDTO>(
    syncChargesForFolders,
    getFilterFieldNameForIndex('byClientId'),
    getTableClientId(clientId, ENTITY_MODEL_NAME)
  );

  return getChargesFromDto(charges);
};

export const ChargeContext = React.createContext<ChargeContext | null>(null);

export const ChargeContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<ChargeState, Action>>(chargeReducer, initialState);
  const { clientId, isOwner, userId } = useUser();

  const setFetchCharges = () => {
    dispatch({ type: 'SHOULD_FETCH' });
  };

  const fetchAndSetCharges = async () => {
    dispatch({ type: 'IS_FETCHING' });
    const charges = await fetchCharges(getTableClientId(clientId!, ENTITY_MODEL_NAME));
    dispatch({ type: 'FETCHED', payload: { charges } });
  };

  useEffect(() => {
    if (state.shouldFetch) fetchAndSetCharges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetch]);

  const createCharge = async (
    input: CreateChargeInput | Omit<CreateChargeInput, 'clientId' | 'readId'>
  ): Promise<Charge> => {
    let charge: Charge | undefined;
    try {
      charge = await mutation<Charge, CreateChargeMutationVariables>(createMutation, {
        input: {
          ...(cleanInputCreate(input) as CreateChargeInput),
          readId: getReadId(clientId!, ENTITY_MODEL_NAME),
          clientId: getTableClientId(clientId!, ENTITY_MODEL_NAME),
          ...(isOwner && { writers: [userId!] }),
        },
      });
      dispatch({ type: 'ADD_CHARGE', payload: { charge } });
    } catch (error) {
      state.error = error as string;
    }
    return charge!;
  };

  useSubscriptions<OnCreateChargeSubscription, OnUpdateChargeSubscription, OnDeleteChargeSubscription>(
    onCreateCharge,
    onUpdateCharge,
    onDeleteCharge,
    (data) => {
      dispatch({
        type: 'ADD_CHARGE',
        payload: { charge: data.onCreateCharge as Charge },
      });
    },
    (data) => {
      dispatch({
        type: 'UPDATE_CHARGE',
        payload: { charge: data.onUpdateCharge as Charge },
      });
    },
    (data) => {
      const { id } = data.onDeleteCharge as Charge;
      dispatch({
        type: 'DELETE_CHARGE',
        payload: { id },
      });
    }
  );

  const processOcr = async (chargeId: string): Promise<MutationStatus> => {
    const result = await mutation<MutationStatus, ProcessOcrMutationVariables>(processOcrMutation, {
      input: {
        chargeId,
        userId: userId ?? '',
        clientId: clientId ?? '',
      },
    });
    return result;
  };

  const getCharge = (id: string) => {
    const charge = (state.charges ?? []).find((chargeElement) => chargeElement.id === id);
    return charge;
  };

  const updateCharge = async (updates: UpdateChargeInput): Promise<Charge> => {
    let charge: Charge | undefined;
    try {
      charge = await mutation<Charge>(updateMutation, {
        input: cleanInputUpdate(updates, false),
      });
      if (charge) {
        dispatch({ type: 'UPDATE_CHARGE', payload: { charge } });
        dispatch({ type: 'SHOULD_FETCH' });
      }
    } catch (error) {
      state.error = error as string;
    }
    return charge!;
  };

  const deleteCharge = async (charge: Charge): Promise<Charge> => {
    let deletedCharge: Charge | null | undefined;
    try {
      deletedCharge = await deleteEntityWithFetchBefore<Charge, DeleteMutationVariables>(
        charge,
        getQuery,
        deleteMutation
      );
      dispatch({ type: 'DELETE_CHARGE', payload: { id: charge.id } });
      return deletedCharge;
    } catch (error) {
      state.error = error as string;
    }
    return deletedCharge!;
  };

  const values = useMemo(
    () => ({
      ...state,
      ...classifyCharges(state.charges ?? []),
      createCharge,
      processOcr,
      getCharge,
      updateCharge,
      deleteCharge,
      setFetchCharges,
      fetchAndSetCharges,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <ChargeContext.Provider value={values}>{children}</ChargeContext.Provider>;
};

export const useCharges = (): ChargeContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<ChargeContext | null>(ChargeContext);

  if (isNil(context)) {
    throw new Error('`useCharges` hook must be used within a `ChargeContext` component');
  }
  useEffect(() => {
    if (!firstRender.current && isNil(context.charges)) {
      context.setFetchCharges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);
  return context;
};
