/* eslint-disable no-redeclare */
import {
  ApplicationInvoice,
  ApplicationSubscription,
  CheckAndConsumeCreditsInput,
  checkAndConsumeCredits as checkAndConsumeCreditsMutation,
  CheckAndConsumeCreditsMutationVariables,
  CheckAndConsumeCreditsResponse,
  cleanInputCreate,
  CreditCost,
  CreditUsageCost,
  disableClientAccount as deleteMutation,
  DisableClientAccountMutationVariables,
  DisableClientAccountResponse,
  getCreditCostsForClient as getCreditCostsForClientQuery,
  initiatePayment as initiatePaymentMutation,
  InitiatePaymentMutationVariables,
  InitiatePaymentResponse,
  syncApplicationInvoices as syncApplicationInvoicesQuery,
  syncCreditUsageCosts as syncCreditUsageCostsQuery,
  syncApplicationSubscriptions as syncQuery,
} from '@rentguru/commons-utils';
import { listGraphqlWithFetch, mutationGraphqlWithFetch, NUMBER_OF_MINUTES_FOR_REFETCH } from '@up2rent/fetch-utils';
import { differenceInMinutes } from 'date-fns';
import isNil from 'lodash/isNil';
import React, { Reducer, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { useUser } from './UserContext';
import { getAdminToolGraphqlUrl } from './utils/admintoolUtils';

export interface ClientContext extends ClientState {
  setFetchClient: () => void;
  checkAndConsumeCredits: (
    input: Omit<CheckAndConsumeCreditsInput, 'clientId' | 'userId'>
  ) => Promise<CheckAndConsumeCreditsResponse>;
  initiatePayment: () => Promise<InitiatePaymentResponse>;
  getCreditCostsForClient: () => Promise<CreditCost[]>;
  disableClient: (id: string) => void;
}

interface ClientState {
  applicationSubscription: ApplicationSubscription | null;
  applicationInvoices: ApplicationInvoice[] | null;
  creditUsageCosts: CreditUsageCost[] | null;
  error?: string;
  loading: boolean;
  shouldFetch: boolean;
  lastFetch: Date | null;
}

type Action =
  | {
      type: 'SHOULD_FETCH' | 'IS_FETCHING';
    }
  | {
      type: 'FETCHED';
      payload: {
        applicationSubscription: ApplicationSubscription;
        applicationInvoices: ApplicationInvoice[];
        creditUsageCosts: CreditUsageCost[];
      };
    };

const initialState: ClientState = {
  loading: false,
  error: undefined,
  applicationSubscription: null,
  applicationInvoices: null,
  creditUsageCosts: null,
  shouldFetch: false,
  lastFetch: null,
};

const clientReducer = (state: ClientState, action: Action): ClientState => {
  switch (action.type) {
    case 'SHOULD_FETCH':
      if (
        state.loading ||
        state.shouldFetch ||
        (state.lastFetch && differenceInMinutes(new Date(), state.lastFetch) < NUMBER_OF_MINUTES_FOR_REFETCH)
      ) {
        return state;
      }
      return {
        ...state,
        shouldFetch: true,
      };
    case 'IS_FETCHING':
      return {
        ...state,
        loading: true,
      };
    case 'FETCHED':
      // Build List with merged object
      return {
        ...state,
        loading: false,
        shouldFetch: false,
        applicationSubscription: action.payload.applicationSubscription,
        applicationInvoices: action.payload.applicationInvoices,
        creditUsageCosts: action.payload.creditUsageCosts,
        lastFetch: new Date(),
      };

    default:
      return state;
  }
};

const fetchClient = async (clientId: string): Promise<ApplicationSubscription[]> =>
  await listGraphqlWithFetch<ApplicationSubscription>({
    url: getAdminToolGraphqlUrl(),
    query: syncQuery,
    indexName: 'clientId',
    indexValue: clientId,
  });

const fetchApplicationInvoices = async (clientId: string): Promise<ApplicationInvoice[]> =>
  await listGraphqlWithFetch<ApplicationInvoice>({
    url: getAdminToolGraphqlUrl(),
    query: syncApplicationInvoicesQuery,
    indexName: 'clientId',
    indexValue: clientId,
  });

const fetchCreditUsageCosts = async (): Promise<CreditUsageCost[]> =>
  await listGraphqlWithFetch<CreditUsageCost>({
    url: getAdminToolGraphqlUrl(),
    query: syncCreditUsageCostsQuery,
  });

export const ClientContext = React.createContext<ClientContext | null>(null);

export const ClientContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<ClientState, Action>>(clientReducer, initialState);
  const { clientId, userId } = useUser();

  const setFetchClient = () => {
    dispatch({ type: 'SHOULD_FETCH' });
  };

  useEffect(() => {
    if (state.shouldFetch) fetchAndSetClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetch]);

  const fetchAndSetClient = async () => {
    try {
      dispatch({ type: 'IS_FETCHING' });
      const applicationSubscriptions = await fetchClient(clientId!);
      const applicationInvoices = await fetchApplicationInvoices(clientId!);
      const creditUsageCosts = await fetchCreditUsageCosts();
      dispatch({
        type: 'FETCHED',
        payload: { applicationSubscription: applicationSubscriptions?.[0], applicationInvoices, creditUsageCosts },
      });
    } catch (error) {
      state.error = error as string;
    }
  };

  const checkAndConsumeCredits = async (
    input: Omit<CheckAndConsumeCreditsInput, 'clientId' | 'userId'>
  ): Promise<CheckAndConsumeCreditsResponse> => {
    const response = await mutationGraphqlWithFetch<
      CheckAndConsumeCreditsResponse,
      CheckAndConsumeCreditsMutationVariables
    >(getAdminToolGraphqlUrl(), checkAndConsumeCreditsMutation, {
      input: {
        ...(cleanInputCreate(input) as CheckAndConsumeCreditsInput),
        clientId: clientId!,
        userId: userId!,
      },
    });
    return response;
  };

  const initiatePayment = async (): Promise<InitiatePaymentResponse> =>
    await mutationGraphqlWithFetch<InitiatePaymentResponse, InitiatePaymentMutationVariables>(
      getAdminToolGraphqlUrl(),
      initiatePaymentMutation,
      {
        input: {
          clientId: clientId!,
        },
      }
    );

  const getCreditCostsForClient = async (): Promise<CreditCost[]> =>
    await listGraphqlWithFetch<CreditCost>({
      url: getAdminToolGraphqlUrl(),
      query: getCreditCostsForClientQuery,
      additionalFilter: {
        clientId: clientId!,
      },
    });

  const disableClient = async (id: string) => {
    try {
      const response = await mutationGraphqlWithFetch<
        DisableClientAccountResponse,
        DisableClientAccountMutationVariables
      >(getAdminToolGraphqlUrl(), deleteMutation, {
        input: { clientId: id },
      });
      if (response) {
        dispatch({ type: 'SHOULD_FETCH' });
      }
    } catch (error) {
      state.error = error as string;
    }
  };

  const values = useMemo(
    () => ({
      ...state,
      setFetchClient,
      fetchAndSetClient,
      checkAndConsumeCredits,
      initiatePayment,
      getCreditCostsForClient,
      disableClient,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <ClientContext.Provider value={values}>{children}</ClientContext.Provider>;
};

export const useClient = (): ClientContext => {
  const firstRender = useRef<boolean>(false);
  const context = useContext<ClientContext | null>(ClientContext);

  if (isNil(context)) {
    throw new Error('`useClient` hook must be used within a `ClientContext` component');
  }
  useEffect(() => {
    if (!firstRender.current && isNil(context.applicationSubscription)) {
      context.setFetchClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return context;
};
