import { Grid } from '@material-ui/core';
import { CustomSimpleDialog, TextDetailEditable } from '@up2rent/ui';
import { Form, Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { LeaseNameField } from 'src/components/ui/Forms/FormField/SummaryFormFields';
import { LeaseExtended, useLeases } from 'src/hooks/LeasesContext';
import * as Yup from 'yup';

interface EditLeaseNameDialogProps {
  open: boolean;
  lease: LeaseExtended;
  onClose: () => void;
}

interface EditLeaseNameFormValues {
  name: string;
}

const EditLeaseNameSchema = Yup.object().shape({
  name: LeaseNameField,
});

const EditLeaseNameDialog: React.FC<EditLeaseNameDialogProps> = ({ open, lease, onClose }) => {
  const { formatMessage } = useIntl();
  const { updateLease } = useLeases();

  const handleEdit = async (values: EditLeaseNameFormValues) => {
    const { name } = values;

    if (lease.name !== name) {
      await updateLease(lease, { name });
    }
    onClose();
  };
  const initialValues = { name: lease.name };

  return (
    <Formik initialValues={initialValues} onSubmit={handleEdit} validationSchema={EditLeaseNameSchema}>
      {({ isSubmitting, handleSubmit }) => {
        return (
          <Form>
            <CustomSimpleDialog
              open={open}
              onClose={onClose}
              onActionButtonClick={handleSubmit}
              actionButtonLabel={formatMessage({
                id: 'save',
              })}
              actionButtonLoading={isSubmitting}
              dividerBelowTitle
              title={formatMessage({ id: 'lease.editLease.action' })}
              formatMessage={formatMessage}
            >
              <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                <TextDetailEditable
                  name="name"
                  title={formatMessage({ id: 'lease.addLease.leaseName' })}
                  type="text"
                  editMode={true}
                  style={{ width: 580, maxWidth: 'none' }}
                />
              </Grid>
            </CustomSimpleDialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditLeaseNameDialog;
