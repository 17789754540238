/* eslint-disable no-console */
import { Box, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { AdvertisementDescription, Colors, CustomLabel, isNilOrEmpty, Language } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { EditAdvertisementFormValues } from 'src/components/RentalUnits/Details/Publication/EditPublication/EditPublicationForm';
import { TranslateDialogBundle } from 'src/components/ui/Forms/FormField/Translators/TranslateTextDetailEditable';
import TranslateTextField from 'src/components/ui/Forms/FormField/Translators/TranslateTextField';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';

const useLocalStyles = makeStyles({
  container: {
    display: 'flex',
    maxWidth: '100%',
    padding: '0px 30px',
    marginBottom: 30,
  },
  containerTitle: {
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 0px',
  },
  h6: {
    flex: 1,
    textAlign: 'left' as const,
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: 700,
  },
});

const PublicationDescriptionEdit: React.FC = () => {
  const localClasses = useLocalStyles();
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<EditAdvertisementFormValues>();
  const [textsToTranslate, setTextsToTranslate] = useState<TranslateDialogBundle | null>(null);
  const [languageSelected, setLanguage] = useState<Language>(Language.EN);

  const afterSubmitHandler = (newTexts: CustomLabel[]) => {
    const advertisementDescriptions = newTexts.map((text) => {
      return {
        language: text.language,
        description: text.label,
      };
    });

    setFieldValue('advertisement.descriptions', advertisementDescriptions);
  };

  const getIndexOfDescriptionAccordingToLanguage = (language: string) => {
    if (!isNil(values.advertisement.descriptions)) {
      return values.advertisement.descriptions.indexOf(
        values.advertisement.descriptions.find((adv) => adv.language === language) as AdvertisementDescription
      );
    }
    return undefined;
  };

  const getCustomLabelAccordingToDescription = (): CustomLabel[] => {
    const customLabel = values.advertisement.descriptions?.reduce((acc, adDescription) => {
      if (adDescription.description) {
        acc.push({
          language: adDescription.language,
          label: adDescription.description,
        });
      }
      return acc;
    }, [] as CustomLabel[]);

    if (isNilOrEmpty(customLabel)) {
      return [
        { language: Language.FR, label: '' },
        { language: Language.EN, label: '' },
        { language: Language.NL, label: '' },
      ];
    }

    return customLabel;
  };

  return (
    <Grid container className={localClasses.container}>
      <Grid container className={localClasses.containerTitle}>
        <Grid item xs={11}>
          <Typography variant="h6" color="inherit" className={localClasses.h6}>
            {formatMessage({ id: 'rentalUnit.editUnit.advertisement.description' })}
          </Typography>
        </Grid>
        <Grid item xs={1} style={{ justifyContent: 'flex-end', textAlign: 'right' }}>
          <Tooltip title={formatMessage({ id: 'settings.addVariousOperation.helper' })} placement="top-end">
            <Box>
              <InfoSvg style={{ fill: Colors.SILVER }} />
            </Box>
          </Tooltip>
        </Grid>
      </Grid>

      <TranslateTextField
        textsToTranslate={textsToTranslate}
        onLanguageChange={(language: Language) => setLanguage(language)}
        onDialogClose={() => setTextsToTranslate(null)}
        languageSelected={languageSelected}
        textFieldProps={{
          name: `advertisement.descriptions[${getIndexOfDescriptionAccordingToLanguage(languageSelected)}].description`,
          placeholder: `${formatMessage({ id: 'rentalUnit.editUnit.advertisement.description' })}...`,
        }}
        actionButtonProps={{
          onClick: () => {
            setTextsToTranslate({
              texts: getCustomLabelAccordingToDescription(),
              afterSubmit: afterSubmitHandler,
            });
          },
        }}
      />
    </Grid>
  );
};

export default PublicationDescriptionEdit;
