import { FormControlLabel, Grid, RadioGroup, Tooltip, Typography } from '@material-ui/core';
import { Colors, CommunicationSendingRule } from '@rentguru/commons-utils';
import { useIntl } from 'react-intl';
import StyledRadio from 'src/components/ui/StyledRadio';
import { ReactComponent as InfoSvg } from 'src/icons/info.svg';
import { SettableCommunicationType } from '../CommunicationSettingsProfileUtils/CommunicationSettingsCommonsUtils';
import { LeaseSettableCommunicationType } from '../CommunicationSettingsProfileUtils/LeaseCommunicationSettingsUtils';

interface SendingRuleInfoMessageProps {
  messageIdPrefix?: string;
}

const SendingRuleInfoMessage: React.FC<SendingRuleInfoMessageProps> = ({ messageIdPrefix = '' }) => {
  const { formatMessage } = useIntl();
  const messageIds = [
    { title: 'manual', help: `${messageIdPrefix}helpManual` },
    { title: 'automatic', help: `${messageIdPrefix}helpAutomatic` },
    { title: 'disabled', help: `${messageIdPrefix}helpDisabled` },
  ];
  return (
    <Grid style={{ marginBottom: 5 }}>
      {messageIds.map((ids) => (
        <Grid key={ids.title} container style={{ marginTop: 5 }}>
          <Grid item xs={3}>
            <Typography style={{ fontWeight: 'bold' }}>
              {formatMessage({ id: `settings.communications.${ids.title}` })}
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={8}>
            <Typography>{formatMessage({ id: `settings.communications.${ids.help}` })}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
interface CommunicationSendingRuleFormItemProps {
  communicationType: SettableCommunicationType;
  sendingRule: CommunicationSendingRule;
  disabled: boolean;
  onChange: (value: CommunicationSendingRule) => void;
}

const CommunicationSendingRuleFormItem: React.FC<CommunicationSendingRuleFormItemProps> = ({
  communicationType,
  sendingRule,
  disabled,
  onChange,
}) => {
  const { formatMessage } = useIntl();
  const sendingRulesFormParams = [
    { sendingRule: CommunicationSendingRule.MANUAL, messageId: 'manual' },
    { sendingRule: CommunicationSendingRule.AUTOMATIC, messageId: 'automatic' },
    { sendingRule: CommunicationSendingRule.DISABLE, messageId: 'disabled' },
  ];

  const infoMessagePrefix =
    communicationType === LeaseSettableCommunicationType.LEASE_UNPAID_CONSOLIDATED_PAYMENT_REMINDER
      ? 'sendingRuleHelp.LEASE_UNPAID_CONSOLIDATED_PAYMENT_REMINDER.'
      : '';

  return (
    <Grid>
      <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <RadioGroup
          name={`${communicationType}.sendingRule`}
          value={sendingRule}
          onChange={(_e, value) => onChange(value as CommunicationSendingRule)}
        >
          <Grid container>
            {sendingRulesFormParams.map((sendingRuleFormParams, index) => (
              <Grid key={sendingRuleFormParams.sendingRule} style={{ marginLeft: index === 0 ? 0 : 15 }}>
                <FormControlLabel
                  value={sendingRuleFormParams.sendingRule}
                  disabled={disabled}
                  control={<StyledRadio name={sendingRuleFormParams.sendingRule} />}
                  label={
                    <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                      {formatMessage({ id: `settings.communications.${sendingRuleFormParams.messageId}` })}
                    </Typography>
                  }
                  id={sendingRuleFormParams.sendingRule}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
        <Tooltip title={<SendingRuleInfoMessage messageIdPrefix={infoMessagePrefix} />}>
          <Grid>
            <InfoSvg style={{ marginTop: 2.5, fill: Colors.SILVER }} />
          </Grid>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default CommunicationSendingRuleFormItem;
