import { makeStyles } from '@material-ui/core';

export const authStyles = makeStyles({
  mainContainer: {
    width: '440px',
    padding: '40px',
  },
  createAccountContainer: {
    padding: '40px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '50%',
  },
});
