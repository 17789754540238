import { Collapse, Grid, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { CustomizedSwitch, TextDetailEditable } from '@up2rent/ui';
import { useFormikContext } from 'formik';
import { isEmpty, isNil } from 'lodash';
import { useIntl } from 'react-intl';
import { useUnits } from 'src/hooks/UnitsContext';
import { EditAdvertisementFormValues } from '../EditPublicationForm';

const useLocalStyles = makeStyles({
  h6: {
    textAlign: 'left' as const,
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    margin: '30px 30px 0 30px',
    padding: '0 0 20px 0',
    bottom: 0,
  },
  switch: {
    margin: '27px 0 0 30px',
  },
});

const ShowAddressEdit = () => {
  const classes = useLocalStyles();
  const { getUnit } = useUnits();
  const { formatMessage } = useIntl();
  const { values, setFieldValue, errors } = useFormikContext<EditAdvertisementFormValues>();

  const unit = getUnit(values.unit.id);
  if (!unit) return <Skeleton />;

  return (
    <>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h6" color="inherit" className={classes.h6}>
            {formatMessage({ id: 'rentalUnit.detail.general.showAddress' })}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <div className={classes.switch}>
            <CustomizedSwitch
              checked={values.unit.publishAddress}
              onClick={() => setFieldValue('unit.publishAddress', !values.unit.publishAddress)}
            />
          </div>
        </Grid>
      </Grid>

      {!isNil(unit.owners) && !isEmpty(unit.owners) && (
        <>
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="h6" color="inherit" className={classes.h6}>
                {formatMessage({ id: 'rentalUnit.detail.general.showCadastralIncome' })}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <div className={classes.switch}>
                <CustomizedSwitch
                  checked={Boolean(values.advertisement.publishCadastralIncome)}
                  onClick={() =>
                    setFieldValue('advertisement.publishCadastralIncome', !values.advertisement.publishCadastralIncome)
                  }
                />
              </div>
            </Grid>
          </Grid>
          <Collapse in={Boolean(values.advertisement.publishCadastralIncome)}>
            <Grid container>
              <Grid item xs={6}>
                <TextDetailEditable
                  title={formatMessage({ id: 'valuation.cadastralIncome' })}
                  editMode={true}
                  min={0}
                  name={
                    isNil(values.building.owners) || isEmpty(values.building.owners)
                      ? 'unit.cadastralIncome'
                      : 'building.cadastralIncome'
                  }
                  type="number"
                  style={{ width: 280 }}
                  error={Boolean(errors.unit?.cadastralIncome)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextDetailEditable
                  title={formatMessage({ id: 'valuation.withholdingTax' })}
                  editMode={true}
                  min={0}
                  name={
                    isNil(values.building.owners) || isEmpty(values.building.owners)
                      ? 'unit.propertyTax'
                      : 'building.propertyTax'
                  }
                  type="number"
                  style={{ width: 280 }}
                  error={Boolean(errors.unit?.propertyTax)}
                />
              </Grid>
            </Grid>
          </Collapse>
        </>
      )}
    </>
  );
};

export default ShowAddressEdit;
