import { Typography } from '@material-ui/core';
import {
  CommunicationSendingRule,
  CommunicationSettingsProfile,
  CommunicationType,
  LeaseStatus,
  getCommunicationSettings,
  getInvoiceSenderIdOfUnitOrBuilding,
  getMainUnit,
} from '@rentguru/commons-utils';
import { CenteredGrid, ElevatedPaper, ElevatedPaperTable, EnhanceTableRowProps } from '@up2rent/ui';
import { isNil, orderBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useBuildings } from 'src/hooks/BuildingsContext';
import { useCommunicationSettingsProfiles } from 'src/hooks/CommunicationSettingsProfilesContext';
import { useContacts } from 'src/hooks/ContactsContext';
import { LeaseExtended, LeaseExtendedForTable, useLeases } from 'src/hooks/LeasesContext';
import { useUnits } from 'src/hooks/UnitsContext';
import { ExitableLocation, NavItem, RouteDestination } from '../Routes/Routes';
import EntityDetail from '../ui/EntityDetail/EntityDetail';
import { FieldFilterResetProvider } from '../ui/FieldFilterSelector';
import LeaseDetail from './Details/LeaseDetail';
import LeasesFilters from './Filters/LeasesFilters';
import LeasesFiltersActive from './Filters/LeasesFiltersActive';
import LeasesFiltersArchived from './Filters/LeasesFiltersArchived';
import LeasesFiltersDrafts from './Filters/LeasesFiltersDrafts';
import LeasesTabHeader from './LeasesTabHeader';
import LeasesTableActive from './Tables/LeasesTableActive';
import LeasesTableArchived from './Tables/LeasesTableArchived';
import LeasesTableDrafts from './Tables/LeasesTableDrafts';

export interface LeasesTableProps {
  leasesActive: LeaseExtendedForTable[];
  leasesDrafts: LeaseExtendedForTable[];
  leasesArchived: LeaseExtendedForTable[];
  loading: boolean;
}

export type LeaseRowProps = EnhanceTableRowProps & LeaseExtended;

export const LEASES_DETAIL_TO = `detail`;
export const leasesNavItems: (NavItem & {
  defaultTab?: boolean;
  isDetail?: boolean;
  leaseStatus: LeaseStatus[];
  filterComponent?: React.FC<LeasesTableProps>;
  component?: React.FC<LeasesTableProps>;
})[] = [
  {
    to: `active`,
    labelId: 'lease.tabs.active',
    component: LeasesTableActive,
    filterComponent: LeasesFiltersActive,
    defaultTab: true,
    leaseStatus: [LeaseStatus.Active],
  },
  {
    to: `drafts`,
    labelId: 'lease.tabs.drafts',
    component: LeasesTableDrafts,
    filterComponent: LeasesFiltersDrafts,
    leaseStatus: [LeaseStatus.Rejected, LeaseStatus.Draft, LeaseStatus.OutForSignature],
  },
  {
    to: `archived`,
    labelId: 'lease.tabs.archived',
    component: LeasesTableArchived,
    filterComponent: LeasesFiltersArchived,
    leaseStatus: [LeaseStatus.Cancelled, LeaseStatus.Ended],
  },
  {
    to: LEASES_DETAIL_TO,
    labelId: '',
    isDetail: true,
    leaseStatus: [],
  },
];

export const getDefaultLeasesTab = () => {
  return leasesNavItems.find((navItems) => navItems.defaultTab);
};

const getDetailLeasesTab = () => {
  return leasesNavItems.find((navItems) => navItems.isDetail);
};

export const getTabAssignedToSelectedLease = (status: LeaseStatus) => {
  return leasesNavItems.find((navItem) => navItem.leaseStatus.includes(status)) ?? getDefaultLeasesTab()!;
};

export const isLeaseDetailOpen = (match: {
  params: {
    tabName?: string | undefined;
    id?: string | undefined;
  };
}) => {
  return Boolean(match.params && match.params.tabName && match.params.tabName === LEASES_DETAIL_TO && match.params.id);
};

export const isConsolidatedPaymentRequestReportActivated = (leases: LeaseExtendedForTable[]) =>
  leases.some((lease) => isConsolidatedPaymentRequestReportActivatedForLease(lease));

export const isConsolidatedPaymentRequestReportActivatedForLease = (lease: LeaseExtendedForTable) =>
  lease.communicationSettingsProfile?.communicationsSettings &&
  getCommunicationSettings(
    CommunicationType.LEASE_UNPAID_CONSOLIDATED_PAYMENT_REMINDER,
    lease.communicationSettingsProfile
  ).sendingRule !== CommunicationSendingRule.DISABLE;

const Leases = () => {
  const { getLease, leases, leasesError, leasesLoading } = useLeases();
  const { getBuilding, buildingsLoading, buildingsError } = useBuildings();
  const { getContact, contactsLoading, contactsError } = useContacts();
  const { getUnit, units, unitsLoading, unitsError } = useUnits();
  const { getLeaseCommunicationSettingsProfiles, loading: communicationSettingsProfilesLoading } =
    useCommunicationSettingsProfiles();

  const [leaseCommunicationSettingsProfiles, setLeaseCommunicationSettingsProfiles] = useState<
    CommunicationSettingsProfile[]
  >([]);

  const loading =
    Boolean(leasesLoading) ||
    unitsLoading ||
    buildingsLoading ||
    contactsLoading ||
    communicationSettingsProfilesLoading;

  useEffect(() => {
    if (communicationSettingsProfilesLoading) {
      return;
    }
    const leaseProfiles = getLeaseCommunicationSettingsProfiles();
    setLeaseCommunicationSettingsProfiles(orderBy(leaseProfiles, 'defaultName'));
  }, [getLeaseCommunicationSettingsProfiles, communicationSettingsProfilesLoading]);

  const history = useHistory();
  const match = useRouteMatch<{ tabName?: string; id?: string }>({
    path: `${RouteDestination.LEASES}/:tabName?/:id?`,
  });
  const { state: exitParams } = useLocation<ExitableLocation>();

  const error = leasesError || unitsError || buildingsError || contactsError;

  const defaultTab = getDefaultLeasesTab();
  const detailTab = getDetailLeasesTab();

  const leasesLists = useMemo<Omit<LeasesTableProps, 'loading' | 'error'>>(() => {
    return leases.reduce(
      (result, currentLease) => {
        // Get complete unit from this lease
        const unitLeases = currentLease.units?.map((unitLease) => {
          const unit = getUnit(unitLease.unit!.id);
          const building = getBuilding(unit?.building?.id ?? '');
          return { ...unitLease, unit: { ...unit!, building } };
        });
        const mainUnit = getMainUnit(unitLeases ?? []);
        const contactId = mainUnit ? getInvoiceSenderIdOfUnitOrBuilding(mainUnit, getBuilding) : '';
        const owner = getContact(contactId)!;
        const communicationProfile = leaseCommunicationSettingsProfiles.find(
          (communicationSettingsProfile) =>
            communicationSettingsProfile.id === currentLease.communicationSettingsProfileId
        )!;
        const lease = { ...currentLease, units: unitLeases, owner, communicationSettingsProfile: communicationProfile };
        if (lease.status === LeaseStatus.Active) {
          result.leasesActive.push(lease);
        } else if (
          [LeaseStatus.Rejected, LeaseStatus.Draft, LeaseStatus.OutForSignature].includes(lease.status as LeaseStatus)
        ) {
          result.leasesDrafts.push(lease);
        } else if ([LeaseStatus.Cancelled, LeaseStatus.Ended].includes(lease.status as LeaseStatus)) {
          result.leasesArchived.push(lease);
        }
        return result;
      },
      { leasesActive: [], leasesArchived: [], leasesDrafts: [] } as Omit<LeasesTableProps, 'loading' | 'error'>
    );
    // eslint-disable-next-line
  }, [leases, units, loading, error, leaseCommunicationSettingsProfiles]);

  if (!match || !defaultTab || !detailTab) {
    return null;
  }

  const handleCloseDetail = () => {
    if (!isNil(exitParams) && !isNil(exitParams.goBackUrl)) {
      history.replace(exitParams.goBackUrl);
    } else {
      history.replace(`${RouteDestination.LEASES}`);
    }
  };

  const openDetail = isLeaseDetailOpen(match);

  return (
    <FieldFilterResetProvider>
      <CenteredGrid>
        <ElevatedPaper>
          <LeasesTabHeader />
          <LeasesFilters {...leasesLists} loading={loading} />
        </ElevatedPaper>
        <ElevatedPaperTable>
          {error && <Typography>{error}</Typography>}
          <Switch>
            <Route
              path={`${RouteDestination.LEASES}/${LEASES_DETAIL_TO}`}
              key="detail"
              render={() => {
                if (!openDetail) {
                  return null;
                }
                const selectedLease = getLease(match.params!.id!);
                if (!selectedLease) {
                  return null;
                }
                const tabAssignedForLease = getTabAssignedToSelectedLease(selectedLease.status as LeaseStatus);
                if (!tabAssignedForLease.component) {
                  return null;
                }
                return <tabAssignedForLease.component {...leasesLists} loading={loading} />;
              }}
            />
            <Route
              path={RouteDestination.LEASES}
              exact={true}
              key="default"
              render={() => {
                if (!defaultTab.component) {
                  return null;
                }
                return <defaultTab.component {...leasesLists} loading={loading} />;
              }}
            />
            {leasesNavItems.map((navItem) => {
              const Component = navItem.component;
              if (!Component || navItem.isDetail || !navItem.to) {
                return null;
              }
              const path = match.path.replace(':tabName?', navItem.to);
              return (
                <Route
                  path={path}
                  key={navItem.to}
                  render={() => {
                    return <Component {...leasesLists} loading={loading} />;
                  }}
                />
              );
            })}
          </Switch>
        </ElevatedPaperTable>
      </CenteredGrid>
      <EntityDetail open={openDetail} onClose={handleCloseDetail} forceOnClose>
        <LeaseDetail id={match.params.id!} />
      </EntityDetail>
    </FieldFilterResetProvider>
  );
};

export default Leases;
