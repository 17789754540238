import { Grid, Typography } from '@material-ui/core';
import { getSafeValueInObject, Unit } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as UnitIcon } from '../../../icons/unitPlaceholder.svg';
import CustomInputComboBox from '../ComboBox/FormikFullCustomComboBox';
import { CustomInputItemType } from '../ComboBox/FullCustomComboBox';
import { useCustomizedComboStyles } from '../ComboBox/TextComboBox';

interface SimpleUnitSelectorProps {
  units: Unit[];
  defaultValue?: Unit;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  fieldName: string;
  inputWidth?: number;
  paperClass?: string;
  disabled?: boolean;
  onChangeObserver?: (
    valueToReturn: CustomInputItemType,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => void;
}

const SimpleUnitSelector: React.FC<SimpleUnitSelectorProps> = ({
  units,
  defaultValue,
  style,
  inputStyle,
  fieldName,
  inputWidth = 560,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const comboBoxClasses = useCustomizedComboStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, errors, touched } = useFormikContext<any>();

  const unitToCustomComboItem = (unit: Unit) => {
    return {
      primary: unit.building!.name,
      secondary: unit.name,
      value: unit,
    };
  };
  const emptyObject = !isNil(defaultValue)
    ? unitToCustomComboItem(defaultValue)
    : { primary: '', secondary: '', value: null };
  const unitSelected = getSafeValueInObject(values, fieldName);

  return (
    <div
      style={{
        alignItems: 'baseline',
        display: 'flex',
        ...style,
      }}
    >
      <CustomInputComboBox
        name={fieldName}
        value={!isNil(unitSelected) && !isEmpty(unitSelected) ? unitToCustomComboItem(unitSelected) : emptyObject}
        label={formatMessage({ id: 'lease.addLease.unit' })}
        options={units.map((u) => unitToCustomComboItem(u))}
        fieldToReturn="value"
        emptyValue={emptyObject}
        inputStyle={{ width: inputWidth, ...inputStyle }}
        renderOption={(value: CustomInputItemType) => {
          return (
            <Grid container alignContent="space-between" style={{ marginBottom: '5px', marginTop: '5px' }}>
              <Grid item xs={2}>
                <UnitIcon
                  style={{
                    width: 15,
                    height: 15,
                    marginRight: 10,
                    fill: 'blue',
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography className={comboBoxClasses.primaryText}>{value.primary}</Typography>
              </Grid>
              <Grid item xs={5} style={{ textAlign: 'right' }}>
                {!isNil(value.secondary) && (
                  <Typography className={comboBoxClasses.secondaryText}>{value.secondary}</Typography>
                )}
              </Grid>
            </Grid>
          );
        }}
        renderInput={(value: CustomInputItemType) => {
          return (
            <>
              <UnitIcon
                style={{
                  width: 15,
                  height: 15,
                  marginRight: 10,
                  fill: 'blue',
                }}
              />
              <Typography
                style={{
                  fontSize: 12,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: `calc(${inputWidth}px - 85px)`,
                }}
              >{`${value.primary} - ${value.secondary}`}</Typography>
            </>
          );
        }}
        error={Boolean(getSafeValueInObject(errors, fieldName) && getSafeValueInObject(touched, fieldName))}
        noOptionsText={formatMessage({ id: 'comboBox.noUnits' })}
        stringToCompare={(value: CustomInputItemType) => `${value.primary} - ${value.secondary}`}
        {...rest}
      />
    </div>
  );
};

export default SimpleUnitSelector;
