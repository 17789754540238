import { Grid, Typography } from '@material-ui/core';
import { CommunicationStatus } from '@rentguru/commons-utils';
import { CustomSimpleDialog } from '@up2rent/ui';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useCommunications } from 'src/hooks/CommunicationsContext';
import { CommunicationWithTemplate } from 'src/utils/communicationUtils';

interface UnblockCommunicationDialogProps {
  unblockCommunicationDialogOpen: boolean;
  onClose: () => void;
  communication: CommunicationWithTemplate;
}

const UnblockCommunicationDialog: React.FC<UnblockCommunicationDialogProps> = ({
  unblockCommunicationDialogOpen,
  onClose,
  communication,
}) => {
  const { formatMessage } = useIntl();
  const [unblockCommunicationLoading, setUnblockCommunicationLoading] = useState<boolean>(false);
  const { updateCommunication } = useCommunications();

  const handleUnblockCommunication = async () => {
    setUnblockCommunicationLoading(true);
    await updateCommunication(communication, {
      status: CommunicationStatus.TO_SEND,
    });
    setUnblockCommunicationLoading(false);
    onClose();
  };

  return (
    <CustomSimpleDialog
      open={unblockCommunicationDialogOpen}
      onClose={onClose}
      onActionButtonClick={handleUnblockCommunication}
      actionButtonLabel={formatMessage({
        id: 'proceed',
      })}
      actionButtonLoading={unblockCommunicationLoading}
      secondaryButtonLabel={formatMessage({
        id: 'close',
      })}
      title={formatMessage({ id: 'communications.detail.unblockCommunication' })}
      dividerBelowTitle
      formatMessage={formatMessage}
    >
      <Grid style={{ marginTop: 20 }}>
        <Typography style={{ marginBottom: 10, fontSize: 14 }}>
          {formatMessage({ id: 'communications.detail.unblockCommunicationInfo1' })}
        </Typography>
        {communication.blockedRecipients?.map((blockedRecipient) => (
          <Typography key={blockedRecipient.recipient} style={{ fontSize: 14 }}>
            • <b>{blockedRecipient.recipient}</b> :{' '}
            {formatMessage({ id: `enums.BounceTypes.${blockedRecipient.bounceType}` })}
          </Typography>
        ))}
        <Typography style={{ fontSize: 14, marginTop: 10 }}>
          {formatMessage({ id: 'communications.detail.unblockCommunicationInfo2' })}
        </Typography>
      </Grid>
    </CustomSimpleDialog>
  );
};

export default UnblockCommunicationDialog;
