/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControlLabel, Typography } from '@material-ui/core';
import {
  Colors,
  REMITTANCE_TYPE_STRUCTURED,
  REMITTANCE_TYPE_UNSTRUCTURED,
  RemittanceType,
  encodeStructuredRemittanceInformation,
  generateStructuredRemittanceInformation,
  getSafeValueInObject,
  isValidOGM,
} from '@rentguru/commons-utils';
import { CustomizedSwitch, TextDetailEditable } from '@up2rent/ui';
import { useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import ResetIcon from 'src/components/ui/ResetIcon';
import * as Yup from 'yup';

export interface NumberFormatCustomProps extends NumberFormatProps {
  inputRef: (el: any) => void;
  onChange: (val: any) => void;
  name: string;
}

const NumberFormatCustom: React.FC<NumberFormatCustomProps> = ({ inputRef, onChange, name, ...other }) => {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
            name,
          },
        });
      }}
      format="+++ ### / #### / ##### +++"
      mask="_"
      allowEmptyFormatting
    />
  );
};

export const RemittanceInformationSchema = Yup.object().shape({
  remittanceInformationType: Yup.string().oneOf([REMITTANCE_TYPE_UNSTRUCTURED, REMITTANCE_TYPE_STRUCTURED]),
  remittanceInformation: Yup.string().when('remittanceInformationType', {
    is: REMITTANCE_TYPE_STRUCTURED,
    then: Yup.string().test(
      'ValidStructuredCommunication',
      'The communication should be a valid structured communication',
      (value) => {
        return isValidOGM(encodeStructuredRemittanceInformation(value));
      }
    ),
    otherwise: Yup.string(),
  }),
});

interface RemittanceInformationFieldProps {
  remittanceInformationName: string;
  remittanceInformationTypeName: string;
  title?: string;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  onlyStructured?: boolean;
  allowGenerateStructuredRemittanceInformation?: boolean;
}

const RemittanceInformationField: React.FC<RemittanceInformationFieldProps> = ({
  remittanceInformationName,
  remittanceInformationTypeName,
  onlyStructured,
  title,
  style = {},
  inputStyle = {},
  allowGenerateStructuredRemittanceInformation = false,
}) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue, setValues, errors } = useFormikContext();
  const remittanceInformationError: string = getSafeValueInObject(errors, remittanceInformationName);
  const remittanceInformationType: RemittanceType = getSafeValueInObject(values, remittanceInformationTypeName);

  const getIconIfNecessary = () => {
    if (
      allowGenerateStructuredRemittanceInformation &&
      (remittanceInformationType === REMITTANCE_TYPE_STRUCTURED || onlyStructured)
    ) {
      return (
        <ResetIcon
          style={{ fill: Colors.BLUE_GREY, cursor: 'pointer' }}
          data-test="reset-structured-communication"
          onClick={() => {
            const newRemittanceInformation = generateStructuredRemittanceInformation();
            setFieldValue(remittanceInformationName, newRemittanceInformation);
          }}
        />
      );
    }
    return <div />;
  };

  return (
    <>
      {!onlyStructured && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            ...style,
          }}
        >
          <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
            {title || formatMessage({ id: 'transactions.addTransaction.remittanceInformation' })}
          </Typography>
          <FormControlLabel
            style={{ margin: 0 }}
            control={
              <CustomizedSwitch
                checked={remittanceInformationType === REMITTANCE_TYPE_STRUCTURED}
                onChange={(_: any, checked: boolean) => {
                  if (remittanceInformationType === RemittanceType.unstructured && checked) {
                    setValues((oldValues) => ({
                      ...oldValues,
                      [remittanceInformationName]: allowGenerateStructuredRemittanceInformation
                        ? generateStructuredRemittanceInformation()
                        : '',
                      [remittanceInformationTypeName]: REMITTANCE_TYPE_STRUCTURED,
                    }));
                  } else {
                    setValues((oldValues) => ({
                      ...oldValues,
                      [remittanceInformationName]: '',
                      [remittanceInformationTypeName]: REMITTANCE_TYPE_UNSTRUCTURED,
                    }));
                  }
                }}
                aria-label="Collapse"
                color="primary"
                disableRipple
              />
            }
            label={formatMessage({ id: 'transactions.addTransaction.remittanceInformationStructured' })}
          />
        </div>
      )}
      <TextDetailEditable
        title={`${formatMessage({ id: 'transactions.addTransaction.remittanceInformation' })}`}
        editMode={true}
        name={remittanceInformationName}
        type="text"
        style={{ maxWidth: 580, ...inputStyle }}
        {...((remittanceInformationType === REMITTANCE_TYPE_STRUCTURED || onlyStructured) && {
          inputProps: { inputComponent: NumberFormatCustom },
        })}
        endAdornment={getIconIfNecessary()}
        error={remittanceInformationError !== ''}
      />
    </>
  );
};

export default RemittanceInformationField;
