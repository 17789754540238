import { Notification, NotificationOption, NotificationType, SettingType } from '@rentguru/commons-utils';
import { ChangeRemindersValues } from './RemindersForm';

export const findNotificationsToDelete = (
  allNotifications: Notification[],
  notificationOptions: ChangeRemindersValues
) => {
  return allNotifications.filter((notification) => {
    if (notification.technic) {
      if (notification.type === NotificationType.PebCertificate) {
        return notificationOptions[SettingType.EPB_REMINDER] === NotificationOption.DISABLED;
      }
      if (notification.type === NotificationType.SmokeDetectorBattery) {
        return notificationOptions[SettingType.DETECTOR_REMINDER] === NotificationOption.DISABLED;
      }
      if (notification.type === NotificationType.FuelTankCertificate) {
        return notificationOptions[SettingType.TANK_REMINDER] === NotificationOption.DISABLED;
      }
      if (notification.type === NotificationType.HeatingCertificate) {
        return notificationOptions[SettingType.HEATING_REMINDER] === NotificationOption.DISABLED;
      }
      if (notification.type === NotificationType.ChimneySweeping) {
        return notificationOptions[SettingType.CHIMNEY_REMINDER] === NotificationOption.DISABLED;
      }
    }
    return false;
  });
};
