/* eslint-disable no-console */
import { Grid, makeStyles } from '@material-ui/core';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import { Colors, Language } from '@rentguru/commons-utils';
import { ActionButtonSecond, ActionButtonSecondProps } from '@up2rent/ui';
import { isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import LanguageMenu from 'src/components/RentalUnits/Details/Publication/PublicationDescription/LanguageMenu';
import TranslateDialog from 'src/components/ui/Dialogs/TranslateDialog';
import { TranslateDialogBundle } from './TranslateTextDetailEditable';

const useLocalStyles = makeStyles({
  containerTabs: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  h6: {
    flex: 1,
    textAlign: 'left' as const,
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Muli',
    fontSize: '16px',
    fontWeight: 700,
  },
  textField: {
    backgroundColor: Colors.PORCELAIN_GREY_3,
    fontSize: 14,
    fontWeight: 600,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottom: `2px solid ${Colors.TOWER_GREY}`,
    marginBottom: 15,
    color: Colors.BLUE_GREY,
  },
});

interface TranslageDialogProps {
  onDialogClose: () => void;
  titleText: string;
  helperText: string;
  warningText: string;
}

interface TranslateWrapperProps {
  textsToTranslate: TranslateDialogBundle | null;
  languageSelected: Language;
  onLanguageChange: (language: Language) => void;
  actionButtonProps?: ActionButtonSecondProps;
  translateDialogProps: TranslageDialogProps;
  children?: React.ReactNode;
}

const TranslateWrapper: React.FC<TranslateWrapperProps> = ({
  textsToTranslate,
  languageSelected,
  onLanguageChange,
  actionButtonProps,
  translateDialogProps,
  children,
}) => {
  const localClasses = useLocalStyles();
  const { formatMessage } = useIntl();

  return (
    <>
      <Grid container className={localClasses.containerTabs}>
        <Grid item xs={9}>
          <LanguageMenu setLanguage={onLanguageChange} selectedLanguage={languageSelected} />
        </Grid>
        <Grid item xs={3} style={{ justifyContent: 'flex-end', textAlign: 'right' }}>
          <ActionButtonSecond
            id="translateButton"
            startIcon={<GTranslateIcon />}
            style={{ textTransform: 'none', height: 30 }}
            {...actionButtonProps}
          >
            {formatMessage({ id: 'templates.translate.importAndTranslate' })}
          </ActionButtonSecond>
        </Grid>

        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
      {!isNil(textsToTranslate) && (
        <TranslateDialog
          open={!isNil(textsToTranslate)}
          onClose={translateDialogProps.onDialogClose}
          afterSubmit={textsToTranslate.afterSubmit}
          texts={textsToTranslate.texts}
          entity={translateDialogProps.titleText}
          theEntity={translateDialogProps.helperText}
          ofTheEntity={translateDialogProps.warningText}
          defaultSourceLanguage={languageSelected}
          sourceLanguageDisabled={true}
        />
      )}
    </>
  );
};

export default TranslateWrapper;
