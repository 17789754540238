import { createStyles, Divider, Grid, makeStyles, Tab, Tabs } from '@material-ui/core';
import { Colors, Contact, getContactNameFromObject, LeaseInventory, LeaseInventoryType } from '@rentguru/commons-utils';
import {
  ActionButtonSecond,
  actionButtonSecondStyles,
  CustomizedTabs,
  ElevatedPaperTable,
  TextDetail,
} from '@up2rent/ui';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import InventoryEncodings from 'src/components/LeaseInventory/LeaseInventoryEncoding/InventoryEncodings';
import InventoryTechnicEncodings from 'src/components/LeaseInventory/LeaseInventoryEncoding/InventoryTechnicEncodings';
import AddRoomDialog from 'src/components/LeaseInventory/LeaseInventoryEncoding/LeaseInventoryDialogs/AddRoomDialog';
import FormHeader from 'src/components/ui/FormHeader';
import { useLeases } from 'src/hooks/LeasesContext';

export const GENERAL_TAB = 0;
export const FINISHES_TAB = 1;
export const EQUIPMENT_TAB = 2;

interface EditInsideValues {
  menu: string;
  sectionItems: string[][];
  unitId: string;
  leaseInventory: LeaseInventory;
}

const useStyles = makeStyles(() =>
  createStyles({
    customTab: {
      textTransform: 'capitalize',
      letterSpacing: 'normal',
      backgroundColor: Colors.PORCELAIN_GREY_3,
      borderRadius: 20,
    },
    customSelectedTab: {
      backgroundColor: Colors.CLASSICAL_WHITE,
      borderRadius: 20,
      border: '2px solid rgba(38, 50, 56, 0.1)',
    },
    indicatorStyle: {
      display: 'none',
    },
    wrapperSelected: {
      fontSize: 14,
      fontWeight: 700,
      color: Colors.CARNATION_RED,
    },
    wrapperNotSelected: {
      fontSize: 14,
      fontWeight: 400,
      color: Colors.SLATE_GREY,
    },
  })
);

const EditInventoryForm: React.FC<EditInsideValues> = ({ menu, sectionItems, unitId, leaseInventory }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const actionButtonClasses = actionButtonSecondStyles();

  const [tabIndex, setTabIndex] = useState<number>(GENERAL_TAB);
  const handleTabChange = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    setTabIndex(index);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [addRoomInside, setAddRoomInside] = useState<boolean>(true);
  const { getLease } = useLeases();
  const lease = getLease(leaseInventory.leaseId);

  return (
    <>
      {menu === sectionItems[0][0] && ( // General
        <>
          <ElevatedPaperTable style={{ maxWidth: 640, marginBottom: 20 }}>
            <FormHeader title={formatMessage({ id: 'leaseInventory.editMenu.type' })} />
            <Divider style={{ marginBottom: 20, marginTop: 10 }} />
            <div
              style={{
                backgroundColor: Colors.PORCELAIN_GREY_3,
                borderRadius: 20,
                marginBottom: 30,
                marginRight: 30,
                marginLeft: 30,
              }}
            >
              <Tabs
                value={leaseInventory.inventoryType}
                variant="fullWidth"
                centered
                classes={{ indicator: classes.indicatorStyle }}
                disabled={true}
              >
                <Tab
                  value={LeaseInventoryType.FIXTURES_ENTRANCE}
                  classes={{
                    root: classes.customTab,
                    selected: classes.customSelectedTab,
                    wrapper:
                      leaseInventory.inventoryType === LeaseInventoryType.FIXTURES_ENTRANCE
                        ? classes.wrapperSelected
                        : classes.wrapperNotSelected,
                  }}
                  label={formatMessage({ id: 'rentalUnit.detail.inventory.entrance' })}
                />
                <Tab
                  value={LeaseInventoryType.FIXTURES_EXIT}
                  classes={{
                    root: classes.customTab,
                    selected: classes.customSelectedTab,
                    wrapper:
                      leaseInventory.inventoryType === LeaseInventoryType.FIXTURES_EXIT
                        ? classes.wrapperSelected
                        : classes.wrapperNotSelected,
                  }}
                  label={formatMessage({ id: 'rentalUnit.detail.inventory.exit' })}
                />
              </Tabs>
            </div>
          </ElevatedPaperTable>

          <ElevatedPaperTable>
            <FormHeader title={formatMessage({ id: 'leaseInventory.lessorSide' })} />
            <Divider style={{ marginBottom: 20, marginTop: 10 }} />
            <Grid container style={{ marginLeft: 30, marginBottom: 20, textAlign: 'left', maxWidth: 580 }}>
              {lease!.lessors.map((lessor: Contact, index: number) => (
                <Grid key={lessor.id} item xs={4}>
                  <TextDetail
                    title={`${formatMessage({ id: 'lease.addLease.lessor' })} ${index + 1}`}
                    text={getContactNameFromObject(lessor)}
                  />
                </Grid>
              ))}
            </Grid>
          </ElevatedPaperTable>

          <ElevatedPaperTable>
            <FormHeader title={formatMessage({ id: 'leaseInventory.tenantSide' })} />
            <Divider style={{ marginBottom: 20, marginTop: 10 }} />
            <Grid container style={{ marginLeft: 30, marginBottom: 20, textAlign: 'left', maxWidth: 580 }}>
              {lease!.tenants.map((tenant: Contact, index: number) => (
                <Grid key={tenant.id} item xs={4}>
                  <TextDetail
                    title={`${formatMessage({ id: 'lease.detail.general.tenant' })} ${index + 1}`}
                    text={getContactNameFromObject(tenant)}
                  />
                </Grid>
              ))}
            </Grid>
          </ElevatedPaperTable>
        </>
      )}

      {(menu === sectionItems[0][1] || menu === sectionItems[0][2]) && ( // Inside & Outside
        <>
          <ElevatedPaperTable style={{ maxWidth: 640, marginBottom: 20 }}>
            <FormHeader
              title={formatMessage({
                id: `leaseInventory.topTabs.${menu === sectionItems[0][1] ? 'inside' : 'outside'}`,
              })}
            >
              <ActionButtonSecond
                className={actionButtonClasses.buttonWithMargin}
                onClick={(event) => {
                  setAddRoomInside(menu === sectionItems[0][1]); // menu === sectionItems[0][1] = inside
                  setAnchorEl(event.currentTarget);
                }}
              >
                {formatMessage({ id: 'leaseInventory.addRoom.title' })}
              </ActionButtonSecond>
            </FormHeader>
            <div style={{ marginTop: 10, marginLeft: 24 }}>
              <CustomizedTabs
                completeHandleChange={handleTabChange}
                tabStyle={{ minWidth: 10 }}
                value={tabIndex}
                dynamicDefaultTab
              >
                <Tab label={formatMessage({ id: 'leaseInventory.editMenu.general' })} />
                <Tab label={formatMessage({ id: 'leaseInventory.topTabs.finishes' })} />
                <Tab label={formatMessage({ id: 'leaseInventory.topTabs.equipment' })} />
              </CustomizedTabs>
            </div>
          </ElevatedPaperTable>

          <ElevatedPaperTable style={{ maxWidth: 640, marginBottom: 20, textAlign: 'left' }}>
            <InventoryEncodings inside={menu === sectionItems[0][1]} leaseInventory={leaseInventory} tab={tabIndex} />
          </ElevatedPaperTable>
        </>
      )}
      {
        menu === sectionItems[0][3] && (
          <>
            <ElevatedPaperTable style={{ maxWidth: 640, marginBottom: 20 }}>
              <FormHeader title={formatMessage({ id: 'leaseInventory.topTabs.technics' })} />
              <InventoryTechnicEncodings readOnly={false} />
            </ElevatedPaperTable>
          </>
        ) // Technics
      }
      <AddRoomDialog open={!isNil(anchorEl)} onClose={() => setAnchorEl(null)} inside={addRoomInside} unitId={unitId} />
    </>
  );
};

export default EditInventoryForm;
