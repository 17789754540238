/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable default-param-last */
import {
  cleanInputCreate,
  cleanInputUpdate,
  CreateInvoiceInput,
  CreateInvoiceMutationVariables,
  createInvoice as createMutation,
  DeleteInvoiceMutationVariables,
  deleteInvoice as deleteMutation,
  getInvoice as getQuery,
  getReadId,
  getTableClientId,
  Invoice,
  InvoiceType,
  isIdInList,
  LooseObject,
  syncInvoices,
  UpdateInvoiceInput,
  updateInvoice as updateMutation,
} from '@rentguru/commons-utils';
import { deleteEntityWithFetchBefore, get, list, mutation, NUMBER_OF_MINUTES_FOR_REFETCH } from '@up2rent/fetch-utils';
import { differenceInMinutes } from 'date-fns';
import { isEmpty } from 'lodash';
import React, { Reducer, useContext, useEffect, useMemo, useReducer } from 'react';
import { FetchByType } from './CommunicationsContext';
import { useLeases } from './LeasesContext';
import { fetchPostings, useTransactions } from './TransactionsContext';
import { useUser } from './UserContext';
import { usePermissions } from './utils/PermissionsContext';
import { syncInvoices as syncInvoicesForFolder } from './utils/cleanedQueries';

export interface InvoiceContext extends Omit<State, 'invoices'> {
  createInvoice: (input: CreateInvoiceInput | Omit<CreateInvoiceInput, 'clientId' | 'readId'>) => Promise<Invoice>;
  getInvoicesOfLease: (leaseId: string) => Invoice[];
  getUnpaidInvoicesOfLease: (leaseId: string) => Invoice[];
  getTenantStatementsInvoices: () => Invoice[];
  updateInvoice: (updates: UpdateInvoiceInput) => Promise<Invoice>;
  deleteInvoice: (invoice: Invoice, refetch?: boolean) => Promise<Invoice | null>;
}

interface State {
  loading: boolean;
  error: string | null;
  shouldFetchTenantStatements: boolean;
  shouldFetchLeases: string[];
  lastTenantStatementsFetch: Date | null;
  lastLeasesFetch: { [leaseId: string]: Date | null };
  invoices: Invoice[] | null;
}

type Action =
  | {
      type: 'FETCH_LEASE';
      payload: { leaseId: string; force?: boolean };
    }
  | {
      type: 'FETCH_TENANT_STATEMENTS';
    }
  | {
      type: 'FETCHED';
      payload: { invoices: Invoice[]; leaseId?: string };
    }
  | {
      type: 'ADD' | 'UPDATE';
      payload: { invoice: Invoice };
    }
  | {
      type: 'DELETE';
      payload: { id: string };
    };

const initialState: State = {
  loading: false,
  error: null,
  shouldFetchTenantStatements: false,
  shouldFetchLeases: [],
  lastTenantStatementsFetch: null,
  lastLeasesFetch: {},
  invoices: null,
};

const contextReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'FETCH_LEASE':
      // Check depending on param if we need to fetch
      const actionLeaseId = action.payload.leaseId;
      const lastLeaseIdFetch = state.lastLeasesFetch[actionLeaseId];
      const actionForce = action.payload.force;
      if (
        !actionLeaseId ||
        state.shouldFetchLeases.includes(actionLeaseId) ||
        (!actionForce &&
          lastLeaseIdFetch &&
          differenceInMinutes(new Date(), lastLeaseIdFetch) < NUMBER_OF_MINUTES_FOR_REFETCH)
      ) {
        return state;
      }
      return {
        ...state,
        shouldFetchLeases: [...state.shouldFetchLeases, actionLeaseId],
        loading: true,
      };
    case 'FETCH_TENANT_STATEMENTS':
      // Check lastFetch
      if (
        state.loading ||
        state.shouldFetchTenantStatements ||
        (state.lastTenantStatementsFetch &&
          differenceInMinutes(new Date(), state.lastTenantStatementsFetch) < NUMBER_OF_MINUTES_FOR_REFETCH)
      ) {
        return state;
      }
      return {
        ...state,
        shouldFetchTenantStatements: true,
        loading: true,
      };
    case 'FETCHED':
      // Build List with merged object
      const leaseId = action.payload.leaseId;
      const newInvoices = [
        ...action.payload.invoices,
        ...(state.invoices ?? []).filter(
          (oldInvoice) => !action.payload.invoices.some((newInvoice) => newInvoice.id === oldInvoice.id)
        ),
      ];
      if (leaseId) {
        const newShouldFetchLeases = state.shouldFetchLeases.filter((leaseIdToFetch) => leaseIdToFetch !== leaseId);
        return {
          ...state,
          loading: !isEmpty(newShouldFetchLeases) || state.shouldFetchTenantStatements,
          shouldFetchLeases: newShouldFetchLeases,
          lastLeasesFetch: { ...state.lastLeasesFetch, [leaseId]: new Date() },
          invoices: newInvoices,
        };
      }

      // Tenants Statement
      return {
        ...state,
        loading: !isEmpty(state.shouldFetchLeases),
        shouldFetchTenantStatements: false,
        lastTenantStatementsFetch: new Date(),
        invoices: newInvoices,
      };
    case 'ADD':
      if (!state.invoices) {
        return state;
      }
      const entity = action.payload.invoice;

      return {
        ...state,
        invoices: [...state.invoices, entity],
      };
    case 'UPDATE':
      if (!state.invoices) {
        return state;
      }

      return {
        ...state,
        invoices: state.invoices.map((item) => {
          if (item.id === action.payload.invoice.id) {
            return action.payload.invoice;
          }
          return item;
        }),
      };
    case 'DELETE':
      if (!state.invoices) {
        return state;
      }

      return {
        ...state,
        invoices: state.invoices.filter((item) => item.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export const fetchInvoices = async (
  by: 'byClientId' | 'byLease' | 'byStatement' | 'byType',
  byValue: string,
  additionalFilter?: LooseObject
): Promise<Invoice[]> => {
  const indexName =
    by === 'byClientId' ? 'clientId' : by === 'byStatement' ? 'statementId' : by === 'byType' ? 'type' : 'leaseId';
  return await list<Invoice>(syncInvoices, indexName, byValue, additionalFilter);
};

export const fetchInvoicesForFolders = async (by: FetchByType, byValue: string): Promise<Invoice[]> =>
  await list<Invoice>(syncInvoicesForFolder, by, byValue);

export const InvoiceContext = React.createContext<InvoiceContext | null>(null);

export const InvoiceContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { leasesCreationDelete } = usePermissions();
  const { clientId, rootUser } = useUser();
  const { leases, leasesLoading } = useLeases();
  const { deletePosting } = useTransactions();
  const [state, dispatch] = useReducer<Reducer<State, Action>>(contextReducer, initialState);

  useEffect(() => {
    let unmounted = false;
    const fetchAndSet = async () => {
      const result = await fetchInvoices('byType', InvoiceType.STATEMENT);
      const resultFiltered = rootUser ? result : result.filter((li) => li.lease && isIdInList(li.lease, leases));
      if (!unmounted) {
        dispatch({ type: 'FETCHED', payload: { invoices: resultFiltered } });
      }
    };
    if (state.shouldFetchTenantStatements && !leasesLoading) {
      fetchAndSet();
    }
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetchTenantStatements, leasesLoading]);

  useEffect(() => {
    let unmounted = false;
    const fetchAndSet = async () => {
      const leaseId = state.shouldFetchLeases[0];
      if (!isEmpty(leaseId)) {
        const result = await fetchInvoices('byLease', leaseId);
        const resultFiltered = rootUser ? result : result.filter((li) => li.lease && isIdInList(li.lease, leases));
        if (!unmounted) {
          dispatch({ type: 'FETCHED', payload: { invoices: resultFiltered, leaseId } });
        }
      }
    };
    if (state.shouldFetchLeases && !leasesLoading) {
      fetchAndSet();
    }
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shouldFetchLeases, leasesLoading]);

  const createInvoice = async (
    input: CreateInvoiceInput | Omit<CreateInvoiceInput, 'clientId' | 'readId'>
  ): Promise<Invoice> => {
    const invoice = await mutation<Invoice, CreateInvoiceMutationVariables>(createMutation, {
      input: {
        ...(cleanInputCreate(input) as CreateInvoiceInput),
        clientId: getTableClientId(clientId!, 'Invoice'),
        readId: getReadId(clientId!, 'Invoice'),
      },
    });
    dispatch({ type: 'ADD', payload: { invoice } });
    if (input.leaseId) {
      dispatch({ type: 'FETCH_LEASE', payload: { leaseId: input.leaseId, force: true } });
    }
    return invoice;
  };

  const getInvoicesOfLease = (leaseId: string) => {
    dispatch({ type: 'FETCH_LEASE', payload: { leaseId } });
    if (state.loading || state.error || !state.invoices) {
      return [];
    }

    return state.invoices.filter((invoice) => invoice.lease && invoice.lease.id === leaseId);
  };

  const getUnpaidInvoicesOfLease = (leaseId: string) => {
    dispatch({ type: 'FETCH_LEASE', payload: { leaseId } });
    if (state.loading || state.error || !state.invoices) {
      return [];
    }

    return state.invoices.filter((invoice) => invoice.lease && invoice.lease.id === leaseId && !invoice.paid);
  };

  const getTenantStatementsInvoices = () => {
    dispatch({ type: 'FETCH_TENANT_STATEMENTS' });
    if (state.loading || state.error || !state.invoices) {
      return [];
    }

    return state.invoices.filter((invoice) => invoice.type === InvoiceType.STATEMENT);
  };

  const updateInvoice = async (updates: UpdateInvoiceInput): Promise<Invoice> => {
    const invoice = await get<Invoice>(getQuery, updates.id);
    const result = await mutation<Invoice>(updateMutation, {
      input: { ...cleanInputUpdate(updates, false), _version: invoice._version },
    });
    dispatch({ type: 'UPDATE', payload: { invoice: result } });
    if (updates.leaseId) {
      dispatch({ type: 'FETCH_LEASE', payload: { leaseId: updates.leaseId, force: true } });
    }

    return result;
  };

  const deleteInvoice = async (invoice: Invoice): Promise<Invoice | null> => {
    if (!leasesCreationDelete) {
      return null;
    }

    const result = await deleteEntityWithFetchBefore<Invoice, DeleteInvoiceMutationVariables>(
      invoice,
      getQuery,
      deleteMutation
    );

    const postingsToDelete = await fetchPostings('byInvoice', invoice.id);
    const deletedPostingsPromises = postingsToDelete.map((postingToDelete) => deletePosting(postingToDelete));
    await Promise.all(deletedPostingsPromises);

    dispatch({ type: 'DELETE', payload: { id: invoice.id } });
    if (invoice.leaseId) {
      dispatch({ type: 'FETCH_LEASE', payload: { leaseId: invoice.leaseId, force: true } });
    }
    return result;
  };

  const values = useMemo(
    () => ({
      ...state,
      createInvoice,
      getInvoicesOfLease,
      getUnpaidInvoicesOfLease,
      getTenantStatementsInvoices,
      updateInvoice,
      deleteInvoice,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <InvoiceContext.Provider value={values}>{children}</InvoiceContext.Provider>;
};

export const useInvoices = (): InvoiceContext => {
  const context = useContext<InvoiceContext | null>(InvoiceContext);

  if (!context) {
    throw new Error('`useInvoices` hook must be used within a `InvoiceContext` component');
  }

  return context;
};
