import { Divider, Grid, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { AdvertisementUnitSubType, AdvertisementUnitType, Colors } from '@rentguru/commons-utils';
import { FormikProps, useFormikContext } from 'formik';
import { isNil } from 'lodash';
import { useIntl } from 'react-intl';
import CustomizedSelectWithImportance from '../CustomizedSelectWithImportance';
import { EditAdvertisementFormValues } from '../EditPublicationForm';
import { typesAndSubTypesArray } from '../PublicationUtils';

const useLocalStyles = makeStyles({
  rootSelect: {
    '& > *': {
      width: 280,
    },
  },
  h6: {
    flex: 1,
    textAlign: 'left' as const,
    color: Colors.CLASSICAL_BLACK,
    fontFamily: 'Mulish',
    fontSize: '16px',
    fontWeight: 700,
    margin: '20px 0 15px 30px',
  },
});

const UnitTypeEdits = () => {
  const classes = useLocalStyles();
  const { formatMessage } = useIntl();
  const { values, setFieldValue }: FormikProps<EditAdvertisementFormValues> = useFormikContext();

  return (
    <Grid>
      <Typography variant="h6" color="inherit" className={classes.h6}>
        {formatMessage({ id: 'rentalUnit.editUnit.advertisement.general.unitType' })}
      </Typography>
      <Grid container style={{ paddingLeft: 10, marginBottom: 30 }}>
        <Grid item xs={6} style={{ maxWidth: 280, paddingLeft: 17 }}>
          <CustomizedSelectWithImportance
            id="typeSeclect"
            label={formatMessage({ id: `rentalUnit.editUnit.advertisement.general.unitType` })}
            fieldName="advertisementType"
            value={values.advertisement.type}
            className={classes.rootSelect}
            error={false}
            margin="dense"
            importance="mandatory"
          >
            {Object.keys(AdvertisementUnitType).map((key) => (
              <MenuItem
                id={key}
                key={key}
                value={key}
                onClick={() => {
                  setFieldValue('advertisement.type', key);
                  setFieldValue('advertisement.subType', '');
                }}
              >
                {formatMessage({ id: `rentalUnit.editUnit.advertisement.general.types.${key}` })}
              </MenuItem>
            ))}
          </CustomizedSelectWithImportance>
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 44, maxWidth: 280 }}>
          <CustomizedSelectWithImportance
            id="subTypeSelect"
            label={formatMessage({ id: `rentalUnit.editUnit.advertisement.general.unitSubType` })}
            fieldName="advertisementSubType"
            value={(values.advertisement.subType as AdvertisementUnitSubType) ?? ''}
            className={classes.rootSelect}
            error={false}
            margin="dense"
            disabled={isNil(values.advertisement.type) || values.advertisement.type === AdvertisementUnitType.SPECIAL}
            importance="recommended"
          >
            {!isNil(values.advertisement.type) &&
              values.advertisement.type !== '' &&
              typesAndSubTypesArray[values.advertisement.type!].map((type) => (
                <MenuItem
                  id={type as AdvertisementUnitSubType}
                  key={type as AdvertisementUnitSubType}
                  value={type as AdvertisementUnitSubType}
                  onClick={() => {
                    setFieldValue('advertisement.subType', type);
                  }}
                >
                  {formatMessage({ id: `rentalUnit.editUnit.advertisement.general.subTypes.${type}` })}
                </MenuItem>
              ))}
          </CustomizedSelectWithImportance>
        </Grid>
      </Grid>
      <Divider />
    </Grid>
  );
};

export default UnitTypeEdits;
