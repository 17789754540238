import {
  Contact,
  getContactNameFromObject,
  InvoiceType,
  InvoiceWithPostings,
  LeaseExtended,
  LeaseStatus,
  LooseObject,
  Posting,
  Transaction,
  TransactionLinkType,
} from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import { CustomMenuItemType } from 'src/components/ui/ComboBox/TextComboBox';
import { fetchInvoices } from 'src/hooks/InvoicesContext';
import { fetchPostings } from 'src/hooks/TransactionsContext';
import { TransactionWithAmountLinked } from './Reconciliation/LeaseTransactionsReconcilation';

export const filterLeasesForLink = (leases: LeaseExtended[]) => {
  return leases.filter((lease) => lease.status === LeaseStatus.Active || lease.status === LeaseStatus.Ended);
};

export const getLeaseCustomMenuItems = (transaction: Transaction, leases: LeaseExtended[]): CustomMenuItemType[] => {
  return leases.reduce((acc: CustomMenuItemType[], lease: LeaseExtended) => {
    if (!isNil(lease.units)) {
      acc.push({
        primary: `${lease.name}${
          !isNil(transaction.proposedLeaseId) &&
          !isNil(transaction.proposedMatch) &&
          transaction.proposedLeaseId === lease.id
            ? ` - ${transaction.proposedMatch}%`
            : ''
        }`,
        secondary: lease.units.map((u) => u.unit?.name ?? '').join(', '),
        value: { leaseId: lease.id },
        isUpper: lease.bankAccountId === transaction.bankAccountId,
      });
    }
    return acc;
  }, [] as CustomMenuItemType[]);
};

export const getContractorCustomMenuItems = (
  transaction: Transaction,
  contractors: Contact[]
): CustomMenuItemType[] => {
  return contractors.map((contractor) => getContractorCustomMenuItem(contractor, transaction));
};

export const getContractorCustomMenuItem = (contractor: Contact, transaction?: Transaction) => ({
  primary: `${getContactNameFromObject(contractor)}${
    !isNil(transaction?.proposedContractorId) && transaction?.proposedContractorId === contractor.id
      ? ` - ${transaction?.proposedMatch}%`
      : ''
  }`,
  value: { contractorId: contractor.id },
});

export const getLeaseInvoicesWithPostings = async (leaseId: string, filter?: LooseObject) => {
  const unpaidInvoicesOfLease = await fetchInvoices('byLease', leaseId, filter);
  const invoicesWithPostings = await Promise.all(
    unpaidInvoicesOfLease.map(async (unpaidInvoice) => {
      const unpaidInvoicesPostings = await fetchPostings('byInvoice', unpaidInvoice.id);
      return {
        ...unpaidInvoice,
        postings: unpaidInvoicesPostings,
        amount:
          unpaidInvoice.creditNote || unpaidInvoice.type === InvoiceType.VARIOUS_OPERATION
            ? -unpaidInvoice.amount
            : unpaidInvoice.amount,
      };
    })
  );
  return invoicesWithPostings;
};

export const getPostingSelectedOfTransaction = (
  transaction: Transaction,
  invoiceWithPostings: InvoiceWithPostings[]
) => {
  if (!transaction.postings) return [];
  return transaction.postings.reduce((acc: Posting[], posting) => {
    if (posting.invoiceId && posting.invoicePostingId) {
      const completePosting = getCompletePostingByInvoices(
        invoiceWithPostings,
        posting.invoiceId,
        posting.invoicePostingId
      );
      if (completePosting) acc.push(completePosting);
    }
    return acc;
  }, []);
};

export const getCompletePostingByInvoices = (
  invoicesWithPostings: InvoiceWithPostings[],
  invoiceId: string,
  postingId: string
) => {
  const invoiceWithPostings = invoicesWithPostings.find((ip) => ip.id === invoiceId);
  return invoiceWithPostings ? invoiceWithPostings.postings.find((p) => p.id === postingId) : undefined;
};

export const isTransactionPartiallyReconciled = (transaction: TransactionWithAmountLinked) => {
  return transaction.amountLinked > 0 && transaction.amountLinked === transaction.amount;
};

export const isUnlinkOfTransactionAllowed = (transactionPostings: Posting[]) => {
  return !transactionPostings.some((p) => !isNil(p.statementId));
};

export const getTransactionAmountBasedOnLinks = (transaction: Transaction, leaseId?: string) =>
  transaction.links?.reduce((amount, currentLink) => {
    if (currentLink.linkType === TransactionLinkType.LEASE) {
      if (leaseId && currentLink.linkId !== leaseId) return amount;
      amount += currentLink.amount;
    }
    return amount;
  }, 0) ?? 0;
