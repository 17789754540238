import { Divider, Grid, Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { Colors, getMainUnit, UnitLease, UnitType } from '@rentguru/commons-utils';
import { CustomPopover, UnitTypeIcon } from '@up2rent/ui';
import React from 'react';
import { useIntl } from 'react-intl';
import UnitNumbersPicto from 'src/components/ui/UnitNumbersPicto';

interface LeaseMainUnitColumnProps {
  anchorEl: HTMLElement | null | undefined;
  unitLeases: UnitLease[];
  open: boolean;
  handleClose: () => void;
  pictoContainerStyle?: CSSProperties;
}
const LeaseMainUnitColumn: React.FC<LeaseMainUnitColumnProps> = ({
  unitLeases,
  open,
  pictoContainerStyle,
  anchorEl,
  handleClose,
}) => {
  const { formatMessage } = useIntl();
  const mainUnit = getMainUnit(unitLeases);

  const isMultiUnit = unitLeases.length > 1;
  return (
    <Grid>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            style={{
              fontSize: 14,
              color: Colors.SLATE_GREY,
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 'normal',
              letterSpacing: 'normal',
            }}
          >
            {mainUnit ? mainUnit.name : ''}
          </Typography>
          {isMultiUnit && (
            <UnitNumbersPicto
              value={unitLeases.length}
              containerStyle={{ border: 'none', backgroundColor: 'transparent', ...pictoContainerStyle }}
            />
          )}
        </div>
        <Typography
          style={{
            fontSize: 12,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: Colors.BLUEY,
            paddingTop: 4,
          }}
        >
          {mainUnit?.building?.name ?? ''}
        </Typography>
      </div>
      {isMultiUnit && open && (
        <CustomPopover
          id="unit-list-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 10,
            horizontal: 'left',
          }}
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            minWidth: 200,
            minHeight: 16,
          }}
          onClick={(e) => e.stopPropagation()}
          onClose={handleClose}
          disableRestoreFocus
          paperWidth={'auto'}
          paperPadding={'10px 20px'}
          paperBorderRadius={10}
        >
          <Typography style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}>
            {formatMessage(
              {
                id: 'lease.addLease.subUnit',
              },
              { value: unitLeases.length - 1 /* -1 = mainUnit */ }
            )}
          </Typography>
          {unitLeases.map((unitLease, index) => {
            if (unitLease.mainUnit) {
              return null;
            }
            return (
              <React.Fragment key={unitLease.id}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <UnitTypeIcon unitType={unitLease.unit?.type as UnitType} />
                  <Typography
                    style={{
                      paddingLeft: 10,
                      fontSize: 14,
                      color: Colors.SLATE_GREY,
                      fontWeight: 'normal',
                      fontStretch: 'normal',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      letterSpacing: 'normal',
                    }}
                  >
                    {unitLease.unit?.name}
                  </Typography>
                </div>
                {index !== unitLeases.length - 1 && <Divider />}
              </React.Fragment>
            );
          })}
          {/* <LeaseBalancePopoverColumn leaseId={leaseId} /> */}
        </CustomPopover>
      )}
    </Grid>
  );
};

export default LeaseMainUnitColumn;
