import { Button, Grid, Table, TableBody, TableHead } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Colors } from '@rentguru/commons-utils';
import isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { useIntl } from 'react-intl';
import { stopPropagation } from 'src/components/Dashboard/Dashboard';
import { useDashboardFilters } from 'src/hooks/DashboardFiltersContext';

export const useStyles = makeStyles(() =>
  createStyles({
    expansionText: {
      color: Colors.BLUE_GREY,
      cursor: 'pointer',
      textTransform: 'none',
    },
  })
);

interface NotificationBoxProps {
  data: React.ReactNode[];
  type?: 'table' | 'list';
  header?: React.ReactNode;
  limit?: number;
  expandButtonStyle?: React.CSSProperties;
  rowHeight?: number;
  boxKey?: string;
  forceUpdateLayout?: React.Dispatch<React.SetStateAction<Layout[]>>;
  customFooter?: React.ReactNode;
}

const SlicedContent: React.FC<NotificationBoxProps> = ({
  data,
  type = 'table',
  header,
  limit = 3,
  expandButtonStyle = {},
  rowHeight,
  boxKey,
  customFooter,
}) => {
  const [baseLimit] = useState(limit);
  const ONE_GRID_UNIT = 11; // 1 h = 11 pixels. No idea why.
  const PADDING_TOP_HEIGHT = 16 / ONE_GRID_UNIT; // 16px is the top padding
  const TOP_HEIGHT = 30 / ONE_GRID_UNIT; // 46 pixels is the top height
  const TAB_MENU_HEIGHT = 38.5 / ONE_GRID_UNIT; // 16px is the bottom padding
  const BUTTON_HEIGHT = 40 / ONE_GRID_UNIT; // 40 pixels is the button height
  const PADDING_BOT_HEIGHT = 16 / ONE_GRID_UNIT; // 16 is the bottom padding
  const UNKNOWN_DIFFERENCE = 10 / ONE_GRID_UNIT;
  const heightNumber = (rowHeight ?? 0) / ONE_GRID_UNIT;
  const { saveNewBoxToDoLayout } = useDashboardFilters();
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [tableLimit, setTableLimit] = useState(limit);
  const slicedData = data.slice(0, tableLimit);
  let expansionText: string;
  let expansionAction: () => void;
  if (tableLimit < data.length) {
    expansionText = formatMessage({ id: 'dashboard.seeMore' });
    expansionAction = () => {
      setTableLimit(Math.min(data.length, tableLimit + limit));
    };
  } else {
    expansionText = formatMessage({ id: 'dashboard.seeLess' });
    expansionAction = () => setTableLimit(Math.min(limit, data.length));
  }

  useEffect(() => {
    const multiplier = Math.min(tableLimit, data.length);
    let finalHeight =
      heightNumber * multiplier +
      PADDING_TOP_HEIGHT +
      TOP_HEIGHT +
      PADDING_BOT_HEIGHT +
      TAB_MENU_HEIGHT +
      UNKNOWN_DIFFERENCE;

    if (tableLimit <= data.length && data.length !== baseLimit) {
      finalHeight += BUTTON_HEIGHT;
    }
    saveNewBoxToDoLayout(boxKey ?? '', false, finalHeight);
    // Do not want to execute this method to much
    // eslint-disable-next-line
  }, [tableLimit]);

  return (
    <>
      {type === 'table' && (
        <Grid onMouseDown={stopPropagation}>
          <Table>
            {!isNil(header) && <TableHead>{header}</TableHead>}
            <TableBody>{slicedData}</TableBody>
          </Table>
        </Grid>
      )}
      {type === 'list' && <>{slicedData}</>}
      {!customFooter && data.length > limit && (
        <div style={{ lineHeight: 2, ...expandButtonStyle }}>
          <Button
            onClick={expansionAction}
            className={classes.expansionText}
            size="small"
            onMouseDown={stopPropagation}
          >
            {expansionText}
          </Button>
        </div>
      )}
      {customFooter}
    </>
  );
};
export default SlicedContent;
