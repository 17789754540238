import { Grid, Tooltip, Typography } from '@material-ui/core';
import {
  Colors,
  LeaseExtended,
  OperationType,
  UnitLease,
  getUTCDateWithoutTime,
  shouldShowBaseIndexDateWarning,
} from '@rentguru/commons-utils';
import { TextDetail } from '@up2rent/ui';
import { format } from 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';
import { getOperationCalculationDefinition } from 'src/components/Leases/LeasePriceHistory/Unit/UnitBodyDetail/CalculationTable';
import { ReactComponent as WarningIcon } from 'src/icons/warning.svg';
interface IndexationRuleProps {
  lease?: LeaseExtended | null;
}

const IndexationRule: React.FC<IndexationRuleProps> = ({ lease }) => {
  const { formatMessage } = useIntl();

  const mainUnitLease = lease?.units?.find((unit) => unit.mainUnit) as UnitLease;

  const { advancedIndexation, baseIndexDate, baseIndexType, baseYear, indexType } = mainUnitLease;

  const shouldShowWarningForBaseIndexDate = advancedIndexation
    ? shouldShowBaseIndexDateWarning(
        baseIndexType!,
        getUTCDateWithoutTime(lease!.startDate!),
        getUTCDateWithoutTime(baseIndexDate!)
      )
    : false;

  return advancedIndexation ? (
    <Grid
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        marginBottom: 20,
        padding: 30,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
      }}
    >
      <Typography variant="h6">{formatMessage({ id: 'lease.detail.indexationGeneral.indexationRule' })}</Typography>
      <Grid container spacing={2} style={{ marginTop: 5 }}>
        <Grid item xs={4}>
          <TextDetail
            title={formatMessage({ id: 'lease.detail.indexationGeneral.indexationRuleType' })}
            text={formatMessage({ id: 'lease.detail.indexationGeneral.custom' })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextDetail
            title={formatMessage({ id: 'lease.addLease.indexType' })}
            text={formatMessage({ id: `enums.IndexType.${indexType}` })}
            needTooltip
          />
        </Grid>
        <Grid item xs={4}>
          <TextDetail title={formatMessage({ id: 'lease.addLease.baseYear' })} text={baseYear ?? ''} />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 5 }}>
        <Grid item xs={4}>
          <TextDetail
            title={formatMessage({ id: 'lease.addLease.baseIndexType' })}
            text={formatMessage({ id: `enums.BaseIndexType.${baseIndexType}` })}
            needTooltip
          />
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
          <TextDetail
            title={formatMessage({ id: 'lease.addLease.baseIndexDate' })}
            text={baseIndexDate ? format(new Date(baseIndexDate), 'MM/yyyy') : ''}
          />
          {shouldShowWarningForBaseIndexDate && (
            <Tooltip
              title={formatMessage({ id: 'lease.addLease.noIndexationUntilbaseIndexDateReached' })}
              placement="bottom"
            >
              <Grid>
                <WarningIcon style={{ marginLeft: 10, fill: Colors.SUN_YELLOW }} />
              </Grid>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 5 }}>
        <Grid item xs={4}>
          <TextDetail
            title={formatMessage({ id: 'lease.detail.indexationGeneral.calculation' })}
            text=""
            style={{ paddingBottom: 10 }}
          />
          {advancedIndexation && getOperationCalculationDefinition(OperationType.INDEXOPERATION, formatMessage)}
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

export default IndexationRule;
