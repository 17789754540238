export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        createdBy
        type
        message
        popupDate
        expirationDate
        status
        createdAt
        buildingId
        building {
          id
          name
          addressId
          acquisitionValue
          acquisitionAdditionalCostsValue
          acquisitionAdvertisedValue
          amountOfFacades
          amountOfFloors
          amountOfHousings
          buildingState
          cadastralIncome
          constructionYear
          janitor
          jointOwnershipId
          propertyTax
          residenceName
          readers
          teams
          readId
          clientId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        unitId
        unit {
          id
          acquisitionAdditionalCostsValue
          acquisitionAdvertisedValue
          acquisitionValue
          advertisedMonthlyCharges
          advertisedRentalPrice
          cadastralIncome
          createdAt
          floor
          jointOwnershipId
          managementDate
          name
          predictedPrice
          propertyTax
          publishAddress
          surface
          type
          buildingId
          unitAdvertisementId
          readers
          readId
          clientId
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        leaseId
        lease {
          id
          balance
          canBeExtended
          createdAt
          endDate
          includeRealEstateInLeaseContract
          invoiceSenderId
          inventoryOfFixturesExpert
          inventoryOfFixturesMethod
          inventoryOfFixturesType
          ipiNumber
          language
          lastIndexationProcessDate
          lastInvoiceDate
          legalCompetentAuthority
          name
          paymentFrequency
          paymentInvoicePeriod
          paymentInvoicePeriodCustomDay
          realEstateAgency
          realEstateTax
          remittanceInformation
          rentalGuarantyAmount
          rentalGuarantyFinancialInstitution
          rentalGuarantyType
          signatureDate
          startDate
          status
          totalInitialMonthlyCharges
          totalInitialRentalPrice
          totalMonthlyCharges
          totalRentalPrice
          type
          vatRateCharge
          vatRateRent
          bankAccountId
          readers
          readId
          clientId
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        technicId
        ticketId
        ticket {
          id
          assignedAt
          assignedToId
          buildingId
          closedAt
          contactId
          content
          createdAt
          emailMessageId
          emailReferences
          parentId
          plannedAt
          referenceCode
          sourceType
          status
          subject
          unitId
          unread
          updatedAt
          readers
          readId
          clientId
          _version
          _deleted
          _lastChangedAt
        }
        readId
        clientId
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

/**
 * We are removing linked entities because Technic is handled by custom lambda authorizer.
 * We need this because auth methods are not inter-compatible. So we cannot have technic.linkedEntity in query.
 */
export const syncTechnics = /* GraphQL */ `
  query SyncTechnics($filter: ModelTechnicFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncTechnics(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        type
        contractNumber
        maintenanceHistory {
          date
          contactId
          fileId
        }
        utilityType
        utilityEanCode
        utilityMeterNumber
        utilityDistributorId
        utilityHistory {
          reading
          secondReading
          date
          reason
        }
        PebValidityDate
        PebEnergeticPerformance
        PebReportNumber
        PebIssueDate
        detectorType
        detectorSerialNumber
        detectorBrand
        detectorLastControlDate
        detectorUnitInventoryId
        heatingBrand
        heatingModel
        heatingInstallationDate
        heatingType
        heatingLastControlDate
        tankType
        tankCapacity
        tankState
        tankLastControlDate
        tankLeakDetection
        tankEncuvement
        tankWallType
        tankInstallationDate
        chimneyLastControlDate
        chimneyUnitInventoryId
        buildingId
        unitId
        leaseId
        contactId
        originalTechnicId
        communicationSettingsProfileId
        readers
        writers
        readId
        clientId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getTechnic = /* GraphQL */ `
  query GetTechnic($id: ID!) {
    getTechnic(id: $id) {
      id
      type
      contractNumber
      maintenanceHistory {
        date
        contactId
        fileId
      }
      utilityType
      utilityEanCode
      utilityMeterNumber
      utilityDistributorId
      PebValidityDate
      PebIssueDate
      PebEnergeticPerformance
      PebReportNumber
      detectorType
      detectorSerialNumber
      detectorBrand
      detectorLastControlDate
      detectorUnitInventoryId
      heatingBrand
      heatingModel
      heatingInstallationDate
      heatingType
      heatingLastControlDate
      tankType
      tankCapacity
      tankState
      tankLastControlDate
      tankLeakDetection
      tankEncuvement
      tankWallType
      tankInstallationDate
      chimneyLastControlDate
      chimneyUnitInventoryId
      buildingId
      unitId
      leaseId
      contactId
      originalTechnicId
      communicationSettingsProfileId
      readers
      writers
      readId
      clientId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createTechnic = /* GraphQL */ `
  mutation CreateTechnic($input: CreateTechnicInput!, $condition: ModelTechnicConditionInput) {
    createTechnic(input: $input, condition: $condition) {
      id
      type
      contractNumber
      maintenanceHistory {
        date
        contactId
        fileId
      }
      utilityType
      utilityEanCode
      utilityMeterNumber
      utilityDistributorId
      utilityHistory {
        reading
        date
        reason
        secondReading
      }
      PebValidityDate
      PebEnergeticPerformance
      PebReportNumber
      PebIssueDate
      detectorType
      detectorSerialNumber
      detectorBrand
      detectorLastControlDate
      detectorUnitInventoryId
      heatingBrand
      heatingModel
      heatingInstallationDate
      heatingType
      heatingLastControlDate
      tankType
      tankCapacity
      tankState
      tankLastControlDate
      tankLeakDetection
      tankEncuvement
      tankWallType
      tankInstallationDate
      chimneyLastControlDate
      chimneyUnitInventoryId
      buildingId
      unitId
      leaseId
      contactId
      originalTechnicId
      communicationSettingsProfileId
      readers
      writers
      readId
      clientId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const deleteTechnic = /* GraphQL */ `
  mutation DeleteTechnic($input: DeleteTechnicInput!, $condition: ModelTechnicConditionInput) {
    deleteTechnic(input: $input, condition: $condition) {
      id
      type
      contractNumber
      maintenanceHistory {
        date
        contactId
        fileId
      }
      utilityType
      utilityEanCode
      utilityMeterNumber
      utilityDistributorId
      utilityHistory {
        reading
        date
        reason
      }
      PebValidityDate
      PebIssueDate
      PebEnergeticPerformance
      PebReportNumber
      detectorType
      detectorSerialNumber
      detectorBrand
      detectorLastControlDate
      detectorUnitInventoryId
      heatingBrand
      heatingModel
      heatingInstallationDate
      heatingType
      heatingLastControlDate
      tankType
      tankCapacity
      tankState
      tankLastControlDate
      tankLeakDetection
      tankEncuvement
      tankWallType
      tankInstallationDate
      chimneyLastControlDate
      chimneyUnitInventoryId
      buildingId
      unitId
      leaseId
      contactId
      communicationSettingsProfileId
      readers
      writers
      readId
      clientId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateTechnic = /* GraphQL */ `
  mutation UpdateTechnic($input: UpdateTechnicInput!, $condition: ModelTechnicConditionInput) {
    updateTechnic(input: $input, condition: $condition) {
      id
      type
      contractNumber
      maintenanceHistory {
        date
        contactId
        fileId
      }
      utilityType
      utilityEanCode
      utilityMeterNumber
      utilityDistributorId
      utilityHistory {
        reading
        date
        reason
      }
      PebValidityDate
      PebIssueDate
      PebEnergeticPerformance
      PebReportNumber
      detectorType
      detectorSerialNumber
      detectorBrand
      detectorLastControlDate
      detectorUnitInventoryId
      heatingBrand
      heatingModel
      heatingInstallationDate
      heatingType
      heatingLastControlDate
      tankType
      tankCapacity
      tankState
      tankLastControlDate
      tankLeakDetection
      tankEncuvement
      tankWallType
      tankInstallationDate
      chimneyLastControlDate
      chimneyUnitInventoryId
      buildingId
      unitId
      leaseId
      contactId
      communicationSettingsProfileId
      readers
      writers
      readId
      clientId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const syncInvoices = /* GraphQL */ `
  query SyncInvoices($filter: ModelInvoiceFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncInvoices(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        amount
        bankAccountId
        communicationId
        createdAt
        creditNote
        creditNoteLabel
        dueDate
        invoiceDate
        invoiceNumber
        leaseId
        paid
        receiptNumber
        reminderDates
        remittanceInformation
        statementId
        type
        unitId
        readers
        readId
        clientId
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const syncCharges = /* GraphQL */ `
  query SyncCharges($filter: ModelChargeFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncCharges(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        status
        documentId
        postings {
          items {
            id
            buildingId
            unitId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        readId
        clientId
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
