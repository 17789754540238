import { Unit, UnitType } from '@rentguru/commons-utils';
import { UnitTypeIcon } from '@up2rent/ui';
import { FormikProps, useFormikContext } from 'formik';
import isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import ComboBox, { CustomizedComboBoxProps, CustomMenuItemType } from '../../ComboBox/TextComboBox';

export interface UnitSelectorFormValues {
  unit: {
    id?: string;
  };
  unitOwnerId: string | null;
}

export const UnitSelectorSchema = Yup.object()
  .shape({
    unit: Yup.object().shape({
      id: Yup.string().min(1).required(),
    }),
  })
  .required();

interface UnitSelectorFieldProps extends Omit<CustomizedComboBoxProps, 'onChange' | 'initialValue' | 'label' | 'data'> {
  units: Unit[];
  labelId?: string | undefined;
  fieldName: string;
  initialValue?: string;
  onChange?: (event: string) => void;
  style?: React.CSSProperties;
  dataTest?: string;
}

const UnitSelectorField: React.FC<UnitSelectorFieldProps> = ({
  units,
  labelId,
  fieldName,
  initialValue: initialUnitId,
  onChange,
  style,
  dataTest = 'unitSelector',
  ...rest
}) => {
  const { errors, touched, setFieldValue }: FormikProps<UnitSelectorFormValues> = useFormikContext();
  const { formatMessage } = useIntl();

  const unitsMenu = units.map((u) => {
    return {
      value: u.id,
      secondary: u.building!.name,
      primary: u.name,
      renderIcon: () => <UnitTypeIcon unitType={u.type as UnitType} />,
    };
  });
  const initialUnit = units.find((u) => u.id === initialUnitId);
  let initialValue;
  if (!isNil(initialUnit)) {
    initialValue = {
      primary: initialUnit.name,
      secondary: initialUnit.building!.name,
      value: initialUnit.id,
      renderIcon: () => <UnitTypeIcon unitType={initialUnit.type as UnitType} />,
    };
  }
  return (
    <div
      style={{
        alignItems: 'baseline',
        display: 'flex',
        ...style,
      }}
    >
      <ComboBox
        label={formatMessage({
          id: labelId ?? 'lease.addLease.unit',
        })}
        data={unitsMenu}
        onChange={(_e: unknown, value: CustomMenuItemType | null) => {
          const safeValue = isNil(value) ? null : (value as CustomMenuItemType).value;
          if (!isNil(onChange)) {
            onChange(safeValue);
          } else {
            setFieldValue(fieldName, safeValue);
          }
        }}
        initialValue={initialValue}
        noOptionsText={formatMessage({ id: 'comboBox.noUnits' })}
        error={Boolean(
          (errors.unit && errors.unit.id && touched.unit && touched.unit.id) || (errors.unit && touched.unit)
        )}
        data-test={dataTest}
        {...rest}
      />
    </div>
  );
};

export default UnitSelectorField;
