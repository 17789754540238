/* eslint-disable react/no-array-index-key */
/* eslint-disable func-names */
import { Collapse, Divider, Grid, InputAdornment, MenuItem, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import {
  Colors,
  Contact,
  ContactType,
  File as FileModel,
  Lease,
  Technic,
  TechnicType,
  UtilityType,
  getMetricFromUtilityType,
  isBeforeOrEqual,
  isDateValid,
  isNilOrEmpty,
} from '@rentguru/commons-utils';
import { ActionButton, TextDetailEditable } from '@up2rent/ui';
import { FormikErrors, FormikProps, useFormikContext } from 'formik';
import { isEmpty, toNumber, toUpper } from 'lodash';
import isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import AddTechnicDialogComplementaryData, {
  AddTechnicDialogComplementaryDataFormValues,
} from 'src/components/Technics/AddTechnicDialogComplementaryData';
import FormikDatePicker from 'src/components/ui/FormikDatePicker';
import SimpleContactSelector from 'src/components/ui/SimpleSelectors/SimpleContactSelector';
import SkeletonComponent from 'src/components/ui/SkeletonComponent';
import { useContacts } from 'src/hooks/ContactsContext';
import * as Yup from 'yup';
import FormikCustomizedSelect from '../../FormikCustomizedSelect';
import FieldsDeleteIconButton from './utils/FieldsDeleteIconButton';

export interface UtilityProvidersFormValues extends AddTechnicDialogComplementaryDataFormValues {
  utilityProviders: NewUtilityProvider[];
}

export interface NewUtilityProvider
  extends Omit<
    Technic,
    'id' | 'clientId' | 'readId' | 'utilityIndexDates' | 'utilityReadings' | 'communicationSettingsProfileId'
  > {
  id?: string;
  utilityIndexDate: Date | string | null;
  utilityReading: number | null;
  utilitySecondReading?: number | null;
  contactId?: string;
  contact?: Contact | null;
  files?: FileModel[] | null;
  lease?: Lease | null;
  distributorId?: string;
}

interface UtilityProvidersFieldsProps {
  multiple?: boolean;
  editMode?: boolean;
  showComplementaryData?: boolean;
}

export const UtilityProvidersSchema = Yup.object()
  .shape({
    utilityProviders: Yup.array()
      .of(
        Yup.object().shape({
          utilityType: Yup.string().required(),
          contractNumber: Yup.string().min(0).nullable(),
          contactId: Yup.string().min(0).nullable(),
          distributorId: Yup.string().min(0).nullable(),
          utilityMeterNumber: Yup.string().notRequired().nullable(),
          utilityReading: Yup.number()
            .nullable()
            .test('should be filled if date is entered', 'mandatory reading error', function (value) {
              const utilityIndexDate = this.parent.utilityIndexDate;
              if (isNil(utilityIndexDate)) return true;

              return !isNil(value) && value >= 0;
            }),
          utilitySecondReading: Yup.number()
            .nullable()
            .test('should be filled if date is entered', 'mandatory reading error', function (value) {
              const utilityIndexDate = this.parent.utilityIndexDate;
              if (isNil(utilityIndexDate)) {
                return true;
              }
              if (this.parent.utilityType === UtilityType.ELECTRICITY_DAY_NIGHT) {
                return !isNil(value) && value >= 0;
              }
              return true;
            }),
          utilityIndexDate: Yup.string()
            .nullable()
            .test('valid date', 'mandatory valid date error', function (value) {
              const utilityReading = this.parent.utilityReading;
              if (isNil(utilityReading)) return true;

              if (isNil(value)) return false;
              return isDateValid(new Date(value)) && isBeforeOrEqual(new Date(value), new Date());
            }),
          utilityEanCode: Yup.string().when('utilityType', {
            is: (val: string) => val === UtilityType.WATER,
            then: Yup.string()
              .notRequired()
              .nullable()
              // eslint-disable-next-line prefer-arrow-callback
              .test('minLengthCheck', 'minLength error', function (value) {
                if (isNilOrEmpty(value)) return true;
                return value!.length >= 2;
              }),
            otherwise: Yup.string()
              .notRequired()
              .nullable()
              .test('minLengthCheck', 'minLength error', function (value) {
                if (isNilOrEmpty(value)) return true;
                if (
                  [UtilityType.ELECTRICITY, UtilityType.ELECTRICITY_DAY_NIGHT, UtilityType.GAS].includes(
                    this.parent.utility
                  ) &&
                  value!.length !== 18
                ) {
                  return false;
                }

                return true;
              }),
          }),
        })
      )
      .notRequired(),
  })
  .required();

const useStyles = makeStyles({
  root: {
    '& > *': {
      width: 280,
    },
  },
  label: {
    color: Colors.LIGHT_BLUE_GREY,
  },
  underline: {
    '&:before': {
      borderBottom: `2px solid ${Colors.TOWER_GREY}`,
    },
  },
  helper: {
    margin: '5px 0',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    color: Colors.LIGHT_BLUE_GREY,
  },
  addButton: {
    background: Colors.CLASSICAL_WHITE,
    color: Colors.DARK_SLATE_GREY,
    '&:hover': {
      background: Colors.PORCELAIN_GREY_2,
    },
  },
});

export const emptyUtilityProvider: NewUtilityProvider = {
  contactId: undefined,
  distributorId: undefined,
  contractNumber: '',
  type: TechnicType.UTILITY_PROVIDER,
  utilityEanCode: '',
  utilityIndexDate: null,
  utilityMeterNumber: '',
  utilityReading: null,
  utilitySecondReading: null,
  utilityType: UtilityType.GAS,
};

const UtilityProvidersFields: React.FC<UtilityProvidersFieldsProps> = ({
  multiple = true,
  editMode = false,
  showComplementaryData = false,
}) => {
  const { values, errors, touched, setFieldValue }: FormikProps<UtilityProvidersFormValues> = useFormikContext();
  const { contractors, contactsLoading } = useContacts();
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const increaseNumber = () => {
    setFieldValue('utilityProviders', [...values.utilityProviders, emptyUtilityProvider]);
  };

  const decreaseNumber = (index: number) => {
    setFieldValue(
      'utilityProviders',
      values.utilityProviders.filter((_m, idx) => index !== idx)
    );
  };

  return (
    <>
      {values.utilityProviders &&
        values.utilityProviders.map((utilityProvider, index) => {
          return (
            <React.Fragment key={index}>
              {multiple && (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: 30 }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: 14,
                      marginBottom: 10,
                      marginTop: 10,
                      marginLeft: 30,
                      textAlign: 'left',
                    }}
                  >
                    {formatMessage({ id: 'utilityProviders.provider' })} {index + 1}
                  </Typography>
                  <FieldsDeleteIconButton onClick={() => decreaseNumber(index)} data-test="delete-utility-provider" />
                </div>
              )}

              <div style={{ textAlign: 'left' }}>
                {showComplementaryData && (
                  <Grid style={{ marginLeft: 20 }}>
                    <AddTechnicDialogComplementaryData />
                  </Grid>
                )}
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
                  <FormikCustomizedSelect
                    label={formatMessage({ id: 'technic.utilityTab.utilityType' })}
                    onChange={(value) => {
                      if (value === UtilityType.WATER && utilityProvider.distributorId) {
                        setFieldValue(`utilityProviders[${index}].distributorId`, undefined);
                      }
                      setFieldValue(`utilityProviders[${index}].utilitySecondReading`, null);
                      setFieldValue(`utilityProviders[${index}].utilityType`, value);
                    }}
                    fieldName={`utilityProviders[${index}].utilityType`}
                    value={values.utilityProviders[index].utilityType}
                    FormControlProps={{ required: true }}
                    className={classes.root}
                    error={Boolean(
                      (errors.utilityProviders?.[index] as FormikErrors<Technic>)?.utilityType &&
                        touched.utilityProviders?.[index].utilityType
                    )}
                  >
                    {[
                      UtilityType.GAS,
                      UtilityType.WATER,
                      UtilityType.ELECTRICITY,
                      UtilityType.ELECTRICITY_DAY_NIGHT,
                    ].map((utilityType) => (
                      <MenuItem id={utilityType} key={utilityType} value={utilityType}>
                        {formatMessage({ id: `enums.UtilityType.${utilityType}` })}
                      </MenuItem>
                    ))}
                  </FormikCustomizedSelect>
                  <SkeletonComponent loading={contactsLoading}>
                    <SimpleContactSelector
                      fieldLabel={`${formatMessage({ id: 'technic.contractorName' })} ${formatMessage({
                        id: 'optional',
                      })}`}
                      contacts={contractors}
                      fieldName={`utilityProviders[${index}].contactId`}
                      inputWidth={280}
                      style={{ marginLeft: 20 }}
                      type={[ContactType.CONTRACTOR]}
                      dataTest="ContractorSelector"
                    />
                  </SkeletonComponent>
                </div>
                <Collapse
                  in={
                    !isNil(utilityProvider.utilityType) &&
                    [UtilityType.ELECTRICITY, UtilityType.ELECTRICITY_DAY_NIGHT, UtilityType.GAS].includes(
                      utilityProvider.utilityType
                    )
                  }
                >
                  <SkeletonComponent loading={contactsLoading}>
                    <SimpleContactSelector
                      fieldLabel={`${formatMessage({ id: 'utilityProviders.distributor' })} ${formatMessage({
                        id: 'optional',
                      })}`}
                      contacts={contractors}
                      fieldName={`utilityProviders[${index}].distributorId`}
                      inputWidth={580}
                      type={[ContactType.CONTRACTOR]}
                      style={{ marginLeft: 20 }}
                      dataTest="DistributorSelector"
                    />
                  </SkeletonComponent>
                </Collapse>
                <div style={{ alignItems: 'baseline' }}>
                  <TextDetailEditable
                    editMode
                    error={Boolean(
                      (errors.utilityProviders?.[index] as FormikErrors<Technic>)?.utilityEanCode &&
                        touched.utilityProviders?.[index].utilityEanCode
                    )}
                    name={`utilityProviders[${index}].utilityEanCode`}
                    title={
                      values.utilityProviders[index].utilityType === UtilityType.WATER
                        ? formatMessage({ id: 'technic.utilityTab.waterMeterId' })
                        : formatMessage({ id: 'utilityProviders.eanCode' })
                    }
                    type="text"
                    style={{ marginLeft: 20 }}
                    helperText={
                      !isEmpty(values.utilityProviders[index].utilityEanCode) &&
                      !isNil(errors) &&
                      !isNil(errors.utilityProviders) &&
                      !isNil(errors.utilityProviders[index]) &&
                      !isNil((errors.utilityProviders[index] as FormikErrors<Technic>).utilityEanCode) &&
                      values.utilityProviders[index].utilityType !== UtilityType.WATER
                        ? formatMessage({ id: 'utilityProviders.eanError' })
                        : ''
                    }
                  />
                  <TextDetailEditable
                    editMode
                    error={Boolean(
                      (errors.utilityProviders?.[index] as FormikErrors<Technic>)?.utilityMeterNumber &&
                        touched.utilityProviders?.[index].utilityMeterNumber
                    )}
                    name={`utilityProviders[${index}].utilityMeterNumber`}
                    title={formatMessage({ id: 'technic.utilityTab.meterNumber' })}
                    type="text"
                    style={{ marginLeft: 20 }}
                  />
                </div>
              </div>
              {!editMode && (
                <div style={{ textAlign: 'left' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label={formatMessage({
                        id: `technic.utilityTab.${
                          values.utilityProviders[index].utilityType === UtilityType.ELECTRICITY_DAY_NIGHT
                            ? 'lastReadingDay'
                            : 'lastReading'
                        }`,
                      })}
                      name={`utilityProviders[${index}].utilityReading`}
                      type="number"
                      margin="dense"
                      variant="filled"
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldValue(
                          `utilityProviders[${index}].utilityReading`,
                          value === '' ? undefined : toNumber(value)
                        );
                      }}
                      inputProps={{ min: 0, step: 0.01 }}
                      style={{ width: 280, marginLeft: 20 }}
                      error={Boolean(
                        (errors.utilityProviders?.[index] as FormikErrors<NewUtilityProvider>)?.utilityReading &&
                          touched.utilityProviders?.[index].utilityReading
                      )}
                      InputProps={{
                        classes: { underline: classes.underline },
                        endAdornment: (
                          <InputAdornment position="end">
                            {getMetricFromUtilityType(values.utilityProviders[index].utilityType)}
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{ className: classes.label }}
                    />
                    {values.utilityProviders[index].utilityType === UtilityType.ELECTRICITY_DAY_NIGHT && (
                      <TextField
                        label={`${formatMessage({
                          id: 'technic.utilityTab.lastReadingNight',
                        })}`}
                        name={`utilityProviders[${index}].utilitySecondReading`}
                        type="number"
                        margin="dense"
                        variant="filled"
                        onChange={(e) => {
                          const value = e.target.value;
                          setFieldValue(
                            `utilityProviders[${index}].utilitySecondReading`,
                            value === '' ? undefined : toNumber(value)
                          );
                        }}
                        inputProps={{ min: 0, step: 0.01 }}
                        style={{ width: 280, marginLeft: 20 }}
                        error={Boolean(
                          (errors.utilityProviders?.[index] as FormikErrors<NewUtilityProvider>)
                            ?.utilitySecondReading && touched.utilityProviders?.[index].utilitySecondReading
                        )}
                        InputProps={{
                          classes: { underline: classes.underline },
                          endAdornment: (
                            <InputAdornment position="end">
                              {getMetricFromUtilityType(values.utilityProviders[index].utilityType)}
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ className: classes.label }}
                      />
                    )}
                  </div>
                  <Grid style={{ display: 'flex', alignItems: 'center' }}>
                    <FormikDatePicker
                      name={`utilityProviders[${index}].utilityIndexDate`}
                      label={formatMessage({ id: 'technic.utilityTab.lastReadingDate' })}
                      value={values.utilityProviders[index].utilityIndexDate}
                      maxDate={new Date()}
                      style={{ width: 280, marginLeft: 20 }}
                      required={false}
                      error={Boolean(
                        (errors.utilityProviders?.[index] as FormikErrors<NewUtilityProvider>)?.utilityIndexDate &&
                          touched.utilityProviders?.[index].utilityIndexDate
                      )}
                      type="string"
                    />
                    <TextDetailEditable
                      editMode
                      error={Boolean(
                        (errors.utilityProviders?.[index] as FormikErrors<NewUtilityProvider>)?.contractNumber &&
                          touched.utilityProviders?.[index].contractNumber
                      )}
                      name={`utilityProviders[${index}].contractNumber`}
                      title={`${formatMessage({ id: 'utilityProviders.contractNumber' })} ${formatMessage({
                        id: 'optional',
                      })}`}
                      type="text"
                      text={
                        isNil(values.utilityProviders[index].contractNumber)
                          ? ''
                          : values.utilityProviders[index].contractNumber
                      }
                      style={{ marginLeft: 20 }}
                    />
                  </Grid>
                  {Boolean(
                    (errors.utilityProviders?.[index] as FormikErrors<NewUtilityProvider>)?.utilityIndexDate &&
                      touched.utilityProviders?.[index].utilityIndexDate
                  ) && (
                    <Grid style={{ marginLeft: 20 }}>
                      <Typography style={{ fontSize: 14, color: Colors.BURNING_ORANGE }}>
                        {formatMessage({ id: 'utilityProviders.meterReadingError' })}
                      </Typography>
                    </Grid>
                  )}
                </div>
              )}
            </React.Fragment>
          );
        })}
      {!isEmpty(values.utilityProviders) && <Divider style={{ marginBottom: 10, marginTop: 10 }} />}
      {multiple && (
        <div style={{ display: 'flex', alignItems: 'left', paddingLeft: '12px' }}>
          <ActionButton id="addUtility" onClick={increaseNumber} className={classes.addButton}>
            <Add />
            {toUpper(
              formatMessage({
                id: 'utilityProviders.provider',
              })
            )}
          </ActionButton>
        </div>
      )}
    </>
  );
};

export default UtilityProvidersFields;
