/* eslint-disable @typescript-eslint/no-shadow */
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Tab,
  TextField,
  Typography,
} from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import { Theme, ThemeProvider, createStyles, createTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { CSSProperties } from '@material-ui/styles';
import {
  Address,
  CivilityType,
  Colors,
  Contact,
  ContactType,
  PhoneNumber,
  contactContainsType,
  getCountryCodeFromDialCode,
  getDialCodeCodeFromCountryCode,
  sanitizeEmailAddress,
  shouldUpdate,
} from '@rentguru/commons-utils';
import {
  ActionButton,
  ContentHeader,
  CustomizedSwitch,
  CustomizedTabs,
  LoaderButton,
  TextDetailEditable,
} from '@up2rent/ui';
import { Form, Formik, FormikHelpers } from 'formik';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { RouteDestination } from 'src/components/Routes/Routes';
import { CountryCode as CountryCodeSelector } from 'src/components/ui/CountrySelector';
import FormInfoBox from 'src/components/ui/FormInfoBox';
import FormikDatePicker from 'src/components/ui/FormikDatePicker';
import AddressFields from 'src/components/ui/Forms/FormField/AddressFields';
import PhoneNumbersForm from 'src/components/ui/Forms/FormSection/PhoneNumbersForm';
import { useContacts } from 'src/hooks/ContactsContext';
import { useUser } from 'src/hooks/UserContext';
import { useUsers } from 'src/hooks/UsersContext';
import { useLocale } from 'src/i18n/IntlProviderWrapper';
import FormikCustomizedSelect from '../ui/FormikCustomizedSelect';
import { OptionalAddressSchema, PhoneNumberSchema, getAddCompanySchema, getAddContactSchema } from './AddContact';

const CustomCheckbox = withStyles({
  root: {
    color: Colors.DODGER_BLUE,
    marginRight: '2px',
    marginBottom: '5px',
    '&$checked': {
      color: Colors.DODGER_BLUE,
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

interface EditContactValues {
  firstName?: string | null;
  lastName?: string | null;
  companyName?: string | null;
  commercialName?: string | null;
  businessNumber?: string | null;
  vat?: string | null;
  vatLiable: boolean;
  email?: string | null;
  birthDate: string | null;
  birthPlace?: string | null;
  nationalRegister?: string | null;
  civility: CivilityType | '' | 'MAN' | 'WOMAN' | 'OTHER';
  address: {
    country?: string | null;
    postalCode?: string | null;
    street: string;
    number?: string | null;
    box?: string | null;
    city?: string | null;
  };
  language?: string | null;
  sendInvitation: boolean;
  phoneNumbers: PhoneNumber[];
  originalValues: Contact;
}

interface EditContactProps {
  afterSubmit?: (contact?: Contact) => void | Promise<void>;
  id: string;
  displayTitle?: boolean;
  displayEmail?: boolean;
  displayCancelButton?: boolean;
  displayTabs?: boolean;
  byOwner?: boolean;
  displayLinkToEntity?: boolean;
  style?: CSSProperties;
  displayCommercialName?: boolean;
  showSendInvite?: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contactType: {
      width: '100%',
    },
    formControl: {
      marginTop: theme.spacing(1),
      marginLeft: 17,
    },
    label: {
      fontSize: '17px',
      lineHeight: '24px',
    },
    addButton: {
      background: Colors.CLASSICAL_WHITE,
      color: Colors.DARK_SLATE_GREY,
      '&:hover': {
        background: Colors.PORCELAIN_GREY_2,
      },
    },
    underline: {
      '&:before': {
        borderBottom: `2px solid ${Colors.TOWER_GREY}`,
      },
    },
  })
);

const theme = createTheme({
  overrides: {
    MuiFormControlLabel: {
      root: {
        display: 'inline',
      },
    },
    MuiSvgIcon: {
      root: {
        display: 'inline',
        fontSize: '1.4rem',
      },
    },
  },
});

const EditContact: React.FC<EditContactProps> = ({
  id,
  afterSubmit,
  displayTitle = true,
  displayEmail = true,
  displayCancelButton = true,
  displayTabs = true,
  byOwner = false,
  displayLinkToEntity = false,
  displayCommercialName = false,
  style = {},
  showSendInvite = true,
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { language } = useLocale();
  const { getUserFromContact, getClientContact } = useUsers();
  const {
    getContact,
    contactsError,
    contactsLoading,
    updateContact,
    deleteAddress,
    updateAddress,
    createAddress,
    inviteContactToClientAccount,
  } = useContacts();
  const { countryCode, isEmailAlreadyTaken, updateCognitoEmail } = useUser();
  const [view, setView] = useState<string>('person');

  const contact = getContact(id);
  useEffect(() => {
    if (!contactsLoading && !isNil(contact)) {
      const newView = !isNil(contact.companyName) && !isEmpty(contact.companyName) ? 'company' : 'person';
      if (newView !== view) setView(newView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, contactsLoading]);

  const handleTabChange = (index: number) => {
    setView(index === 0 ? 'person' : 'company');
  };

  const handleAfterSubmit = async (contact?: Contact) => {
    if (!isNil(afterSubmit)) {
      await afterSubmit(contact);
    }
  };

  if (contactsLoading || isNil(contact)) {
    return (
      <>
        {displayTitle && <ContentHeader title={formatMessage({ id: 'contact.editContact.title' })} />}
        <div style={{ marginLeft: 30, marginRight: 30 }}>
          <div style={{ flexGrow: 1, marginRight: 8 }}>
            <Typography>{formatMessage({ id: 'loading' })}</Typography>
          </div>
        </div>
      </>
    );
  }
  if (contactsError) {
    return <Typography>{contactsError}</Typography>;
  }

  const phoneNumbers = !isNil(contact.phoneNumbers) ? contact.phoneNumbers : [];

  const sendInvitationToContact = (contact: Contact) => {
    if (!contact.sendInvitation) return;
    const clientContact = getClientContact();
    if (clientContact) {
      // No await as if can be slow with lambda cold start
      inviteContactToClientAccount(contact, clientContact);
      enqueueSnackbar(formatMessage({ id: 'settings.addMember.sent' }), {
        variant: 'success',
      });
    }
  };

  const handleCreate = async (
    values: EditContactValues,
    { setSubmitting, setErrors }: FormikHelpers<EditContactValues>
  ) => {
    const {
      address,
      phoneNumbers: phoneNumbersForm,
      originalValues,
      vatLiable,
      email,
      sendInvitation,
      ...remainingValues
    } = values;

    const emailLowerCase = email ? sanitizeEmailAddress(email) : undefined;

    const emailWasUpdated =
      isNil(emailLowerCase) !== isNil(originalValues.email) ||
      (emailLowerCase && emailLowerCase !== originalValues.email);

    if (emailWasUpdated && !isEmpty(emailWasUpdated)) {
      const res = await isEmailAlreadyTaken(emailLowerCase!);
      if (res.success === false) {
        setErrors({ email: formatMessage({ id: 'error.UsernameExistsException' }) });
        setSubmitting(false);
        return;
      }
    }

    let needToSendInvitation = sendInvitation && !originalValues.sendInvitation;

    const { street } = address;
    const isAddressEmpty = isNil(street) || isEmpty(street);
    let updatedContact: Contact | undefined;
    let newAddressField: Address | undefined | null;
    // There was an address but the user decided to empty the fields => delete it
    if (!isNil(contact.address) && isAddressEmpty) {
      await deleteAddress(contact.address.id);
      newAddressField = null;
    }
    // There was an address and the fields do no match anymore
    else if (!isNil(contact.address) && shouldUpdate(contact.address, values.address)) {
      await updateAddress(contact.address, values.address as Partial<Address>);
    }
    // There wasn't an address but now there is one
    else if (isNil(contact.address) && !isAddressEmpty) {
      const newAddress = await createAddress({ ...values.address } as Omit<Address, 'clientId' | 'readId' | 'id'>);
      newAddressField = newAddress;
    }
    const parsedPhoneNumbers: PhoneNumber[] = [];
    phoneNumbersForm.forEach((phoneNumber) => {
      if (!isEmpty(phoneNumber.number)) {
        try {
          const parsedPhoneNumber = parsePhoneNumber(
            phoneNumber.number,
            getCountryCodeFromDialCode(phoneNumber.countryCode) as CountryCode
          );
          parsedPhoneNumbers.push({
            countryCode: phoneNumber.countryCode,
            number: parsedPhoneNumber.nationalNumber as string,
          });
        } catch (err) {
          console.error(err);
        }
      }
    });
    const addressFieldShouldBeUpdated = typeof newAddressField !== 'undefined';
    const phoneNumbersShouldBeUpdated = !isEqual(parsedPhoneNumbers, phoneNumbers);
    const vatShouldBeUpdated =
      (!isEmpty(originalValues.vat) && !vatLiable) || (isEmpty(originalValues.vat) && vatLiable);
    const sendInvitationUpdated = sendInvitation !== originalValues.sendInvitation;
    if (
      shouldUpdate(contact!, remainingValues) ||
      emailWasUpdated ||
      addressFieldShouldBeUpdated ||
      phoneNumbersShouldBeUpdated ||
      vatShouldBeUpdated ||
      sendInvitationUpdated
    ) {
      updatedContact = await updateContact(contact!, {
        ...remainingValues,
        sendInvitation,
        ...(!vatLiable && { vat: undefined }),
        ...(emailWasUpdated && { email: emailLowerCase }),
        ...(addressFieldShouldBeUpdated && { addressId: (newAddressField as Address).id }),
        ...(phoneNumbersShouldBeUpdated && { phoneNumbers: parsedPhoneNumbers }),
        ...(!isNil(values.birthDate) ? { birthDate: values.birthDate } : { birthDate: '' }),
        // reset company specific values if contact is a person
        ...(viewPerson === true && !isNil(values.companyName) && { companyName: undefined }),
        ...(viewPerson === true && !isNil(values.commercialName) && { commercialName: undefined }),
        ...(viewPerson === true && !isNil(values.vat) && { vat: undefined }),
        ...(viewPerson === true && !isNil(values.businessNumber) && { businessNumber: undefined }),
        // reset person specific values if company is selected
        ...(!viewPerson && !isNil(values.firstName) && { firstName: undefined }),
        ...(!viewPerson && !isNil(values.lastName) && { lastName: undefined }),
        ...(!viewPerson && !isNil(values.birthDate) && { birthDate: undefined }),
        ...(!viewPerson && !isNil(values.birthPlace) && { birthPlace: undefined }),
        ...(!viewPerson && !isNil(values.nationalRegister) && { nationalRegister: undefined }),
        ...(!viewPerson && !isNil(values.civility) && { civility: undefined }),
        ...(contact.types.includes(ContactType.OWNER) && { language: values.language }),
      } as Partial<Contact>);
    }

    if (emailWasUpdated) {
      // If the the email was updated and the contact has a user, the email of the user should also be updated
      const userOfContact = getUserFromContact(contact.id);
      if (userOfContact && userOfContact.cognitoSub) {
        const { success, message } = await updateCognitoEmail(userOfContact.id, emailLowerCase!, language, byOwner);
        if (!success) {
          enqueueSnackbar(
            message === 'New mail already exists!' ? formatMessage({ id: 'error.UsernameExistsException' }) : message,
            { variant: 'error' }
          );
          return; // No positive snackbar!
        }

        enqueueSnackbar(formatMessage({ id: 'settings.security.accountModified' }), { variant: 'success' });
      }

      // If we just added an email and it is valid, we are inviting the tenant or owner to join Up2Rent
      // Double check for tenant that he has lease
      const isContactTenantWithLease =
        contactContainsType(contact, ContactType.TENANT) && contact.leases && contact.leases.length > 0;
      // Check condition with Seb

      if (isContactTenantWithLease || contactContainsType(contact, ContactType.OWNER)) {
        sendInvitationToContact(updatedContact!);
        needToSendInvitation = false;
      }
    }

    if (needToSendInvitation) {
      sendInvitationToContact(updatedContact!);
    }

    setSubmitting(false);
    await handleAfterSubmit(updatedContact);
  };

  const { units: _units, buildings: _buildings, leases: _leases, ...contactWithoutLinks } = contact;
  const initialValues: EditContactValues = {
    ...contactWithoutLinks,
    birthDate: !isNil(contact.birthDate) ? contact.birthDate : null,
    birthPlace: contact.birthPlace,
    nationalRegister: contact.nationalRegister,
    civility: !isNil(contact.civility) ? contact.civility : '',
    address: contact.address
      ? contact.address
      : { street: '', box: '', city: '', country: countryCode as CountryCode, number: '', postalCode: '' },
    originalValues: contact,
    phoneNumbers: [],
    vatLiable: !isNil(contact) && !isNil(contact.vat) && !isEmpty(contact.vat),
    sendInvitation: contact.sendInvitation,
  };

  const viewPerson = view === 'person';
  const validationSchema = viewPerson
    ? getAddContactSchema(formatMessage).concat(OptionalAddressSchema).concat(PhoneNumberSchema)
    : getAddCompanySchema(formatMessage).concat(OptionalAddressSchema).concat(PhoneNumberSchema);

  return (
    <Grid style={style}>
      {displayTitle && (
        <ContentHeader title={formatMessage({ id: 'contact.editContact.title' })}>
          {displayLinkToEntity && (
            <ActionButton
              onClick={() => {
                history.push({
                  pathname: `${RouteDestination.CONTACTS}/${id}`,
                  state: { goBackUrl: history.location },
                });
              }}
            >
              {toUpper(formatMessage({ id: 'contact.detail.menu.display' }))}
            </ActionButton>
          )}
        </ContentHeader>
      )}
      {displayTabs && (
        <CustomizedTabs
          handleChange={(i: number) => handleTabChange(i)}
          variant="fullWidth"
          value={viewPerson ? 0 : 1}
          dynamicDefaultTab
        >
          <Tab label={formatMessage({ id: 'contact.addContact.physicalPerson' })} />
          <Tab label={formatMessage({ id: 'contact.addContact.corporation' })} />
        </CustomizedTabs>
      )}

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleCreate}>
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting, status }) => {
          const increaseNumbers = () => {
            const emptyPhones = {
              countryCode: values.address.country
                ? getDialCodeCodeFromCountryCode(values.address.country as CountryCodeSelector)
                : '',
              number: '',
            };
            setFieldValue('phoneNumbers', [...values.phoneNumbers, emptyPhones]);
          };

          const vatLiable = values.vatLiable;
          return (
            <Form>
              <Grid style={{ marginLeft: 30, marginRight: 30 }}>
                <Grid style={{ flexGrow: 1, marginRight: 8 }}>
                  {showSendInvite && !contactContainsType(contact, ContactType.CONTRACTOR) && (
                    <Grid
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: 2, marginTop: 8 }}
                    >
                      <CustomizedSwitch
                        checked={values.sendInvitation}
                        name="sendInvitation"
                        onChange={(event) => {
                          const checked = event.target.checked;
                          setFieldValue('sendInvitation', checked);
                        }}
                      />
                      <Typography style={{ fontSize: 14 }}>
                        {formatMessage({ id: 'settings.addMember.invite' })}
                      </Typography>
                    </Grid>
                  )}
                  {/* Fix for scrollbar below - 8 is due to the spacing 16 of grid container */}
                  {!viewPerson ? (
                    <Grid container spacing={2} style={{ alignItems: 'center' }}>
                      <Grid item xs={12}>
                        <TextDetailEditable
                          title={formatMessage({ id: 'contact.detail.general.companyNameLabel' })}
                          editMode={true}
                          name="companyName"
                          type="text"
                          noMaxWidth
                          error={Boolean(errors.companyName && touched.companyName)}
                        />
                      </Grid>
                      {displayCommercialName && (
                        <Grid item xs={12}>
                          <TextDetailEditable
                            title={formatMessage({ id: 'contact.detail.general.commercialNameLabel' })}
                            editMode={true}
                            name="commercialName"
                            type="text"
                            noMaxWidth
                            error={Boolean(errors.commercialName && touched.commercialName)}
                          />
                        </Grid>
                      )}
                      <Grid item xs={6}>
                        <Box
                          display="flex"
                          justifyContent="left"
                          bgcolor="background.paper"
                          borderColor={Colors.TOWER_GREY}
                          m={1}
                          border={1}
                          style={{ width: '100%', marginLeft: 0, marginRight: 20 }}
                          borderRadius={6}
                        >
                          <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                              <ThemeProvider theme={theme}>
                                <FormControlLabel
                                  control={
                                    <CustomCheckbox
                                      checked={vatLiable}
                                      onChange={(event) => {
                                        const checked = event.target.checked;
                                        if (
                                          checked &&
                                          values.address.country === 'BE' &&
                                          !isEmpty(values.businessNumber)
                                        ) {
                                          setFieldValue('vat', `BE${values.businessNumber}`);
                                        }
                                        if (!checked) {
                                          setFieldValue('vat', '');
                                        }
                                        setFieldValue('vatLiable', checked);
                                      }}
                                      name="vatLiable"
                                      color="primary"
                                    />
                                  }
                                  label={formatMessage({ id: 'contact.addContact.vatLiable' })}
                                  classes={{
                                    label: classes.label,
                                  }}
                                />
                              </ThemeProvider>
                            </FormGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      {vatLiable && (
                        <Grid item xs={6}>
                          <TextField
                            InputProps={{ classes: { underline: classes.underline } }}
                            label={formatMessage({
                              id: 'contact.addContact.vatLabel',
                            })}
                            type="text"
                            name="vat"
                            id="vat"
                            margin="dense"
                            variant="filled"
                            value={values.vat}
                            onChange={(value) => {
                              const currentValue = value.target.value;

                              if (vatLiable && values.address.country === 'BE') {
                                setFieldValue('businessNumber', currentValue.replace(/\D/g, ''));
                              }
                              handleChange(value);
                            }}
                            onBlur={handleBlur}
                            style={{ width: '100%', marginTop: 0, marginBottom: 0 }}
                            error={Boolean(errors.vat && touched.vat)}
                            required
                          />
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextDetailEditable
                          title={formatMessage({ id: 'contact.detail.general.firstNameLabel' })}
                          editMode={true}
                          name="firstName"
                          type="text"
                          noMaxWidth
                          error={Boolean(errors.firstName && touched.firstName)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextDetailEditable
                          title={formatMessage({ id: 'contact.detail.general.lastNameLabel' })}
                          editMode={true}
                          name="lastName"
                          type="text"
                          noMaxWidth
                          error={Boolean(errors.lastName && touched.lastName)}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={2}>
                    {!viewPerson && (
                      <Grid item xs={12}>
                        <TextDetailEditable
                          title={formatMessage({ id: 'contact.addContact.enterpriseNumber' })}
                          editMode={true}
                          name="businessNumber"
                          type="text"
                          noMaxWidth
                          error={Boolean(errors.businessNumber && touched.businessNumber)}
                        />
                      </Grid>
                    )}
                    {displayEmail && (
                      <Grid item xs={12}>
                        <TextDetailEditable
                          title={formatMessage({ id: 'contact.detail.general.emailLabel' })}
                          editMode={true}
                          name="email"
                          type="text"
                          helperText={errors.email && touched.email ? errors.email : ''}
                          noMaxWidth
                          error={Boolean(errors.email && touched.email)}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {viewPerson && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormikCustomizedSelect
                            label={`${formatMessage({ id: 'contact.detail.general.gender' })} ${formatMessage({
                              id: 'optional',
                            })}`}
                            fieldName="civility"
                            value={isNil(values.civility) ? '' : values.civility}
                            FormControlProps={{ style: { width: '100%' } }}
                            error={Boolean(errors.civility && touched.civility)}
                            margin="dense"
                          >
                            <MenuItem id="none" key="none" value="">
                              {formatMessage({ id: 'tickets.list.none' })}
                            </MenuItem>
                            <MenuItem id="MAN" key="MAN" value={CivilityType.MAN}>
                              {formatMessage({ id: 'contact.detail.gender.man' })}
                            </MenuItem>
                            <MenuItem id="WOMAN" key="WOMAN" value={CivilityType.WOMAN}>
                              {formatMessage({ id: 'contact.detail.gender.woman' })}
                            </MenuItem>
                            <MenuItem id="OTHER" key="OTHER" value={CivilityType.OTHER}>
                              {formatMessage({ id: 'contact.detail.gender.other' })}
                            </MenuItem>
                          </FormikCustomizedSelect>
                        </Grid>
                        <Grid item xs={6}>
                          <TextDetailEditable
                            title={`${formatMessage({ id: 'contact.detail.general.nationalRegister' })} ${formatMessage(
                              {
                                id: 'optional',
                              }
                            )}`}
                            editMode={true}
                            name="nationalRegister"
                            type="text"
                            noMaxWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormikDatePicker
                            name="birthDate"
                            label={`${formatMessage({ id: 'contact.detail.general.birthDate' })} ${formatMessage({
                              id: 'optional',
                            })}`}
                            value={!isEmpty(values.birthDate) ? values.birthDate : null}
                            style={{ width: '100%' }}
                            required={false}
                            type="string"
                            margin="dense"
                            maxDate={new Date()}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextDetailEditable
                            title={`${formatMessage({ id: 'contact.detail.general.birthPlace' })} ${formatMessage({
                              id: 'optional',
                            })}`}
                            editMode={true}
                            name="birthPlace"
                            type="text"
                            noMaxWidth
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {contactContainsType(contact, ContactType.OWNER) &&
                    !contactContainsType(contact, ContactType.MEMBER) && (
                      <FormikCustomizedSelect
                        label={formatMessage({ id: 'settings.languages' })}
                        fieldName="language"
                        value={values.language}
                        FormControlProps={{ required: false, style: { width: 535 } }}
                        error={Boolean(errors.language && touched.language)}
                      >
                        <MenuItem value="en">{formatMessage({ id: 'enums.AdvertisementLanguages.en' })}</MenuItem>
                        <MenuItem value="fr">{formatMessage({ id: 'enums.AdvertisementLanguages.fr' })}</MenuItem>
                        <MenuItem value="nl">{formatMessage({ id: 'enums.AdvertisementLanguages.nl' })}</MenuItem>
                      </FormikCustomizedSelect>
                    )}
                  <Grid item xs={12}>
                    <AddressFields
                      containerStyle={{ marginLeft: 0, marginRight: 0 }}
                      placeAutocompleteStyle={{ width: '100%' }}
                      placeAutocompleteMargin="dense"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {!initialValues.sendInvitation && values.sendInvitation && (
                      <FormInfoBox
                        message={formatMessage({ id: 'settings.addMember.willBeSentByMail' })}
                        gridStyle={{ marginLeft: 0, marginTop: 10 }}
                      />
                    )}
                  </Grid>
                </Grid>
                {!isNil(contact.phoneNumbers) && contact.phoneNumbers.length !== 0 && (
                  <Divider style={{ marginTop: 20 }} />
                )}
                <PhoneNumbersForm phoneNumbersList={phoneNumbers} />
              </Grid>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <Grid
                style={{
                  marginBottom: 20,
                  marginRight: 30,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <ActionButton
                  onClick={increaseNumbers}
                  style={{
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                  id="addPhoneNumber"
                  className={classes.addButton}
                >
                  <Add />
                  {toUpper(
                    formatMessage({
                      id: 'contact.addContact.addNumberLabel',
                    })
                  )}
                </ActionButton>
                <Grid
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {displayCancelButton && (
                    <ActionButton
                      onClick={async () => {
                        await handleAfterSubmit();
                      }}
                      style={{ background: 'none', color: Colors.DARK_SLATE_GREY, marginRight: 20 }}
                    >
                      {toUpper(formatMessage({ id: 'cancel' }))}
                    </ActionButton>
                  )}

                  <LoaderButton loading={isSubmitting} success={status}>
                    {formatMessage({
                      id: 'save',
                    })}
                  </LoaderButton>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default EditContact;
