import { Grid } from '@material-ui/core';
import { ContactType, contactContainsTypes, getBicNumberAndBankName } from '@rentguru/commons-utils';
import { CustomSimpleDialog, TextDetailEditable } from '@up2rent/ui';
import { Form, Formik, FormikHelpers } from 'formik';
import { electronicFormatIBAN, isValidBIC, isValidIBAN } from 'ibantools';
import isNil from 'lodash/isNil';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import SimpleContactSelector from 'src/components/ui/SimpleSelectors/SimpleContactSelector';
import { useBankAccounts } from 'src/hooks/BankAccountsContext';
import { useContacts } from 'src/hooks/ContactsContext';
import * as Yup from 'yup';

const IBAN_PROBLEM = 'Not valid IBAN number';
const BIC_PROBLEM = 'Not valid BIC number';
const AddEditBankAccountSchema = Yup.object().shape({
  contactId: Yup.string().required(),
  number: Yup.string()
    .required()
    // eslint-disable-next-line prefer-arrow-callback, func-names
    .test('validIban', IBAN_PROBLEM, function (value) {
      // keep function for this.parent below
      const electronicFormat = !isNil(value) ? electronicFormatIBAN(value) : null;
      return !isNil(electronicFormat) && isValidIBAN(electronicFormat);
    }),
  bicNumber: Yup.string()
    .optional()
    // eslint-disable-next-line prefer-arrow-callback, func-names
    .test('valiBic', BIC_PROBLEM, function (value) {
      // keep function for this.parent below
      return value === '' || isNil(value) || isValidBIC(value);
    }),
  bankName: Yup.string().optional(),
});

interface AddEditBankAccountDialogProps {
  id?: string;
  onClose: () => void;
  open: boolean;
  afterSubmit?: (bankAccountId: string) => void;
}

interface AddEditBankAccountValues {
  id?: string;
  contactId: string;
  number: string;
  bicNumber: string;
  bankName: string;
}

export const AddEditBankAccountDialog: React.FC<AddEditBankAccountDialogProps> = ({
  id,
  onClose,
  open,
  afterSubmit,
}) => {
  const { formatMessage } = useIntl();
  const { createBankAccount, updateBankAccount, loading, error, getBankAccount } = useBankAccounts();
  const { contacts, contactsLoading, getContact } = useContacts();
  const { enqueueSnackbar } = useSnackbar();

  const handleCreateUpdate = async (
    values: AddEditBankAccountValues,
    { setSubmitting, setStatus }: FormikHelpers<AddEditBankAccountValues>
  ) => {
    const { contactId: _contactId, ...rest } = values;
    values.number = electronicFormatIBAN(values.number) || '';
    const bankAccountContact = getContact(values.contactId);
    let bankAccount;
    if (bankAccountContact) {
      if (isNil(id)) {
        bankAccount = await createBankAccount({ ...rest, contactId: bankAccountContact.id });
        if (isNil(bankAccount)) {
          enqueueSnackbar(formatMessage({ id: `errors.bankAccountAlreadyExists` }), {
            persist: false,
            autoHideDuration: 6000,
            variant: 'error',
            preventDuplicate: true,
          });
          setSubmitting(false);
          return;
        }
      } else {
        // Update
        const oldBankAccount = getBankAccount(id);
        bankAccount = await updateBankAccount(oldBankAccount!, { ...rest, contactId: bankAccountContact.id });
      }
    }

    if (bankAccount && afterSubmit) afterSubmit(bankAccount.id);
    setStatus(true);
    setSubmitting(false);
    onClose();
  };

  let oldBankAccount;
  if (!isNil(id)) {
    if (loading || error || contactsLoading) {
      return null;
    }
    oldBankAccount = getBankAccount(id);
  }

  const bankAccountsContactCandidates = contacts.filter((c) =>
    contactContainsTypes(c, [ContactType.OWNER, ContactType.CLIENT, ContactType.JOINT_OWNERSHIP])
  );

  const initialValues: AddEditBankAccountValues = {
    id,
    contactId: oldBankAccount && oldBankAccount.contact ? oldBankAccount.contact.id : '',
    number: oldBankAccount ? oldBankAccount.number : '',
    bicNumber: oldBankAccount && oldBankAccount.bicNumber ? oldBankAccount.bicNumber : '',
    bankName: oldBankAccount && oldBankAccount.bankName ? oldBankAccount.bankName : '',
  };

  const isInEditMode = !isNil(id);

  return (
    <Formik initialValues={initialValues} validationSchema={AddEditBankAccountSchema} onSubmit={handleCreateUpdate}>
      {({ values, errors, touched, isSubmitting, setFieldValue, handleSubmit }) => (
        <Form>
          <CustomSimpleDialog
            open={open}
            onClose={onClose}
            onActionButtonClick={handleSubmit}
            actionButtonLabel={formatMessage({
              id: isInEditMode ? 'save' : 'add',
            })}
            actionButtonLoading={isSubmitting}
            dividerBelowTitle
            title={formatMessage({ id: `bankAccount.addBankAccount.action${isInEditMode ? 'Edit' : ''}` })}
            formatMessage={formatMessage}
          >
            <Grid style={{ marginTop: 20 }}>
              <SimpleContactSelector
                fieldLabel={formatMessage({ id: 'bankAccount.addBankAccount.holder' })}
                contacts={bankAccountsContactCandidates}
                fieldName="contactId"
                inputWidth={552}
                type={[ContactType.OWNER]}
                groupBy="type"
              />
              <TextDetailEditable
                title={formatMessage({
                  id: 'bankAccount.addBankAccount.number',
                })}
                editMode
                name="number"
                type="text"
                noMaxWidth
                error={Boolean(errors.number && touched.number)}
                onChangeObserver={async (value) => {
                  const electronicFormat = electronicFormatIBAN(value);
                  if (!isNil(electronicFormat) && isValidIBAN(electronicFormat)) {
                    const bicBank = await getBicNumberAndBankName(electronicFormat);
                    if (values.bicNumber === '') {
                      setFieldValue('bicNumber', bicBank.bicNumber);
                    }
                    if (values.bankName === '') {
                      setFieldValue('bankName', bicBank.bankName);
                    }
                  }
                }}
              />
              <TextDetailEditable
                title={`${formatMessage({
                  id: 'bankAccount.addBankAccount.bic',
                })} ${formatMessage({
                  id: 'optional',
                })}`}
                editMode
                name="bicNumber"
                type="text"
                noMaxWidth
                error={Boolean(errors.bicNumber && touched.bicNumber)}
              />

              <TextDetailEditable
                title={`${formatMessage({
                  id: 'bankAccount.addBankAccount.bankName',
                })} ${formatMessage({
                  id: 'optional',
                })}`}
                editMode
                name="bankName"
                type="text"
                noMaxWidth
                error={Boolean(errors.bankName && touched.bankName)}
              />
            </Grid>
          </CustomSimpleDialog>
        </Form>
      )}
    </Formik>
  );
};
