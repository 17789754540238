import { Grid, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import React from 'react';
import { ReactComponent as MultiplyIcon } from '../../../../../../icons/icon-multiply.svg';
import { ReactComponent as MinusIcon } from '../../../../../../icons/icon-minus.svg';
import { calculationStyles } from '../../../leasePriceHistoryStyles';

export enum BasicCalculationOperator {
  MULTIPLICATION = 'MULTIPLICATION',
  ADDITION = 'ADDITION',
  SUBTRACTION = 'SUBTRACTION',
}

interface BasicCalculationFormulaProps {
  firstOperand: number | string;
  secondOperand: number | string;
  operator: BasicCalculationOperator;
}

const selectOperator = (operator: BasicCalculationOperator) => {
  switch (operator) {
    case BasicCalculationOperator.MULTIPLICATION:
      return <MultiplyIcon style={{ width: 32 }} />;
    case BasicCalculationOperator.ADDITION:
      return (
        <Typography style={{ marginRight: 10, fontSize: 14, color: Colors.SLATE_GREY, fontWeight: 'bolder' }}>
          +
        </Typography>
      );
    case BasicCalculationOperator.SUBTRACTION:
      return <MinusIcon style={{ width: 32 }} />;
    // no default
  }
};

const BasicCalculationFormula: React.FC<BasicCalculationFormulaProps> = ({ firstOperand, secondOperand, operator }) => {
  const styles = calculationStyles();

  return (
    <Grid style={{ textAlign: 'center' }}>
      <Grid className={styles.formulaNumeratorGrid}>
        <Typography className={styles.calculationText}>{firstOperand}</Typography>
        {selectOperator(operator)}
        <Typography className={styles.calculationText}>{secondOperand}</Typography>
      </Grid>
    </Grid>
  );
};

export default BasicCalculationFormula;
