import {
  Advertisement,
  AdvertisementUnitSubType,
  AdvertisementUnitType,
  Building,
  BuildingState,
  CertificateOfConformityType,
  CreateAdvertisementInput,
  DelimitedZoneType,
  FloodProneAreaType,
  HeatingType,
  ModelWithVersion,
  Unit,
  getSafeValueInObject,
  isNilOrEmpty,
} from '@rentguru/commons-utils';
import { isNil } from 'lodash';
import { EditAdvertisementFormValues } from '../EditPublicationForm';

const addArrayIfNecessary = (
  values: EditAdvertisementFormValues,
  fieldName: string,
  returnedPropertyName: string
): Omit<CreateAdvertisementInput, 'readId' | 'clientId'> => {
  const value = getSafeValueInObject(values, fieldName);
  if (isNilOrEmpty(value)) {
    return {};
  }
  return {
    [returnedPropertyName]: value,
  };
};

export const getBooleanValueFromString = (value: string) => {
  switch (value.toLowerCase()) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return undefined;
  }
};

export const formValuesToCreateAdvertisementInput = (
  values: EditAdvertisementFormValues,
  advertisement: Advertisement | null
): Omit<CreateAdvertisementInput, 'readId' | 'clientId'> => ({
  type: values.advertisement.type as AdvertisementUnitType,
  subType: values.advertisement.subType as AdvertisementUnitSubType,
  ...(!isNilOrEmpty(advertisement)
    ? {
        id: values.advertisement.id,
        _version: (values.advertisement as unknown as ModelWithVersion<Advertisement>)._version,
      }
    : {}),
  availabilityDate: !isNil(values.advertisement?.availabilityDate)
    ? new Date(values.advertisement.availabilityDate.toString()).toISOString() // Waits a date under certain format.
    : undefined,
  width: values.advertisement?.width,
  length: values.advertisement?.length,
  landArea: values.advertisement?.landArea,
  ...addArrayIfNecessary(values, 'advertisement.amenities', 'amenities'),
  ...addArrayIfNecessary(values, 'advertisement.landTerrain', 'landTerrain'),
  ...addArrayIfNecessary(values, 'advertisement.descriptions', 'descriptions'),
  ...addArrayIfNecessary(values, 'advertisement.heatingAmenities', 'heatingAmenities'),
  ...addArrayIfNecessary(values, 'advertisement.nearbyComodities', 'nearbyComodities'),
  heatingType: values.advertisement.heatingType as HeatingType,
  delimitedZone: values.advertisement?.delimitedZone as DelimitedZoneType,
  floodProneArea: values.advertisement?.floodProneArea as FloodProneAreaType,
  certificateOfConformity: values.advertisement?.certificateOfConformity as CertificateOfConformityType,
  resort: getBooleanValueFromString(values.advertisement.resort),
  furnished: getBooleanValueFromString(values.advertisement.furnished),
  firstOccupancy: getBooleanValueFromString(values.advertisement.firstOccupancy),
  rightOfPreemption: getBooleanValueFromString(values.advertisement.rightOfPreemption),
  planningPermission: getBooleanValueFromString(values.advertisement.planningPermission),
  wheelChairAccessible: getBooleanValueFromString(values.advertisement.wheelChairAccessible),
  subdivisionAuthorization: getBooleanValueFromString(values.advertisement.subdivisionAuthorization),
  citationForPlanningOffence: getBooleanValueFromString(values.advertisement.citationForPlanningOffence),
  publishCadastralIncome: !isNil(values.advertisement.publishCadastralIncome)
    ? values.advertisement.publishCadastralIncome
    : false,
});

export const formValuesToBuilding = (values: EditAdvertisementFormValues, building: Building): Partial<Building> => ({
  residenceName: values.building.residenceName || '',
  constructionYear: values.building.constructionYear
    ? new Date(values.building.constructionYear!.toString()).toISOString()
    : undefined,
  buildingState:
    values.building.buildingState !== 'none' ? (values.building.buildingState as BuildingState) : undefined,
  amountOfFacades: values.building.amountOfFacades ? values.building.amountOfFacades * 1 : undefined,
  amountOfHousings: values.building.amountOfHousings ? values.building.amountOfHousings * 1 : undefined,
  janitor: getBooleanValueFromString(values.building.janitor),
  amountOfFloors: values.building.amountOfFloors,
  cadastralIncome: !isNilOrEmpty(building.owners) ? values.building.cadastralIncome ?? undefined : undefined,
  propertyTax: !isNilOrEmpty(building.owners) ? values.building.propertyTax ?? undefined : undefined,
});

export const formValuesToUnit = (
  values: EditAdvertisementFormValues,
  building: Building,
  newAdvertisement: Advertisement | null,
  oldAdvertisement: Advertisement | null
): Partial<Unit> => ({
  advertisedRentalPrice: values.unit.advertisedRentalPrice ? Number(values.unit.advertisedRentalPrice) : undefined,
  advertisedMonthlyCharges: values.unit.advertisedMonthlyCharges
    ? Number(values.unit.advertisedMonthlyCharges)
    : undefined,
  floor: values.unit.floor ? Number(values.unit.floor) : undefined,
  unitAdvertisementId: !isNil(newAdvertisement) ? newAdvertisement.id : oldAdvertisement!.id,
  cadastralIncome: isNilOrEmpty(building.owners) ? values.unit.cadastralIncome ?? undefined : undefined,
  propertyTax: isNilOrEmpty(building.owners) ? values.unit.propertyTax ?? undefined : undefined,
  publishAddress: Boolean(values.unit.publishAddress),
});
