import { Invoice, LeaseExtended } from '@rentguru/commons-utils';
import { isBefore, isWithinInterval, subMonths } from 'date-fns';
import { isEmpty } from 'lodash';
import { Filter, LeasesActiveFilters, LeasesArchivedFilters, LeasesDraftsFilters } from 'src/hooks/FiltersContext';
import { isEntityInClassicFilters } from 'src/utils/filtersUtils';

/**
 * Lease Active
 */
export const isOneLeaseActiveFilterSelected = (leaseFilters: LeasesActiveFilters) => {
  const { filters, balanceDept, balanceLatePaymentMonth, endingInFromTo } = leaseFilters;
  const balanceFilterSelected = balanceDept !== 0 || balanceLatePaymentMonth !== 0;
  const endLeaseFilterSelected = endingInFromTo !== null;
  const filtersSelected = balanceFilterSelected || endLeaseFilterSelected || isCommonFilterSelected(filters);
  return filtersSelected;
};

export const filterLeasesActiveByFilters = <T extends LeaseExtended>(
  leases: T[],
  leaseFilters: LeasesActiveFilters,
  unpaidInvoicesOfClient: Invoice[] | null
) => {
  if (!isOneLeaseActiveFilterSelected(leaseFilters)) {
    return leases;
  }
  const { filters, balanceDept, balanceLatePaymentMonth, endingInFromTo } = leaseFilters;
  return leases.filter((lease) => {
    if (filters.length > 0 && !isLeaseInClassicFilters(lease, filters)) {
      return false;
    }
    if (balanceDept !== 0 && !doesLeaseRespectBalance(lease, balanceDept)) {
      return false;
    }
    if (
      balanceLatePaymentMonth !== 0 &&
      !doesLeaseRespectLatePayment(lease, balanceLatePaymentMonth, unpaidInvoicesOfClient)
    ) {
      return false;
    }
    if (endingInFromTo && !leaseFinishedInPeriod(lease, new Date(endingInFromTo.from), new Date(endingInFromTo.to))) {
      return false;
    }
    return true;
  });
};

/**
 * Lease Drafts
 */
export const isOneLeaseDraftsFilterSelected = (leaseFilters: LeasesDraftsFilters) => {
  const { filters } = leaseFilters;
  const filtersSelected = isCommonFilterSelected(filters) || !isEmpty(filters.filter((f) => f.name === 'status'));
  return filtersSelected;
};

export const filterLeasesDraftsByFilters = (leases: LeaseExtended[], leaseFilters: LeasesDraftsFilters) => {
  if (!isOneLeaseDraftsFilterSelected(leaseFilters)) {
    return leases;
  }
  const { filters } = leaseFilters;
  return leases.filter((lease) => {
    if (filters.length > 0 && !isLeaseInClassicFilters(lease, filters)) {
      return false;
    }

    return true;
  });
};

/**
 * Lease Archived
 */
export const isOneLeaseArchivedFilterSelected = (leaseFilters: LeasesArchivedFilters) => {
  const { filters, balanceDept, endedSinceFromTo } = leaseFilters;
  const balanceFilterSelected = balanceDept !== 0;
  const endLeaseFilterSelected = endedSinceFromTo !== null;
  const filtersSelected = balanceFilterSelected || endLeaseFilterSelected || isCommonFilterSelected(filters);
  return filtersSelected;
};

export const filterLeasesArchivedByFilters = (leases: LeaseExtended[], leaseFilters: LeasesArchivedFilters) => {
  if (!isOneLeaseArchivedFilterSelected(leaseFilters)) {
    return leases;
  }
  const { filters, balanceDept, endedSinceFromTo } = leaseFilters;
  return leases.filter((lease) => {
    if (filters.length > 0 && !isLeaseInClassicFilters(lease, filters)) {
      return false;
    }
    if (balanceDept !== 0 && !doesLeaseRespectBalance(lease, balanceDept)) {
      return false;
    }
    if (
      endedSinceFromTo &&
      !leaseFinishedInPeriod(lease, new Date(endedSinceFromTo.from), new Date(endedSinceFromTo.to))
    ) {
      return false;
    }
    return true;
  });
};

/**
 * Common functions
 */

const isCommonFilterSelected = (filters: Filter[]) => {
  return (
    !isEmpty(filters.filter((f) => f.name === 'building')) ||
    !isEmpty(filters.filter((f) => f.name === 'unit')) ||
    !isEmpty(filters.filter((f) => f.name === 'type')) ||
    !isEmpty(filters.filter((f) => f.name === 'lease')) ||
    !isEmpty(filters.filter((f) => f.name === 'tenants')) ||
    !isEmpty(filters.filter((f) => f.name === 'city')) ||
    !isEmpty(filters.filter((f) => f.name === 'owner'))
  );
};

const isLeaseInClassicFilters = (lease: LeaseExtended, filters: Filter[]) => {
  // For units/buildings filter, we need to check if it's contained, so we cannot call the other function
  const containedClassicFilters = ['unit', 'building', 'tenants'];
  const containedFilter = filters.filter((filter) => containedClassicFilters.includes(filter.name));
  const isInContainedFilter = containedFilter.reduce((result, currentFilter) => {
    if (currentFilter.items.length === 0) {
      return result;
    }
    if (currentFilter.name === 'unit') {
      const isUnitInThisLease = lease.units?.some(
        (unitLease) => unitLease.unit && currentFilter.items.includes(unitLease.unit.id)
      );
      if (!isUnitInThisLease) {
        return false;
      }
    }
    if (currentFilter.name === 'building') {
      const isBuildingInThisLease = lease.units?.some(
        (unitLease) =>
          unitLease.unit && unitLease.unit.building && currentFilter.items.includes(unitLease.unit.building.id)
      );
      if (!isBuildingInThisLease) {
        return false;
      }
    }
    if (currentFilter.name === 'tenants') {
      const isTenantInTheLease = lease.tenants?.some(
        (tenantLease) => tenantLease && currentFilter.items.includes(tenantLease.id)
      );
      if (!isTenantInTheLease) {
        return false;
      }
    }

    return result;
  }, true);
  const otherFilters = filters.filter((filter) => !containedClassicFilters.includes(filter.name));
  const isInClassicFilter = isEntityInClassicFilters(lease, otherFilters);
  return isInContainedFilter && isInClassicFilter;
};

const doesLeaseRespectBalance = (lease: LeaseExtended, balance: number) => {
  return lease.balance && (lease.balance < 0 ? Math.abs(lease.balance) : lease.balance) > balance;
};

const doesLeaseRespectLatePayment = (
  lease: LeaseExtended,
  latePaymentInMonth: number,
  unpaidInvoicesOfClient: Invoice[] | null
) => {
  if (!unpaidInvoicesOfClient || isEmpty(unpaidInvoicesOfClient)) {
    return true;
  }
  const unpaidInvoicesOfLease = unpaidInvoicesOfClient.filter((invoice) => invoice.leaseId === lease.id);
  if (isEmpty(unpaidInvoicesOfLease)) {
    return true;
  }
  const oldestInvoice = unpaidInvoicesOfLease.reduce((result: null | Invoice, currentInvoice) => {
    if (!result || currentInvoice.invoiceDate < result.invoiceDate) {
      return currentInvoice;
    }
    return result;
  }, null);
  if (!oldestInvoice) {
    return true;
  }
  const latePayment = subMonths(new Date(), latePaymentInMonth);
  const invoiceDate = new Date(oldestInvoice.invoiceDate);
  return isBefore(invoiceDate, latePayment);
};

const leaseFinishedInPeriod = (lease: LeaseExtended, from: Date, to: Date) => {
  return isWithinInterval(new Date(lease.endDate), {
    start: from,
    end: to,
  });
};
