/* eslint-disable @typescript-eslint/no-shadow */
import { Grid, Typography } from '@material-ui/core';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';
import { getSafeValueInObject, Unit } from '@rentguru/commons-utils';
import { useFormikContext } from 'formik';
import isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as UnitIcon } from '../../../icons/unitPlaceholder.svg';
import FullCustomComboBox, { CustomInputItemType } from '../ComboBox/FullCustomComboBox';
import { useCustomizedComboStyles } from '../ComboBox/TextComboBox';

interface MultipleUnitsSelectorProps {
  units: Unit[];
  fieldName: string;
  defaultValues?: Unit[];
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  inputWidth?: number;
  paperClass?: string;
  disabled?: boolean;
}

const renderTags = (values: CustomInputItemType[], getTagProps: AutocompleteGetTagProps) =>
  values.map((value: CustomInputItemType, index: number) => {
    const tagProps = { ...getTagProps({ index }) };
    // @ts-ignore
    delete tagProps.onDelete;
    return (
      <Typography key={value.value.id} style={{ fontSize: 16, fontWeight: 500 }} {...tagProps}>
        {`${value.primary}${index !== values.length - 1 ? ', ' : ''}`}
      </Typography>
    );
  });

const MultipleUnitsSelector: React.FC<MultipleUnitsSelectorProps> = ({
  units,
  fieldName,
  defaultValues,
  style,
  inputStyle,
  inputWidth = 560,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const comboBoxClasses = useCustomizedComboStyles();
  const { values, errors, touched, setFieldValue } = useFormikContext<unknown>();

  const unitToCustomComboItem = (unit: Unit) => {
    return {
      primary: unit.name,
      secondary: unit.building!.name,
      value: unit,
    };
  };
  const unitsSelected: Unit[] = getSafeValueInObject(values, fieldName);

  const defautItems = !isNil(defaultValues)
    ? defaultValues.map((defaultValue) => unitToCustomComboItem(defaultValue))
    : [];
  const unitsComboBoxItems = !isNil(unitsSelected)
    ? unitsSelected.map((unitSelected) => unitToCustomComboItem(unitSelected))
    : [];

  return (
    <div
      style={{
        alignItems: 'baseline',
        display: 'flex',
        ...style,
      }}
    >
      <FullCustomComboBox
        value={!isNil(unitsSelected) ? unitsComboBoxItems : defautItems}
        label={formatMessage({ id: 'menu.rentalUnit' })}
        options={units.map((u) => unitToCustomComboItem(u))}
        inputStyle={{ width: inputWidth, ...inputStyle }}
        onChange={(_event, values) => {
          const units = values ? values.map((v) => v.value) : [];
          setFieldValue(fieldName, units);
        }}
        renderOption={(value: CustomInputItemType) => {
          return (
            <Grid container alignContent="space-between" style={{ marginBottom: '5px', marginTop: '5px' }}>
              <Grid item xs={2}>
                <UnitIcon
                  style={{
                    width: 15,
                    height: 15,
                    marginRight: 10,
                    fill: 'blue',
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography className={comboBoxClasses.primaryText}>{value.primary}</Typography>
              </Grid>
              <Grid item xs={5} style={{ textAlign: 'right' }}>
                {!isNil(value.secondary) && (
                  <Typography className={comboBoxClasses.secondaryText}>{value.secondary}</Typography>
                )}
              </Grid>
            </Grid>
          );
        }}
        renderInput={(value: CustomInputItemType) => {
          return <Typography style={{ fontSize: 12 }}>{value.primary}</Typography>;
        }}
        error={Boolean(getSafeValueInObject(errors, fieldName) && getSafeValueInObject(touched, fieldName))}
        noOptionsText={formatMessage({ id: 'comboBox.noUnits' })}
        stringToCompare={(value: CustomInputItemType) => `${value.primary} - ${value.secondary}`}
        renderTags={renderTags}
        disableClearable={false}
        clearOnEscape={true}
        disableCloseOnSelect={true}
        {...rest}
      />
    </div>
  );
};

export default MultipleUnitsSelector;
