/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type BatchChangeCommunicationsInput = {
  communications: Array< BatchChangeIdVersion >,
  action: BatchChangeCommunicationsAction,
  userId: string,
  clientId: string,
};

export type BatchChangeIdVersion = {
  id: string,
  _version: number,
};

export enum BatchChangeCommunicationsAction {
  SEND = "SEND",
  RESEND = "RESEND",
  DELETE = "DELETE",
}


export type MutationStatus = {
  __typename: "MutationStatus",
  status?: boolean | null,
  id: string,
};

export type ChangeProfessionalActivityForLeaseInput = {
  leaseId: string,
  unitIds: Array< string >,
  userId: string,
  clientId: string,
};

export type CreateClientAccountInput = {
  companyName: string,
  vat?: string | null,
  businessNumber?: string | null,
  address?: CreateClientAddressInput | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  provider?: string | null,
  language?: string | null,
  GDPRAcceptedVersion: string,
  generalConditionsAcceptedVersion: string,
  accountType: AccountType,
  chargeEmail?: string | null,
  issueEmail?: string | null,
};

export type CreateClientAddressInput = {
  street?: string | null,
  number?: string | null,
  box?: string | null,
  postalCode?: string | null,
  city?: string | null,
  country?: string | null,
};

export enum AccountType {
  OWNER = "OWNER",
  AGENCY = "AGENCY",
  FUND = "FUND",
}


export type DeleteEntityByIdInput = {
  entityId: string,
  entity: DeepDeletableEntityType,
};

export enum DeepDeletableEntityType {
  BUILDING = "BUILDING",
  CONTACT = "CONTACT",
  LEASE = "LEASE",
  UNIT = "UNIT",
}


export type GenerateCommunicationPdfInput = {
  id: string,
};

export type GenerateCommunicationPdfResult = {
  __typename: "GenerateCommunicationPdfResult",
  key?: string | null,
  status: boolean,
};

export type GenerateDocumentInput = {
  id: string,
  type: DocumentType,
};

export enum DocumentType {
  EntranceFurniture = "EntranceFurniture",
  EntranceInventory = "EntranceInventory",
  ExitFurniture = "ExitFurniture",
  ExitInventory = "ExitInventory",
  Invoice = "Invoice",
  Lease = "Lease",
  StatementDetail = "StatementDetail",
  StatementReceipt = "StatementReceipt",
  LeasePriceHistoryIndexationAccepted = "LeasePriceHistoryIndexationAccepted",
  LeaseTerminationFromLessor = "LeaseTerminationFromLessor",
  Receipt = "Receipt",
  Letter = "Letter",
  LeaseConsolidatedPaymentRequest = "LeaseConsolidatedPaymentRequest",
}


export type ProcessOcrInput = {
  chargeId: string,
  userId: string,
  clientId: string,
};

export type GenerateReportInput = {
  type: GenerateReportType,
  outputFormat: GenerateReportOutputFormat,
  outputResults: Array< GenerateReportOutputResult >,
  periodFrom?: string | null,
  periodTo?: string | null,
  ownersDetailsFilters?: Array< OwnersDetailsFiltersInput > | null,
  feesOwnerIdsFilters?: Array< string > | null,
  supplierDebtsContractorIdsFilters?: Array< string > | null,
  operationalPerformanceOptions?: OperationalPerformanceReportOptionsInput | null,
  chargesOptions?: ChargesReportOptionsInput | null,
  language: string,
  userId: string,
  clientId: string,
};

export enum GenerateReportType {
  OPERATIONAL_PERFORMANCE = "OPERATIONAL_PERFORMANCE",
  CHARGES = "CHARGES",
  TURNOVER = "TURNOVER",
  FEES = "FEES",
  TENANTS_AGING_BALANCE = "TENANTS_AGING_BALANCE",
  SUPPLIER_DEBTS = "SUPPLIER_DEBTS",
}


export enum GenerateReportOutputFormat {
  PDF = "PDF",
  CSV = "CSV",
}


export enum GenerateReportOutputResult {
  CUSTOM_SUBSCRIPTION = "CUSTOM_SUBSCRIPTION",
  EMAIL = "EMAIL",
}


export type OwnersDetailsFiltersInput = {
  ownerId: string,
  buildingIds?: Array< string | null > | null,
  unitIds?: Array< string | null > | null,
  leaseIds?: Array< string | null > | null,
};

export type OperationalPerformanceReportOptionsInput = {
  comparisonPeriodFrom?: string | null,
  comparisonPeriodTo?: string | null,
  amountVatExcluded?: boolean | null,
};

export type ChargesReportOptionsInput = {
  tenantRepartitionColumn?: boolean | null,
  ownerRepartitionColumn?: boolean | null,
  totalColumn?: boolean | null,
};

export type GenerateReportResult = {
  __typename: "GenerateReportResult",
  errorCode?: GenerateReportErrorCode | null,
  status: boolean,
};

export enum GenerateReportErrorCode {
  WRONG_PARAMS = "WRONG_PARAMS",
  WRONG_FORMAT = "WRONG_FORMAT",
  UNAUTHORIZED = "UNAUTHORIZED",
  GENERATION_ERROR = "GENERATION_ERROR",
}


export type AdvertisementPublishingInput = {
  platforms?: Array< AdvertisementPublishingPlatform > | null,
  unitId: string,
};

export type AdvertisementPublishingPlatform = {
  name: PublishingPlatform,
  params: AdvertisementPublishingPlatformParams,
};

export enum PublishingPlatform {
  LOGIC_IMMO = "LOGIC_IMMO",
}


export type AdvertisementPublishingPlatformParams = {
  action: AdvertisementPublishingPlatformActions,
  durationInMonths?: number | null,
};

export enum AdvertisementPublishingPlatformActions {
  PUBLISH = "PUBLISH",
  DELETE = "DELETE",
  UPDATE = "UPDATE",
}


export type AdvertisementPostingMutationStatus = {
  __typename: "AdvertisementPostingMutationStatus",
  id: string,
  statusList?:  Array<AdvertisementPostingStatus > | null,
  errorMessage?: string | null,
};

export type AdvertisementPostingStatus = {
  __typename: "AdvertisementPostingStatus",
  publishingPlatform: PublishingPlatform,
  status: boolean,
  successCode: number,
};

export type UserRightsChangeInput = {
  userId: string,
  event: UserRightChangeEvent,
  clientId: string,
  leaseId?: string | null,
  unitId?: string | null,
  buildingId?: string | null,
};

export enum UserRightChangeEvent {
  DELETE_TENANT_LEASE_ACCESS = "DELETE_TENANT_LEASE_ACCESS",
  DELETE_OWNER_UNIT_ACCESS = "DELETE_OWNER_UNIT_ACCESS",
  DELETE_OWNER_BUILDING_ACCESS = "DELETE_OWNER_BUILDING_ACCESS",
}


export type InviteContactToClientAccountInput = {
  email: string,
  invitationType: InviteContactToClientAccountType,
  contactId: string,
  accountType?: AccountType | null,
  hash: string,
  language?: string | null,
  clientId: string,
};

export enum InviteContactToClientAccountType {
  SIGN_LEASE = "SIGN_LEASE",
  SIGN_DOCUMENT = "SIGN_DOCUMENT",
  INVITE_TENANT = "INVITE_TENANT",
  INVITE_OWNER = "INVITE_OWNER",
}


export type InviteUserToClientAccountInput = {
  email: string,
  teamId: string,
  roleId: string,
  accountType?: AccountType | null,
  hash: string,
  language?: string | null,
  clientId: string,
};

export type IsEmailAlreadyTakenInput = {
  email: string,
};

export type StatusResult = {
  __typename: "StatusResult",
  success: boolean,
  message?: string | null,
};

export type RecalculateStatementInput = {
  statementId: string,
  userId: string,
  clientId: string,
};

export type RejectDocumentInput = {
  id: string,
  type: DocumentType,
  contactId: string,
  rejectReason?: string | null,
};

export type RevokePontoIntegrationInput = {
  userId: string,
  clientId: string,
};

export type MutationUserInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  language?: string | null,
  updatedBy?: MutationUserInputUpdatedBy | null,
};

export enum MutationUserInputUpdatedBy {
  OWNER = "OWNER",
}


export type VerifyPendingSendingSourceInput = {
  id: string,
  userId: string,
  clientId: string,
};

export type VerifyPontoIntegrationInput = {
  authorizationCode: string,
  codeVerifier: string,
  clientId: string,
};

export type DownloadFoldersInput = {
  selectedFolders: Array< SelectedFoldersParamsInput >,
  clientId: string,
  userId: string,
  language: string,
  onPage: boolean,
  entityId?: string | null,
  entityType?: string | null,
  isSearching?: boolean | null,
  isOnlyDownloadingInvoices?: boolean | null,
};

export type SelectedFoldersParamsInput = {
  translatedName: string,
  folderDepth: string,
};

export type CreateAccountLabelInput = {
  id?: string | null,
  labels?: Array< CustomLabelInput > | null,
  bankAccountId?: string | null,
  contactId?: string | null,
  class: number,
  topClass: number,
  customUsage?: AccountLabelCustomUsage | null,
  disabled?: boolean | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type CustomLabelInput = {
  language: string,
  label: string,
};

export enum AccountLabelCustomUsage {
  INDEXATION_REGULARIZATION = "INDEXATION_REGULARIZATION",
}


export type ModelAccountLabelConditionInput = {
  bankAccountId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  class?: ModelIntInput | null,
  topClass?: ModelIntInput | null,
  customUsage?: ModelAccountLabelCustomUsageInput | null,
  disabled?: ModelBooleanInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelAccountLabelConditionInput | null > | null,
  or?: Array< ModelAccountLabelConditionInput | null > | null,
  not?: ModelAccountLabelConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelAccountLabelCustomUsageInput = {
  eq?: AccountLabelCustomUsage | null,
  ne?: AccountLabelCustomUsage | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type AccountLabel = {
  __typename: "AccountLabel",
  id: string,
  labels?:  Array<CustomLabel > | null,
  bankAccountId?: string | null,
  bankAccount?: BankAccount | null,
  contactId?: string | null,
  contact?: Contact | null,
  class: number,
  topClass: number,
  customUsage?: AccountLabelCustomUsage | null,
  disabled?: boolean | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type CustomLabel = {
  __typename: "CustomLabel",
  language: string,
  label: string,
};

export type BankAccount = {
  __typename: "BankAccount",
  id: string,
  contactId: string,
  contact?: Contact | null,
  number: string,
  bicNumber?: string | null,
  bankName?: string | null,
  customValues?:  Array<CustomValue > | null,
  disabled?: boolean | null,
  integrations?: Array< IntegrationName > | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Contact = {
  __typename: "Contact",
  id: string,
  createdAt?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  companyName?: string | null,
  commercialName?: string | null,
  communicationSettingsProfileId?: string | null,
  customValues?:  Array<CustomValue > | null,
  businessNumber?: string | null,
  types: Array< ContactType >,
  vat?: string | null,
  email?: string | null,
  birthDate?: string | null,
  birthPlace?: string | null,
  nationalRegister?: string | null,
  civility?: CivilityType | null,
  addressId?: string | null,
  address?: Address | null,
  phoneNumbers?:  Array<PhoneNumber > | null,
  language?: string | null,
  jointOwners?:  Array<JointOwner > | null,
  agencyRateDisabled?: boolean | null,
  status?: ContactStatus | null,
  leases?: ModelLeaseContactConnection | null,
  buildings?: ModelBuildingOwnerConnection | null,
  units?: ModelUnitOwnerConnection | null,
  sendInvitation: boolean,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type CustomValue = {
  __typename: "CustomValue",
  name: string,
  value: string,
};

export enum ContactType {
  OWNER = "OWNER",
  TENANT = "TENANT",
  GUARANTOR = "GUARANTOR",
  MEMBER = "MEMBER",
  CONTRACTOR = "CONTRACTOR",
  JOINT_OWNERSHIP = "JOINT_OWNERSHIP",
  CLIENT = "CLIENT",
}


export enum CivilityType {
  MAN = "MAN",
  WOMAN = "WOMAN",
  OTHER = "OTHER",
}


export type Address = {
  __typename: "Address",
  id: string,
  status?: AddressStatus | null,
  street: string,
  number?: string | null,
  box?: string | null,
  postalCode?: string | null,
  city?: string | null,
  region?: string | null,
  country?: string | null,
  customValues?:  Array<CustomValue > | null,
  latitude?: number | null,
  longitude?: number | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum AddressStatus {
  NEW = "NEW",
  DRAFT_OCR = "DRAFT_OCR",
  ACTIVE_GEOCODED = "ACTIVE_GEOCODED",
  ERROR = "ERROR",
}


export type PhoneNumber = {
  __typename: "PhoneNumber",
  countryCode: string,
  number: string,
};

export type JointOwner = {
  __typename: "JointOwner",
  contactId: string,
  contactPerson?: boolean | null,
};

export enum ContactStatus {
  DRAFT_OCR = "DRAFT_OCR",
  ARCHIVED = "ARCHIVED",
  ERROR = "ERROR",
}


export type ModelLeaseContactConnection = {
  __typename: "ModelLeaseContactConnection",
  items:  Array<LeaseContact | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type LeaseContact = {
  __typename: "LeaseContact",
  id: string,
  leaseId: string,
  contactId: string,
  lease?: Lease | null,
  contact?: Contact | null,
  contactRole: ContactType,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Lease = {
  __typename: "Lease",
  id: string,
  balance?: number | null,
  canBeExtended?: boolean | null,
  communicationSettingsProfileId: string,
  createdAt?: string | null,
  endDate: string,
  includeRealEstateInLeaseContract?: boolean | null,
  invoiceSenderId: string,
  invoiceSender?: Contact | null,
  inventoryOfFixturesExpert?: string | null,
  inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
  inventoryOfFixturesType?: InventoryOfFixturesType | null,
  ipiNumber?: string | null,
  language: Language,
  lastIndexationProcessDate?: string | null,
  lastInvoiceDate?: string | null,
  legalCompetentAuthority?: string | null,
  name: string,
  paymentFrequency: LeasePaymentFrequency,
  paymentInvoicePeriod: LeasePaymentInvoicePeriod,
  paymentInvoicePeriodCustomDay?: number | null,
  realEstateAgency?: string | null,
  realEstateTax?: number | null,
  remittanceInformation?: string | null,
  rentalGuarantyAmount?: number | null,
  rentalGuarantyFinancialInstitution?: string | null,
  rentalGuarantyType?: LeaseRentalGuarantyType | null,
  signatureDate?: string | null,
  startDate: string,
  status: LeaseStatus,
  technicCommunicationSettingsProfileId: string,
  totalInitialMonthlyCharges: number,
  totalInitialRentalPrice: number,
  totalMonthlyCharges: number,
  totalRentalPrice: number,
  type: LeaseType,
  vatRateCharge?: number | null,
  vatRateRent?: number | null,
  additionalClauses?: ModelLeaseAdditionalClauseConnection | null,
  bankAccountId?: string | null,
  bankAccount?: BankAccount | null,
  contacts?: ModelLeaseContactConnection | null,
  files?: ModelFileConnection | null,
  leaseActionHistories?: ModelLeaseActionHistoryConnection | null,
  leaseInventories?: ModelLeaseInventoryConnection | null,
  leasePriceHistories?: ModelLeasePriceHistoryConnection | null,
  signatureDocuments?: ModelSignatureDocumentConnection | null,
  units?: ModelUnitLeaseConnection | null,
  variousOperations?: ModelLeaseVariousOperationConnection | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum InventoryOfFixturesMethod {
  EXPERT = "EXPERT",
  BETWEEN_PARTIES = "BETWEEN_PARTIES",
}


export enum InventoryOfFixturesType {
  FIRST_MONTH = "FIRST_MONTH",
  BEFORE_OCCUPATION = "BEFORE_OCCUPATION",
}


export enum Language {
  FR = "FR",
  NL = "NL",
  EN = "EN",
}


export enum LeasePaymentFrequency {
  MONTHLY = "MONTHLY",
  BI_MONTHLY = "BI_MONTHLY",
  QUARTERLY = "QUARTERLY",
  BI_ANNUAL = "BI_ANNUAL",
  YEARLY = "YEARLY",
}


export enum LeasePaymentInvoicePeriod {
  CONTRACT_PERIOD = "CONTRACT_PERIOD",
  CALENDAR_PERIOD = "CALENDAR_PERIOD",
  CUSTOM_PERIOD = "CUSTOM_PERIOD",
}


export enum LeaseRentalGuarantyType {
  CASH = "CASH",
  BANK = "BANK",
  CPAS = "CPAS",
  BANK_BLOCKED = "BANK_BLOCKED",
}


export enum LeaseStatus {
  Draft = "Draft",
  OutForSignature = "OutForSignature",
  Active = "Active",
  Rejected = "Rejected",
  Ended = "Ended",
  Cancelled = "Cancelled",
}


export enum LeaseType {
  MAIN_RESIDENCE_9_YEARS = "MAIN_RESIDENCE_9_YEARS",
  MAIN_RESIDENCE_SHORT = "MAIN_RESIDENCE_SHORT",
  MAIN_RESIDENCE_LONG = "MAIN_RESIDENCE_LONG",
  MAIN_RESIDENCE_INFINITE = "MAIN_RESIDENCE_INFINITE",
  COMMERCIAL = "COMMERCIAL",
  OFFICE = "OFFICE",
  WAREHOUSE = "WAREHOUSE",
  STUDENT = "STUDENT",
  FLATSHARING = "FLATSHARING",
  PARKING = "PARKING",
}


export type ModelLeaseAdditionalClauseConnection = {
  __typename: "ModelLeaseAdditionalClauseConnection",
  items:  Array<LeaseAdditionalClause | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type LeaseAdditionalClause = {
  __typename: "LeaseAdditionalClause",
  id: string,
  leaseId: string,
  lease?: Lease | null,
  title?: string | null,
  body: string,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelFileConnection = {
  __typename: "ModelFileConnection",
  items:  Array<File | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type File = {
  __typename: "File",
  id: string,
  bucket: string,
  createdAt?: string | null,
  category?: DocumentCategory | null,
  categoryId: string,
  foreignKey: string,
  foreignTableName: string,
  key: string,
  mimeType?: string | null,
  region: string,
  signaturePositions?:  Array<SignaturePosition > | null,
  size?: number | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type DocumentCategory = {
  __typename: "DocumentCategory",
  id: string,
  entity?: string | null,
  fileCategory: FileCategory,
  labels:  Array<CustomLabel >,
  parentId?: string | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum FileCategory {
  LEASE_CONTRACT = "LEASE_CONTRACT",
  LEASE_CONTRACT_END = "LEASE_CONTRACT_END",
  LEASE_INVENTORY_CONTRACT = "LEASE_INVENTORY_CONTRACT",
  CHARGE_INVOICE = "CHARGE_INVOICE",
  INVOICE = "INVOICE",
  PEB = "PEB",
  CO_OWNERSHIP_REGULATIONS = "CO_OWNERSHIP_REGULATIONS",
  RULES_OF_PROCEDURE = "RULES_OF_PROCEDURE",
  HYDROCARBONTANK_CERTIFICATE = "HYDROCARBONTANK_CERTIFICATE",
  FURNITURE_INVENTORY = "FURNITURE_INVENTORY",
  HEATING = "HEATING",
  CHIMNEY = "CHIMNEY",
  UTILITY = "UTILITY",
  DETECTOR = "DETECTOR",
  INVENTORY_OF_FIXTURES = "INVENTORY_OF_FIXTURES",
  COMMUNICATION = "COMMUNICATION",
  ADVERTISEMENT_PICTURE = "ADVERTISEMENT_PICTURE",
  CLIENT_LOGO = "CLIENT_LOGO",
  BASIC_DEED = "BASIC_DEED",
  CONTRACT = "CONTRACT",
  STATEMENT_DETAIL = "STATEMENT_DETAIL",
  STATEMENT_RECEIPT = "STATEMENT_RECEIPT",
  CONTRACT_AMENDMENT = "CONTRACT_AMENDMENT",
  LEASE_PRICE_HISTORY_DETAIL = "LEASE_PRICE_HISTORY_DETAIL",
  CUSTOM = "CUSTOM",
  INVOICE_RECEIPT = "INVOICE_RECEIPT",
  TICKET = "TICKET",
  LEASE_INVENTORY_ENCODING = "LEASE_INVENTORY_ENCODING",
  LETTER = "LETTER",
  TEMPORARY = "TEMPORARY",
}


export type SignaturePosition = {
  __typename: "SignaturePosition",
  top: number,
  left: number,
  page: number,
  width?: number | null,
  height?: number | null,
  contactId: string,
};

export type ModelLeaseActionHistoryConnection = {
  __typename: "ModelLeaseActionHistoryConnection",
  items:  Array<LeaseActionHistory | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type LeaseActionHistory = {
  __typename: "LeaseActionHistory",
  id: string,
  action: LeaseAction,
  comment?: string | null,
  contactId: string,
  createdAt?: string | null,
  createdBy?: Contact | null,
  historyDetails:  Array<LeaseActionHistoryDetail >,
  leaseId: string,
  lease?: Lease | null,
  signatureDocumentId?: string | null,
  signatureDocument?: SignatureDocument | null,
  status?: LeaseActionHistoryStatus | null,
  communicationChannel?: CommunicationChannel | null,
  communicationSendDate?: string | null,
  communicationByPlatform?: boolean | null,
  endReason?: LeaseActionEndReason | null,
  endReasonOwnUseRelation?: string | null,
  endReasonOwnUseName?: string | null,
  initiator?: LeaseActionEndInitiator | null,
  totalAmount?: number | null,
  totalAmountLabel?: string | null,
  automatic?: boolean | null,
  hasLeaseTypeChanged?: boolean | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum LeaseAction {
  EXTEND_LEASE = "EXTEND_LEASE",
  END_LEASE = "END_LEASE",
  ADD_UNIT = "ADD_UNIT",
  CANCELED_END_LEASE = "CANCELED_END_LEASE",
  REMOVE_UNIT = "REMOVE_UNIT",
  INITIAL_DATE = "INITIAL_DATE",
}


export type LeaseActionHistoryDetail = {
  __typename: "LeaseActionHistoryDetail",
  unitId: string,
  newEndDate?: string | null,
  newStartDate?: string | null,
  previousEndDate?: string | null,
  previousStartDate?: string | null,
};

export type SignatureDocument = {
  __typename: "SignatureDocument",
  id: string,
  additionalMessage?: string | null,
  communicationBody?: string | null,
  createdAt?: string | null,
  expirationDate?: string | null,
  fileIds?: Array< string > | null,
  foreignKey: string,
  foreignTableName: string,
  sentAt?: string | null,
  signatureMethods: Array< SignatureType >,
  signatureResults:  Array<SignatureDocumentResult >,
  status: SignatureDocumentStatus,
  externalStatus?: string | null,
  type: SignatureDocumentType,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum SignatureType {
  EID = "EID",
  ITSME = "ITSME",
  SMSOTP = "SMSOTP",
  EMAILOTP = "EMAILOTP",
  MANUAL = "MANUAL",
}


export type SignatureDocumentResult = {
  __typename: "SignatureDocumentResult",
  openedDate?: string | null,
  signedDate?: string | null,
  signed?: boolean | null,
  signatureLink?: string | null,
  rejectReason?: string | null,
  lastSignInviteSentDate?: string | null,
  trackingProvider?: TrackingProvider | null,
  trackingCode?: string | null,
  contactId: string,
};

export enum TrackingProvider {
  BPOST = "BPOST",
}


export enum SignatureDocumentStatus {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  SIGNED = "SIGNED",
  ERROR = "ERROR",
}


export enum SignatureDocumentType {
  DOCUMENT_TO_SIGN = "DOCUMENT_TO_SIGN",
  REGISTERED_LETTER = "REGISTERED_LETTER",
  LETTER = "LETTER",
}


export enum LeaseActionHistoryStatus {
  TO_PROCESS = "TO_PROCESS",
  PROCESSED = "PROCESSED",
}


export enum CommunicationChannel {
  EMAIL = "EMAIL",
  IN_APP = "IN_APP",
  LETTER = "LETTER",
  REGISTERED_LETTER = "REGISTERED_LETTER",
  SMS = "SMS",
  WHATSAPP = "WHATSAPP",
}


export enum LeaseActionEndReason {
  OWN_USE = "OWN_USE",
  RENOVATION = "RENOVATION",
  NONE = "NONE",
}


export enum LeaseActionEndInitiator {
  LESSOR = "LESSOR",
  TENANT = "TENANT",
  MUTUAL = "MUTUAL",
  COURT_DECISION = "COURT_DECISION",
  NOTARIAL_ACT = "NOTARIAL_ACT",
}


export type ModelLeaseInventoryConnection = {
  __typename: "ModelLeaseInventoryConnection",
  items:  Array<LeaseInventory | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type LeaseInventory = {
  __typename: "LeaseInventory",
  id: string,
  leaseId: string,
  inventoryType: LeaseInventoryType,
  status: LeaseStatus,
  createdAt: string,
  signatureDate?: string | null,
  note?: string | null,
  encodings?: ModelLeaseInventoryEncodingConnection | null,
  fileId?: string | null,
  file?: File | null,
  signatureMethods?: Array< SignatureType > | null,
  additionalMessage?: string | null,
  connectionPlugs?: Array< ConnectionPlugsType > | null,
  keys?:  Array<LeaseInventoryUnitKey > | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum LeaseInventoryType {
  FIXTURES_ENTRANCE = "FIXTURES_ENTRANCE",
  FIXTURES_EXIT = "FIXTURES_EXIT",
  FURNITURES_ENTRANCE = "FURNITURES_ENTRANCE",
  FURNITURES_EXIT = "FURNITURES_EXIT",
}


export type ModelLeaseInventoryEncodingConnection = {
  __typename: "ModelLeaseInventoryEncodingConnection",
  items:  Array<LeaseInventoryEncoding | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type LeaseInventoryEncoding = {
  __typename: "LeaseInventoryEncoding",
  id: string,
  leaseInventoryId: string,
  structureId: string,
  state?: LeaseInventoryStructureStateType | null,
  note?: string | null,
  checkedItems?: ModelLeaseInventoryCheckedItemConnection | null,
  furnitureQuantity?: number | null,
  furniturePrice?: number | null,
  furnitureExitIssues?:  Array<LeaseInventoryFurnitureExitIssue > | null,
  medias?: ModelFileConnection | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum LeaseInventoryStructureStateType {
  VERY_GOOD = "VERY_GOOD",
  GOOD = "GOOD",
  BAD = "BAD",
  VERY_BAD = "VERY_BAD",
}


export type ModelLeaseInventoryCheckedItemConnection = {
  __typename: "ModelLeaseInventoryCheckedItemConnection",
  items:  Array<LeaseInventoryCheckedItem | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type LeaseInventoryCheckedItem = {
  __typename: "LeaseInventoryCheckedItem",
  id: string,
  custom: boolean,
  leaseInventoryEncodingId: string,
  totalDefects?: number | null,
  customItemId?: string | null,
  customItem?: InventoryCustomItem | null,
  value?: string | null,
  type?: InventoryItemType | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type InventoryCustomItem = {
  __typename: "InventoryCustomItem",
  id: string,
  type: InventoryItemType,
  value: string,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum InventoryItemType {
  COLOR = "COLOR",
  MATERIAL = "MATERIAL",
  DEFECT = "DEFECT",
}


export type LeaseInventoryFurnitureExitIssue = {
  __typename: "LeaseInventoryFurnitureExitIssue",
  quantity: number,
  issue: LeaseInventoryFurnitureExitIssueType,
  action: LeaseInventoryFurnitureExitIssueAction,
  penalty?: number | null,
};

export enum LeaseInventoryFurnitureExitIssueType {
  MISSING = "MISSING",
  DAMAGED = "DAMAGED",
  REPLACED = "REPLACED",
  OUT_OF_ORDER = "OUT_OF_ORDER",
}


export enum LeaseInventoryFurnitureExitIssueAction {
  TO_BE_REPARED = "TO_BE_REPARED",
  TO_BE_REPLACED = "TO_BE_REPLACED",
  NOTHING_TO_DO = "NOTHING_TO_DO",
}


export enum ConnectionPlugsType {
  TELEVISION = "TELEVISION",
  INTERNET = "INTERNET",
  TELEPHONE = "TELEPHONE",
}


export type LeaseInventoryUnitKey = {
  __typename: "LeaseInventoryUnitKey",
  keyType: LeaseInventoryUnitKeyType,
  keyId?: string | null,
};

export enum LeaseInventoryUnitKeyType {
  APARTMENT_KEY = "APARTMENT_KEY",
  BUILDING_KEY = "BUILDING_KEY",
  HOUSE_KEY = "HOUSE_KEY",
  BASEMENT_KEY = "BASEMENT_KEY",
  ROOM_KEY = "ROOM_KEY",
  APARTMENT_BADGE = "APARTMENT_BADGE",
  BUILDING_BADGE = "BUILDING_BADGE",
  HOUSE_BADGE = "HOUSE_BADGE",
  BASEMENT_BADGE = "BASEMENT_BADGE",
  ROOM_BADGE = "ROOM_BADGE",
}


export type ModelLeasePriceHistoryConnection = {
  __typename: "ModelLeasePriceHistoryConnection",
  items:  Array<LeasePriceHistory | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type LeasePriceHistory = {
  __typename: "LeasePriceHistory",
  id: string,
  previousTotalRentalPrice: number,
  previousTotalMonthlyCharge: number,
  totalRentalPrice: number,
  optimalTotalRentalPrice?: number | null,
  totalMonthlyCharges: number,
  optimalTotalMonthlyCharges?: number | null,
  applicationDate: string,
  status: LeaseAmountUpdateStatus,
  comment?: string | null,
  amountDetails:  Array<LeasePriceHistoryDetail >,
  type: LeasePriceHistoryType,
  communicationId?: string | null,
  communication?: Communication | null,
  leaseId: string,
  lease?: Lease | null,
  createdAt?: string | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum LeaseAmountUpdateStatus {
  DRAFT = "DRAFT",
  TO_PROCESS_CLIENT_ACCEPTED = "TO_PROCESS_CLIENT_ACCEPTED",
  TO_PROCESS_CLIENT_REFUSED = "TO_PROCESS_CLIENT_REFUSED",
  ACCEPTED_PROCESSED_NEED_TO_APPLY = "ACCEPTED_PROCESSED_NEED_TO_APPLY",
  APPLIED = "APPLIED",
  REFUSED = "REFUSED",
  OUTDATED = "OUTDATED",
  ERROR = "ERROR",
}


export type LeasePriceHistoryDetail = {
  __typename: "LeasePriceHistoryDetail",
  unitId: string,
  indexationBaseIndex?: number | null,
  indexationNewIndex?: number | null,
  previousRentalPrice: number,
  newRentalPrice: number,
  rentalStatus: LeasePriceHistoryStatus,
  previousMonthlyChargesPrice: number,
  newMonthlyChargesPrice: number,
  monthlyChargesStatus: LeasePriceHistoryStatus,
  monthlyChargesType?: LeaseMonthlyChargesType | null,
  optimalNewRentalPrice?: number | null,
  optimalNewMonthlyChargesPrice?: number | null,
  epb?: string | null,
  epbIssueDate?: string | null,
  oldestIndexForCorrectionFactorCalculation?: number | null,
  newestIndexForCorrectionFactorCalculation?: number | null,
  rentIndexedPriceAfterNewEpbReduction?: number | null,
  chargesIndexedPriceAfterNewEpbReduction?: number | null,
  correctionFactor?: number | null,
  reductionPercentage?: number | null,
  rentDifference?: number | null,
  rentReduction?: number | null,
  indexedRentAfterReduction?: number | null,
  chargesDifference?: number | null,
  chargesReduction?: number | null,
  indexedChargesAfterReduction?: number | null,
  previousYearIndexationNewIndex?: number | null,
  previousYearIndexedRent?: number | null,
  previousYearIndexedCharges?: number | null,
};

export enum LeasePriceHistoryStatus {
  INDEXED = "INDEXED",
  NON_INDEXED = "NON_INDEXED",
  INDEXED_WITH_CUSTOM_AMOUNT = "INDEXED_WITH_CUSTOM_AMOUNT",
  NOT_APPLICABLE = "NOT_APPLICABLE",
}


export enum LeaseMonthlyChargesType {
  FixedPrice = "FixedPrice",
  MonthlyProvisioned = "MonthlyProvisioned",
}


export enum LeasePriceHistoryType {
  INDEXATION = "INDEXATION",
  ADJUSTMENT = "ADJUSTMENT",
  INITIAL_PRICE = "INITIAL_PRICE",
  TERMS_CHANGE = "TERMS_CHANGE",
}


export type Communication = {
  __typename: "Communication",
  id: string,
  addressId?: string | null,
  address?: Address | null,
  blockedRecipients?:  Array<CommunicationBlockedRecipient > | null,
  body: string,
  buildingId?: string | null,
  channel: CommunicationChannel,
  communicationTemplateId?: string | null,
  contact?: Contact | null,
  contactId?: string | null,
  createdAt?: string | null,
  expirationDate?: string | null,
  files?: ModelFileConnection | null,
  invoice?: Invoice | null,
  invoiceId?: string | null,
  lease?: Lease | null,
  leaseId?: string | null,
  leasePriceHistoryId?: string | null,
  leasePriceHistory?: LeasePriceHistory | null,
  linkedCommunicationsIds?: Array< string > | null,
  recipient?: string | null,
  recipientInBcc?: string | null,
  recipientInCc?: string | null,
  sender?: string | null,
  sentAt?: string | null,
  externalId?: string | null,
  signatureDocumentId?: string | null,
  signatureDocument?: SignatureDocument | null,
  snoozedDate?: string | null,
  statement?: Statement | null,
  statementId?: string | null,
  status: CommunicationStatus,
  subject: string,
  toSend: boolean,
  type: CommunicationType,
  _ttl?: number | null,
  unit?: Unit | null,
  unitId?: string | null,
  readId: string,
  readers?: Array< string > | null,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type CommunicationBlockedRecipient = {
  __typename: "CommunicationBlockedRecipient",
  recipient: string,
  bounceType: BounceType,
};

export enum BounceType {
  BLOCKED_BY_RECIPIENT = "BLOCKED_BY_RECIPIENT",
  CONTENT_NOT_ACCEPTED = "CONTENT_NOT_ACCEPTED",
  INVALID_NUMBER = "INVALID_NUMBER",
  MAILBOX_DOES_NOT_EXIST = "MAILBOX_DOES_NOT_EXIST",
  MAILBOX_FULL = "MAILBOX_FULL",
  MESSAGE_TOO_LARGE = "MESSAGE_TOO_LARGE",
  TEMPORARY_FAILURE = "TEMPORARY_FAILURE",
  TWILIO_UNREACHABLE_DESTINATION = "TWILIO_UNREACHABLE_DESTINATION",
  UNKNOWN = "UNKNOWN",
}


export type Invoice = {
  __typename: "Invoice",
  id: string,
  amount: number,
  bankAccountId: string,
  bankAccount?: BankAccount | null,
  communicationId?: string | null,
  communication?: Communication | null,
  createdAt: string,
  creditNote?: boolean | null,
  creditNoteLabel?: string | null,
  dueDate?: string | null,
  invoiceDate: string,
  invoiceNumber?: string | null,
  leaseId?: string | null,
  lease?: Lease | null,
  paid: boolean,
  receiptNumber?: string | null,
  reminderDates?: Array< string > | null,
  remittanceInformation?: string | null,
  statementId?: string | null,
  statement?: Statement | null,
  type: InvoiceType,
  unitId?: string | null,
  unit?: Unit | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Statement = {
  __typename: "Statement",
  id: string,
  amounts?:  Array<CustomValue > | null,
  chargeStatements?: ModelChargeStatementConnection | null,
  closingStatement: boolean,
  periodFrom: string,
  periodTo: string,
  source: StatementSource,
  status: StatementStatus,
  sentDate?: string | null,
  leaseId?: string | null,
  lease?: Lease | null,
  agencyRateStatements?: ModelAgencyRateStatementConnection | null,
  ownerId?: string | null,
  owner?: Contact | null,
  repaymentStatements?: ModelRepaymentStatementConnection | null,
  types?: Array< StatementType > | null,
  unitIds?: Array< string > | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelChargeStatementConnection = {
  __typename: "ModelChargeStatementConnection",
  items:  Array<ChargeStatement | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ChargeStatement = {
  __typename: "ChargeStatement",
  id: string,
  charge?: Charge | null,
  chargeId: string,
  statement?: Statement | null,
  statementId: string,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Charge = {
  __typename: "Charge",
  id: string,
  status: ChargeStatus,
  invoiceDate?: string | null,
  paymentDueDate?: string | null,
  archivedDate?: string | null,
  periodFrom?: string | null,
  periodTo?: string | null,
  totalAmount?: number | null,
  source: ChargeSource,
  draftFromOcr?: boolean | null,
  accountingDocumentId?: string | null,
  paid: boolean,
  remittanceInformation?: string | null,
  note?: string | null,
  createdAt: string,
  creatorEmail?: string | null,
  createdById?: string | null,
  createdBy?: Contact | null,
  contractorId?: string | null,
  contractor?: Contact | null,
  proposedContractors?:  Array<ProposedValue > | null,
  recipientId?: string | null,
  recipient?: Contact | null,
  proposedRecipients?:  Array<ProposedValue > | null,
  documentId: string,
  document?: File | null,
  postings?: ModelPostingConnection | null,
  readId: string,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum ChargeStatus {
  TO_CHECK = "TO_CHECK",
  TO_AFFECT = "TO_AFFECT",
  AFFECTED = "AFFECTED",
}


export enum ChargeSource {
  MAIL = "MAIL",
  WEB = "WEB",
  MANUAL = "MANUAL",
}


export type ProposedValue = {
  __typename: "ProposedValue",
  id: string,
  confidence?: number | null,
};

export type ModelPostingConnection = {
  __typename: "ModelPostingConnection",
  items:  Array<Posting | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Posting = {
  __typename: "Posting",
  id: string,
  createdAt?: string | null,
  class: number,
  customLabel?: string | null,
  topClass: number,
  type: PostingType,
  totalAmount: number,
  periodFrom: string,
  periodTo: string,
  buildingId?: string | null,
  building?: Building | null,
  unitId?: string | null,
  unit?: Unit | null,
  invoiceId?: string | null,
  invoice?: Invoice | null,
  chargeId?: string | null,
  charge?: Charge | null,
  transactionId?: string | null,
  transaction?: Transaction | null,
  leaseId?: string | null,
  lease?: Lease | null,
  bankAccountId?: string | null,
  bankAccount?: BankAccount | null,
  accountLabelId?: string | null,
  accountLabel?: AccountLabel | null,
  contactId?: string | null,
  contact?: Contact | null,
  leaseVariousOperationId?: string | null,
  leaseVariousOperation?: LeaseVariousOperation | null,
  statementId?: string | null,
  amountVatExcluded?: number | null,
  vatAmount?: number | null,
  vatRate?: number | null,
  foreignId?: string | null,
  foreignType?: PostingForeignType | null,
  invoicePostingId?: string | null,
  reconciledAt?: string | null,
  status?: PostingStatus | null,
  chargeOwnerRepartition?: number | null,
  chargeRepartitionMetric?: string | null,
  chargeRepartitionValue?: number | null,
  chargeSplitNumber?: number | null,
  chargeType?: PostingChargeType | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum PostingType {
  CREDIT = "CREDIT",
  DEBIT = "DEBIT",
}


export type Building = {
  __typename: "Building",
  id: string,
  name: string,
  addressId?: string | null,
  address?: Address | null,
  acquisitionValue?: number | null,
  acquisitionAdditionalCostsValue?: number | null,
  acquisitionAdvertisedValue?: number | null,
  amountOfFacades?: number | null,
  amountOfFloors?: number | null,
  amountOfHousings?: number | null,
  buildingState?: BuildingState | null,
  cadastralIncome?: number | null,
  constructionYear?: string | null,
  customValues?:  Array<CustomValue > | null,
  janitor?: boolean | null,
  jointOwnershipId?: string | null,
  owners?: ModelBuildingOwnerConnection | null,
  propertyTax?: number | null,
  residenceName?: string | null,
  units?: ModelUnitConnection | null,
  events?: ModelBuildingEventConnection | null,
  readers?: Array< string > | null,
  teams?: Array< string > | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum BuildingState {
  VERY_GOOD = "VERY_GOOD",
  GOOD = "GOOD",
  BAD = "BAD",
  VERY_BAD = "VERY_BAD",
}


export type ModelBuildingOwnerConnection = {
  __typename: "ModelBuildingOwnerConnection",
  items:  Array<BuildingOwner | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type BuildingOwner = {
  __typename: "BuildingOwner",
  id: string,
  buildingId: string,
  building?: Building | null,
  contactId: string,
  disabled?: boolean | null,
  owner?: Contact | null,
  periodFrom?: string | null,
  periodTo?: string | null,
  stake: number,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelUnitConnection = {
  __typename: "ModelUnitConnection",
  items:  Array<Unit | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Unit = {
  __typename: "Unit",
  id: string,
  acquisitionAdditionalCostsValue?: number | null,
  acquisitionAdvertisedValue?: number | null,
  acquisitionValue?: number | null,
  advertisedMonthlyCharges?: number | null,
  advertisedRentalPrice?: number | null,
  cadastralIncome?: number | null,
  createdAt?: string | null,
  customValues?:  Array<CustomValue > | null,
  floor?: number | null,
  jointOwnershipId?: string | null,
  managementDate: string,
  name: string,
  predictedPrice?: string | null,
  propertyTax?: number | null,
  publishAddress?: boolean | null,
  surface?: number | null,
  type: UnitType,
  buildingId: string,
  building?: Building | null,
  owners?: ModelUnitOwnerConnection | null,
  leases?: ModelUnitLeaseConnection | null,
  events?: ModelUnitEventConnection | null,
  unitAdvertisementId?: string | null,
  unitInventories?: ModelUnitInventoryConnection | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum UnitType {
  RESIDENTIAL = "RESIDENTIAL",
  STUDENT = "STUDENT",
  COMMERCIAL = "COMMERCIAL",
  WAREHOUSE = "WAREHOUSE",
  OFFICE = "OFFICE",
  PARKING = "PARKING",
  OTHER = "OTHER",
}


export type ModelUnitOwnerConnection = {
  __typename: "ModelUnitOwnerConnection",
  items:  Array<UnitOwner | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UnitOwner = {
  __typename: "UnitOwner",
  id: string,
  disabled?: boolean | null,
  owner?: Contact | null,
  ownerId: string,
  periodFrom?: string | null,
  periodTo?: string | null,
  stake: number,
  unit?: Unit | null,
  unitId: string,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelUnitLeaseConnection = {
  __typename: "ModelUnitLeaseConnection",
  items:  Array<UnitLease | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UnitLease = {
  __typename: "UnitLease",
  id: string,
  allowChargeAdjustment?: boolean | null,
  authorizeProfessionalActivity?: boolean | null,
  chargesIndexation?: boolean | null,
  createdAt?: string | null,
  endDate: string,
  furnishedRental?: boolean | null,
  furnishedRentalInsuranceByTenant?: boolean | null,
  furnishedRentalRentalPricePercentage?: number | null,
  initialMonthlyCharges: number,
  initialRentalPrice: number,
  indexation?: boolean | null,
  advancedIndexation?: boolean | null,
  indexType?: IndexType | null,
  baseYear?: number | null,
  baseIndexType?: BaseIndexType | null,
  baseIndexDate?: string | null,
  leaseId: string,
  mainUnit: boolean,
  monthlyChargesType?: LeaseMonthlyChargesType | null,
  monthlyCharges: number,
  rentalPrice: number,
  signatureDate?: string | null,
  startDate: string,
  unitId: string,
  unit?: Unit | null,
  lease?: Lease | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum IndexType {
  HEALTH = "HEALTH",
  CONSUMER_PRICE = "CONSUMER_PRICE",
  CONSUMER_PRICE_NO_ENERGY = "CONSUMER_PRICE_NO_ENERGY",
}


export enum BaseIndexType {
  MONTH_BEFORE_SIGNATURE_DATE = "MONTH_BEFORE_SIGNATURE_DATE",
  MONTH_BEFORE_START_DATE = "MONTH_BEFORE_START_DATE",
  CUSTOM_DATE = "CUSTOM_DATE",
}


export type ModelUnitEventConnection = {
  __typename: "ModelUnitEventConnection",
  items:  Array<UnitEvent | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UnitEvent = {
  __typename: "UnitEvent",
  id: string,
  unitId: string,
  type: UnitEventType,
  startDate: string,
  endDate: string,
  totalCost?: number | null,
  note?: string | null,
  writers?: Array< string > | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum UnitEventType {
  OWN_USE = "OWN_USE",
  WORK = "WORK",
}


export type ModelUnitInventoryConnection = {
  __typename: "ModelUnitInventoryConnection",
  items:  Array<UnitInventory | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UnitInventory = {
  __typename: "UnitInventory",
  id: string,
  privateBatthRoom?: boolean | null,
  createdAt?: string | null,
  deletedAt?: string | null,
  kitchenEquipment?: KitchenEquipment | null,
  roomName?: string | null,
  roomType: UnitInventoryRoomType,
  surface?: number | null,
  showSurface?: boolean | null,
  unitId: string,
  inside: boolean,
  structures?: ModelUnitInventoryStructureConnection | null,
  readId: string,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum KitchenEquipment {
  FULLY_EQUIPPED = "FULLY_EQUIPPED",
  EQUIPPED = "EQUIPPED",
  PARTIALLY_EQUIPPED = "PARTIALLY_EQUIPPED",
  UNEQUIPPED = "UNEQUIPPED",
  AMERICAN_FULLY_EQUIPPED = "AMERICAN_FULLY_EQUIPPED",
  AMERICAN_EQUIPPED = "AMERICAN_EQUIPPED",
  AMERICAN_PARTIALLY_EQUIPPED = "AMERICAN_PARTIALLY_EQUIPPED",
  AMERICAN_UNEQUIPPED = "AMERICAN_UNEQUIPPED",
}


export enum UnitInventoryRoomType {
  ENTRANCE = "ENTRANCE",
  LIVING = "LIVING",
  DINING = "DINING",
  KITCHEN = "KITCHEN",
  LIVING_WITH_OPEN_KITCHEN = "LIVING_WITH_OPEN_KITCHEN",
  OFFICE = "OFFICE",
  BEDROOM = "BEDROOM",
  BATHROOM = "BATHROOM",
  BALCONY = "BALCONY",
  SHOWER = "SHOWER",
  TOILET = "TOILET",
  CELLAR = "CELLAR",
  ATTIC = "ATTIC",
  GARDEN = "GARDEN",
  TERRACE = "TERRACE",
  PATIO = "PATIO",
  GARAGE = "GARAGE",
  PARKING = "PARKING",
  OTHER = "OTHER",
  REUNION = "REUNION",
  TECHNICAL = "TECHNICAL",
  INDIVIDUAL_OFFICE = "INDIVIDUAL_OFFICE",
  OPENSPACE = "OPENSPACE",
  WAREHOUSE = "WAREHOUSE",
}


export type ModelUnitInventoryStructureConnection = {
  __typename: "ModelUnitInventoryStructureConnection",
  items:  Array<UnitInventoryStructure | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UnitInventoryStructure = {
  __typename: "UnitInventoryStructure",
  id: string,
  createdAt?: string | null,
  deletedAt?: string | null,
  unitInventoryId: string,
  encodings?: ModelLeaseInventoryEncodingConnection | null,
  type: UnitInventoryStructureType,
  name: string,
  brand?: string | null,
  readId: string,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum UnitInventoryStructureType {
  GENERAL = "GENERAL",
  EQUIPMENT = "EQUIPMENT",
  FURNITURE = "FURNITURE",
  OTHER = "OTHER",
}


export type ModelBuildingEventConnection = {
  __typename: "ModelBuildingEventConnection",
  items:  Array<BuildingEvent | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type BuildingEvent = {
  __typename: "BuildingEvent",
  id: string,
  buildingId: string,
  type: string,
  startDate: string,
  endDate: string,
  totalCost?: number | null,
  note?: string | null,
  writers?: Array< string > | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Transaction = {
  __typename: "Transaction",
  id: string,
  status: TransactionStatus,
  sequence?: number | null,
  statementDate: string,
  operationDate?: string | null,
  createdAt?: string | null,
  remittanceInformationType?: RemittanceType | null,
  remittanceInformation?: string | null,
  comment?: string | null,
  amount: number,
  bankAccountId: string,
  bankAccount?: BankAccount | null,
  counterpartName?: string | null,
  counterpartAccountNumber?: string | null,
  manualEntry?: boolean | null,
  proposedLeaseId?: string | null,
  proposedUnitId?: string | null,
  proposedContractorId?: string | null,
  proposedMatch?: number | null,
  links?:  Array<TransactionLink > | null,
  postings?: ModelPostingConnection | null,
  updatedAt: string,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum TransactionStatus {
  TO_RECONCILE = "TO_RECONCILE",
  PARTIALLY_RECONCILED = "PARTIALLY_RECONCILED",
  RECONCILED = "RECONCILED",
  EXCLUDED = "EXCLUDED",
  IGNORE = "IGNORE",
}


export enum RemittanceType {
  structured = "structured",
  unstructured = "unstructured",
}


export type TransactionLink = {
  __typename: "TransactionLink",
  amount: number,
  linkId: string,
  linkType: TransactionLinkType,
};

export enum TransactionLinkType {
  CONTACT = "CONTACT",
  LEASE = "LEASE",
}


export type LeaseVariousOperation = {
  __typename: "LeaseVariousOperation",
  id: string,
  amount: number,
  contactId: string,
  createdAt: string,
  createdBy?: Contact | null,
  endDate: string,
  label: string,
  lastInvoiceDate?: string | null,
  leaseId: string,
  lease?: Lease | null,
  leaseVariousOperationHistories?:  Array<LeaseVariousOperationHistory > | null,
  operationType: LeaseVariousOperationType,
  paymentFrequency: LeasePaymentFrequency,
  paymentInvoicePeriod: LeasePaymentInvoicePeriod,
  paymentInvoicePeriodCustomDay?: number | null,
  reason?: string | null,
  separateInvoice: boolean,
  startDate: string,
  type: VariousOperationType,
  unit?: Unit | null,
  unitId: string,
  vatRate?: number | null,
  readId: string,
  readers?: Array< string > | null,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type LeaseVariousOperationHistory = {
  __typename: "LeaseVariousOperationHistory",
  periodFrom: string,
  periodTo: string,
  amount: number,
};

export enum LeaseVariousOperationType {
  DISCOUNT = "DISCOUNT",
  CHARGE = "CHARGE",
  SERVICE = "SERVICE",
}


export enum VariousOperationType {
  ONE_TIME = "ONE_TIME",
  END_WITH_LEASE = "END_WITH_LEASE",
  RECURRING = "RECURRING",
}


export enum PostingForeignType {
  TRANSACTION_PENDING_BALANCE = "TRANSACTION_PENDING_BALANCE",
  TRANSACTION_RECONCILIATION = "TRANSACTION_RECONCILIATION",
}


export enum PostingStatus {
  PAID_BY_OWNER = "PAID_BY_OWNER",
  TO_BE_PAID_BY_TENANT = "TO_BE_PAID_BY_TENANT",
  TO_BE_PAID_BY_OWNER = "TO_BE_PAID_BY_OWNER",
}


export enum PostingChargeType {
  OWNER = "OWNER",
  TENANT = "TENANT",
  OWNER_VACANCY = "OWNER_VACANCY",
}


export enum StatementSource {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
}


export enum StatementStatus {
  TO_VALIDATE = "TO_VALIDATE",
  DONE = "DONE",
}


export type ModelAgencyRateStatementConnection = {
  __typename: "ModelAgencyRateStatementConnection",
  items:  Array<AgencyRateStatement | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type AgencyRateStatement = {
  __typename: "AgencyRateStatement",
  id: string,
  agencyRate?: AgencyRate | null,
  agencyRateId: string,
  statement?: Statement | null,
  statementId: string,
  unitAmounts?:  Array<AgencyRateStatementAmount > | null,
  totalAmountRateable: number,
  totalFixedAmount: number,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type AgencyRate = {
  __typename: "AgencyRate",
  id: string,
  amountDetails:  Array<AgencyRateAmountDetail >,
  amountIfVacantUnit?: number | null,
  amountMinimumFees?: number | null,
  custom: boolean,
  feesAppliedTo: AgencyRateFeesType,
  includedInAmount?: Array< AgencyRateIncludedAmount > | null,
  name: string,
  owners?: ModelAgencyRateOwnerConnection | null,
  repartitionType: AgencyRateRepartitionType,
  type: AgencyRateType,
  unitType: Array< UnitType >,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type AgencyRateAmountDetail = {
  __typename: "AgencyRateAmountDetail",
  amountType: AgencyRateAmountDetailType,
  endAmount?: number | null,
  feeApplied: number,
  startAmount: number,
};

export enum AgencyRateAmountDetailType {
  PRICE = "PRICE",
  PERCENTAGE = "PERCENTAGE",
}


export enum AgencyRateFeesType {
  AMOUNT_COLLECTED = "AMOUNT_COLLECTED",
  EXPECTED_RENT = "EXPECTED_RENT",
}


export enum AgencyRateIncludedAmount {
  RENT_WITHOUT_CHARGES = "RENT_WITHOUT_CHARGES",
  PROVISIONED_CHARGES = "PROVISIONED_CHARGES",
  FIXED_CHARGES = "FIXED_CHARGES",
  RENTAL_DISCOUNT = "RENTAL_DISCOUNT",
  INDEXED_PRICE = "INDEXED_PRICE",
  VARIOUS_OPERATION_CHARGE = "VARIOUS_OPERATION_CHARGE",
  VARIOUS_OPERATION_SERVICE = "VARIOUS_OPERATION_SERVICE",
}


export type ModelAgencyRateOwnerConnection = {
  __typename: "ModelAgencyRateOwnerConnection",
  items:  Array<AgencyRateOwner | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type AgencyRateOwner = {
  __typename: "AgencyRateOwner",
  id: string,
  agencyRateId: string,
  contactId: string,
  agencyRate?: AgencyRate | null,
  owner?: Contact | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum AgencyRateRepartitionType {
  BY_UNIT = "BY_UNIT",
  ON_PROPERTY = "ON_PROPERTY",
}


export enum AgencyRateType {
  FIXED = "FIXED",
  DECREASING = "DECREASING",
}


export type AgencyRateStatementAmount = {
  __typename: "AgencyRateStatementAmount",
  unitId?: string | null,
  amountRateable: number,
  fixedAmount: number,
};

export type ModelRepaymentStatementConnection = {
  __typename: "ModelRepaymentStatementConnection",
  items:  Array<RepaymentStatement | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type RepaymentStatement = {
  __typename: "RepaymentStatement",
  id: string,
  delaysCleared: number,
  expectedRent: number,
  lease?: Lease | null,
  leaseId: string,
  rentsCollected: number,
  repaymentStatementDetails:  Array<RepaymentStatementDetail >,
  statement?: Statement | null,
  statementId: string,
  totalReceivedInJointAccount: number,
  totalExpectedInOwnerAccount: number,
  unpaidRents: number,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type RepaymentStatementDetail = {
  __typename: "RepaymentStatementDetail",
  class: number,
  invoiceId: string,
  invoicePostingId: string,
  periodFrom: string,
  periodTo: string,
  totalAmount: number,
  transactionId?: string | null,
  type: RepaymentStatementDetailType,
};

export enum RepaymentStatementDetailType {
  EXPECTED = "EXPECTED",
  RECEIVED = "RECEIVED",
  UNPAID = "UNPAID",
}


export enum StatementType {
  CHARGES = "CHARGES",
  FEES = "FEES",
  RE_PAYMENT = "RE_PAYMENT",
}


export enum InvoiceType {
  RENT = "RENT",
  STATEMENT = "STATEMENT",
  LEASE_VARIOUS_OPERATION = "LEASE_VARIOUS_OPERATION",
  VARIOUS_OPERATION = "VARIOUS_OPERATION",
  VARIOUS_EXCEPTIONAL_REVENUE = "VARIOUS_EXCEPTIONAL_REVENUE",
}


export enum CommunicationStatus {
  TO_SEND = "TO_SEND",
  SENT = "SENT",
  SNOOZED = "SNOOZED",
  TO_DELETE = "TO_DELETE",
  BOUNCE_ERROR = "BOUNCE_ERROR",
  CREDIT_ERROR = "CREDIT_ERROR",
  OUTDATED = "OUTDATED",
}


export enum CommunicationType {
  CUSTOM = "CUSTOM",
  DOCUMENT_SIGNATURE_REMINDER = "DOCUMENT_SIGNATURE_REMINDER",
  DOCUMENT_SIGNED = "DOCUMENT_SIGNED",
  LEASE_ALLOW_PROFESSIONAL_ACTIVITY_ON = "LEASE_ALLOW_PROFESSIONAL_ACTIVITY_ON",
  LEASE_BANKING_INFO_UPDATE = "LEASE_BANKING_INFO_UPDATE",
  LEASE_CHARGES_ADJUSTMENT = "LEASE_CHARGES_ADJUSTMENT",
  LEASE_CONTRACT_EXTENDED = "LEASE_CONTRACT_EXTENDED",
  LEASE_CONTRACT_REJECTED = "LEASE_CONTRACT_REJECTED",
  LEASE_CONTRACT_RETRACTED = "LEASE_CONTRACT_RETRACTED",
  LEASE_CONTRACT_REVOKED = "LEASE_CONTRACT_REVOKED",
  LEASE_CONTRACT_SIGNATURE_REMINDER = "LEASE_CONTRACT_SIGNATURE_REMINDER",
  LEASE_CONTRACT_SIGNED = "LEASE_CONTRACT_SIGNED",
  LEASE_CREDIT_NOTE = "LEASE_CREDIT_NOTE",
  LEASE_DISCOUNT_CREATED = "LEASE_DISCOUNT_CREATED",
  LEASE_DISCOUNT_DELETED = "LEASE_DISCOUNT_DELETED",
  LEASE_DISCOUNT_UPDATED = "LEASE_DISCOUNT_UPDATED",
  LEASE_OPERATION_CREATED = "LEASE_OPERATION_CREATED",
  LEASE_OPERATION_DELETED = "LEASE_OPERATION_DELETED",
  LEASE_OPERATION_PAYMENT_REQUEST = "LEASE_OPERATION_PAYMENT_REQUEST",
  LEASE_OPERATION_UPDATED = "LEASE_OPERATION_UPDATED",
  LEASE_PAYMENT_RECEIPT = "LEASE_PAYMENT_RECEIPT",
  LEASE_PAYMENT_REQUEST = "LEASE_PAYMENT_REQUEST",
  LEASE_RENT_INDEXATION = "LEASE_RENT_INDEXATION",
  LEASE_RENT_INDEXATION_SKIPPED = "LEASE_RENT_INDEXATION_SKIPPED",
  LEASE_RENTAL_ADAPTATION = "LEASE_RENTAL_ADAPTATION",
  LEASE_TENANT_STATEMENT = "LEASE_TENANT_STATEMENT",
  LEASE_TERMINATION = "LEASE_TERMINATION",
  LEASE_UNPAID_AMOUNT_FORMAL_NOTICE = "LEASE_UNPAID_AMOUNT_FORMAL_NOTICE",
  LEASE_UNPAID_AMOUNT_REMINDER = "LEASE_UNPAID_AMOUNT_REMINDER",
  LEASE_UNPAID_AMOUNT_SECOND_REMINDER = "LEASE_UNPAID_AMOUNT_SECOND_REMINDER",
  LEASE_UNPAID_CONSOLIDATED_PAYMENT_REMINDER = "LEASE_UNPAID_CONSOLIDATED_PAYMENT_REMINDER",
  OWNER_STATEMENT = "OWNER_STATEMENT",
  SYSTEM = "SYSTEM",
}


export type ModelSignatureDocumentConnection = {
  __typename: "ModelSignatureDocumentConnection",
  items:  Array<SignatureDocument | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelLeaseVariousOperationConnection = {
  __typename: "ModelLeaseVariousOperationConnection",
  items:  Array<LeaseVariousOperation | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export enum IntegrationName {
  SAGE_BOB50 = "SAGE_BOB50",
  PONTO = "PONTO",
  MYBANX = "MYBANX",
  OCR = "OCR",
}


export type UpdateAccountLabelInput = {
  id: string,
  labels?: Array< CustomLabelInput > | null,
  bankAccountId?: string | null,
  contactId?: string | null,
  class?: number | null,
  topClass?: number | null,
  customUsage?: AccountLabelCustomUsage | null,
  disabled?: boolean | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteAccountLabelInput = {
  id: string,
  _version?: number | null,
};

export type CreateAddressInput = {
  id?: string | null,
  status?: AddressStatus | null,
  street: string,
  number?: string | null,
  box?: string | null,
  postalCode?: string | null,
  city?: string | null,
  region?: string | null,
  country?: string | null,
  customValues?: Array< CustomValueInput > | null,
  latitude?: number | null,
  longitude?: number | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type CustomValueInput = {
  name: string,
  value: string,
};

export type ModelAddressConditionInput = {
  status?: ModelAddressStatusInput | null,
  street?: ModelStringInput | null,
  number?: ModelStringInput | null,
  box?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  city?: ModelStringInput | null,
  region?: ModelStringInput | null,
  country?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelAddressConditionInput | null > | null,
  or?: Array< ModelAddressConditionInput | null > | null,
  not?: ModelAddressConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAddressStatusInput = {
  eq?: AddressStatus | null,
  ne?: AddressStatus | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateAddressInput = {
  id: string,
  status?: AddressStatus | null,
  street?: string | null,
  number?: string | null,
  box?: string | null,
  postalCode?: string | null,
  city?: string | null,
  region?: string | null,
  country?: string | null,
  customValues?: Array< CustomValueInput > | null,
  latitude?: number | null,
  longitude?: number | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteAddressInput = {
  id: string,
  _version?: number | null,
};

export type CreateAdvertisementInput = {
  id?: string | null,
  type?: AdvertisementUnitType | null,
  subType?: AdvertisementUnitSubType | null,
  amenities?: Array< AmenitiesType > | null,
  amountOfFacades?: number | null,
  availabilityDate?: string | null,
  certificateOfConformity?: CertificateOfConformityType | null,
  citationForPlanningOffence?: boolean | null,
  descriptions?: Array< AdvertisementDescriptionInput > | null,
  delimitedZone?: DelimitedZoneType | null,
  floodProneArea?: FloodProneAreaType | null,
  furnished?: boolean | null,
  firstOccupancy?: boolean | null,
  gardenSurface?: number | null,
  heatingAmenities?: Array< HeatingAmenities > | null,
  heatingType?: HeatingType | null,
  landTerrain?: Array< LandTerrainType > | null,
  landArea?: number | null,
  length?: number | null,
  nearbyComodities?: Array< NearbyComodityInput > | null,
  publishedPlatforms?: Array< PublishedPlatformInput > | null,
  planningPermission?: boolean | null,
  publishCadastralIncome?: boolean | null,
  resort?: boolean | null,
  rightOfPreemption?: boolean | null,
  subdivisionAuthorization?: boolean | null,
  wheelChairAccessible?: boolean | null,
  width?: number | null,
  terraceSurface?: number | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export enum AdvertisementUnitType {
  APARTMENT = "APARTMENT",
  HOUSE = "HOUSE",
  LAND = "LAND",
  COMMERCIAL = "COMMERCIAL",
  GARAGEPARKING = "GARAGEPARKING",
  SPECIAL = "SPECIAL",
}


export enum AdvertisementUnitSubType {
  STUDIO = "STUDIO",
  DUPLEX = "DUPLEX",
  TRIPLEX = "TRIPLEX",
  PENTHOUSE = "PENTHOUSE",
  GROUNDFLOORFLAT = "GROUNDFLOORFLAT",
  LOFT = "LOFT",
  STUDENTFLAT = "STUDENTFLAT",
  BUILDING = "BUILDING",
  FLAT = "FLAT",
  APARTMENTSTANDARD = "APARTMENTSTANDARD",
  VILLA = "VILLA",
  TERRACED = "TERRACED",
  FARM = "FARM",
  MANSION = "MANSION",
  COTTAGE = "COTTAGE",
  BUNGALOW = "BUNGALOW",
  CASTLE = "CASTLE",
  DETACHED = "DETACHED",
  SEMIDETACHED = "SEMIDETACHED",
  KANGAROOHOUSE = "KANGAROOHOUSE",
  PILEHOUSE = "PILEHOUSE",
  HOUSEBOAT = "HOUSEBOAT",
  MOBILEHOME = "MOBILEHOME",
  MIXED = "MIXED",
  BUILDINGLAND = "BUILDINGLAND",
  MEADOW = "MEADOW",
  WOOD = "WOOD",
  INDUSTRIALLAND = "INDUSTRIALLAND",
  FARMLAND = "FARMLAND",
  OFFICE = "OFFICE",
  OFFICEBUILDING = "OFFICEBUILDING",
  WAREHOUSE = "WAREHOUSE",
  SHOP = "SHOP",
  RESTAURANT = "RESTAURANT",
  BAR = "BAR",
  HOTEL = "HOTEL",
  RETAILSPACE = "RETAILSPACE",
  INDUSTRIAL = "INDUSTRIAL",
  BUSINESS = "BUSINESS",
  OPENAIRSPACE = "OPENAIRSPACE",
  OUTSIDEBOX = "OUTSIDEBOX",
  INSIDEBOX = "INSIDEBOX",
  COVEREDSPACE = "COVEREDSPACE",
  CARPARK = "CARPARK",
  INDOORSPACE = "INDOORSPACE",
}


export enum AmenitiesType {
  ALARM = "ALARM",
  ARMOURED_DOOR = "ARMOURED_DOOR",
  DOOR_PHONE = "DOOR_PHONE",
  FIRE_PLACE = "FIRE_PLACE",
  JACUZZI = "JACUZZI",
  LARGE_PETS_ALLOWED = "LARGE_PETS_ALLOWED",
  SAUNA = "SAUNA",
  SMALL_PETS_ALLOWED = "SMALL_PETS_ALLOWED",
  TV_CONNECTION = "TV_CONNECTION",
}


export enum CertificateOfConformityType {
  YES_CONFORM = "YES_CONFORM",
  YES_NOT_CONFORM = "YES_NOT_CONFORM",
  NO = "NO",
}


export type AdvertisementDescriptionInput = {
  language: string,
  description?: string | null,
};

export enum DelimitedZoneType {
  DELIMITED_FLOOD_ZONE = "DELIMITED_FLOOD_ZONE",
  DELIMITED_REPARIAN_ZONE = "DELIMITED_REPARIAN_ZONE",
  NOT_COMMUNICATED = "NOT_COMMUNICATED",
}


export enum FloodProneAreaType {
  RECOGNIZED_FLOOD_ZONE = "RECOGNIZED_FLOOD_ZONE",
  POTENTIAL_FLOOD_ZONE = "POTENTIAL_FLOOD_ZONE",
  NON_FLOODABLE_ZONE = "NON_FLOODABLE_ZONE",
  NOT_COMMUNICATED = "NOT_COMMUNICATED",
}


export enum HeatingAmenities {
  COLLECTIVE_HEATING = "COLLECTIVE_HEATING",
  COLLECTIVE_WATER = "COLLECTIVE_WATER",
  SIMPLE_GLAZING = "SIMPLE_GLAZING",
  DOUBLE_GLAZING = "DOUBLE_GLAZING",
  TRIPLE_GLAZING = "TRIPLE_GLAZING",
  QUADRUPLE_GLAZING = "QUADRUPLE_GLAZING",
  AIR_CONDITIONNING = "AIR_CONDITIONNING",
}


export enum HeatingType {
  GAS = "GAS",
  FUEL_OIL = "FUEL_OIL",
  ELECTRICITY = "ELECTRICITY",
  HEATING_PUMP = "HEATING_PUMP",
  WOOD = "WOOD",
  GEOTHERMAL = "GEOTHERMAL",
  SOLAR = "SOLAR",
  WIND = "WIND",
}


export enum LandTerrainType {
  STREET_FRONT_LAND = "STREET_FRONT_LAND",
  STREET_BACK_LAND = "STREET_BACK_LAND",
  FLAT_LAND = "FLAT_LAND",
}


export type NearbyComodityInput = {
  type: NearbyCommoditiesTypes,
  name: string,
  distance: number,
};

export enum NearbyCommoditiesTypes {
  RESTAURANT = "RESTAURANT",
  SUPERMARKET = "SUPERMARKET",
  TRANSPORTS = "TRANSPORTS",
  SCHOOL = "SCHOOL",
  HEALTHCARE = "HEALTHCARE",
}


export type PublishedPlatformInput = {
  name: PublishingPlatform,
  publishedDate: string,
  id: string,
};

export type ModelAdvertisementConditionInput = {
  type?: ModelAdvertisementUnitTypeInput | null,
  subType?: ModelAdvertisementUnitSubTypeInput | null,
  amenities?: ModelAmenitiesTypeListInput | null,
  amountOfFacades?: ModelIntInput | null,
  availabilityDate?: ModelStringInput | null,
  certificateOfConformity?: ModelCertificateOfConformityTypeInput | null,
  citationForPlanningOffence?: ModelBooleanInput | null,
  delimitedZone?: ModelDelimitedZoneTypeInput | null,
  floodProneArea?: ModelFloodProneAreaTypeInput | null,
  furnished?: ModelBooleanInput | null,
  firstOccupancy?: ModelBooleanInput | null,
  gardenSurface?: ModelFloatInput | null,
  heatingAmenities?: ModelHeatingAmenitiesListInput | null,
  heatingType?: ModelHeatingTypeInput | null,
  landTerrain?: ModelLandTerrainTypeListInput | null,
  landArea?: ModelFloatInput | null,
  length?: ModelFloatInput | null,
  planningPermission?: ModelBooleanInput | null,
  publishCadastralIncome?: ModelBooleanInput | null,
  resort?: ModelBooleanInput | null,
  rightOfPreemption?: ModelBooleanInput | null,
  subdivisionAuthorization?: ModelBooleanInput | null,
  wheelChairAccessible?: ModelBooleanInput | null,
  width?: ModelFloatInput | null,
  terraceSurface?: ModelFloatInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelAdvertisementConditionInput | null > | null,
  or?: Array< ModelAdvertisementConditionInput | null > | null,
  not?: ModelAdvertisementConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAdvertisementUnitTypeInput = {
  eq?: AdvertisementUnitType | null,
  ne?: AdvertisementUnitType | null,
};

export type ModelAdvertisementUnitSubTypeInput = {
  eq?: AdvertisementUnitSubType | null,
  ne?: AdvertisementUnitSubType | null,
};

export type ModelAmenitiesTypeListInput = {
  eq?: Array< AmenitiesType | null > | null,
  ne?: Array< AmenitiesType | null > | null,
  contains?: AmenitiesType | null,
  notContains?: AmenitiesType | null,
};

export type ModelCertificateOfConformityTypeInput = {
  eq?: CertificateOfConformityType | null,
  ne?: CertificateOfConformityType | null,
};

export type ModelDelimitedZoneTypeInput = {
  eq?: DelimitedZoneType | null,
  ne?: DelimitedZoneType | null,
};

export type ModelFloodProneAreaTypeInput = {
  eq?: FloodProneAreaType | null,
  ne?: FloodProneAreaType | null,
};

export type ModelHeatingAmenitiesListInput = {
  eq?: Array< HeatingAmenities | null > | null,
  ne?: Array< HeatingAmenities | null > | null,
  contains?: HeatingAmenities | null,
  notContains?: HeatingAmenities | null,
};

export type ModelHeatingTypeInput = {
  eq?: HeatingType | null,
  ne?: HeatingType | null,
};

export type ModelLandTerrainTypeListInput = {
  eq?: Array< LandTerrainType | null > | null,
  ne?: Array< LandTerrainType | null > | null,
  contains?: LandTerrainType | null,
  notContains?: LandTerrainType | null,
};

export type Advertisement = {
  __typename: "Advertisement",
  id: string,
  type?: AdvertisementUnitType | null,
  subType?: AdvertisementUnitSubType | null,
  amenities?: Array< AmenitiesType > | null,
  amountOfFacades?: number | null,
  availabilityDate?: string | null,
  certificateOfConformity?: CertificateOfConformityType | null,
  citationForPlanningOffence?: boolean | null,
  descriptions?:  Array<AdvertisementDescription > | null,
  delimitedZone?: DelimitedZoneType | null,
  floodProneArea?: FloodProneAreaType | null,
  furnished?: boolean | null,
  firstOccupancy?: boolean | null,
  gardenSurface?: number | null,
  heatingAmenities?: Array< HeatingAmenities > | null,
  heatingType?: HeatingType | null,
  landTerrain?: Array< LandTerrainType > | null,
  landArea?: number | null,
  length?: number | null,
  nearbyComodities?:  Array<NearbyComodity > | null,
  publishedPlatforms?:  Array<PublishedPlatform > | null,
  planningPermission?: boolean | null,
  publishCadastralIncome?: boolean | null,
  resort?: boolean | null,
  rightOfPreemption?: boolean | null,
  subdivisionAuthorization?: boolean | null,
  wheelChairAccessible?: boolean | null,
  width?: number | null,
  terraceSurface?: number | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type AdvertisementDescription = {
  __typename: "AdvertisementDescription",
  language: string,
  description?: string | null,
};

export type NearbyComodity = {
  __typename: "NearbyComodity",
  type: NearbyCommoditiesTypes,
  name: string,
  distance: number,
};

export type PublishedPlatform = {
  __typename: "PublishedPlatform",
  name: PublishingPlatform,
  publishedDate: string,
  id: string,
};

export type UpdateAdvertisementInput = {
  id: string,
  type?: AdvertisementUnitType | null,
  subType?: AdvertisementUnitSubType | null,
  amenities?: Array< AmenitiesType > | null,
  amountOfFacades?: number | null,
  availabilityDate?: string | null,
  certificateOfConformity?: CertificateOfConformityType | null,
  citationForPlanningOffence?: boolean | null,
  descriptions?: Array< AdvertisementDescriptionInput > | null,
  delimitedZone?: DelimitedZoneType | null,
  floodProneArea?: FloodProneAreaType | null,
  furnished?: boolean | null,
  firstOccupancy?: boolean | null,
  gardenSurface?: number | null,
  heatingAmenities?: Array< HeatingAmenities > | null,
  heatingType?: HeatingType | null,
  landTerrain?: Array< LandTerrainType > | null,
  landArea?: number | null,
  length?: number | null,
  nearbyComodities?: Array< NearbyComodityInput > | null,
  publishedPlatforms?: Array< PublishedPlatformInput > | null,
  planningPermission?: boolean | null,
  publishCadastralIncome?: boolean | null,
  resort?: boolean | null,
  rightOfPreemption?: boolean | null,
  subdivisionAuthorization?: boolean | null,
  wheelChairAccessible?: boolean | null,
  width?: number | null,
  terraceSurface?: number | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteAdvertisementInput = {
  id: string,
  _version?: number | null,
};

export type CreateAgencyRateInput = {
  id?: string | null,
  amountDetails: Array< AgencyRateAmountDetailInput >,
  amountIfVacantUnit?: number | null,
  amountMinimumFees?: number | null,
  custom: boolean,
  feesAppliedTo: AgencyRateFeesType,
  includedInAmount?: Array< AgencyRateIncludedAmount > | null,
  name: string,
  repartitionType: AgencyRateRepartitionType,
  type: AgencyRateType,
  unitType: Array< UnitType >,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type AgencyRateAmountDetailInput = {
  amountType: AgencyRateAmountDetailType,
  endAmount?: number | null,
  feeApplied: number,
  startAmount: number,
};

export type ModelAgencyRateConditionInput = {
  amountIfVacantUnit?: ModelFloatInput | null,
  amountMinimumFees?: ModelFloatInput | null,
  custom?: ModelBooleanInput | null,
  feesAppliedTo?: ModelAgencyRateFeesTypeInput | null,
  includedInAmount?: ModelAgencyRateIncludedAmountListInput | null,
  name?: ModelStringInput | null,
  repartitionType?: ModelAgencyRateRepartitionTypeInput | null,
  type?: ModelAgencyRateTypeInput | null,
  unitType?: ModelUnitTypeInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelAgencyRateConditionInput | null > | null,
  or?: Array< ModelAgencyRateConditionInput | null > | null,
  not?: ModelAgencyRateConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAgencyRateFeesTypeInput = {
  eq?: AgencyRateFeesType | null,
  ne?: AgencyRateFeesType | null,
};

export type ModelAgencyRateIncludedAmountListInput = {
  eq?: Array< AgencyRateIncludedAmount | null > | null,
  ne?: Array< AgencyRateIncludedAmount | null > | null,
  contains?: AgencyRateIncludedAmount | null,
  notContains?: AgencyRateIncludedAmount | null,
};

export type ModelAgencyRateRepartitionTypeInput = {
  eq?: AgencyRateRepartitionType | null,
  ne?: AgencyRateRepartitionType | null,
};

export type ModelAgencyRateTypeInput = {
  eq?: AgencyRateType | null,
  ne?: AgencyRateType | null,
};

export type ModelUnitTypeInput = {
  eq?: UnitType | null,
  ne?: UnitType | null,
};

export type UpdateAgencyRateInput = {
  id: string,
  amountDetails?: Array< AgencyRateAmountDetailInput > | null,
  amountIfVacantUnit?: number | null,
  amountMinimumFees?: number | null,
  custom?: boolean | null,
  feesAppliedTo?: AgencyRateFeesType | null,
  includedInAmount?: Array< AgencyRateIncludedAmount > | null,
  name?: string | null,
  repartitionType?: AgencyRateRepartitionType | null,
  type?: AgencyRateType | null,
  unitType?: Array< UnitType > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteAgencyRateInput = {
  id: string,
  _version?: number | null,
};

export type CreateAgencyRateOwnerInput = {
  id?: string | null,
  agencyRateId: string,
  contactId: string,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelAgencyRateOwnerConditionInput = {
  agencyRateId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelAgencyRateOwnerConditionInput | null > | null,
  or?: Array< ModelAgencyRateOwnerConditionInput | null > | null,
  not?: ModelAgencyRateOwnerConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateAgencyRateOwnerInput = {
  id: string,
  agencyRateId?: string | null,
  contactId?: string | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteAgencyRateOwnerInput = {
  id: string,
  _version?: number | null,
};

export type CreateAgencyRateStatementInput = {
  id?: string | null,
  agencyRateId: string,
  statementId: string,
  unitAmounts?: Array< AgencyRateStatementAmountInput > | null,
  totalAmountRateable: number,
  totalFixedAmount: number,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type AgencyRateStatementAmountInput = {
  unitId?: string | null,
  amountRateable: number,
  fixedAmount: number,
};

export type ModelAgencyRateStatementConditionInput = {
  agencyRateId?: ModelIDInput | null,
  statementId?: ModelIDInput | null,
  totalAmountRateable?: ModelFloatInput | null,
  totalFixedAmount?: ModelFloatInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelAgencyRateStatementConditionInput | null > | null,
  or?: Array< ModelAgencyRateStatementConditionInput | null > | null,
  not?: ModelAgencyRateStatementConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateAgencyRateStatementInput = {
  id: string,
  agencyRateId?: string | null,
  statementId?: string | null,
  unitAmounts?: Array< AgencyRateStatementAmountInput > | null,
  totalAmountRateable?: number | null,
  totalFixedAmount?: number | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteAgencyRateStatementInput = {
  id: string,
  _version?: number | null,
};

export type CreateBankAccountInput = {
  id?: string | null,
  contactId: string,
  number: string,
  bicNumber?: string | null,
  bankName?: string | null,
  customValues?: Array< CustomValueInput > | null,
  disabled?: boolean | null,
  integrations?: Array< IntegrationName > | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelBankAccountConditionInput = {
  contactId?: ModelIDInput | null,
  number?: ModelStringInput | null,
  bicNumber?: ModelStringInput | null,
  bankName?: ModelStringInput | null,
  disabled?: ModelBooleanInput | null,
  integrations?: ModelIntegrationNameListInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelBankAccountConditionInput | null > | null,
  or?: Array< ModelBankAccountConditionInput | null > | null,
  not?: ModelBankAccountConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIntegrationNameListInput = {
  eq?: Array< IntegrationName | null > | null,
  ne?: Array< IntegrationName | null > | null,
  contains?: IntegrationName | null,
  notContains?: IntegrationName | null,
};

export type UpdateBankAccountInput = {
  id: string,
  contactId?: string | null,
  number?: string | null,
  bicNumber?: string | null,
  bankName?: string | null,
  customValues?: Array< CustomValueInput > | null,
  disabled?: boolean | null,
  integrations?: Array< IntegrationName > | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteBankAccountInput = {
  id: string,
  _version?: number | null,
};

export type CreateBuildingInput = {
  id?: string | null,
  name: string,
  addressId?: string | null,
  acquisitionValue?: number | null,
  acquisitionAdditionalCostsValue?: number | null,
  acquisitionAdvertisedValue?: number | null,
  amountOfFacades?: number | null,
  amountOfFloors?: number | null,
  amountOfHousings?: number | null,
  buildingState?: BuildingState | null,
  cadastralIncome?: number | null,
  constructionYear?: string | null,
  customValues?: Array< CustomValueInput > | null,
  janitor?: boolean | null,
  jointOwnershipId?: string | null,
  propertyTax?: number | null,
  residenceName?: string | null,
  readers?: Array< string > | null,
  teams?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelBuildingConditionInput = {
  name?: ModelStringInput | null,
  addressId?: ModelIDInput | null,
  acquisitionValue?: ModelFloatInput | null,
  acquisitionAdditionalCostsValue?: ModelFloatInput | null,
  acquisitionAdvertisedValue?: ModelFloatInput | null,
  amountOfFacades?: ModelIntInput | null,
  amountOfFloors?: ModelIntInput | null,
  amountOfHousings?: ModelIntInput | null,
  buildingState?: ModelBuildingStateInput | null,
  cadastralIncome?: ModelFloatInput | null,
  constructionYear?: ModelStringInput | null,
  janitor?: ModelBooleanInput | null,
  jointOwnershipId?: ModelIDInput | null,
  propertyTax?: ModelFloatInput | null,
  residenceName?: ModelStringInput | null,
  readers?: ModelIDInput | null,
  teams?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelBuildingConditionInput | null > | null,
  or?: Array< ModelBuildingConditionInput | null > | null,
  not?: ModelBuildingConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelBuildingStateInput = {
  eq?: BuildingState | null,
  ne?: BuildingState | null,
};

export type UpdateBuildingInput = {
  id: string,
  name?: string | null,
  addressId?: string | null,
  acquisitionValue?: number | null,
  acquisitionAdditionalCostsValue?: number | null,
  acquisitionAdvertisedValue?: number | null,
  amountOfFacades?: number | null,
  amountOfFloors?: number | null,
  amountOfHousings?: number | null,
  buildingState?: BuildingState | null,
  cadastralIncome?: number | null,
  constructionYear?: string | null,
  customValues?: Array< CustomValueInput > | null,
  janitor?: boolean | null,
  jointOwnershipId?: string | null,
  propertyTax?: number | null,
  residenceName?: string | null,
  readers?: Array< string > | null,
  teams?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteBuildingInput = {
  id: string,
  _version?: number | null,
};

export type CreateBuildingEventInput = {
  id?: string | null,
  buildingId: string,
  type: string,
  startDate: string,
  endDate: string,
  totalCost?: number | null,
  note?: string | null,
  writers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelBuildingEventConditionInput = {
  buildingId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  totalCost?: ModelFloatInput | null,
  note?: ModelStringInput | null,
  writers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelBuildingEventConditionInput | null > | null,
  or?: Array< ModelBuildingEventConditionInput | null > | null,
  not?: ModelBuildingEventConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateBuildingEventInput = {
  id: string,
  buildingId?: string | null,
  type?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  totalCost?: number | null,
  note?: string | null,
  writers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteBuildingEventInput = {
  id: string,
  _version?: number | null,
};

export type CreateBuildingOwnerInput = {
  id?: string | null,
  buildingId: string,
  contactId: string,
  disabled?: boolean | null,
  periodFrom?: string | null,
  periodTo?: string | null,
  stake: number,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelBuildingOwnerConditionInput = {
  buildingId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  disabled?: ModelBooleanInput | null,
  periodFrom?: ModelStringInput | null,
  periodTo?: ModelStringInput | null,
  stake?: ModelFloatInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelBuildingOwnerConditionInput | null > | null,
  or?: Array< ModelBuildingOwnerConditionInput | null > | null,
  not?: ModelBuildingOwnerConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateBuildingOwnerInput = {
  id: string,
  buildingId?: string | null,
  contactId?: string | null,
  disabled?: boolean | null,
  periodFrom?: string | null,
  periodTo?: string | null,
  stake?: number | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteBuildingOwnerInput = {
  id: string,
  _version?: number | null,
};

export type CreateChargeInput = {
  id?: string | null,
  status: ChargeStatus,
  invoiceDate?: string | null,
  paymentDueDate?: string | null,
  archivedDate?: string | null,
  periodFrom?: string | null,
  periodTo?: string | null,
  totalAmount?: number | null,
  source: ChargeSource,
  draftFromOcr?: boolean | null,
  accountingDocumentId?: string | null,
  paid: boolean,
  remittanceInformation?: string | null,
  note?: string | null,
  createdAt?: string | null,
  creatorEmail?: string | null,
  createdById?: string | null,
  contractorId?: string | null,
  proposedContractors?: Array< ProposedValueInput > | null,
  recipientId?: string | null,
  proposedRecipients?: Array< ProposedValueInput > | null,
  documentId: string,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ProposedValueInput = {
  id: string,
  confidence?: number | null,
};

export type ModelChargeConditionInput = {
  status?: ModelChargeStatusInput | null,
  invoiceDate?: ModelStringInput | null,
  paymentDueDate?: ModelStringInput | null,
  archivedDate?: ModelStringInput | null,
  periodFrom?: ModelStringInput | null,
  periodTo?: ModelStringInput | null,
  totalAmount?: ModelFloatInput | null,
  source?: ModelChargeSourceInput | null,
  draftFromOcr?: ModelBooleanInput | null,
  accountingDocumentId?: ModelIDInput | null,
  paid?: ModelBooleanInput | null,
  remittanceInformation?: ModelStringInput | null,
  note?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  creatorEmail?: ModelStringInput | null,
  createdById?: ModelIDInput | null,
  contractorId?: ModelIDInput | null,
  recipientId?: ModelIDInput | null,
  documentId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelChargeConditionInput | null > | null,
  or?: Array< ModelChargeConditionInput | null > | null,
  not?: ModelChargeConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelChargeStatusInput = {
  eq?: ChargeStatus | null,
  ne?: ChargeStatus | null,
};

export type ModelChargeSourceInput = {
  eq?: ChargeSource | null,
  ne?: ChargeSource | null,
};

export type UpdateChargeInput = {
  id: string,
  status?: ChargeStatus | null,
  invoiceDate?: string | null,
  paymentDueDate?: string | null,
  archivedDate?: string | null,
  periodFrom?: string | null,
  periodTo?: string | null,
  totalAmount?: number | null,
  source?: ChargeSource | null,
  draftFromOcr?: boolean | null,
  accountingDocumentId?: string | null,
  paid?: boolean | null,
  remittanceInformation?: string | null,
  note?: string | null,
  createdAt?: string | null,
  creatorEmail?: string | null,
  createdById?: string | null,
  contractorId?: string | null,
  proposedContractors?: Array< ProposedValueInput > | null,
  recipientId?: string | null,
  proposedRecipients?: Array< ProposedValueInput > | null,
  documentId?: string | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteChargeInput = {
  id: string,
  _version?: number | null,
};

export type CreateChargeStatementInput = {
  id?: string | null,
  chargeId: string,
  statementId: string,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelChargeStatementConditionInput = {
  chargeId?: ModelIDInput | null,
  statementId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelChargeStatementConditionInput | null > | null,
  or?: Array< ModelChargeStatementConditionInput | null > | null,
  not?: ModelChargeStatementConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateChargeStatementInput = {
  id: string,
  chargeId?: string | null,
  statementId?: string | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteChargeStatementInput = {
  id: string,
  _version?: number | null,
};

export type CreateClientInput = {
  id?: string | null,
  accountType: AccountType,
  contactId: string,
  generalConditionsAcceptDate: string,
  lastGeneralConditionsAcceptedVersion: string,
  GDPRAcceptDate: string,
  lastGDPRAcceptedVersion: string,
  chargeEmail?: string | null,
  issueEmail?: string | null,
  chargeEmailFromSendingSource?: string | null,
  issueEmailFromSendingSource?: string | null,
  _version?: number | null,
};

export type ModelClientConditionInput = {
  accountType?: ModelAccountTypeInput | null,
  contactId?: ModelIDInput | null,
  generalConditionsAcceptDate?: ModelStringInput | null,
  lastGeneralConditionsAcceptedVersion?: ModelStringInput | null,
  GDPRAcceptDate?: ModelStringInput | null,
  lastGDPRAcceptedVersion?: ModelStringInput | null,
  chargeEmail?: ModelStringInput | null,
  issueEmail?: ModelStringInput | null,
  chargeEmailFromSendingSource?: ModelStringInput | null,
  issueEmailFromSendingSource?: ModelStringInput | null,
  and?: Array< ModelClientConditionInput | null > | null,
  or?: Array< ModelClientConditionInput | null > | null,
  not?: ModelClientConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAccountTypeInput = {
  eq?: AccountType | null,
  ne?: AccountType | null,
};

export type Client = {
  __typename: "Client",
  id: string,
  accountType: AccountType,
  contactId: string,
  contact?: Contact | null,
  generalConditionsAcceptDate: string,
  lastGeneralConditionsAcceptedVersion: string,
  GDPRAcceptDate: string,
  lastGDPRAcceptedVersion: string,
  chargeEmail?: string | null,
  issueEmail?: string | null,
  chargeEmailFromSendingSource?: string | null,
  issueEmailFromSendingSource?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateClientInput = {
  id: string,
  accountType?: AccountType | null,
  contactId?: string | null,
  generalConditionsAcceptDate?: string | null,
  lastGeneralConditionsAcceptedVersion?: string | null,
  GDPRAcceptDate?: string | null,
  lastGDPRAcceptedVersion?: string | null,
  chargeEmail?: string | null,
  issueEmail?: string | null,
  chargeEmailFromSendingSource?: string | null,
  issueEmailFromSendingSource?: string | null,
  _version?: number | null,
};

export type DeleteClientInput = {
  id: string,
  _version?: number | null,
};

export type CreateCommunicationInput = {
  id?: string | null,
  addressId?: string | null,
  blockedRecipients?: Array< CommunicationBlockedRecipientInput > | null,
  body: string,
  buildingId?: string | null,
  channel: CommunicationChannel,
  communicationTemplateId?: string | null,
  contactId?: string | null,
  createdAt?: string | null,
  expirationDate?: string | null,
  invoiceId?: string | null,
  leaseId?: string | null,
  leasePriceHistoryId?: string | null,
  linkedCommunicationsIds?: Array< string > | null,
  recipient?: string | null,
  recipientInBcc?: string | null,
  recipientInCc?: string | null,
  sender?: string | null,
  sentAt?: string | null,
  externalId?: string | null,
  signatureDocumentId?: string | null,
  snoozedDate?: string | null,
  statementId?: string | null,
  status: CommunicationStatus,
  subject: string,
  toSend: boolean,
  type: CommunicationType,
  _ttl?: number | null,
  unitId?: string | null,
  readId: string,
  readers?: Array< string > | null,
  clientId: string,
  _version?: number | null,
};

export type CommunicationBlockedRecipientInput = {
  recipient: string,
  bounceType: BounceType,
};

export type ModelCommunicationConditionInput = {
  addressId?: ModelIDInput | null,
  body?: ModelStringInput | null,
  buildingId?: ModelStringInput | null,
  channel?: ModelCommunicationChannelInput | null,
  communicationTemplateId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  expirationDate?: ModelStringInput | null,
  invoiceId?: ModelIDInput | null,
  leaseId?: ModelIDInput | null,
  leasePriceHistoryId?: ModelIDInput | null,
  linkedCommunicationsIds?: ModelIDInput | null,
  recipient?: ModelStringInput | null,
  recipientInBcc?: ModelStringInput | null,
  recipientInCc?: ModelStringInput | null,
  sender?: ModelStringInput | null,
  sentAt?: ModelStringInput | null,
  externalId?: ModelIDInput | null,
  signatureDocumentId?: ModelIDInput | null,
  snoozedDate?: ModelStringInput | null,
  statementId?: ModelIDInput | null,
  status?: ModelCommunicationStatusInput | null,
  subject?: ModelStringInput | null,
  toSend?: ModelBooleanInput | null,
  type?: ModelCommunicationTypeInput | null,
  _ttl?: ModelIntInput | null,
  unitId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelCommunicationConditionInput | null > | null,
  or?: Array< ModelCommunicationConditionInput | null > | null,
  not?: ModelCommunicationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCommunicationChannelInput = {
  eq?: CommunicationChannel | null,
  ne?: CommunicationChannel | null,
};

export type ModelCommunicationStatusInput = {
  eq?: CommunicationStatus | null,
  ne?: CommunicationStatus | null,
};

export type ModelCommunicationTypeInput = {
  eq?: CommunicationType | null,
  ne?: CommunicationType | null,
};

export type UpdateCommunicationInput = {
  id: string,
  addressId?: string | null,
  blockedRecipients?: Array< CommunicationBlockedRecipientInput > | null,
  body?: string | null,
  buildingId?: string | null,
  channel?: CommunicationChannel | null,
  communicationTemplateId?: string | null,
  contactId?: string | null,
  createdAt?: string | null,
  expirationDate?: string | null,
  invoiceId?: string | null,
  leaseId?: string | null,
  leasePriceHistoryId?: string | null,
  linkedCommunicationsIds?: Array< string > | null,
  recipient?: string | null,
  recipientInBcc?: string | null,
  recipientInCc?: string | null,
  sender?: string | null,
  sentAt?: string | null,
  externalId?: string | null,
  signatureDocumentId?: string | null,
  snoozedDate?: string | null,
  statementId?: string | null,
  status?: CommunicationStatus | null,
  subject?: string | null,
  toSend?: boolean | null,
  type?: CommunicationType | null,
  _ttl?: number | null,
  unitId?: string | null,
  readId?: string | null,
  readers?: Array< string > | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteCommunicationInput = {
  id: string,
  _version?: number | null,
};

export type CreateCommunicationSettingsProfileInput = {
  id?: string | null,
  communicationsSettings: Array< CommunicationSettingsInput >,
  createdAt?: string | null,
  defaultName?: string | null,
  isDefaultSetting: boolean,
  referent: CommunicationSettingsProfileReferent,
  replacementId?: string | null,
  readId: string,
  readers?: Array< string > | null,
  clientId: string,
  _version?: number | null,
};

export type CommunicationSettingsInput = {
  channel: CommunicationChannel,
  communicationType: CommunicationType,
  daysBeforeDueDate?: number | null,
  daysOverdue?: number | null,
  frequency?: CommunicationFrequencySettingInput | null,
  sendingRule: CommunicationSendingRule,
  recipients: Array< CommunicationSettingRecipient >,
  recipientsInBcc: Array< CommunicationSettingRecipient >,
  recipientsInCopy: Array< string >,
};

export type CommunicationFrequencySettingInput = {
  dateFrom?: CommunicationFrequencySettingPeriodDateFrom | null,
  periodInterval: number,
  periodType: CommunicationFrequencySettingPeriodType,
};

export enum CommunicationFrequencySettingPeriodDateFrom {
  ANNIVERSARY = "ANNIVERSARY",
  MAINTENANCE_DAY = "MAINTENANCE_DAY",
}


export enum CommunicationFrequencySettingPeriodType {
  DAYS = "DAYS",
  WEEKS = "WEEKS",
  MONTHS = "MONTHS",
  YEARS = "YEARS",
}


export enum CommunicationSendingRule {
  AUTOMATIC = "AUTOMATIC",
  DISABLE = "DISABLE",
  MANUAL = "MANUAL",
}


export enum CommunicationSettingRecipient {
  GUARANTOR = "GUARANTOR",
  OWNER = "OWNER",
  TENANT = "TENANT",
}


export enum CommunicationSettingsProfileReferent {
  LEASE = "LEASE",
  OWNER = "OWNER",
  TECHNIC = "TECHNIC",
}


export type ModelCommunicationSettingsProfileConditionInput = {
  createdAt?: ModelStringInput | null,
  defaultName?: ModelStringInput | null,
  isDefaultSetting?: ModelBooleanInput | null,
  referent?: ModelCommunicationSettingsProfileReferentInput | null,
  replacementId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelCommunicationSettingsProfileConditionInput | null > | null,
  or?: Array< ModelCommunicationSettingsProfileConditionInput | null > | null,
  not?: ModelCommunicationSettingsProfileConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCommunicationSettingsProfileReferentInput = {
  eq?: CommunicationSettingsProfileReferent | null,
  ne?: CommunicationSettingsProfileReferent | null,
};

export type CommunicationSettingsProfile = {
  __typename: "CommunicationSettingsProfile",
  id: string,
  communicationsSettings:  Array<CommunicationSettings >,
  createdAt?: string | null,
  defaultName?: string | null,
  isDefaultSetting: boolean,
  referent: CommunicationSettingsProfileReferent,
  replacementId?: string | null,
  readId: string,
  readers?: Array< string > | null,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type CommunicationSettings = {
  __typename: "CommunicationSettings",
  channel: CommunicationChannel,
  communicationType: CommunicationType,
  daysBeforeDueDate?: number | null,
  daysOverdue?: number | null,
  frequency?: CommunicationFrequencySetting | null,
  sendingRule: CommunicationSendingRule,
  recipients: Array< CommunicationSettingRecipient >,
  recipientsInBcc: Array< CommunicationSettingRecipient >,
  recipientsInCopy: Array< string >,
};

export type CommunicationFrequencySetting = {
  __typename: "CommunicationFrequencySetting",
  dateFrom?: CommunicationFrequencySettingPeriodDateFrom | null,
  periodInterval: number,
  periodType: CommunicationFrequencySettingPeriodType,
};

export type UpdateCommunicationSettingsProfileInput = {
  id: string,
  communicationsSettings?: Array< CommunicationSettingsInput > | null,
  createdAt?: string | null,
  defaultName?: string | null,
  isDefaultSetting?: boolean | null,
  referent?: CommunicationSettingsProfileReferent | null,
  replacementId?: string | null,
  readId?: string | null,
  readers?: Array< string > | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteCommunicationSettingsProfileInput = {
  id: string,
  _version?: number | null,
};

export type CreateContactInput = {
  id?: string | null,
  createdAt?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  companyName?: string | null,
  commercialName?: string | null,
  communicationSettingsProfileId?: string | null,
  customValues?: Array< CustomValueInput > | null,
  businessNumber?: string | null,
  types: Array< ContactType >,
  vat?: string | null,
  email?: string | null,
  birthDate?: string | null,
  birthPlace?: string | null,
  nationalRegister?: string | null,
  civility?: CivilityType | null,
  addressId?: string | null,
  phoneNumbers?: Array< PhoneNumberInput > | null,
  language?: string | null,
  jointOwners?: Array< JointOwnerInput > | null,
  agencyRateDisabled?: boolean | null,
  status?: ContactStatus | null,
  sendInvitation: boolean,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type PhoneNumberInput = {
  countryCode: string,
  number: string,
};

export type JointOwnerInput = {
  contactId: string,
  contactPerson?: boolean | null,
};

export type ModelContactConditionInput = {
  createdAt?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  commercialName?: ModelStringInput | null,
  communicationSettingsProfileId?: ModelIDInput | null,
  businessNumber?: ModelStringInput | null,
  types?: ModelContactTypeInput | null,
  vat?: ModelStringInput | null,
  email?: ModelStringInput | null,
  birthDate?: ModelStringInput | null,
  birthPlace?: ModelStringInput | null,
  nationalRegister?: ModelStringInput | null,
  civility?: ModelCivilityTypeInput | null,
  addressId?: ModelIDInput | null,
  language?: ModelStringInput | null,
  agencyRateDisabled?: ModelBooleanInput | null,
  status?: ModelContactStatusInput | null,
  sendInvitation?: ModelBooleanInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelContactConditionInput | null > | null,
  or?: Array< ModelContactConditionInput | null > | null,
  not?: ModelContactConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelContactTypeInput = {
  eq?: ContactType | null,
  ne?: ContactType | null,
};

export type ModelCivilityTypeInput = {
  eq?: CivilityType | null,
  ne?: CivilityType | null,
};

export type ModelContactStatusInput = {
  eq?: ContactStatus | null,
  ne?: ContactStatus | null,
};

export type UpdateContactInput = {
  id: string,
  createdAt?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  companyName?: string | null,
  commercialName?: string | null,
  communicationSettingsProfileId?: string | null,
  customValues?: Array< CustomValueInput > | null,
  businessNumber?: string | null,
  types?: Array< ContactType > | null,
  vat?: string | null,
  email?: string | null,
  birthDate?: string | null,
  birthPlace?: string | null,
  nationalRegister?: string | null,
  civility?: CivilityType | null,
  addressId?: string | null,
  phoneNumbers?: Array< PhoneNumberInput > | null,
  language?: string | null,
  jointOwners?: Array< JointOwnerInput > | null,
  agencyRateDisabled?: boolean | null,
  status?: ContactStatus | null,
  sendInvitation?: boolean | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteContactInput = {
  id: string,
  _version?: number | null,
};

export type CreateCustomFieldInput = {
  id?: string | null,
  entity: string,
  fieldName: string,
  fieldType: CustomFieldType,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export enum CustomFieldType {
  STRING = "STRING",
  NUMBER = "NUMBER",
  FLOAT = "FLOAT",
  DATE = "DATE",
  BOOLEAN = "BOOLEAN",
}


export type ModelCustomFieldConditionInput = {
  entity?: ModelStringInput | null,
  fieldName?: ModelStringInput | null,
  fieldType?: ModelCustomFieldTypeInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelCustomFieldConditionInput | null > | null,
  or?: Array< ModelCustomFieldConditionInput | null > | null,
  not?: ModelCustomFieldConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCustomFieldTypeInput = {
  eq?: CustomFieldType | null,
  ne?: CustomFieldType | null,
};

export type CustomField = {
  __typename: "CustomField",
  id: string,
  entity: string,
  fieldName: string,
  fieldType: CustomFieldType,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCustomFieldInput = {
  id: string,
  entity?: string | null,
  fieldName?: string | null,
  fieldType?: CustomFieldType | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteCustomFieldInput = {
  id: string,
  _version?: number | null,
};

export type CreateCustomSubscriptionInput = {
  id?: string | null,
  name: CustomSubscriptionType,
  value: string,
  createdAt?: string | null,
  userId?: string | null,
  _ttl: number,
  updatedAt?: string | null,
  clientId: string,
  _version?: number | null,
};

export enum CustomSubscriptionType {
  BLOCKED_COMMUNICATIONS = "BLOCKED_COMMUNICATIONS",
  COMMUNICATION = "COMMUNICATION",
  CREDITS = "CREDITS",
  ENTITY_DELETION = "ENTITY_DELETION",
  INTEGRATION = "INTEGRATION",
  OCR_SUCCESS = "OCR_SUCCESS",
  OCR_FAILURE = "OCR_FAILURE",
  REFETCH = "REFETCH",
  REPORT = "REPORT",
  SENDING_SOURCE = "SENDING_SOURCE",
  SIGNATURE_DOCUMENT = "SIGNATURE_DOCUMENT",
  SUBSCRIPTION = "SUBSCRIPTION",
  TACIT_RENEWAL = "TACIT_RENEWAL",
  TACIT_WARNING = "TACIT_WARNING",
  ZIP = "ZIP",
}


export type ModelCustomSubscriptionConditionInput = {
  name?: ModelCustomSubscriptionTypeInput | null,
  value?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  _ttl?: ModelIntInput | null,
  updatedAt?: ModelStringInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelCustomSubscriptionConditionInput | null > | null,
  or?: Array< ModelCustomSubscriptionConditionInput | null > | null,
  not?: ModelCustomSubscriptionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCustomSubscriptionTypeInput = {
  eq?: CustomSubscriptionType | null,
  ne?: CustomSubscriptionType | null,
};

export type CustomSubscription = {
  __typename: "CustomSubscription",
  id: string,
  name: CustomSubscriptionType,
  value: string,
  createdAt?: string | null,
  userId?: string | null,
  _ttl: number,
  updatedAt: string,
  clientId: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCustomSubscriptionInput = {
  id: string,
  name?: CustomSubscriptionType | null,
  value?: string | null,
  createdAt?: string | null,
  userId?: string | null,
  _ttl?: number | null,
  updatedAt?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteCustomSubscriptionInput = {
  id: string,
  _version?: number | null,
};

export type CreateDocumentCategoryInput = {
  id?: string | null,
  entity?: string | null,
  fileCategory: FileCategory,
  labels: Array< CustomLabelInput >,
  parentId?: string | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelDocumentCategoryConditionInput = {
  entity?: ModelStringInput | null,
  fileCategory?: ModelFileCategoryInput | null,
  parentId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelDocumentCategoryConditionInput | null > | null,
  or?: Array< ModelDocumentCategoryConditionInput | null > | null,
  not?: ModelDocumentCategoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFileCategoryInput = {
  eq?: FileCategory | null,
  ne?: FileCategory | null,
};

export type UpdateDocumentCategoryInput = {
  id: string,
  entity?: string | null,
  fileCategory?: FileCategory | null,
  labels?: Array< CustomLabelInput > | null,
  parentId?: string | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteDocumentCategoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateFileInput = {
  id?: string | null,
  bucket: string,
  createdAt?: string | null,
  categoryId: string,
  foreignKey: string,
  foreignTableName: string,
  key: string,
  mimeType?: string | null,
  region: string,
  signaturePositions?: Array< SignaturePositionInput > | null,
  size?: number | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type SignaturePositionInput = {
  top: number,
  left: number,
  page: number,
  width?: number | null,
  height?: number | null,
  contactId: string,
};

export type ModelFileConditionInput = {
  bucket?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  categoryId?: ModelIDInput | null,
  foreignKey?: ModelIDInput | null,
  foreignTableName?: ModelStringInput | null,
  key?: ModelStringInput | null,
  mimeType?: ModelStringInput | null,
  region?: ModelStringInput | null,
  size?: ModelIntInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelFileConditionInput | null > | null,
  or?: Array< ModelFileConditionInput | null > | null,
  not?: ModelFileConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateFileInput = {
  id: string,
  bucket?: string | null,
  createdAt?: string | null,
  categoryId?: string | null,
  foreignKey?: string | null,
  foreignTableName?: string | null,
  key?: string | null,
  mimeType?: string | null,
  region?: string | null,
  signaturePositions?: Array< SignaturePositionInput > | null,
  size?: number | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteFileInput = {
  id: string,
  _version?: number | null,
};

export type CreateIntegrationInput = {
  id?: string | null,
  configurations?: Array< IntegrationConfigurationInput > | null,
  error?: string | null,
  lastRun?: string | null,
  name: IntegrationName,
  status: IntegrationStatus,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type IntegrationConfigurationInput = {
  name: string,
  value: string,
};

export enum IntegrationStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  ERROR = "ERROR",
}


export type ModelIntegrationConditionInput = {
  error?: ModelStringInput | null,
  lastRun?: ModelStringInput | null,
  name?: ModelIntegrationNameInput | null,
  status?: ModelIntegrationStatusInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelIntegrationConditionInput | null > | null,
  or?: Array< ModelIntegrationConditionInput | null > | null,
  not?: ModelIntegrationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIntegrationNameInput = {
  eq?: IntegrationName | null,
  ne?: IntegrationName | null,
};

export type ModelIntegrationStatusInput = {
  eq?: IntegrationStatus | null,
  ne?: IntegrationStatus | null,
};

export type Integration = {
  __typename: "Integration",
  id: string,
  configurations?:  Array<IntegrationConfiguration > | null,
  error?: string | null,
  lastRun?: string | null,
  name: IntegrationName,
  status: IntegrationStatus,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type IntegrationConfiguration = {
  __typename: "IntegrationConfiguration",
  name: string,
  value: string,
};

export type UpdateIntegrationInput = {
  id: string,
  configurations?: Array< IntegrationConfigurationInput > | null,
  error?: string | null,
  lastRun?: string | null,
  name?: IntegrationName | null,
  status?: IntegrationStatus | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteIntegrationInput = {
  id: string,
  _version?: number | null,
};

export type CreateInventoryCustomItemInput = {
  id?: string | null,
  type: InventoryItemType,
  value: string,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelInventoryCustomItemConditionInput = {
  type?: ModelInventoryItemTypeInput | null,
  value?: ModelStringInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelInventoryCustomItemConditionInput | null > | null,
  or?: Array< ModelInventoryCustomItemConditionInput | null > | null,
  not?: ModelInventoryCustomItemConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelInventoryItemTypeInput = {
  eq?: InventoryItemType | null,
  ne?: InventoryItemType | null,
};

export type UpdateInventoryCustomItemInput = {
  id: string,
  type?: InventoryItemType | null,
  value?: string | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteInventoryCustomItemInput = {
  id: string,
  _version?: number | null,
};

export type CreateInvoiceInput = {
  id?: string | null,
  amount: number,
  bankAccountId: string,
  communicationId?: string | null,
  createdAt?: string | null,
  creditNote?: boolean | null,
  creditNoteLabel?: string | null,
  dueDate?: string | null,
  invoiceDate: string,
  invoiceNumber?: string | null,
  leaseId?: string | null,
  paid: boolean,
  receiptNumber?: string | null,
  reminderDates?: Array< string > | null,
  remittanceInformation?: string | null,
  statementId?: string | null,
  type: InvoiceType,
  unitId?: string | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelInvoiceConditionInput = {
  amount?: ModelFloatInput | null,
  bankAccountId?: ModelIDInput | null,
  communicationId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  creditNote?: ModelBooleanInput | null,
  creditNoteLabel?: ModelStringInput | null,
  dueDate?: ModelStringInput | null,
  invoiceDate?: ModelStringInput | null,
  invoiceNumber?: ModelStringInput | null,
  leaseId?: ModelIDInput | null,
  paid?: ModelBooleanInput | null,
  receiptNumber?: ModelStringInput | null,
  reminderDates?: ModelStringInput | null,
  remittanceInformation?: ModelStringInput | null,
  statementId?: ModelIDInput | null,
  type?: ModelInvoiceTypeInput | null,
  unitId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelInvoiceConditionInput | null > | null,
  or?: Array< ModelInvoiceConditionInput | null > | null,
  not?: ModelInvoiceConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelInvoiceTypeInput = {
  eq?: InvoiceType | null,
  ne?: InvoiceType | null,
};

export type UpdateInvoiceInput = {
  id: string,
  amount?: number | null,
  bankAccountId?: string | null,
  communicationId?: string | null,
  createdAt?: string | null,
  creditNote?: boolean | null,
  creditNoteLabel?: string | null,
  dueDate?: string | null,
  invoiceDate?: string | null,
  invoiceNumber?: string | null,
  leaseId?: string | null,
  paid?: boolean | null,
  receiptNumber?: string | null,
  reminderDates?: Array< string > | null,
  remittanceInformation?: string | null,
  statementId?: string | null,
  type?: InvoiceType | null,
  unitId?: string | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteInvoiceInput = {
  id: string,
  _version?: number | null,
};

export type CreateInvoiceSettingInput = {
  id?: string | null,
  creditNoteFormat: string,
  creditNoteLastUpdate: string,
  creditNoteIncrementNumber: number,
  invoiceFormat: string,
  invoiceLastUpdate: string,
  invoiceIncrementNumber: number,
  paymentRequestFormat: string,
  paymentRequestLastUpdate: string,
  paymentRequestIncrementNumber: number,
  receiptFormat: string,
  receiptLastUpdate: string,
  receiptIncrementNumber: number,
  ownerId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelInvoiceSettingConditionInput = {
  creditNoteFormat?: ModelStringInput | null,
  creditNoteLastUpdate?: ModelStringInput | null,
  creditNoteIncrementNumber?: ModelIntInput | null,
  invoiceFormat?: ModelStringInput | null,
  invoiceLastUpdate?: ModelStringInput | null,
  invoiceIncrementNumber?: ModelIntInput | null,
  paymentRequestFormat?: ModelStringInput | null,
  paymentRequestLastUpdate?: ModelStringInput | null,
  paymentRequestIncrementNumber?: ModelIntInput | null,
  receiptFormat?: ModelStringInput | null,
  receiptLastUpdate?: ModelStringInput | null,
  receiptIncrementNumber?: ModelIntInput | null,
  ownerId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelInvoiceSettingConditionInput | null > | null,
  or?: Array< ModelInvoiceSettingConditionInput | null > | null,
  not?: ModelInvoiceSettingConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type InvoiceSetting = {
  __typename: "InvoiceSetting",
  id: string,
  creditNoteFormat: string,
  creditNoteLastUpdate: string,
  creditNoteIncrementNumber: number,
  invoiceFormat: string,
  invoiceLastUpdate: string,
  invoiceIncrementNumber: number,
  paymentRequestFormat: string,
  paymentRequestLastUpdate: string,
  paymentRequestIncrementNumber: number,
  receiptFormat: string,
  receiptLastUpdate: string,
  receiptIncrementNumber: number,
  ownerId: string,
  owner?: Contact | null,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateInvoiceSettingInput = {
  id: string,
  creditNoteFormat?: string | null,
  creditNoteLastUpdate?: string | null,
  creditNoteIncrementNumber?: number | null,
  invoiceFormat?: string | null,
  invoiceLastUpdate?: string | null,
  invoiceIncrementNumber?: number | null,
  paymentRequestFormat?: string | null,
  paymentRequestLastUpdate?: string | null,
  paymentRequestIncrementNumber?: number | null,
  receiptFormat?: string | null,
  receiptLastUpdate?: string | null,
  receiptIncrementNumber?: number | null,
  ownerId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteInvoiceSettingInput = {
  id: string,
  _version?: number | null,
};

export type CreateLeaseInput = {
  id?: string | null,
  balance?: number | null,
  canBeExtended?: boolean | null,
  communicationSettingsProfileId: string,
  createdAt?: string | null,
  endDate: string,
  includeRealEstateInLeaseContract?: boolean | null,
  invoiceSenderId: string,
  inventoryOfFixturesExpert?: string | null,
  inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
  inventoryOfFixturesType?: InventoryOfFixturesType | null,
  ipiNumber?: string | null,
  language: Language,
  lastIndexationProcessDate?: string | null,
  lastInvoiceDate?: string | null,
  legalCompetentAuthority?: string | null,
  name: string,
  paymentFrequency: LeasePaymentFrequency,
  paymentInvoicePeriod: LeasePaymentInvoicePeriod,
  paymentInvoicePeriodCustomDay?: number | null,
  realEstateAgency?: string | null,
  realEstateTax?: number | null,
  remittanceInformation?: string | null,
  rentalGuarantyAmount?: number | null,
  rentalGuarantyFinancialInstitution?: string | null,
  rentalGuarantyType?: LeaseRentalGuarantyType | null,
  signatureDate?: string | null,
  startDate: string,
  status: LeaseStatus,
  technicCommunicationSettingsProfileId: string,
  totalInitialMonthlyCharges: number,
  totalInitialRentalPrice: number,
  totalMonthlyCharges: number,
  totalRentalPrice: number,
  type: LeaseType,
  vatRateCharge?: number | null,
  vatRateRent?: number | null,
  bankAccountId?: string | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelLeaseConditionInput = {
  balance?: ModelFloatInput | null,
  canBeExtended?: ModelBooleanInput | null,
  communicationSettingsProfileId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  includeRealEstateInLeaseContract?: ModelBooleanInput | null,
  invoiceSenderId?: ModelIDInput | null,
  inventoryOfFixturesExpert?: ModelStringInput | null,
  inventoryOfFixturesMethod?: ModelInventoryOfFixturesMethodInput | null,
  inventoryOfFixturesType?: ModelInventoryOfFixturesTypeInput | null,
  ipiNumber?: ModelStringInput | null,
  language?: ModelLanguageInput | null,
  lastIndexationProcessDate?: ModelStringInput | null,
  lastInvoiceDate?: ModelStringInput | null,
  legalCompetentAuthority?: ModelStringInput | null,
  name?: ModelStringInput | null,
  paymentFrequency?: ModelLeasePaymentFrequencyInput | null,
  paymentInvoicePeriod?: ModelLeasePaymentInvoicePeriodInput | null,
  paymentInvoicePeriodCustomDay?: ModelIntInput | null,
  realEstateAgency?: ModelStringInput | null,
  realEstateTax?: ModelIntInput | null,
  remittanceInformation?: ModelStringInput | null,
  rentalGuarantyAmount?: ModelFloatInput | null,
  rentalGuarantyFinancialInstitution?: ModelStringInput | null,
  rentalGuarantyType?: ModelLeaseRentalGuarantyTypeInput | null,
  signatureDate?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  status?: ModelLeaseStatusInput | null,
  technicCommunicationSettingsProfileId?: ModelIDInput | null,
  totalInitialMonthlyCharges?: ModelFloatInput | null,
  totalInitialRentalPrice?: ModelFloatInput | null,
  totalMonthlyCharges?: ModelFloatInput | null,
  totalRentalPrice?: ModelFloatInput | null,
  type?: ModelLeaseTypeInput | null,
  vatRateCharge?: ModelFloatInput | null,
  vatRateRent?: ModelFloatInput | null,
  bankAccountId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeaseConditionInput | null > | null,
  or?: Array< ModelLeaseConditionInput | null > | null,
  not?: ModelLeaseConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelInventoryOfFixturesMethodInput = {
  eq?: InventoryOfFixturesMethod | null,
  ne?: InventoryOfFixturesMethod | null,
};

export type ModelInventoryOfFixturesTypeInput = {
  eq?: InventoryOfFixturesType | null,
  ne?: InventoryOfFixturesType | null,
};

export type ModelLanguageInput = {
  eq?: Language | null,
  ne?: Language | null,
};

export type ModelLeasePaymentFrequencyInput = {
  eq?: LeasePaymentFrequency | null,
  ne?: LeasePaymentFrequency | null,
};

export type ModelLeasePaymentInvoicePeriodInput = {
  eq?: LeasePaymentInvoicePeriod | null,
  ne?: LeasePaymentInvoicePeriod | null,
};

export type ModelLeaseRentalGuarantyTypeInput = {
  eq?: LeaseRentalGuarantyType | null,
  ne?: LeaseRentalGuarantyType | null,
};

export type ModelLeaseStatusInput = {
  eq?: LeaseStatus | null,
  ne?: LeaseStatus | null,
};

export type ModelLeaseTypeInput = {
  eq?: LeaseType | null,
  ne?: LeaseType | null,
};

export type UpdateLeaseInput = {
  id: string,
  balance?: number | null,
  canBeExtended?: boolean | null,
  communicationSettingsProfileId?: string | null,
  createdAt?: string | null,
  endDate?: string | null,
  includeRealEstateInLeaseContract?: boolean | null,
  invoiceSenderId?: string | null,
  inventoryOfFixturesExpert?: string | null,
  inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
  inventoryOfFixturesType?: InventoryOfFixturesType | null,
  ipiNumber?: string | null,
  language?: Language | null,
  lastIndexationProcessDate?: string | null,
  lastInvoiceDate?: string | null,
  legalCompetentAuthority?: string | null,
  name?: string | null,
  paymentFrequency?: LeasePaymentFrequency | null,
  paymentInvoicePeriod?: LeasePaymentInvoicePeriod | null,
  paymentInvoicePeriodCustomDay?: number | null,
  realEstateAgency?: string | null,
  realEstateTax?: number | null,
  remittanceInformation?: string | null,
  rentalGuarantyAmount?: number | null,
  rentalGuarantyFinancialInstitution?: string | null,
  rentalGuarantyType?: LeaseRentalGuarantyType | null,
  signatureDate?: string | null,
  startDate?: string | null,
  status?: LeaseStatus | null,
  technicCommunicationSettingsProfileId?: string | null,
  totalInitialMonthlyCharges?: number | null,
  totalInitialRentalPrice?: number | null,
  totalMonthlyCharges?: number | null,
  totalRentalPrice?: number | null,
  type?: LeaseType | null,
  vatRateCharge?: number | null,
  vatRateRent?: number | null,
  bankAccountId?: string | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteLeaseInput = {
  id: string,
  _version?: number | null,
};

export type CreateLeaseActionHistoryInput = {
  id?: string | null,
  action: LeaseAction,
  comment?: string | null,
  contactId: string,
  createdAt?: string | null,
  historyDetails: Array< LeaseActionHistoryDetailInput >,
  leaseId: string,
  signatureDocumentId?: string | null,
  status?: LeaseActionHistoryStatus | null,
  communicationChannel?: CommunicationChannel | null,
  communicationSendDate?: string | null,
  communicationByPlatform?: boolean | null,
  endReason?: LeaseActionEndReason | null,
  endReasonOwnUseRelation?: string | null,
  endReasonOwnUseName?: string | null,
  initiator?: LeaseActionEndInitiator | null,
  totalAmount?: number | null,
  totalAmountLabel?: string | null,
  automatic?: boolean | null,
  hasLeaseTypeChanged?: boolean | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type LeaseActionHistoryDetailInput = {
  unitId: string,
  newEndDate?: string | null,
  newStartDate?: string | null,
  previousEndDate?: string | null,
  previousStartDate?: string | null,
};

export type ModelLeaseActionHistoryConditionInput = {
  action?: ModelLeaseActionInput | null,
  comment?: ModelStringInput | null,
  contactId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  leaseId?: ModelIDInput | null,
  signatureDocumentId?: ModelIDInput | null,
  status?: ModelLeaseActionHistoryStatusInput | null,
  communicationChannel?: ModelCommunicationChannelInput | null,
  communicationSendDate?: ModelStringInput | null,
  communicationByPlatform?: ModelBooleanInput | null,
  endReason?: ModelLeaseActionEndReasonInput | null,
  endReasonOwnUseRelation?: ModelStringInput | null,
  endReasonOwnUseName?: ModelStringInput | null,
  initiator?: ModelLeaseActionEndInitiatorInput | null,
  totalAmount?: ModelFloatInput | null,
  totalAmountLabel?: ModelStringInput | null,
  automatic?: ModelBooleanInput | null,
  hasLeaseTypeChanged?: ModelBooleanInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeaseActionHistoryConditionInput | null > | null,
  or?: Array< ModelLeaseActionHistoryConditionInput | null > | null,
  not?: ModelLeaseActionHistoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLeaseActionInput = {
  eq?: LeaseAction | null,
  ne?: LeaseAction | null,
};

export type ModelLeaseActionHistoryStatusInput = {
  eq?: LeaseActionHistoryStatus | null,
  ne?: LeaseActionHistoryStatus | null,
};

export type ModelLeaseActionEndReasonInput = {
  eq?: LeaseActionEndReason | null,
  ne?: LeaseActionEndReason | null,
};

export type ModelLeaseActionEndInitiatorInput = {
  eq?: LeaseActionEndInitiator | null,
  ne?: LeaseActionEndInitiator | null,
};

export type UpdateLeaseActionHistoryInput = {
  id: string,
  action?: LeaseAction | null,
  comment?: string | null,
  contactId?: string | null,
  createdAt?: string | null,
  historyDetails?: Array< LeaseActionHistoryDetailInput > | null,
  leaseId?: string | null,
  signatureDocumentId?: string | null,
  status?: LeaseActionHistoryStatus | null,
  communicationChannel?: CommunicationChannel | null,
  communicationSendDate?: string | null,
  communicationByPlatform?: boolean | null,
  endReason?: LeaseActionEndReason | null,
  endReasonOwnUseRelation?: string | null,
  endReasonOwnUseName?: string | null,
  initiator?: LeaseActionEndInitiator | null,
  totalAmount?: number | null,
  totalAmountLabel?: string | null,
  automatic?: boolean | null,
  hasLeaseTypeChanged?: boolean | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteLeaseActionHistoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateLeaseAdditionalClauseInput = {
  id?: string | null,
  leaseId: string,
  title?: string | null,
  body: string,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelLeaseAdditionalClauseConditionInput = {
  leaseId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeaseAdditionalClauseConditionInput | null > | null,
  or?: Array< ModelLeaseAdditionalClauseConditionInput | null > | null,
  not?: ModelLeaseAdditionalClauseConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateLeaseAdditionalClauseInput = {
  id: string,
  leaseId?: string | null,
  title?: string | null,
  body?: string | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteLeaseAdditionalClauseInput = {
  id: string,
  _version?: number | null,
};

export type CreateLeaseContactInput = {
  id?: string | null,
  leaseId: string,
  contactId: string,
  contactRole: ContactType,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelLeaseContactConditionInput = {
  leaseId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  contactRole?: ModelContactTypeInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeaseContactConditionInput | null > | null,
  or?: Array< ModelLeaseContactConditionInput | null > | null,
  not?: ModelLeaseContactConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateLeaseContactInput = {
  id: string,
  leaseId?: string | null,
  contactId?: string | null,
  contactRole?: ContactType | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteLeaseContactInput = {
  id: string,
  _version?: number | null,
};

export type CreateLeasePriceHistoryInput = {
  id?: string | null,
  previousTotalRentalPrice: number,
  previousTotalMonthlyCharge: number,
  totalRentalPrice: number,
  optimalTotalRentalPrice?: number | null,
  totalMonthlyCharges: number,
  optimalTotalMonthlyCharges?: number | null,
  applicationDate: string,
  status: LeaseAmountUpdateStatus,
  comment?: string | null,
  amountDetails: Array< LeasePriceHistoryDetailInput >,
  type: LeasePriceHistoryType,
  communicationId?: string | null,
  leaseId: string,
  createdAt?: string | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type LeasePriceHistoryDetailInput = {
  unitId: string,
  indexationBaseIndex?: number | null,
  indexationNewIndex?: number | null,
  previousRentalPrice: number,
  newRentalPrice: number,
  rentalStatus: LeasePriceHistoryStatus,
  previousMonthlyChargesPrice: number,
  newMonthlyChargesPrice: number,
  monthlyChargesStatus: LeasePriceHistoryStatus,
  monthlyChargesType?: LeaseMonthlyChargesType | null,
  optimalNewRentalPrice?: number | null,
  optimalNewMonthlyChargesPrice?: number | null,
  epb?: string | null,
  epbIssueDate?: string | null,
  oldestIndexForCorrectionFactorCalculation?: number | null,
  newestIndexForCorrectionFactorCalculation?: number | null,
  rentIndexedPriceAfterNewEpbReduction?: number | null,
  chargesIndexedPriceAfterNewEpbReduction?: number | null,
  correctionFactor?: number | null,
  reductionPercentage?: number | null,
  rentDifference?: number | null,
  rentReduction?: number | null,
  indexedRentAfterReduction?: number | null,
  chargesDifference?: number | null,
  chargesReduction?: number | null,
  indexedChargesAfterReduction?: number | null,
  previousYearIndexationNewIndex?: number | null,
  previousYearIndexedRent?: number | null,
  previousYearIndexedCharges?: number | null,
};

export type ModelLeasePriceHistoryConditionInput = {
  previousTotalRentalPrice?: ModelFloatInput | null,
  previousTotalMonthlyCharge?: ModelFloatInput | null,
  totalRentalPrice?: ModelFloatInput | null,
  optimalTotalRentalPrice?: ModelFloatInput | null,
  totalMonthlyCharges?: ModelFloatInput | null,
  optimalTotalMonthlyCharges?: ModelFloatInput | null,
  applicationDate?: ModelStringInput | null,
  status?: ModelLeaseAmountUpdateStatusInput | null,
  comment?: ModelStringInput | null,
  type?: ModelLeasePriceHistoryTypeInput | null,
  communicationId?: ModelIDInput | null,
  leaseId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeasePriceHistoryConditionInput | null > | null,
  or?: Array< ModelLeasePriceHistoryConditionInput | null > | null,
  not?: ModelLeasePriceHistoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLeaseAmountUpdateStatusInput = {
  eq?: LeaseAmountUpdateStatus | null,
  ne?: LeaseAmountUpdateStatus | null,
};

export type ModelLeasePriceHistoryTypeInput = {
  eq?: LeasePriceHistoryType | null,
  ne?: LeasePriceHistoryType | null,
};

export type UpdateLeasePriceHistoryInput = {
  id: string,
  previousTotalRentalPrice?: number | null,
  previousTotalMonthlyCharge?: number | null,
  totalRentalPrice?: number | null,
  optimalTotalRentalPrice?: number | null,
  totalMonthlyCharges?: number | null,
  optimalTotalMonthlyCharges?: number | null,
  applicationDate?: string | null,
  status?: LeaseAmountUpdateStatus | null,
  comment?: string | null,
  amountDetails?: Array< LeasePriceHistoryDetailInput > | null,
  type?: LeasePriceHistoryType | null,
  communicationId?: string | null,
  leaseId?: string | null,
  createdAt?: string | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteLeasePriceHistoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateLeaseInventoryInput = {
  id?: string | null,
  leaseId: string,
  inventoryType: LeaseInventoryType,
  status: LeaseStatus,
  createdAt?: string | null,
  signatureDate?: string | null,
  note?: string | null,
  fileId?: string | null,
  signatureMethods?: Array< SignatureType > | null,
  additionalMessage?: string | null,
  connectionPlugs?: Array< ConnectionPlugsType > | null,
  keys?: Array< LeaseInventoryUnitKeyInput > | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type LeaseInventoryUnitKeyInput = {
  keyType: LeaseInventoryUnitKeyType,
  keyId?: string | null,
};

export type ModelLeaseInventoryConditionInput = {
  leaseId?: ModelIDInput | null,
  inventoryType?: ModelLeaseInventoryTypeInput | null,
  status?: ModelLeaseStatusInput | null,
  createdAt?: ModelStringInput | null,
  signatureDate?: ModelStringInput | null,
  note?: ModelStringInput | null,
  fileId?: ModelIDInput | null,
  signatureMethods?: ModelSignatureTypeListInput | null,
  additionalMessage?: ModelStringInput | null,
  connectionPlugs?: ModelConnectionPlugsTypeListInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeaseInventoryConditionInput | null > | null,
  or?: Array< ModelLeaseInventoryConditionInput | null > | null,
  not?: ModelLeaseInventoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLeaseInventoryTypeInput = {
  eq?: LeaseInventoryType | null,
  ne?: LeaseInventoryType | null,
};

export type ModelSignatureTypeListInput = {
  eq?: Array< SignatureType | null > | null,
  ne?: Array< SignatureType | null > | null,
  contains?: SignatureType | null,
  notContains?: SignatureType | null,
};

export type ModelConnectionPlugsTypeListInput = {
  eq?: Array< ConnectionPlugsType | null > | null,
  ne?: Array< ConnectionPlugsType | null > | null,
  contains?: ConnectionPlugsType | null,
  notContains?: ConnectionPlugsType | null,
};

export type UpdateLeaseInventoryInput = {
  id: string,
  leaseId?: string | null,
  inventoryType?: LeaseInventoryType | null,
  status?: LeaseStatus | null,
  createdAt?: string | null,
  signatureDate?: string | null,
  note?: string | null,
  fileId?: string | null,
  signatureMethods?: Array< SignatureType > | null,
  additionalMessage?: string | null,
  connectionPlugs?: Array< ConnectionPlugsType > | null,
  keys?: Array< LeaseInventoryUnitKeyInput > | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteLeaseInventoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateLeaseInventoryCheckedItemInput = {
  id?: string | null,
  custom: boolean,
  leaseInventoryEncodingId: string,
  totalDefects?: number | null,
  customItemId?: string | null,
  value?: string | null,
  type?: InventoryItemType | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelLeaseInventoryCheckedItemConditionInput = {
  custom?: ModelBooleanInput | null,
  leaseInventoryEncodingId?: ModelIDInput | null,
  totalDefects?: ModelIntInput | null,
  customItemId?: ModelIDInput | null,
  value?: ModelStringInput | null,
  type?: ModelInventoryItemTypeInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeaseInventoryCheckedItemConditionInput | null > | null,
  or?: Array< ModelLeaseInventoryCheckedItemConditionInput | null > | null,
  not?: ModelLeaseInventoryCheckedItemConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateLeaseInventoryCheckedItemInput = {
  id: string,
  custom?: boolean | null,
  leaseInventoryEncodingId?: string | null,
  totalDefects?: number | null,
  customItemId?: string | null,
  value?: string | null,
  type?: InventoryItemType | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteLeaseInventoryCheckedItemInput = {
  id: string,
  _version?: number | null,
};

export type CreateLeaseInventoryEncodingInput = {
  id?: string | null,
  leaseInventoryId: string,
  structureId: string,
  state?: LeaseInventoryStructureStateType | null,
  note?: string | null,
  furnitureQuantity?: number | null,
  furniturePrice?: number | null,
  furnitureExitIssues?: Array< LeaseInventoryFurnitureExitIssueInput > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type LeaseInventoryFurnitureExitIssueInput = {
  quantity: number,
  issue: LeaseInventoryFurnitureExitIssueType,
  action: LeaseInventoryFurnitureExitIssueAction,
  penalty?: number | null,
};

export type ModelLeaseInventoryEncodingConditionInput = {
  leaseInventoryId?: ModelIDInput | null,
  structureId?: ModelIDInput | null,
  state?: ModelLeaseInventoryStructureStateTypeInput | null,
  note?: ModelStringInput | null,
  furnitureQuantity?: ModelIntInput | null,
  furniturePrice?: ModelFloatInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeaseInventoryEncodingConditionInput | null > | null,
  or?: Array< ModelLeaseInventoryEncodingConditionInput | null > | null,
  not?: ModelLeaseInventoryEncodingConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLeaseInventoryStructureStateTypeInput = {
  eq?: LeaseInventoryStructureStateType | null,
  ne?: LeaseInventoryStructureStateType | null,
};

export type UpdateLeaseInventoryEncodingInput = {
  id: string,
  leaseInventoryId?: string | null,
  structureId?: string | null,
  state?: LeaseInventoryStructureStateType | null,
  note?: string | null,
  furnitureQuantity?: number | null,
  furniturePrice?: number | null,
  furnitureExitIssues?: Array< LeaseInventoryFurnitureExitIssueInput > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteLeaseInventoryEncodingInput = {
  id: string,
  _version?: number | null,
};

export type CreateLeaseVariousOperationInput = {
  id?: string | null,
  amount: number,
  contactId: string,
  createdAt?: string | null,
  endDate: string,
  label: string,
  lastInvoiceDate?: string | null,
  leaseId: string,
  leaseVariousOperationHistories?: Array< LeaseVariousOperationHistoryInput > | null,
  operationType: LeaseVariousOperationType,
  paymentFrequency: LeasePaymentFrequency,
  paymentInvoicePeriod: LeasePaymentInvoicePeriod,
  paymentInvoicePeriodCustomDay?: number | null,
  reason?: string | null,
  separateInvoice: boolean,
  startDate: string,
  type: VariousOperationType,
  unitId: string,
  vatRate?: number | null,
  readId: string,
  readers?: Array< string > | null,
  clientId: string,
  _version?: number | null,
};

export type LeaseVariousOperationHistoryInput = {
  periodFrom: string,
  periodTo: string,
  amount: number,
};

export type ModelLeaseVariousOperationConditionInput = {
  amount?: ModelFloatInput | null,
  contactId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  label?: ModelStringInput | null,
  lastInvoiceDate?: ModelStringInput | null,
  leaseId?: ModelIDInput | null,
  operationType?: ModelLeaseVariousOperationTypeInput | null,
  paymentFrequency?: ModelLeasePaymentFrequencyInput | null,
  paymentInvoicePeriod?: ModelLeasePaymentInvoicePeriodInput | null,
  paymentInvoicePeriodCustomDay?: ModelIntInput | null,
  reason?: ModelStringInput | null,
  separateInvoice?: ModelBooleanInput | null,
  startDate?: ModelStringInput | null,
  type?: ModelVariousOperationTypeInput | null,
  unitId?: ModelIDInput | null,
  vatRate?: ModelFloatInput | null,
  readId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeaseVariousOperationConditionInput | null > | null,
  or?: Array< ModelLeaseVariousOperationConditionInput | null > | null,
  not?: ModelLeaseVariousOperationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLeaseVariousOperationTypeInput = {
  eq?: LeaseVariousOperationType | null,
  ne?: LeaseVariousOperationType | null,
};

export type ModelVariousOperationTypeInput = {
  eq?: VariousOperationType | null,
  ne?: VariousOperationType | null,
};

export type UpdateLeaseVariousOperationInput = {
  id: string,
  amount?: number | null,
  contactId?: string | null,
  createdAt?: string | null,
  endDate?: string | null,
  label?: string | null,
  lastInvoiceDate?: string | null,
  leaseId?: string | null,
  leaseVariousOperationHistories?: Array< LeaseVariousOperationHistoryInput > | null,
  operationType?: LeaseVariousOperationType | null,
  paymentFrequency?: LeasePaymentFrequency | null,
  paymentInvoicePeriod?: LeasePaymentInvoicePeriod | null,
  paymentInvoicePeriodCustomDay?: number | null,
  reason?: string | null,
  separateInvoice?: boolean | null,
  startDate?: string | null,
  type?: VariousOperationType | null,
  unitId?: string | null,
  vatRate?: number | null,
  readId?: string | null,
  readers?: Array< string > | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteLeaseVariousOperationInput = {
  id: string,
  _version?: number | null,
};

export type CreateMortgageInput = {
  id?: string | null,
  lender: string,
  durationInYears: number,
  rateType: MortgageRateType,
  interestRate: number,
  aprRate: number,
  startDate: string,
  amount: number,
  monthlyPayment: number,
  unitId?: string | null,
  buildingId?: string | null,
  writers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export enum MortgageRateType {
  Fixed = "Fixed",
  Variable = "Variable",
}


export type ModelMortgageConditionInput = {
  lender?: ModelStringInput | null,
  durationInYears?: ModelIntInput | null,
  rateType?: ModelMortgageRateTypeInput | null,
  interestRate?: ModelFloatInput | null,
  aprRate?: ModelFloatInput | null,
  startDate?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  monthlyPayment?: ModelFloatInput | null,
  unitId?: ModelIDInput | null,
  buildingId?: ModelIDInput | null,
  writers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelMortgageConditionInput | null > | null,
  or?: Array< ModelMortgageConditionInput | null > | null,
  not?: ModelMortgageConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelMortgageRateTypeInput = {
  eq?: MortgageRateType | null,
  ne?: MortgageRateType | null,
};

export type Mortgage = {
  __typename: "Mortgage",
  id: string,
  lender: string,
  durationInYears: number,
  rateType: MortgageRateType,
  interestRate: number,
  aprRate: number,
  startDate: string,
  amount: number,
  monthlyPayment: number,
  unitId?: string | null,
  unit?: Unit | null,
  buildingId?: string | null,
  building?: Building | null,
  writers?: Array< string > | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateMortgageInput = {
  id: string,
  lender?: string | null,
  durationInYears?: number | null,
  rateType?: MortgageRateType | null,
  interestRate?: number | null,
  aprRate?: number | null,
  startDate?: string | null,
  amount?: number | null,
  monthlyPayment?: number | null,
  unitId?: string | null,
  buildingId?: string | null,
  writers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteMortgageInput = {
  id: string,
  _version?: number | null,
};

export type CreateNotificationInput = {
  id?: string | null,
  createdBy: NotificationCreation,
  type?: NotificationType | null,
  message?: string | null,
  popupDate: string,
  expirationDate?: string | null,
  status: NotificationStatus,
  createdAt?: string | null,
  buildingId?: string | null,
  unitId?: string | null,
  leaseId?: string | null,
  technicId?: string | null,
  ticketId?: string | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export enum NotificationCreation {
  Automatic = "Automatic",
  Manual = "Manual",
}


export enum NotificationType {
  PebCertificate = "PebCertificate",
  SmokeDetectorBattery = "SmokeDetectorBattery",
  FuelTankCertificate = "FuelTankCertificate",
  HeatingCertificate = "HeatingCertificate",
  ChimneySweeping = "ChimneySweeping",
  Custom = "Custom",
}


export enum NotificationStatus {
  Active = "Active",
  Snoozed = "Snoozed",
  Ignored = "Ignored",
  Done = "Done",
}


export type ModelNotificationConditionInput = {
  createdBy?: ModelNotificationCreationInput | null,
  type?: ModelNotificationTypeInput | null,
  message?: ModelStringInput | null,
  popupDate?: ModelStringInput | null,
  expirationDate?: ModelStringInput | null,
  status?: ModelNotificationStatusInput | null,
  createdAt?: ModelStringInput | null,
  buildingId?: ModelIDInput | null,
  unitId?: ModelIDInput | null,
  leaseId?: ModelIDInput | null,
  technicId?: ModelIDInput | null,
  ticketId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelNotificationCreationInput = {
  eq?: NotificationCreation | null,
  ne?: NotificationCreation | null,
};

export type ModelNotificationTypeInput = {
  eq?: NotificationType | null,
  ne?: NotificationType | null,
};

export type ModelNotificationStatusInput = {
  eq?: NotificationStatus | null,
  ne?: NotificationStatus | null,
};

export type Notification = {
  __typename: "Notification",
  id: string,
  createdBy: NotificationCreation,
  type?: NotificationType | null,
  message?: string | null,
  popupDate: string,
  expirationDate?: string | null,
  status: NotificationStatus,
  createdAt?: string | null,
  buildingId?: string | null,
  building?: Building | null,
  unitId?: string | null,
  unit?: Unit | null,
  leaseId?: string | null,
  lease?: Lease | null,
  technicId?: string | null,
  technic?: Technic | null,
  ticketId?: string | null,
  ticket?: Ticket | null,
  readId: string,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Technic = {
  __typename: "Technic",
  id: string,
  type: TechnicType,
  contractNumber?: string | null,
  communicationSettingsProfileId: string,
  maintenanceHistory?:  Array<TechnicMaintenanceHistory > | null,
  utilityType?: UtilityType | null,
  utilityEanCode?: string | null,
  utilityMeterNumber?: string | null,
  utilityDistributorId?: string | null,
  utilityDistributor?: Contact | null,
  utilityHistory?:  Array<UtilityHistory > | null,
  PebValidityDate?: string | null,
  PebEnergeticPerformance?: string | null,
  PebReportNumber?: string | null,
  PebIssueDate?: string | null,
  detectorType?: DetectorType | null,
  detectorSerialNumber?: string | null,
  detectorBrand?: string | null,
  detectorLastControlDate?: string | null,
  detectorUnitInventoryId?: string | null,
  heatingBrand?: string | null,
  heatingModel?: string | null,
  heatingInstallationDate?: string | null,
  heatingType?: HeatingType | null,
  heatingLastControlDate?: string | null,
  tankType?: TankType | null,
  tankCapacity?: number | null,
  tankState?: TankState | null,
  tankLastControlDate?: string | null,
  tankLeakDetection?: boolean | null,
  tankEncuvement?: boolean | null,
  tankWallType?: TankWallType | null,
  tankInstallationDate?: string | null,
  chimneyLastControlDate?: string | null,
  chimneyUnitInventoryId?: string | null,
  files?: ModelFileConnection | null,
  buildingId?: string | null,
  building?: Building | null,
  unitId?: string | null,
  unit?: Unit | null,
  leaseId?: string | null,
  lease?: Lease | null,
  contactId?: string | null,
  contact?: Contact | null,
  customValues?:  Array<CustomValue > | null,
  originalTechnicId?: string | null,
  readers?: Array< string > | null,
  writers?: Array< string > | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum TechnicType {
  UTILITY_PROVIDER = "UTILITY_PROVIDER",
  PEB = "PEB",
  DETECTOR = "DETECTOR",
  HEATING = "HEATING",
  CHIMNEY = "CHIMNEY",
  FUELTANK = "FUELTANK",
  LIFT = "LIFT",
}


export type TechnicMaintenanceHistory = {
  __typename: "TechnicMaintenanceHistory",
  date: string,
  contactId?: string | null,
  fileId?: string | null,
};

export enum UtilityType {
  ELECTRICITY = "ELECTRICITY",
  ELECTRICITY_DAY_NIGHT = "ELECTRICITY_DAY_NIGHT",
  GAS = "GAS",
  WATER = "WATER",
}


export type UtilityHistory = {
  __typename: "UtilityHistory",
  date: string,
  reason?: UtilityReadingReason | null,
  reading: number,
  secondReading?: number | null,
};

export enum UtilityReadingReason {
  LEASE_START = "LEASE_START",
  LEASE_END = "LEASE_END",
  NEW_SUPPLIER = "NEW_SUPPLIER",
  CONSUMPTION_TRACKING = "CONSUMPTION_TRACKING",
  MONTHLY_READING = "MONTHLY_READING",
  QUARTERLY_READING = "QUARTERLY_READING",
  YEARLY_READING = "YEARLY_READING",
  RENOVATION = "RENOVATION",
  OWN_USE_START = "OWN_USE_START",
  OWN_USE_END = "OWN_USE_END",
  OTHER = "OTHER",
}


export enum DetectorType {
  CO2 = "CO2",
  SMOKE = "SMOKE",
}


export enum TankType {
  AERIAL = "AERIAL",
  UNDERGROUND = "UNDERGROUND",
}


export enum TankState {
  NEW = "NEW",
  EXISTING = "EXISTING",
}


export enum TankWallType {
  SIMPLE = "SIMPLE",
  DOUBLE = "DOUBLE",
}


export type Ticket = {
  __typename: "Ticket",
  id: string,
  assignedAt?: string | null,
  assignedTo?: Contact | null,
  assignedToId?: string | null,
  attachment?: ModelFileConnection | null,
  building?: Building | null,
  buildingId?: string | null,
  closedAt?: string | null,
  contact?: Contact | null,
  contactId: string,
  content: string,
  conversations?: ModelTicketConnection | null,
  createdAt: string,
  emailMessageId?: string | null,
  emailReferences?: Array< string | null > | null,
  parentId?: string | null,
  plannedAt?: string | null,
  referenceCode?: string | null,
  sourceType: TicketSourceType,
  status?: TicketStatus | null,
  subject: string,
  unit?: Unit | null,
  unitId?: string | null,
  unread: boolean,
  updatedAt?: string | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelTicketConnection = {
  __typename: "ModelTicketConnection",
  items:  Array<Ticket | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export enum TicketSourceType {
  APP = "APP",
  MOBILE = "MOBILE",
  MAIL = "MAIL",
  WEB = "WEB",
}


export enum TicketStatus {
  URGENT = "URGENT",
  PENDING = "PENDING",
  RESOLVED = "RESOLVED",
  ARCHIVED = "ARCHIVED",
}


export type UpdateNotificationInput = {
  id: string,
  createdBy?: NotificationCreation | null,
  type?: NotificationType | null,
  message?: string | null,
  popupDate?: string | null,
  expirationDate?: string | null,
  status?: NotificationStatus | null,
  createdAt?: string | null,
  buildingId?: string | null,
  unitId?: string | null,
  leaseId?: string | null,
  technicId?: string | null,
  ticketId?: string | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteNotificationInput = {
  id: string,
  _version?: number | null,
};

export type CreatePostingInput = {
  id?: string | null,
  createdAt?: string | null,
  class: number,
  customLabel?: string | null,
  topClass: number,
  type: PostingType,
  totalAmount: number,
  periodFrom: string,
  periodTo: string,
  buildingId?: string | null,
  unitId?: string | null,
  invoiceId?: string | null,
  chargeId?: string | null,
  transactionId?: string | null,
  leaseId?: string | null,
  bankAccountId?: string | null,
  accountLabelId?: string | null,
  contactId?: string | null,
  leaseVariousOperationId?: string | null,
  statementId?: string | null,
  amountVatExcluded?: number | null,
  vatAmount?: number | null,
  vatRate?: number | null,
  foreignId?: string | null,
  foreignType?: PostingForeignType | null,
  invoicePostingId?: string | null,
  reconciledAt?: string | null,
  status?: PostingStatus | null,
  chargeOwnerRepartition?: number | null,
  chargeRepartitionMetric?: string | null,
  chargeRepartitionValue?: number | null,
  chargeSplitNumber?: number | null,
  chargeType?: PostingChargeType | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelPostingConditionInput = {
  createdAt?: ModelStringInput | null,
  class?: ModelIntInput | null,
  customLabel?: ModelStringInput | null,
  topClass?: ModelIntInput | null,
  type?: ModelPostingTypeInput | null,
  totalAmount?: ModelFloatInput | null,
  periodFrom?: ModelStringInput | null,
  periodTo?: ModelStringInput | null,
  buildingId?: ModelIDInput | null,
  unitId?: ModelIDInput | null,
  invoiceId?: ModelIDInput | null,
  chargeId?: ModelIDInput | null,
  transactionId?: ModelIDInput | null,
  leaseId?: ModelIDInput | null,
  bankAccountId?: ModelIDInput | null,
  accountLabelId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  leaseVariousOperationId?: ModelIDInput | null,
  statementId?: ModelIDInput | null,
  amountVatExcluded?: ModelFloatInput | null,
  vatAmount?: ModelFloatInput | null,
  vatRate?: ModelFloatInput | null,
  foreignId?: ModelIDInput | null,
  foreignType?: ModelPostingForeignTypeInput | null,
  invoicePostingId?: ModelIDInput | null,
  reconciledAt?: ModelStringInput | null,
  status?: ModelPostingStatusInput | null,
  chargeOwnerRepartition?: ModelFloatInput | null,
  chargeRepartitionMetric?: ModelStringInput | null,
  chargeRepartitionValue?: ModelFloatInput | null,
  chargeSplitNumber?: ModelIntInput | null,
  chargeType?: ModelPostingChargeTypeInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelPostingConditionInput | null > | null,
  or?: Array< ModelPostingConditionInput | null > | null,
  not?: ModelPostingConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPostingTypeInput = {
  eq?: PostingType | null,
  ne?: PostingType | null,
};

export type ModelPostingForeignTypeInput = {
  eq?: PostingForeignType | null,
  ne?: PostingForeignType | null,
};

export type ModelPostingStatusInput = {
  eq?: PostingStatus | null,
  ne?: PostingStatus | null,
};

export type ModelPostingChargeTypeInput = {
  eq?: PostingChargeType | null,
  ne?: PostingChargeType | null,
};

export type UpdatePostingInput = {
  id: string,
  createdAt?: string | null,
  class?: number | null,
  customLabel?: string | null,
  topClass?: number | null,
  type?: PostingType | null,
  totalAmount?: number | null,
  periodFrom?: string | null,
  periodTo?: string | null,
  buildingId?: string | null,
  unitId?: string | null,
  invoiceId?: string | null,
  chargeId?: string | null,
  transactionId?: string | null,
  leaseId?: string | null,
  bankAccountId?: string | null,
  accountLabelId?: string | null,
  contactId?: string | null,
  leaseVariousOperationId?: string | null,
  statementId?: string | null,
  amountVatExcluded?: number | null,
  vatAmount?: number | null,
  vatRate?: number | null,
  foreignId?: string | null,
  foreignType?: PostingForeignType | null,
  invoicePostingId?: string | null,
  reconciledAt?: string | null,
  status?: PostingStatus | null,
  chargeOwnerRepartition?: number | null,
  chargeRepartitionMetric?: string | null,
  chargeRepartitionValue?: number | null,
  chargeSplitNumber?: number | null,
  chargeType?: PostingChargeType | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeletePostingInput = {
  id: string,
  _version?: number | null,
};

export type CreateRepartitionKeyInput = {
  id?: string | null,
  name: string,
  type: RepartitionKeyType,
  accountLabelId: string,
  buildingId: string,
  unitRepartition?: Array< RepartitionValuesInput > | null,
  ownerRepartition: number,
  tenantRepartition: number,
  repartitionMetric: string,
  technics?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export enum RepartitionKeyType {
  CONSUMPTION_BASED_ON_SHARES = "CONSUMPTION_BASED_ON_SHARES",
  CONSUMPTION_BASED_ON_USAGE = "CONSUMPTION_BASED_ON_USAGE",
  SHARES = "SHARES",
  SURFACE = "SURFACE",
  CUSTOM = "CUSTOM",
}


export type RepartitionValuesInput = {
  unitId: string,
  value: number,
};

export type ModelRepartitionKeyConditionInput = {
  name?: ModelStringInput | null,
  type?: ModelRepartitionKeyTypeInput | null,
  accountLabelId?: ModelIDInput | null,
  buildingId?: ModelIDInput | null,
  ownerRepartition?: ModelFloatInput | null,
  tenantRepartition?: ModelFloatInput | null,
  repartitionMetric?: ModelStringInput | null,
  technics?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelRepartitionKeyConditionInput | null > | null,
  or?: Array< ModelRepartitionKeyConditionInput | null > | null,
  not?: ModelRepartitionKeyConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelRepartitionKeyTypeInput = {
  eq?: RepartitionKeyType | null,
  ne?: RepartitionKeyType | null,
};

export type RepartitionKey = {
  __typename: "RepartitionKey",
  id: string,
  name: string,
  type: RepartitionKeyType,
  accountLabelId: string,
  accountLabel?: AccountLabel | null,
  buildingId: string,
  building?: Building | null,
  unitRepartition?:  Array<RepartitionValues > | null,
  ownerRepartition: number,
  tenantRepartition: number,
  repartitionMetric: string,
  technics?: Array< string > | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type RepartitionValues = {
  __typename: "RepartitionValues",
  unitId: string,
  value: number,
};

export type UpdateRepartitionKeyInput = {
  id: string,
  name?: string | null,
  type?: RepartitionKeyType | null,
  accountLabelId?: string | null,
  buildingId?: string | null,
  unitRepartition?: Array< RepartitionValuesInput > | null,
  ownerRepartition?: number | null,
  tenantRepartition?: number | null,
  repartitionMetric?: string | null,
  technics?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteRepartitionKeyInput = {
  id: string,
  _version?: number | null,
};

export type CreateRepaymentStatementInput = {
  id?: string | null,
  delaysCleared: number,
  expectedRent: number,
  leaseId: string,
  rentsCollected: number,
  repaymentStatementDetails: Array< RepaymentStatementDetailInput >,
  statementId: string,
  totalReceivedInJointAccount: number,
  totalExpectedInOwnerAccount: number,
  unpaidRents: number,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type RepaymentStatementDetailInput = {
  class: number,
  invoiceId: string,
  invoicePostingId: string,
  periodFrom: string,
  periodTo: string,
  totalAmount: number,
  transactionId?: string | null,
  type: RepaymentStatementDetailType,
};

export type ModelRepaymentStatementConditionInput = {
  delaysCleared?: ModelFloatInput | null,
  expectedRent?: ModelFloatInput | null,
  leaseId?: ModelIDInput | null,
  rentsCollected?: ModelFloatInput | null,
  statementId?: ModelIDInput | null,
  totalReceivedInJointAccount?: ModelFloatInput | null,
  totalExpectedInOwnerAccount?: ModelFloatInput | null,
  unpaidRents?: ModelFloatInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelRepaymentStatementConditionInput | null > | null,
  or?: Array< ModelRepaymentStatementConditionInput | null > | null,
  not?: ModelRepaymentStatementConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateRepaymentStatementInput = {
  id: string,
  delaysCleared?: number | null,
  expectedRent?: number | null,
  leaseId?: string | null,
  rentsCollected?: number | null,
  repaymentStatementDetails?: Array< RepaymentStatementDetailInput > | null,
  statementId?: string | null,
  totalReceivedInJointAccount?: number | null,
  totalExpectedInOwnerAccount?: number | null,
  unpaidRents?: number | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteRepaymentStatementInput = {
  id: string,
  _version?: number | null,
};

export type CreateSendingSourceInput = {
  id?: string | null,
  createdAt?: string | null,
  createdById?: string | null,
  dnsRecords?: Array< DNSRecordInput > | null,
  emailAddress: string,
  issuesEmail: string,
  chargesEmail: string,
  errorMessage?: SendingSourceErrorType | null,
  name: string,
  lastReminderDate?: string | null,
  status: SendingSourceStatus,
  statusLastVerification?: string | null,
  verifiedType: SendingSourceVerifiedType,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type DNSRecordInput = {
  name: string,
  type: string,
  value: string,
};

export enum SendingSourceErrorType {
  ALREADY_EXISTS = "ALREADY_EXISTS",
  CRASH = "CRASH",
  TIME = "TIME",
  SES = "SES",
  OTHER = "OTHER",
}


export enum SendingSourceStatus {
  ERROR = "ERROR",
  NONE = "NONE",
  PENDING = "PENDING",
  VERIFIED = "VERIFIED",
}


export enum SendingSourceVerifiedType {
  DEFAULT = "DEFAULT",
  NONE = "NONE",
}


export type ModelSendingSourceConditionInput = {
  createdAt?: ModelStringInput | null,
  createdById?: ModelIDInput | null,
  emailAddress?: ModelStringInput | null,
  issuesEmail?: ModelStringInput | null,
  chargesEmail?: ModelStringInput | null,
  errorMessage?: ModelSendingSourceErrorTypeInput | null,
  name?: ModelStringInput | null,
  lastReminderDate?: ModelStringInput | null,
  status?: ModelSendingSourceStatusInput | null,
  statusLastVerification?: ModelStringInput | null,
  verifiedType?: ModelSendingSourceVerifiedTypeInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelSendingSourceConditionInput | null > | null,
  or?: Array< ModelSendingSourceConditionInput | null > | null,
  not?: ModelSendingSourceConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSendingSourceErrorTypeInput = {
  eq?: SendingSourceErrorType | null,
  ne?: SendingSourceErrorType | null,
};

export type ModelSendingSourceStatusInput = {
  eq?: SendingSourceStatus | null,
  ne?: SendingSourceStatus | null,
};

export type ModelSendingSourceVerifiedTypeInput = {
  eq?: SendingSourceVerifiedType | null,
  ne?: SendingSourceVerifiedType | null,
};

export type SendingSource = {
  __typename: "SendingSource",
  id: string,
  createdAt?: string | null,
  createdById?: string | null,
  createdBy?: Contact | null,
  dnsRecords?:  Array<DNSRecord > | null,
  emailAddress: string,
  issuesEmail: string,
  chargesEmail: string,
  errorMessage?: SendingSourceErrorType | null,
  name: string,
  lastReminderDate?: string | null,
  status: SendingSourceStatus,
  statusLastVerification?: string | null,
  verifiedType: SendingSourceVerifiedType,
  readId: string,
  clientId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type DNSRecord = {
  __typename: "DNSRecord",
  name: string,
  type: string,
  value: string,
};

export type UpdateSendingSourceInput = {
  id: string,
  createdAt?: string | null,
  createdById?: string | null,
  dnsRecords?: Array< DNSRecordInput > | null,
  emailAddress?: string | null,
  issuesEmail?: string | null,
  chargesEmail?: string | null,
  errorMessage?: SendingSourceErrorType | null,
  name?: string | null,
  lastReminderDate?: string | null,
  status?: SendingSourceStatus | null,
  statusLastVerification?: string | null,
  verifiedType?: SendingSourceVerifiedType | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteSendingSourceInput = {
  id: string,
  _version?: number | null,
};

export type CreateSettingInput = {
  id?: string | null,
  name: SettingType,
  value: string,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export enum SettingType {
  CHIMNEY_REMINDER = "CHIMNEY_REMINDER",
  DETECTOR_REMINDER = "DETECTOR_REMINDER",
  EPB_REMINDER = "EPB_REMINDER",
  HEATING_REMINDER = "HEATING_REMINDER",
  TANK_REMINDER = "TANK_REMINDER",
  AUTOMATIC_INDEXATION = "AUTOMATIC_INDEXATION",
  CHARGE_ADJUSTMENT_PERCENTAGE_VARIATION = "CHARGE_ADJUSTMENT_PERCENTAGE_VARIATION",
  COMMUNICATION_TIME_FRAME = "COMMUNICATION_TIME_FRAME",
  VAT_AGENCY_RATE = "VAT_AGENCY_RATE",
}


export type ModelSettingConditionInput = {
  name?: ModelSettingTypeInput | null,
  value?: ModelStringInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelSettingConditionInput | null > | null,
  or?: Array< ModelSettingConditionInput | null > | null,
  not?: ModelSettingConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSettingTypeInput = {
  eq?: SettingType | null,
  ne?: SettingType | null,
};

export type Setting = {
  __typename: "Setting",
  id: string,
  name: SettingType,
  value: string,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateSettingInput = {
  id: string,
  name?: SettingType | null,
  value?: string | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteSettingInput = {
  id: string,
  _version?: number | null,
};

export type CreateSignatureDocumentInput = {
  id?: string | null,
  additionalMessage?: string | null,
  communicationBody?: string | null,
  createdAt?: string | null,
  expirationDate?: string | null,
  fileIds?: Array< string > | null,
  foreignKey: string,
  foreignTableName: string,
  sentAt?: string | null,
  signatureMethods: Array< SignatureType >,
  signatureResults: Array< SignatureDocumentResultInput >,
  status: SignatureDocumentStatus,
  externalStatus?: string | null,
  type: SignatureDocumentType,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type SignatureDocumentResultInput = {
  openedDate?: string | null,
  signedDate?: string | null,
  signed?: boolean | null,
  signatureLink?: string | null,
  rejectReason?: string | null,
  lastSignInviteSentDate?: string | null,
  trackingProvider?: TrackingProvider | null,
  trackingCode?: string | null,
  contactId: string,
};

export type ModelSignatureDocumentConditionInput = {
  additionalMessage?: ModelStringInput | null,
  communicationBody?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  expirationDate?: ModelStringInput | null,
  fileIds?: ModelIDInput | null,
  foreignKey?: ModelIDInput | null,
  foreignTableName?: ModelStringInput | null,
  sentAt?: ModelStringInput | null,
  signatureMethods?: ModelSignatureTypeInput | null,
  status?: ModelSignatureDocumentStatusInput | null,
  externalStatus?: ModelStringInput | null,
  type?: ModelSignatureDocumentTypeInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelSignatureDocumentConditionInput | null > | null,
  or?: Array< ModelSignatureDocumentConditionInput | null > | null,
  not?: ModelSignatureDocumentConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSignatureTypeInput = {
  eq?: SignatureType | null,
  ne?: SignatureType | null,
};

export type ModelSignatureDocumentStatusInput = {
  eq?: SignatureDocumentStatus | null,
  ne?: SignatureDocumentStatus | null,
};

export type ModelSignatureDocumentTypeInput = {
  eq?: SignatureDocumentType | null,
  ne?: SignatureDocumentType | null,
};

export type UpdateSignatureDocumentInput = {
  id: string,
  additionalMessage?: string | null,
  communicationBody?: string | null,
  createdAt?: string | null,
  expirationDate?: string | null,
  fileIds?: Array< string > | null,
  foreignKey?: string | null,
  foreignTableName?: string | null,
  sentAt?: string | null,
  signatureMethods?: Array< SignatureType > | null,
  signatureResults?: Array< SignatureDocumentResultInput > | null,
  status?: SignatureDocumentStatus | null,
  externalStatus?: string | null,
  type?: SignatureDocumentType | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteSignatureDocumentInput = {
  id: string,
  _version?: number | null,
};

export type CreateStatementInput = {
  id?: string | null,
  amounts?: Array< CustomValueInput > | null,
  closingStatement: boolean,
  periodFrom: string,
  periodTo: string,
  source: StatementSource,
  status: StatementStatus,
  sentDate?: string | null,
  leaseId?: string | null,
  ownerId?: string | null,
  types?: Array< StatementType > | null,
  unitIds?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelStatementConditionInput = {
  closingStatement?: ModelBooleanInput | null,
  periodFrom?: ModelStringInput | null,
  periodTo?: ModelStringInput | null,
  source?: ModelStatementSourceInput | null,
  status?: ModelStatementStatusInput | null,
  sentDate?: ModelStringInput | null,
  leaseId?: ModelIDInput | null,
  ownerId?: ModelIDInput | null,
  types?: ModelStatementTypeListInput | null,
  unitIds?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelStatementConditionInput | null > | null,
  or?: Array< ModelStatementConditionInput | null > | null,
  not?: ModelStatementConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStatementSourceInput = {
  eq?: StatementSource | null,
  ne?: StatementSource | null,
};

export type ModelStatementStatusInput = {
  eq?: StatementStatus | null,
  ne?: StatementStatus | null,
};

export type ModelStatementTypeListInput = {
  eq?: Array< StatementType | null > | null,
  ne?: Array< StatementType | null > | null,
  contains?: StatementType | null,
  notContains?: StatementType | null,
};

export type UpdateStatementInput = {
  id: string,
  amounts?: Array< CustomValueInput > | null,
  closingStatement?: boolean | null,
  periodFrom?: string | null,
  periodTo?: string | null,
  source?: StatementSource | null,
  status?: StatementStatus | null,
  sentDate?: string | null,
  leaseId?: string | null,
  ownerId?: string | null,
  types?: Array< StatementType > | null,
  unitIds?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteStatementInput = {
  id: string,
  _version?: number | null,
};

export type CreateStatementSettingInput = {
  id?: string | null,
  type: StatementSettingType,
  custom: boolean,
  disabled?: boolean | null,
  monthFrequency: number,
  startMonth?: string | null,
  leaseId?: string | null,
  ownerId?: string | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export enum StatementSettingType {
  OWNER = "OWNER",
  LEASE = "LEASE",
}


export type ModelStatementSettingConditionInput = {
  type?: ModelStatementSettingTypeInput | null,
  custom?: ModelBooleanInput | null,
  disabled?: ModelBooleanInput | null,
  monthFrequency?: ModelIntInput | null,
  startMonth?: ModelStringInput | null,
  leaseId?: ModelIDInput | null,
  ownerId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelStatementSettingConditionInput | null > | null,
  or?: Array< ModelStatementSettingConditionInput | null > | null,
  not?: ModelStatementSettingConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStatementSettingTypeInput = {
  eq?: StatementSettingType | null,
  ne?: StatementSettingType | null,
};

export type StatementSetting = {
  __typename: "StatementSetting",
  id: string,
  type: StatementSettingType,
  custom: boolean,
  disabled?: boolean | null,
  monthFrequency: number,
  startMonth?: string | null,
  leaseId?: string | null,
  lease?: Lease | null,
  ownerId?: string | null,
  owner?: Contact | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateStatementSettingInput = {
  id: string,
  type?: StatementSettingType | null,
  custom?: boolean | null,
  disabled?: boolean | null,
  monthFrequency?: number | null,
  startMonth?: string | null,
  leaseId?: string | null,
  ownerId?: string | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteStatementSettingInput = {
  id: string,
  _version?: number | null,
};

export type CreateTeamInput = {
  id?: string | null,
  name: string,
  parentId?: string | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelTeamConditionInput = {
  name?: ModelStringInput | null,
  parentId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelTeamConditionInput | null > | null,
  or?: Array< ModelTeamConditionInput | null > | null,
  not?: ModelTeamConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Team = {
  __typename: "Team",
  id: string,
  name: string,
  parentId?: string | null,
  children?: ModelTeamConnection | null,
  users?: ModelUserTeamConnection | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelTeamConnection = {
  __typename: "ModelTeamConnection",
  items:  Array<Team | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserTeamConnection = {
  __typename: "ModelUserTeamConnection",
  items:  Array<UserTeam | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UserTeam = {
  __typename: "UserTeam",
  id: string,
  userId: string,
  teamId: string,
  team?: Team | null,
  user?: User | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type User = {
  __typename: "User",
  id: string,
  email: string,
  firstName?: string | null,
  lastName?: string | null,
  cognitoSub?: string | null,
  googleSub?: string | null,
  facebookSub?: string | null,
  rootUser?: boolean | null,
  refreshToken?: boolean | null,
  teams?: ModelUserTeamConnection | null,
  userRoleId?: string | null,
  userRole?: UserRole | null,
  contactId?: string | null,
  contact?: Contact | null,
  status: UserStatus,
  readId: string,
  clientId: string,
  _ttl?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UserRole = {
  __typename: "UserRole",
  id: string,
  name: string,
  userRights?:  Array<UserRight > | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UserRight = {
  __typename: "UserRight",
  access?: Array< UserRightAccessType > | null,
  section: UserRightSection,
};

export enum UserRightAccessType {
  READ = "READ",
  WRITE = "WRITE",
  DELETE = "DELETE",
}


export enum UserRightSection {
  NOTIFICATIONS_DETAILS = "NOTIFICATIONS_DETAILS",
  COMMUNICATIONS_DETAILS = "COMMUNICATIONS_DETAILS",
  CONTACTS_DETAILS = "CONTACTS_DETAILS",
  TICKETS_DETAILS = "TICKETS_DETAILS",
  ADVERTISEMENTS_DETAILS = "ADVERTISEMENTS_DETAILS",
  TEMPLATES_CONTRACTS = "TEMPLATES_CONTRACTS",
  TEMPLATES_COMMUNICATIONS = "TEMPLATES_COMMUNICATIONS",
  FINANCIAL_TRANSACTIONS = "FINANCIAL_TRANSACTIONS",
  FINANCIAL_OCR = "FINANCIAL_OCR",
  FINANCIAL_MORTGAGES = "FINANCIAL_MORTGAGES",
  FINANCIAL_VALUATIONS_AND_COSTS = "FINANCIAL_VALUATIONS_AND_COSTS",
  LEASES_CREATION = "LEASES_CREATION",
  LEASES_SIGNATURE = "LEASES_SIGNATURE",
  LEASES_DETAILS = "LEASES_DETAILS",
  INVENTORY_OF_FIXTURES_CREATION = "INVENTORY_OF_FIXTURES_CREATION",
  FURNITURES_INVENTORY_CREATION = "FURNITURES_INVENTORY_CREATION",
  BUILDINGS_UNITS_DETAILS = "BUILDINGS_UNITS_DETAILS",
  BUILDINGS_UNITS_UTILITY_PROVIDERS = "BUILDINGS_UNITS_UTILITY_PROVIDERS",
  BUILDINGS_UNITS_TECHNICS = "BUILDINGS_UNITS_TECHNICS",
  AGENCY_RATE = "AGENCY_RATE",
  SETTINGS_AUTOMATION = "SETTINGS_AUTOMATION",
  SETTINGS_MANAGEMENT = "SETTINGS_MANAGEMENT",
}


export enum UserStatus {
  DISABLED = "DISABLED",
  JOINED = "JOINED",
  NOT_INVITED = "NOT_INVITED",
  PENDING = "PENDING",
}


export type UpdateTeamInput = {
  id: string,
  name?: string | null,
  parentId?: string | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteTeamInput = {
  id: string,
  _version?: number | null,
};

export type CreateTechnicInput = {
  id?: string | null,
  type: TechnicType,
  contractNumber?: string | null,
  communicationSettingsProfileId: string,
  maintenanceHistory?: Array< TechnicMaintenanceHistoryInput > | null,
  utilityType?: UtilityType | null,
  utilityEanCode?: string | null,
  utilityMeterNumber?: string | null,
  utilityDistributorId?: string | null,
  utilityHistory?: Array< UtilityHistoryInput > | null,
  PebValidityDate?: string | null,
  PebEnergeticPerformance?: string | null,
  PebReportNumber?: string | null,
  PebIssueDate?: string | null,
  detectorType?: DetectorType | null,
  detectorSerialNumber?: string | null,
  detectorBrand?: string | null,
  detectorLastControlDate?: string | null,
  detectorUnitInventoryId?: string | null,
  heatingBrand?: string | null,
  heatingModel?: string | null,
  heatingInstallationDate?: string | null,
  heatingType?: HeatingType | null,
  heatingLastControlDate?: string | null,
  tankType?: TankType | null,
  tankCapacity?: number | null,
  tankState?: TankState | null,
  tankLastControlDate?: string | null,
  tankLeakDetection?: boolean | null,
  tankEncuvement?: boolean | null,
  tankWallType?: TankWallType | null,
  tankInstallationDate?: string | null,
  chimneyLastControlDate?: string | null,
  chimneyUnitInventoryId?: string | null,
  buildingId?: string | null,
  unitId?: string | null,
  leaseId?: string | null,
  contactId?: string | null,
  customValues?: Array< CustomValueInput > | null,
  originalTechnicId?: string | null,
  readers?: Array< string > | null,
  writers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type TechnicMaintenanceHistoryInput = {
  date: string,
  contactId?: string | null,
  fileId?: string | null,
};

export type UtilityHistoryInput = {
  date: string,
  reason?: UtilityReadingReason | null,
  reading: number,
  secondReading?: number | null,
};

export type ModelTechnicConditionInput = {
  type?: ModelTechnicTypeInput | null,
  contractNumber?: ModelStringInput | null,
  communicationSettingsProfileId?: ModelIDInput | null,
  utilityType?: ModelUtilityTypeInput | null,
  utilityEanCode?: ModelStringInput | null,
  utilityMeterNumber?: ModelStringInput | null,
  utilityDistributorId?: ModelIDInput | null,
  PebValidityDate?: ModelStringInput | null,
  PebEnergeticPerformance?: ModelStringInput | null,
  PebReportNumber?: ModelStringInput | null,
  PebIssueDate?: ModelStringInput | null,
  detectorType?: ModelDetectorTypeInput | null,
  detectorSerialNumber?: ModelStringInput | null,
  detectorBrand?: ModelStringInput | null,
  detectorLastControlDate?: ModelStringInput | null,
  detectorUnitInventoryId?: ModelStringInput | null,
  heatingBrand?: ModelStringInput | null,
  heatingModel?: ModelStringInput | null,
  heatingInstallationDate?: ModelStringInput | null,
  heatingType?: ModelHeatingTypeInput | null,
  heatingLastControlDate?: ModelStringInput | null,
  tankType?: ModelTankTypeInput | null,
  tankCapacity?: ModelIntInput | null,
  tankState?: ModelTankStateInput | null,
  tankLastControlDate?: ModelStringInput | null,
  tankLeakDetection?: ModelBooleanInput | null,
  tankEncuvement?: ModelBooleanInput | null,
  tankWallType?: ModelTankWallTypeInput | null,
  tankInstallationDate?: ModelStringInput | null,
  chimneyLastControlDate?: ModelStringInput | null,
  chimneyUnitInventoryId?: ModelStringInput | null,
  buildingId?: ModelIDInput | null,
  unitId?: ModelIDInput | null,
  leaseId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  originalTechnicId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  writers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelTechnicConditionInput | null > | null,
  or?: Array< ModelTechnicConditionInput | null > | null,
  not?: ModelTechnicConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTechnicTypeInput = {
  eq?: TechnicType | null,
  ne?: TechnicType | null,
};

export type ModelUtilityTypeInput = {
  eq?: UtilityType | null,
  ne?: UtilityType | null,
};

export type ModelDetectorTypeInput = {
  eq?: DetectorType | null,
  ne?: DetectorType | null,
};

export type ModelTankTypeInput = {
  eq?: TankType | null,
  ne?: TankType | null,
};

export type ModelTankStateInput = {
  eq?: TankState | null,
  ne?: TankState | null,
};

export type ModelTankWallTypeInput = {
  eq?: TankWallType | null,
  ne?: TankWallType | null,
};

export type UpdateTechnicInput = {
  id: string,
  type?: TechnicType | null,
  contractNumber?: string | null,
  communicationSettingsProfileId?: string | null,
  maintenanceHistory?: Array< TechnicMaintenanceHistoryInput > | null,
  utilityType?: UtilityType | null,
  utilityEanCode?: string | null,
  utilityMeterNumber?: string | null,
  utilityDistributorId?: string | null,
  utilityHistory?: Array< UtilityHistoryInput > | null,
  PebValidityDate?: string | null,
  PebEnergeticPerformance?: string | null,
  PebReportNumber?: string | null,
  PebIssueDate?: string | null,
  detectorType?: DetectorType | null,
  detectorSerialNumber?: string | null,
  detectorBrand?: string | null,
  detectorLastControlDate?: string | null,
  detectorUnitInventoryId?: string | null,
  heatingBrand?: string | null,
  heatingModel?: string | null,
  heatingInstallationDate?: string | null,
  heatingType?: HeatingType | null,
  heatingLastControlDate?: string | null,
  tankType?: TankType | null,
  tankCapacity?: number | null,
  tankState?: TankState | null,
  tankLastControlDate?: string | null,
  tankLeakDetection?: boolean | null,
  tankEncuvement?: boolean | null,
  tankWallType?: TankWallType | null,
  tankInstallationDate?: string | null,
  chimneyLastControlDate?: string | null,
  chimneyUnitInventoryId?: string | null,
  buildingId?: string | null,
  unitId?: string | null,
  leaseId?: string | null,
  contactId?: string | null,
  customValues?: Array< CustomValueInput > | null,
  originalTechnicId?: string | null,
  readers?: Array< string > | null,
  writers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteTechnicInput = {
  id: string,
  _version?: number | null,
};

export type CreateTemplateInput = {
  id?: string | null,
  language: string,
  subject?: string | null,
  subjectRawFormat?: string | null,
  body: string,
  bodyRawFormat?: string | null,
  type: TemplateType,
  subType: TemplateSubType,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export enum TemplateType {
  MAIL = "MAIL",
  CONTRACT = "CONTRACT",
  CONTRACT_DOCUMENT = "CONTRACT_DOCUMENT",
  SMS = "SMS",
  WHATSAPP = "WHATSAPP",
}


export enum TemplateSubType {
  ACCOUNTING_EXPORT = "ACCOUNTING_EXPORT",
  FIXTURES_ENTRANCE = "FIXTURES_ENTRANCE",
  FIXTURES_EXIT = "FIXTURES_EXIT",
  FOOTER = "FOOTER",
  FURNITURES_ENTRANCE = "FURNITURES_ENTRANCE",
  FURNITURES_EXIT = "FURNITURES_EXIT",
  HEADER = "HEADER",
  MAIN_RESIDENCE_9_YEARS = "MAIN_RESIDENCE_9_YEARS",
  REPORT = "REPORT",
  DOCUMENT_SIGNATURE_REMINDER = "DOCUMENT_SIGNATURE_REMINDER",
  DOCUMENT_SIGNED = "DOCUMENT_SIGNED",
  LEASE_ALLOW_PROFESSIONAL_ACTIVITY_ON = "LEASE_ALLOW_PROFESSIONAL_ACTIVITY_ON",
  LEASE_BANKING_INFO_UPDATE = "LEASE_BANKING_INFO_UPDATE",
  LEASE_CHARGES_ADJUSTMENT = "LEASE_CHARGES_ADJUSTMENT",
  LEASE_CONTRACT_EXTENDED = "LEASE_CONTRACT_EXTENDED",
  LEASE_CONTRACT_REJECTED = "LEASE_CONTRACT_REJECTED",
  LEASE_CONTRACT_RETRACTED = "LEASE_CONTRACT_RETRACTED",
  LEASE_CONTRACT_REVOKED = "LEASE_CONTRACT_REVOKED",
  LEASE_CONTRACT_SIGNATURE_REMINDER = "LEASE_CONTRACT_SIGNATURE_REMINDER",
  LEASE_CONTRACT_SIGNED = "LEASE_CONTRACT_SIGNED",
  LEASE_CREDIT_NOTE = "LEASE_CREDIT_NOTE",
  LEASE_DISCOUNT_CREATED = "LEASE_DISCOUNT_CREATED",
  LEASE_DISCOUNT_DELETED = "LEASE_DISCOUNT_DELETED",
  LEASE_DISCOUNT_UPDATED = "LEASE_DISCOUNT_UPDATED",
  LEASE_OPERATION_CREATED = "LEASE_OPERATION_CREATED",
  LEASE_OPERATION_DELETED = "LEASE_OPERATION_DELETED",
  LEASE_OPERATION_PAYMENT_REQUEST = "LEASE_OPERATION_PAYMENT_REQUEST",
  LEASE_OPERATION_UPDATED = "LEASE_OPERATION_UPDATED",
  LEASE_PAYMENT_RECEIPT = "LEASE_PAYMENT_RECEIPT",
  LEASE_PAYMENT_REQUEST = "LEASE_PAYMENT_REQUEST",
  LEASE_RENT_INDEXATION = "LEASE_RENT_INDEXATION",
  LEASE_RENT_INDEXATION_SKIPPED = "LEASE_RENT_INDEXATION_SKIPPED",
  LEASE_RENTAL_ADAPTATION = "LEASE_RENTAL_ADAPTATION",
  LEASE_TENANT_STATEMENT = "LEASE_TENANT_STATEMENT",
  LEASE_TERMINATION = "LEASE_TERMINATION",
  LEASE_UNPAID_AMOUNT_FORMAL_NOTICE = "LEASE_UNPAID_AMOUNT_FORMAL_NOTICE",
  LEASE_UNPAID_AMOUNT_REMINDER = "LEASE_UNPAID_AMOUNT_REMINDER",
  LEASE_UNPAID_AMOUNT_SECOND_REMINDER = "LEASE_UNPAID_AMOUNT_SECOND_REMINDER",
  LEASE_UNPAID_CONSOLIDATED_PAYMENT_REMINDER = "LEASE_UNPAID_CONSOLIDATED_PAYMENT_REMINDER",
  OWNER_STATEMENT = "OWNER_STATEMENT",
}


export type ModelTemplateConditionInput = {
  language?: ModelStringInput | null,
  subject?: ModelStringInput | null,
  subjectRawFormat?: ModelStringInput | null,
  body?: ModelStringInput | null,
  bodyRawFormat?: ModelStringInput | null,
  type?: ModelTemplateTypeInput | null,
  subType?: ModelTemplateSubTypeInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelTemplateConditionInput | null > | null,
  or?: Array< ModelTemplateConditionInput | null > | null,
  not?: ModelTemplateConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTemplateTypeInput = {
  eq?: TemplateType | null,
  ne?: TemplateType | null,
};

export type ModelTemplateSubTypeInput = {
  eq?: TemplateSubType | null,
  ne?: TemplateSubType | null,
};

export type Template = {
  __typename: "Template",
  id: string,
  language: string,
  subject?: string | null,
  subjectRawFormat?: string | null,
  body: string,
  bodyRawFormat?: string | null,
  type: TemplateType,
  subType: TemplateSubType,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateTemplateInput = {
  id: string,
  language?: string | null,
  subject?: string | null,
  subjectRawFormat?: string | null,
  body?: string | null,
  bodyRawFormat?: string | null,
  type?: TemplateType | null,
  subType?: TemplateSubType | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteTemplateInput = {
  id: string,
  _version?: number | null,
};

export type CreateTicketInput = {
  id?: string | null,
  assignedAt?: string | null,
  assignedToId?: string | null,
  buildingId?: string | null,
  closedAt?: string | null,
  contactId: string,
  content: string,
  createdAt?: string | null,
  emailMessageId?: string | null,
  emailReferences?: Array< string | null > | null,
  parentId?: string | null,
  plannedAt?: string | null,
  referenceCode?: string | null,
  sourceType: TicketSourceType,
  status?: TicketStatus | null,
  subject: string,
  unitId?: string | null,
  unread: boolean,
  updatedAt?: string | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelTicketConditionInput = {
  assignedAt?: ModelStringInput | null,
  assignedToId?: ModelIDInput | null,
  buildingId?: ModelIDInput | null,
  closedAt?: ModelStringInput | null,
  contactId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  emailMessageId?: ModelStringInput | null,
  emailReferences?: ModelStringInput | null,
  parentId?: ModelIDInput | null,
  plannedAt?: ModelStringInput | null,
  referenceCode?: ModelStringInput | null,
  sourceType?: ModelTicketSourceTypeInput | null,
  status?: ModelTicketStatusInput | null,
  subject?: ModelStringInput | null,
  unitId?: ModelIDInput | null,
  unread?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelTicketConditionInput | null > | null,
  or?: Array< ModelTicketConditionInput | null > | null,
  not?: ModelTicketConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTicketSourceTypeInput = {
  eq?: TicketSourceType | null,
  ne?: TicketSourceType | null,
};

export type ModelTicketStatusInput = {
  eq?: TicketStatus | null,
  ne?: TicketStatus | null,
};

export type UpdateTicketInput = {
  id: string,
  assignedAt?: string | null,
  assignedToId?: string | null,
  buildingId?: string | null,
  closedAt?: string | null,
  contactId?: string | null,
  content?: string | null,
  createdAt?: string | null,
  emailMessageId?: string | null,
  emailReferences?: Array< string | null > | null,
  parentId?: string | null,
  plannedAt?: string | null,
  referenceCode?: string | null,
  sourceType?: TicketSourceType | null,
  status?: TicketStatus | null,
  subject?: string | null,
  unitId?: string | null,
  unread?: boolean | null,
  updatedAt?: string | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteTicketInput = {
  id: string,
  _version?: number | null,
};

export type CreateTransactionInput = {
  id?: string | null,
  status: TransactionStatus,
  sequence?: number | null,
  statementDate: string,
  operationDate?: string | null,
  createdAt?: string | null,
  remittanceInformationType?: RemittanceType | null,
  remittanceInformation?: string | null,
  comment?: string | null,
  amount: number,
  bankAccountId: string,
  counterpartName?: string | null,
  counterpartAccountNumber?: string | null,
  manualEntry?: boolean | null,
  proposedLeaseId?: string | null,
  proposedUnitId?: string | null,
  proposedContractorId?: string | null,
  proposedMatch?: number | null,
  links?: Array< TransactionLinkInput > | null,
  updatedAt?: string | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type TransactionLinkInput = {
  amount: number,
  linkId: string,
  linkType: TransactionLinkType,
};

export type ModelTransactionConditionInput = {
  status?: ModelTransactionStatusInput | null,
  sequence?: ModelFloatInput | null,
  statementDate?: ModelStringInput | null,
  operationDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  remittanceInformationType?: ModelRemittanceTypeInput | null,
  remittanceInformation?: ModelStringInput | null,
  comment?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  bankAccountId?: ModelIDInput | null,
  counterpartName?: ModelStringInput | null,
  counterpartAccountNumber?: ModelStringInput | null,
  manualEntry?: ModelBooleanInput | null,
  proposedLeaseId?: ModelIDInput | null,
  proposedUnitId?: ModelIDInput | null,
  proposedContractorId?: ModelIDInput | null,
  proposedMatch?: ModelIntInput | null,
  updatedAt?: ModelStringInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelTransactionConditionInput | null > | null,
  or?: Array< ModelTransactionConditionInput | null > | null,
  not?: ModelTransactionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTransactionStatusInput = {
  eq?: TransactionStatus | null,
  ne?: TransactionStatus | null,
};

export type ModelRemittanceTypeInput = {
  eq?: RemittanceType | null,
  ne?: RemittanceType | null,
};

export type UpdateTransactionInput = {
  id: string,
  status?: TransactionStatus | null,
  sequence?: number | null,
  statementDate?: string | null,
  operationDate?: string | null,
  createdAt?: string | null,
  remittanceInformationType?: RemittanceType | null,
  remittanceInformation?: string | null,
  comment?: string | null,
  amount?: number | null,
  bankAccountId?: string | null,
  counterpartName?: string | null,
  counterpartAccountNumber?: string | null,
  manualEntry?: boolean | null,
  proposedLeaseId?: string | null,
  proposedUnitId?: string | null,
  proposedContractorId?: string | null,
  proposedMatch?: number | null,
  links?: Array< TransactionLinkInput > | null,
  updatedAt?: string | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteTransactionInput = {
  id: string,
  _version?: number | null,
};

export type CreateTransactionRuleInput = {
  id?: string | null,
  remittanceInformation?: string | null,
  counterpartName?: string | null,
  counterpartAccountNumber?: string | null,
  usedFields: string,
  exclude: boolean,
  leaseId?: string | null,
  unitId?: string | null,
  contractorId?: string | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelTransactionRuleConditionInput = {
  remittanceInformation?: ModelStringInput | null,
  counterpartName?: ModelStringInput | null,
  counterpartAccountNumber?: ModelStringInput | null,
  usedFields?: ModelStringInput | null,
  exclude?: ModelBooleanInput | null,
  leaseId?: ModelIDInput | null,
  unitId?: ModelIDInput | null,
  contractorId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelTransactionRuleConditionInput | null > | null,
  or?: Array< ModelTransactionRuleConditionInput | null > | null,
  not?: ModelTransactionRuleConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type TransactionRule = {
  __typename: "TransactionRule",
  id: string,
  remittanceInformation?: string | null,
  counterpartName?: string | null,
  counterpartAccountNumber?: string | null,
  usedFields: string,
  exclude: boolean,
  leaseId?: string | null,
  lease?: Lease | null,
  unitId?: string | null,
  unit?: Unit | null,
  contractorId?: string | null,
  contractor?: Contact | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateTransactionRuleInput = {
  id: string,
  remittanceInformation?: string | null,
  counterpartName?: string | null,
  counterpartAccountNumber?: string | null,
  usedFields?: string | null,
  exclude?: boolean | null,
  leaseId?: string | null,
  unitId?: string | null,
  contractorId?: string | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteTransactionRuleInput = {
  id: string,
  _version?: number | null,
};

export type CreateUnitInput = {
  id?: string | null,
  acquisitionAdditionalCostsValue?: number | null,
  acquisitionAdvertisedValue?: number | null,
  acquisitionValue?: number | null,
  advertisedMonthlyCharges?: number | null,
  advertisedRentalPrice?: number | null,
  cadastralIncome?: number | null,
  createdAt?: string | null,
  customValues?: Array< CustomValueInput > | null,
  floor?: number | null,
  jointOwnershipId?: string | null,
  managementDate: string,
  name: string,
  predictedPrice?: string | null,
  propertyTax?: number | null,
  publishAddress?: boolean | null,
  surface?: number | null,
  type: UnitType,
  buildingId: string,
  unitAdvertisementId?: string | null,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelUnitConditionInput = {
  acquisitionAdditionalCostsValue?: ModelFloatInput | null,
  acquisitionAdvertisedValue?: ModelFloatInput | null,
  acquisitionValue?: ModelFloatInput | null,
  advertisedMonthlyCharges?: ModelFloatInput | null,
  advertisedRentalPrice?: ModelFloatInput | null,
  cadastralIncome?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  floor?: ModelIntInput | null,
  jointOwnershipId?: ModelIDInput | null,
  managementDate?: ModelStringInput | null,
  name?: ModelStringInput | null,
  predictedPrice?: ModelStringInput | null,
  propertyTax?: ModelFloatInput | null,
  publishAddress?: ModelBooleanInput | null,
  surface?: ModelFloatInput | null,
  type?: ModelUnitTypeInput | null,
  buildingId?: ModelIDInput | null,
  unitAdvertisementId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUnitConditionInput | null > | null,
  or?: Array< ModelUnitConditionInput | null > | null,
  not?: ModelUnitConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateUnitInput = {
  id: string,
  acquisitionAdditionalCostsValue?: number | null,
  acquisitionAdvertisedValue?: number | null,
  acquisitionValue?: number | null,
  advertisedMonthlyCharges?: number | null,
  advertisedRentalPrice?: number | null,
  cadastralIncome?: number | null,
  createdAt?: string | null,
  customValues?: Array< CustomValueInput > | null,
  floor?: number | null,
  jointOwnershipId?: string | null,
  managementDate?: string | null,
  name?: string | null,
  predictedPrice?: string | null,
  propertyTax?: number | null,
  publishAddress?: boolean | null,
  surface?: number | null,
  type?: UnitType | null,
  buildingId?: string | null,
  unitAdvertisementId?: string | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteUnitInput = {
  id: string,
  _version?: number | null,
};

export type CreateUnitEventInput = {
  id?: string | null,
  unitId: string,
  type: UnitEventType,
  startDate: string,
  endDate: string,
  totalCost?: number | null,
  note?: string | null,
  writers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelUnitEventConditionInput = {
  unitId?: ModelIDInput | null,
  type?: ModelUnitEventTypeInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  totalCost?: ModelFloatInput | null,
  note?: ModelStringInput | null,
  writers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUnitEventConditionInput | null > | null,
  or?: Array< ModelUnitEventConditionInput | null > | null,
  not?: ModelUnitEventConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUnitEventTypeInput = {
  eq?: UnitEventType | null,
  ne?: UnitEventType | null,
};

export type UpdateUnitEventInput = {
  id: string,
  unitId?: string | null,
  type?: UnitEventType | null,
  startDate?: string | null,
  endDate?: string | null,
  totalCost?: number | null,
  note?: string | null,
  writers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteUnitEventInput = {
  id: string,
  _version?: number | null,
};

export type CreateUnitInventoryInput = {
  id?: string | null,
  privateBatthRoom?: boolean | null,
  createdAt?: string | null,
  deletedAt?: string | null,
  kitchenEquipment?: KitchenEquipment | null,
  roomName?: string | null,
  roomType: UnitInventoryRoomType,
  surface?: number | null,
  showSurface?: boolean | null,
  unitId: string,
  inside: boolean,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelUnitInventoryConditionInput = {
  privateBatthRoom?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  kitchenEquipment?: ModelKitchenEquipmentInput | null,
  roomName?: ModelStringInput | null,
  roomType?: ModelUnitInventoryRoomTypeInput | null,
  surface?: ModelFloatInput | null,
  showSurface?: ModelBooleanInput | null,
  unitId?: ModelIDInput | null,
  inside?: ModelBooleanInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUnitInventoryConditionInput | null > | null,
  or?: Array< ModelUnitInventoryConditionInput | null > | null,
  not?: ModelUnitInventoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelKitchenEquipmentInput = {
  eq?: KitchenEquipment | null,
  ne?: KitchenEquipment | null,
};

export type ModelUnitInventoryRoomTypeInput = {
  eq?: UnitInventoryRoomType | null,
  ne?: UnitInventoryRoomType | null,
};

export type UpdateUnitInventoryInput = {
  id: string,
  privateBatthRoom?: boolean | null,
  createdAt?: string | null,
  deletedAt?: string | null,
  kitchenEquipment?: KitchenEquipment | null,
  roomName?: string | null,
  roomType?: UnitInventoryRoomType | null,
  surface?: number | null,
  showSurface?: boolean | null,
  unitId?: string | null,
  inside?: boolean | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteUnitInventoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateUnitInventoryStructureInput = {
  id?: string | null,
  createdAt?: string | null,
  deletedAt?: string | null,
  unitInventoryId: string,
  type: UnitInventoryStructureType,
  name: string,
  brand?: string | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelUnitInventoryStructureConditionInput = {
  createdAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  unitInventoryId?: ModelIDInput | null,
  type?: ModelUnitInventoryStructureTypeInput | null,
  name?: ModelStringInput | null,
  brand?: ModelStringInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUnitInventoryStructureConditionInput | null > | null,
  or?: Array< ModelUnitInventoryStructureConditionInput | null > | null,
  not?: ModelUnitInventoryStructureConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUnitInventoryStructureTypeInput = {
  eq?: UnitInventoryStructureType | null,
  ne?: UnitInventoryStructureType | null,
};

export type UpdateUnitInventoryStructureInput = {
  id: string,
  createdAt?: string | null,
  deletedAt?: string | null,
  unitInventoryId?: string | null,
  type?: UnitInventoryStructureType | null,
  name?: string | null,
  brand?: string | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteUnitInventoryStructureInput = {
  id: string,
  _version?: number | null,
};

export type CreateUnitLeaseInput = {
  id?: string | null,
  allowChargeAdjustment?: boolean | null,
  authorizeProfessionalActivity?: boolean | null,
  chargesIndexation?: boolean | null,
  createdAt?: string | null,
  endDate: string,
  furnishedRental?: boolean | null,
  furnishedRentalInsuranceByTenant?: boolean | null,
  furnishedRentalRentalPricePercentage?: number | null,
  initialMonthlyCharges: number,
  initialRentalPrice: number,
  indexation?: boolean | null,
  advancedIndexation?: boolean | null,
  indexType?: IndexType | null,
  baseYear?: number | null,
  baseIndexType?: BaseIndexType | null,
  baseIndexDate?: string | null,
  leaseId: string,
  mainUnit: boolean,
  monthlyChargesType?: LeaseMonthlyChargesType | null,
  monthlyCharges: number,
  rentalPrice: number,
  signatureDate?: string | null,
  startDate: string,
  unitId: string,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelUnitLeaseConditionInput = {
  allowChargeAdjustment?: ModelBooleanInput | null,
  authorizeProfessionalActivity?: ModelBooleanInput | null,
  chargesIndexation?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  furnishedRental?: ModelBooleanInput | null,
  furnishedRentalInsuranceByTenant?: ModelBooleanInput | null,
  furnishedRentalRentalPricePercentage?: ModelFloatInput | null,
  initialMonthlyCharges?: ModelFloatInput | null,
  initialRentalPrice?: ModelFloatInput | null,
  indexation?: ModelBooleanInput | null,
  advancedIndexation?: ModelBooleanInput | null,
  indexType?: ModelIndexTypeInput | null,
  baseYear?: ModelFloatInput | null,
  baseIndexType?: ModelBaseIndexTypeInput | null,
  baseIndexDate?: ModelStringInput | null,
  leaseId?: ModelIDInput | null,
  mainUnit?: ModelBooleanInput | null,
  monthlyChargesType?: ModelLeaseMonthlyChargesTypeInput | null,
  monthlyCharges?: ModelFloatInput | null,
  rentalPrice?: ModelFloatInput | null,
  signatureDate?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  unitId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUnitLeaseConditionInput | null > | null,
  or?: Array< ModelUnitLeaseConditionInput | null > | null,
  not?: ModelUnitLeaseConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIndexTypeInput = {
  eq?: IndexType | null,
  ne?: IndexType | null,
};

export type ModelBaseIndexTypeInput = {
  eq?: BaseIndexType | null,
  ne?: BaseIndexType | null,
};

export type ModelLeaseMonthlyChargesTypeInput = {
  eq?: LeaseMonthlyChargesType | null,
  ne?: LeaseMonthlyChargesType | null,
};

export type UpdateUnitLeaseInput = {
  id: string,
  allowChargeAdjustment?: boolean | null,
  authorizeProfessionalActivity?: boolean | null,
  chargesIndexation?: boolean | null,
  createdAt?: string | null,
  endDate?: string | null,
  furnishedRental?: boolean | null,
  furnishedRentalInsuranceByTenant?: boolean | null,
  furnishedRentalRentalPricePercentage?: number | null,
  initialMonthlyCharges?: number | null,
  initialRentalPrice?: number | null,
  indexation?: boolean | null,
  advancedIndexation?: boolean | null,
  indexType?: IndexType | null,
  baseYear?: number | null,
  baseIndexType?: BaseIndexType | null,
  baseIndexDate?: string | null,
  leaseId?: string | null,
  mainUnit?: boolean | null,
  monthlyChargesType?: LeaseMonthlyChargesType | null,
  monthlyCharges?: number | null,
  rentalPrice?: number | null,
  signatureDate?: string | null,
  startDate?: string | null,
  unitId?: string | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteUnitLeaseInput = {
  id: string,
  _version?: number | null,
};

export type CreateUnitOwnerInput = {
  id?: string | null,
  disabled?: boolean | null,
  ownerId: string,
  periodFrom?: string | null,
  periodTo?: string | null,
  stake: number,
  unitId: string,
  readers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelUnitOwnerConditionInput = {
  disabled?: ModelBooleanInput | null,
  ownerId?: ModelIDInput | null,
  periodFrom?: ModelStringInput | null,
  periodTo?: ModelStringInput | null,
  stake?: ModelFloatInput | null,
  unitId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUnitOwnerConditionInput | null > | null,
  or?: Array< ModelUnitOwnerConditionInput | null > | null,
  not?: ModelUnitOwnerConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateUnitOwnerInput = {
  id: string,
  disabled?: boolean | null,
  ownerId?: string | null,
  periodFrom?: string | null,
  periodTo?: string | null,
  stake?: number | null,
  unitId?: string | null,
  readers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteUnitOwnerInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserInput = {
  id?: string | null,
  email: string,
  firstName?: string | null,
  lastName?: string | null,
  cognitoSub?: string | null,
  googleSub?: string | null,
  facebookSub?: string | null,
  rootUser?: boolean | null,
  refreshToken?: boolean | null,
  userRoleId?: string | null,
  contactId?: string | null,
  status: UserStatus,
  readId: string,
  clientId: string,
  _ttl?: number | null,
  _version?: number | null,
};

export type ModelUserConditionInput = {
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  cognitoSub?: ModelIDInput | null,
  googleSub?: ModelIDInput | null,
  facebookSub?: ModelIDInput | null,
  rootUser?: ModelBooleanInput | null,
  refreshToken?: ModelBooleanInput | null,
  userRoleId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  status?: ModelUserStatusInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  _ttl?: ModelIntInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserStatusInput = {
  eq?: UserStatus | null,
  ne?: UserStatus | null,
};

export type UpdateUserInput = {
  id: string,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  cognitoSub?: string | null,
  googleSub?: string | null,
  facebookSub?: string | null,
  rootUser?: boolean | null,
  refreshToken?: boolean | null,
  userRoleId?: string | null,
  contactId?: string | null,
  status?: UserStatus | null,
  readId?: string | null,
  clientId?: string | null,
  _ttl?: number | null,
  _version?: number | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserRoleInput = {
  id?: string | null,
  name: string,
  userRights?: Array< UserRightInput > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type UserRightInput = {
  access?: Array< UserRightAccessType > | null,
  section: UserRightSection,
};

export type ModelUserRoleConditionInput = {
  name?: ModelStringInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUserRoleConditionInput | null > | null,
  or?: Array< ModelUserRoleConditionInput | null > | null,
  not?: ModelUserRoleConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateUserRoleInput = {
  id: string,
  name?: string | null,
  userRights?: Array< UserRightInput > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteUserRoleInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserTeamInput = {
  id?: string | null,
  userId: string,
  teamId: string,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelUserTeamConditionInput = {
  userId?: ModelIDInput | null,
  teamId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUserTeamConditionInput | null > | null,
  or?: Array< ModelUserTeamConditionInput | null > | null,
  not?: ModelUserTeamConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateUserTeamInput = {
  id: string,
  userId?: string | null,
  teamId?: string | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteUserTeamInput = {
  id: string,
  _version?: number | null,
};

export type CreateValuationInput = {
  id?: string | null,
  sourceType?: ValuationSourceType | null,
  valorization: number,
  date: string,
  unitId?: string | null,
  buildingId?: string | null,
  writers?: Array< string > | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export enum ValuationSourceType {
  MANUAL = "MANUAL",
  PROFESSIONAL = "PROFESSIONAL",
}


export type ModelValuationConditionInput = {
  sourceType?: ModelValuationSourceTypeInput | null,
  valorization?: ModelFloatInput | null,
  date?: ModelStringInput | null,
  unitId?: ModelIDInput | null,
  buildingId?: ModelIDInput | null,
  writers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelValuationConditionInput | null > | null,
  or?: Array< ModelValuationConditionInput | null > | null,
  not?: ModelValuationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelValuationSourceTypeInput = {
  eq?: ValuationSourceType | null,
  ne?: ValuationSourceType | null,
};

export type Valuation = {
  __typename: "Valuation",
  id: string,
  sourceType?: ValuationSourceType | null,
  valorization: number,
  date: string,
  unitId?: string | null,
  unit?: Unit | null,
  buildingId?: string | null,
  building?: Building | null,
  writers?: Array< string > | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateValuationInput = {
  id: string,
  sourceType?: ValuationSourceType | null,
  valorization?: number | null,
  date?: string | null,
  unitId?: string | null,
  buildingId?: string | null,
  writers?: Array< string > | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteValuationInput = {
  id: string,
  _version?: number | null,
};

export type CreateVariousOperationInput = {
  id?: string | null,
  amount: number,
  labels?: Array< CustomLabelInput > | null,
  numberOfMonths?: number | null,
  operationType: LeaseVariousOperationType,
  paymentFrequency: LeasePaymentFrequency,
  type: VariousOperationType,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelVariousOperationConditionInput = {
  amount?: ModelFloatInput | null,
  numberOfMonths?: ModelIntInput | null,
  operationType?: ModelLeaseVariousOperationTypeInput | null,
  paymentFrequency?: ModelLeasePaymentFrequencyInput | null,
  type?: ModelVariousOperationTypeInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelVariousOperationConditionInput | null > | null,
  or?: Array< ModelVariousOperationConditionInput | null > | null,
  not?: ModelVariousOperationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type VariousOperation = {
  __typename: "VariousOperation",
  id: string,
  amount: number,
  labels?:  Array<CustomLabel > | null,
  numberOfMonths?: number | null,
  operationType: LeaseVariousOperationType,
  paymentFrequency: LeasePaymentFrequency,
  type: VariousOperationType,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateVariousOperationInput = {
  id: string,
  amount?: number | null,
  labels?: Array< CustomLabelInput > | null,
  numberOfMonths?: number | null,
  operationType?: LeaseVariousOperationType | null,
  paymentFrequency?: LeasePaymentFrequency | null,
  type?: VariousOperationType | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteVariousOperationInput = {
  id: string,
  _version?: number | null,
};

export type CreateVatRateInput = {
  id?: string | null,
  country: string,
  label: string,
  value?: number | null,
  readId: string,
  clientId: string,
  _version?: number | null,
};

export type ModelVatRateConditionInput = {
  country?: ModelStringInput | null,
  label?: ModelStringInput | null,
  value?: ModelFloatInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelVatRateConditionInput | null > | null,
  or?: Array< ModelVatRateConditionInput | null > | null,
  not?: ModelVatRateConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type VatRate = {
  __typename: "VatRate",
  id: string,
  country: string,
  label: string,
  value?: number | null,
  readId: string,
  clientId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateVatRateInput = {
  id: string,
  country?: string | null,
  label?: string | null,
  value?: number | null,
  readId?: string | null,
  clientId?: string | null,
  _version?: number | null,
};

export type DeleteVatRateInput = {
  id: string,
  _version?: number | null,
};

export type ModelAccountLabelFilterInput = {
  id?: ModelIDInput | null,
  bankAccountId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  class?: ModelIntInput | null,
  topClass?: ModelIntInput | null,
  customUsage?: ModelAccountLabelCustomUsageInput | null,
  disabled?: ModelBooleanInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelAccountLabelFilterInput | null > | null,
  or?: Array< ModelAccountLabelFilterInput | null > | null,
  not?: ModelAccountLabelFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAccountLabelConnection = {
  __typename: "ModelAccountLabelConnection",
  items:  Array<AccountLabel | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAddressFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelAddressStatusInput | null,
  street?: ModelStringInput | null,
  number?: ModelStringInput | null,
  box?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  city?: ModelStringInput | null,
  region?: ModelStringInput | null,
  country?: ModelStringInput | null,
  latitude?: ModelFloatInput | null,
  longitude?: ModelFloatInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelAddressFilterInput | null > | null,
  or?: Array< ModelAddressFilterInput | null > | null,
  not?: ModelAddressFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAddressConnection = {
  __typename: "ModelAddressConnection",
  items:  Array<Address | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAdvertisementFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelAdvertisementUnitTypeInput | null,
  subType?: ModelAdvertisementUnitSubTypeInput | null,
  amenities?: ModelAmenitiesTypeListInput | null,
  amountOfFacades?: ModelIntInput | null,
  availabilityDate?: ModelStringInput | null,
  certificateOfConformity?: ModelCertificateOfConformityTypeInput | null,
  citationForPlanningOffence?: ModelBooleanInput | null,
  delimitedZone?: ModelDelimitedZoneTypeInput | null,
  floodProneArea?: ModelFloodProneAreaTypeInput | null,
  furnished?: ModelBooleanInput | null,
  firstOccupancy?: ModelBooleanInput | null,
  gardenSurface?: ModelFloatInput | null,
  heatingAmenities?: ModelHeatingAmenitiesListInput | null,
  heatingType?: ModelHeatingTypeInput | null,
  landTerrain?: ModelLandTerrainTypeListInput | null,
  landArea?: ModelFloatInput | null,
  length?: ModelFloatInput | null,
  planningPermission?: ModelBooleanInput | null,
  publishCadastralIncome?: ModelBooleanInput | null,
  resort?: ModelBooleanInput | null,
  rightOfPreemption?: ModelBooleanInput | null,
  subdivisionAuthorization?: ModelBooleanInput | null,
  wheelChairAccessible?: ModelBooleanInput | null,
  width?: ModelFloatInput | null,
  terraceSurface?: ModelFloatInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelAdvertisementFilterInput | null > | null,
  or?: Array< ModelAdvertisementFilterInput | null > | null,
  not?: ModelAdvertisementFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAdvertisementConnection = {
  __typename: "ModelAdvertisementConnection",
  items:  Array<Advertisement | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAgencyRateFilterInput = {
  id?: ModelIDInput | null,
  amountIfVacantUnit?: ModelFloatInput | null,
  amountMinimumFees?: ModelFloatInput | null,
  custom?: ModelBooleanInput | null,
  feesAppliedTo?: ModelAgencyRateFeesTypeInput | null,
  includedInAmount?: ModelAgencyRateIncludedAmountListInput | null,
  name?: ModelStringInput | null,
  repartitionType?: ModelAgencyRateRepartitionTypeInput | null,
  type?: ModelAgencyRateTypeInput | null,
  unitType?: ModelUnitTypeInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelAgencyRateFilterInput | null > | null,
  or?: Array< ModelAgencyRateFilterInput | null > | null,
  not?: ModelAgencyRateFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAgencyRateConnection = {
  __typename: "ModelAgencyRateConnection",
  items:  Array<AgencyRate | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAgencyRateOwnerFilterInput = {
  id?: ModelIDInput | null,
  agencyRateId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelAgencyRateOwnerFilterInput | null > | null,
  or?: Array< ModelAgencyRateOwnerFilterInput | null > | null,
  not?: ModelAgencyRateOwnerFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAgencyRateStatementFilterInput = {
  id?: ModelIDInput | null,
  agencyRateId?: ModelIDInput | null,
  statementId?: ModelIDInput | null,
  totalAmountRateable?: ModelFloatInput | null,
  totalFixedAmount?: ModelFloatInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelAgencyRateStatementFilterInput | null > | null,
  or?: Array< ModelAgencyRateStatementFilterInput | null > | null,
  not?: ModelAgencyRateStatementFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelBankAccountFilterInput = {
  id?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  number?: ModelStringInput | null,
  bicNumber?: ModelStringInput | null,
  bankName?: ModelStringInput | null,
  disabled?: ModelBooleanInput | null,
  integrations?: ModelIntegrationNameListInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelBankAccountFilterInput | null > | null,
  or?: Array< ModelBankAccountFilterInput | null > | null,
  not?: ModelBankAccountFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelBankAccountConnection = {
  __typename: "ModelBankAccountConnection",
  items:  Array<BankAccount | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBuildingFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  addressId?: ModelIDInput | null,
  acquisitionValue?: ModelFloatInput | null,
  acquisitionAdditionalCostsValue?: ModelFloatInput | null,
  acquisitionAdvertisedValue?: ModelFloatInput | null,
  amountOfFacades?: ModelIntInput | null,
  amountOfFloors?: ModelIntInput | null,
  amountOfHousings?: ModelIntInput | null,
  buildingState?: ModelBuildingStateInput | null,
  cadastralIncome?: ModelFloatInput | null,
  constructionYear?: ModelStringInput | null,
  janitor?: ModelBooleanInput | null,
  jointOwnershipId?: ModelIDInput | null,
  propertyTax?: ModelFloatInput | null,
  residenceName?: ModelStringInput | null,
  readers?: ModelIDInput | null,
  teams?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelBuildingFilterInput | null > | null,
  or?: Array< ModelBuildingFilterInput | null > | null,
  not?: ModelBuildingFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelBuildingConnection = {
  __typename: "ModelBuildingConnection",
  items:  Array<Building | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelBuildingEventFilterInput = {
  id?: ModelIDInput | null,
  buildingId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  totalCost?: ModelFloatInput | null,
  note?: ModelStringInput | null,
  writers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelBuildingEventFilterInput | null > | null,
  or?: Array< ModelBuildingEventFilterInput | null > | null,
  not?: ModelBuildingEventFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelBuildingOwnerFilterInput = {
  id?: ModelIDInput | null,
  buildingId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  disabled?: ModelBooleanInput | null,
  periodFrom?: ModelStringInput | null,
  periodTo?: ModelStringInput | null,
  stake?: ModelFloatInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelBuildingOwnerFilterInput | null > | null,
  or?: Array< ModelBuildingOwnerFilterInput | null > | null,
  not?: ModelBuildingOwnerFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelChargeFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelChargeStatusInput | null,
  invoiceDate?: ModelStringInput | null,
  paymentDueDate?: ModelStringInput | null,
  archivedDate?: ModelStringInput | null,
  periodFrom?: ModelStringInput | null,
  periodTo?: ModelStringInput | null,
  totalAmount?: ModelFloatInput | null,
  source?: ModelChargeSourceInput | null,
  draftFromOcr?: ModelBooleanInput | null,
  accountingDocumentId?: ModelIDInput | null,
  paid?: ModelBooleanInput | null,
  remittanceInformation?: ModelStringInput | null,
  note?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  creatorEmail?: ModelStringInput | null,
  createdById?: ModelIDInput | null,
  contractorId?: ModelIDInput | null,
  recipientId?: ModelIDInput | null,
  documentId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelChargeFilterInput | null > | null,
  or?: Array< ModelChargeFilterInput | null > | null,
  not?: ModelChargeFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelChargeConnection = {
  __typename: "ModelChargeConnection",
  items:  Array<Charge | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelChargeStatementFilterInput = {
  id?: ModelIDInput | null,
  chargeId?: ModelIDInput | null,
  statementId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelChargeStatementFilterInput | null > | null,
  or?: Array< ModelChargeStatementFilterInput | null > | null,
  not?: ModelChargeStatementFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelClientFilterInput = {
  id?: ModelIDInput | null,
  accountType?: ModelAccountTypeInput | null,
  contactId?: ModelIDInput | null,
  generalConditionsAcceptDate?: ModelStringInput | null,
  lastGeneralConditionsAcceptedVersion?: ModelStringInput | null,
  GDPRAcceptDate?: ModelStringInput | null,
  lastGDPRAcceptedVersion?: ModelStringInput | null,
  chargeEmail?: ModelStringInput | null,
  issueEmail?: ModelStringInput | null,
  chargeEmailFromSendingSource?: ModelStringInput | null,
  issueEmailFromSendingSource?: ModelStringInput | null,
  and?: Array< ModelClientFilterInput | null > | null,
  or?: Array< ModelClientFilterInput | null > | null,
  not?: ModelClientFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelClientConnection = {
  __typename: "ModelClientConnection",
  items:  Array<Client | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCommunicationFilterInput = {
  id?: ModelIDInput | null,
  addressId?: ModelIDInput | null,
  body?: ModelStringInput | null,
  buildingId?: ModelStringInput | null,
  channel?: ModelCommunicationChannelInput | null,
  communicationTemplateId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  expirationDate?: ModelStringInput | null,
  invoiceId?: ModelIDInput | null,
  leaseId?: ModelIDInput | null,
  leasePriceHistoryId?: ModelIDInput | null,
  linkedCommunicationsIds?: ModelIDInput | null,
  recipient?: ModelStringInput | null,
  recipientInBcc?: ModelStringInput | null,
  recipientInCc?: ModelStringInput | null,
  sender?: ModelStringInput | null,
  sentAt?: ModelStringInput | null,
  externalId?: ModelIDInput | null,
  signatureDocumentId?: ModelIDInput | null,
  snoozedDate?: ModelStringInput | null,
  statementId?: ModelIDInput | null,
  status?: ModelCommunicationStatusInput | null,
  subject?: ModelStringInput | null,
  toSend?: ModelBooleanInput | null,
  type?: ModelCommunicationTypeInput | null,
  _ttl?: ModelIntInput | null,
  unitId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelCommunicationFilterInput | null > | null,
  or?: Array< ModelCommunicationFilterInput | null > | null,
  not?: ModelCommunicationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCommunicationConnection = {
  __typename: "ModelCommunicationConnection",
  items:  Array<Communication | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCommunicationSettingsProfileFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  defaultName?: ModelStringInput | null,
  isDefaultSetting?: ModelBooleanInput | null,
  referent?: ModelCommunicationSettingsProfileReferentInput | null,
  replacementId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelCommunicationSettingsProfileFilterInput | null > | null,
  or?: Array< ModelCommunicationSettingsProfileFilterInput | null > | null,
  not?: ModelCommunicationSettingsProfileFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCommunicationSettingsProfileConnection = {
  __typename: "ModelCommunicationSettingsProfileConnection",
  items:  Array<CommunicationSettingsProfile | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelContactFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  commercialName?: ModelStringInput | null,
  communicationSettingsProfileId?: ModelIDInput | null,
  businessNumber?: ModelStringInput | null,
  types?: ModelContactTypeInput | null,
  vat?: ModelStringInput | null,
  email?: ModelStringInput | null,
  birthDate?: ModelStringInput | null,
  birthPlace?: ModelStringInput | null,
  nationalRegister?: ModelStringInput | null,
  civility?: ModelCivilityTypeInput | null,
  addressId?: ModelIDInput | null,
  language?: ModelStringInput | null,
  agencyRateDisabled?: ModelBooleanInput | null,
  status?: ModelContactStatusInput | null,
  sendInvitation?: ModelBooleanInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelContactFilterInput | null > | null,
  or?: Array< ModelContactFilterInput | null > | null,
  not?: ModelContactFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelContactConnection = {
  __typename: "ModelContactConnection",
  items:  Array<Contact | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCustomFieldFilterInput = {
  id?: ModelIDInput | null,
  entity?: ModelStringInput | null,
  fieldName?: ModelStringInput | null,
  fieldType?: ModelCustomFieldTypeInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelCustomFieldFilterInput | null > | null,
  or?: Array< ModelCustomFieldFilterInput | null > | null,
  not?: ModelCustomFieldFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCustomFieldConnection = {
  __typename: "ModelCustomFieldConnection",
  items:  Array<CustomField | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCustomSubscriptionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelCustomSubscriptionTypeInput | null,
  value?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  _ttl?: ModelIntInput | null,
  updatedAt?: ModelStringInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelCustomSubscriptionFilterInput | null > | null,
  or?: Array< ModelCustomSubscriptionFilterInput | null > | null,
  not?: ModelCustomSubscriptionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCustomSubscriptionConnection = {
  __typename: "ModelCustomSubscriptionConnection",
  items:  Array<CustomSubscription | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDocumentCategoryFilterInput = {
  id?: ModelIDInput | null,
  entity?: ModelStringInput | null,
  fileCategory?: ModelFileCategoryInput | null,
  parentId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelDocumentCategoryFilterInput | null > | null,
  or?: Array< ModelDocumentCategoryFilterInput | null > | null,
  not?: ModelDocumentCategoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelDocumentCategoryConnection = {
  __typename: "ModelDocumentCategoryConnection",
  items:  Array<DocumentCategory | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFileFilterInput = {
  id?: ModelIDInput | null,
  bucket?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  categoryId?: ModelIDInput | null,
  foreignKey?: ModelIDInput | null,
  foreignTableName?: ModelStringInput | null,
  key?: ModelStringInput | null,
  mimeType?: ModelStringInput | null,
  region?: ModelStringInput | null,
  size?: ModelIntInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelFileFilterInput | null > | null,
  or?: Array< ModelFileFilterInput | null > | null,
  not?: ModelFileFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIntegrationFilterInput = {
  id?: ModelIDInput | null,
  error?: ModelStringInput | null,
  lastRun?: ModelStringInput | null,
  name?: ModelIntegrationNameInput | null,
  status?: ModelIntegrationStatusInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelIntegrationFilterInput | null > | null,
  or?: Array< ModelIntegrationFilterInput | null > | null,
  not?: ModelIntegrationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIntegrationConnection = {
  __typename: "ModelIntegrationConnection",
  items:  Array<Integration | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInventoryCustomItemFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelInventoryItemTypeInput | null,
  value?: ModelStringInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelInventoryCustomItemFilterInput | null > | null,
  or?: Array< ModelInventoryCustomItemFilterInput | null > | null,
  not?: ModelInventoryCustomItemFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelInventoryCustomItemConnection = {
  __typename: "ModelInventoryCustomItemConnection",
  items:  Array<InventoryCustomItem | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInvoiceFilterInput = {
  id?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  bankAccountId?: ModelIDInput | null,
  communicationId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  creditNote?: ModelBooleanInput | null,
  creditNoteLabel?: ModelStringInput | null,
  dueDate?: ModelStringInput | null,
  invoiceDate?: ModelStringInput | null,
  invoiceNumber?: ModelStringInput | null,
  leaseId?: ModelIDInput | null,
  paid?: ModelBooleanInput | null,
  receiptNumber?: ModelStringInput | null,
  reminderDates?: ModelStringInput | null,
  remittanceInformation?: ModelStringInput | null,
  statementId?: ModelIDInput | null,
  type?: ModelInvoiceTypeInput | null,
  unitId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelInvoiceFilterInput | null > | null,
  or?: Array< ModelInvoiceFilterInput | null > | null,
  not?: ModelInvoiceFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelInvoiceConnection = {
  __typename: "ModelInvoiceConnection",
  items:  Array<Invoice | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInvoiceSettingFilterInput = {
  id?: ModelIDInput | null,
  creditNoteFormat?: ModelStringInput | null,
  creditNoteLastUpdate?: ModelStringInput | null,
  creditNoteIncrementNumber?: ModelIntInput | null,
  invoiceFormat?: ModelStringInput | null,
  invoiceLastUpdate?: ModelStringInput | null,
  invoiceIncrementNumber?: ModelIntInput | null,
  paymentRequestFormat?: ModelStringInput | null,
  paymentRequestLastUpdate?: ModelStringInput | null,
  paymentRequestIncrementNumber?: ModelIntInput | null,
  receiptFormat?: ModelStringInput | null,
  receiptLastUpdate?: ModelStringInput | null,
  receiptIncrementNumber?: ModelIntInput | null,
  ownerId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelInvoiceSettingFilterInput | null > | null,
  or?: Array< ModelInvoiceSettingFilterInput | null > | null,
  not?: ModelInvoiceSettingFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelInvoiceSettingConnection = {
  __typename: "ModelInvoiceSettingConnection",
  items:  Array<InvoiceSetting | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelLeaseFilterInput = {
  id?: ModelIDInput | null,
  balance?: ModelFloatInput | null,
  canBeExtended?: ModelBooleanInput | null,
  communicationSettingsProfileId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  includeRealEstateInLeaseContract?: ModelBooleanInput | null,
  invoiceSenderId?: ModelIDInput | null,
  inventoryOfFixturesExpert?: ModelStringInput | null,
  inventoryOfFixturesMethod?: ModelInventoryOfFixturesMethodInput | null,
  inventoryOfFixturesType?: ModelInventoryOfFixturesTypeInput | null,
  ipiNumber?: ModelStringInput | null,
  language?: ModelLanguageInput | null,
  lastIndexationProcessDate?: ModelStringInput | null,
  lastInvoiceDate?: ModelStringInput | null,
  legalCompetentAuthority?: ModelStringInput | null,
  name?: ModelStringInput | null,
  paymentFrequency?: ModelLeasePaymentFrequencyInput | null,
  paymentInvoicePeriod?: ModelLeasePaymentInvoicePeriodInput | null,
  paymentInvoicePeriodCustomDay?: ModelIntInput | null,
  realEstateAgency?: ModelStringInput | null,
  realEstateTax?: ModelIntInput | null,
  remittanceInformation?: ModelStringInput | null,
  rentalGuarantyAmount?: ModelFloatInput | null,
  rentalGuarantyFinancialInstitution?: ModelStringInput | null,
  rentalGuarantyType?: ModelLeaseRentalGuarantyTypeInput | null,
  signatureDate?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  status?: ModelLeaseStatusInput | null,
  technicCommunicationSettingsProfileId?: ModelIDInput | null,
  totalInitialMonthlyCharges?: ModelFloatInput | null,
  totalInitialRentalPrice?: ModelFloatInput | null,
  totalMonthlyCharges?: ModelFloatInput | null,
  totalRentalPrice?: ModelFloatInput | null,
  type?: ModelLeaseTypeInput | null,
  vatRateCharge?: ModelFloatInput | null,
  vatRateRent?: ModelFloatInput | null,
  bankAccountId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeaseFilterInput | null > | null,
  or?: Array< ModelLeaseFilterInput | null > | null,
  not?: ModelLeaseFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLeaseConnection = {
  __typename: "ModelLeaseConnection",
  items:  Array<Lease | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelLeaseActionHistoryFilterInput = {
  id?: ModelIDInput | null,
  action?: ModelLeaseActionInput | null,
  comment?: ModelStringInput | null,
  contactId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  leaseId?: ModelIDInput | null,
  signatureDocumentId?: ModelIDInput | null,
  status?: ModelLeaseActionHistoryStatusInput | null,
  communicationChannel?: ModelCommunicationChannelInput | null,
  communicationSendDate?: ModelStringInput | null,
  communicationByPlatform?: ModelBooleanInput | null,
  endReason?: ModelLeaseActionEndReasonInput | null,
  endReasonOwnUseRelation?: ModelStringInput | null,
  endReasonOwnUseName?: ModelStringInput | null,
  initiator?: ModelLeaseActionEndInitiatorInput | null,
  totalAmount?: ModelFloatInput | null,
  totalAmountLabel?: ModelStringInput | null,
  automatic?: ModelBooleanInput | null,
  hasLeaseTypeChanged?: ModelBooleanInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeaseActionHistoryFilterInput | null > | null,
  or?: Array< ModelLeaseActionHistoryFilterInput | null > | null,
  not?: ModelLeaseActionHistoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLeaseAdditionalClauseFilterInput = {
  id?: ModelIDInput | null,
  leaseId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeaseAdditionalClauseFilterInput | null > | null,
  or?: Array< ModelLeaseAdditionalClauseFilterInput | null > | null,
  not?: ModelLeaseAdditionalClauseFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLeaseContactFilterInput = {
  id?: ModelIDInput | null,
  leaseId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  contactRole?: ModelContactTypeInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeaseContactFilterInput | null > | null,
  or?: Array< ModelLeaseContactFilterInput | null > | null,
  not?: ModelLeaseContactFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLeasePriceHistoryFilterInput = {
  id?: ModelIDInput | null,
  previousTotalRentalPrice?: ModelFloatInput | null,
  previousTotalMonthlyCharge?: ModelFloatInput | null,
  totalRentalPrice?: ModelFloatInput | null,
  optimalTotalRentalPrice?: ModelFloatInput | null,
  totalMonthlyCharges?: ModelFloatInput | null,
  optimalTotalMonthlyCharges?: ModelFloatInput | null,
  applicationDate?: ModelStringInput | null,
  status?: ModelLeaseAmountUpdateStatusInput | null,
  comment?: ModelStringInput | null,
  type?: ModelLeasePriceHistoryTypeInput | null,
  communicationId?: ModelIDInput | null,
  leaseId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeasePriceHistoryFilterInput | null > | null,
  or?: Array< ModelLeasePriceHistoryFilterInput | null > | null,
  not?: ModelLeasePriceHistoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLeaseInventoryFilterInput = {
  id?: ModelIDInput | null,
  leaseId?: ModelIDInput | null,
  inventoryType?: ModelLeaseInventoryTypeInput | null,
  status?: ModelLeaseStatusInput | null,
  createdAt?: ModelStringInput | null,
  signatureDate?: ModelStringInput | null,
  note?: ModelStringInput | null,
  fileId?: ModelIDInput | null,
  signatureMethods?: ModelSignatureTypeListInput | null,
  additionalMessage?: ModelStringInput | null,
  connectionPlugs?: ModelConnectionPlugsTypeListInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeaseInventoryFilterInput | null > | null,
  or?: Array< ModelLeaseInventoryFilterInput | null > | null,
  not?: ModelLeaseInventoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLeaseInventoryCheckedItemFilterInput = {
  id?: ModelIDInput | null,
  custom?: ModelBooleanInput | null,
  leaseInventoryEncodingId?: ModelIDInput | null,
  totalDefects?: ModelIntInput | null,
  customItemId?: ModelIDInput | null,
  value?: ModelStringInput | null,
  type?: ModelInventoryItemTypeInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeaseInventoryCheckedItemFilterInput | null > | null,
  or?: Array< ModelLeaseInventoryCheckedItemFilterInput | null > | null,
  not?: ModelLeaseInventoryCheckedItemFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLeaseInventoryEncodingFilterInput = {
  id?: ModelIDInput | null,
  leaseInventoryId?: ModelIDInput | null,
  structureId?: ModelIDInput | null,
  state?: ModelLeaseInventoryStructureStateTypeInput | null,
  note?: ModelStringInput | null,
  furnitureQuantity?: ModelIntInput | null,
  furniturePrice?: ModelFloatInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeaseInventoryEncodingFilterInput | null > | null,
  or?: Array< ModelLeaseInventoryEncodingFilterInput | null > | null,
  not?: ModelLeaseInventoryEncodingFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLeaseVariousOperationFilterInput = {
  id?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  contactId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  label?: ModelStringInput | null,
  lastInvoiceDate?: ModelStringInput | null,
  leaseId?: ModelIDInput | null,
  operationType?: ModelLeaseVariousOperationTypeInput | null,
  paymentFrequency?: ModelLeasePaymentFrequencyInput | null,
  paymentInvoicePeriod?: ModelLeasePaymentInvoicePeriodInput | null,
  paymentInvoicePeriodCustomDay?: ModelIntInput | null,
  reason?: ModelStringInput | null,
  separateInvoice?: ModelBooleanInput | null,
  startDate?: ModelStringInput | null,
  type?: ModelVariousOperationTypeInput | null,
  unitId?: ModelIDInput | null,
  vatRate?: ModelFloatInput | null,
  readId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelLeaseVariousOperationFilterInput | null > | null,
  or?: Array< ModelLeaseVariousOperationFilterInput | null > | null,
  not?: ModelLeaseVariousOperationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelMortgageFilterInput = {
  id?: ModelIDInput | null,
  lender?: ModelStringInput | null,
  durationInYears?: ModelIntInput | null,
  rateType?: ModelMortgageRateTypeInput | null,
  interestRate?: ModelFloatInput | null,
  aprRate?: ModelFloatInput | null,
  startDate?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  monthlyPayment?: ModelFloatInput | null,
  unitId?: ModelIDInput | null,
  buildingId?: ModelIDInput | null,
  writers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelMortgageFilterInput | null > | null,
  or?: Array< ModelMortgageFilterInput | null > | null,
  not?: ModelMortgageFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelMortgageConnection = {
  __typename: "ModelMortgageConnection",
  items:  Array<Mortgage | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  createdBy?: ModelNotificationCreationInput | null,
  type?: ModelNotificationTypeInput | null,
  message?: ModelStringInput | null,
  popupDate?: ModelStringInput | null,
  expirationDate?: ModelStringInput | null,
  status?: ModelNotificationStatusInput | null,
  createdAt?: ModelStringInput | null,
  buildingId?: ModelIDInput | null,
  unitId?: ModelIDInput | null,
  leaseId?: ModelIDInput | null,
  technicId?: ModelIDInput | null,
  ticketId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPostingFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  class?: ModelIntInput | null,
  customLabel?: ModelStringInput | null,
  topClass?: ModelIntInput | null,
  type?: ModelPostingTypeInput | null,
  totalAmount?: ModelFloatInput | null,
  periodFrom?: ModelStringInput | null,
  periodTo?: ModelStringInput | null,
  buildingId?: ModelIDInput | null,
  unitId?: ModelIDInput | null,
  invoiceId?: ModelIDInput | null,
  chargeId?: ModelIDInput | null,
  transactionId?: ModelIDInput | null,
  leaseId?: ModelIDInput | null,
  bankAccountId?: ModelIDInput | null,
  accountLabelId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  leaseVariousOperationId?: ModelIDInput | null,
  statementId?: ModelIDInput | null,
  amountVatExcluded?: ModelFloatInput | null,
  vatAmount?: ModelFloatInput | null,
  vatRate?: ModelFloatInput | null,
  foreignId?: ModelIDInput | null,
  foreignType?: ModelPostingForeignTypeInput | null,
  invoicePostingId?: ModelIDInput | null,
  reconciledAt?: ModelStringInput | null,
  status?: ModelPostingStatusInput | null,
  chargeOwnerRepartition?: ModelFloatInput | null,
  chargeRepartitionMetric?: ModelStringInput | null,
  chargeRepartitionValue?: ModelFloatInput | null,
  chargeSplitNumber?: ModelIntInput | null,
  chargeType?: ModelPostingChargeTypeInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelPostingFilterInput | null > | null,
  or?: Array< ModelPostingFilterInput | null > | null,
  not?: ModelPostingFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelRepartitionKeyFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  type?: ModelRepartitionKeyTypeInput | null,
  accountLabelId?: ModelIDInput | null,
  buildingId?: ModelIDInput | null,
  ownerRepartition?: ModelFloatInput | null,
  tenantRepartition?: ModelFloatInput | null,
  repartitionMetric?: ModelStringInput | null,
  technics?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelRepartitionKeyFilterInput | null > | null,
  or?: Array< ModelRepartitionKeyFilterInput | null > | null,
  not?: ModelRepartitionKeyFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelRepartitionKeyConnection = {
  __typename: "ModelRepartitionKeyConnection",
  items:  Array<RepartitionKey | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelRepaymentStatementFilterInput = {
  id?: ModelIDInput | null,
  delaysCleared?: ModelFloatInput | null,
  expectedRent?: ModelFloatInput | null,
  leaseId?: ModelIDInput | null,
  rentsCollected?: ModelFloatInput | null,
  statementId?: ModelIDInput | null,
  totalReceivedInJointAccount?: ModelFloatInput | null,
  totalExpectedInOwnerAccount?: ModelFloatInput | null,
  unpaidRents?: ModelFloatInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelRepaymentStatementFilterInput | null > | null,
  or?: Array< ModelRepaymentStatementFilterInput | null > | null,
  not?: ModelRepaymentStatementFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSendingSourceFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  createdById?: ModelIDInput | null,
  emailAddress?: ModelStringInput | null,
  issuesEmail?: ModelStringInput | null,
  chargesEmail?: ModelStringInput | null,
  errorMessage?: ModelSendingSourceErrorTypeInput | null,
  name?: ModelStringInput | null,
  lastReminderDate?: ModelStringInput | null,
  status?: ModelSendingSourceStatusInput | null,
  statusLastVerification?: ModelStringInput | null,
  verifiedType?: ModelSendingSourceVerifiedTypeInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelSendingSourceFilterInput | null > | null,
  or?: Array< ModelSendingSourceFilterInput | null > | null,
  not?: ModelSendingSourceFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSendingSourceConnection = {
  __typename: "ModelSendingSourceConnection",
  items:  Array<SendingSource | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSettingFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelSettingTypeInput | null,
  value?: ModelStringInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelSettingFilterInput | null > | null,
  or?: Array< ModelSettingFilterInput | null > | null,
  not?: ModelSettingFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSettingConnection = {
  __typename: "ModelSettingConnection",
  items:  Array<Setting | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSignatureDocumentFilterInput = {
  id?: ModelIDInput | null,
  additionalMessage?: ModelStringInput | null,
  communicationBody?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  expirationDate?: ModelStringInput | null,
  fileIds?: ModelIDInput | null,
  foreignKey?: ModelIDInput | null,
  foreignTableName?: ModelStringInput | null,
  sentAt?: ModelStringInput | null,
  signatureMethods?: ModelSignatureTypeInput | null,
  status?: ModelSignatureDocumentStatusInput | null,
  externalStatus?: ModelStringInput | null,
  type?: ModelSignatureDocumentTypeInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelSignatureDocumentFilterInput | null > | null,
  or?: Array< ModelSignatureDocumentFilterInput | null > | null,
  not?: ModelSignatureDocumentFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStatementFilterInput = {
  id?: ModelIDInput | null,
  closingStatement?: ModelBooleanInput | null,
  periodFrom?: ModelStringInput | null,
  periodTo?: ModelStringInput | null,
  source?: ModelStatementSourceInput | null,
  status?: ModelStatementStatusInput | null,
  sentDate?: ModelStringInput | null,
  leaseId?: ModelIDInput | null,
  ownerId?: ModelIDInput | null,
  types?: ModelStatementTypeListInput | null,
  unitIds?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelStatementFilterInput | null > | null,
  or?: Array< ModelStatementFilterInput | null > | null,
  not?: ModelStatementFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStatementConnection = {
  __typename: "ModelStatementConnection",
  items:  Array<Statement | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelStatementSettingFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStatementSettingTypeInput | null,
  custom?: ModelBooleanInput | null,
  disabled?: ModelBooleanInput | null,
  monthFrequency?: ModelIntInput | null,
  startMonth?: ModelStringInput | null,
  leaseId?: ModelIDInput | null,
  ownerId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelStatementSettingFilterInput | null > | null,
  or?: Array< ModelStatementSettingFilterInput | null > | null,
  not?: ModelStatementSettingFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStatementSettingConnection = {
  __typename: "ModelStatementSettingConnection",
  items:  Array<StatementSetting | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTeamFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  parentId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelTeamFilterInput | null > | null,
  or?: Array< ModelTeamFilterInput | null > | null,
  not?: ModelTeamFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTechnicFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelTechnicTypeInput | null,
  contractNumber?: ModelStringInput | null,
  communicationSettingsProfileId?: ModelIDInput | null,
  utilityType?: ModelUtilityTypeInput | null,
  utilityEanCode?: ModelStringInput | null,
  utilityMeterNumber?: ModelStringInput | null,
  utilityDistributorId?: ModelIDInput | null,
  PebValidityDate?: ModelStringInput | null,
  PebEnergeticPerformance?: ModelStringInput | null,
  PebReportNumber?: ModelStringInput | null,
  PebIssueDate?: ModelStringInput | null,
  detectorType?: ModelDetectorTypeInput | null,
  detectorSerialNumber?: ModelStringInput | null,
  detectorBrand?: ModelStringInput | null,
  detectorLastControlDate?: ModelStringInput | null,
  detectorUnitInventoryId?: ModelStringInput | null,
  heatingBrand?: ModelStringInput | null,
  heatingModel?: ModelStringInput | null,
  heatingInstallationDate?: ModelStringInput | null,
  heatingType?: ModelHeatingTypeInput | null,
  heatingLastControlDate?: ModelStringInput | null,
  tankType?: ModelTankTypeInput | null,
  tankCapacity?: ModelIntInput | null,
  tankState?: ModelTankStateInput | null,
  tankLastControlDate?: ModelStringInput | null,
  tankLeakDetection?: ModelBooleanInput | null,
  tankEncuvement?: ModelBooleanInput | null,
  tankWallType?: ModelTankWallTypeInput | null,
  tankInstallationDate?: ModelStringInput | null,
  chimneyLastControlDate?: ModelStringInput | null,
  chimneyUnitInventoryId?: ModelStringInput | null,
  buildingId?: ModelIDInput | null,
  unitId?: ModelIDInput | null,
  leaseId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  originalTechnicId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  writers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelTechnicFilterInput | null > | null,
  or?: Array< ModelTechnicFilterInput | null > | null,
  not?: ModelTechnicFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTechnicConnection = {
  __typename: "ModelTechnicConnection",
  items:  Array<Technic | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTemplateFilterInput = {
  id?: ModelIDInput | null,
  language?: ModelStringInput | null,
  subject?: ModelStringInput | null,
  subjectRawFormat?: ModelStringInput | null,
  body?: ModelStringInput | null,
  bodyRawFormat?: ModelStringInput | null,
  type?: ModelTemplateTypeInput | null,
  subType?: ModelTemplateSubTypeInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelTemplateFilterInput | null > | null,
  or?: Array< ModelTemplateFilterInput | null > | null,
  not?: ModelTemplateFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTemplateConnection = {
  __typename: "ModelTemplateConnection",
  items:  Array<Template | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTicketFilterInput = {
  id?: ModelIDInput | null,
  assignedAt?: ModelStringInput | null,
  assignedToId?: ModelIDInput | null,
  buildingId?: ModelIDInput | null,
  closedAt?: ModelStringInput | null,
  contactId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  emailMessageId?: ModelStringInput | null,
  emailReferences?: ModelStringInput | null,
  parentId?: ModelIDInput | null,
  plannedAt?: ModelStringInput | null,
  referenceCode?: ModelStringInput | null,
  sourceType?: ModelTicketSourceTypeInput | null,
  status?: ModelTicketStatusInput | null,
  subject?: ModelStringInput | null,
  unitId?: ModelIDInput | null,
  unread?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelTicketFilterInput | null > | null,
  or?: Array< ModelTicketFilterInput | null > | null,
  not?: ModelTicketFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelTransactionFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelTransactionStatusInput | null,
  sequence?: ModelFloatInput | null,
  statementDate?: ModelStringInput | null,
  operationDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  remittanceInformationType?: ModelRemittanceTypeInput | null,
  remittanceInformation?: ModelStringInput | null,
  comment?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  bankAccountId?: ModelIDInput | null,
  counterpartName?: ModelStringInput | null,
  counterpartAccountNumber?: ModelStringInput | null,
  manualEntry?: ModelBooleanInput | null,
  proposedLeaseId?: ModelIDInput | null,
  proposedUnitId?: ModelIDInput | null,
  proposedContractorId?: ModelIDInput | null,
  proposedMatch?: ModelIntInput | null,
  updatedAt?: ModelStringInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelTransactionFilterInput | null > | null,
  or?: Array< ModelTransactionFilterInput | null > | null,
  not?: ModelTransactionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTransactionConnection = {
  __typename: "ModelTransactionConnection",
  items:  Array<Transaction | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTransactionRuleFilterInput = {
  id?: ModelIDInput | null,
  remittanceInformation?: ModelStringInput | null,
  counterpartName?: ModelStringInput | null,
  counterpartAccountNumber?: ModelStringInput | null,
  usedFields?: ModelStringInput | null,
  exclude?: ModelBooleanInput | null,
  leaseId?: ModelIDInput | null,
  unitId?: ModelIDInput | null,
  contractorId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelTransactionRuleFilterInput | null > | null,
  or?: Array< ModelTransactionRuleFilterInput | null > | null,
  not?: ModelTransactionRuleFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTransactionRuleConnection = {
  __typename: "ModelTransactionRuleConnection",
  items:  Array<TransactionRule | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUnitFilterInput = {
  id?: ModelIDInput | null,
  acquisitionAdditionalCostsValue?: ModelFloatInput | null,
  acquisitionAdvertisedValue?: ModelFloatInput | null,
  acquisitionValue?: ModelFloatInput | null,
  advertisedMonthlyCharges?: ModelFloatInput | null,
  advertisedRentalPrice?: ModelFloatInput | null,
  cadastralIncome?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  floor?: ModelIntInput | null,
  jointOwnershipId?: ModelIDInput | null,
  managementDate?: ModelStringInput | null,
  name?: ModelStringInput | null,
  predictedPrice?: ModelStringInput | null,
  propertyTax?: ModelFloatInput | null,
  publishAddress?: ModelBooleanInput | null,
  surface?: ModelFloatInput | null,
  type?: ModelUnitTypeInput | null,
  buildingId?: ModelIDInput | null,
  unitAdvertisementId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUnitFilterInput | null > | null,
  or?: Array< ModelUnitFilterInput | null > | null,
  not?: ModelUnitFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUnitEventFilterInput = {
  id?: ModelIDInput | null,
  unitId?: ModelIDInput | null,
  type?: ModelUnitEventTypeInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  totalCost?: ModelFloatInput | null,
  note?: ModelStringInput | null,
  writers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUnitEventFilterInput | null > | null,
  or?: Array< ModelUnitEventFilterInput | null > | null,
  not?: ModelUnitEventFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUnitInventoryFilterInput = {
  id?: ModelIDInput | null,
  privateBatthRoom?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  kitchenEquipment?: ModelKitchenEquipmentInput | null,
  roomName?: ModelStringInput | null,
  roomType?: ModelUnitInventoryRoomTypeInput | null,
  surface?: ModelFloatInput | null,
  showSurface?: ModelBooleanInput | null,
  unitId?: ModelIDInput | null,
  inside?: ModelBooleanInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUnitInventoryFilterInput | null > | null,
  or?: Array< ModelUnitInventoryFilterInput | null > | null,
  not?: ModelUnitInventoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUnitInventoryStructureFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  unitInventoryId?: ModelIDInput | null,
  type?: ModelUnitInventoryStructureTypeInput | null,
  name?: ModelStringInput | null,
  brand?: ModelStringInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUnitInventoryStructureFilterInput | null > | null,
  or?: Array< ModelUnitInventoryStructureFilterInput | null > | null,
  not?: ModelUnitInventoryStructureFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUnitLeaseFilterInput = {
  id?: ModelIDInput | null,
  allowChargeAdjustment?: ModelBooleanInput | null,
  authorizeProfessionalActivity?: ModelBooleanInput | null,
  chargesIndexation?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  furnishedRental?: ModelBooleanInput | null,
  furnishedRentalInsuranceByTenant?: ModelBooleanInput | null,
  furnishedRentalRentalPricePercentage?: ModelFloatInput | null,
  initialMonthlyCharges?: ModelFloatInput | null,
  initialRentalPrice?: ModelFloatInput | null,
  indexation?: ModelBooleanInput | null,
  advancedIndexation?: ModelBooleanInput | null,
  indexType?: ModelIndexTypeInput | null,
  baseYear?: ModelFloatInput | null,
  baseIndexType?: ModelBaseIndexTypeInput | null,
  baseIndexDate?: ModelStringInput | null,
  leaseId?: ModelIDInput | null,
  mainUnit?: ModelBooleanInput | null,
  monthlyChargesType?: ModelLeaseMonthlyChargesTypeInput | null,
  monthlyCharges?: ModelFloatInput | null,
  rentalPrice?: ModelFloatInput | null,
  signatureDate?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  unitId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUnitLeaseFilterInput | null > | null,
  or?: Array< ModelUnitLeaseFilterInput | null > | null,
  not?: ModelUnitLeaseFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUnitOwnerFilterInput = {
  id?: ModelIDInput | null,
  disabled?: ModelBooleanInput | null,
  ownerId?: ModelIDInput | null,
  periodFrom?: ModelStringInput | null,
  periodTo?: ModelStringInput | null,
  stake?: ModelFloatInput | null,
  unitId?: ModelIDInput | null,
  readers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUnitOwnerFilterInput | null > | null,
  or?: Array< ModelUnitOwnerFilterInput | null > | null,
  not?: ModelUnitOwnerFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  cognitoSub?: ModelIDInput | null,
  googleSub?: ModelIDInput | null,
  facebookSub?: ModelIDInput | null,
  rootUser?: ModelBooleanInput | null,
  refreshToken?: ModelBooleanInput | null,
  userRoleId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  status?: ModelUserStatusInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  _ttl?: ModelIntInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserRoleFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUserRoleFilterInput | null > | null,
  or?: Array< ModelUserRoleFilterInput | null > | null,
  not?: ModelUserRoleFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserRoleConnection = {
  __typename: "ModelUserRoleConnection",
  items:  Array<UserRole | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserTeamFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  teamId?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelUserTeamFilterInput | null > | null,
  or?: Array< ModelUserTeamFilterInput | null > | null,
  not?: ModelUserTeamFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelValuationFilterInput = {
  id?: ModelIDInput | null,
  sourceType?: ModelValuationSourceTypeInput | null,
  valorization?: ModelFloatInput | null,
  date?: ModelStringInput | null,
  unitId?: ModelIDInput | null,
  buildingId?: ModelIDInput | null,
  writers?: ModelIDInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelValuationFilterInput | null > | null,
  or?: Array< ModelValuationFilterInput | null > | null,
  not?: ModelValuationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelValuationConnection = {
  __typename: "ModelValuationConnection",
  items:  Array<Valuation | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelVariousOperationFilterInput = {
  id?: ModelIDInput | null,
  amount?: ModelFloatInput | null,
  numberOfMonths?: ModelIntInput | null,
  operationType?: ModelLeaseVariousOperationTypeInput | null,
  paymentFrequency?: ModelLeasePaymentFrequencyInput | null,
  type?: ModelVariousOperationTypeInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelVariousOperationFilterInput | null > | null,
  or?: Array< ModelVariousOperationFilterInput | null > | null,
  not?: ModelVariousOperationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelVariousOperationConnection = {
  __typename: "ModelVariousOperationConnection",
  items:  Array<VariousOperation | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelVatRateFilterInput = {
  id?: ModelIDInput | null,
  country?: ModelStringInput | null,
  label?: ModelStringInput | null,
  value?: ModelFloatInput | null,
  readId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  and?: Array< ModelVatRateFilterInput | null > | null,
  or?: Array< ModelVatRateFilterInput | null > | null,
  not?: ModelVatRateFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelVatRateConnection = {
  __typename: "ModelVatRateConnection",
  items:  Array<VatRate | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSubscriptionAddressFilterInput = {
  status?: ModelSubscriptionStringInput | null,
  street?: ModelSubscriptionStringInput | null,
  number?: ModelSubscriptionStringInput | null,
  box?: ModelSubscriptionStringInput | null,
  postalCode?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  region?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  latitude?: ModelSubscriptionFloatInput | null,
  longitude?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionAddressFilterInput | null > | null,
  or?: Array< ModelSubscriptionAddressFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBankAccountFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  contactId?: ModelSubscriptionIDInput | null,
  number?: ModelSubscriptionStringInput | null,
  bicNumber?: ModelSubscriptionStringInput | null,
  bankName?: ModelSubscriptionStringInput | null,
  disabled?: ModelSubscriptionBooleanInput | null,
  integrations?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBankAccountFilterInput | null > | null,
  or?: Array< ModelSubscriptionBankAccountFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionBuildingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  addressId?: ModelSubscriptionIDInput | null,
  acquisitionValue?: ModelSubscriptionFloatInput | null,
  acquisitionAdditionalCostsValue?: ModelSubscriptionFloatInput | null,
  acquisitionAdvertisedValue?: ModelSubscriptionFloatInput | null,
  amountOfFacades?: ModelSubscriptionIntInput | null,
  amountOfFloors?: ModelSubscriptionIntInput | null,
  amountOfHousings?: ModelSubscriptionIntInput | null,
  buildingState?: ModelSubscriptionStringInput | null,
  cadastralIncome?: ModelSubscriptionFloatInput | null,
  constructionYear?: ModelSubscriptionStringInput | null,
  janitor?: ModelSubscriptionBooleanInput | null,
  jointOwnershipId?: ModelSubscriptionIDInput | null,
  propertyTax?: ModelSubscriptionFloatInput | null,
  residenceName?: ModelSubscriptionStringInput | null,
  clientId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionBuildingFilterInput | null > | null,
  or?: Array< ModelSubscriptionBuildingFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBuildingEventFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  buildingId?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  totalCost?: ModelSubscriptionFloatInput | null,
  note?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBuildingEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionBuildingEventFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionBuildingOwnerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  buildingId?: ModelSubscriptionIDInput | null,
  contactId?: ModelSubscriptionIDInput | null,
  disabled?: ModelSubscriptionBooleanInput | null,
  periodFrom?: ModelSubscriptionStringInput | null,
  periodTo?: ModelSubscriptionStringInput | null,
  stake?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionBuildingOwnerFilterInput | null > | null,
  or?: Array< ModelSubscriptionBuildingOwnerFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionChargeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  invoiceDate?: ModelSubscriptionStringInput | null,
  paymentDueDate?: ModelSubscriptionStringInput | null,
  archivedDate?: ModelSubscriptionStringInput | null,
  periodFrom?: ModelSubscriptionStringInput | null,
  periodTo?: ModelSubscriptionStringInput | null,
  totalAmount?: ModelSubscriptionFloatInput | null,
  source?: ModelSubscriptionStringInput | null,
  draftFromOcr?: ModelSubscriptionBooleanInput | null,
  accountingDocumentId?: ModelSubscriptionIDInput | null,
  paid?: ModelSubscriptionBooleanInput | null,
  remittanceInformation?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  creatorEmail?: ModelSubscriptionStringInput | null,
  createdById?: ModelSubscriptionIDInput | null,
  contractorId?: ModelSubscriptionIDInput | null,
  recipientId?: ModelSubscriptionIDInput | null,
  documentId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionChargeFilterInput | null > | null,
  or?: Array< ModelSubscriptionChargeFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCommunicationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  addressId?: ModelSubscriptionIDInput | null,
  body?: ModelSubscriptionStringInput | null,
  buildingId?: ModelSubscriptionStringInput | null,
  channel?: ModelSubscriptionStringInput | null,
  communicationTemplateId?: ModelSubscriptionIDInput | null,
  contactId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  expirationDate?: ModelSubscriptionStringInput | null,
  invoiceId?: ModelSubscriptionIDInput | null,
  leaseId?: ModelSubscriptionIDInput | null,
  leasePriceHistoryId?: ModelSubscriptionIDInput | null,
  linkedCommunicationsIds?: ModelSubscriptionIDInput | null,
  recipient?: ModelSubscriptionStringInput | null,
  recipientInBcc?: ModelSubscriptionStringInput | null,
  recipientInCc?: ModelSubscriptionStringInput | null,
  sender?: ModelSubscriptionStringInput | null,
  sentAt?: ModelSubscriptionStringInput | null,
  externalId?: ModelSubscriptionIDInput | null,
  signatureDocumentId?: ModelSubscriptionIDInput | null,
  snoozedDate?: ModelSubscriptionStringInput | null,
  statementId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  subject?: ModelSubscriptionStringInput | null,
  toSend?: ModelSubscriptionBooleanInput | null,
  type?: ModelSubscriptionStringInput | null,
  _ttl?: ModelSubscriptionIntInput | null,
  unitId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCommunicationFilterInput | null > | null,
  or?: Array< ModelSubscriptionCommunicationFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCommunicationSettingsProfileFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  defaultName?: ModelSubscriptionStringInput | null,
  isDefaultSetting?: ModelSubscriptionBooleanInput | null,
  referent?: ModelSubscriptionStringInput | null,
  replacementId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCommunicationSettingsProfileFilterInput | null > | null,
  or?: Array< ModelSubscriptionCommunicationSettingsProfileFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionContactFilterInput = {
  createdAt?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  companyName?: ModelSubscriptionStringInput | null,
  commercialName?: ModelSubscriptionStringInput | null,
  communicationSettingsProfileId?: ModelSubscriptionIDInput | null,
  businessNumber?: ModelSubscriptionStringInput | null,
  types?: ModelSubscriptionStringInput | null,
  vat?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  birthDate?: ModelSubscriptionStringInput | null,
  birthPlace?: ModelSubscriptionStringInput | null,
  nationalRegister?: ModelSubscriptionStringInput | null,
  civility?: ModelSubscriptionStringInput | null,
  addressId?: ModelSubscriptionIDInput | null,
  language?: ModelSubscriptionStringInput | null,
  agencyRateDisabled?: ModelSubscriptionBooleanInput | null,
  status?: ModelSubscriptionStringInput | null,
  sendInvitation?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionContactFilterInput | null > | null,
  or?: Array< ModelSubscriptionContactFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCustomFieldFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  entity?: ModelSubscriptionStringInput | null,
  fieldName?: ModelSubscriptionStringInput | null,
  fieldType?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCustomFieldFilterInput | null > | null,
  or?: Array< ModelSubscriptionCustomFieldFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCustomSubscriptionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  value?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  _ttl?: ModelSubscriptionIntInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCustomSubscriptionFilterInput | null > | null,
  or?: Array< ModelSubscriptionCustomSubscriptionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionDocumentCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  entity?: ModelSubscriptionStringInput | null,
  fileCategory?: ModelSubscriptionStringInput | null,
  parentId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionDocumentCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionDocumentCategoryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFileFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  bucket?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  categoryId?: ModelSubscriptionIDInput | null,
  foreignKey?: ModelSubscriptionIDInput | null,
  key?: ModelSubscriptionStringInput | null,
  mimeType?: ModelSubscriptionStringInput | null,
  region?: ModelSubscriptionStringInput | null,
  size?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionFileFilterInput | null > | null,
  or?: Array< ModelSubscriptionFileFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionInventoryCustomItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  value?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInventoryCustomItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionInventoryCustomItemFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionLeaseFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  balance?: ModelSubscriptionFloatInput | null,
  canBeExtended?: ModelSubscriptionBooleanInput | null,
  communicationSettingsProfileId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  includeRealEstateInLeaseContract?: ModelSubscriptionBooleanInput | null,
  invoiceSenderId?: ModelSubscriptionIDInput | null,
  inventoryOfFixturesExpert?: ModelSubscriptionStringInput | null,
  inventoryOfFixturesMethod?: ModelSubscriptionStringInput | null,
  inventoryOfFixturesType?: ModelSubscriptionStringInput | null,
  ipiNumber?: ModelSubscriptionStringInput | null,
  language?: ModelSubscriptionStringInput | null,
  lastIndexationProcessDate?: ModelSubscriptionStringInput | null,
  lastInvoiceDate?: ModelSubscriptionStringInput | null,
  legalCompetentAuthority?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  paymentFrequency?: ModelSubscriptionStringInput | null,
  paymentInvoicePeriod?: ModelSubscriptionStringInput | null,
  paymentInvoicePeriodCustomDay?: ModelSubscriptionIntInput | null,
  realEstateAgency?: ModelSubscriptionStringInput | null,
  realEstateTax?: ModelSubscriptionIntInput | null,
  remittanceInformation?: ModelSubscriptionStringInput | null,
  rentalGuarantyAmount?: ModelSubscriptionFloatInput | null,
  rentalGuarantyFinancialInstitution?: ModelSubscriptionStringInput | null,
  rentalGuarantyType?: ModelSubscriptionStringInput | null,
  signatureDate?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  technicCommunicationSettingsProfileId?: ModelSubscriptionIDInput | null,
  totalInitialMonthlyCharges?: ModelSubscriptionFloatInput | null,
  totalInitialRentalPrice?: ModelSubscriptionFloatInput | null,
  totalMonthlyCharges?: ModelSubscriptionFloatInput | null,
  totalRentalPrice?: ModelSubscriptionFloatInput | null,
  type?: ModelSubscriptionStringInput | null,
  vatRateCharge?: ModelSubscriptionFloatInput | null,
  vatRateRent?: ModelSubscriptionFloatInput | null,
  bankAccountId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionLeaseFilterInput | null > | null,
  or?: Array< ModelSubscriptionLeaseFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionLeaseContactFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  leaseId?: ModelSubscriptionIDInput | null,
  contactId?: ModelSubscriptionIDInput | null,
  contactRole?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLeaseContactFilterInput | null > | null,
  or?: Array< ModelSubscriptionLeaseContactFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionLeaseInventoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  leaseId?: ModelSubscriptionIDInput | null,
  inventoryType?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  signatureDate?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  fileId?: ModelSubscriptionIDInput | null,
  signatureMethods?: ModelSubscriptionStringInput | null,
  additionalMessage?: ModelSubscriptionStringInput | null,
  connectionPlugs?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLeaseInventoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionLeaseInventoryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionLeaseInventoryCheckedItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  custom?: ModelSubscriptionBooleanInput | null,
  leaseInventoryEncodingId?: ModelSubscriptionIDInput | null,
  totalDefects?: ModelSubscriptionIntInput | null,
  customItemId?: ModelSubscriptionIDInput | null,
  value?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLeaseInventoryCheckedItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionLeaseInventoryCheckedItemFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionLeaseInventoryEncodingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  leaseInventoryId?: ModelSubscriptionIDInput | null,
  structureId?: ModelSubscriptionIDInput | null,
  state?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  furnitureQuantity?: ModelSubscriptionIntInput | null,
  furniturePrice?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionLeaseInventoryEncodingFilterInput | null > | null,
  or?: Array< ModelSubscriptionLeaseInventoryEncodingFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionNotificationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdBy?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  popupDate?: ModelSubscriptionStringInput | null,
  expirationDate?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  buildingId?: ModelSubscriptionIDInput | null,
  unitId?: ModelSubscriptionIDInput | null,
  leaseId?: ModelSubscriptionIDInput | null,
  technicId?: ModelSubscriptionIDInput | null,
  ticketId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionSendingSourceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdById?: ModelSubscriptionIDInput | null,
  emailAddress?: ModelSubscriptionStringInput | null,
  issuesEmail?: ModelSubscriptionStringInput | null,
  chargesEmail?: ModelSubscriptionStringInput | null,
  errorMessage?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  lastReminderDate?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  statusLastVerification?: ModelSubscriptionStringInput | null,
  verifiedType?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSendingSourceFilterInput | null > | null,
  or?: Array< ModelSubscriptionSendingSourceFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionSignatureDocumentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  additionalMessage?: ModelSubscriptionStringInput | null,
  communicationBody?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  expirationDate?: ModelSubscriptionStringInput | null,
  fileIds?: ModelSubscriptionIDInput | null,
  foreignKey?: ModelSubscriptionIDInput | null,
  foreignTableName?: ModelSubscriptionStringInput | null,
  sentAt?: ModelSubscriptionStringInput | null,
  signatureMethods?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  externalStatus?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSignatureDocumentFilterInput | null > | null,
  or?: Array< ModelSubscriptionSignatureDocumentFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTeamFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  parentId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionTeamFilterInput | null > | null,
  or?: Array< ModelSubscriptionTeamFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTechnicFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  contractNumber?: ModelSubscriptionStringInput | null,
  communicationSettingsProfileId?: ModelSubscriptionIDInput | null,
  utilityType?: ModelSubscriptionStringInput | null,
  utilityEanCode?: ModelSubscriptionStringInput | null,
  utilityMeterNumber?: ModelSubscriptionStringInput | null,
  utilityDistributorId?: ModelSubscriptionIDInput | null,
  PebValidityDate?: ModelSubscriptionStringInput | null,
  PebEnergeticPerformance?: ModelSubscriptionStringInput | null,
  PebReportNumber?: ModelSubscriptionStringInput | null,
  PebIssueDate?: ModelSubscriptionStringInput | null,
  detectorType?: ModelSubscriptionStringInput | null,
  detectorSerialNumber?: ModelSubscriptionStringInput | null,
  detectorBrand?: ModelSubscriptionStringInput | null,
  detectorLastControlDate?: ModelSubscriptionStringInput | null,
  detectorUnitInventoryId?: ModelSubscriptionStringInput | null,
  heatingBrand?: ModelSubscriptionStringInput | null,
  heatingModel?: ModelSubscriptionStringInput | null,
  heatingInstallationDate?: ModelSubscriptionStringInput | null,
  heatingType?: ModelSubscriptionStringInput | null,
  heatingLastControlDate?: ModelSubscriptionStringInput | null,
  tankType?: ModelSubscriptionStringInput | null,
  tankCapacity?: ModelSubscriptionIntInput | null,
  tankState?: ModelSubscriptionStringInput | null,
  tankLastControlDate?: ModelSubscriptionStringInput | null,
  tankLeakDetection?: ModelSubscriptionBooleanInput | null,
  tankEncuvement?: ModelSubscriptionBooleanInput | null,
  tankWallType?: ModelSubscriptionStringInput | null,
  tankInstallationDate?: ModelSubscriptionStringInput | null,
  chimneyLastControlDate?: ModelSubscriptionStringInput | null,
  chimneyUnitInventoryId?: ModelSubscriptionStringInput | null,
  buildingId?: ModelSubscriptionIDInput | null,
  unitId?: ModelSubscriptionIDInput | null,
  leaseId?: ModelSubscriptionIDInput | null,
  contactId?: ModelSubscriptionIDInput | null,
  originalTechnicId?: ModelSubscriptionIDInput | null,
  readers?: ModelSubscriptionIDInput | null,
  writers?: ModelSubscriptionIDInput | null,
  readId?: ModelSubscriptionIDInput | null,
  clientId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionTechnicFilterInput | null > | null,
  or?: Array< ModelSubscriptionTechnicFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTicketFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  assignedAt?: ModelSubscriptionStringInput | null,
  assignedToId?: ModelSubscriptionIDInput | null,
  buildingId?: ModelSubscriptionIDInput | null,
  closedAt?: ModelSubscriptionStringInput | null,
  contactId?: ModelSubscriptionIDInput | null,
  content?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  emailMessageId?: ModelSubscriptionStringInput | null,
  emailReferences?: ModelSubscriptionStringInput | null,
  parentId?: ModelSubscriptionIDInput | null,
  plannedAt?: ModelSubscriptionStringInput | null,
  referenceCode?: ModelSubscriptionStringInput | null,
  sourceType?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  subject?: ModelSubscriptionStringInput | null,
  unitId?: ModelSubscriptionIDInput | null,
  unread?: ModelSubscriptionBooleanInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTicketFilterInput | null > | null,
  or?: Array< ModelSubscriptionTicketFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUnitFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  acquisitionAdditionalCostsValue?: ModelSubscriptionFloatInput | null,
  acquisitionAdvertisedValue?: ModelSubscriptionFloatInput | null,
  acquisitionValue?: ModelSubscriptionFloatInput | null,
  advertisedMonthlyCharges?: ModelSubscriptionFloatInput | null,
  advertisedRentalPrice?: ModelSubscriptionFloatInput | null,
  cadastralIncome?: ModelSubscriptionFloatInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  floor?: ModelSubscriptionIntInput | null,
  jointOwnershipId?: ModelSubscriptionIDInput | null,
  managementDate?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  predictedPrice?: ModelSubscriptionStringInput | null,
  propertyTax?: ModelSubscriptionFloatInput | null,
  publishAddress?: ModelSubscriptionBooleanInput | null,
  surface?: ModelSubscriptionFloatInput | null,
  type?: ModelSubscriptionStringInput | null,
  buildingId?: ModelSubscriptionIDInput | null,
  unitAdvertisementId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionUnitFilterInput | null > | null,
  or?: Array< ModelSubscriptionUnitFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUnitEventFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  unitId?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  totalCost?: ModelSubscriptionFloatInput | null,
  note?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUnitEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionUnitEventFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUnitInventoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  privateBatthRoom?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  kitchenEquipment?: ModelSubscriptionStringInput | null,
  roomName?: ModelSubscriptionStringInput | null,
  roomType?: ModelSubscriptionStringInput | null,
  surface?: ModelSubscriptionFloatInput | null,
  showSurface?: ModelSubscriptionBooleanInput | null,
  unitId?: ModelSubscriptionIDInput | null,
  inside?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionUnitInventoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionUnitInventoryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUnitInventoryStructureFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  deletedAt?: ModelSubscriptionStringInput | null,
  unitInventoryId?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  brand?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUnitInventoryStructureFilterInput | null > | null,
  or?: Array< ModelSubscriptionUnitInventoryStructureFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUnitLeaseFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  allowChargeAdjustment?: ModelSubscriptionBooleanInput | null,
  authorizeProfessionalActivity?: ModelSubscriptionBooleanInput | null,
  chargesIndexation?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  furnishedRental?: ModelSubscriptionBooleanInput | null,
  furnishedRentalInsuranceByTenant?: ModelSubscriptionBooleanInput | null,
  furnishedRentalRentalPricePercentage?: ModelSubscriptionFloatInput | null,
  initialMonthlyCharges?: ModelSubscriptionFloatInput | null,
  initialRentalPrice?: ModelSubscriptionFloatInput | null,
  indexation?: ModelSubscriptionBooleanInput | null,
  advancedIndexation?: ModelSubscriptionBooleanInput | null,
  indexType?: ModelSubscriptionStringInput | null,
  baseYear?: ModelSubscriptionFloatInput | null,
  baseIndexType?: ModelSubscriptionStringInput | null,
  baseIndexDate?: ModelSubscriptionStringInput | null,
  leaseId?: ModelSubscriptionIDInput | null,
  mainUnit?: ModelSubscriptionBooleanInput | null,
  monthlyChargesType?: ModelSubscriptionStringInput | null,
  monthlyCharges?: ModelSubscriptionFloatInput | null,
  rentalPrice?: ModelSubscriptionFloatInput | null,
  signatureDate?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  unitId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionUnitLeaseFilterInput | null > | null,
  or?: Array< ModelSubscriptionUnitLeaseFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUnitOwnerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  disabled?: ModelSubscriptionBooleanInput | null,
  ownerId?: ModelSubscriptionIDInput | null,
  periodFrom?: ModelSubscriptionStringInput | null,
  periodTo?: ModelSubscriptionStringInput | null,
  stake?: ModelSubscriptionFloatInput | null,
  unitId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionUnitOwnerFilterInput | null > | null,
  or?: Array< ModelSubscriptionUnitOwnerFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserFilterInput = {
  email?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  cognitoSub?: ModelSubscriptionIDInput | null,
  googleSub?: ModelSubscriptionIDInput | null,
  facebookSub?: ModelSubscriptionIDInput | null,
  rootUser?: ModelSubscriptionBooleanInput | null,
  refreshToken?: ModelSubscriptionBooleanInput | null,
  userRoleId?: ModelSubscriptionIDInput | null,
  contactId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  _ttl?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserRoleFilterInput = {
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserRoleFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserRoleFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserTeamFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  teamId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionUserTeamFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserTeamFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type BatchChangeCommunicationsMutationVariables = {
  input: BatchChangeCommunicationsInput,
};

export type BatchChangeCommunicationsMutation = {
  batchChangeCommunications?:  {
    __typename: "MutationStatus",
    status?: boolean | null,
    id: string,
  } | null,
};

export type ChangeProfessionalActivityForLeaseMutationVariables = {
  input: ChangeProfessionalActivityForLeaseInput,
};

export type ChangeProfessionalActivityForLeaseMutation = {
  changeProfessionalActivityForLease?:  {
    __typename: "MutationStatus",
    status?: boolean | null,
    id: string,
  } | null,
};

export type CreateClientAccountMutationVariables = {
  input: CreateClientAccountInput,
};

export type CreateClientAccountMutation = {
  createClientAccount?:  {
    __typename: "MutationStatus",
    status?: boolean | null,
    id: string,
  } | null,
};

export type DeleteEntityByIdMutationVariables = {
  input: DeleteEntityByIdInput,
};

export type DeleteEntityByIdMutation = {
  deleteEntityById?:  {
    __typename: "MutationStatus",
    status?: boolean | null,
    id: string,
  } | null,
};

export type GenerateCommunicationPdfMutationVariables = {
  input: GenerateCommunicationPdfInput,
};

export type GenerateCommunicationPdfMutation = {
  generateCommunicationPdf?:  {
    __typename: "GenerateCommunicationPdfResult",
    key?: string | null,
    status: boolean,
  } | null,
};

export type GenerateDocumentMutationVariables = {
  input: GenerateDocumentInput,
};

export type GenerateDocumentMutation = {
  generateDocument?:  {
    __typename: "MutationStatus",
    status?: boolean | null,
    id: string,
  } | null,
};

export type ProcessOcrMutationVariables = {
  input: ProcessOcrInput,
};

export type ProcessOcrMutation = {
  processOcr?:  {
    __typename: "MutationStatus",
    status?: boolean | null,
    id: string,
  } | null,
};

export type GenerateReportMutationVariables = {
  input: GenerateReportInput,
};

export type GenerateReportMutation = {
  generateReport?:  {
    __typename: "GenerateReportResult",
    errorCode?: GenerateReportErrorCode | null,
    status: boolean,
  } | null,
};

export type HandleAdvertisementPublishingMutationVariables = {
  input: AdvertisementPublishingInput,
};

export type HandleAdvertisementPublishingMutation = {
  handleAdvertisementPublishing?:  {
    __typename: "AdvertisementPostingMutationStatus",
    id: string,
    statusList?:  Array< {
      __typename: "AdvertisementPostingStatus",
      publishingPlatform: PublishingPlatform,
      status: boolean,
      successCode: number,
    } > | null,
    errorMessage?: string | null,
  } | null,
};

export type HandleUserRightsChangeMutationVariables = {
  input: UserRightsChangeInput,
};

export type HandleUserRightsChangeMutation = {
  handleUserRightsChange?:  {
    __typename: "MutationStatus",
    status?: boolean | null,
    id: string,
  } | null,
};

export type InviteContactToClientAccountMutationVariables = {
  input: InviteContactToClientAccountInput,
};

export type InviteContactToClientAccountMutation = {
  inviteContactToClientAccount?:  {
    __typename: "MutationStatus",
    status?: boolean | null,
    id: string,
  } | null,
};

export type InviteUserToClientAccountMutationVariables = {
  input: InviteUserToClientAccountInput,
};

export type InviteUserToClientAccountMutation = {
  inviteUserToClientAccount?:  {
    __typename: "MutationStatus",
    status?: boolean | null,
    id: string,
  } | null,
};

export type IsEmailAlreadyTakenMutationVariables = {
  input: IsEmailAlreadyTakenInput,
};

export type IsEmailAlreadyTakenMutation = {
  isEmailAlreadyTaken?:  {
    __typename: "StatusResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type RecalculateStatementMutationVariables = {
  input: RecalculateStatementInput,
};

export type RecalculateStatementMutation = {
  recalculateStatement?:  {
    __typename: "MutationStatus",
    status?: boolean | null,
    id: string,
  } | null,
};

export type RejectDocumentMutationVariables = {
  input: RejectDocumentInput,
};

export type RejectDocumentMutation = {
  rejectDocument?:  {
    __typename: "MutationStatus",
    status?: boolean | null,
    id: string,
  } | null,
};

export type RevokePontoIntegrationMutationVariables = {
  input: RevokePontoIntegrationInput,
};

export type RevokePontoIntegrationMutation = {
  revokePontoIntegration?:  {
    __typename: "MutationStatus",
    status?: boolean | null,
    id: string,
  } | null,
};

export type UpdateUserCustomMutationVariables = {
  input: MutationUserInput,
};

export type UpdateUserCustomMutation = {
  updateUserCustom?:  {
    __typename: "StatusResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type VerifyPendingSendingSourceMutationVariables = {
  input: VerifyPendingSendingSourceInput,
};

export type VerifyPendingSendingSourceMutation = {
  verifyPendingSendingSource?:  {
    __typename: "MutationStatus",
    status?: boolean | null,
    id: string,
  } | null,
};

export type VerifyPontoIntegrationMutationVariables = {
  input: VerifyPontoIntegrationInput,
};

export type VerifyPontoIntegrationMutation = {
  verifyPontoIntegration?:  {
    __typename: "MutationStatus",
    status?: boolean | null,
    id: string,
  } | null,
};

export type GetDownloadZipFileUrlMutationVariables = {
  input: DownloadFoldersInput,
};

export type GetDownloadZipFileUrlMutation = {
  getDownloadZipFileUrl?:  {
    __typename: "StatusResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type CreateAccountLabelMutationVariables = {
  input: CreateAccountLabelInput,
  condition?: ModelAccountLabelConditionInput | null,
};

export type CreateAccountLabelMutation = {
  createAccountLabel?:  {
    __typename: "AccountLabel",
    id: string,
    labels?:  Array< {
      __typename: "CustomLabel",
      language: string,
      label: string,
    } > | null,
    bankAccountId?: string | null,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    class: number,
    topClass: number,
    customUsage?: AccountLabelCustomUsage | null,
    disabled?: boolean | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAccountLabelMutationVariables = {
  input: UpdateAccountLabelInput,
  condition?: ModelAccountLabelConditionInput | null,
};

export type UpdateAccountLabelMutation = {
  updateAccountLabel?:  {
    __typename: "AccountLabel",
    id: string,
    labels?:  Array< {
      __typename: "CustomLabel",
      language: string,
      label: string,
    } > | null,
    bankAccountId?: string | null,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    class: number,
    topClass: number,
    customUsage?: AccountLabelCustomUsage | null,
    disabled?: boolean | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAccountLabelMutationVariables = {
  input: DeleteAccountLabelInput,
  condition?: ModelAccountLabelConditionInput | null,
};

export type DeleteAccountLabelMutation = {
  deleteAccountLabel?:  {
    __typename: "AccountLabel",
    id: string,
    labels?:  Array< {
      __typename: "CustomLabel",
      language: string,
      label: string,
    } > | null,
    bankAccountId?: string | null,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    class: number,
    topClass: number,
    customUsage?: AccountLabelCustomUsage | null,
    disabled?: boolean | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAddressMutationVariables = {
  input: CreateAddressInput,
  condition?: ModelAddressConditionInput | null,
};

export type CreateAddressMutation = {
  createAddress?:  {
    __typename: "Address",
    id: string,
    status?: AddressStatus | null,
    street: string,
    number?: string | null,
    box?: string | null,
    postalCode?: string | null,
    city?: string | null,
    region?: string | null,
    country?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    latitude?: number | null,
    longitude?: number | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAddressMutationVariables = {
  input: UpdateAddressInput,
  condition?: ModelAddressConditionInput | null,
};

export type UpdateAddressMutation = {
  updateAddress?:  {
    __typename: "Address",
    id: string,
    status?: AddressStatus | null,
    street: string,
    number?: string | null,
    box?: string | null,
    postalCode?: string | null,
    city?: string | null,
    region?: string | null,
    country?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    latitude?: number | null,
    longitude?: number | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAddressMutationVariables = {
  input: DeleteAddressInput,
  condition?: ModelAddressConditionInput | null,
};

export type DeleteAddressMutation = {
  deleteAddress?:  {
    __typename: "Address",
    id: string,
    status?: AddressStatus | null,
    street: string,
    number?: string | null,
    box?: string | null,
    postalCode?: string | null,
    city?: string | null,
    region?: string | null,
    country?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    latitude?: number | null,
    longitude?: number | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAdvertisementMutationVariables = {
  input: CreateAdvertisementInput,
  condition?: ModelAdvertisementConditionInput | null,
};

export type CreateAdvertisementMutation = {
  createAdvertisement?:  {
    __typename: "Advertisement",
    id: string,
    type?: AdvertisementUnitType | null,
    subType?: AdvertisementUnitSubType | null,
    amenities?: Array< AmenitiesType > | null,
    amountOfFacades?: number | null,
    availabilityDate?: string | null,
    certificateOfConformity?: CertificateOfConformityType | null,
    citationForPlanningOffence?: boolean | null,
    descriptions?:  Array< {
      __typename: "AdvertisementDescription",
      language: string,
      description?: string | null,
    } > | null,
    delimitedZone?: DelimitedZoneType | null,
    floodProneArea?: FloodProneAreaType | null,
    furnished?: boolean | null,
    firstOccupancy?: boolean | null,
    gardenSurface?: number | null,
    heatingAmenities?: Array< HeatingAmenities > | null,
    heatingType?: HeatingType | null,
    landTerrain?: Array< LandTerrainType > | null,
    landArea?: number | null,
    length?: number | null,
    nearbyComodities?:  Array< {
      __typename: "NearbyComodity",
      type: NearbyCommoditiesTypes,
      name: string,
      distance: number,
    } > | null,
    publishedPlatforms?:  Array< {
      __typename: "PublishedPlatform",
      name: PublishingPlatform,
      publishedDate: string,
      id: string,
    } > | null,
    planningPermission?: boolean | null,
    publishCadastralIncome?: boolean | null,
    resort?: boolean | null,
    rightOfPreemption?: boolean | null,
    subdivisionAuthorization?: boolean | null,
    wheelChairAccessible?: boolean | null,
    width?: number | null,
    terraceSurface?: number | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAdvertisementMutationVariables = {
  input: UpdateAdvertisementInput,
  condition?: ModelAdvertisementConditionInput | null,
};

export type UpdateAdvertisementMutation = {
  updateAdvertisement?:  {
    __typename: "Advertisement",
    id: string,
    type?: AdvertisementUnitType | null,
    subType?: AdvertisementUnitSubType | null,
    amenities?: Array< AmenitiesType > | null,
    amountOfFacades?: number | null,
    availabilityDate?: string | null,
    certificateOfConformity?: CertificateOfConformityType | null,
    citationForPlanningOffence?: boolean | null,
    descriptions?:  Array< {
      __typename: "AdvertisementDescription",
      language: string,
      description?: string | null,
    } > | null,
    delimitedZone?: DelimitedZoneType | null,
    floodProneArea?: FloodProneAreaType | null,
    furnished?: boolean | null,
    firstOccupancy?: boolean | null,
    gardenSurface?: number | null,
    heatingAmenities?: Array< HeatingAmenities > | null,
    heatingType?: HeatingType | null,
    landTerrain?: Array< LandTerrainType > | null,
    landArea?: number | null,
    length?: number | null,
    nearbyComodities?:  Array< {
      __typename: "NearbyComodity",
      type: NearbyCommoditiesTypes,
      name: string,
      distance: number,
    } > | null,
    publishedPlatforms?:  Array< {
      __typename: "PublishedPlatform",
      name: PublishingPlatform,
      publishedDate: string,
      id: string,
    } > | null,
    planningPermission?: boolean | null,
    publishCadastralIncome?: boolean | null,
    resort?: boolean | null,
    rightOfPreemption?: boolean | null,
    subdivisionAuthorization?: boolean | null,
    wheelChairAccessible?: boolean | null,
    width?: number | null,
    terraceSurface?: number | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAdvertisementMutationVariables = {
  input: DeleteAdvertisementInput,
  condition?: ModelAdvertisementConditionInput | null,
};

export type DeleteAdvertisementMutation = {
  deleteAdvertisement?:  {
    __typename: "Advertisement",
    id: string,
    type?: AdvertisementUnitType | null,
    subType?: AdvertisementUnitSubType | null,
    amenities?: Array< AmenitiesType > | null,
    amountOfFacades?: number | null,
    availabilityDate?: string | null,
    certificateOfConformity?: CertificateOfConformityType | null,
    citationForPlanningOffence?: boolean | null,
    descriptions?:  Array< {
      __typename: "AdvertisementDescription",
      language: string,
      description?: string | null,
    } > | null,
    delimitedZone?: DelimitedZoneType | null,
    floodProneArea?: FloodProneAreaType | null,
    furnished?: boolean | null,
    firstOccupancy?: boolean | null,
    gardenSurface?: number | null,
    heatingAmenities?: Array< HeatingAmenities > | null,
    heatingType?: HeatingType | null,
    landTerrain?: Array< LandTerrainType > | null,
    landArea?: number | null,
    length?: number | null,
    nearbyComodities?:  Array< {
      __typename: "NearbyComodity",
      type: NearbyCommoditiesTypes,
      name: string,
      distance: number,
    } > | null,
    publishedPlatforms?:  Array< {
      __typename: "PublishedPlatform",
      name: PublishingPlatform,
      publishedDate: string,
      id: string,
    } > | null,
    planningPermission?: boolean | null,
    publishCadastralIncome?: boolean | null,
    resort?: boolean | null,
    rightOfPreemption?: boolean | null,
    subdivisionAuthorization?: boolean | null,
    wheelChairAccessible?: boolean | null,
    width?: number | null,
    terraceSurface?: number | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAgencyRateMutationVariables = {
  input: CreateAgencyRateInput,
  condition?: ModelAgencyRateConditionInput | null,
};

export type CreateAgencyRateMutation = {
  createAgencyRate?:  {
    __typename: "AgencyRate",
    id: string,
    amountDetails:  Array< {
      __typename: "AgencyRateAmountDetail",
      amountType: AgencyRateAmountDetailType,
      endAmount?: number | null,
      feeApplied: number,
      startAmount: number,
    } >,
    amountIfVacantUnit?: number | null,
    amountMinimumFees?: number | null,
    custom: boolean,
    feesAppliedTo: AgencyRateFeesType,
    includedInAmount?: Array< AgencyRateIncludedAmount > | null,
    name: string,
    owners?:  {
      __typename: "ModelAgencyRateOwnerConnection",
      items:  Array< {
        __typename: "AgencyRateOwner",
        id: string,
        agencyRateId: string,
        contactId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    repartitionType: AgencyRateRepartitionType,
    type: AgencyRateType,
    unitType: Array< UnitType >,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAgencyRateMutationVariables = {
  input: UpdateAgencyRateInput,
  condition?: ModelAgencyRateConditionInput | null,
};

export type UpdateAgencyRateMutation = {
  updateAgencyRate?:  {
    __typename: "AgencyRate",
    id: string,
    amountDetails:  Array< {
      __typename: "AgencyRateAmountDetail",
      amountType: AgencyRateAmountDetailType,
      endAmount?: number | null,
      feeApplied: number,
      startAmount: number,
    } >,
    amountIfVacantUnit?: number | null,
    amountMinimumFees?: number | null,
    custom: boolean,
    feesAppliedTo: AgencyRateFeesType,
    includedInAmount?: Array< AgencyRateIncludedAmount > | null,
    name: string,
    owners?:  {
      __typename: "ModelAgencyRateOwnerConnection",
      items:  Array< {
        __typename: "AgencyRateOwner",
        id: string,
        agencyRateId: string,
        contactId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    repartitionType: AgencyRateRepartitionType,
    type: AgencyRateType,
    unitType: Array< UnitType >,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAgencyRateMutationVariables = {
  input: DeleteAgencyRateInput,
  condition?: ModelAgencyRateConditionInput | null,
};

export type DeleteAgencyRateMutation = {
  deleteAgencyRate?:  {
    __typename: "AgencyRate",
    id: string,
    amountDetails:  Array< {
      __typename: "AgencyRateAmountDetail",
      amountType: AgencyRateAmountDetailType,
      endAmount?: number | null,
      feeApplied: number,
      startAmount: number,
    } >,
    amountIfVacantUnit?: number | null,
    amountMinimumFees?: number | null,
    custom: boolean,
    feesAppliedTo: AgencyRateFeesType,
    includedInAmount?: Array< AgencyRateIncludedAmount > | null,
    name: string,
    owners?:  {
      __typename: "ModelAgencyRateOwnerConnection",
      items:  Array< {
        __typename: "AgencyRateOwner",
        id: string,
        agencyRateId: string,
        contactId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    repartitionType: AgencyRateRepartitionType,
    type: AgencyRateType,
    unitType: Array< UnitType >,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAgencyRateOwnerMutationVariables = {
  input: CreateAgencyRateOwnerInput,
  condition?: ModelAgencyRateOwnerConditionInput | null,
};

export type CreateAgencyRateOwnerMutation = {
  createAgencyRateOwner?:  {
    __typename: "AgencyRateOwner",
    id: string,
    agencyRateId: string,
    contactId: string,
    agencyRate?:  {
      __typename: "AgencyRate",
      id: string,
      amountDetails:  Array< {
        __typename: "AgencyRateAmountDetail",
        amountType: AgencyRateAmountDetailType,
        endAmount?: number | null,
        feeApplied: number,
        startAmount: number,
      } >,
      amountIfVacantUnit?: number | null,
      amountMinimumFees?: number | null,
      custom: boolean,
      feesAppliedTo: AgencyRateFeesType,
      includedInAmount?: Array< AgencyRateIncludedAmount > | null,
      name: string,
      owners?:  {
        __typename: "ModelAgencyRateOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      repartitionType: AgencyRateRepartitionType,
      type: AgencyRateType,
      unitType: Array< UnitType >,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAgencyRateOwnerMutationVariables = {
  input: UpdateAgencyRateOwnerInput,
  condition?: ModelAgencyRateOwnerConditionInput | null,
};

export type UpdateAgencyRateOwnerMutation = {
  updateAgencyRateOwner?:  {
    __typename: "AgencyRateOwner",
    id: string,
    agencyRateId: string,
    contactId: string,
    agencyRate?:  {
      __typename: "AgencyRate",
      id: string,
      amountDetails:  Array< {
        __typename: "AgencyRateAmountDetail",
        amountType: AgencyRateAmountDetailType,
        endAmount?: number | null,
        feeApplied: number,
        startAmount: number,
      } >,
      amountIfVacantUnit?: number | null,
      amountMinimumFees?: number | null,
      custom: boolean,
      feesAppliedTo: AgencyRateFeesType,
      includedInAmount?: Array< AgencyRateIncludedAmount > | null,
      name: string,
      owners?:  {
        __typename: "ModelAgencyRateOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      repartitionType: AgencyRateRepartitionType,
      type: AgencyRateType,
      unitType: Array< UnitType >,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAgencyRateOwnerMutationVariables = {
  input: DeleteAgencyRateOwnerInput,
  condition?: ModelAgencyRateOwnerConditionInput | null,
};

export type DeleteAgencyRateOwnerMutation = {
  deleteAgencyRateOwner?:  {
    __typename: "AgencyRateOwner",
    id: string,
    agencyRateId: string,
    contactId: string,
    agencyRate?:  {
      __typename: "AgencyRate",
      id: string,
      amountDetails:  Array< {
        __typename: "AgencyRateAmountDetail",
        amountType: AgencyRateAmountDetailType,
        endAmount?: number | null,
        feeApplied: number,
        startAmount: number,
      } >,
      amountIfVacantUnit?: number | null,
      amountMinimumFees?: number | null,
      custom: boolean,
      feesAppliedTo: AgencyRateFeesType,
      includedInAmount?: Array< AgencyRateIncludedAmount > | null,
      name: string,
      owners?:  {
        __typename: "ModelAgencyRateOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      repartitionType: AgencyRateRepartitionType,
      type: AgencyRateType,
      unitType: Array< UnitType >,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAgencyRateStatementMutationVariables = {
  input: CreateAgencyRateStatementInput,
  condition?: ModelAgencyRateStatementConditionInput | null,
};

export type CreateAgencyRateStatementMutation = {
  createAgencyRateStatement?:  {
    __typename: "AgencyRateStatement",
    id: string,
    agencyRate?:  {
      __typename: "AgencyRate",
      id: string,
      amountDetails:  Array< {
        __typename: "AgencyRateAmountDetail",
        amountType: AgencyRateAmountDetailType,
        endAmount?: number | null,
        feeApplied: number,
        startAmount: number,
      } >,
      amountIfVacantUnit?: number | null,
      amountMinimumFees?: number | null,
      custom: boolean,
      feesAppliedTo: AgencyRateFeesType,
      includedInAmount?: Array< AgencyRateIncludedAmount > | null,
      name: string,
      owners?:  {
        __typename: "ModelAgencyRateOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      repartitionType: AgencyRateRepartitionType,
      type: AgencyRateType,
      unitType: Array< UnitType >,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    agencyRateId: string,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId: string,
    unitAmounts?:  Array< {
      __typename: "AgencyRateStatementAmount",
      unitId?: string | null,
      amountRateable: number,
      fixedAmount: number,
    } > | null,
    totalAmountRateable: number,
    totalFixedAmount: number,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAgencyRateStatementMutationVariables = {
  input: UpdateAgencyRateStatementInput,
  condition?: ModelAgencyRateStatementConditionInput | null,
};

export type UpdateAgencyRateStatementMutation = {
  updateAgencyRateStatement?:  {
    __typename: "AgencyRateStatement",
    id: string,
    agencyRate?:  {
      __typename: "AgencyRate",
      id: string,
      amountDetails:  Array< {
        __typename: "AgencyRateAmountDetail",
        amountType: AgencyRateAmountDetailType,
        endAmount?: number | null,
        feeApplied: number,
        startAmount: number,
      } >,
      amountIfVacantUnit?: number | null,
      amountMinimumFees?: number | null,
      custom: boolean,
      feesAppliedTo: AgencyRateFeesType,
      includedInAmount?: Array< AgencyRateIncludedAmount > | null,
      name: string,
      owners?:  {
        __typename: "ModelAgencyRateOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      repartitionType: AgencyRateRepartitionType,
      type: AgencyRateType,
      unitType: Array< UnitType >,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    agencyRateId: string,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId: string,
    unitAmounts?:  Array< {
      __typename: "AgencyRateStatementAmount",
      unitId?: string | null,
      amountRateable: number,
      fixedAmount: number,
    } > | null,
    totalAmountRateable: number,
    totalFixedAmount: number,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAgencyRateStatementMutationVariables = {
  input: DeleteAgencyRateStatementInput,
  condition?: ModelAgencyRateStatementConditionInput | null,
};

export type DeleteAgencyRateStatementMutation = {
  deleteAgencyRateStatement?:  {
    __typename: "AgencyRateStatement",
    id: string,
    agencyRate?:  {
      __typename: "AgencyRate",
      id: string,
      amountDetails:  Array< {
        __typename: "AgencyRateAmountDetail",
        amountType: AgencyRateAmountDetailType,
        endAmount?: number | null,
        feeApplied: number,
        startAmount: number,
      } >,
      amountIfVacantUnit?: number | null,
      amountMinimumFees?: number | null,
      custom: boolean,
      feesAppliedTo: AgencyRateFeesType,
      includedInAmount?: Array< AgencyRateIncludedAmount > | null,
      name: string,
      owners?:  {
        __typename: "ModelAgencyRateOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      repartitionType: AgencyRateRepartitionType,
      type: AgencyRateType,
      unitType: Array< UnitType >,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    agencyRateId: string,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId: string,
    unitAmounts?:  Array< {
      __typename: "AgencyRateStatementAmount",
      unitId?: string | null,
      amountRateable: number,
      fixedAmount: number,
    } > | null,
    totalAmountRateable: number,
    totalFixedAmount: number,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBankAccountMutationVariables = {
  input: CreateBankAccountInput,
  condition?: ModelBankAccountConditionInput | null,
};

export type CreateBankAccountMutation = {
  createBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    number: string,
    bicNumber?: string | null,
    bankName?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    disabled?: boolean | null,
    integrations?: Array< IntegrationName > | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBankAccountMutationVariables = {
  input: UpdateBankAccountInput,
  condition?: ModelBankAccountConditionInput | null,
};

export type UpdateBankAccountMutation = {
  updateBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    number: string,
    bicNumber?: string | null,
    bankName?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    disabled?: boolean | null,
    integrations?: Array< IntegrationName > | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBankAccountMutationVariables = {
  input: DeleteBankAccountInput,
  condition?: ModelBankAccountConditionInput | null,
};

export type DeleteBankAccountMutation = {
  deleteBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    number: string,
    bicNumber?: string | null,
    bankName?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    disabled?: boolean | null,
    integrations?: Array< IntegrationName > | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBuildingMutationVariables = {
  input: CreateBuildingInput,
  condition?: ModelBuildingConditionInput | null,
};

export type CreateBuildingMutation = {
  createBuilding?:  {
    __typename: "Building",
    id: string,
    name: string,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    acquisitionValue?: number | null,
    acquisitionAdditionalCostsValue?: number | null,
    acquisitionAdvertisedValue?: number | null,
    amountOfFacades?: number | null,
    amountOfFloors?: number | null,
    amountOfHousings?: number | null,
    buildingState?: BuildingState | null,
    cadastralIncome?: number | null,
    constructionYear?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    janitor?: boolean | null,
    jointOwnershipId?: string | null,
    owners?:  {
      __typename: "ModelBuildingOwnerConnection",
      items:  Array< {
        __typename: "BuildingOwner",
        id: string,
        buildingId: string,
        contactId: string,
        disabled?: boolean | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    propertyTax?: number | null,
    residenceName?: string | null,
    units?:  {
      __typename: "ModelUnitConnection",
      items:  Array< {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelBuildingEventConnection",
      items:  Array< {
        __typename: "BuildingEvent",
        id: string,
        buildingId: string,
        type: string,
        startDate: string,
        endDate: string,
        totalCost?: number | null,
        note?: string | null,
        writers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    teams?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBuildingMutationVariables = {
  input: UpdateBuildingInput,
  condition?: ModelBuildingConditionInput | null,
};

export type UpdateBuildingMutation = {
  updateBuilding?:  {
    __typename: "Building",
    id: string,
    name: string,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    acquisitionValue?: number | null,
    acquisitionAdditionalCostsValue?: number | null,
    acquisitionAdvertisedValue?: number | null,
    amountOfFacades?: number | null,
    amountOfFloors?: number | null,
    amountOfHousings?: number | null,
    buildingState?: BuildingState | null,
    cadastralIncome?: number | null,
    constructionYear?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    janitor?: boolean | null,
    jointOwnershipId?: string | null,
    owners?:  {
      __typename: "ModelBuildingOwnerConnection",
      items:  Array< {
        __typename: "BuildingOwner",
        id: string,
        buildingId: string,
        contactId: string,
        disabled?: boolean | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    propertyTax?: number | null,
    residenceName?: string | null,
    units?:  {
      __typename: "ModelUnitConnection",
      items:  Array< {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelBuildingEventConnection",
      items:  Array< {
        __typename: "BuildingEvent",
        id: string,
        buildingId: string,
        type: string,
        startDate: string,
        endDate: string,
        totalCost?: number | null,
        note?: string | null,
        writers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    teams?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBuildingMutationVariables = {
  input: DeleteBuildingInput,
  condition?: ModelBuildingConditionInput | null,
};

export type DeleteBuildingMutation = {
  deleteBuilding?:  {
    __typename: "Building",
    id: string,
    name: string,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    acquisitionValue?: number | null,
    acquisitionAdditionalCostsValue?: number | null,
    acquisitionAdvertisedValue?: number | null,
    amountOfFacades?: number | null,
    amountOfFloors?: number | null,
    amountOfHousings?: number | null,
    buildingState?: BuildingState | null,
    cadastralIncome?: number | null,
    constructionYear?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    janitor?: boolean | null,
    jointOwnershipId?: string | null,
    owners?:  {
      __typename: "ModelBuildingOwnerConnection",
      items:  Array< {
        __typename: "BuildingOwner",
        id: string,
        buildingId: string,
        contactId: string,
        disabled?: boolean | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    propertyTax?: number | null,
    residenceName?: string | null,
    units?:  {
      __typename: "ModelUnitConnection",
      items:  Array< {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelBuildingEventConnection",
      items:  Array< {
        __typename: "BuildingEvent",
        id: string,
        buildingId: string,
        type: string,
        startDate: string,
        endDate: string,
        totalCost?: number | null,
        note?: string | null,
        writers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    teams?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBuildingEventMutationVariables = {
  input: CreateBuildingEventInput,
  condition?: ModelBuildingEventConditionInput | null,
};

export type CreateBuildingEventMutation = {
  createBuildingEvent?:  {
    __typename: "BuildingEvent",
    id: string,
    buildingId: string,
    type: string,
    startDate: string,
    endDate: string,
    totalCost?: number | null,
    note?: string | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBuildingEventMutationVariables = {
  input: UpdateBuildingEventInput,
  condition?: ModelBuildingEventConditionInput | null,
};

export type UpdateBuildingEventMutation = {
  updateBuildingEvent?:  {
    __typename: "BuildingEvent",
    id: string,
    buildingId: string,
    type: string,
    startDate: string,
    endDate: string,
    totalCost?: number | null,
    note?: string | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBuildingEventMutationVariables = {
  input: DeleteBuildingEventInput,
  condition?: ModelBuildingEventConditionInput | null,
};

export type DeleteBuildingEventMutation = {
  deleteBuildingEvent?:  {
    __typename: "BuildingEvent",
    id: string,
    buildingId: string,
    type: string,
    startDate: string,
    endDate: string,
    totalCost?: number | null,
    note?: string | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateBuildingOwnerMutationVariables = {
  input: CreateBuildingOwnerInput,
  condition?: ModelBuildingOwnerConditionInput | null,
};

export type CreateBuildingOwnerMutation = {
  createBuildingOwner?:  {
    __typename: "BuildingOwner",
    id: string,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId: string,
    disabled?: boolean | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    periodFrom?: string | null,
    periodTo?: string | null,
    stake: number,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBuildingOwnerMutationVariables = {
  input: UpdateBuildingOwnerInput,
  condition?: ModelBuildingOwnerConditionInput | null,
};

export type UpdateBuildingOwnerMutation = {
  updateBuildingOwner?:  {
    __typename: "BuildingOwner",
    id: string,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId: string,
    disabled?: boolean | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    periodFrom?: string | null,
    periodTo?: string | null,
    stake: number,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBuildingOwnerMutationVariables = {
  input: DeleteBuildingOwnerInput,
  condition?: ModelBuildingOwnerConditionInput | null,
};

export type DeleteBuildingOwnerMutation = {
  deleteBuildingOwner?:  {
    __typename: "BuildingOwner",
    id: string,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId: string,
    disabled?: boolean | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    periodFrom?: string | null,
    periodTo?: string | null,
    stake: number,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateChargeMutationVariables = {
  input: CreateChargeInput,
  condition?: ModelChargeConditionInput | null,
};

export type CreateChargeMutation = {
  createCharge?:  {
    __typename: "Charge",
    id: string,
    status: ChargeStatus,
    invoiceDate?: string | null,
    paymentDueDate?: string | null,
    archivedDate?: string | null,
    periodFrom?: string | null,
    periodTo?: string | null,
    totalAmount?: number | null,
    source: ChargeSource,
    draftFromOcr?: boolean | null,
    accountingDocumentId?: string | null,
    paid: boolean,
    remittanceInformation?: string | null,
    note?: string | null,
    createdAt: string,
    creatorEmail?: string | null,
    createdById?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contractorId?: string | null,
    contractor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    proposedContractors?:  Array< {
      __typename: "ProposedValue",
      id: string,
      confidence?: number | null,
    } > | null,
    recipientId?: string | null,
    recipient?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    proposedRecipients?:  Array< {
      __typename: "ProposedValue",
      id: string,
      confidence?: number | null,
    } > | null,
    documentId: string,
    document?:  {
      __typename: "File",
      id: string,
      bucket: string,
      createdAt?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        entity?: string | null,
        fileCategory: FileCategory,
        parentId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categoryId: string,
      foreignKey: string,
      foreignTableName: string,
      key: string,
      mimeType?: string | null,
      region: string,
      signaturePositions?:  Array< {
        __typename: "SignaturePosition",
        top: number,
        left: number,
        page: number,
        width?: number | null,
        height?: number | null,
        contactId: string,
      } > | null,
      size?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    postings?:  {
      __typename: "ModelPostingConnection",
      items:  Array< {
        __typename: "Posting",
        id: string,
        createdAt?: string | null,
        class: number,
        customLabel?: string | null,
        topClass: number,
        type: PostingType,
        totalAmount: number,
        periodFrom: string,
        periodTo: string,
        buildingId?: string | null,
        unitId?: string | null,
        invoiceId?: string | null,
        chargeId?: string | null,
        transactionId?: string | null,
        leaseId?: string | null,
        bankAccountId?: string | null,
        accountLabelId?: string | null,
        contactId?: string | null,
        leaseVariousOperationId?: string | null,
        statementId?: string | null,
        amountVatExcluded?: number | null,
        vatAmount?: number | null,
        vatRate?: number | null,
        foreignId?: string | null,
        foreignType?: PostingForeignType | null,
        invoicePostingId?: string | null,
        reconciledAt?: string | null,
        status?: PostingStatus | null,
        chargeOwnerRepartition?: number | null,
        chargeRepartitionMetric?: string | null,
        chargeRepartitionValue?: number | null,
        chargeSplitNumber?: number | null,
        chargeType?: PostingChargeType | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateChargeMutationVariables = {
  input: UpdateChargeInput,
  condition?: ModelChargeConditionInput | null,
};

export type UpdateChargeMutation = {
  updateCharge?:  {
    __typename: "Charge",
    id: string,
    status: ChargeStatus,
    invoiceDate?: string | null,
    paymentDueDate?: string | null,
    archivedDate?: string | null,
    periodFrom?: string | null,
    periodTo?: string | null,
    totalAmount?: number | null,
    source: ChargeSource,
    draftFromOcr?: boolean | null,
    accountingDocumentId?: string | null,
    paid: boolean,
    remittanceInformation?: string | null,
    note?: string | null,
    createdAt: string,
    creatorEmail?: string | null,
    createdById?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contractorId?: string | null,
    contractor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    proposedContractors?:  Array< {
      __typename: "ProposedValue",
      id: string,
      confidence?: number | null,
    } > | null,
    recipientId?: string | null,
    recipient?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    proposedRecipients?:  Array< {
      __typename: "ProposedValue",
      id: string,
      confidence?: number | null,
    } > | null,
    documentId: string,
    document?:  {
      __typename: "File",
      id: string,
      bucket: string,
      createdAt?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        entity?: string | null,
        fileCategory: FileCategory,
        parentId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categoryId: string,
      foreignKey: string,
      foreignTableName: string,
      key: string,
      mimeType?: string | null,
      region: string,
      signaturePositions?:  Array< {
        __typename: "SignaturePosition",
        top: number,
        left: number,
        page: number,
        width?: number | null,
        height?: number | null,
        contactId: string,
      } > | null,
      size?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    postings?:  {
      __typename: "ModelPostingConnection",
      items:  Array< {
        __typename: "Posting",
        id: string,
        createdAt?: string | null,
        class: number,
        customLabel?: string | null,
        topClass: number,
        type: PostingType,
        totalAmount: number,
        periodFrom: string,
        periodTo: string,
        buildingId?: string | null,
        unitId?: string | null,
        invoiceId?: string | null,
        chargeId?: string | null,
        transactionId?: string | null,
        leaseId?: string | null,
        bankAccountId?: string | null,
        accountLabelId?: string | null,
        contactId?: string | null,
        leaseVariousOperationId?: string | null,
        statementId?: string | null,
        amountVatExcluded?: number | null,
        vatAmount?: number | null,
        vatRate?: number | null,
        foreignId?: string | null,
        foreignType?: PostingForeignType | null,
        invoicePostingId?: string | null,
        reconciledAt?: string | null,
        status?: PostingStatus | null,
        chargeOwnerRepartition?: number | null,
        chargeRepartitionMetric?: string | null,
        chargeRepartitionValue?: number | null,
        chargeSplitNumber?: number | null,
        chargeType?: PostingChargeType | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteChargeMutationVariables = {
  input: DeleteChargeInput,
  condition?: ModelChargeConditionInput | null,
};

export type DeleteChargeMutation = {
  deleteCharge?:  {
    __typename: "Charge",
    id: string,
    status: ChargeStatus,
    invoiceDate?: string | null,
    paymentDueDate?: string | null,
    archivedDate?: string | null,
    periodFrom?: string | null,
    periodTo?: string | null,
    totalAmount?: number | null,
    source: ChargeSource,
    draftFromOcr?: boolean | null,
    accountingDocumentId?: string | null,
    paid: boolean,
    remittanceInformation?: string | null,
    note?: string | null,
    createdAt: string,
    creatorEmail?: string | null,
    createdById?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contractorId?: string | null,
    contractor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    proposedContractors?:  Array< {
      __typename: "ProposedValue",
      id: string,
      confidence?: number | null,
    } > | null,
    recipientId?: string | null,
    recipient?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    proposedRecipients?:  Array< {
      __typename: "ProposedValue",
      id: string,
      confidence?: number | null,
    } > | null,
    documentId: string,
    document?:  {
      __typename: "File",
      id: string,
      bucket: string,
      createdAt?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        entity?: string | null,
        fileCategory: FileCategory,
        parentId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categoryId: string,
      foreignKey: string,
      foreignTableName: string,
      key: string,
      mimeType?: string | null,
      region: string,
      signaturePositions?:  Array< {
        __typename: "SignaturePosition",
        top: number,
        left: number,
        page: number,
        width?: number | null,
        height?: number | null,
        contactId: string,
      } > | null,
      size?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    postings?:  {
      __typename: "ModelPostingConnection",
      items:  Array< {
        __typename: "Posting",
        id: string,
        createdAt?: string | null,
        class: number,
        customLabel?: string | null,
        topClass: number,
        type: PostingType,
        totalAmount: number,
        periodFrom: string,
        periodTo: string,
        buildingId?: string | null,
        unitId?: string | null,
        invoiceId?: string | null,
        chargeId?: string | null,
        transactionId?: string | null,
        leaseId?: string | null,
        bankAccountId?: string | null,
        accountLabelId?: string | null,
        contactId?: string | null,
        leaseVariousOperationId?: string | null,
        statementId?: string | null,
        amountVatExcluded?: number | null,
        vatAmount?: number | null,
        vatRate?: number | null,
        foreignId?: string | null,
        foreignType?: PostingForeignType | null,
        invoicePostingId?: string | null,
        reconciledAt?: string | null,
        status?: PostingStatus | null,
        chargeOwnerRepartition?: number | null,
        chargeRepartitionMetric?: string | null,
        chargeRepartitionValue?: number | null,
        chargeSplitNumber?: number | null,
        chargeType?: PostingChargeType | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateChargeStatementMutationVariables = {
  input: CreateChargeStatementInput,
  condition?: ModelChargeStatementConditionInput | null,
};

export type CreateChargeStatementMutation = {
  createChargeStatement?:  {
    __typename: "ChargeStatement",
    id: string,
    charge?:  {
      __typename: "Charge",
      id: string,
      status: ChargeStatus,
      invoiceDate?: string | null,
      paymentDueDate?: string | null,
      archivedDate?: string | null,
      periodFrom?: string | null,
      periodTo?: string | null,
      totalAmount?: number | null,
      source: ChargeSource,
      draftFromOcr?: boolean | null,
      accountingDocumentId?: string | null,
      paid: boolean,
      remittanceInformation?: string | null,
      note?: string | null,
      createdAt: string,
      creatorEmail?: string | null,
      createdById?: string | null,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contractorId?: string | null,
      contractor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedContractors?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      recipientId?: string | null,
      recipient?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedRecipients?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      documentId: string,
      document?:  {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      postings?:  {
        __typename: "ModelPostingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    chargeId: string,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateChargeStatementMutationVariables = {
  input: UpdateChargeStatementInput,
  condition?: ModelChargeStatementConditionInput | null,
};

export type UpdateChargeStatementMutation = {
  updateChargeStatement?:  {
    __typename: "ChargeStatement",
    id: string,
    charge?:  {
      __typename: "Charge",
      id: string,
      status: ChargeStatus,
      invoiceDate?: string | null,
      paymentDueDate?: string | null,
      archivedDate?: string | null,
      periodFrom?: string | null,
      periodTo?: string | null,
      totalAmount?: number | null,
      source: ChargeSource,
      draftFromOcr?: boolean | null,
      accountingDocumentId?: string | null,
      paid: boolean,
      remittanceInformation?: string | null,
      note?: string | null,
      createdAt: string,
      creatorEmail?: string | null,
      createdById?: string | null,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contractorId?: string | null,
      contractor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedContractors?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      recipientId?: string | null,
      recipient?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedRecipients?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      documentId: string,
      document?:  {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      postings?:  {
        __typename: "ModelPostingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    chargeId: string,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteChargeStatementMutationVariables = {
  input: DeleteChargeStatementInput,
  condition?: ModelChargeStatementConditionInput | null,
};

export type DeleteChargeStatementMutation = {
  deleteChargeStatement?:  {
    __typename: "ChargeStatement",
    id: string,
    charge?:  {
      __typename: "Charge",
      id: string,
      status: ChargeStatus,
      invoiceDate?: string | null,
      paymentDueDate?: string | null,
      archivedDate?: string | null,
      periodFrom?: string | null,
      periodTo?: string | null,
      totalAmount?: number | null,
      source: ChargeSource,
      draftFromOcr?: boolean | null,
      accountingDocumentId?: string | null,
      paid: boolean,
      remittanceInformation?: string | null,
      note?: string | null,
      createdAt: string,
      creatorEmail?: string | null,
      createdById?: string | null,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contractorId?: string | null,
      contractor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedContractors?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      recipientId?: string | null,
      recipient?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedRecipients?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      documentId: string,
      document?:  {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      postings?:  {
        __typename: "ModelPostingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    chargeId: string,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateClientMutationVariables = {
  input: CreateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type CreateClientMutation = {
  createClient?:  {
    __typename: "Client",
    id: string,
    accountType: AccountType,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    generalConditionsAcceptDate: string,
    lastGeneralConditionsAcceptedVersion: string,
    GDPRAcceptDate: string,
    lastGDPRAcceptedVersion: string,
    chargeEmail?: string | null,
    issueEmail?: string | null,
    chargeEmailFromSendingSource?: string | null,
    issueEmailFromSendingSource?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateClientMutationVariables = {
  input: UpdateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type UpdateClientMutation = {
  updateClient?:  {
    __typename: "Client",
    id: string,
    accountType: AccountType,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    generalConditionsAcceptDate: string,
    lastGeneralConditionsAcceptedVersion: string,
    GDPRAcceptDate: string,
    lastGDPRAcceptedVersion: string,
    chargeEmail?: string | null,
    issueEmail?: string | null,
    chargeEmailFromSendingSource?: string | null,
    issueEmailFromSendingSource?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteClientMutationVariables = {
  input: DeleteClientInput,
  condition?: ModelClientConditionInput | null,
};

export type DeleteClientMutation = {
  deleteClient?:  {
    __typename: "Client",
    id: string,
    accountType: AccountType,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    generalConditionsAcceptDate: string,
    lastGeneralConditionsAcceptedVersion: string,
    GDPRAcceptDate: string,
    lastGDPRAcceptedVersion: string,
    chargeEmail?: string | null,
    issueEmail?: string | null,
    chargeEmailFromSendingSource?: string | null,
    issueEmailFromSendingSource?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCommunicationMutationVariables = {
  input: CreateCommunicationInput,
  condition?: ModelCommunicationConditionInput | null,
};

export type CreateCommunicationMutation = {
  createCommunication?:  {
    __typename: "Communication",
    id: string,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    blockedRecipients?:  Array< {
      __typename: "CommunicationBlockedRecipient",
      recipient: string,
      bounceType: BounceType,
    } > | null,
    body: string,
    buildingId?: string | null,
    channel: CommunicationChannel,
    communicationTemplateId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    createdAt?: string | null,
    expirationDate?: string | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      creditNote?: boolean | null,
      creditNoteLabel?: string | null,
      dueDate?: string | null,
      invoiceDate: string,
      invoiceNumber?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      paid: boolean,
      receiptNumber?: string | null,
      reminderDates?: Array< string > | null,
      remittanceInformation?: string | null,
      statementId?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      type: InvoiceType,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    invoiceId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    leasePriceHistoryId?: string | null,
    leasePriceHistory?:  {
      __typename: "LeasePriceHistory",
      id: string,
      previousTotalRentalPrice: number,
      previousTotalMonthlyCharge: number,
      totalRentalPrice: number,
      optimalTotalRentalPrice?: number | null,
      totalMonthlyCharges: number,
      optimalTotalMonthlyCharges?: number | null,
      applicationDate: string,
      status: LeaseAmountUpdateStatus,
      comment?: string | null,
      amountDetails:  Array< {
        __typename: "LeasePriceHistoryDetail",
        unitId: string,
        indexationBaseIndex?: number | null,
        indexationNewIndex?: number | null,
        previousRentalPrice: number,
        newRentalPrice: number,
        rentalStatus: LeasePriceHistoryStatus,
        previousMonthlyChargesPrice: number,
        newMonthlyChargesPrice: number,
        monthlyChargesStatus: LeasePriceHistoryStatus,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        optimalNewRentalPrice?: number | null,
        optimalNewMonthlyChargesPrice?: number | null,
        epb?: string | null,
        epbIssueDate?: string | null,
        oldestIndexForCorrectionFactorCalculation?: number | null,
        newestIndexForCorrectionFactorCalculation?: number | null,
        rentIndexedPriceAfterNewEpbReduction?: number | null,
        chargesIndexedPriceAfterNewEpbReduction?: number | null,
        correctionFactor?: number | null,
        reductionPercentage?: number | null,
        rentDifference?: number | null,
        rentReduction?: number | null,
        indexedRentAfterReduction?: number | null,
        chargesDifference?: number | null,
        chargesReduction?: number | null,
        indexedChargesAfterReduction?: number | null,
        previousYearIndexationNewIndex?: number | null,
        previousYearIndexedRent?: number | null,
        previousYearIndexedCharges?: number | null,
      } >,
      type: LeasePriceHistoryType,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    linkedCommunicationsIds?: Array< string > | null,
    recipient?: string | null,
    recipientInBcc?: string | null,
    recipientInCc?: string | null,
    sender?: string | null,
    sentAt?: string | null,
    externalId?: string | null,
    signatureDocumentId?: string | null,
    signatureDocument?:  {
      __typename: "SignatureDocument",
      id: string,
      additionalMessage?: string | null,
      communicationBody?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      fileIds?: Array< string > | null,
      foreignKey: string,
      foreignTableName: string,
      sentAt?: string | null,
      signatureMethods: Array< SignatureType >,
      signatureResults:  Array< {
        __typename: "SignatureDocumentResult",
        openedDate?: string | null,
        signedDate?: string | null,
        signed?: boolean | null,
        signatureLink?: string | null,
        rejectReason?: string | null,
        lastSignInviteSentDate?: string | null,
        trackingProvider?: TrackingProvider | null,
        trackingCode?: string | null,
        contactId: string,
      } >,
      status: SignatureDocumentStatus,
      externalStatus?: string | null,
      type: SignatureDocumentType,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    snoozedDate?: string | null,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId?: string | null,
    status: CommunicationStatus,
    subject: string,
    toSend: boolean,
    type: CommunicationType,
    _ttl?: number | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCommunicationMutationVariables = {
  input: UpdateCommunicationInput,
  condition?: ModelCommunicationConditionInput | null,
};

export type UpdateCommunicationMutation = {
  updateCommunication?:  {
    __typename: "Communication",
    id: string,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    blockedRecipients?:  Array< {
      __typename: "CommunicationBlockedRecipient",
      recipient: string,
      bounceType: BounceType,
    } > | null,
    body: string,
    buildingId?: string | null,
    channel: CommunicationChannel,
    communicationTemplateId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    createdAt?: string | null,
    expirationDate?: string | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      creditNote?: boolean | null,
      creditNoteLabel?: string | null,
      dueDate?: string | null,
      invoiceDate: string,
      invoiceNumber?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      paid: boolean,
      receiptNumber?: string | null,
      reminderDates?: Array< string > | null,
      remittanceInformation?: string | null,
      statementId?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      type: InvoiceType,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    invoiceId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    leasePriceHistoryId?: string | null,
    leasePriceHistory?:  {
      __typename: "LeasePriceHistory",
      id: string,
      previousTotalRentalPrice: number,
      previousTotalMonthlyCharge: number,
      totalRentalPrice: number,
      optimalTotalRentalPrice?: number | null,
      totalMonthlyCharges: number,
      optimalTotalMonthlyCharges?: number | null,
      applicationDate: string,
      status: LeaseAmountUpdateStatus,
      comment?: string | null,
      amountDetails:  Array< {
        __typename: "LeasePriceHistoryDetail",
        unitId: string,
        indexationBaseIndex?: number | null,
        indexationNewIndex?: number | null,
        previousRentalPrice: number,
        newRentalPrice: number,
        rentalStatus: LeasePriceHistoryStatus,
        previousMonthlyChargesPrice: number,
        newMonthlyChargesPrice: number,
        monthlyChargesStatus: LeasePriceHistoryStatus,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        optimalNewRentalPrice?: number | null,
        optimalNewMonthlyChargesPrice?: number | null,
        epb?: string | null,
        epbIssueDate?: string | null,
        oldestIndexForCorrectionFactorCalculation?: number | null,
        newestIndexForCorrectionFactorCalculation?: number | null,
        rentIndexedPriceAfterNewEpbReduction?: number | null,
        chargesIndexedPriceAfterNewEpbReduction?: number | null,
        correctionFactor?: number | null,
        reductionPercentage?: number | null,
        rentDifference?: number | null,
        rentReduction?: number | null,
        indexedRentAfterReduction?: number | null,
        chargesDifference?: number | null,
        chargesReduction?: number | null,
        indexedChargesAfterReduction?: number | null,
        previousYearIndexationNewIndex?: number | null,
        previousYearIndexedRent?: number | null,
        previousYearIndexedCharges?: number | null,
      } >,
      type: LeasePriceHistoryType,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    linkedCommunicationsIds?: Array< string > | null,
    recipient?: string | null,
    recipientInBcc?: string | null,
    recipientInCc?: string | null,
    sender?: string | null,
    sentAt?: string | null,
    externalId?: string | null,
    signatureDocumentId?: string | null,
    signatureDocument?:  {
      __typename: "SignatureDocument",
      id: string,
      additionalMessage?: string | null,
      communicationBody?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      fileIds?: Array< string > | null,
      foreignKey: string,
      foreignTableName: string,
      sentAt?: string | null,
      signatureMethods: Array< SignatureType >,
      signatureResults:  Array< {
        __typename: "SignatureDocumentResult",
        openedDate?: string | null,
        signedDate?: string | null,
        signed?: boolean | null,
        signatureLink?: string | null,
        rejectReason?: string | null,
        lastSignInviteSentDate?: string | null,
        trackingProvider?: TrackingProvider | null,
        trackingCode?: string | null,
        contactId: string,
      } >,
      status: SignatureDocumentStatus,
      externalStatus?: string | null,
      type: SignatureDocumentType,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    snoozedDate?: string | null,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId?: string | null,
    status: CommunicationStatus,
    subject: string,
    toSend: boolean,
    type: CommunicationType,
    _ttl?: number | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCommunicationMutationVariables = {
  input: DeleteCommunicationInput,
  condition?: ModelCommunicationConditionInput | null,
};

export type DeleteCommunicationMutation = {
  deleteCommunication?:  {
    __typename: "Communication",
    id: string,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    blockedRecipients?:  Array< {
      __typename: "CommunicationBlockedRecipient",
      recipient: string,
      bounceType: BounceType,
    } > | null,
    body: string,
    buildingId?: string | null,
    channel: CommunicationChannel,
    communicationTemplateId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    createdAt?: string | null,
    expirationDate?: string | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      creditNote?: boolean | null,
      creditNoteLabel?: string | null,
      dueDate?: string | null,
      invoiceDate: string,
      invoiceNumber?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      paid: boolean,
      receiptNumber?: string | null,
      reminderDates?: Array< string > | null,
      remittanceInformation?: string | null,
      statementId?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      type: InvoiceType,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    invoiceId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    leasePriceHistoryId?: string | null,
    leasePriceHistory?:  {
      __typename: "LeasePriceHistory",
      id: string,
      previousTotalRentalPrice: number,
      previousTotalMonthlyCharge: number,
      totalRentalPrice: number,
      optimalTotalRentalPrice?: number | null,
      totalMonthlyCharges: number,
      optimalTotalMonthlyCharges?: number | null,
      applicationDate: string,
      status: LeaseAmountUpdateStatus,
      comment?: string | null,
      amountDetails:  Array< {
        __typename: "LeasePriceHistoryDetail",
        unitId: string,
        indexationBaseIndex?: number | null,
        indexationNewIndex?: number | null,
        previousRentalPrice: number,
        newRentalPrice: number,
        rentalStatus: LeasePriceHistoryStatus,
        previousMonthlyChargesPrice: number,
        newMonthlyChargesPrice: number,
        monthlyChargesStatus: LeasePriceHistoryStatus,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        optimalNewRentalPrice?: number | null,
        optimalNewMonthlyChargesPrice?: number | null,
        epb?: string | null,
        epbIssueDate?: string | null,
        oldestIndexForCorrectionFactorCalculation?: number | null,
        newestIndexForCorrectionFactorCalculation?: number | null,
        rentIndexedPriceAfterNewEpbReduction?: number | null,
        chargesIndexedPriceAfterNewEpbReduction?: number | null,
        correctionFactor?: number | null,
        reductionPercentage?: number | null,
        rentDifference?: number | null,
        rentReduction?: number | null,
        indexedRentAfterReduction?: number | null,
        chargesDifference?: number | null,
        chargesReduction?: number | null,
        indexedChargesAfterReduction?: number | null,
        previousYearIndexationNewIndex?: number | null,
        previousYearIndexedRent?: number | null,
        previousYearIndexedCharges?: number | null,
      } >,
      type: LeasePriceHistoryType,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    linkedCommunicationsIds?: Array< string > | null,
    recipient?: string | null,
    recipientInBcc?: string | null,
    recipientInCc?: string | null,
    sender?: string | null,
    sentAt?: string | null,
    externalId?: string | null,
    signatureDocumentId?: string | null,
    signatureDocument?:  {
      __typename: "SignatureDocument",
      id: string,
      additionalMessage?: string | null,
      communicationBody?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      fileIds?: Array< string > | null,
      foreignKey: string,
      foreignTableName: string,
      sentAt?: string | null,
      signatureMethods: Array< SignatureType >,
      signatureResults:  Array< {
        __typename: "SignatureDocumentResult",
        openedDate?: string | null,
        signedDate?: string | null,
        signed?: boolean | null,
        signatureLink?: string | null,
        rejectReason?: string | null,
        lastSignInviteSentDate?: string | null,
        trackingProvider?: TrackingProvider | null,
        trackingCode?: string | null,
        contactId: string,
      } >,
      status: SignatureDocumentStatus,
      externalStatus?: string | null,
      type: SignatureDocumentType,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    snoozedDate?: string | null,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId?: string | null,
    status: CommunicationStatus,
    subject: string,
    toSend: boolean,
    type: CommunicationType,
    _ttl?: number | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCommunicationSettingsProfileMutationVariables = {
  input: CreateCommunicationSettingsProfileInput,
  condition?: ModelCommunicationSettingsProfileConditionInput | null,
};

export type CreateCommunicationSettingsProfileMutation = {
  createCommunicationSettingsProfile?:  {
    __typename: "CommunicationSettingsProfile",
    id: string,
    communicationsSettings:  Array< {
      __typename: "CommunicationSettings",
      channel: CommunicationChannel,
      communicationType: CommunicationType,
      daysBeforeDueDate?: number | null,
      daysOverdue?: number | null,
      frequency?:  {
        __typename: "CommunicationFrequencySetting",
        dateFrom?: CommunicationFrequencySettingPeriodDateFrom | null,
        periodInterval: number,
        periodType: CommunicationFrequencySettingPeriodType,
      } | null,
      sendingRule: CommunicationSendingRule,
      recipients: Array< CommunicationSettingRecipient >,
      recipientsInBcc: Array< CommunicationSettingRecipient >,
      recipientsInCopy: Array< string >,
    } >,
    createdAt?: string | null,
    defaultName?: string | null,
    isDefaultSetting: boolean,
    referent: CommunicationSettingsProfileReferent,
    replacementId?: string | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCommunicationSettingsProfileMutationVariables = {
  input: UpdateCommunicationSettingsProfileInput,
  condition?: ModelCommunicationSettingsProfileConditionInput | null,
};

export type UpdateCommunicationSettingsProfileMutation = {
  updateCommunicationSettingsProfile?:  {
    __typename: "CommunicationSettingsProfile",
    id: string,
    communicationsSettings:  Array< {
      __typename: "CommunicationSettings",
      channel: CommunicationChannel,
      communicationType: CommunicationType,
      daysBeforeDueDate?: number | null,
      daysOverdue?: number | null,
      frequency?:  {
        __typename: "CommunicationFrequencySetting",
        dateFrom?: CommunicationFrequencySettingPeriodDateFrom | null,
        periodInterval: number,
        periodType: CommunicationFrequencySettingPeriodType,
      } | null,
      sendingRule: CommunicationSendingRule,
      recipients: Array< CommunicationSettingRecipient >,
      recipientsInBcc: Array< CommunicationSettingRecipient >,
      recipientsInCopy: Array< string >,
    } >,
    createdAt?: string | null,
    defaultName?: string | null,
    isDefaultSetting: boolean,
    referent: CommunicationSettingsProfileReferent,
    replacementId?: string | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCommunicationSettingsProfileMutationVariables = {
  input: DeleteCommunicationSettingsProfileInput,
  condition?: ModelCommunicationSettingsProfileConditionInput | null,
};

export type DeleteCommunicationSettingsProfileMutation = {
  deleteCommunicationSettingsProfile?:  {
    __typename: "CommunicationSettingsProfile",
    id: string,
    communicationsSettings:  Array< {
      __typename: "CommunicationSettings",
      channel: CommunicationChannel,
      communicationType: CommunicationType,
      daysBeforeDueDate?: number | null,
      daysOverdue?: number | null,
      frequency?:  {
        __typename: "CommunicationFrequencySetting",
        dateFrom?: CommunicationFrequencySettingPeriodDateFrom | null,
        periodInterval: number,
        periodType: CommunicationFrequencySettingPeriodType,
      } | null,
      sendingRule: CommunicationSendingRule,
      recipients: Array< CommunicationSettingRecipient >,
      recipientsInBcc: Array< CommunicationSettingRecipient >,
      recipientsInCopy: Array< string >,
    } >,
    createdAt?: string | null,
    defaultName?: string | null,
    isDefaultSetting: boolean,
    referent: CommunicationSettingsProfileReferent,
    replacementId?: string | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateContactMutationVariables = {
  input: CreateContactInput,
  condition?: ModelContactConditionInput | null,
};

export type CreateContactMutation = {
  createContact?:  {
    __typename: "Contact",
    id: string,
    createdAt?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    companyName?: string | null,
    commercialName?: string | null,
    communicationSettingsProfileId?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    businessNumber?: string | null,
    types: Array< ContactType >,
    vat?: string | null,
    email?: string | null,
    birthDate?: string | null,
    birthPlace?: string | null,
    nationalRegister?: string | null,
    civility?: CivilityType | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    phoneNumbers?:  Array< {
      __typename: "PhoneNumber",
      countryCode: string,
      number: string,
    } > | null,
    language?: string | null,
    jointOwners?:  Array< {
      __typename: "JointOwner",
      contactId: string,
      contactPerson?: boolean | null,
    } > | null,
    agencyRateDisabled?: boolean | null,
    status?: ContactStatus | null,
    leases?:  {
      __typename: "ModelLeaseContactConnection",
      items:  Array< {
        __typename: "LeaseContact",
        id: string,
        leaseId: string,
        contactId: string,
        contactRole: ContactType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildings?:  {
      __typename: "ModelBuildingOwnerConnection",
      items:  Array< {
        __typename: "BuildingOwner",
        id: string,
        buildingId: string,
        contactId: string,
        disabled?: boolean | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    units?:  {
      __typename: "ModelUnitOwnerConnection",
      items:  Array< {
        __typename: "UnitOwner",
        id: string,
        disabled?: boolean | null,
        ownerId: string,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sendInvitation: boolean,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateContactMutationVariables = {
  input: UpdateContactInput,
  condition?: ModelContactConditionInput | null,
};

export type UpdateContactMutation = {
  updateContact?:  {
    __typename: "Contact",
    id: string,
    createdAt?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    companyName?: string | null,
    commercialName?: string | null,
    communicationSettingsProfileId?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    businessNumber?: string | null,
    types: Array< ContactType >,
    vat?: string | null,
    email?: string | null,
    birthDate?: string | null,
    birthPlace?: string | null,
    nationalRegister?: string | null,
    civility?: CivilityType | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    phoneNumbers?:  Array< {
      __typename: "PhoneNumber",
      countryCode: string,
      number: string,
    } > | null,
    language?: string | null,
    jointOwners?:  Array< {
      __typename: "JointOwner",
      contactId: string,
      contactPerson?: boolean | null,
    } > | null,
    agencyRateDisabled?: boolean | null,
    status?: ContactStatus | null,
    leases?:  {
      __typename: "ModelLeaseContactConnection",
      items:  Array< {
        __typename: "LeaseContact",
        id: string,
        leaseId: string,
        contactId: string,
        contactRole: ContactType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildings?:  {
      __typename: "ModelBuildingOwnerConnection",
      items:  Array< {
        __typename: "BuildingOwner",
        id: string,
        buildingId: string,
        contactId: string,
        disabled?: boolean | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    units?:  {
      __typename: "ModelUnitOwnerConnection",
      items:  Array< {
        __typename: "UnitOwner",
        id: string,
        disabled?: boolean | null,
        ownerId: string,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sendInvitation: boolean,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteContactMutationVariables = {
  input: DeleteContactInput,
  condition?: ModelContactConditionInput | null,
};

export type DeleteContactMutation = {
  deleteContact?:  {
    __typename: "Contact",
    id: string,
    createdAt?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    companyName?: string | null,
    commercialName?: string | null,
    communicationSettingsProfileId?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    businessNumber?: string | null,
    types: Array< ContactType >,
    vat?: string | null,
    email?: string | null,
    birthDate?: string | null,
    birthPlace?: string | null,
    nationalRegister?: string | null,
    civility?: CivilityType | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    phoneNumbers?:  Array< {
      __typename: "PhoneNumber",
      countryCode: string,
      number: string,
    } > | null,
    language?: string | null,
    jointOwners?:  Array< {
      __typename: "JointOwner",
      contactId: string,
      contactPerson?: boolean | null,
    } > | null,
    agencyRateDisabled?: boolean | null,
    status?: ContactStatus | null,
    leases?:  {
      __typename: "ModelLeaseContactConnection",
      items:  Array< {
        __typename: "LeaseContact",
        id: string,
        leaseId: string,
        contactId: string,
        contactRole: ContactType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildings?:  {
      __typename: "ModelBuildingOwnerConnection",
      items:  Array< {
        __typename: "BuildingOwner",
        id: string,
        buildingId: string,
        contactId: string,
        disabled?: boolean | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    units?:  {
      __typename: "ModelUnitOwnerConnection",
      items:  Array< {
        __typename: "UnitOwner",
        id: string,
        disabled?: boolean | null,
        ownerId: string,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sendInvitation: boolean,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCustomFieldMutationVariables = {
  input: CreateCustomFieldInput,
  condition?: ModelCustomFieldConditionInput | null,
};

export type CreateCustomFieldMutation = {
  createCustomField?:  {
    __typename: "CustomField",
    id: string,
    entity: string,
    fieldName: string,
    fieldType: CustomFieldType,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCustomFieldMutationVariables = {
  input: UpdateCustomFieldInput,
  condition?: ModelCustomFieldConditionInput | null,
};

export type UpdateCustomFieldMutation = {
  updateCustomField?:  {
    __typename: "CustomField",
    id: string,
    entity: string,
    fieldName: string,
    fieldType: CustomFieldType,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCustomFieldMutationVariables = {
  input: DeleteCustomFieldInput,
  condition?: ModelCustomFieldConditionInput | null,
};

export type DeleteCustomFieldMutation = {
  deleteCustomField?:  {
    __typename: "CustomField",
    id: string,
    entity: string,
    fieldName: string,
    fieldType: CustomFieldType,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCustomSubscriptionMutationVariables = {
  input: CreateCustomSubscriptionInput,
  condition?: ModelCustomSubscriptionConditionInput | null,
};

export type CreateCustomSubscriptionMutation = {
  createCustomSubscription?:  {
    __typename: "CustomSubscription",
    id: string,
    name: CustomSubscriptionType,
    value: string,
    createdAt?: string | null,
    userId?: string | null,
    _ttl: number,
    updatedAt: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCustomSubscriptionMutationVariables = {
  input: UpdateCustomSubscriptionInput,
  condition?: ModelCustomSubscriptionConditionInput | null,
};

export type UpdateCustomSubscriptionMutation = {
  updateCustomSubscription?:  {
    __typename: "CustomSubscription",
    id: string,
    name: CustomSubscriptionType,
    value: string,
    createdAt?: string | null,
    userId?: string | null,
    _ttl: number,
    updatedAt: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCustomSubscriptionMutationVariables = {
  input: DeleteCustomSubscriptionInput,
  condition?: ModelCustomSubscriptionConditionInput | null,
};

export type DeleteCustomSubscriptionMutation = {
  deleteCustomSubscription?:  {
    __typename: "CustomSubscription",
    id: string,
    name: CustomSubscriptionType,
    value: string,
    createdAt?: string | null,
    userId?: string | null,
    _ttl: number,
    updatedAt: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDocumentCategoryMutationVariables = {
  input: CreateDocumentCategoryInput,
  condition?: ModelDocumentCategoryConditionInput | null,
};

export type CreateDocumentCategoryMutation = {
  createDocumentCategory?:  {
    __typename: "DocumentCategory",
    id: string,
    entity?: string | null,
    fileCategory: FileCategory,
    labels:  Array< {
      __typename: "CustomLabel",
      language: string,
      label: string,
    } >,
    parentId?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDocumentCategoryMutationVariables = {
  input: UpdateDocumentCategoryInput,
  condition?: ModelDocumentCategoryConditionInput | null,
};

export type UpdateDocumentCategoryMutation = {
  updateDocumentCategory?:  {
    __typename: "DocumentCategory",
    id: string,
    entity?: string | null,
    fileCategory: FileCategory,
    labels:  Array< {
      __typename: "CustomLabel",
      language: string,
      label: string,
    } >,
    parentId?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDocumentCategoryMutationVariables = {
  input: DeleteDocumentCategoryInput,
  condition?: ModelDocumentCategoryConditionInput | null,
};

export type DeleteDocumentCategoryMutation = {
  deleteDocumentCategory?:  {
    __typename: "DocumentCategory",
    id: string,
    entity?: string | null,
    fileCategory: FileCategory,
    labels:  Array< {
      __typename: "CustomLabel",
      language: string,
      label: string,
    } >,
    parentId?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFileMutationVariables = {
  input: CreateFileInput,
  condition?: ModelFileConditionInput | null,
};

export type CreateFileMutation = {
  createFile?:  {
    __typename: "File",
    id: string,
    bucket: string,
    createdAt?: string | null,
    category?:  {
      __typename: "DocumentCategory",
      id: string,
      entity?: string | null,
      fileCategory: FileCategory,
      labels:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } >,
      parentId?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categoryId: string,
    foreignKey: string,
    foreignTableName: string,
    key: string,
    mimeType?: string | null,
    region: string,
    signaturePositions?:  Array< {
      __typename: "SignaturePosition",
      top: number,
      left: number,
      page: number,
      width?: number | null,
      height?: number | null,
      contactId: string,
    } > | null,
    size?: number | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFileMutationVariables = {
  input: UpdateFileInput,
  condition?: ModelFileConditionInput | null,
};

export type UpdateFileMutation = {
  updateFile?:  {
    __typename: "File",
    id: string,
    bucket: string,
    createdAt?: string | null,
    category?:  {
      __typename: "DocumentCategory",
      id: string,
      entity?: string | null,
      fileCategory: FileCategory,
      labels:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } >,
      parentId?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categoryId: string,
    foreignKey: string,
    foreignTableName: string,
    key: string,
    mimeType?: string | null,
    region: string,
    signaturePositions?:  Array< {
      __typename: "SignaturePosition",
      top: number,
      left: number,
      page: number,
      width?: number | null,
      height?: number | null,
      contactId: string,
    } > | null,
    size?: number | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFileMutationVariables = {
  input: DeleteFileInput,
  condition?: ModelFileConditionInput | null,
};

export type DeleteFileMutation = {
  deleteFile?:  {
    __typename: "File",
    id: string,
    bucket: string,
    createdAt?: string | null,
    category?:  {
      __typename: "DocumentCategory",
      id: string,
      entity?: string | null,
      fileCategory: FileCategory,
      labels:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } >,
      parentId?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categoryId: string,
    foreignKey: string,
    foreignTableName: string,
    key: string,
    mimeType?: string | null,
    region: string,
    signaturePositions?:  Array< {
      __typename: "SignaturePosition",
      top: number,
      left: number,
      page: number,
      width?: number | null,
      height?: number | null,
      contactId: string,
    } > | null,
    size?: number | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateIntegrationMutationVariables = {
  input: CreateIntegrationInput,
  condition?: ModelIntegrationConditionInput | null,
};

export type CreateIntegrationMutation = {
  createIntegration?:  {
    __typename: "Integration",
    id: string,
    configurations?:  Array< {
      __typename: "IntegrationConfiguration",
      name: string,
      value: string,
    } > | null,
    error?: string | null,
    lastRun?: string | null,
    name: IntegrationName,
    status: IntegrationStatus,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateIntegrationMutationVariables = {
  input: UpdateIntegrationInput,
  condition?: ModelIntegrationConditionInput | null,
};

export type UpdateIntegrationMutation = {
  updateIntegration?:  {
    __typename: "Integration",
    id: string,
    configurations?:  Array< {
      __typename: "IntegrationConfiguration",
      name: string,
      value: string,
    } > | null,
    error?: string | null,
    lastRun?: string | null,
    name: IntegrationName,
    status: IntegrationStatus,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteIntegrationMutationVariables = {
  input: DeleteIntegrationInput,
  condition?: ModelIntegrationConditionInput | null,
};

export type DeleteIntegrationMutation = {
  deleteIntegration?:  {
    __typename: "Integration",
    id: string,
    configurations?:  Array< {
      __typename: "IntegrationConfiguration",
      name: string,
      value: string,
    } > | null,
    error?: string | null,
    lastRun?: string | null,
    name: IntegrationName,
    status: IntegrationStatus,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateInventoryCustomItemMutationVariables = {
  input: CreateInventoryCustomItemInput,
  condition?: ModelInventoryCustomItemConditionInput | null,
};

export type CreateInventoryCustomItemMutation = {
  createInventoryCustomItem?:  {
    __typename: "InventoryCustomItem",
    id: string,
    type: InventoryItemType,
    value: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateInventoryCustomItemMutationVariables = {
  input: UpdateInventoryCustomItemInput,
  condition?: ModelInventoryCustomItemConditionInput | null,
};

export type UpdateInventoryCustomItemMutation = {
  updateInventoryCustomItem?:  {
    __typename: "InventoryCustomItem",
    id: string,
    type: InventoryItemType,
    value: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteInventoryCustomItemMutationVariables = {
  input: DeleteInventoryCustomItemInput,
  condition?: ModelInventoryCustomItemConditionInput | null,
};

export type DeleteInventoryCustomItemMutation = {
  deleteInventoryCustomItem?:  {
    __typename: "InventoryCustomItem",
    id: string,
    type: InventoryItemType,
    value: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateInvoiceMutationVariables = {
  input: CreateInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type CreateInvoiceMutation = {
  createInvoice?:  {
    __typename: "Invoice",
    id: string,
    amount: number,
    bankAccountId: string,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    communicationId?: string | null,
    communication?:  {
      __typename: "Communication",
      id: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      blockedRecipients?:  Array< {
        __typename: "CommunicationBlockedRecipient",
        recipient: string,
        bounceType: BounceType,
      } > | null,
      body: string,
      buildingId?: string | null,
      channel: CommunicationChannel,
      communicationTemplateId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        amount: number,
        bankAccountId: string,
        communicationId?: string | null,
        createdAt: string,
        creditNote?: boolean | null,
        creditNoteLabel?: string | null,
        dueDate?: string | null,
        invoiceDate: string,
        invoiceNumber?: string | null,
        leaseId?: string | null,
        paid: boolean,
        receiptNumber?: string | null,
        reminderDates?: Array< string > | null,
        remittanceInformation?: string | null,
        statementId?: string | null,
        type: InvoiceType,
        unitId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      invoiceId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      leasePriceHistoryId?: string | null,
      leasePriceHistory?:  {
        __typename: "LeasePriceHistory",
        id: string,
        previousTotalRentalPrice: number,
        previousTotalMonthlyCharge: number,
        totalRentalPrice: number,
        optimalTotalRentalPrice?: number | null,
        totalMonthlyCharges: number,
        optimalTotalMonthlyCharges?: number | null,
        applicationDate: string,
        status: LeaseAmountUpdateStatus,
        comment?: string | null,
        type: LeasePriceHistoryType,
        communicationId?: string | null,
        leaseId: string,
        createdAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      linkedCommunicationsIds?: Array< string > | null,
      recipient?: string | null,
      recipientInBcc?: string | null,
      recipientInCc?: string | null,
      sender?: string | null,
      sentAt?: string | null,
      externalId?: string | null,
      signatureDocumentId?: string | null,
      signatureDocument?:  {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      snoozedDate?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId?: string | null,
      status: CommunicationStatus,
      subject: string,
      toSend: boolean,
      type: CommunicationType,
      _ttl?: number | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    creditNote?: boolean | null,
    creditNoteLabel?: string | null,
    dueDate?: string | null,
    invoiceDate: string,
    invoiceNumber?: string | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    paid: boolean,
    receiptNumber?: string | null,
    reminderDates?: Array< string > | null,
    remittanceInformation?: string | null,
    statementId?: string | null,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: InvoiceType,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateInvoiceMutationVariables = {
  input: UpdateInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type UpdateInvoiceMutation = {
  updateInvoice?:  {
    __typename: "Invoice",
    id: string,
    amount: number,
    bankAccountId: string,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    communicationId?: string | null,
    communication?:  {
      __typename: "Communication",
      id: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      blockedRecipients?:  Array< {
        __typename: "CommunicationBlockedRecipient",
        recipient: string,
        bounceType: BounceType,
      } > | null,
      body: string,
      buildingId?: string | null,
      channel: CommunicationChannel,
      communicationTemplateId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        amount: number,
        bankAccountId: string,
        communicationId?: string | null,
        createdAt: string,
        creditNote?: boolean | null,
        creditNoteLabel?: string | null,
        dueDate?: string | null,
        invoiceDate: string,
        invoiceNumber?: string | null,
        leaseId?: string | null,
        paid: boolean,
        receiptNumber?: string | null,
        reminderDates?: Array< string > | null,
        remittanceInformation?: string | null,
        statementId?: string | null,
        type: InvoiceType,
        unitId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      invoiceId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      leasePriceHistoryId?: string | null,
      leasePriceHistory?:  {
        __typename: "LeasePriceHistory",
        id: string,
        previousTotalRentalPrice: number,
        previousTotalMonthlyCharge: number,
        totalRentalPrice: number,
        optimalTotalRentalPrice?: number | null,
        totalMonthlyCharges: number,
        optimalTotalMonthlyCharges?: number | null,
        applicationDate: string,
        status: LeaseAmountUpdateStatus,
        comment?: string | null,
        type: LeasePriceHistoryType,
        communicationId?: string | null,
        leaseId: string,
        createdAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      linkedCommunicationsIds?: Array< string > | null,
      recipient?: string | null,
      recipientInBcc?: string | null,
      recipientInCc?: string | null,
      sender?: string | null,
      sentAt?: string | null,
      externalId?: string | null,
      signatureDocumentId?: string | null,
      signatureDocument?:  {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      snoozedDate?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId?: string | null,
      status: CommunicationStatus,
      subject: string,
      toSend: boolean,
      type: CommunicationType,
      _ttl?: number | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    creditNote?: boolean | null,
    creditNoteLabel?: string | null,
    dueDate?: string | null,
    invoiceDate: string,
    invoiceNumber?: string | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    paid: boolean,
    receiptNumber?: string | null,
    reminderDates?: Array< string > | null,
    remittanceInformation?: string | null,
    statementId?: string | null,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: InvoiceType,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteInvoiceMutationVariables = {
  input: DeleteInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type DeleteInvoiceMutation = {
  deleteInvoice?:  {
    __typename: "Invoice",
    id: string,
    amount: number,
    bankAccountId: string,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    communicationId?: string | null,
    communication?:  {
      __typename: "Communication",
      id: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      blockedRecipients?:  Array< {
        __typename: "CommunicationBlockedRecipient",
        recipient: string,
        bounceType: BounceType,
      } > | null,
      body: string,
      buildingId?: string | null,
      channel: CommunicationChannel,
      communicationTemplateId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        amount: number,
        bankAccountId: string,
        communicationId?: string | null,
        createdAt: string,
        creditNote?: boolean | null,
        creditNoteLabel?: string | null,
        dueDate?: string | null,
        invoiceDate: string,
        invoiceNumber?: string | null,
        leaseId?: string | null,
        paid: boolean,
        receiptNumber?: string | null,
        reminderDates?: Array< string > | null,
        remittanceInformation?: string | null,
        statementId?: string | null,
        type: InvoiceType,
        unitId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      invoiceId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      leasePriceHistoryId?: string | null,
      leasePriceHistory?:  {
        __typename: "LeasePriceHistory",
        id: string,
        previousTotalRentalPrice: number,
        previousTotalMonthlyCharge: number,
        totalRentalPrice: number,
        optimalTotalRentalPrice?: number | null,
        totalMonthlyCharges: number,
        optimalTotalMonthlyCharges?: number | null,
        applicationDate: string,
        status: LeaseAmountUpdateStatus,
        comment?: string | null,
        type: LeasePriceHistoryType,
        communicationId?: string | null,
        leaseId: string,
        createdAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      linkedCommunicationsIds?: Array< string > | null,
      recipient?: string | null,
      recipientInBcc?: string | null,
      recipientInCc?: string | null,
      sender?: string | null,
      sentAt?: string | null,
      externalId?: string | null,
      signatureDocumentId?: string | null,
      signatureDocument?:  {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      snoozedDate?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId?: string | null,
      status: CommunicationStatus,
      subject: string,
      toSend: boolean,
      type: CommunicationType,
      _ttl?: number | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    creditNote?: boolean | null,
    creditNoteLabel?: string | null,
    dueDate?: string | null,
    invoiceDate: string,
    invoiceNumber?: string | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    paid: boolean,
    receiptNumber?: string | null,
    reminderDates?: Array< string > | null,
    remittanceInformation?: string | null,
    statementId?: string | null,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: InvoiceType,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateInvoiceSettingMutationVariables = {
  input: CreateInvoiceSettingInput,
  condition?: ModelInvoiceSettingConditionInput | null,
};

export type CreateInvoiceSettingMutation = {
  createInvoiceSetting?:  {
    __typename: "InvoiceSetting",
    id: string,
    creditNoteFormat: string,
    creditNoteLastUpdate: string,
    creditNoteIncrementNumber: number,
    invoiceFormat: string,
    invoiceLastUpdate: string,
    invoiceIncrementNumber: number,
    paymentRequestFormat: string,
    paymentRequestLastUpdate: string,
    paymentRequestIncrementNumber: number,
    receiptFormat: string,
    receiptLastUpdate: string,
    receiptIncrementNumber: number,
    ownerId: string,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateInvoiceSettingMutationVariables = {
  input: UpdateInvoiceSettingInput,
  condition?: ModelInvoiceSettingConditionInput | null,
};

export type UpdateInvoiceSettingMutation = {
  updateInvoiceSetting?:  {
    __typename: "InvoiceSetting",
    id: string,
    creditNoteFormat: string,
    creditNoteLastUpdate: string,
    creditNoteIncrementNumber: number,
    invoiceFormat: string,
    invoiceLastUpdate: string,
    invoiceIncrementNumber: number,
    paymentRequestFormat: string,
    paymentRequestLastUpdate: string,
    paymentRequestIncrementNumber: number,
    receiptFormat: string,
    receiptLastUpdate: string,
    receiptIncrementNumber: number,
    ownerId: string,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteInvoiceSettingMutationVariables = {
  input: DeleteInvoiceSettingInput,
  condition?: ModelInvoiceSettingConditionInput | null,
};

export type DeleteInvoiceSettingMutation = {
  deleteInvoiceSetting?:  {
    __typename: "InvoiceSetting",
    id: string,
    creditNoteFormat: string,
    creditNoteLastUpdate: string,
    creditNoteIncrementNumber: number,
    invoiceFormat: string,
    invoiceLastUpdate: string,
    invoiceIncrementNumber: number,
    paymentRequestFormat: string,
    paymentRequestLastUpdate: string,
    paymentRequestIncrementNumber: number,
    receiptFormat: string,
    receiptLastUpdate: string,
    receiptIncrementNumber: number,
    ownerId: string,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLeaseMutationVariables = {
  input: CreateLeaseInput,
  condition?: ModelLeaseConditionInput | null,
};

export type CreateLeaseMutation = {
  createLease?:  {
    __typename: "Lease",
    id: string,
    balance?: number | null,
    canBeExtended?: boolean | null,
    communicationSettingsProfileId: string,
    createdAt?: string | null,
    endDate: string,
    includeRealEstateInLeaseContract?: boolean | null,
    invoiceSenderId: string,
    invoiceSender?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inventoryOfFixturesExpert?: string | null,
    inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
    inventoryOfFixturesType?: InventoryOfFixturesType | null,
    ipiNumber?: string | null,
    language: Language,
    lastIndexationProcessDate?: string | null,
    lastInvoiceDate?: string | null,
    legalCompetentAuthority?: string | null,
    name: string,
    paymentFrequency: LeasePaymentFrequency,
    paymentInvoicePeriod: LeasePaymentInvoicePeriod,
    paymentInvoicePeriodCustomDay?: number | null,
    realEstateAgency?: string | null,
    realEstateTax?: number | null,
    remittanceInformation?: string | null,
    rentalGuarantyAmount?: number | null,
    rentalGuarantyFinancialInstitution?: string | null,
    rentalGuarantyType?: LeaseRentalGuarantyType | null,
    signatureDate?: string | null,
    startDate: string,
    status: LeaseStatus,
    technicCommunicationSettingsProfileId: string,
    totalInitialMonthlyCharges: number,
    totalInitialRentalPrice: number,
    totalMonthlyCharges: number,
    totalRentalPrice: number,
    type: LeaseType,
    vatRateCharge?: number | null,
    vatRateRent?: number | null,
    additionalClauses?:  {
      __typename: "ModelLeaseAdditionalClauseConnection",
      items:  Array< {
        __typename: "LeaseAdditionalClause",
        id: string,
        leaseId: string,
        title?: string | null,
        body: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bankAccountId?: string | null,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contacts?:  {
      __typename: "ModelLeaseContactConnection",
      items:  Array< {
        __typename: "LeaseContact",
        id: string,
        leaseId: string,
        contactId: string,
        contactRole: ContactType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leaseActionHistories?:  {
      __typename: "ModelLeaseActionHistoryConnection",
      items:  Array< {
        __typename: "LeaseActionHistory",
        id: string,
        action: LeaseAction,
        comment?: string | null,
        contactId: string,
        createdAt?: string | null,
        leaseId: string,
        signatureDocumentId?: string | null,
        status?: LeaseActionHistoryStatus | null,
        communicationChannel?: CommunicationChannel | null,
        communicationSendDate?: string | null,
        communicationByPlatform?: boolean | null,
        endReason?: LeaseActionEndReason | null,
        endReasonOwnUseRelation?: string | null,
        endReasonOwnUseName?: string | null,
        initiator?: LeaseActionEndInitiator | null,
        totalAmount?: number | null,
        totalAmountLabel?: string | null,
        automatic?: boolean | null,
        hasLeaseTypeChanged?: boolean | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leaseInventories?:  {
      __typename: "ModelLeaseInventoryConnection",
      items:  Array< {
        __typename: "LeaseInventory",
        id: string,
        leaseId: string,
        inventoryType: LeaseInventoryType,
        status: LeaseStatus,
        createdAt: string,
        signatureDate?: string | null,
        note?: string | null,
        fileId?: string | null,
        signatureMethods?: Array< SignatureType > | null,
        additionalMessage?: string | null,
        connectionPlugs?: Array< ConnectionPlugsType > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leasePriceHistories?:  {
      __typename: "ModelLeasePriceHistoryConnection",
      items:  Array< {
        __typename: "LeasePriceHistory",
        id: string,
        previousTotalRentalPrice: number,
        previousTotalMonthlyCharge: number,
        totalRentalPrice: number,
        optimalTotalRentalPrice?: number | null,
        totalMonthlyCharges: number,
        optimalTotalMonthlyCharges?: number | null,
        applicationDate: string,
        status: LeaseAmountUpdateStatus,
        comment?: string | null,
        type: LeasePriceHistoryType,
        communicationId?: string | null,
        leaseId: string,
        createdAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    signatureDocuments?:  {
      __typename: "ModelSignatureDocumentConnection",
      items:  Array< {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    units?:  {
      __typename: "ModelUnitLeaseConnection",
      items:  Array< {
        __typename: "UnitLease",
        id: string,
        allowChargeAdjustment?: boolean | null,
        authorizeProfessionalActivity?: boolean | null,
        chargesIndexation?: boolean | null,
        createdAt?: string | null,
        endDate: string,
        furnishedRental?: boolean | null,
        furnishedRentalInsuranceByTenant?: boolean | null,
        furnishedRentalRentalPricePercentage?: number | null,
        initialMonthlyCharges: number,
        initialRentalPrice: number,
        indexation?: boolean | null,
        advancedIndexation?: boolean | null,
        indexType?: IndexType | null,
        baseYear?: number | null,
        baseIndexType?: BaseIndexType | null,
        baseIndexDate?: string | null,
        leaseId: string,
        mainUnit: boolean,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        monthlyCharges: number,
        rentalPrice: number,
        signatureDate?: string | null,
        startDate: string,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    variousOperations?:  {
      __typename: "ModelLeaseVariousOperationConnection",
      items:  Array< {
        __typename: "LeaseVariousOperation",
        id: string,
        amount: number,
        contactId: string,
        createdAt: string,
        endDate: string,
        label: string,
        lastInvoiceDate?: string | null,
        leaseId: string,
        operationType: LeaseVariousOperationType,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        reason?: string | null,
        separateInvoice: boolean,
        startDate: string,
        type: VariousOperationType,
        unitId: string,
        vatRate?: number | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLeaseMutationVariables = {
  input: UpdateLeaseInput,
  condition?: ModelLeaseConditionInput | null,
};

export type UpdateLeaseMutation = {
  updateLease?:  {
    __typename: "Lease",
    id: string,
    balance?: number | null,
    canBeExtended?: boolean | null,
    communicationSettingsProfileId: string,
    createdAt?: string | null,
    endDate: string,
    includeRealEstateInLeaseContract?: boolean | null,
    invoiceSenderId: string,
    invoiceSender?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inventoryOfFixturesExpert?: string | null,
    inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
    inventoryOfFixturesType?: InventoryOfFixturesType | null,
    ipiNumber?: string | null,
    language: Language,
    lastIndexationProcessDate?: string | null,
    lastInvoiceDate?: string | null,
    legalCompetentAuthority?: string | null,
    name: string,
    paymentFrequency: LeasePaymentFrequency,
    paymentInvoicePeriod: LeasePaymentInvoicePeriod,
    paymentInvoicePeriodCustomDay?: number | null,
    realEstateAgency?: string | null,
    realEstateTax?: number | null,
    remittanceInformation?: string | null,
    rentalGuarantyAmount?: number | null,
    rentalGuarantyFinancialInstitution?: string | null,
    rentalGuarantyType?: LeaseRentalGuarantyType | null,
    signatureDate?: string | null,
    startDate: string,
    status: LeaseStatus,
    technicCommunicationSettingsProfileId: string,
    totalInitialMonthlyCharges: number,
    totalInitialRentalPrice: number,
    totalMonthlyCharges: number,
    totalRentalPrice: number,
    type: LeaseType,
    vatRateCharge?: number | null,
    vatRateRent?: number | null,
    additionalClauses?:  {
      __typename: "ModelLeaseAdditionalClauseConnection",
      items:  Array< {
        __typename: "LeaseAdditionalClause",
        id: string,
        leaseId: string,
        title?: string | null,
        body: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bankAccountId?: string | null,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contacts?:  {
      __typename: "ModelLeaseContactConnection",
      items:  Array< {
        __typename: "LeaseContact",
        id: string,
        leaseId: string,
        contactId: string,
        contactRole: ContactType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leaseActionHistories?:  {
      __typename: "ModelLeaseActionHistoryConnection",
      items:  Array< {
        __typename: "LeaseActionHistory",
        id: string,
        action: LeaseAction,
        comment?: string | null,
        contactId: string,
        createdAt?: string | null,
        leaseId: string,
        signatureDocumentId?: string | null,
        status?: LeaseActionHistoryStatus | null,
        communicationChannel?: CommunicationChannel | null,
        communicationSendDate?: string | null,
        communicationByPlatform?: boolean | null,
        endReason?: LeaseActionEndReason | null,
        endReasonOwnUseRelation?: string | null,
        endReasonOwnUseName?: string | null,
        initiator?: LeaseActionEndInitiator | null,
        totalAmount?: number | null,
        totalAmountLabel?: string | null,
        automatic?: boolean | null,
        hasLeaseTypeChanged?: boolean | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leaseInventories?:  {
      __typename: "ModelLeaseInventoryConnection",
      items:  Array< {
        __typename: "LeaseInventory",
        id: string,
        leaseId: string,
        inventoryType: LeaseInventoryType,
        status: LeaseStatus,
        createdAt: string,
        signatureDate?: string | null,
        note?: string | null,
        fileId?: string | null,
        signatureMethods?: Array< SignatureType > | null,
        additionalMessage?: string | null,
        connectionPlugs?: Array< ConnectionPlugsType > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leasePriceHistories?:  {
      __typename: "ModelLeasePriceHistoryConnection",
      items:  Array< {
        __typename: "LeasePriceHistory",
        id: string,
        previousTotalRentalPrice: number,
        previousTotalMonthlyCharge: number,
        totalRentalPrice: number,
        optimalTotalRentalPrice?: number | null,
        totalMonthlyCharges: number,
        optimalTotalMonthlyCharges?: number | null,
        applicationDate: string,
        status: LeaseAmountUpdateStatus,
        comment?: string | null,
        type: LeasePriceHistoryType,
        communicationId?: string | null,
        leaseId: string,
        createdAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    signatureDocuments?:  {
      __typename: "ModelSignatureDocumentConnection",
      items:  Array< {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    units?:  {
      __typename: "ModelUnitLeaseConnection",
      items:  Array< {
        __typename: "UnitLease",
        id: string,
        allowChargeAdjustment?: boolean | null,
        authorizeProfessionalActivity?: boolean | null,
        chargesIndexation?: boolean | null,
        createdAt?: string | null,
        endDate: string,
        furnishedRental?: boolean | null,
        furnishedRentalInsuranceByTenant?: boolean | null,
        furnishedRentalRentalPricePercentage?: number | null,
        initialMonthlyCharges: number,
        initialRentalPrice: number,
        indexation?: boolean | null,
        advancedIndexation?: boolean | null,
        indexType?: IndexType | null,
        baseYear?: number | null,
        baseIndexType?: BaseIndexType | null,
        baseIndexDate?: string | null,
        leaseId: string,
        mainUnit: boolean,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        monthlyCharges: number,
        rentalPrice: number,
        signatureDate?: string | null,
        startDate: string,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    variousOperations?:  {
      __typename: "ModelLeaseVariousOperationConnection",
      items:  Array< {
        __typename: "LeaseVariousOperation",
        id: string,
        amount: number,
        contactId: string,
        createdAt: string,
        endDate: string,
        label: string,
        lastInvoiceDate?: string | null,
        leaseId: string,
        operationType: LeaseVariousOperationType,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        reason?: string | null,
        separateInvoice: boolean,
        startDate: string,
        type: VariousOperationType,
        unitId: string,
        vatRate?: number | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLeaseMutationVariables = {
  input: DeleteLeaseInput,
  condition?: ModelLeaseConditionInput | null,
};

export type DeleteLeaseMutation = {
  deleteLease?:  {
    __typename: "Lease",
    id: string,
    balance?: number | null,
    canBeExtended?: boolean | null,
    communicationSettingsProfileId: string,
    createdAt?: string | null,
    endDate: string,
    includeRealEstateInLeaseContract?: boolean | null,
    invoiceSenderId: string,
    invoiceSender?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inventoryOfFixturesExpert?: string | null,
    inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
    inventoryOfFixturesType?: InventoryOfFixturesType | null,
    ipiNumber?: string | null,
    language: Language,
    lastIndexationProcessDate?: string | null,
    lastInvoiceDate?: string | null,
    legalCompetentAuthority?: string | null,
    name: string,
    paymentFrequency: LeasePaymentFrequency,
    paymentInvoicePeriod: LeasePaymentInvoicePeriod,
    paymentInvoicePeriodCustomDay?: number | null,
    realEstateAgency?: string | null,
    realEstateTax?: number | null,
    remittanceInformation?: string | null,
    rentalGuarantyAmount?: number | null,
    rentalGuarantyFinancialInstitution?: string | null,
    rentalGuarantyType?: LeaseRentalGuarantyType | null,
    signatureDate?: string | null,
    startDate: string,
    status: LeaseStatus,
    technicCommunicationSettingsProfileId: string,
    totalInitialMonthlyCharges: number,
    totalInitialRentalPrice: number,
    totalMonthlyCharges: number,
    totalRentalPrice: number,
    type: LeaseType,
    vatRateCharge?: number | null,
    vatRateRent?: number | null,
    additionalClauses?:  {
      __typename: "ModelLeaseAdditionalClauseConnection",
      items:  Array< {
        __typename: "LeaseAdditionalClause",
        id: string,
        leaseId: string,
        title?: string | null,
        body: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bankAccountId?: string | null,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contacts?:  {
      __typename: "ModelLeaseContactConnection",
      items:  Array< {
        __typename: "LeaseContact",
        id: string,
        leaseId: string,
        contactId: string,
        contactRole: ContactType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leaseActionHistories?:  {
      __typename: "ModelLeaseActionHistoryConnection",
      items:  Array< {
        __typename: "LeaseActionHistory",
        id: string,
        action: LeaseAction,
        comment?: string | null,
        contactId: string,
        createdAt?: string | null,
        leaseId: string,
        signatureDocumentId?: string | null,
        status?: LeaseActionHistoryStatus | null,
        communicationChannel?: CommunicationChannel | null,
        communicationSendDate?: string | null,
        communicationByPlatform?: boolean | null,
        endReason?: LeaseActionEndReason | null,
        endReasonOwnUseRelation?: string | null,
        endReasonOwnUseName?: string | null,
        initiator?: LeaseActionEndInitiator | null,
        totalAmount?: number | null,
        totalAmountLabel?: string | null,
        automatic?: boolean | null,
        hasLeaseTypeChanged?: boolean | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leaseInventories?:  {
      __typename: "ModelLeaseInventoryConnection",
      items:  Array< {
        __typename: "LeaseInventory",
        id: string,
        leaseId: string,
        inventoryType: LeaseInventoryType,
        status: LeaseStatus,
        createdAt: string,
        signatureDate?: string | null,
        note?: string | null,
        fileId?: string | null,
        signatureMethods?: Array< SignatureType > | null,
        additionalMessage?: string | null,
        connectionPlugs?: Array< ConnectionPlugsType > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leasePriceHistories?:  {
      __typename: "ModelLeasePriceHistoryConnection",
      items:  Array< {
        __typename: "LeasePriceHistory",
        id: string,
        previousTotalRentalPrice: number,
        previousTotalMonthlyCharge: number,
        totalRentalPrice: number,
        optimalTotalRentalPrice?: number | null,
        totalMonthlyCharges: number,
        optimalTotalMonthlyCharges?: number | null,
        applicationDate: string,
        status: LeaseAmountUpdateStatus,
        comment?: string | null,
        type: LeasePriceHistoryType,
        communicationId?: string | null,
        leaseId: string,
        createdAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    signatureDocuments?:  {
      __typename: "ModelSignatureDocumentConnection",
      items:  Array< {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    units?:  {
      __typename: "ModelUnitLeaseConnection",
      items:  Array< {
        __typename: "UnitLease",
        id: string,
        allowChargeAdjustment?: boolean | null,
        authorizeProfessionalActivity?: boolean | null,
        chargesIndexation?: boolean | null,
        createdAt?: string | null,
        endDate: string,
        furnishedRental?: boolean | null,
        furnishedRentalInsuranceByTenant?: boolean | null,
        furnishedRentalRentalPricePercentage?: number | null,
        initialMonthlyCharges: number,
        initialRentalPrice: number,
        indexation?: boolean | null,
        advancedIndexation?: boolean | null,
        indexType?: IndexType | null,
        baseYear?: number | null,
        baseIndexType?: BaseIndexType | null,
        baseIndexDate?: string | null,
        leaseId: string,
        mainUnit: boolean,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        monthlyCharges: number,
        rentalPrice: number,
        signatureDate?: string | null,
        startDate: string,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    variousOperations?:  {
      __typename: "ModelLeaseVariousOperationConnection",
      items:  Array< {
        __typename: "LeaseVariousOperation",
        id: string,
        amount: number,
        contactId: string,
        createdAt: string,
        endDate: string,
        label: string,
        lastInvoiceDate?: string | null,
        leaseId: string,
        operationType: LeaseVariousOperationType,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        reason?: string | null,
        separateInvoice: boolean,
        startDate: string,
        type: VariousOperationType,
        unitId: string,
        vatRate?: number | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLeaseActionHistoryMutationVariables = {
  input: CreateLeaseActionHistoryInput,
  condition?: ModelLeaseActionHistoryConditionInput | null,
};

export type CreateLeaseActionHistoryMutation = {
  createLeaseActionHistory?:  {
    __typename: "LeaseActionHistory",
    id: string,
    action: LeaseAction,
    comment?: string | null,
    contactId: string,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    historyDetails:  Array< {
      __typename: "LeaseActionHistoryDetail",
      unitId: string,
      newEndDate?: string | null,
      newStartDate?: string | null,
      previousEndDate?: string | null,
      previousStartDate?: string | null,
    } >,
    leaseId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    signatureDocumentId?: string | null,
    signatureDocument?:  {
      __typename: "SignatureDocument",
      id: string,
      additionalMessage?: string | null,
      communicationBody?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      fileIds?: Array< string > | null,
      foreignKey: string,
      foreignTableName: string,
      sentAt?: string | null,
      signatureMethods: Array< SignatureType >,
      signatureResults:  Array< {
        __typename: "SignatureDocumentResult",
        openedDate?: string | null,
        signedDate?: string | null,
        signed?: boolean | null,
        signatureLink?: string | null,
        rejectReason?: string | null,
        lastSignInviteSentDate?: string | null,
        trackingProvider?: TrackingProvider | null,
        trackingCode?: string | null,
        contactId: string,
      } >,
      status: SignatureDocumentStatus,
      externalStatus?: string | null,
      type: SignatureDocumentType,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status?: LeaseActionHistoryStatus | null,
    communicationChannel?: CommunicationChannel | null,
    communicationSendDate?: string | null,
    communicationByPlatform?: boolean | null,
    endReason?: LeaseActionEndReason | null,
    endReasonOwnUseRelation?: string | null,
    endReasonOwnUseName?: string | null,
    initiator?: LeaseActionEndInitiator | null,
    totalAmount?: number | null,
    totalAmountLabel?: string | null,
    automatic?: boolean | null,
    hasLeaseTypeChanged?: boolean | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLeaseActionHistoryMutationVariables = {
  input: UpdateLeaseActionHistoryInput,
  condition?: ModelLeaseActionHistoryConditionInput | null,
};

export type UpdateLeaseActionHistoryMutation = {
  updateLeaseActionHistory?:  {
    __typename: "LeaseActionHistory",
    id: string,
    action: LeaseAction,
    comment?: string | null,
    contactId: string,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    historyDetails:  Array< {
      __typename: "LeaseActionHistoryDetail",
      unitId: string,
      newEndDate?: string | null,
      newStartDate?: string | null,
      previousEndDate?: string | null,
      previousStartDate?: string | null,
    } >,
    leaseId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    signatureDocumentId?: string | null,
    signatureDocument?:  {
      __typename: "SignatureDocument",
      id: string,
      additionalMessage?: string | null,
      communicationBody?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      fileIds?: Array< string > | null,
      foreignKey: string,
      foreignTableName: string,
      sentAt?: string | null,
      signatureMethods: Array< SignatureType >,
      signatureResults:  Array< {
        __typename: "SignatureDocumentResult",
        openedDate?: string | null,
        signedDate?: string | null,
        signed?: boolean | null,
        signatureLink?: string | null,
        rejectReason?: string | null,
        lastSignInviteSentDate?: string | null,
        trackingProvider?: TrackingProvider | null,
        trackingCode?: string | null,
        contactId: string,
      } >,
      status: SignatureDocumentStatus,
      externalStatus?: string | null,
      type: SignatureDocumentType,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status?: LeaseActionHistoryStatus | null,
    communicationChannel?: CommunicationChannel | null,
    communicationSendDate?: string | null,
    communicationByPlatform?: boolean | null,
    endReason?: LeaseActionEndReason | null,
    endReasonOwnUseRelation?: string | null,
    endReasonOwnUseName?: string | null,
    initiator?: LeaseActionEndInitiator | null,
    totalAmount?: number | null,
    totalAmountLabel?: string | null,
    automatic?: boolean | null,
    hasLeaseTypeChanged?: boolean | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLeaseActionHistoryMutationVariables = {
  input: DeleteLeaseActionHistoryInput,
  condition?: ModelLeaseActionHistoryConditionInput | null,
};

export type DeleteLeaseActionHistoryMutation = {
  deleteLeaseActionHistory?:  {
    __typename: "LeaseActionHistory",
    id: string,
    action: LeaseAction,
    comment?: string | null,
    contactId: string,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    historyDetails:  Array< {
      __typename: "LeaseActionHistoryDetail",
      unitId: string,
      newEndDate?: string | null,
      newStartDate?: string | null,
      previousEndDate?: string | null,
      previousStartDate?: string | null,
    } >,
    leaseId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    signatureDocumentId?: string | null,
    signatureDocument?:  {
      __typename: "SignatureDocument",
      id: string,
      additionalMessage?: string | null,
      communicationBody?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      fileIds?: Array< string > | null,
      foreignKey: string,
      foreignTableName: string,
      sentAt?: string | null,
      signatureMethods: Array< SignatureType >,
      signatureResults:  Array< {
        __typename: "SignatureDocumentResult",
        openedDate?: string | null,
        signedDate?: string | null,
        signed?: boolean | null,
        signatureLink?: string | null,
        rejectReason?: string | null,
        lastSignInviteSentDate?: string | null,
        trackingProvider?: TrackingProvider | null,
        trackingCode?: string | null,
        contactId: string,
      } >,
      status: SignatureDocumentStatus,
      externalStatus?: string | null,
      type: SignatureDocumentType,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status?: LeaseActionHistoryStatus | null,
    communicationChannel?: CommunicationChannel | null,
    communicationSendDate?: string | null,
    communicationByPlatform?: boolean | null,
    endReason?: LeaseActionEndReason | null,
    endReasonOwnUseRelation?: string | null,
    endReasonOwnUseName?: string | null,
    initiator?: LeaseActionEndInitiator | null,
    totalAmount?: number | null,
    totalAmountLabel?: string | null,
    automatic?: boolean | null,
    hasLeaseTypeChanged?: boolean | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLeaseAdditionalClauseMutationVariables = {
  input: CreateLeaseAdditionalClauseInput,
  condition?: ModelLeaseAdditionalClauseConditionInput | null,
};

export type CreateLeaseAdditionalClauseMutation = {
  createLeaseAdditionalClause?:  {
    __typename: "LeaseAdditionalClause",
    id: string,
    leaseId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    title?: string | null,
    body: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLeaseAdditionalClauseMutationVariables = {
  input: UpdateLeaseAdditionalClauseInput,
  condition?: ModelLeaseAdditionalClauseConditionInput | null,
};

export type UpdateLeaseAdditionalClauseMutation = {
  updateLeaseAdditionalClause?:  {
    __typename: "LeaseAdditionalClause",
    id: string,
    leaseId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    title?: string | null,
    body: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLeaseAdditionalClauseMutationVariables = {
  input: DeleteLeaseAdditionalClauseInput,
  condition?: ModelLeaseAdditionalClauseConditionInput | null,
};

export type DeleteLeaseAdditionalClauseMutation = {
  deleteLeaseAdditionalClause?:  {
    __typename: "LeaseAdditionalClause",
    id: string,
    leaseId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    title?: string | null,
    body: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLeaseContactMutationVariables = {
  input: CreateLeaseContactInput,
  condition?: ModelLeaseContactConditionInput | null,
};

export type CreateLeaseContactMutation = {
  createLeaseContact?:  {
    __typename: "LeaseContact",
    id: string,
    leaseId: string,
    contactId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactRole: ContactType,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLeaseContactMutationVariables = {
  input: UpdateLeaseContactInput,
  condition?: ModelLeaseContactConditionInput | null,
};

export type UpdateLeaseContactMutation = {
  updateLeaseContact?:  {
    __typename: "LeaseContact",
    id: string,
    leaseId: string,
    contactId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactRole: ContactType,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLeaseContactMutationVariables = {
  input: DeleteLeaseContactInput,
  condition?: ModelLeaseContactConditionInput | null,
};

export type DeleteLeaseContactMutation = {
  deleteLeaseContact?:  {
    __typename: "LeaseContact",
    id: string,
    leaseId: string,
    contactId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactRole: ContactType,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLeasePriceHistoryMutationVariables = {
  input: CreateLeasePriceHistoryInput,
  condition?: ModelLeasePriceHistoryConditionInput | null,
};

export type CreateLeasePriceHistoryMutation = {
  createLeasePriceHistory?:  {
    __typename: "LeasePriceHistory",
    id: string,
    previousTotalRentalPrice: number,
    previousTotalMonthlyCharge: number,
    totalRentalPrice: number,
    optimalTotalRentalPrice?: number | null,
    totalMonthlyCharges: number,
    optimalTotalMonthlyCharges?: number | null,
    applicationDate: string,
    status: LeaseAmountUpdateStatus,
    comment?: string | null,
    amountDetails:  Array< {
      __typename: "LeasePriceHistoryDetail",
      unitId: string,
      indexationBaseIndex?: number | null,
      indexationNewIndex?: number | null,
      previousRentalPrice: number,
      newRentalPrice: number,
      rentalStatus: LeasePriceHistoryStatus,
      previousMonthlyChargesPrice: number,
      newMonthlyChargesPrice: number,
      monthlyChargesStatus: LeasePriceHistoryStatus,
      monthlyChargesType?: LeaseMonthlyChargesType | null,
      optimalNewRentalPrice?: number | null,
      optimalNewMonthlyChargesPrice?: number | null,
      epb?: string | null,
      epbIssueDate?: string | null,
      oldestIndexForCorrectionFactorCalculation?: number | null,
      newestIndexForCorrectionFactorCalculation?: number | null,
      rentIndexedPriceAfterNewEpbReduction?: number | null,
      chargesIndexedPriceAfterNewEpbReduction?: number | null,
      correctionFactor?: number | null,
      reductionPercentage?: number | null,
      rentDifference?: number | null,
      rentReduction?: number | null,
      indexedRentAfterReduction?: number | null,
      chargesDifference?: number | null,
      chargesReduction?: number | null,
      indexedChargesAfterReduction?: number | null,
      previousYearIndexationNewIndex?: number | null,
      previousYearIndexedRent?: number | null,
      previousYearIndexedCharges?: number | null,
    } >,
    type: LeasePriceHistoryType,
    communicationId?: string | null,
    communication?:  {
      __typename: "Communication",
      id: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      blockedRecipients?:  Array< {
        __typename: "CommunicationBlockedRecipient",
        recipient: string,
        bounceType: BounceType,
      } > | null,
      body: string,
      buildingId?: string | null,
      channel: CommunicationChannel,
      communicationTemplateId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        amount: number,
        bankAccountId: string,
        communicationId?: string | null,
        createdAt: string,
        creditNote?: boolean | null,
        creditNoteLabel?: string | null,
        dueDate?: string | null,
        invoiceDate: string,
        invoiceNumber?: string | null,
        leaseId?: string | null,
        paid: boolean,
        receiptNumber?: string | null,
        reminderDates?: Array< string > | null,
        remittanceInformation?: string | null,
        statementId?: string | null,
        type: InvoiceType,
        unitId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      invoiceId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      leasePriceHistoryId?: string | null,
      leasePriceHistory?:  {
        __typename: "LeasePriceHistory",
        id: string,
        previousTotalRentalPrice: number,
        previousTotalMonthlyCharge: number,
        totalRentalPrice: number,
        optimalTotalRentalPrice?: number | null,
        totalMonthlyCharges: number,
        optimalTotalMonthlyCharges?: number | null,
        applicationDate: string,
        status: LeaseAmountUpdateStatus,
        comment?: string | null,
        type: LeasePriceHistoryType,
        communicationId?: string | null,
        leaseId: string,
        createdAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      linkedCommunicationsIds?: Array< string > | null,
      recipient?: string | null,
      recipientInBcc?: string | null,
      recipientInCc?: string | null,
      sender?: string | null,
      sentAt?: string | null,
      externalId?: string | null,
      signatureDocumentId?: string | null,
      signatureDocument?:  {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      snoozedDate?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId?: string | null,
      status: CommunicationStatus,
      subject: string,
      toSend: boolean,
      type: CommunicationType,
      _ttl?: number | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLeasePriceHistoryMutationVariables = {
  input: UpdateLeasePriceHistoryInput,
  condition?: ModelLeasePriceHistoryConditionInput | null,
};

export type UpdateLeasePriceHistoryMutation = {
  updateLeasePriceHistory?:  {
    __typename: "LeasePriceHistory",
    id: string,
    previousTotalRentalPrice: number,
    previousTotalMonthlyCharge: number,
    totalRentalPrice: number,
    optimalTotalRentalPrice?: number | null,
    totalMonthlyCharges: number,
    optimalTotalMonthlyCharges?: number | null,
    applicationDate: string,
    status: LeaseAmountUpdateStatus,
    comment?: string | null,
    amountDetails:  Array< {
      __typename: "LeasePriceHistoryDetail",
      unitId: string,
      indexationBaseIndex?: number | null,
      indexationNewIndex?: number | null,
      previousRentalPrice: number,
      newRentalPrice: number,
      rentalStatus: LeasePriceHistoryStatus,
      previousMonthlyChargesPrice: number,
      newMonthlyChargesPrice: number,
      monthlyChargesStatus: LeasePriceHistoryStatus,
      monthlyChargesType?: LeaseMonthlyChargesType | null,
      optimalNewRentalPrice?: number | null,
      optimalNewMonthlyChargesPrice?: number | null,
      epb?: string | null,
      epbIssueDate?: string | null,
      oldestIndexForCorrectionFactorCalculation?: number | null,
      newestIndexForCorrectionFactorCalculation?: number | null,
      rentIndexedPriceAfterNewEpbReduction?: number | null,
      chargesIndexedPriceAfterNewEpbReduction?: number | null,
      correctionFactor?: number | null,
      reductionPercentage?: number | null,
      rentDifference?: number | null,
      rentReduction?: number | null,
      indexedRentAfterReduction?: number | null,
      chargesDifference?: number | null,
      chargesReduction?: number | null,
      indexedChargesAfterReduction?: number | null,
      previousYearIndexationNewIndex?: number | null,
      previousYearIndexedRent?: number | null,
      previousYearIndexedCharges?: number | null,
    } >,
    type: LeasePriceHistoryType,
    communicationId?: string | null,
    communication?:  {
      __typename: "Communication",
      id: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      blockedRecipients?:  Array< {
        __typename: "CommunicationBlockedRecipient",
        recipient: string,
        bounceType: BounceType,
      } > | null,
      body: string,
      buildingId?: string | null,
      channel: CommunicationChannel,
      communicationTemplateId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        amount: number,
        bankAccountId: string,
        communicationId?: string | null,
        createdAt: string,
        creditNote?: boolean | null,
        creditNoteLabel?: string | null,
        dueDate?: string | null,
        invoiceDate: string,
        invoiceNumber?: string | null,
        leaseId?: string | null,
        paid: boolean,
        receiptNumber?: string | null,
        reminderDates?: Array< string > | null,
        remittanceInformation?: string | null,
        statementId?: string | null,
        type: InvoiceType,
        unitId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      invoiceId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      leasePriceHistoryId?: string | null,
      leasePriceHistory?:  {
        __typename: "LeasePriceHistory",
        id: string,
        previousTotalRentalPrice: number,
        previousTotalMonthlyCharge: number,
        totalRentalPrice: number,
        optimalTotalRentalPrice?: number | null,
        totalMonthlyCharges: number,
        optimalTotalMonthlyCharges?: number | null,
        applicationDate: string,
        status: LeaseAmountUpdateStatus,
        comment?: string | null,
        type: LeasePriceHistoryType,
        communicationId?: string | null,
        leaseId: string,
        createdAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      linkedCommunicationsIds?: Array< string > | null,
      recipient?: string | null,
      recipientInBcc?: string | null,
      recipientInCc?: string | null,
      sender?: string | null,
      sentAt?: string | null,
      externalId?: string | null,
      signatureDocumentId?: string | null,
      signatureDocument?:  {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      snoozedDate?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId?: string | null,
      status: CommunicationStatus,
      subject: string,
      toSend: boolean,
      type: CommunicationType,
      _ttl?: number | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLeasePriceHistoryMutationVariables = {
  input: DeleteLeasePriceHistoryInput,
  condition?: ModelLeasePriceHistoryConditionInput | null,
};

export type DeleteLeasePriceHistoryMutation = {
  deleteLeasePriceHistory?:  {
    __typename: "LeasePriceHistory",
    id: string,
    previousTotalRentalPrice: number,
    previousTotalMonthlyCharge: number,
    totalRentalPrice: number,
    optimalTotalRentalPrice?: number | null,
    totalMonthlyCharges: number,
    optimalTotalMonthlyCharges?: number | null,
    applicationDate: string,
    status: LeaseAmountUpdateStatus,
    comment?: string | null,
    amountDetails:  Array< {
      __typename: "LeasePriceHistoryDetail",
      unitId: string,
      indexationBaseIndex?: number | null,
      indexationNewIndex?: number | null,
      previousRentalPrice: number,
      newRentalPrice: number,
      rentalStatus: LeasePriceHistoryStatus,
      previousMonthlyChargesPrice: number,
      newMonthlyChargesPrice: number,
      monthlyChargesStatus: LeasePriceHistoryStatus,
      monthlyChargesType?: LeaseMonthlyChargesType | null,
      optimalNewRentalPrice?: number | null,
      optimalNewMonthlyChargesPrice?: number | null,
      epb?: string | null,
      epbIssueDate?: string | null,
      oldestIndexForCorrectionFactorCalculation?: number | null,
      newestIndexForCorrectionFactorCalculation?: number | null,
      rentIndexedPriceAfterNewEpbReduction?: number | null,
      chargesIndexedPriceAfterNewEpbReduction?: number | null,
      correctionFactor?: number | null,
      reductionPercentage?: number | null,
      rentDifference?: number | null,
      rentReduction?: number | null,
      indexedRentAfterReduction?: number | null,
      chargesDifference?: number | null,
      chargesReduction?: number | null,
      indexedChargesAfterReduction?: number | null,
      previousYearIndexationNewIndex?: number | null,
      previousYearIndexedRent?: number | null,
      previousYearIndexedCharges?: number | null,
    } >,
    type: LeasePriceHistoryType,
    communicationId?: string | null,
    communication?:  {
      __typename: "Communication",
      id: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      blockedRecipients?:  Array< {
        __typename: "CommunicationBlockedRecipient",
        recipient: string,
        bounceType: BounceType,
      } > | null,
      body: string,
      buildingId?: string | null,
      channel: CommunicationChannel,
      communicationTemplateId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        amount: number,
        bankAccountId: string,
        communicationId?: string | null,
        createdAt: string,
        creditNote?: boolean | null,
        creditNoteLabel?: string | null,
        dueDate?: string | null,
        invoiceDate: string,
        invoiceNumber?: string | null,
        leaseId?: string | null,
        paid: boolean,
        receiptNumber?: string | null,
        reminderDates?: Array< string > | null,
        remittanceInformation?: string | null,
        statementId?: string | null,
        type: InvoiceType,
        unitId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      invoiceId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      leasePriceHistoryId?: string | null,
      leasePriceHistory?:  {
        __typename: "LeasePriceHistory",
        id: string,
        previousTotalRentalPrice: number,
        previousTotalMonthlyCharge: number,
        totalRentalPrice: number,
        optimalTotalRentalPrice?: number | null,
        totalMonthlyCharges: number,
        optimalTotalMonthlyCharges?: number | null,
        applicationDate: string,
        status: LeaseAmountUpdateStatus,
        comment?: string | null,
        type: LeasePriceHistoryType,
        communicationId?: string | null,
        leaseId: string,
        createdAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      linkedCommunicationsIds?: Array< string > | null,
      recipient?: string | null,
      recipientInBcc?: string | null,
      recipientInCc?: string | null,
      sender?: string | null,
      sentAt?: string | null,
      externalId?: string | null,
      signatureDocumentId?: string | null,
      signatureDocument?:  {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      snoozedDate?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId?: string | null,
      status: CommunicationStatus,
      subject: string,
      toSend: boolean,
      type: CommunicationType,
      _ttl?: number | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLeaseInventoryMutationVariables = {
  input: CreateLeaseInventoryInput,
  condition?: ModelLeaseInventoryConditionInput | null,
};

export type CreateLeaseInventoryMutation = {
  createLeaseInventory?:  {
    __typename: "LeaseInventory",
    id: string,
    leaseId: string,
    inventoryType: LeaseInventoryType,
    status: LeaseStatus,
    createdAt: string,
    signatureDate?: string | null,
    note?: string | null,
    encodings?:  {
      __typename: "ModelLeaseInventoryEncodingConnection",
      items:  Array< {
        __typename: "LeaseInventoryEncoding",
        id: string,
        leaseInventoryId: string,
        structureId: string,
        state?: LeaseInventoryStructureStateType | null,
        note?: string | null,
        furnitureQuantity?: number | null,
        furniturePrice?: number | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    fileId?: string | null,
    file?:  {
      __typename: "File",
      id: string,
      bucket: string,
      createdAt?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        entity?: string | null,
        fileCategory: FileCategory,
        parentId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categoryId: string,
      foreignKey: string,
      foreignTableName: string,
      key: string,
      mimeType?: string | null,
      region: string,
      signaturePositions?:  Array< {
        __typename: "SignaturePosition",
        top: number,
        left: number,
        page: number,
        width?: number | null,
        height?: number | null,
        contactId: string,
      } > | null,
      size?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    signatureMethods?: Array< SignatureType > | null,
    additionalMessage?: string | null,
    connectionPlugs?: Array< ConnectionPlugsType > | null,
    keys?:  Array< {
      __typename: "LeaseInventoryUnitKey",
      keyType: LeaseInventoryUnitKeyType,
      keyId?: string | null,
    } > | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLeaseInventoryMutationVariables = {
  input: UpdateLeaseInventoryInput,
  condition?: ModelLeaseInventoryConditionInput | null,
};

export type UpdateLeaseInventoryMutation = {
  updateLeaseInventory?:  {
    __typename: "LeaseInventory",
    id: string,
    leaseId: string,
    inventoryType: LeaseInventoryType,
    status: LeaseStatus,
    createdAt: string,
    signatureDate?: string | null,
    note?: string | null,
    encodings?:  {
      __typename: "ModelLeaseInventoryEncodingConnection",
      items:  Array< {
        __typename: "LeaseInventoryEncoding",
        id: string,
        leaseInventoryId: string,
        structureId: string,
        state?: LeaseInventoryStructureStateType | null,
        note?: string | null,
        furnitureQuantity?: number | null,
        furniturePrice?: number | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    fileId?: string | null,
    file?:  {
      __typename: "File",
      id: string,
      bucket: string,
      createdAt?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        entity?: string | null,
        fileCategory: FileCategory,
        parentId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categoryId: string,
      foreignKey: string,
      foreignTableName: string,
      key: string,
      mimeType?: string | null,
      region: string,
      signaturePositions?:  Array< {
        __typename: "SignaturePosition",
        top: number,
        left: number,
        page: number,
        width?: number | null,
        height?: number | null,
        contactId: string,
      } > | null,
      size?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    signatureMethods?: Array< SignatureType > | null,
    additionalMessage?: string | null,
    connectionPlugs?: Array< ConnectionPlugsType > | null,
    keys?:  Array< {
      __typename: "LeaseInventoryUnitKey",
      keyType: LeaseInventoryUnitKeyType,
      keyId?: string | null,
    } > | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLeaseInventoryMutationVariables = {
  input: DeleteLeaseInventoryInput,
  condition?: ModelLeaseInventoryConditionInput | null,
};

export type DeleteLeaseInventoryMutation = {
  deleteLeaseInventory?:  {
    __typename: "LeaseInventory",
    id: string,
    leaseId: string,
    inventoryType: LeaseInventoryType,
    status: LeaseStatus,
    createdAt: string,
    signatureDate?: string | null,
    note?: string | null,
    encodings?:  {
      __typename: "ModelLeaseInventoryEncodingConnection",
      items:  Array< {
        __typename: "LeaseInventoryEncoding",
        id: string,
        leaseInventoryId: string,
        structureId: string,
        state?: LeaseInventoryStructureStateType | null,
        note?: string | null,
        furnitureQuantity?: number | null,
        furniturePrice?: number | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    fileId?: string | null,
    file?:  {
      __typename: "File",
      id: string,
      bucket: string,
      createdAt?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        entity?: string | null,
        fileCategory: FileCategory,
        parentId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categoryId: string,
      foreignKey: string,
      foreignTableName: string,
      key: string,
      mimeType?: string | null,
      region: string,
      signaturePositions?:  Array< {
        __typename: "SignaturePosition",
        top: number,
        left: number,
        page: number,
        width?: number | null,
        height?: number | null,
        contactId: string,
      } > | null,
      size?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    signatureMethods?: Array< SignatureType > | null,
    additionalMessage?: string | null,
    connectionPlugs?: Array< ConnectionPlugsType > | null,
    keys?:  Array< {
      __typename: "LeaseInventoryUnitKey",
      keyType: LeaseInventoryUnitKeyType,
      keyId?: string | null,
    } > | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLeaseInventoryCheckedItemMutationVariables = {
  input: CreateLeaseInventoryCheckedItemInput,
  condition?: ModelLeaseInventoryCheckedItemConditionInput | null,
};

export type CreateLeaseInventoryCheckedItemMutation = {
  createLeaseInventoryCheckedItem?:  {
    __typename: "LeaseInventoryCheckedItem",
    id: string,
    custom: boolean,
    leaseInventoryEncodingId: string,
    totalDefects?: number | null,
    customItemId?: string | null,
    customItem?:  {
      __typename: "InventoryCustomItem",
      id: string,
      type: InventoryItemType,
      value: string,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    value?: string | null,
    type?: InventoryItemType | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLeaseInventoryCheckedItemMutationVariables = {
  input: UpdateLeaseInventoryCheckedItemInput,
  condition?: ModelLeaseInventoryCheckedItemConditionInput | null,
};

export type UpdateLeaseInventoryCheckedItemMutation = {
  updateLeaseInventoryCheckedItem?:  {
    __typename: "LeaseInventoryCheckedItem",
    id: string,
    custom: boolean,
    leaseInventoryEncodingId: string,
    totalDefects?: number | null,
    customItemId?: string | null,
    customItem?:  {
      __typename: "InventoryCustomItem",
      id: string,
      type: InventoryItemType,
      value: string,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    value?: string | null,
    type?: InventoryItemType | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLeaseInventoryCheckedItemMutationVariables = {
  input: DeleteLeaseInventoryCheckedItemInput,
  condition?: ModelLeaseInventoryCheckedItemConditionInput | null,
};

export type DeleteLeaseInventoryCheckedItemMutation = {
  deleteLeaseInventoryCheckedItem?:  {
    __typename: "LeaseInventoryCheckedItem",
    id: string,
    custom: boolean,
    leaseInventoryEncodingId: string,
    totalDefects?: number | null,
    customItemId?: string | null,
    customItem?:  {
      __typename: "InventoryCustomItem",
      id: string,
      type: InventoryItemType,
      value: string,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    value?: string | null,
    type?: InventoryItemType | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLeaseInventoryEncodingMutationVariables = {
  input: CreateLeaseInventoryEncodingInput,
  condition?: ModelLeaseInventoryEncodingConditionInput | null,
};

export type CreateLeaseInventoryEncodingMutation = {
  createLeaseInventoryEncoding?:  {
    __typename: "LeaseInventoryEncoding",
    id: string,
    leaseInventoryId: string,
    structureId: string,
    state?: LeaseInventoryStructureStateType | null,
    note?: string | null,
    checkedItems?:  {
      __typename: "ModelLeaseInventoryCheckedItemConnection",
      items:  Array< {
        __typename: "LeaseInventoryCheckedItem",
        id: string,
        custom: boolean,
        leaseInventoryEncodingId: string,
        totalDefects?: number | null,
        customItemId?: string | null,
        value?: string | null,
        type?: InventoryItemType | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    furnitureQuantity?: number | null,
    furniturePrice?: number | null,
    furnitureExitIssues?:  Array< {
      __typename: "LeaseInventoryFurnitureExitIssue",
      quantity: number,
      issue: LeaseInventoryFurnitureExitIssueType,
      action: LeaseInventoryFurnitureExitIssueAction,
      penalty?: number | null,
    } > | null,
    medias?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLeaseInventoryEncodingMutationVariables = {
  input: UpdateLeaseInventoryEncodingInput,
  condition?: ModelLeaseInventoryEncodingConditionInput | null,
};

export type UpdateLeaseInventoryEncodingMutation = {
  updateLeaseInventoryEncoding?:  {
    __typename: "LeaseInventoryEncoding",
    id: string,
    leaseInventoryId: string,
    structureId: string,
    state?: LeaseInventoryStructureStateType | null,
    note?: string | null,
    checkedItems?:  {
      __typename: "ModelLeaseInventoryCheckedItemConnection",
      items:  Array< {
        __typename: "LeaseInventoryCheckedItem",
        id: string,
        custom: boolean,
        leaseInventoryEncodingId: string,
        totalDefects?: number | null,
        customItemId?: string | null,
        value?: string | null,
        type?: InventoryItemType | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    furnitureQuantity?: number | null,
    furniturePrice?: number | null,
    furnitureExitIssues?:  Array< {
      __typename: "LeaseInventoryFurnitureExitIssue",
      quantity: number,
      issue: LeaseInventoryFurnitureExitIssueType,
      action: LeaseInventoryFurnitureExitIssueAction,
      penalty?: number | null,
    } > | null,
    medias?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLeaseInventoryEncodingMutationVariables = {
  input: DeleteLeaseInventoryEncodingInput,
  condition?: ModelLeaseInventoryEncodingConditionInput | null,
};

export type DeleteLeaseInventoryEncodingMutation = {
  deleteLeaseInventoryEncoding?:  {
    __typename: "LeaseInventoryEncoding",
    id: string,
    leaseInventoryId: string,
    structureId: string,
    state?: LeaseInventoryStructureStateType | null,
    note?: string | null,
    checkedItems?:  {
      __typename: "ModelLeaseInventoryCheckedItemConnection",
      items:  Array< {
        __typename: "LeaseInventoryCheckedItem",
        id: string,
        custom: boolean,
        leaseInventoryEncodingId: string,
        totalDefects?: number | null,
        customItemId?: string | null,
        value?: string | null,
        type?: InventoryItemType | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    furnitureQuantity?: number | null,
    furniturePrice?: number | null,
    furnitureExitIssues?:  Array< {
      __typename: "LeaseInventoryFurnitureExitIssue",
      quantity: number,
      issue: LeaseInventoryFurnitureExitIssueType,
      action: LeaseInventoryFurnitureExitIssueAction,
      penalty?: number | null,
    } > | null,
    medias?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLeaseVariousOperationMutationVariables = {
  input: CreateLeaseVariousOperationInput,
  condition?: ModelLeaseVariousOperationConditionInput | null,
};

export type CreateLeaseVariousOperationMutation = {
  createLeaseVariousOperation?:  {
    __typename: "LeaseVariousOperation",
    id: string,
    amount: number,
    contactId: string,
    createdAt: string,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    endDate: string,
    label: string,
    lastInvoiceDate?: string | null,
    leaseId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseVariousOperationHistories?:  Array< {
      __typename: "LeaseVariousOperationHistory",
      periodFrom: string,
      periodTo: string,
      amount: number,
    } > | null,
    operationType: LeaseVariousOperationType,
    paymentFrequency: LeasePaymentFrequency,
    paymentInvoicePeriod: LeasePaymentInvoicePeriod,
    paymentInvoicePeriodCustomDay?: number | null,
    reason?: string | null,
    separateInvoice: boolean,
    startDate: string,
    type: VariousOperationType,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId: string,
    vatRate?: number | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLeaseVariousOperationMutationVariables = {
  input: UpdateLeaseVariousOperationInput,
  condition?: ModelLeaseVariousOperationConditionInput | null,
};

export type UpdateLeaseVariousOperationMutation = {
  updateLeaseVariousOperation?:  {
    __typename: "LeaseVariousOperation",
    id: string,
    amount: number,
    contactId: string,
    createdAt: string,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    endDate: string,
    label: string,
    lastInvoiceDate?: string | null,
    leaseId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseVariousOperationHistories?:  Array< {
      __typename: "LeaseVariousOperationHistory",
      periodFrom: string,
      periodTo: string,
      amount: number,
    } > | null,
    operationType: LeaseVariousOperationType,
    paymentFrequency: LeasePaymentFrequency,
    paymentInvoicePeriod: LeasePaymentInvoicePeriod,
    paymentInvoicePeriodCustomDay?: number | null,
    reason?: string | null,
    separateInvoice: boolean,
    startDate: string,
    type: VariousOperationType,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId: string,
    vatRate?: number | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLeaseVariousOperationMutationVariables = {
  input: DeleteLeaseVariousOperationInput,
  condition?: ModelLeaseVariousOperationConditionInput | null,
};

export type DeleteLeaseVariousOperationMutation = {
  deleteLeaseVariousOperation?:  {
    __typename: "LeaseVariousOperation",
    id: string,
    amount: number,
    contactId: string,
    createdAt: string,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    endDate: string,
    label: string,
    lastInvoiceDate?: string | null,
    leaseId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseVariousOperationHistories?:  Array< {
      __typename: "LeaseVariousOperationHistory",
      periodFrom: string,
      periodTo: string,
      amount: number,
    } > | null,
    operationType: LeaseVariousOperationType,
    paymentFrequency: LeasePaymentFrequency,
    paymentInvoicePeriod: LeasePaymentInvoicePeriod,
    paymentInvoicePeriodCustomDay?: number | null,
    reason?: string | null,
    separateInvoice: boolean,
    startDate: string,
    type: VariousOperationType,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId: string,
    vatRate?: number | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMortgageMutationVariables = {
  input: CreateMortgageInput,
  condition?: ModelMortgageConditionInput | null,
};

export type CreateMortgageMutation = {
  createMortgage?:  {
    __typename: "Mortgage",
    id: string,
    lender: string,
    durationInYears: number,
    rateType: MortgageRateType,
    interestRate: number,
    aprRate: number,
    startDate: string,
    amount: number,
    monthlyPayment: number,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMortgageMutationVariables = {
  input: UpdateMortgageInput,
  condition?: ModelMortgageConditionInput | null,
};

export type UpdateMortgageMutation = {
  updateMortgage?:  {
    __typename: "Mortgage",
    id: string,
    lender: string,
    durationInYears: number,
    rateType: MortgageRateType,
    interestRate: number,
    aprRate: number,
    startDate: string,
    amount: number,
    monthlyPayment: number,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMortgageMutationVariables = {
  input: DeleteMortgageInput,
  condition?: ModelMortgageConditionInput | null,
};

export type DeleteMortgageMutation = {
  deleteMortgage?:  {
    __typename: "Mortgage",
    id: string,
    lender: string,
    durationInYears: number,
    rateType: MortgageRateType,
    interestRate: number,
    aprRate: number,
    startDate: string,
    amount: number,
    monthlyPayment: number,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    createdBy: NotificationCreation,
    type?: NotificationType | null,
    message?: string | null,
    popupDate: string,
    expirationDate?: string | null,
    status: NotificationStatus,
    createdAt?: string | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    technicId?: string | null,
    technic?:  {
      __typename: "Technic",
      id: string,
      type: TechnicType,
      contractNumber?: string | null,
      communicationSettingsProfileId: string,
      maintenanceHistory?:  Array< {
        __typename: "TechnicMaintenanceHistory",
        date: string,
        contactId?: string | null,
        fileId?: string | null,
      } > | null,
      utilityType?: UtilityType | null,
      utilityEanCode?: string | null,
      utilityMeterNumber?: string | null,
      utilityDistributorId?: string | null,
      utilityDistributor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      utilityHistory?:  Array< {
        __typename: "UtilityHistory",
        date: string,
        reason?: UtilityReadingReason | null,
        reading: number,
        secondReading?: number | null,
      } > | null,
      PebValidityDate?: string | null,
      PebEnergeticPerformance?: string | null,
      PebReportNumber?: string | null,
      PebIssueDate?: string | null,
      detectorType?: DetectorType | null,
      detectorSerialNumber?: string | null,
      detectorBrand?: string | null,
      detectorLastControlDate?: string | null,
      detectorUnitInventoryId?: string | null,
      heatingBrand?: string | null,
      heatingModel?: string | null,
      heatingInstallationDate?: string | null,
      heatingType?: HeatingType | null,
      heatingLastControlDate?: string | null,
      tankType?: TankType | null,
      tankCapacity?: number | null,
      tankState?: TankState | null,
      tankLastControlDate?: string | null,
      tankLeakDetection?: boolean | null,
      tankEncuvement?: boolean | null,
      tankWallType?: TankWallType | null,
      tankInstallationDate?: string | null,
      chimneyLastControlDate?: string | null,
      chimneyUnitInventoryId?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildingId?: string | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      originalTechnicId?: string | null,
      readers?: Array< string > | null,
      writers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ticketId?: string | null,
    ticket?:  {
      __typename: "Ticket",
      id: string,
      assignedAt?: string | null,
      assignedTo?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      assignedToId?: string | null,
      attachment?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      buildingId?: string | null,
      closedAt?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId: string,
      content: string,
      conversations?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      emailMessageId?: string | null,
      emailReferences?: Array< string | null > | null,
      parentId?: string | null,
      plannedAt?: string | null,
      referenceCode?: string | null,
      sourceType: TicketSourceType,
      status?: TicketStatus | null,
      subject: string,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unread: boolean,
      updatedAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    createdBy: NotificationCreation,
    type?: NotificationType | null,
    message?: string | null,
    popupDate: string,
    expirationDate?: string | null,
    status: NotificationStatus,
    createdAt?: string | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    technicId?: string | null,
    technic?:  {
      __typename: "Technic",
      id: string,
      type: TechnicType,
      contractNumber?: string | null,
      communicationSettingsProfileId: string,
      maintenanceHistory?:  Array< {
        __typename: "TechnicMaintenanceHistory",
        date: string,
        contactId?: string | null,
        fileId?: string | null,
      } > | null,
      utilityType?: UtilityType | null,
      utilityEanCode?: string | null,
      utilityMeterNumber?: string | null,
      utilityDistributorId?: string | null,
      utilityDistributor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      utilityHistory?:  Array< {
        __typename: "UtilityHistory",
        date: string,
        reason?: UtilityReadingReason | null,
        reading: number,
        secondReading?: number | null,
      } > | null,
      PebValidityDate?: string | null,
      PebEnergeticPerformance?: string | null,
      PebReportNumber?: string | null,
      PebIssueDate?: string | null,
      detectorType?: DetectorType | null,
      detectorSerialNumber?: string | null,
      detectorBrand?: string | null,
      detectorLastControlDate?: string | null,
      detectorUnitInventoryId?: string | null,
      heatingBrand?: string | null,
      heatingModel?: string | null,
      heatingInstallationDate?: string | null,
      heatingType?: HeatingType | null,
      heatingLastControlDate?: string | null,
      tankType?: TankType | null,
      tankCapacity?: number | null,
      tankState?: TankState | null,
      tankLastControlDate?: string | null,
      tankLeakDetection?: boolean | null,
      tankEncuvement?: boolean | null,
      tankWallType?: TankWallType | null,
      tankInstallationDate?: string | null,
      chimneyLastControlDate?: string | null,
      chimneyUnitInventoryId?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildingId?: string | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      originalTechnicId?: string | null,
      readers?: Array< string > | null,
      writers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ticketId?: string | null,
    ticket?:  {
      __typename: "Ticket",
      id: string,
      assignedAt?: string | null,
      assignedTo?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      assignedToId?: string | null,
      attachment?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      buildingId?: string | null,
      closedAt?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId: string,
      content: string,
      conversations?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      emailMessageId?: string | null,
      emailReferences?: Array< string | null > | null,
      parentId?: string | null,
      plannedAt?: string | null,
      referenceCode?: string | null,
      sourceType: TicketSourceType,
      status?: TicketStatus | null,
      subject: string,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unread: boolean,
      updatedAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    createdBy: NotificationCreation,
    type?: NotificationType | null,
    message?: string | null,
    popupDate: string,
    expirationDate?: string | null,
    status: NotificationStatus,
    createdAt?: string | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    technicId?: string | null,
    technic?:  {
      __typename: "Technic",
      id: string,
      type: TechnicType,
      contractNumber?: string | null,
      communicationSettingsProfileId: string,
      maintenanceHistory?:  Array< {
        __typename: "TechnicMaintenanceHistory",
        date: string,
        contactId?: string | null,
        fileId?: string | null,
      } > | null,
      utilityType?: UtilityType | null,
      utilityEanCode?: string | null,
      utilityMeterNumber?: string | null,
      utilityDistributorId?: string | null,
      utilityDistributor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      utilityHistory?:  Array< {
        __typename: "UtilityHistory",
        date: string,
        reason?: UtilityReadingReason | null,
        reading: number,
        secondReading?: number | null,
      } > | null,
      PebValidityDate?: string | null,
      PebEnergeticPerformance?: string | null,
      PebReportNumber?: string | null,
      PebIssueDate?: string | null,
      detectorType?: DetectorType | null,
      detectorSerialNumber?: string | null,
      detectorBrand?: string | null,
      detectorLastControlDate?: string | null,
      detectorUnitInventoryId?: string | null,
      heatingBrand?: string | null,
      heatingModel?: string | null,
      heatingInstallationDate?: string | null,
      heatingType?: HeatingType | null,
      heatingLastControlDate?: string | null,
      tankType?: TankType | null,
      tankCapacity?: number | null,
      tankState?: TankState | null,
      tankLastControlDate?: string | null,
      tankLeakDetection?: boolean | null,
      tankEncuvement?: boolean | null,
      tankWallType?: TankWallType | null,
      tankInstallationDate?: string | null,
      chimneyLastControlDate?: string | null,
      chimneyUnitInventoryId?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildingId?: string | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      originalTechnicId?: string | null,
      readers?: Array< string > | null,
      writers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ticketId?: string | null,
    ticket?:  {
      __typename: "Ticket",
      id: string,
      assignedAt?: string | null,
      assignedTo?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      assignedToId?: string | null,
      attachment?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      buildingId?: string | null,
      closedAt?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId: string,
      content: string,
      conversations?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      emailMessageId?: string | null,
      emailReferences?: Array< string | null > | null,
      parentId?: string | null,
      plannedAt?: string | null,
      referenceCode?: string | null,
      sourceType: TicketSourceType,
      status?: TicketStatus | null,
      subject: string,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unread: boolean,
      updatedAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePostingMutationVariables = {
  input: CreatePostingInput,
  condition?: ModelPostingConditionInput | null,
};

export type CreatePostingMutation = {
  createPosting?:  {
    __typename: "Posting",
    id: string,
    createdAt?: string | null,
    class: number,
    customLabel?: string | null,
    topClass: number,
    type: PostingType,
    totalAmount: number,
    periodFrom: string,
    periodTo: string,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    invoiceId?: string | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      creditNote?: boolean | null,
      creditNoteLabel?: string | null,
      dueDate?: string | null,
      invoiceDate: string,
      invoiceNumber?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      paid: boolean,
      receiptNumber?: string | null,
      reminderDates?: Array< string > | null,
      remittanceInformation?: string | null,
      statementId?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      type: InvoiceType,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    chargeId?: string | null,
    charge?:  {
      __typename: "Charge",
      id: string,
      status: ChargeStatus,
      invoiceDate?: string | null,
      paymentDueDate?: string | null,
      archivedDate?: string | null,
      periodFrom?: string | null,
      periodTo?: string | null,
      totalAmount?: number | null,
      source: ChargeSource,
      draftFromOcr?: boolean | null,
      accountingDocumentId?: string | null,
      paid: boolean,
      remittanceInformation?: string | null,
      note?: string | null,
      createdAt: string,
      creatorEmail?: string | null,
      createdById?: string | null,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contractorId?: string | null,
      contractor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedContractors?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      recipientId?: string | null,
      recipient?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedRecipients?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      documentId: string,
      document?:  {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      postings?:  {
        __typename: "ModelPostingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    transactionId?: string | null,
    transaction?:  {
      __typename: "Transaction",
      id: string,
      status: TransactionStatus,
      sequence?: number | null,
      statementDate: string,
      operationDate?: string | null,
      createdAt?: string | null,
      remittanceInformationType?: RemittanceType | null,
      remittanceInformation?: string | null,
      comment?: string | null,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      counterpartName?: string | null,
      counterpartAccountNumber?: string | null,
      manualEntry?: boolean | null,
      proposedLeaseId?: string | null,
      proposedUnitId?: string | null,
      proposedContractorId?: string | null,
      proposedMatch?: number | null,
      links?:  Array< {
        __typename: "TransactionLink",
        amount: number,
        linkId: string,
        linkType: TransactionLinkType,
      } > | null,
      postings?:  {
        __typename: "ModelPostingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    bankAccountId?: string | null,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    accountLabelId?: string | null,
    accountLabel?:  {
      __typename: "AccountLabel",
      id: string,
      labels?:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } > | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      class: number,
      topClass: number,
      customUsage?: AccountLabelCustomUsage | null,
      disabled?: boolean | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseVariousOperationId?: string | null,
    leaseVariousOperation?:  {
      __typename: "LeaseVariousOperation",
      id: string,
      amount: number,
      contactId: string,
      createdAt: string,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      endDate: string,
      label: string,
      lastInvoiceDate?: string | null,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseVariousOperationHistories?:  Array< {
        __typename: "LeaseVariousOperationHistory",
        periodFrom: string,
        periodTo: string,
        amount: number,
      } > | null,
      operationType: LeaseVariousOperationType,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      reason?: string | null,
      separateInvoice: boolean,
      startDate: string,
      type: VariousOperationType,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId: string,
      vatRate?: number | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId?: string | null,
    amountVatExcluded?: number | null,
    vatAmount?: number | null,
    vatRate?: number | null,
    foreignId?: string | null,
    foreignType?: PostingForeignType | null,
    invoicePostingId?: string | null,
    reconciledAt?: string | null,
    status?: PostingStatus | null,
    chargeOwnerRepartition?: number | null,
    chargeRepartitionMetric?: string | null,
    chargeRepartitionValue?: number | null,
    chargeSplitNumber?: number | null,
    chargeType?: PostingChargeType | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePostingMutationVariables = {
  input: UpdatePostingInput,
  condition?: ModelPostingConditionInput | null,
};

export type UpdatePostingMutation = {
  updatePosting?:  {
    __typename: "Posting",
    id: string,
    createdAt?: string | null,
    class: number,
    customLabel?: string | null,
    topClass: number,
    type: PostingType,
    totalAmount: number,
    periodFrom: string,
    periodTo: string,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    invoiceId?: string | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      creditNote?: boolean | null,
      creditNoteLabel?: string | null,
      dueDate?: string | null,
      invoiceDate: string,
      invoiceNumber?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      paid: boolean,
      receiptNumber?: string | null,
      reminderDates?: Array< string > | null,
      remittanceInformation?: string | null,
      statementId?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      type: InvoiceType,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    chargeId?: string | null,
    charge?:  {
      __typename: "Charge",
      id: string,
      status: ChargeStatus,
      invoiceDate?: string | null,
      paymentDueDate?: string | null,
      archivedDate?: string | null,
      periodFrom?: string | null,
      periodTo?: string | null,
      totalAmount?: number | null,
      source: ChargeSource,
      draftFromOcr?: boolean | null,
      accountingDocumentId?: string | null,
      paid: boolean,
      remittanceInformation?: string | null,
      note?: string | null,
      createdAt: string,
      creatorEmail?: string | null,
      createdById?: string | null,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contractorId?: string | null,
      contractor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedContractors?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      recipientId?: string | null,
      recipient?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedRecipients?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      documentId: string,
      document?:  {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      postings?:  {
        __typename: "ModelPostingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    transactionId?: string | null,
    transaction?:  {
      __typename: "Transaction",
      id: string,
      status: TransactionStatus,
      sequence?: number | null,
      statementDate: string,
      operationDate?: string | null,
      createdAt?: string | null,
      remittanceInformationType?: RemittanceType | null,
      remittanceInformation?: string | null,
      comment?: string | null,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      counterpartName?: string | null,
      counterpartAccountNumber?: string | null,
      manualEntry?: boolean | null,
      proposedLeaseId?: string | null,
      proposedUnitId?: string | null,
      proposedContractorId?: string | null,
      proposedMatch?: number | null,
      links?:  Array< {
        __typename: "TransactionLink",
        amount: number,
        linkId: string,
        linkType: TransactionLinkType,
      } > | null,
      postings?:  {
        __typename: "ModelPostingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    bankAccountId?: string | null,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    accountLabelId?: string | null,
    accountLabel?:  {
      __typename: "AccountLabel",
      id: string,
      labels?:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } > | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      class: number,
      topClass: number,
      customUsage?: AccountLabelCustomUsage | null,
      disabled?: boolean | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseVariousOperationId?: string | null,
    leaseVariousOperation?:  {
      __typename: "LeaseVariousOperation",
      id: string,
      amount: number,
      contactId: string,
      createdAt: string,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      endDate: string,
      label: string,
      lastInvoiceDate?: string | null,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseVariousOperationHistories?:  Array< {
        __typename: "LeaseVariousOperationHistory",
        periodFrom: string,
        periodTo: string,
        amount: number,
      } > | null,
      operationType: LeaseVariousOperationType,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      reason?: string | null,
      separateInvoice: boolean,
      startDate: string,
      type: VariousOperationType,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId: string,
      vatRate?: number | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId?: string | null,
    amountVatExcluded?: number | null,
    vatAmount?: number | null,
    vatRate?: number | null,
    foreignId?: string | null,
    foreignType?: PostingForeignType | null,
    invoicePostingId?: string | null,
    reconciledAt?: string | null,
    status?: PostingStatus | null,
    chargeOwnerRepartition?: number | null,
    chargeRepartitionMetric?: string | null,
    chargeRepartitionValue?: number | null,
    chargeSplitNumber?: number | null,
    chargeType?: PostingChargeType | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePostingMutationVariables = {
  input: DeletePostingInput,
  condition?: ModelPostingConditionInput | null,
};

export type DeletePostingMutation = {
  deletePosting?:  {
    __typename: "Posting",
    id: string,
    createdAt?: string | null,
    class: number,
    customLabel?: string | null,
    topClass: number,
    type: PostingType,
    totalAmount: number,
    periodFrom: string,
    periodTo: string,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    invoiceId?: string | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      creditNote?: boolean | null,
      creditNoteLabel?: string | null,
      dueDate?: string | null,
      invoiceDate: string,
      invoiceNumber?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      paid: boolean,
      receiptNumber?: string | null,
      reminderDates?: Array< string > | null,
      remittanceInformation?: string | null,
      statementId?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      type: InvoiceType,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    chargeId?: string | null,
    charge?:  {
      __typename: "Charge",
      id: string,
      status: ChargeStatus,
      invoiceDate?: string | null,
      paymentDueDate?: string | null,
      archivedDate?: string | null,
      periodFrom?: string | null,
      periodTo?: string | null,
      totalAmount?: number | null,
      source: ChargeSource,
      draftFromOcr?: boolean | null,
      accountingDocumentId?: string | null,
      paid: boolean,
      remittanceInformation?: string | null,
      note?: string | null,
      createdAt: string,
      creatorEmail?: string | null,
      createdById?: string | null,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contractorId?: string | null,
      contractor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedContractors?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      recipientId?: string | null,
      recipient?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedRecipients?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      documentId: string,
      document?:  {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      postings?:  {
        __typename: "ModelPostingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    transactionId?: string | null,
    transaction?:  {
      __typename: "Transaction",
      id: string,
      status: TransactionStatus,
      sequence?: number | null,
      statementDate: string,
      operationDate?: string | null,
      createdAt?: string | null,
      remittanceInformationType?: RemittanceType | null,
      remittanceInformation?: string | null,
      comment?: string | null,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      counterpartName?: string | null,
      counterpartAccountNumber?: string | null,
      manualEntry?: boolean | null,
      proposedLeaseId?: string | null,
      proposedUnitId?: string | null,
      proposedContractorId?: string | null,
      proposedMatch?: number | null,
      links?:  Array< {
        __typename: "TransactionLink",
        amount: number,
        linkId: string,
        linkType: TransactionLinkType,
      } > | null,
      postings?:  {
        __typename: "ModelPostingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    bankAccountId?: string | null,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    accountLabelId?: string | null,
    accountLabel?:  {
      __typename: "AccountLabel",
      id: string,
      labels?:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } > | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      class: number,
      topClass: number,
      customUsage?: AccountLabelCustomUsage | null,
      disabled?: boolean | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseVariousOperationId?: string | null,
    leaseVariousOperation?:  {
      __typename: "LeaseVariousOperation",
      id: string,
      amount: number,
      contactId: string,
      createdAt: string,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      endDate: string,
      label: string,
      lastInvoiceDate?: string | null,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseVariousOperationHistories?:  Array< {
        __typename: "LeaseVariousOperationHistory",
        periodFrom: string,
        periodTo: string,
        amount: number,
      } > | null,
      operationType: LeaseVariousOperationType,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      reason?: string | null,
      separateInvoice: boolean,
      startDate: string,
      type: VariousOperationType,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId: string,
      vatRate?: number | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId?: string | null,
    amountVatExcluded?: number | null,
    vatAmount?: number | null,
    vatRate?: number | null,
    foreignId?: string | null,
    foreignType?: PostingForeignType | null,
    invoicePostingId?: string | null,
    reconciledAt?: string | null,
    status?: PostingStatus | null,
    chargeOwnerRepartition?: number | null,
    chargeRepartitionMetric?: string | null,
    chargeRepartitionValue?: number | null,
    chargeSplitNumber?: number | null,
    chargeType?: PostingChargeType | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateRepartitionKeyMutationVariables = {
  input: CreateRepartitionKeyInput,
  condition?: ModelRepartitionKeyConditionInput | null,
};

export type CreateRepartitionKeyMutation = {
  createRepartitionKey?:  {
    __typename: "RepartitionKey",
    id: string,
    name: string,
    type: RepartitionKeyType,
    accountLabelId: string,
    accountLabel?:  {
      __typename: "AccountLabel",
      id: string,
      labels?:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } > | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      class: number,
      topClass: number,
      customUsage?: AccountLabelCustomUsage | null,
      disabled?: boolean | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitRepartition?:  Array< {
      __typename: "RepartitionValues",
      unitId: string,
      value: number,
    } > | null,
    ownerRepartition: number,
    tenantRepartition: number,
    repartitionMetric: string,
    technics?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateRepartitionKeyMutationVariables = {
  input: UpdateRepartitionKeyInput,
  condition?: ModelRepartitionKeyConditionInput | null,
};

export type UpdateRepartitionKeyMutation = {
  updateRepartitionKey?:  {
    __typename: "RepartitionKey",
    id: string,
    name: string,
    type: RepartitionKeyType,
    accountLabelId: string,
    accountLabel?:  {
      __typename: "AccountLabel",
      id: string,
      labels?:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } > | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      class: number,
      topClass: number,
      customUsage?: AccountLabelCustomUsage | null,
      disabled?: boolean | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitRepartition?:  Array< {
      __typename: "RepartitionValues",
      unitId: string,
      value: number,
    } > | null,
    ownerRepartition: number,
    tenantRepartition: number,
    repartitionMetric: string,
    technics?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteRepartitionKeyMutationVariables = {
  input: DeleteRepartitionKeyInput,
  condition?: ModelRepartitionKeyConditionInput | null,
};

export type DeleteRepartitionKeyMutation = {
  deleteRepartitionKey?:  {
    __typename: "RepartitionKey",
    id: string,
    name: string,
    type: RepartitionKeyType,
    accountLabelId: string,
    accountLabel?:  {
      __typename: "AccountLabel",
      id: string,
      labels?:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } > | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      class: number,
      topClass: number,
      customUsage?: AccountLabelCustomUsage | null,
      disabled?: boolean | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitRepartition?:  Array< {
      __typename: "RepartitionValues",
      unitId: string,
      value: number,
    } > | null,
    ownerRepartition: number,
    tenantRepartition: number,
    repartitionMetric: string,
    technics?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateRepaymentStatementMutationVariables = {
  input: CreateRepaymentStatementInput,
  condition?: ModelRepaymentStatementConditionInput | null,
};

export type CreateRepaymentStatementMutation = {
  createRepaymentStatement?:  {
    __typename: "RepaymentStatement",
    id: string,
    delaysCleared: number,
    expectedRent: number,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId: string,
    rentsCollected: number,
    repaymentStatementDetails:  Array< {
      __typename: "RepaymentStatementDetail",
      class: number,
      invoiceId: string,
      invoicePostingId: string,
      periodFrom: string,
      periodTo: string,
      totalAmount: number,
      transactionId?: string | null,
      type: RepaymentStatementDetailType,
    } >,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId: string,
    totalReceivedInJointAccount: number,
    totalExpectedInOwnerAccount: number,
    unpaidRents: number,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateRepaymentStatementMutationVariables = {
  input: UpdateRepaymentStatementInput,
  condition?: ModelRepaymentStatementConditionInput | null,
};

export type UpdateRepaymentStatementMutation = {
  updateRepaymentStatement?:  {
    __typename: "RepaymentStatement",
    id: string,
    delaysCleared: number,
    expectedRent: number,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId: string,
    rentsCollected: number,
    repaymentStatementDetails:  Array< {
      __typename: "RepaymentStatementDetail",
      class: number,
      invoiceId: string,
      invoicePostingId: string,
      periodFrom: string,
      periodTo: string,
      totalAmount: number,
      transactionId?: string | null,
      type: RepaymentStatementDetailType,
    } >,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId: string,
    totalReceivedInJointAccount: number,
    totalExpectedInOwnerAccount: number,
    unpaidRents: number,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteRepaymentStatementMutationVariables = {
  input: DeleteRepaymentStatementInput,
  condition?: ModelRepaymentStatementConditionInput | null,
};

export type DeleteRepaymentStatementMutation = {
  deleteRepaymentStatement?:  {
    __typename: "RepaymentStatement",
    id: string,
    delaysCleared: number,
    expectedRent: number,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId: string,
    rentsCollected: number,
    repaymentStatementDetails:  Array< {
      __typename: "RepaymentStatementDetail",
      class: number,
      invoiceId: string,
      invoicePostingId: string,
      periodFrom: string,
      periodTo: string,
      totalAmount: number,
      transactionId?: string | null,
      type: RepaymentStatementDetailType,
    } >,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId: string,
    totalReceivedInJointAccount: number,
    totalExpectedInOwnerAccount: number,
    unpaidRents: number,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSendingSourceMutationVariables = {
  input: CreateSendingSourceInput,
  condition?: ModelSendingSourceConditionInput | null,
};

export type CreateSendingSourceMutation = {
  createSendingSource?:  {
    __typename: "SendingSource",
    id: string,
    createdAt?: string | null,
    createdById?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dnsRecords?:  Array< {
      __typename: "DNSRecord",
      name: string,
      type: string,
      value: string,
    } > | null,
    emailAddress: string,
    issuesEmail: string,
    chargesEmail: string,
    errorMessage?: SendingSourceErrorType | null,
    name: string,
    lastReminderDate?: string | null,
    status: SendingSourceStatus,
    statusLastVerification?: string | null,
    verifiedType: SendingSourceVerifiedType,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSendingSourceMutationVariables = {
  input: UpdateSendingSourceInput,
  condition?: ModelSendingSourceConditionInput | null,
};

export type UpdateSendingSourceMutation = {
  updateSendingSource?:  {
    __typename: "SendingSource",
    id: string,
    createdAt?: string | null,
    createdById?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dnsRecords?:  Array< {
      __typename: "DNSRecord",
      name: string,
      type: string,
      value: string,
    } > | null,
    emailAddress: string,
    issuesEmail: string,
    chargesEmail: string,
    errorMessage?: SendingSourceErrorType | null,
    name: string,
    lastReminderDate?: string | null,
    status: SendingSourceStatus,
    statusLastVerification?: string | null,
    verifiedType: SendingSourceVerifiedType,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSendingSourceMutationVariables = {
  input: DeleteSendingSourceInput,
  condition?: ModelSendingSourceConditionInput | null,
};

export type DeleteSendingSourceMutation = {
  deleteSendingSource?:  {
    __typename: "SendingSource",
    id: string,
    createdAt?: string | null,
    createdById?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dnsRecords?:  Array< {
      __typename: "DNSRecord",
      name: string,
      type: string,
      value: string,
    } > | null,
    emailAddress: string,
    issuesEmail: string,
    chargesEmail: string,
    errorMessage?: SendingSourceErrorType | null,
    name: string,
    lastReminderDate?: string | null,
    status: SendingSourceStatus,
    statusLastVerification?: string | null,
    verifiedType: SendingSourceVerifiedType,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSettingMutationVariables = {
  input: CreateSettingInput,
  condition?: ModelSettingConditionInput | null,
};

export type CreateSettingMutation = {
  createSetting?:  {
    __typename: "Setting",
    id: string,
    name: SettingType,
    value: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSettingMutationVariables = {
  input: UpdateSettingInput,
  condition?: ModelSettingConditionInput | null,
};

export type UpdateSettingMutation = {
  updateSetting?:  {
    __typename: "Setting",
    id: string,
    name: SettingType,
    value: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSettingMutationVariables = {
  input: DeleteSettingInput,
  condition?: ModelSettingConditionInput | null,
};

export type DeleteSettingMutation = {
  deleteSetting?:  {
    __typename: "Setting",
    id: string,
    name: SettingType,
    value: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSignatureDocumentMutationVariables = {
  input: CreateSignatureDocumentInput,
  condition?: ModelSignatureDocumentConditionInput | null,
};

export type CreateSignatureDocumentMutation = {
  createSignatureDocument?:  {
    __typename: "SignatureDocument",
    id: string,
    additionalMessage?: string | null,
    communicationBody?: string | null,
    createdAt?: string | null,
    expirationDate?: string | null,
    fileIds?: Array< string > | null,
    foreignKey: string,
    foreignTableName: string,
    sentAt?: string | null,
    signatureMethods: Array< SignatureType >,
    signatureResults:  Array< {
      __typename: "SignatureDocumentResult",
      openedDate?: string | null,
      signedDate?: string | null,
      signed?: boolean | null,
      signatureLink?: string | null,
      rejectReason?: string | null,
      lastSignInviteSentDate?: string | null,
      trackingProvider?: TrackingProvider | null,
      trackingCode?: string | null,
      contactId: string,
    } >,
    status: SignatureDocumentStatus,
    externalStatus?: string | null,
    type: SignatureDocumentType,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSignatureDocumentMutationVariables = {
  input: UpdateSignatureDocumentInput,
  condition?: ModelSignatureDocumentConditionInput | null,
};

export type UpdateSignatureDocumentMutation = {
  updateSignatureDocument?:  {
    __typename: "SignatureDocument",
    id: string,
    additionalMessage?: string | null,
    communicationBody?: string | null,
    createdAt?: string | null,
    expirationDate?: string | null,
    fileIds?: Array< string > | null,
    foreignKey: string,
    foreignTableName: string,
    sentAt?: string | null,
    signatureMethods: Array< SignatureType >,
    signatureResults:  Array< {
      __typename: "SignatureDocumentResult",
      openedDate?: string | null,
      signedDate?: string | null,
      signed?: boolean | null,
      signatureLink?: string | null,
      rejectReason?: string | null,
      lastSignInviteSentDate?: string | null,
      trackingProvider?: TrackingProvider | null,
      trackingCode?: string | null,
      contactId: string,
    } >,
    status: SignatureDocumentStatus,
    externalStatus?: string | null,
    type: SignatureDocumentType,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSignatureDocumentMutationVariables = {
  input: DeleteSignatureDocumentInput,
  condition?: ModelSignatureDocumentConditionInput | null,
};

export type DeleteSignatureDocumentMutation = {
  deleteSignatureDocument?:  {
    __typename: "SignatureDocument",
    id: string,
    additionalMessage?: string | null,
    communicationBody?: string | null,
    createdAt?: string | null,
    expirationDate?: string | null,
    fileIds?: Array< string > | null,
    foreignKey: string,
    foreignTableName: string,
    sentAt?: string | null,
    signatureMethods: Array< SignatureType >,
    signatureResults:  Array< {
      __typename: "SignatureDocumentResult",
      openedDate?: string | null,
      signedDate?: string | null,
      signed?: boolean | null,
      signatureLink?: string | null,
      rejectReason?: string | null,
      lastSignInviteSentDate?: string | null,
      trackingProvider?: TrackingProvider | null,
      trackingCode?: string | null,
      contactId: string,
    } >,
    status: SignatureDocumentStatus,
    externalStatus?: string | null,
    type: SignatureDocumentType,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateStatementMutationVariables = {
  input: CreateStatementInput,
  condition?: ModelStatementConditionInput | null,
};

export type CreateStatementMutation = {
  createStatement?:  {
    __typename: "Statement",
    id: string,
    amounts?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    chargeStatements?:  {
      __typename: "ModelChargeStatementConnection",
      items:  Array< {
        __typename: "ChargeStatement",
        id: string,
        chargeId: string,
        statementId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    closingStatement: boolean,
    periodFrom: string,
    periodTo: string,
    source: StatementSource,
    status: StatementStatus,
    sentDate?: string | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    agencyRateStatements?:  {
      __typename: "ModelAgencyRateStatementConnection",
      items:  Array< {
        __typename: "AgencyRateStatement",
        id: string,
        agencyRateId: string,
        statementId: string,
        totalAmountRateable: number,
        totalFixedAmount: number,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    repaymentStatements?:  {
      __typename: "ModelRepaymentStatementConnection",
      items:  Array< {
        __typename: "RepaymentStatement",
        id: string,
        delaysCleared: number,
        expectedRent: number,
        leaseId: string,
        rentsCollected: number,
        statementId: string,
        totalReceivedInJointAccount: number,
        totalExpectedInOwnerAccount: number,
        unpaidRents: number,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    types?: Array< StatementType > | null,
    unitIds?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateStatementMutationVariables = {
  input: UpdateStatementInput,
  condition?: ModelStatementConditionInput | null,
};

export type UpdateStatementMutation = {
  updateStatement?:  {
    __typename: "Statement",
    id: string,
    amounts?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    chargeStatements?:  {
      __typename: "ModelChargeStatementConnection",
      items:  Array< {
        __typename: "ChargeStatement",
        id: string,
        chargeId: string,
        statementId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    closingStatement: boolean,
    periodFrom: string,
    periodTo: string,
    source: StatementSource,
    status: StatementStatus,
    sentDate?: string | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    agencyRateStatements?:  {
      __typename: "ModelAgencyRateStatementConnection",
      items:  Array< {
        __typename: "AgencyRateStatement",
        id: string,
        agencyRateId: string,
        statementId: string,
        totalAmountRateable: number,
        totalFixedAmount: number,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    repaymentStatements?:  {
      __typename: "ModelRepaymentStatementConnection",
      items:  Array< {
        __typename: "RepaymentStatement",
        id: string,
        delaysCleared: number,
        expectedRent: number,
        leaseId: string,
        rentsCollected: number,
        statementId: string,
        totalReceivedInJointAccount: number,
        totalExpectedInOwnerAccount: number,
        unpaidRents: number,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    types?: Array< StatementType > | null,
    unitIds?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteStatementMutationVariables = {
  input: DeleteStatementInput,
  condition?: ModelStatementConditionInput | null,
};

export type DeleteStatementMutation = {
  deleteStatement?:  {
    __typename: "Statement",
    id: string,
    amounts?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    chargeStatements?:  {
      __typename: "ModelChargeStatementConnection",
      items:  Array< {
        __typename: "ChargeStatement",
        id: string,
        chargeId: string,
        statementId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    closingStatement: boolean,
    periodFrom: string,
    periodTo: string,
    source: StatementSource,
    status: StatementStatus,
    sentDate?: string | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    agencyRateStatements?:  {
      __typename: "ModelAgencyRateStatementConnection",
      items:  Array< {
        __typename: "AgencyRateStatement",
        id: string,
        agencyRateId: string,
        statementId: string,
        totalAmountRateable: number,
        totalFixedAmount: number,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    repaymentStatements?:  {
      __typename: "ModelRepaymentStatementConnection",
      items:  Array< {
        __typename: "RepaymentStatement",
        id: string,
        delaysCleared: number,
        expectedRent: number,
        leaseId: string,
        rentsCollected: number,
        statementId: string,
        totalReceivedInJointAccount: number,
        totalExpectedInOwnerAccount: number,
        unpaidRents: number,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    types?: Array< StatementType > | null,
    unitIds?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateStatementSettingMutationVariables = {
  input: CreateStatementSettingInput,
  condition?: ModelStatementSettingConditionInput | null,
};

export type CreateStatementSettingMutation = {
  createStatementSetting?:  {
    __typename: "StatementSetting",
    id: string,
    type: StatementSettingType,
    custom: boolean,
    disabled?: boolean | null,
    monthFrequency: number,
    startMonth?: string | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateStatementSettingMutationVariables = {
  input: UpdateStatementSettingInput,
  condition?: ModelStatementSettingConditionInput | null,
};

export type UpdateStatementSettingMutation = {
  updateStatementSetting?:  {
    __typename: "StatementSetting",
    id: string,
    type: StatementSettingType,
    custom: boolean,
    disabled?: boolean | null,
    monthFrequency: number,
    startMonth?: string | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteStatementSettingMutationVariables = {
  input: DeleteStatementSettingInput,
  condition?: ModelStatementSettingConditionInput | null,
};

export type DeleteStatementSettingMutation = {
  deleteStatementSetting?:  {
    __typename: "StatementSetting",
    id: string,
    type: StatementSettingType,
    custom: boolean,
    disabled?: boolean | null,
    monthFrequency: number,
    startMonth?: string | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTeamMutationVariables = {
  input: CreateTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type CreateTeamMutation = {
  createTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    parentId?: string | null,
    children?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        parentId?: string | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users?:  {
      __typename: "ModelUserTeamConnection",
      items:  Array< {
        __typename: "UserTeam",
        id: string,
        userId: string,
        teamId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTeamMutationVariables = {
  input: UpdateTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type UpdateTeamMutation = {
  updateTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    parentId?: string | null,
    children?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        parentId?: string | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users?:  {
      __typename: "ModelUserTeamConnection",
      items:  Array< {
        __typename: "UserTeam",
        id: string,
        userId: string,
        teamId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTeamMutationVariables = {
  input: DeleteTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type DeleteTeamMutation = {
  deleteTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    parentId?: string | null,
    children?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        parentId?: string | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users?:  {
      __typename: "ModelUserTeamConnection",
      items:  Array< {
        __typename: "UserTeam",
        id: string,
        userId: string,
        teamId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTechnicMutationVariables = {
  input: CreateTechnicInput,
  condition?: ModelTechnicConditionInput | null,
};

export type CreateTechnicMutation = {
  createTechnic?:  {
    __typename: "Technic",
    id: string,
    type: TechnicType,
    contractNumber?: string | null,
    communicationSettingsProfileId: string,
    maintenanceHistory?:  Array< {
      __typename: "TechnicMaintenanceHistory",
      date: string,
      contactId?: string | null,
      fileId?: string | null,
    } > | null,
    utilityType?: UtilityType | null,
    utilityEanCode?: string | null,
    utilityMeterNumber?: string | null,
    utilityDistributorId?: string | null,
    utilityDistributor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    utilityHistory?:  Array< {
      __typename: "UtilityHistory",
      date: string,
      reason?: UtilityReadingReason | null,
      reading: number,
      secondReading?: number | null,
    } > | null,
    PebValidityDate?: string | null,
    PebEnergeticPerformance?: string | null,
    PebReportNumber?: string | null,
    PebIssueDate?: string | null,
    detectorType?: DetectorType | null,
    detectorSerialNumber?: string | null,
    detectorBrand?: string | null,
    detectorLastControlDate?: string | null,
    detectorUnitInventoryId?: string | null,
    heatingBrand?: string | null,
    heatingModel?: string | null,
    heatingInstallationDate?: string | null,
    heatingType?: HeatingType | null,
    heatingLastControlDate?: string | null,
    tankType?: TankType | null,
    tankCapacity?: number | null,
    tankState?: TankState | null,
    tankLastControlDate?: string | null,
    tankLeakDetection?: boolean | null,
    tankEncuvement?: boolean | null,
    tankWallType?: TankWallType | null,
    tankInstallationDate?: string | null,
    chimneyLastControlDate?: string | null,
    chimneyUnitInventoryId?: string | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    originalTechnicId?: string | null,
    readers?: Array< string > | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTechnicMutationVariables = {
  input: UpdateTechnicInput,
  condition?: ModelTechnicConditionInput | null,
};

export type UpdateTechnicMutation = {
  updateTechnic?:  {
    __typename: "Technic",
    id: string,
    type: TechnicType,
    contractNumber?: string | null,
    communicationSettingsProfileId: string,
    maintenanceHistory?:  Array< {
      __typename: "TechnicMaintenanceHistory",
      date: string,
      contactId?: string | null,
      fileId?: string | null,
    } > | null,
    utilityType?: UtilityType | null,
    utilityEanCode?: string | null,
    utilityMeterNumber?: string | null,
    utilityDistributorId?: string | null,
    utilityDistributor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    utilityHistory?:  Array< {
      __typename: "UtilityHistory",
      date: string,
      reason?: UtilityReadingReason | null,
      reading: number,
      secondReading?: number | null,
    } > | null,
    PebValidityDate?: string | null,
    PebEnergeticPerformance?: string | null,
    PebReportNumber?: string | null,
    PebIssueDate?: string | null,
    detectorType?: DetectorType | null,
    detectorSerialNumber?: string | null,
    detectorBrand?: string | null,
    detectorLastControlDate?: string | null,
    detectorUnitInventoryId?: string | null,
    heatingBrand?: string | null,
    heatingModel?: string | null,
    heatingInstallationDate?: string | null,
    heatingType?: HeatingType | null,
    heatingLastControlDate?: string | null,
    tankType?: TankType | null,
    tankCapacity?: number | null,
    tankState?: TankState | null,
    tankLastControlDate?: string | null,
    tankLeakDetection?: boolean | null,
    tankEncuvement?: boolean | null,
    tankWallType?: TankWallType | null,
    tankInstallationDate?: string | null,
    chimneyLastControlDate?: string | null,
    chimneyUnitInventoryId?: string | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    originalTechnicId?: string | null,
    readers?: Array< string > | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTechnicMutationVariables = {
  input: DeleteTechnicInput,
  condition?: ModelTechnicConditionInput | null,
};

export type DeleteTechnicMutation = {
  deleteTechnic?:  {
    __typename: "Technic",
    id: string,
    type: TechnicType,
    contractNumber?: string | null,
    communicationSettingsProfileId: string,
    maintenanceHistory?:  Array< {
      __typename: "TechnicMaintenanceHistory",
      date: string,
      contactId?: string | null,
      fileId?: string | null,
    } > | null,
    utilityType?: UtilityType | null,
    utilityEanCode?: string | null,
    utilityMeterNumber?: string | null,
    utilityDistributorId?: string | null,
    utilityDistributor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    utilityHistory?:  Array< {
      __typename: "UtilityHistory",
      date: string,
      reason?: UtilityReadingReason | null,
      reading: number,
      secondReading?: number | null,
    } > | null,
    PebValidityDate?: string | null,
    PebEnergeticPerformance?: string | null,
    PebReportNumber?: string | null,
    PebIssueDate?: string | null,
    detectorType?: DetectorType | null,
    detectorSerialNumber?: string | null,
    detectorBrand?: string | null,
    detectorLastControlDate?: string | null,
    detectorUnitInventoryId?: string | null,
    heatingBrand?: string | null,
    heatingModel?: string | null,
    heatingInstallationDate?: string | null,
    heatingType?: HeatingType | null,
    heatingLastControlDate?: string | null,
    tankType?: TankType | null,
    tankCapacity?: number | null,
    tankState?: TankState | null,
    tankLastControlDate?: string | null,
    tankLeakDetection?: boolean | null,
    tankEncuvement?: boolean | null,
    tankWallType?: TankWallType | null,
    tankInstallationDate?: string | null,
    chimneyLastControlDate?: string | null,
    chimneyUnitInventoryId?: string | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    originalTechnicId?: string | null,
    readers?: Array< string > | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTemplateMutationVariables = {
  input: CreateTemplateInput,
  condition?: ModelTemplateConditionInput | null,
};

export type CreateTemplateMutation = {
  createTemplate?:  {
    __typename: "Template",
    id: string,
    language: string,
    subject?: string | null,
    subjectRawFormat?: string | null,
    body: string,
    bodyRawFormat?: string | null,
    type: TemplateType,
    subType: TemplateSubType,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTemplateMutationVariables = {
  input: UpdateTemplateInput,
  condition?: ModelTemplateConditionInput | null,
};

export type UpdateTemplateMutation = {
  updateTemplate?:  {
    __typename: "Template",
    id: string,
    language: string,
    subject?: string | null,
    subjectRawFormat?: string | null,
    body: string,
    bodyRawFormat?: string | null,
    type: TemplateType,
    subType: TemplateSubType,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTemplateMutationVariables = {
  input: DeleteTemplateInput,
  condition?: ModelTemplateConditionInput | null,
};

export type DeleteTemplateMutation = {
  deleteTemplate?:  {
    __typename: "Template",
    id: string,
    language: string,
    subject?: string | null,
    subjectRawFormat?: string | null,
    body: string,
    bodyRawFormat?: string | null,
    type: TemplateType,
    subType: TemplateSubType,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTicketMutationVariables = {
  input: CreateTicketInput,
  condition?: ModelTicketConditionInput | null,
};

export type CreateTicketMutation = {
  createTicket?:  {
    __typename: "Ticket",
    id: string,
    assignedAt?: string | null,
    assignedTo?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    assignedToId?: string | null,
    attachment?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    closedAt?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId: string,
    content: string,
    conversations?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        assignedAt?: string | null,
        assignedToId?: string | null,
        buildingId?: string | null,
        closedAt?: string | null,
        contactId: string,
        content: string,
        createdAt: string,
        emailMessageId?: string | null,
        emailReferences?: Array< string | null > | null,
        parentId?: string | null,
        plannedAt?: string | null,
        referenceCode?: string | null,
        sourceType: TicketSourceType,
        status?: TicketStatus | null,
        subject: string,
        unitId?: string | null,
        unread: boolean,
        updatedAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    emailMessageId?: string | null,
    emailReferences?: Array< string | null > | null,
    parentId?: string | null,
    plannedAt?: string | null,
    referenceCode?: string | null,
    sourceType: TicketSourceType,
    status?: TicketStatus | null,
    subject: string,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unread: boolean,
    updatedAt?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTicketMutationVariables = {
  input: UpdateTicketInput,
  condition?: ModelTicketConditionInput | null,
};

export type UpdateTicketMutation = {
  updateTicket?:  {
    __typename: "Ticket",
    id: string,
    assignedAt?: string | null,
    assignedTo?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    assignedToId?: string | null,
    attachment?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    closedAt?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId: string,
    content: string,
    conversations?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        assignedAt?: string | null,
        assignedToId?: string | null,
        buildingId?: string | null,
        closedAt?: string | null,
        contactId: string,
        content: string,
        createdAt: string,
        emailMessageId?: string | null,
        emailReferences?: Array< string | null > | null,
        parentId?: string | null,
        plannedAt?: string | null,
        referenceCode?: string | null,
        sourceType: TicketSourceType,
        status?: TicketStatus | null,
        subject: string,
        unitId?: string | null,
        unread: boolean,
        updatedAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    emailMessageId?: string | null,
    emailReferences?: Array< string | null > | null,
    parentId?: string | null,
    plannedAt?: string | null,
    referenceCode?: string | null,
    sourceType: TicketSourceType,
    status?: TicketStatus | null,
    subject: string,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unread: boolean,
    updatedAt?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTicketMutationVariables = {
  input: DeleteTicketInput,
  condition?: ModelTicketConditionInput | null,
};

export type DeleteTicketMutation = {
  deleteTicket?:  {
    __typename: "Ticket",
    id: string,
    assignedAt?: string | null,
    assignedTo?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    assignedToId?: string | null,
    attachment?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    closedAt?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId: string,
    content: string,
    conversations?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        assignedAt?: string | null,
        assignedToId?: string | null,
        buildingId?: string | null,
        closedAt?: string | null,
        contactId: string,
        content: string,
        createdAt: string,
        emailMessageId?: string | null,
        emailReferences?: Array< string | null > | null,
        parentId?: string | null,
        plannedAt?: string | null,
        referenceCode?: string | null,
        sourceType: TicketSourceType,
        status?: TicketStatus | null,
        subject: string,
        unitId?: string | null,
        unread: boolean,
        updatedAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    emailMessageId?: string | null,
    emailReferences?: Array< string | null > | null,
    parentId?: string | null,
    plannedAt?: string | null,
    referenceCode?: string | null,
    sourceType: TicketSourceType,
    status?: TicketStatus | null,
    subject: string,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unread: boolean,
    updatedAt?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTransactionMutationVariables = {
  input: CreateTransactionInput,
  condition?: ModelTransactionConditionInput | null,
};

export type CreateTransactionMutation = {
  createTransaction?:  {
    __typename: "Transaction",
    id: string,
    status: TransactionStatus,
    sequence?: number | null,
    statementDate: string,
    operationDate?: string | null,
    createdAt?: string | null,
    remittanceInformationType?: RemittanceType | null,
    remittanceInformation?: string | null,
    comment?: string | null,
    amount: number,
    bankAccountId: string,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    counterpartName?: string | null,
    counterpartAccountNumber?: string | null,
    manualEntry?: boolean | null,
    proposedLeaseId?: string | null,
    proposedUnitId?: string | null,
    proposedContractorId?: string | null,
    proposedMatch?: number | null,
    links?:  Array< {
      __typename: "TransactionLink",
      amount: number,
      linkId: string,
      linkType: TransactionLinkType,
    } > | null,
    postings?:  {
      __typename: "ModelPostingConnection",
      items:  Array< {
        __typename: "Posting",
        id: string,
        createdAt?: string | null,
        class: number,
        customLabel?: string | null,
        topClass: number,
        type: PostingType,
        totalAmount: number,
        periodFrom: string,
        periodTo: string,
        buildingId?: string | null,
        unitId?: string | null,
        invoiceId?: string | null,
        chargeId?: string | null,
        transactionId?: string | null,
        leaseId?: string | null,
        bankAccountId?: string | null,
        accountLabelId?: string | null,
        contactId?: string | null,
        leaseVariousOperationId?: string | null,
        statementId?: string | null,
        amountVatExcluded?: number | null,
        vatAmount?: number | null,
        vatRate?: number | null,
        foreignId?: string | null,
        foreignType?: PostingForeignType | null,
        invoicePostingId?: string | null,
        reconciledAt?: string | null,
        status?: PostingStatus | null,
        chargeOwnerRepartition?: number | null,
        chargeRepartitionMetric?: string | null,
        chargeRepartitionValue?: number | null,
        chargeSplitNumber?: number | null,
        chargeType?: PostingChargeType | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTransactionMutationVariables = {
  input: UpdateTransactionInput,
  condition?: ModelTransactionConditionInput | null,
};

export type UpdateTransactionMutation = {
  updateTransaction?:  {
    __typename: "Transaction",
    id: string,
    status: TransactionStatus,
    sequence?: number | null,
    statementDate: string,
    operationDate?: string | null,
    createdAt?: string | null,
    remittanceInformationType?: RemittanceType | null,
    remittanceInformation?: string | null,
    comment?: string | null,
    amount: number,
    bankAccountId: string,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    counterpartName?: string | null,
    counterpartAccountNumber?: string | null,
    manualEntry?: boolean | null,
    proposedLeaseId?: string | null,
    proposedUnitId?: string | null,
    proposedContractorId?: string | null,
    proposedMatch?: number | null,
    links?:  Array< {
      __typename: "TransactionLink",
      amount: number,
      linkId: string,
      linkType: TransactionLinkType,
    } > | null,
    postings?:  {
      __typename: "ModelPostingConnection",
      items:  Array< {
        __typename: "Posting",
        id: string,
        createdAt?: string | null,
        class: number,
        customLabel?: string | null,
        topClass: number,
        type: PostingType,
        totalAmount: number,
        periodFrom: string,
        periodTo: string,
        buildingId?: string | null,
        unitId?: string | null,
        invoiceId?: string | null,
        chargeId?: string | null,
        transactionId?: string | null,
        leaseId?: string | null,
        bankAccountId?: string | null,
        accountLabelId?: string | null,
        contactId?: string | null,
        leaseVariousOperationId?: string | null,
        statementId?: string | null,
        amountVatExcluded?: number | null,
        vatAmount?: number | null,
        vatRate?: number | null,
        foreignId?: string | null,
        foreignType?: PostingForeignType | null,
        invoicePostingId?: string | null,
        reconciledAt?: string | null,
        status?: PostingStatus | null,
        chargeOwnerRepartition?: number | null,
        chargeRepartitionMetric?: string | null,
        chargeRepartitionValue?: number | null,
        chargeSplitNumber?: number | null,
        chargeType?: PostingChargeType | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTransactionMutationVariables = {
  input: DeleteTransactionInput,
  condition?: ModelTransactionConditionInput | null,
};

export type DeleteTransactionMutation = {
  deleteTransaction?:  {
    __typename: "Transaction",
    id: string,
    status: TransactionStatus,
    sequence?: number | null,
    statementDate: string,
    operationDate?: string | null,
    createdAt?: string | null,
    remittanceInformationType?: RemittanceType | null,
    remittanceInformation?: string | null,
    comment?: string | null,
    amount: number,
    bankAccountId: string,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    counterpartName?: string | null,
    counterpartAccountNumber?: string | null,
    manualEntry?: boolean | null,
    proposedLeaseId?: string | null,
    proposedUnitId?: string | null,
    proposedContractorId?: string | null,
    proposedMatch?: number | null,
    links?:  Array< {
      __typename: "TransactionLink",
      amount: number,
      linkId: string,
      linkType: TransactionLinkType,
    } > | null,
    postings?:  {
      __typename: "ModelPostingConnection",
      items:  Array< {
        __typename: "Posting",
        id: string,
        createdAt?: string | null,
        class: number,
        customLabel?: string | null,
        topClass: number,
        type: PostingType,
        totalAmount: number,
        periodFrom: string,
        periodTo: string,
        buildingId?: string | null,
        unitId?: string | null,
        invoiceId?: string | null,
        chargeId?: string | null,
        transactionId?: string | null,
        leaseId?: string | null,
        bankAccountId?: string | null,
        accountLabelId?: string | null,
        contactId?: string | null,
        leaseVariousOperationId?: string | null,
        statementId?: string | null,
        amountVatExcluded?: number | null,
        vatAmount?: number | null,
        vatRate?: number | null,
        foreignId?: string | null,
        foreignType?: PostingForeignType | null,
        invoicePostingId?: string | null,
        reconciledAt?: string | null,
        status?: PostingStatus | null,
        chargeOwnerRepartition?: number | null,
        chargeRepartitionMetric?: string | null,
        chargeRepartitionValue?: number | null,
        chargeSplitNumber?: number | null,
        chargeType?: PostingChargeType | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTransactionRuleMutationVariables = {
  input: CreateTransactionRuleInput,
  condition?: ModelTransactionRuleConditionInput | null,
};

export type CreateTransactionRuleMutation = {
  createTransactionRule?:  {
    __typename: "TransactionRule",
    id: string,
    remittanceInformation?: string | null,
    counterpartName?: string | null,
    counterpartAccountNumber?: string | null,
    usedFields: string,
    exclude: boolean,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contractorId?: string | null,
    contractor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTransactionRuleMutationVariables = {
  input: UpdateTransactionRuleInput,
  condition?: ModelTransactionRuleConditionInput | null,
};

export type UpdateTransactionRuleMutation = {
  updateTransactionRule?:  {
    __typename: "TransactionRule",
    id: string,
    remittanceInformation?: string | null,
    counterpartName?: string | null,
    counterpartAccountNumber?: string | null,
    usedFields: string,
    exclude: boolean,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contractorId?: string | null,
    contractor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTransactionRuleMutationVariables = {
  input: DeleteTransactionRuleInput,
  condition?: ModelTransactionRuleConditionInput | null,
};

export type DeleteTransactionRuleMutation = {
  deleteTransactionRule?:  {
    __typename: "TransactionRule",
    id: string,
    remittanceInformation?: string | null,
    counterpartName?: string | null,
    counterpartAccountNumber?: string | null,
    usedFields: string,
    exclude: boolean,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contractorId?: string | null,
    contractor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUnitMutationVariables = {
  input: CreateUnitInput,
  condition?: ModelUnitConditionInput | null,
};

export type CreateUnitMutation = {
  createUnit?:  {
    __typename: "Unit",
    id: string,
    acquisitionAdditionalCostsValue?: number | null,
    acquisitionAdvertisedValue?: number | null,
    acquisitionValue?: number | null,
    advertisedMonthlyCharges?: number | null,
    advertisedRentalPrice?: number | null,
    cadastralIncome?: number | null,
    createdAt?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    floor?: number | null,
    jointOwnershipId?: string | null,
    managementDate: string,
    name: string,
    predictedPrice?: string | null,
    propertyTax?: number | null,
    publishAddress?: boolean | null,
    surface?: number | null,
    type: UnitType,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owners?:  {
      __typename: "ModelUnitOwnerConnection",
      items:  Array< {
        __typename: "UnitOwner",
        id: string,
        disabled?: boolean | null,
        ownerId: string,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leases?:  {
      __typename: "ModelUnitLeaseConnection",
      items:  Array< {
        __typename: "UnitLease",
        id: string,
        allowChargeAdjustment?: boolean | null,
        authorizeProfessionalActivity?: boolean | null,
        chargesIndexation?: boolean | null,
        createdAt?: string | null,
        endDate: string,
        furnishedRental?: boolean | null,
        furnishedRentalInsuranceByTenant?: boolean | null,
        furnishedRentalRentalPricePercentage?: number | null,
        initialMonthlyCharges: number,
        initialRentalPrice: number,
        indexation?: boolean | null,
        advancedIndexation?: boolean | null,
        indexType?: IndexType | null,
        baseYear?: number | null,
        baseIndexType?: BaseIndexType | null,
        baseIndexDate?: string | null,
        leaseId: string,
        mainUnit: boolean,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        monthlyCharges: number,
        rentalPrice: number,
        signatureDate?: string | null,
        startDate: string,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelUnitEventConnection",
      items:  Array< {
        __typename: "UnitEvent",
        id: string,
        unitId: string,
        type: UnitEventType,
        startDate: string,
        endDate: string,
        totalCost?: number | null,
        note?: string | null,
        writers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    unitAdvertisementId?: string | null,
    unitInventories?:  {
      __typename: "ModelUnitInventoryConnection",
      items:  Array< {
        __typename: "UnitInventory",
        id: string,
        privateBatthRoom?: boolean | null,
        createdAt?: string | null,
        deletedAt?: string | null,
        kitchenEquipment?: KitchenEquipment | null,
        roomName?: string | null,
        roomType: UnitInventoryRoomType,
        surface?: number | null,
        showSurface?: boolean | null,
        unitId: string,
        inside: boolean,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUnitMutationVariables = {
  input: UpdateUnitInput,
  condition?: ModelUnitConditionInput | null,
};

export type UpdateUnitMutation = {
  updateUnit?:  {
    __typename: "Unit",
    id: string,
    acquisitionAdditionalCostsValue?: number | null,
    acquisitionAdvertisedValue?: number | null,
    acquisitionValue?: number | null,
    advertisedMonthlyCharges?: number | null,
    advertisedRentalPrice?: number | null,
    cadastralIncome?: number | null,
    createdAt?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    floor?: number | null,
    jointOwnershipId?: string | null,
    managementDate: string,
    name: string,
    predictedPrice?: string | null,
    propertyTax?: number | null,
    publishAddress?: boolean | null,
    surface?: number | null,
    type: UnitType,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owners?:  {
      __typename: "ModelUnitOwnerConnection",
      items:  Array< {
        __typename: "UnitOwner",
        id: string,
        disabled?: boolean | null,
        ownerId: string,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leases?:  {
      __typename: "ModelUnitLeaseConnection",
      items:  Array< {
        __typename: "UnitLease",
        id: string,
        allowChargeAdjustment?: boolean | null,
        authorizeProfessionalActivity?: boolean | null,
        chargesIndexation?: boolean | null,
        createdAt?: string | null,
        endDate: string,
        furnishedRental?: boolean | null,
        furnishedRentalInsuranceByTenant?: boolean | null,
        furnishedRentalRentalPricePercentage?: number | null,
        initialMonthlyCharges: number,
        initialRentalPrice: number,
        indexation?: boolean | null,
        advancedIndexation?: boolean | null,
        indexType?: IndexType | null,
        baseYear?: number | null,
        baseIndexType?: BaseIndexType | null,
        baseIndexDate?: string | null,
        leaseId: string,
        mainUnit: boolean,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        monthlyCharges: number,
        rentalPrice: number,
        signatureDate?: string | null,
        startDate: string,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelUnitEventConnection",
      items:  Array< {
        __typename: "UnitEvent",
        id: string,
        unitId: string,
        type: UnitEventType,
        startDate: string,
        endDate: string,
        totalCost?: number | null,
        note?: string | null,
        writers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    unitAdvertisementId?: string | null,
    unitInventories?:  {
      __typename: "ModelUnitInventoryConnection",
      items:  Array< {
        __typename: "UnitInventory",
        id: string,
        privateBatthRoom?: boolean | null,
        createdAt?: string | null,
        deletedAt?: string | null,
        kitchenEquipment?: KitchenEquipment | null,
        roomName?: string | null,
        roomType: UnitInventoryRoomType,
        surface?: number | null,
        showSurface?: boolean | null,
        unitId: string,
        inside: boolean,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUnitMutationVariables = {
  input: DeleteUnitInput,
  condition?: ModelUnitConditionInput | null,
};

export type DeleteUnitMutation = {
  deleteUnit?:  {
    __typename: "Unit",
    id: string,
    acquisitionAdditionalCostsValue?: number | null,
    acquisitionAdvertisedValue?: number | null,
    acquisitionValue?: number | null,
    advertisedMonthlyCharges?: number | null,
    advertisedRentalPrice?: number | null,
    cadastralIncome?: number | null,
    createdAt?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    floor?: number | null,
    jointOwnershipId?: string | null,
    managementDate: string,
    name: string,
    predictedPrice?: string | null,
    propertyTax?: number | null,
    publishAddress?: boolean | null,
    surface?: number | null,
    type: UnitType,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owners?:  {
      __typename: "ModelUnitOwnerConnection",
      items:  Array< {
        __typename: "UnitOwner",
        id: string,
        disabled?: boolean | null,
        ownerId: string,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leases?:  {
      __typename: "ModelUnitLeaseConnection",
      items:  Array< {
        __typename: "UnitLease",
        id: string,
        allowChargeAdjustment?: boolean | null,
        authorizeProfessionalActivity?: boolean | null,
        chargesIndexation?: boolean | null,
        createdAt?: string | null,
        endDate: string,
        furnishedRental?: boolean | null,
        furnishedRentalInsuranceByTenant?: boolean | null,
        furnishedRentalRentalPricePercentage?: number | null,
        initialMonthlyCharges: number,
        initialRentalPrice: number,
        indexation?: boolean | null,
        advancedIndexation?: boolean | null,
        indexType?: IndexType | null,
        baseYear?: number | null,
        baseIndexType?: BaseIndexType | null,
        baseIndexDate?: string | null,
        leaseId: string,
        mainUnit: boolean,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        monthlyCharges: number,
        rentalPrice: number,
        signatureDate?: string | null,
        startDate: string,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelUnitEventConnection",
      items:  Array< {
        __typename: "UnitEvent",
        id: string,
        unitId: string,
        type: UnitEventType,
        startDate: string,
        endDate: string,
        totalCost?: number | null,
        note?: string | null,
        writers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    unitAdvertisementId?: string | null,
    unitInventories?:  {
      __typename: "ModelUnitInventoryConnection",
      items:  Array< {
        __typename: "UnitInventory",
        id: string,
        privateBatthRoom?: boolean | null,
        createdAt?: string | null,
        deletedAt?: string | null,
        kitchenEquipment?: KitchenEquipment | null,
        roomName?: string | null,
        roomType: UnitInventoryRoomType,
        surface?: number | null,
        showSurface?: boolean | null,
        unitId: string,
        inside: boolean,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUnitEventMutationVariables = {
  input: CreateUnitEventInput,
  condition?: ModelUnitEventConditionInput | null,
};

export type CreateUnitEventMutation = {
  createUnitEvent?:  {
    __typename: "UnitEvent",
    id: string,
    unitId: string,
    type: UnitEventType,
    startDate: string,
    endDate: string,
    totalCost?: number | null,
    note?: string | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUnitEventMutationVariables = {
  input: UpdateUnitEventInput,
  condition?: ModelUnitEventConditionInput | null,
};

export type UpdateUnitEventMutation = {
  updateUnitEvent?:  {
    __typename: "UnitEvent",
    id: string,
    unitId: string,
    type: UnitEventType,
    startDate: string,
    endDate: string,
    totalCost?: number | null,
    note?: string | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUnitEventMutationVariables = {
  input: DeleteUnitEventInput,
  condition?: ModelUnitEventConditionInput | null,
};

export type DeleteUnitEventMutation = {
  deleteUnitEvent?:  {
    __typename: "UnitEvent",
    id: string,
    unitId: string,
    type: UnitEventType,
    startDate: string,
    endDate: string,
    totalCost?: number | null,
    note?: string | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUnitInventoryMutationVariables = {
  input: CreateUnitInventoryInput,
  condition?: ModelUnitInventoryConditionInput | null,
};

export type CreateUnitInventoryMutation = {
  createUnitInventory?:  {
    __typename: "UnitInventory",
    id: string,
    privateBatthRoom?: boolean | null,
    createdAt?: string | null,
    deletedAt?: string | null,
    kitchenEquipment?: KitchenEquipment | null,
    roomName?: string | null,
    roomType: UnitInventoryRoomType,
    surface?: number | null,
    showSurface?: boolean | null,
    unitId: string,
    inside: boolean,
    structures?:  {
      __typename: "ModelUnitInventoryStructureConnection",
      items:  Array< {
        __typename: "UnitInventoryStructure",
        id: string,
        createdAt?: string | null,
        deletedAt?: string | null,
        unitInventoryId: string,
        type: UnitInventoryStructureType,
        name: string,
        brand?: string | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUnitInventoryMutationVariables = {
  input: UpdateUnitInventoryInput,
  condition?: ModelUnitInventoryConditionInput | null,
};

export type UpdateUnitInventoryMutation = {
  updateUnitInventory?:  {
    __typename: "UnitInventory",
    id: string,
    privateBatthRoom?: boolean | null,
    createdAt?: string | null,
    deletedAt?: string | null,
    kitchenEquipment?: KitchenEquipment | null,
    roomName?: string | null,
    roomType: UnitInventoryRoomType,
    surface?: number | null,
    showSurface?: boolean | null,
    unitId: string,
    inside: boolean,
    structures?:  {
      __typename: "ModelUnitInventoryStructureConnection",
      items:  Array< {
        __typename: "UnitInventoryStructure",
        id: string,
        createdAt?: string | null,
        deletedAt?: string | null,
        unitInventoryId: string,
        type: UnitInventoryStructureType,
        name: string,
        brand?: string | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUnitInventoryMutationVariables = {
  input: DeleteUnitInventoryInput,
  condition?: ModelUnitInventoryConditionInput | null,
};

export type DeleteUnitInventoryMutation = {
  deleteUnitInventory?:  {
    __typename: "UnitInventory",
    id: string,
    privateBatthRoom?: boolean | null,
    createdAt?: string | null,
    deletedAt?: string | null,
    kitchenEquipment?: KitchenEquipment | null,
    roomName?: string | null,
    roomType: UnitInventoryRoomType,
    surface?: number | null,
    showSurface?: boolean | null,
    unitId: string,
    inside: boolean,
    structures?:  {
      __typename: "ModelUnitInventoryStructureConnection",
      items:  Array< {
        __typename: "UnitInventoryStructure",
        id: string,
        createdAt?: string | null,
        deletedAt?: string | null,
        unitInventoryId: string,
        type: UnitInventoryStructureType,
        name: string,
        brand?: string | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUnitInventoryStructureMutationVariables = {
  input: CreateUnitInventoryStructureInput,
  condition?: ModelUnitInventoryStructureConditionInput | null,
};

export type CreateUnitInventoryStructureMutation = {
  createUnitInventoryStructure?:  {
    __typename: "UnitInventoryStructure",
    id: string,
    createdAt?: string | null,
    deletedAt?: string | null,
    unitInventoryId: string,
    encodings?:  {
      __typename: "ModelLeaseInventoryEncodingConnection",
      items:  Array< {
        __typename: "LeaseInventoryEncoding",
        id: string,
        leaseInventoryId: string,
        structureId: string,
        state?: LeaseInventoryStructureStateType | null,
        note?: string | null,
        furnitureQuantity?: number | null,
        furniturePrice?: number | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    type: UnitInventoryStructureType,
    name: string,
    brand?: string | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUnitInventoryStructureMutationVariables = {
  input: UpdateUnitInventoryStructureInput,
  condition?: ModelUnitInventoryStructureConditionInput | null,
};

export type UpdateUnitInventoryStructureMutation = {
  updateUnitInventoryStructure?:  {
    __typename: "UnitInventoryStructure",
    id: string,
    createdAt?: string | null,
    deletedAt?: string | null,
    unitInventoryId: string,
    encodings?:  {
      __typename: "ModelLeaseInventoryEncodingConnection",
      items:  Array< {
        __typename: "LeaseInventoryEncoding",
        id: string,
        leaseInventoryId: string,
        structureId: string,
        state?: LeaseInventoryStructureStateType | null,
        note?: string | null,
        furnitureQuantity?: number | null,
        furniturePrice?: number | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    type: UnitInventoryStructureType,
    name: string,
    brand?: string | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUnitInventoryStructureMutationVariables = {
  input: DeleteUnitInventoryStructureInput,
  condition?: ModelUnitInventoryStructureConditionInput | null,
};

export type DeleteUnitInventoryStructureMutation = {
  deleteUnitInventoryStructure?:  {
    __typename: "UnitInventoryStructure",
    id: string,
    createdAt?: string | null,
    deletedAt?: string | null,
    unitInventoryId: string,
    encodings?:  {
      __typename: "ModelLeaseInventoryEncodingConnection",
      items:  Array< {
        __typename: "LeaseInventoryEncoding",
        id: string,
        leaseInventoryId: string,
        structureId: string,
        state?: LeaseInventoryStructureStateType | null,
        note?: string | null,
        furnitureQuantity?: number | null,
        furniturePrice?: number | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    type: UnitInventoryStructureType,
    name: string,
    brand?: string | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUnitLeaseMutationVariables = {
  input: CreateUnitLeaseInput,
  condition?: ModelUnitLeaseConditionInput | null,
};

export type CreateUnitLeaseMutation = {
  createUnitLease?:  {
    __typename: "UnitLease",
    id: string,
    allowChargeAdjustment?: boolean | null,
    authorizeProfessionalActivity?: boolean | null,
    chargesIndexation?: boolean | null,
    createdAt?: string | null,
    endDate: string,
    furnishedRental?: boolean | null,
    furnishedRentalInsuranceByTenant?: boolean | null,
    furnishedRentalRentalPricePercentage?: number | null,
    initialMonthlyCharges: number,
    initialRentalPrice: number,
    indexation?: boolean | null,
    advancedIndexation?: boolean | null,
    indexType?: IndexType | null,
    baseYear?: number | null,
    baseIndexType?: BaseIndexType | null,
    baseIndexDate?: string | null,
    leaseId: string,
    mainUnit: boolean,
    monthlyChargesType?: LeaseMonthlyChargesType | null,
    monthlyCharges: number,
    rentalPrice: number,
    signatureDate?: string | null,
    startDate: string,
    unitId: string,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUnitLeaseMutationVariables = {
  input: UpdateUnitLeaseInput,
  condition?: ModelUnitLeaseConditionInput | null,
};

export type UpdateUnitLeaseMutation = {
  updateUnitLease?:  {
    __typename: "UnitLease",
    id: string,
    allowChargeAdjustment?: boolean | null,
    authorizeProfessionalActivity?: boolean | null,
    chargesIndexation?: boolean | null,
    createdAt?: string | null,
    endDate: string,
    furnishedRental?: boolean | null,
    furnishedRentalInsuranceByTenant?: boolean | null,
    furnishedRentalRentalPricePercentage?: number | null,
    initialMonthlyCharges: number,
    initialRentalPrice: number,
    indexation?: boolean | null,
    advancedIndexation?: boolean | null,
    indexType?: IndexType | null,
    baseYear?: number | null,
    baseIndexType?: BaseIndexType | null,
    baseIndexDate?: string | null,
    leaseId: string,
    mainUnit: boolean,
    monthlyChargesType?: LeaseMonthlyChargesType | null,
    monthlyCharges: number,
    rentalPrice: number,
    signatureDate?: string | null,
    startDate: string,
    unitId: string,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUnitLeaseMutationVariables = {
  input: DeleteUnitLeaseInput,
  condition?: ModelUnitLeaseConditionInput | null,
};

export type DeleteUnitLeaseMutation = {
  deleteUnitLease?:  {
    __typename: "UnitLease",
    id: string,
    allowChargeAdjustment?: boolean | null,
    authorizeProfessionalActivity?: boolean | null,
    chargesIndexation?: boolean | null,
    createdAt?: string | null,
    endDate: string,
    furnishedRental?: boolean | null,
    furnishedRentalInsuranceByTenant?: boolean | null,
    furnishedRentalRentalPricePercentage?: number | null,
    initialMonthlyCharges: number,
    initialRentalPrice: number,
    indexation?: boolean | null,
    advancedIndexation?: boolean | null,
    indexType?: IndexType | null,
    baseYear?: number | null,
    baseIndexType?: BaseIndexType | null,
    baseIndexDate?: string | null,
    leaseId: string,
    mainUnit: boolean,
    monthlyChargesType?: LeaseMonthlyChargesType | null,
    monthlyCharges: number,
    rentalPrice: number,
    signatureDate?: string | null,
    startDate: string,
    unitId: string,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUnitOwnerMutationVariables = {
  input: CreateUnitOwnerInput,
  condition?: ModelUnitOwnerConditionInput | null,
};

export type CreateUnitOwnerMutation = {
  createUnitOwner?:  {
    __typename: "UnitOwner",
    id: string,
    disabled?: boolean | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ownerId: string,
    periodFrom?: string | null,
    periodTo?: string | null,
    stake: number,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId: string,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUnitOwnerMutationVariables = {
  input: UpdateUnitOwnerInput,
  condition?: ModelUnitOwnerConditionInput | null,
};

export type UpdateUnitOwnerMutation = {
  updateUnitOwner?:  {
    __typename: "UnitOwner",
    id: string,
    disabled?: boolean | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ownerId: string,
    periodFrom?: string | null,
    periodTo?: string | null,
    stake: number,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId: string,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUnitOwnerMutationVariables = {
  input: DeleteUnitOwnerInput,
  condition?: ModelUnitOwnerConditionInput | null,
};

export type DeleteUnitOwnerMutation = {
  deleteUnitOwner?:  {
    __typename: "UnitOwner",
    id: string,
    disabled?: boolean | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ownerId: string,
    periodFrom?: string | null,
    periodTo?: string | null,
    stake: number,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId: string,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    cognitoSub?: string | null,
    googleSub?: string | null,
    facebookSub?: string | null,
    rootUser?: boolean | null,
    refreshToken?: boolean | null,
    teams?:  {
      __typename: "ModelUserTeamConnection",
      items:  Array< {
        __typename: "UserTeam",
        id: string,
        userId: string,
        teamId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userRoleId?: string | null,
    userRole?:  {
      __typename: "UserRole",
      id: string,
      name: string,
      userRights?:  Array< {
        __typename: "UserRight",
        access?: Array< UserRightAccessType > | null,
        section: UserRightSection,
      } > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status: UserStatus,
    readId: string,
    clientId: string,
    _ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    cognitoSub?: string | null,
    googleSub?: string | null,
    facebookSub?: string | null,
    rootUser?: boolean | null,
    refreshToken?: boolean | null,
    teams?:  {
      __typename: "ModelUserTeamConnection",
      items:  Array< {
        __typename: "UserTeam",
        id: string,
        userId: string,
        teamId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userRoleId?: string | null,
    userRole?:  {
      __typename: "UserRole",
      id: string,
      name: string,
      userRights?:  Array< {
        __typename: "UserRight",
        access?: Array< UserRightAccessType > | null,
        section: UserRightSection,
      } > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status: UserStatus,
    readId: string,
    clientId: string,
    _ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    cognitoSub?: string | null,
    googleSub?: string | null,
    facebookSub?: string | null,
    rootUser?: boolean | null,
    refreshToken?: boolean | null,
    teams?:  {
      __typename: "ModelUserTeamConnection",
      items:  Array< {
        __typename: "UserTeam",
        id: string,
        userId: string,
        teamId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userRoleId?: string | null,
    userRole?:  {
      __typename: "UserRole",
      id: string,
      name: string,
      userRights?:  Array< {
        __typename: "UserRight",
        access?: Array< UserRightAccessType > | null,
        section: UserRightSection,
      } > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status: UserStatus,
    readId: string,
    clientId: string,
    _ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserRoleMutationVariables = {
  input: CreateUserRoleInput,
  condition?: ModelUserRoleConditionInput | null,
};

export type CreateUserRoleMutation = {
  createUserRole?:  {
    __typename: "UserRole",
    id: string,
    name: string,
    userRights?:  Array< {
      __typename: "UserRight",
      access?: Array< UserRightAccessType > | null,
      section: UserRightSection,
    } > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserRoleMutationVariables = {
  input: UpdateUserRoleInput,
  condition?: ModelUserRoleConditionInput | null,
};

export type UpdateUserRoleMutation = {
  updateUserRole?:  {
    __typename: "UserRole",
    id: string,
    name: string,
    userRights?:  Array< {
      __typename: "UserRight",
      access?: Array< UserRightAccessType > | null,
      section: UserRightSection,
    } > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserRoleMutationVariables = {
  input: DeleteUserRoleInput,
  condition?: ModelUserRoleConditionInput | null,
};

export type DeleteUserRoleMutation = {
  deleteUserRole?:  {
    __typename: "UserRole",
    id: string,
    name: string,
    userRights?:  Array< {
      __typename: "UserRight",
      access?: Array< UserRightAccessType > | null,
      section: UserRightSection,
    } > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserTeamMutationVariables = {
  input: CreateUserTeamInput,
  condition?: ModelUserTeamConditionInput | null,
};

export type CreateUserTeamMutation = {
  createUserTeam?:  {
    __typename: "UserTeam",
    id: string,
    userId: string,
    teamId: string,
    team?:  {
      __typename: "Team",
      id: string,
      name: string,
      parentId?: string | null,
      children?:  {
        __typename: "ModelTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoSub?: string | null,
      googleSub?: string | null,
      facebookSub?: string | null,
      rootUser?: boolean | null,
      refreshToken?: boolean | null,
      teams?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userRoleId?: string | null,
      userRole?:  {
        __typename: "UserRole",
        id: string,
        name: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status: UserStatus,
      readId: string,
      clientId: string,
      _ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserTeamMutationVariables = {
  input: UpdateUserTeamInput,
  condition?: ModelUserTeamConditionInput | null,
};

export type UpdateUserTeamMutation = {
  updateUserTeam?:  {
    __typename: "UserTeam",
    id: string,
    userId: string,
    teamId: string,
    team?:  {
      __typename: "Team",
      id: string,
      name: string,
      parentId?: string | null,
      children?:  {
        __typename: "ModelTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoSub?: string | null,
      googleSub?: string | null,
      facebookSub?: string | null,
      rootUser?: boolean | null,
      refreshToken?: boolean | null,
      teams?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userRoleId?: string | null,
      userRole?:  {
        __typename: "UserRole",
        id: string,
        name: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status: UserStatus,
      readId: string,
      clientId: string,
      _ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserTeamMutationVariables = {
  input: DeleteUserTeamInput,
  condition?: ModelUserTeamConditionInput | null,
};

export type DeleteUserTeamMutation = {
  deleteUserTeam?:  {
    __typename: "UserTeam",
    id: string,
    userId: string,
    teamId: string,
    team?:  {
      __typename: "Team",
      id: string,
      name: string,
      parentId?: string | null,
      children?:  {
        __typename: "ModelTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoSub?: string | null,
      googleSub?: string | null,
      facebookSub?: string | null,
      rootUser?: boolean | null,
      refreshToken?: boolean | null,
      teams?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userRoleId?: string | null,
      userRole?:  {
        __typename: "UserRole",
        id: string,
        name: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status: UserStatus,
      readId: string,
      clientId: string,
      _ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateValuationMutationVariables = {
  input: CreateValuationInput,
  condition?: ModelValuationConditionInput | null,
};

export type CreateValuationMutation = {
  createValuation?:  {
    __typename: "Valuation",
    id: string,
    sourceType?: ValuationSourceType | null,
    valorization: number,
    date: string,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateValuationMutationVariables = {
  input: UpdateValuationInput,
  condition?: ModelValuationConditionInput | null,
};

export type UpdateValuationMutation = {
  updateValuation?:  {
    __typename: "Valuation",
    id: string,
    sourceType?: ValuationSourceType | null,
    valorization: number,
    date: string,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteValuationMutationVariables = {
  input: DeleteValuationInput,
  condition?: ModelValuationConditionInput | null,
};

export type DeleteValuationMutation = {
  deleteValuation?:  {
    __typename: "Valuation",
    id: string,
    sourceType?: ValuationSourceType | null,
    valorization: number,
    date: string,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateVariousOperationMutationVariables = {
  input: CreateVariousOperationInput,
  condition?: ModelVariousOperationConditionInput | null,
};

export type CreateVariousOperationMutation = {
  createVariousOperation?:  {
    __typename: "VariousOperation",
    id: string,
    amount: number,
    labels?:  Array< {
      __typename: "CustomLabel",
      language: string,
      label: string,
    } > | null,
    numberOfMonths?: number | null,
    operationType: LeaseVariousOperationType,
    paymentFrequency: LeasePaymentFrequency,
    type: VariousOperationType,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateVariousOperationMutationVariables = {
  input: UpdateVariousOperationInput,
  condition?: ModelVariousOperationConditionInput | null,
};

export type UpdateVariousOperationMutation = {
  updateVariousOperation?:  {
    __typename: "VariousOperation",
    id: string,
    amount: number,
    labels?:  Array< {
      __typename: "CustomLabel",
      language: string,
      label: string,
    } > | null,
    numberOfMonths?: number | null,
    operationType: LeaseVariousOperationType,
    paymentFrequency: LeasePaymentFrequency,
    type: VariousOperationType,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteVariousOperationMutationVariables = {
  input: DeleteVariousOperationInput,
  condition?: ModelVariousOperationConditionInput | null,
};

export type DeleteVariousOperationMutation = {
  deleteVariousOperation?:  {
    __typename: "VariousOperation",
    id: string,
    amount: number,
    labels?:  Array< {
      __typename: "CustomLabel",
      language: string,
      label: string,
    } > | null,
    numberOfMonths?: number | null,
    operationType: LeaseVariousOperationType,
    paymentFrequency: LeasePaymentFrequency,
    type: VariousOperationType,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateVatRateMutationVariables = {
  input: CreateVatRateInput,
  condition?: ModelVatRateConditionInput | null,
};

export type CreateVatRateMutation = {
  createVatRate?:  {
    __typename: "VatRate",
    id: string,
    country: string,
    label: string,
    value?: number | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateVatRateMutationVariables = {
  input: UpdateVatRateInput,
  condition?: ModelVatRateConditionInput | null,
};

export type UpdateVatRateMutation = {
  updateVatRate?:  {
    __typename: "VatRate",
    id: string,
    country: string,
    label: string,
    value?: number | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteVatRateMutationVariables = {
  input: DeleteVatRateInput,
  condition?: ModelVatRateConditionInput | null,
};

export type DeleteVatRateMutation = {
  deleteVatRate?:  {
    __typename: "VatRate",
    id: string,
    country: string,
    label: string,
    value?: number | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetAccountLabelQueryVariables = {
  id: string,
};

export type GetAccountLabelQuery = {
  getAccountLabel?:  {
    __typename: "AccountLabel",
    id: string,
    labels?:  Array< {
      __typename: "CustomLabel",
      language: string,
      label: string,
    } > | null,
    bankAccountId?: string | null,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    class: number,
    topClass: number,
    customUsage?: AccountLabelCustomUsage | null,
    disabled?: boolean | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAccountLabelsQueryVariables = {
  filter?: ModelAccountLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountLabelsQuery = {
  listAccountLabels?:  {
    __typename: "ModelAccountLabelConnection",
    items:  Array< {
      __typename: "AccountLabel",
      id: string,
      labels?:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } > | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      class: number,
      topClass: number,
      customUsage?: AccountLabelCustomUsage | null,
      disabled?: boolean | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAccountLabelsQueryVariables = {
  filter?: ModelAccountLabelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAccountLabelsQuery = {
  syncAccountLabels?:  {
    __typename: "ModelAccountLabelConnection",
    items:  Array< {
      __typename: "AccountLabel",
      id: string,
      labels?:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } > | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      class: number,
      topClass: number,
      customUsage?: AccountLabelCustomUsage | null,
      disabled?: boolean | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAddressQueryVariables = {
  id: string,
};

export type GetAddressQuery = {
  getAddress?:  {
    __typename: "Address",
    id: string,
    status?: AddressStatus | null,
    street: string,
    number?: string | null,
    box?: string | null,
    postalCode?: string | null,
    city?: string | null,
    region?: string | null,
    country?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    latitude?: number | null,
    longitude?: number | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAddressesQueryVariables = {
  filter?: ModelAddressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAddressesQuery = {
  listAddresses?:  {
    __typename: "ModelAddressConnection",
    items:  Array< {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAddressesQueryVariables = {
  filter?: ModelAddressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAddressesQuery = {
  syncAddresses?:  {
    __typename: "ModelAddressConnection",
    items:  Array< {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAdvertisementQueryVariables = {
  id: string,
};

export type GetAdvertisementQuery = {
  getAdvertisement?:  {
    __typename: "Advertisement",
    id: string,
    type?: AdvertisementUnitType | null,
    subType?: AdvertisementUnitSubType | null,
    amenities?: Array< AmenitiesType > | null,
    amountOfFacades?: number | null,
    availabilityDate?: string | null,
    certificateOfConformity?: CertificateOfConformityType | null,
    citationForPlanningOffence?: boolean | null,
    descriptions?:  Array< {
      __typename: "AdvertisementDescription",
      language: string,
      description?: string | null,
    } > | null,
    delimitedZone?: DelimitedZoneType | null,
    floodProneArea?: FloodProneAreaType | null,
    furnished?: boolean | null,
    firstOccupancy?: boolean | null,
    gardenSurface?: number | null,
    heatingAmenities?: Array< HeatingAmenities > | null,
    heatingType?: HeatingType | null,
    landTerrain?: Array< LandTerrainType > | null,
    landArea?: number | null,
    length?: number | null,
    nearbyComodities?:  Array< {
      __typename: "NearbyComodity",
      type: NearbyCommoditiesTypes,
      name: string,
      distance: number,
    } > | null,
    publishedPlatforms?:  Array< {
      __typename: "PublishedPlatform",
      name: PublishingPlatform,
      publishedDate: string,
      id: string,
    } > | null,
    planningPermission?: boolean | null,
    publishCadastralIncome?: boolean | null,
    resort?: boolean | null,
    rightOfPreemption?: boolean | null,
    subdivisionAuthorization?: boolean | null,
    wheelChairAccessible?: boolean | null,
    width?: number | null,
    terraceSurface?: number | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAdvertisementsQueryVariables = {
  filter?: ModelAdvertisementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdvertisementsQuery = {
  listAdvertisements?:  {
    __typename: "ModelAdvertisementConnection",
    items:  Array< {
      __typename: "Advertisement",
      id: string,
      type?: AdvertisementUnitType | null,
      subType?: AdvertisementUnitSubType | null,
      amenities?: Array< AmenitiesType > | null,
      amountOfFacades?: number | null,
      availabilityDate?: string | null,
      certificateOfConformity?: CertificateOfConformityType | null,
      citationForPlanningOffence?: boolean | null,
      descriptions?:  Array< {
        __typename: "AdvertisementDescription",
        language: string,
        description?: string | null,
      } > | null,
      delimitedZone?: DelimitedZoneType | null,
      floodProneArea?: FloodProneAreaType | null,
      furnished?: boolean | null,
      firstOccupancy?: boolean | null,
      gardenSurface?: number | null,
      heatingAmenities?: Array< HeatingAmenities > | null,
      heatingType?: HeatingType | null,
      landTerrain?: Array< LandTerrainType > | null,
      landArea?: number | null,
      length?: number | null,
      nearbyComodities?:  Array< {
        __typename: "NearbyComodity",
        type: NearbyCommoditiesTypes,
        name: string,
        distance: number,
      } > | null,
      publishedPlatforms?:  Array< {
        __typename: "PublishedPlatform",
        name: PublishingPlatform,
        publishedDate: string,
        id: string,
      } > | null,
      planningPermission?: boolean | null,
      publishCadastralIncome?: boolean | null,
      resort?: boolean | null,
      rightOfPreemption?: boolean | null,
      subdivisionAuthorization?: boolean | null,
      wheelChairAccessible?: boolean | null,
      width?: number | null,
      terraceSurface?: number | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAdvertisementsQueryVariables = {
  filter?: ModelAdvertisementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAdvertisementsQuery = {
  syncAdvertisements?:  {
    __typename: "ModelAdvertisementConnection",
    items:  Array< {
      __typename: "Advertisement",
      id: string,
      type?: AdvertisementUnitType | null,
      subType?: AdvertisementUnitSubType | null,
      amenities?: Array< AmenitiesType > | null,
      amountOfFacades?: number | null,
      availabilityDate?: string | null,
      certificateOfConformity?: CertificateOfConformityType | null,
      citationForPlanningOffence?: boolean | null,
      descriptions?:  Array< {
        __typename: "AdvertisementDescription",
        language: string,
        description?: string | null,
      } > | null,
      delimitedZone?: DelimitedZoneType | null,
      floodProneArea?: FloodProneAreaType | null,
      furnished?: boolean | null,
      firstOccupancy?: boolean | null,
      gardenSurface?: number | null,
      heatingAmenities?: Array< HeatingAmenities > | null,
      heatingType?: HeatingType | null,
      landTerrain?: Array< LandTerrainType > | null,
      landArea?: number | null,
      length?: number | null,
      nearbyComodities?:  Array< {
        __typename: "NearbyComodity",
        type: NearbyCommoditiesTypes,
        name: string,
        distance: number,
      } > | null,
      publishedPlatforms?:  Array< {
        __typename: "PublishedPlatform",
        name: PublishingPlatform,
        publishedDate: string,
        id: string,
      } > | null,
      planningPermission?: boolean | null,
      publishCadastralIncome?: boolean | null,
      resort?: boolean | null,
      rightOfPreemption?: boolean | null,
      subdivisionAuthorization?: boolean | null,
      wheelChairAccessible?: boolean | null,
      width?: number | null,
      terraceSurface?: number | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAgencyRateQueryVariables = {
  id: string,
};

export type GetAgencyRateQuery = {
  getAgencyRate?:  {
    __typename: "AgencyRate",
    id: string,
    amountDetails:  Array< {
      __typename: "AgencyRateAmountDetail",
      amountType: AgencyRateAmountDetailType,
      endAmount?: number | null,
      feeApplied: number,
      startAmount: number,
    } >,
    amountIfVacantUnit?: number | null,
    amountMinimumFees?: number | null,
    custom: boolean,
    feesAppliedTo: AgencyRateFeesType,
    includedInAmount?: Array< AgencyRateIncludedAmount > | null,
    name: string,
    owners?:  {
      __typename: "ModelAgencyRateOwnerConnection",
      items:  Array< {
        __typename: "AgencyRateOwner",
        id: string,
        agencyRateId: string,
        contactId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    repartitionType: AgencyRateRepartitionType,
    type: AgencyRateType,
    unitType: Array< UnitType >,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAgencyRatesQueryVariables = {
  filter?: ModelAgencyRateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgencyRatesQuery = {
  listAgencyRates?:  {
    __typename: "ModelAgencyRateConnection",
    items:  Array< {
      __typename: "AgencyRate",
      id: string,
      amountDetails:  Array< {
        __typename: "AgencyRateAmountDetail",
        amountType: AgencyRateAmountDetailType,
        endAmount?: number | null,
        feeApplied: number,
        startAmount: number,
      } >,
      amountIfVacantUnit?: number | null,
      amountMinimumFees?: number | null,
      custom: boolean,
      feesAppliedTo: AgencyRateFeesType,
      includedInAmount?: Array< AgencyRateIncludedAmount > | null,
      name: string,
      owners?:  {
        __typename: "ModelAgencyRateOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      repartitionType: AgencyRateRepartitionType,
      type: AgencyRateType,
      unitType: Array< UnitType >,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAgencyRatesQueryVariables = {
  filter?: ModelAgencyRateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAgencyRatesQuery = {
  syncAgencyRates?:  {
    __typename: "ModelAgencyRateConnection",
    items:  Array< {
      __typename: "AgencyRate",
      id: string,
      amountDetails:  Array< {
        __typename: "AgencyRateAmountDetail",
        amountType: AgencyRateAmountDetailType,
        endAmount?: number | null,
        feeApplied: number,
        startAmount: number,
      } >,
      amountIfVacantUnit?: number | null,
      amountMinimumFees?: number | null,
      custom: boolean,
      feesAppliedTo: AgencyRateFeesType,
      includedInAmount?: Array< AgencyRateIncludedAmount > | null,
      name: string,
      owners?:  {
        __typename: "ModelAgencyRateOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      repartitionType: AgencyRateRepartitionType,
      type: AgencyRateType,
      unitType: Array< UnitType >,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAgencyRateOwnerQueryVariables = {
  id: string,
};

export type GetAgencyRateOwnerQuery = {
  getAgencyRateOwner?:  {
    __typename: "AgencyRateOwner",
    id: string,
    agencyRateId: string,
    contactId: string,
    agencyRate?:  {
      __typename: "AgencyRate",
      id: string,
      amountDetails:  Array< {
        __typename: "AgencyRateAmountDetail",
        amountType: AgencyRateAmountDetailType,
        endAmount?: number | null,
        feeApplied: number,
        startAmount: number,
      } >,
      amountIfVacantUnit?: number | null,
      amountMinimumFees?: number | null,
      custom: boolean,
      feesAppliedTo: AgencyRateFeesType,
      includedInAmount?: Array< AgencyRateIncludedAmount > | null,
      name: string,
      owners?:  {
        __typename: "ModelAgencyRateOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      repartitionType: AgencyRateRepartitionType,
      type: AgencyRateType,
      unitType: Array< UnitType >,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAgencyRateOwnersQueryVariables = {
  filter?: ModelAgencyRateOwnerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgencyRateOwnersQuery = {
  listAgencyRateOwners?:  {
    __typename: "ModelAgencyRateOwnerConnection",
    items:  Array< {
      __typename: "AgencyRateOwner",
      id: string,
      agencyRateId: string,
      contactId: string,
      agencyRate?:  {
        __typename: "AgencyRate",
        id: string,
        amountIfVacantUnit?: number | null,
        amountMinimumFees?: number | null,
        custom: boolean,
        feesAppliedTo: AgencyRateFeesType,
        includedInAmount?: Array< AgencyRateIncludedAmount > | null,
        name: string,
        repartitionType: AgencyRateRepartitionType,
        type: AgencyRateType,
        unitType: Array< UnitType >,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAgencyRateOwnersQueryVariables = {
  filter?: ModelAgencyRateOwnerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAgencyRateOwnersQuery = {
  syncAgencyRateOwners?:  {
    __typename: "ModelAgencyRateOwnerConnection",
    items:  Array< {
      __typename: "AgencyRateOwner",
      id: string,
      agencyRateId: string,
      contactId: string,
      agencyRate?:  {
        __typename: "AgencyRate",
        id: string,
        amountIfVacantUnit?: number | null,
        amountMinimumFees?: number | null,
        custom: boolean,
        feesAppliedTo: AgencyRateFeesType,
        includedInAmount?: Array< AgencyRateIncludedAmount > | null,
        name: string,
        repartitionType: AgencyRateRepartitionType,
        type: AgencyRateType,
        unitType: Array< UnitType >,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAgencyRateStatementQueryVariables = {
  id: string,
};

export type GetAgencyRateStatementQuery = {
  getAgencyRateStatement?:  {
    __typename: "AgencyRateStatement",
    id: string,
    agencyRate?:  {
      __typename: "AgencyRate",
      id: string,
      amountDetails:  Array< {
        __typename: "AgencyRateAmountDetail",
        amountType: AgencyRateAmountDetailType,
        endAmount?: number | null,
        feeApplied: number,
        startAmount: number,
      } >,
      amountIfVacantUnit?: number | null,
      amountMinimumFees?: number | null,
      custom: boolean,
      feesAppliedTo: AgencyRateFeesType,
      includedInAmount?: Array< AgencyRateIncludedAmount > | null,
      name: string,
      owners?:  {
        __typename: "ModelAgencyRateOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      repartitionType: AgencyRateRepartitionType,
      type: AgencyRateType,
      unitType: Array< UnitType >,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    agencyRateId: string,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId: string,
    unitAmounts?:  Array< {
      __typename: "AgencyRateStatementAmount",
      unitId?: string | null,
      amountRateable: number,
      fixedAmount: number,
    } > | null,
    totalAmountRateable: number,
    totalFixedAmount: number,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAgencyRateStatementsQueryVariables = {
  filter?: ModelAgencyRateStatementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgencyRateStatementsQuery = {
  listAgencyRateStatements?:  {
    __typename: "ModelAgencyRateStatementConnection",
    items:  Array< {
      __typename: "AgencyRateStatement",
      id: string,
      agencyRate?:  {
        __typename: "AgencyRate",
        id: string,
        amountIfVacantUnit?: number | null,
        amountMinimumFees?: number | null,
        custom: boolean,
        feesAppliedTo: AgencyRateFeesType,
        includedInAmount?: Array< AgencyRateIncludedAmount > | null,
        name: string,
        repartitionType: AgencyRateRepartitionType,
        type: AgencyRateType,
        unitType: Array< UnitType >,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateId: string,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId: string,
      unitAmounts?:  Array< {
        __typename: "AgencyRateStatementAmount",
        unitId?: string | null,
        amountRateable: number,
        fixedAmount: number,
      } > | null,
      totalAmountRateable: number,
      totalFixedAmount: number,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAgencyRateStatementsQueryVariables = {
  filter?: ModelAgencyRateStatementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAgencyRateStatementsQuery = {
  syncAgencyRateStatements?:  {
    __typename: "ModelAgencyRateStatementConnection",
    items:  Array< {
      __typename: "AgencyRateStatement",
      id: string,
      agencyRate?:  {
        __typename: "AgencyRate",
        id: string,
        amountIfVacantUnit?: number | null,
        amountMinimumFees?: number | null,
        custom: boolean,
        feesAppliedTo: AgencyRateFeesType,
        includedInAmount?: Array< AgencyRateIncludedAmount > | null,
        name: string,
        repartitionType: AgencyRateRepartitionType,
        type: AgencyRateType,
        unitType: Array< UnitType >,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateId: string,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId: string,
      unitAmounts?:  Array< {
        __typename: "AgencyRateStatementAmount",
        unitId?: string | null,
        amountRateable: number,
        fixedAmount: number,
      } > | null,
      totalAmountRateable: number,
      totalFixedAmount: number,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBankAccountQueryVariables = {
  id: string,
};

export type GetBankAccountQuery = {
  getBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    number: string,
    bicNumber?: string | null,
    bankName?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    disabled?: boolean | null,
    integrations?: Array< IntegrationName > | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBankAccountsQueryVariables = {
  filter?: ModelBankAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBankAccountsQuery = {
  listBankAccounts?:  {
    __typename: "ModelBankAccountConnection",
    items:  Array< {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBankAccountsQueryVariables = {
  filter?: ModelBankAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBankAccountsQuery = {
  syncBankAccounts?:  {
    __typename: "ModelBankAccountConnection",
    items:  Array< {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBuildingQueryVariables = {
  id: string,
};

export type GetBuildingQuery = {
  getBuilding?:  {
    __typename: "Building",
    id: string,
    name: string,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    acquisitionValue?: number | null,
    acquisitionAdditionalCostsValue?: number | null,
    acquisitionAdvertisedValue?: number | null,
    amountOfFacades?: number | null,
    amountOfFloors?: number | null,
    amountOfHousings?: number | null,
    buildingState?: BuildingState | null,
    cadastralIncome?: number | null,
    constructionYear?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    janitor?: boolean | null,
    jointOwnershipId?: string | null,
    owners?:  {
      __typename: "ModelBuildingOwnerConnection",
      items:  Array< {
        __typename: "BuildingOwner",
        id: string,
        buildingId: string,
        contactId: string,
        disabled?: boolean | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    propertyTax?: number | null,
    residenceName?: string | null,
    units?:  {
      __typename: "ModelUnitConnection",
      items:  Array< {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelBuildingEventConnection",
      items:  Array< {
        __typename: "BuildingEvent",
        id: string,
        buildingId: string,
        type: string,
        startDate: string,
        endDate: string,
        totalCost?: number | null,
        note?: string | null,
        writers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    teams?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBuildingsQueryVariables = {
  filter?: ModelBuildingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBuildingsQuery = {
  listBuildings?:  {
    __typename: "ModelBuildingConnection",
    items:  Array< {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBuildingsQueryVariables = {
  filter?: ModelBuildingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBuildingsQuery = {
  syncBuildings?:  {
    __typename: "ModelBuildingConnection",
    items:  Array< {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBuildingEventQueryVariables = {
  id: string,
};

export type GetBuildingEventQuery = {
  getBuildingEvent?:  {
    __typename: "BuildingEvent",
    id: string,
    buildingId: string,
    type: string,
    startDate: string,
    endDate: string,
    totalCost?: number | null,
    note?: string | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBuildingEventsQueryVariables = {
  filter?: ModelBuildingEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBuildingEventsQuery = {
  listBuildingEvents?:  {
    __typename: "ModelBuildingEventConnection",
    items:  Array< {
      __typename: "BuildingEvent",
      id: string,
      buildingId: string,
      type: string,
      startDate: string,
      endDate: string,
      totalCost?: number | null,
      note?: string | null,
      writers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBuildingEventsQueryVariables = {
  filter?: ModelBuildingEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBuildingEventsQuery = {
  syncBuildingEvents?:  {
    __typename: "ModelBuildingEventConnection",
    items:  Array< {
      __typename: "BuildingEvent",
      id: string,
      buildingId: string,
      type: string,
      startDate: string,
      endDate: string,
      totalCost?: number | null,
      note?: string | null,
      writers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBuildingOwnerQueryVariables = {
  id: string,
};

export type GetBuildingOwnerQuery = {
  getBuildingOwner?:  {
    __typename: "BuildingOwner",
    id: string,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId: string,
    disabled?: boolean | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    periodFrom?: string | null,
    periodTo?: string | null,
    stake: number,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBuildingOwnersQueryVariables = {
  filter?: ModelBuildingOwnerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBuildingOwnersQuery = {
  listBuildingOwners?:  {
    __typename: "ModelBuildingOwnerConnection",
    items:  Array< {
      __typename: "BuildingOwner",
      id: string,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId: string,
      disabled?: boolean | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      periodFrom?: string | null,
      periodTo?: string | null,
      stake: number,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBuildingOwnersQueryVariables = {
  filter?: ModelBuildingOwnerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBuildingOwnersQuery = {
  syncBuildingOwners?:  {
    __typename: "ModelBuildingOwnerConnection",
    items:  Array< {
      __typename: "BuildingOwner",
      id: string,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId: string,
      disabled?: boolean | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      periodFrom?: string | null,
      periodTo?: string | null,
      stake: number,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetChargeQueryVariables = {
  id: string,
};

export type GetChargeQuery = {
  getCharge?:  {
    __typename: "Charge",
    id: string,
    status: ChargeStatus,
    invoiceDate?: string | null,
    paymentDueDate?: string | null,
    archivedDate?: string | null,
    periodFrom?: string | null,
    periodTo?: string | null,
    totalAmount?: number | null,
    source: ChargeSource,
    draftFromOcr?: boolean | null,
    accountingDocumentId?: string | null,
    paid: boolean,
    remittanceInformation?: string | null,
    note?: string | null,
    createdAt: string,
    creatorEmail?: string | null,
    createdById?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contractorId?: string | null,
    contractor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    proposedContractors?:  Array< {
      __typename: "ProposedValue",
      id: string,
      confidence?: number | null,
    } > | null,
    recipientId?: string | null,
    recipient?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    proposedRecipients?:  Array< {
      __typename: "ProposedValue",
      id: string,
      confidence?: number | null,
    } > | null,
    documentId: string,
    document?:  {
      __typename: "File",
      id: string,
      bucket: string,
      createdAt?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        entity?: string | null,
        fileCategory: FileCategory,
        parentId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categoryId: string,
      foreignKey: string,
      foreignTableName: string,
      key: string,
      mimeType?: string | null,
      region: string,
      signaturePositions?:  Array< {
        __typename: "SignaturePosition",
        top: number,
        left: number,
        page: number,
        width?: number | null,
        height?: number | null,
        contactId: string,
      } > | null,
      size?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    postings?:  {
      __typename: "ModelPostingConnection",
      items:  Array< {
        __typename: "Posting",
        id: string,
        createdAt?: string | null,
        class: number,
        customLabel?: string | null,
        topClass: number,
        type: PostingType,
        totalAmount: number,
        periodFrom: string,
        periodTo: string,
        buildingId?: string | null,
        unitId?: string | null,
        invoiceId?: string | null,
        chargeId?: string | null,
        transactionId?: string | null,
        leaseId?: string | null,
        bankAccountId?: string | null,
        accountLabelId?: string | null,
        contactId?: string | null,
        leaseVariousOperationId?: string | null,
        statementId?: string | null,
        amountVatExcluded?: number | null,
        vatAmount?: number | null,
        vatRate?: number | null,
        foreignId?: string | null,
        foreignType?: PostingForeignType | null,
        invoicePostingId?: string | null,
        reconciledAt?: string | null,
        status?: PostingStatus | null,
        chargeOwnerRepartition?: number | null,
        chargeRepartitionMetric?: string | null,
        chargeRepartitionValue?: number | null,
        chargeSplitNumber?: number | null,
        chargeType?: PostingChargeType | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListChargesQueryVariables = {
  filter?: ModelChargeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChargesQuery = {
  listCharges?:  {
    __typename: "ModelChargeConnection",
    items:  Array< {
      __typename: "Charge",
      id: string,
      status: ChargeStatus,
      invoiceDate?: string | null,
      paymentDueDate?: string | null,
      archivedDate?: string | null,
      periodFrom?: string | null,
      periodTo?: string | null,
      totalAmount?: number | null,
      source: ChargeSource,
      draftFromOcr?: boolean | null,
      accountingDocumentId?: string | null,
      paid: boolean,
      remittanceInformation?: string | null,
      note?: string | null,
      createdAt: string,
      creatorEmail?: string | null,
      createdById?: string | null,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contractorId?: string | null,
      contractor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedContractors?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      recipientId?: string | null,
      recipient?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedRecipients?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      documentId: string,
      document?:  {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      postings?:  {
        __typename: "ModelPostingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncChargesQueryVariables = {
  filter?: ModelChargeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncChargesQuery = {
  syncCharges?:  {
    __typename: "ModelChargeConnection",
    items:  Array< {
      __typename: "Charge",
      id: string,
      status: ChargeStatus,
      invoiceDate?: string | null,
      paymentDueDate?: string | null,
      archivedDate?: string | null,
      periodFrom?: string | null,
      periodTo?: string | null,
      totalAmount?: number | null,
      source: ChargeSource,
      draftFromOcr?: boolean | null,
      accountingDocumentId?: string | null,
      paid: boolean,
      remittanceInformation?: string | null,
      note?: string | null,
      createdAt: string,
      creatorEmail?: string | null,
      createdById?: string | null,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contractorId?: string | null,
      contractor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedContractors?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      recipientId?: string | null,
      recipient?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedRecipients?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      documentId: string,
      document?:  {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      postings?:  {
        __typename: "ModelPostingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetChargeStatementQueryVariables = {
  id: string,
};

export type GetChargeStatementQuery = {
  getChargeStatement?:  {
    __typename: "ChargeStatement",
    id: string,
    charge?:  {
      __typename: "Charge",
      id: string,
      status: ChargeStatus,
      invoiceDate?: string | null,
      paymentDueDate?: string | null,
      archivedDate?: string | null,
      periodFrom?: string | null,
      periodTo?: string | null,
      totalAmount?: number | null,
      source: ChargeSource,
      draftFromOcr?: boolean | null,
      accountingDocumentId?: string | null,
      paid: boolean,
      remittanceInformation?: string | null,
      note?: string | null,
      createdAt: string,
      creatorEmail?: string | null,
      createdById?: string | null,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contractorId?: string | null,
      contractor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedContractors?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      recipientId?: string | null,
      recipient?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedRecipients?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      documentId: string,
      document?:  {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      postings?:  {
        __typename: "ModelPostingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    chargeId: string,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListChargeStatementsQueryVariables = {
  filter?: ModelChargeStatementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChargeStatementsQuery = {
  listChargeStatements?:  {
    __typename: "ModelChargeStatementConnection",
    items:  Array< {
      __typename: "ChargeStatement",
      id: string,
      charge?:  {
        __typename: "Charge",
        id: string,
        status: ChargeStatus,
        invoiceDate?: string | null,
        paymentDueDate?: string | null,
        archivedDate?: string | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        totalAmount?: number | null,
        source: ChargeSource,
        draftFromOcr?: boolean | null,
        accountingDocumentId?: string | null,
        paid: boolean,
        remittanceInformation?: string | null,
        note?: string | null,
        createdAt: string,
        creatorEmail?: string | null,
        createdById?: string | null,
        contractorId?: string | null,
        recipientId?: string | null,
        documentId: string,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      chargeId: string,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId: string,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncChargeStatementsQueryVariables = {
  filter?: ModelChargeStatementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncChargeStatementsQuery = {
  syncChargeStatements?:  {
    __typename: "ModelChargeStatementConnection",
    items:  Array< {
      __typename: "ChargeStatement",
      id: string,
      charge?:  {
        __typename: "Charge",
        id: string,
        status: ChargeStatus,
        invoiceDate?: string | null,
        paymentDueDate?: string | null,
        archivedDate?: string | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        totalAmount?: number | null,
        source: ChargeSource,
        draftFromOcr?: boolean | null,
        accountingDocumentId?: string | null,
        paid: boolean,
        remittanceInformation?: string | null,
        note?: string | null,
        createdAt: string,
        creatorEmail?: string | null,
        createdById?: string | null,
        contractorId?: string | null,
        recipientId?: string | null,
        documentId: string,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      chargeId: string,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId: string,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetClientQueryVariables = {
  id: string,
};

export type GetClientQuery = {
  getClient?:  {
    __typename: "Client",
    id: string,
    accountType: AccountType,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    generalConditionsAcceptDate: string,
    lastGeneralConditionsAcceptedVersion: string,
    GDPRAcceptDate: string,
    lastGDPRAcceptedVersion: string,
    chargeEmail?: string | null,
    issueEmail?: string | null,
    chargeEmailFromSendingSource?: string | null,
    issueEmailFromSendingSource?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListClientsQueryVariables = {
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientsQuery = {
  listClients?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      accountType: AccountType,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      generalConditionsAcceptDate: string,
      lastGeneralConditionsAcceptedVersion: string,
      GDPRAcceptDate: string,
      lastGDPRAcceptedVersion: string,
      chargeEmail?: string | null,
      issueEmail?: string | null,
      chargeEmailFromSendingSource?: string | null,
      issueEmailFromSendingSource?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncClientsQueryVariables = {
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncClientsQuery = {
  syncClients?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      accountType: AccountType,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      generalConditionsAcceptDate: string,
      lastGeneralConditionsAcceptedVersion: string,
      GDPRAcceptDate: string,
      lastGDPRAcceptedVersion: string,
      chargeEmail?: string | null,
      issueEmail?: string | null,
      chargeEmailFromSendingSource?: string | null,
      issueEmailFromSendingSource?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCommunicationQueryVariables = {
  id: string,
};

export type GetCommunicationQuery = {
  getCommunication?:  {
    __typename: "Communication",
    id: string,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    blockedRecipients?:  Array< {
      __typename: "CommunicationBlockedRecipient",
      recipient: string,
      bounceType: BounceType,
    } > | null,
    body: string,
    buildingId?: string | null,
    channel: CommunicationChannel,
    communicationTemplateId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    createdAt?: string | null,
    expirationDate?: string | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      creditNote?: boolean | null,
      creditNoteLabel?: string | null,
      dueDate?: string | null,
      invoiceDate: string,
      invoiceNumber?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      paid: boolean,
      receiptNumber?: string | null,
      reminderDates?: Array< string > | null,
      remittanceInformation?: string | null,
      statementId?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      type: InvoiceType,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    invoiceId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    leasePriceHistoryId?: string | null,
    leasePriceHistory?:  {
      __typename: "LeasePriceHistory",
      id: string,
      previousTotalRentalPrice: number,
      previousTotalMonthlyCharge: number,
      totalRentalPrice: number,
      optimalTotalRentalPrice?: number | null,
      totalMonthlyCharges: number,
      optimalTotalMonthlyCharges?: number | null,
      applicationDate: string,
      status: LeaseAmountUpdateStatus,
      comment?: string | null,
      amountDetails:  Array< {
        __typename: "LeasePriceHistoryDetail",
        unitId: string,
        indexationBaseIndex?: number | null,
        indexationNewIndex?: number | null,
        previousRentalPrice: number,
        newRentalPrice: number,
        rentalStatus: LeasePriceHistoryStatus,
        previousMonthlyChargesPrice: number,
        newMonthlyChargesPrice: number,
        monthlyChargesStatus: LeasePriceHistoryStatus,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        optimalNewRentalPrice?: number | null,
        optimalNewMonthlyChargesPrice?: number | null,
        epb?: string | null,
        epbIssueDate?: string | null,
        oldestIndexForCorrectionFactorCalculation?: number | null,
        newestIndexForCorrectionFactorCalculation?: number | null,
        rentIndexedPriceAfterNewEpbReduction?: number | null,
        chargesIndexedPriceAfterNewEpbReduction?: number | null,
        correctionFactor?: number | null,
        reductionPercentage?: number | null,
        rentDifference?: number | null,
        rentReduction?: number | null,
        indexedRentAfterReduction?: number | null,
        chargesDifference?: number | null,
        chargesReduction?: number | null,
        indexedChargesAfterReduction?: number | null,
        previousYearIndexationNewIndex?: number | null,
        previousYearIndexedRent?: number | null,
        previousYearIndexedCharges?: number | null,
      } >,
      type: LeasePriceHistoryType,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    linkedCommunicationsIds?: Array< string > | null,
    recipient?: string | null,
    recipientInBcc?: string | null,
    recipientInCc?: string | null,
    sender?: string | null,
    sentAt?: string | null,
    externalId?: string | null,
    signatureDocumentId?: string | null,
    signatureDocument?:  {
      __typename: "SignatureDocument",
      id: string,
      additionalMessage?: string | null,
      communicationBody?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      fileIds?: Array< string > | null,
      foreignKey: string,
      foreignTableName: string,
      sentAt?: string | null,
      signatureMethods: Array< SignatureType >,
      signatureResults:  Array< {
        __typename: "SignatureDocumentResult",
        openedDate?: string | null,
        signedDate?: string | null,
        signed?: boolean | null,
        signatureLink?: string | null,
        rejectReason?: string | null,
        lastSignInviteSentDate?: string | null,
        trackingProvider?: TrackingProvider | null,
        trackingCode?: string | null,
        contactId: string,
      } >,
      status: SignatureDocumentStatus,
      externalStatus?: string | null,
      type: SignatureDocumentType,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    snoozedDate?: string | null,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId?: string | null,
    status: CommunicationStatus,
    subject: string,
    toSend: boolean,
    type: CommunicationType,
    _ttl?: number | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCommunicationsQueryVariables = {
  filter?: ModelCommunicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommunicationsQuery = {
  listCommunications?:  {
    __typename: "ModelCommunicationConnection",
    items:  Array< {
      __typename: "Communication",
      id: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      blockedRecipients?:  Array< {
        __typename: "CommunicationBlockedRecipient",
        recipient: string,
        bounceType: BounceType,
      } > | null,
      body: string,
      buildingId?: string | null,
      channel: CommunicationChannel,
      communicationTemplateId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        amount: number,
        bankAccountId: string,
        communicationId?: string | null,
        createdAt: string,
        creditNote?: boolean | null,
        creditNoteLabel?: string | null,
        dueDate?: string | null,
        invoiceDate: string,
        invoiceNumber?: string | null,
        leaseId?: string | null,
        paid: boolean,
        receiptNumber?: string | null,
        reminderDates?: Array< string > | null,
        remittanceInformation?: string | null,
        statementId?: string | null,
        type: InvoiceType,
        unitId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      invoiceId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      leasePriceHistoryId?: string | null,
      leasePriceHistory?:  {
        __typename: "LeasePriceHistory",
        id: string,
        previousTotalRentalPrice: number,
        previousTotalMonthlyCharge: number,
        totalRentalPrice: number,
        optimalTotalRentalPrice?: number | null,
        totalMonthlyCharges: number,
        optimalTotalMonthlyCharges?: number | null,
        applicationDate: string,
        status: LeaseAmountUpdateStatus,
        comment?: string | null,
        type: LeasePriceHistoryType,
        communicationId?: string | null,
        leaseId: string,
        createdAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      linkedCommunicationsIds?: Array< string > | null,
      recipient?: string | null,
      recipientInBcc?: string | null,
      recipientInCc?: string | null,
      sender?: string | null,
      sentAt?: string | null,
      externalId?: string | null,
      signatureDocumentId?: string | null,
      signatureDocument?:  {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      snoozedDate?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId?: string | null,
      status: CommunicationStatus,
      subject: string,
      toSend: boolean,
      type: CommunicationType,
      _ttl?: number | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCommunicationsQueryVariables = {
  filter?: ModelCommunicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCommunicationsQuery = {
  syncCommunications?:  {
    __typename: "ModelCommunicationConnection",
    items:  Array< {
      __typename: "Communication",
      id: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      blockedRecipients?:  Array< {
        __typename: "CommunicationBlockedRecipient",
        recipient: string,
        bounceType: BounceType,
      } > | null,
      body: string,
      buildingId?: string | null,
      channel: CommunicationChannel,
      communicationTemplateId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        amount: number,
        bankAccountId: string,
        communicationId?: string | null,
        createdAt: string,
        creditNote?: boolean | null,
        creditNoteLabel?: string | null,
        dueDate?: string | null,
        invoiceDate: string,
        invoiceNumber?: string | null,
        leaseId?: string | null,
        paid: boolean,
        receiptNumber?: string | null,
        reminderDates?: Array< string > | null,
        remittanceInformation?: string | null,
        statementId?: string | null,
        type: InvoiceType,
        unitId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      invoiceId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      leasePriceHistoryId?: string | null,
      leasePriceHistory?:  {
        __typename: "LeasePriceHistory",
        id: string,
        previousTotalRentalPrice: number,
        previousTotalMonthlyCharge: number,
        totalRentalPrice: number,
        optimalTotalRentalPrice?: number | null,
        totalMonthlyCharges: number,
        optimalTotalMonthlyCharges?: number | null,
        applicationDate: string,
        status: LeaseAmountUpdateStatus,
        comment?: string | null,
        type: LeasePriceHistoryType,
        communicationId?: string | null,
        leaseId: string,
        createdAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      linkedCommunicationsIds?: Array< string > | null,
      recipient?: string | null,
      recipientInBcc?: string | null,
      recipientInCc?: string | null,
      sender?: string | null,
      sentAt?: string | null,
      externalId?: string | null,
      signatureDocumentId?: string | null,
      signatureDocument?:  {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      snoozedDate?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId?: string | null,
      status: CommunicationStatus,
      subject: string,
      toSend: boolean,
      type: CommunicationType,
      _ttl?: number | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCommunicationSettingsProfileQueryVariables = {
  id: string,
};

export type GetCommunicationSettingsProfileQuery = {
  getCommunicationSettingsProfile?:  {
    __typename: "CommunicationSettingsProfile",
    id: string,
    communicationsSettings:  Array< {
      __typename: "CommunicationSettings",
      channel: CommunicationChannel,
      communicationType: CommunicationType,
      daysBeforeDueDate?: number | null,
      daysOverdue?: number | null,
      frequency?:  {
        __typename: "CommunicationFrequencySetting",
        dateFrom?: CommunicationFrequencySettingPeriodDateFrom | null,
        periodInterval: number,
        periodType: CommunicationFrequencySettingPeriodType,
      } | null,
      sendingRule: CommunicationSendingRule,
      recipients: Array< CommunicationSettingRecipient >,
      recipientsInBcc: Array< CommunicationSettingRecipient >,
      recipientsInCopy: Array< string >,
    } >,
    createdAt?: string | null,
    defaultName?: string | null,
    isDefaultSetting: boolean,
    referent: CommunicationSettingsProfileReferent,
    replacementId?: string | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCommunicationSettingsProfilesQueryVariables = {
  filter?: ModelCommunicationSettingsProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommunicationSettingsProfilesQuery = {
  listCommunicationSettingsProfiles?:  {
    __typename: "ModelCommunicationSettingsProfileConnection",
    items:  Array< {
      __typename: "CommunicationSettingsProfile",
      id: string,
      communicationsSettings:  Array< {
        __typename: "CommunicationSettings",
        channel: CommunicationChannel,
        communicationType: CommunicationType,
        daysBeforeDueDate?: number | null,
        daysOverdue?: number | null,
        sendingRule: CommunicationSendingRule,
        recipients: Array< CommunicationSettingRecipient >,
        recipientsInBcc: Array< CommunicationSettingRecipient >,
        recipientsInCopy: Array< string >,
      } >,
      createdAt?: string | null,
      defaultName?: string | null,
      isDefaultSetting: boolean,
      referent: CommunicationSettingsProfileReferent,
      replacementId?: string | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCommunicationSettingsProfilesQueryVariables = {
  filter?: ModelCommunicationSettingsProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCommunicationSettingsProfilesQuery = {
  syncCommunicationSettingsProfiles?:  {
    __typename: "ModelCommunicationSettingsProfileConnection",
    items:  Array< {
      __typename: "CommunicationSettingsProfile",
      id: string,
      communicationsSettings:  Array< {
        __typename: "CommunicationSettings",
        channel: CommunicationChannel,
        communicationType: CommunicationType,
        daysBeforeDueDate?: number | null,
        daysOverdue?: number | null,
        sendingRule: CommunicationSendingRule,
        recipients: Array< CommunicationSettingRecipient >,
        recipientsInBcc: Array< CommunicationSettingRecipient >,
        recipientsInCopy: Array< string >,
      } >,
      createdAt?: string | null,
      defaultName?: string | null,
      isDefaultSetting: boolean,
      referent: CommunicationSettingsProfileReferent,
      replacementId?: string | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetContactQueryVariables = {
  id: string,
};

export type GetContactQuery = {
  getContact?:  {
    __typename: "Contact",
    id: string,
    createdAt?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    companyName?: string | null,
    commercialName?: string | null,
    communicationSettingsProfileId?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    businessNumber?: string | null,
    types: Array< ContactType >,
    vat?: string | null,
    email?: string | null,
    birthDate?: string | null,
    birthPlace?: string | null,
    nationalRegister?: string | null,
    civility?: CivilityType | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    phoneNumbers?:  Array< {
      __typename: "PhoneNumber",
      countryCode: string,
      number: string,
    } > | null,
    language?: string | null,
    jointOwners?:  Array< {
      __typename: "JointOwner",
      contactId: string,
      contactPerson?: boolean | null,
    } > | null,
    agencyRateDisabled?: boolean | null,
    status?: ContactStatus | null,
    leases?:  {
      __typename: "ModelLeaseContactConnection",
      items:  Array< {
        __typename: "LeaseContact",
        id: string,
        leaseId: string,
        contactId: string,
        contactRole: ContactType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildings?:  {
      __typename: "ModelBuildingOwnerConnection",
      items:  Array< {
        __typename: "BuildingOwner",
        id: string,
        buildingId: string,
        contactId: string,
        disabled?: boolean | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    units?:  {
      __typename: "ModelUnitOwnerConnection",
      items:  Array< {
        __typename: "UnitOwner",
        id: string,
        disabled?: boolean | null,
        ownerId: string,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sendInvitation: boolean,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListContactsQueryVariables = {
  filter?: ModelContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactsQuery = {
  listContacts?:  {
    __typename: "ModelContactConnection",
    items:  Array< {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncContactsQueryVariables = {
  filter?: ModelContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncContactsQuery = {
  syncContacts?:  {
    __typename: "ModelContactConnection",
    items:  Array< {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCustomFieldQueryVariables = {
  id: string,
};

export type GetCustomFieldQuery = {
  getCustomField?:  {
    __typename: "CustomField",
    id: string,
    entity: string,
    fieldName: string,
    fieldType: CustomFieldType,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCustomFieldsQueryVariables = {
  filter?: ModelCustomFieldFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomFieldsQuery = {
  listCustomFields?:  {
    __typename: "ModelCustomFieldConnection",
    items:  Array< {
      __typename: "CustomField",
      id: string,
      entity: string,
      fieldName: string,
      fieldType: CustomFieldType,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCustomFieldsQueryVariables = {
  filter?: ModelCustomFieldFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCustomFieldsQuery = {
  syncCustomFields?:  {
    __typename: "ModelCustomFieldConnection",
    items:  Array< {
      __typename: "CustomField",
      id: string,
      entity: string,
      fieldName: string,
      fieldType: CustomFieldType,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCustomSubscriptionQueryVariables = {
  id: string,
};

export type GetCustomSubscriptionQuery = {
  getCustomSubscription?:  {
    __typename: "CustomSubscription",
    id: string,
    name: CustomSubscriptionType,
    value: string,
    createdAt?: string | null,
    userId?: string | null,
    _ttl: number,
    updatedAt: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCustomSubscriptionsQueryVariables = {
  filter?: ModelCustomSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomSubscriptionsQuery = {
  listCustomSubscriptions?:  {
    __typename: "ModelCustomSubscriptionConnection",
    items:  Array< {
      __typename: "CustomSubscription",
      id: string,
      name: CustomSubscriptionType,
      value: string,
      createdAt?: string | null,
      userId?: string | null,
      _ttl: number,
      updatedAt: string,
      clientId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCustomSubscriptionsQueryVariables = {
  filter?: ModelCustomSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCustomSubscriptionsQuery = {
  syncCustomSubscriptions?:  {
    __typename: "ModelCustomSubscriptionConnection",
    items:  Array< {
      __typename: "CustomSubscription",
      id: string,
      name: CustomSubscriptionType,
      value: string,
      createdAt?: string | null,
      userId?: string | null,
      _ttl: number,
      updatedAt: string,
      clientId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDocumentCategoryQueryVariables = {
  id: string,
};

export type GetDocumentCategoryQuery = {
  getDocumentCategory?:  {
    __typename: "DocumentCategory",
    id: string,
    entity?: string | null,
    fileCategory: FileCategory,
    labels:  Array< {
      __typename: "CustomLabel",
      language: string,
      label: string,
    } >,
    parentId?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDocumentCategoriesQueryVariables = {
  filter?: ModelDocumentCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentCategoriesQuery = {
  listDocumentCategories?:  {
    __typename: "ModelDocumentCategoryConnection",
    items:  Array< {
      __typename: "DocumentCategory",
      id: string,
      entity?: string | null,
      fileCategory: FileCategory,
      labels:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } >,
      parentId?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDocumentCategoriesQueryVariables = {
  filter?: ModelDocumentCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDocumentCategoriesQuery = {
  syncDocumentCategories?:  {
    __typename: "ModelDocumentCategoryConnection",
    items:  Array< {
      __typename: "DocumentCategory",
      id: string,
      entity?: string | null,
      fileCategory: FileCategory,
      labels:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } >,
      parentId?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFileQueryVariables = {
  id: string,
};

export type GetFileQuery = {
  getFile?:  {
    __typename: "File",
    id: string,
    bucket: string,
    createdAt?: string | null,
    category?:  {
      __typename: "DocumentCategory",
      id: string,
      entity?: string | null,
      fileCategory: FileCategory,
      labels:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } >,
      parentId?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categoryId: string,
    foreignKey: string,
    foreignTableName: string,
    key: string,
    mimeType?: string | null,
    region: string,
    signaturePositions?:  Array< {
      __typename: "SignaturePosition",
      top: number,
      left: number,
      page: number,
      width?: number | null,
      height?: number | null,
      contactId: string,
    } > | null,
    size?: number | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFilesQueryVariables = {
  filter?: ModelFileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFilesQuery = {
  listFiles?:  {
    __typename: "ModelFileConnection",
    items:  Array< {
      __typename: "File",
      id: string,
      bucket: string,
      createdAt?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        entity?: string | null,
        fileCategory: FileCategory,
        parentId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categoryId: string,
      foreignKey: string,
      foreignTableName: string,
      key: string,
      mimeType?: string | null,
      region: string,
      signaturePositions?:  Array< {
        __typename: "SignaturePosition",
        top: number,
        left: number,
        page: number,
        width?: number | null,
        height?: number | null,
        contactId: string,
      } > | null,
      size?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFilesQueryVariables = {
  filter?: ModelFileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFilesQuery = {
  syncFiles?:  {
    __typename: "ModelFileConnection",
    items:  Array< {
      __typename: "File",
      id: string,
      bucket: string,
      createdAt?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        entity?: string | null,
        fileCategory: FileCategory,
        parentId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categoryId: string,
      foreignKey: string,
      foreignTableName: string,
      key: string,
      mimeType?: string | null,
      region: string,
      signaturePositions?:  Array< {
        __typename: "SignaturePosition",
        top: number,
        left: number,
        page: number,
        width?: number | null,
        height?: number | null,
        contactId: string,
      } > | null,
      size?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetIntegrationQueryVariables = {
  id: string,
};

export type GetIntegrationQuery = {
  getIntegration?:  {
    __typename: "Integration",
    id: string,
    configurations?:  Array< {
      __typename: "IntegrationConfiguration",
      name: string,
      value: string,
    } > | null,
    error?: string | null,
    lastRun?: string | null,
    name: IntegrationName,
    status: IntegrationStatus,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListIntegrationsQueryVariables = {
  filter?: ModelIntegrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIntegrationsQuery = {
  listIntegrations?:  {
    __typename: "ModelIntegrationConnection",
    items:  Array< {
      __typename: "Integration",
      id: string,
      configurations?:  Array< {
        __typename: "IntegrationConfiguration",
        name: string,
        value: string,
      } > | null,
      error?: string | null,
      lastRun?: string | null,
      name: IntegrationName,
      status: IntegrationStatus,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncIntegrationsQueryVariables = {
  filter?: ModelIntegrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncIntegrationsQuery = {
  syncIntegrations?:  {
    __typename: "ModelIntegrationConnection",
    items:  Array< {
      __typename: "Integration",
      id: string,
      configurations?:  Array< {
        __typename: "IntegrationConfiguration",
        name: string,
        value: string,
      } > | null,
      error?: string | null,
      lastRun?: string | null,
      name: IntegrationName,
      status: IntegrationStatus,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInventoryCustomItemQueryVariables = {
  id: string,
};

export type GetInventoryCustomItemQuery = {
  getInventoryCustomItem?:  {
    __typename: "InventoryCustomItem",
    id: string,
    type: InventoryItemType,
    value: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListInventoryCustomItemsQueryVariables = {
  filter?: ModelInventoryCustomItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInventoryCustomItemsQuery = {
  listInventoryCustomItems?:  {
    __typename: "ModelInventoryCustomItemConnection",
    items:  Array< {
      __typename: "InventoryCustomItem",
      id: string,
      type: InventoryItemType,
      value: string,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInventoryCustomItemsQueryVariables = {
  filter?: ModelInventoryCustomItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInventoryCustomItemsQuery = {
  syncInventoryCustomItems?:  {
    __typename: "ModelInventoryCustomItemConnection",
    items:  Array< {
      __typename: "InventoryCustomItem",
      id: string,
      type: InventoryItemType,
      value: string,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInvoiceQueryVariables = {
  id: string,
};

export type GetInvoiceQuery = {
  getInvoice?:  {
    __typename: "Invoice",
    id: string,
    amount: number,
    bankAccountId: string,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    communicationId?: string | null,
    communication?:  {
      __typename: "Communication",
      id: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      blockedRecipients?:  Array< {
        __typename: "CommunicationBlockedRecipient",
        recipient: string,
        bounceType: BounceType,
      } > | null,
      body: string,
      buildingId?: string | null,
      channel: CommunicationChannel,
      communicationTemplateId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        amount: number,
        bankAccountId: string,
        communicationId?: string | null,
        createdAt: string,
        creditNote?: boolean | null,
        creditNoteLabel?: string | null,
        dueDate?: string | null,
        invoiceDate: string,
        invoiceNumber?: string | null,
        leaseId?: string | null,
        paid: boolean,
        receiptNumber?: string | null,
        reminderDates?: Array< string > | null,
        remittanceInformation?: string | null,
        statementId?: string | null,
        type: InvoiceType,
        unitId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      invoiceId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      leasePriceHistoryId?: string | null,
      leasePriceHistory?:  {
        __typename: "LeasePriceHistory",
        id: string,
        previousTotalRentalPrice: number,
        previousTotalMonthlyCharge: number,
        totalRentalPrice: number,
        optimalTotalRentalPrice?: number | null,
        totalMonthlyCharges: number,
        optimalTotalMonthlyCharges?: number | null,
        applicationDate: string,
        status: LeaseAmountUpdateStatus,
        comment?: string | null,
        type: LeasePriceHistoryType,
        communicationId?: string | null,
        leaseId: string,
        createdAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      linkedCommunicationsIds?: Array< string > | null,
      recipient?: string | null,
      recipientInBcc?: string | null,
      recipientInCc?: string | null,
      sender?: string | null,
      sentAt?: string | null,
      externalId?: string | null,
      signatureDocumentId?: string | null,
      signatureDocument?:  {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      snoozedDate?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId?: string | null,
      status: CommunicationStatus,
      subject: string,
      toSend: boolean,
      type: CommunicationType,
      _ttl?: number | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    creditNote?: boolean | null,
    creditNoteLabel?: string | null,
    dueDate?: string | null,
    invoiceDate: string,
    invoiceNumber?: string | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    paid: boolean,
    receiptNumber?: string | null,
    reminderDates?: Array< string > | null,
    remittanceInformation?: string | null,
    statementId?: string | null,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    type: InvoiceType,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListInvoicesQueryVariables = {
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInvoicesQuery = {
  listInvoices?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      creditNote?: boolean | null,
      creditNoteLabel?: string | null,
      dueDate?: string | null,
      invoiceDate: string,
      invoiceNumber?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      paid: boolean,
      receiptNumber?: string | null,
      reminderDates?: Array< string > | null,
      remittanceInformation?: string | null,
      statementId?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      type: InvoiceType,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInvoicesQueryVariables = {
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInvoicesQuery = {
  syncInvoices?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      creditNote?: boolean | null,
      creditNoteLabel?: string | null,
      dueDate?: string | null,
      invoiceDate: string,
      invoiceNumber?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      paid: boolean,
      receiptNumber?: string | null,
      reminderDates?: Array< string > | null,
      remittanceInformation?: string | null,
      statementId?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      type: InvoiceType,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInvoiceSettingQueryVariables = {
  id: string,
};

export type GetInvoiceSettingQuery = {
  getInvoiceSetting?:  {
    __typename: "InvoiceSetting",
    id: string,
    creditNoteFormat: string,
    creditNoteLastUpdate: string,
    creditNoteIncrementNumber: number,
    invoiceFormat: string,
    invoiceLastUpdate: string,
    invoiceIncrementNumber: number,
    paymentRequestFormat: string,
    paymentRequestLastUpdate: string,
    paymentRequestIncrementNumber: number,
    receiptFormat: string,
    receiptLastUpdate: string,
    receiptIncrementNumber: number,
    ownerId: string,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListInvoiceSettingsQueryVariables = {
  filter?: ModelInvoiceSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInvoiceSettingsQuery = {
  listInvoiceSettings?:  {
    __typename: "ModelInvoiceSettingConnection",
    items:  Array< {
      __typename: "InvoiceSetting",
      id: string,
      creditNoteFormat: string,
      creditNoteLastUpdate: string,
      creditNoteIncrementNumber: number,
      invoiceFormat: string,
      invoiceLastUpdate: string,
      invoiceIncrementNumber: number,
      paymentRequestFormat: string,
      paymentRequestLastUpdate: string,
      paymentRequestIncrementNumber: number,
      receiptFormat: string,
      receiptLastUpdate: string,
      receiptIncrementNumber: number,
      ownerId: string,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInvoiceSettingsQueryVariables = {
  filter?: ModelInvoiceSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInvoiceSettingsQuery = {
  syncInvoiceSettings?:  {
    __typename: "ModelInvoiceSettingConnection",
    items:  Array< {
      __typename: "InvoiceSetting",
      id: string,
      creditNoteFormat: string,
      creditNoteLastUpdate: string,
      creditNoteIncrementNumber: number,
      invoiceFormat: string,
      invoiceLastUpdate: string,
      invoiceIncrementNumber: number,
      paymentRequestFormat: string,
      paymentRequestLastUpdate: string,
      paymentRequestIncrementNumber: number,
      receiptFormat: string,
      receiptLastUpdate: string,
      receiptIncrementNumber: number,
      ownerId: string,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLeaseQueryVariables = {
  id: string,
};

export type GetLeaseQuery = {
  getLease?:  {
    __typename: "Lease",
    id: string,
    balance?: number | null,
    canBeExtended?: boolean | null,
    communicationSettingsProfileId: string,
    createdAt?: string | null,
    endDate: string,
    includeRealEstateInLeaseContract?: boolean | null,
    invoiceSenderId: string,
    invoiceSender?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inventoryOfFixturesExpert?: string | null,
    inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
    inventoryOfFixturesType?: InventoryOfFixturesType | null,
    ipiNumber?: string | null,
    language: Language,
    lastIndexationProcessDate?: string | null,
    lastInvoiceDate?: string | null,
    legalCompetentAuthority?: string | null,
    name: string,
    paymentFrequency: LeasePaymentFrequency,
    paymentInvoicePeriod: LeasePaymentInvoicePeriod,
    paymentInvoicePeriodCustomDay?: number | null,
    realEstateAgency?: string | null,
    realEstateTax?: number | null,
    remittanceInformation?: string | null,
    rentalGuarantyAmount?: number | null,
    rentalGuarantyFinancialInstitution?: string | null,
    rentalGuarantyType?: LeaseRentalGuarantyType | null,
    signatureDate?: string | null,
    startDate: string,
    status: LeaseStatus,
    technicCommunicationSettingsProfileId: string,
    totalInitialMonthlyCharges: number,
    totalInitialRentalPrice: number,
    totalMonthlyCharges: number,
    totalRentalPrice: number,
    type: LeaseType,
    vatRateCharge?: number | null,
    vatRateRent?: number | null,
    additionalClauses?:  {
      __typename: "ModelLeaseAdditionalClauseConnection",
      items:  Array< {
        __typename: "LeaseAdditionalClause",
        id: string,
        leaseId: string,
        title?: string | null,
        body: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bankAccountId?: string | null,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contacts?:  {
      __typename: "ModelLeaseContactConnection",
      items:  Array< {
        __typename: "LeaseContact",
        id: string,
        leaseId: string,
        contactId: string,
        contactRole: ContactType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leaseActionHistories?:  {
      __typename: "ModelLeaseActionHistoryConnection",
      items:  Array< {
        __typename: "LeaseActionHistory",
        id: string,
        action: LeaseAction,
        comment?: string | null,
        contactId: string,
        createdAt?: string | null,
        leaseId: string,
        signatureDocumentId?: string | null,
        status?: LeaseActionHistoryStatus | null,
        communicationChannel?: CommunicationChannel | null,
        communicationSendDate?: string | null,
        communicationByPlatform?: boolean | null,
        endReason?: LeaseActionEndReason | null,
        endReasonOwnUseRelation?: string | null,
        endReasonOwnUseName?: string | null,
        initiator?: LeaseActionEndInitiator | null,
        totalAmount?: number | null,
        totalAmountLabel?: string | null,
        automatic?: boolean | null,
        hasLeaseTypeChanged?: boolean | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leaseInventories?:  {
      __typename: "ModelLeaseInventoryConnection",
      items:  Array< {
        __typename: "LeaseInventory",
        id: string,
        leaseId: string,
        inventoryType: LeaseInventoryType,
        status: LeaseStatus,
        createdAt: string,
        signatureDate?: string | null,
        note?: string | null,
        fileId?: string | null,
        signatureMethods?: Array< SignatureType > | null,
        additionalMessage?: string | null,
        connectionPlugs?: Array< ConnectionPlugsType > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leasePriceHistories?:  {
      __typename: "ModelLeasePriceHistoryConnection",
      items:  Array< {
        __typename: "LeasePriceHistory",
        id: string,
        previousTotalRentalPrice: number,
        previousTotalMonthlyCharge: number,
        totalRentalPrice: number,
        optimalTotalRentalPrice?: number | null,
        totalMonthlyCharges: number,
        optimalTotalMonthlyCharges?: number | null,
        applicationDate: string,
        status: LeaseAmountUpdateStatus,
        comment?: string | null,
        type: LeasePriceHistoryType,
        communicationId?: string | null,
        leaseId: string,
        createdAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    signatureDocuments?:  {
      __typename: "ModelSignatureDocumentConnection",
      items:  Array< {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    units?:  {
      __typename: "ModelUnitLeaseConnection",
      items:  Array< {
        __typename: "UnitLease",
        id: string,
        allowChargeAdjustment?: boolean | null,
        authorizeProfessionalActivity?: boolean | null,
        chargesIndexation?: boolean | null,
        createdAt?: string | null,
        endDate: string,
        furnishedRental?: boolean | null,
        furnishedRentalInsuranceByTenant?: boolean | null,
        furnishedRentalRentalPricePercentage?: number | null,
        initialMonthlyCharges: number,
        initialRentalPrice: number,
        indexation?: boolean | null,
        advancedIndexation?: boolean | null,
        indexType?: IndexType | null,
        baseYear?: number | null,
        baseIndexType?: BaseIndexType | null,
        baseIndexDate?: string | null,
        leaseId: string,
        mainUnit: boolean,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        monthlyCharges: number,
        rentalPrice: number,
        signatureDate?: string | null,
        startDate: string,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    variousOperations?:  {
      __typename: "ModelLeaseVariousOperationConnection",
      items:  Array< {
        __typename: "LeaseVariousOperation",
        id: string,
        amount: number,
        contactId: string,
        createdAt: string,
        endDate: string,
        label: string,
        lastInvoiceDate?: string | null,
        leaseId: string,
        operationType: LeaseVariousOperationType,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        reason?: string | null,
        separateInvoice: boolean,
        startDate: string,
        type: VariousOperationType,
        unitId: string,
        vatRate?: number | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLeasesQueryVariables = {
  filter?: ModelLeaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLeasesQuery = {
  listLeases?:  {
    __typename: "ModelLeaseConnection",
    items:  Array< {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLeasesQueryVariables = {
  filter?: ModelLeaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLeasesQuery = {
  syncLeases?:  {
    __typename: "ModelLeaseConnection",
    items:  Array< {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLeaseActionHistoryQueryVariables = {
  id: string,
};

export type GetLeaseActionHistoryQuery = {
  getLeaseActionHistory?:  {
    __typename: "LeaseActionHistory",
    id: string,
    action: LeaseAction,
    comment?: string | null,
    contactId: string,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    historyDetails:  Array< {
      __typename: "LeaseActionHistoryDetail",
      unitId: string,
      newEndDate?: string | null,
      newStartDate?: string | null,
      previousEndDate?: string | null,
      previousStartDate?: string | null,
    } >,
    leaseId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    signatureDocumentId?: string | null,
    signatureDocument?:  {
      __typename: "SignatureDocument",
      id: string,
      additionalMessage?: string | null,
      communicationBody?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      fileIds?: Array< string > | null,
      foreignKey: string,
      foreignTableName: string,
      sentAt?: string | null,
      signatureMethods: Array< SignatureType >,
      signatureResults:  Array< {
        __typename: "SignatureDocumentResult",
        openedDate?: string | null,
        signedDate?: string | null,
        signed?: boolean | null,
        signatureLink?: string | null,
        rejectReason?: string | null,
        lastSignInviteSentDate?: string | null,
        trackingProvider?: TrackingProvider | null,
        trackingCode?: string | null,
        contactId: string,
      } >,
      status: SignatureDocumentStatus,
      externalStatus?: string | null,
      type: SignatureDocumentType,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status?: LeaseActionHistoryStatus | null,
    communicationChannel?: CommunicationChannel | null,
    communicationSendDate?: string | null,
    communicationByPlatform?: boolean | null,
    endReason?: LeaseActionEndReason | null,
    endReasonOwnUseRelation?: string | null,
    endReasonOwnUseName?: string | null,
    initiator?: LeaseActionEndInitiator | null,
    totalAmount?: number | null,
    totalAmountLabel?: string | null,
    automatic?: boolean | null,
    hasLeaseTypeChanged?: boolean | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLeaseActionHistoriesQueryVariables = {
  filter?: ModelLeaseActionHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLeaseActionHistoriesQuery = {
  listLeaseActionHistories?:  {
    __typename: "ModelLeaseActionHistoryConnection",
    items:  Array< {
      __typename: "LeaseActionHistory",
      id: string,
      action: LeaseAction,
      comment?: string | null,
      contactId: string,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      historyDetails:  Array< {
        __typename: "LeaseActionHistoryDetail",
        unitId: string,
        newEndDate?: string | null,
        newStartDate?: string | null,
        previousEndDate?: string | null,
        previousStartDate?: string | null,
      } >,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      signatureDocumentId?: string | null,
      signatureDocument?:  {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: LeaseActionHistoryStatus | null,
      communicationChannel?: CommunicationChannel | null,
      communicationSendDate?: string | null,
      communicationByPlatform?: boolean | null,
      endReason?: LeaseActionEndReason | null,
      endReasonOwnUseRelation?: string | null,
      endReasonOwnUseName?: string | null,
      initiator?: LeaseActionEndInitiator | null,
      totalAmount?: number | null,
      totalAmountLabel?: string | null,
      automatic?: boolean | null,
      hasLeaseTypeChanged?: boolean | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLeaseActionHistoriesQueryVariables = {
  filter?: ModelLeaseActionHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLeaseActionHistoriesQuery = {
  syncLeaseActionHistories?:  {
    __typename: "ModelLeaseActionHistoryConnection",
    items:  Array< {
      __typename: "LeaseActionHistory",
      id: string,
      action: LeaseAction,
      comment?: string | null,
      contactId: string,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      historyDetails:  Array< {
        __typename: "LeaseActionHistoryDetail",
        unitId: string,
        newEndDate?: string | null,
        newStartDate?: string | null,
        previousEndDate?: string | null,
        previousStartDate?: string | null,
      } >,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      signatureDocumentId?: string | null,
      signatureDocument?:  {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status?: LeaseActionHistoryStatus | null,
      communicationChannel?: CommunicationChannel | null,
      communicationSendDate?: string | null,
      communicationByPlatform?: boolean | null,
      endReason?: LeaseActionEndReason | null,
      endReasonOwnUseRelation?: string | null,
      endReasonOwnUseName?: string | null,
      initiator?: LeaseActionEndInitiator | null,
      totalAmount?: number | null,
      totalAmountLabel?: string | null,
      automatic?: boolean | null,
      hasLeaseTypeChanged?: boolean | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLeaseAdditionalClauseQueryVariables = {
  id: string,
};

export type GetLeaseAdditionalClauseQuery = {
  getLeaseAdditionalClause?:  {
    __typename: "LeaseAdditionalClause",
    id: string,
    leaseId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    title?: string | null,
    body: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLeaseAdditionalClausesQueryVariables = {
  filter?: ModelLeaseAdditionalClauseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLeaseAdditionalClausesQuery = {
  listLeaseAdditionalClauses?:  {
    __typename: "ModelLeaseAdditionalClauseConnection",
    items:  Array< {
      __typename: "LeaseAdditionalClause",
      id: string,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      title?: string | null,
      body: string,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLeaseAdditionalClausesQueryVariables = {
  filter?: ModelLeaseAdditionalClauseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLeaseAdditionalClausesQuery = {
  syncLeaseAdditionalClauses?:  {
    __typename: "ModelLeaseAdditionalClauseConnection",
    items:  Array< {
      __typename: "LeaseAdditionalClause",
      id: string,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      title?: string | null,
      body: string,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLeaseContactQueryVariables = {
  id: string,
};

export type GetLeaseContactQuery = {
  getLeaseContact?:  {
    __typename: "LeaseContact",
    id: string,
    leaseId: string,
    contactId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactRole: ContactType,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLeaseContactsQueryVariables = {
  filter?: ModelLeaseContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLeaseContactsQuery = {
  listLeaseContacts?:  {
    __typename: "ModelLeaseContactConnection",
    items:  Array< {
      __typename: "LeaseContact",
      id: string,
      leaseId: string,
      contactId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactRole: ContactType,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLeaseContactsQueryVariables = {
  filter?: ModelLeaseContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLeaseContactsQuery = {
  syncLeaseContacts?:  {
    __typename: "ModelLeaseContactConnection",
    items:  Array< {
      __typename: "LeaseContact",
      id: string,
      leaseId: string,
      contactId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactRole: ContactType,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLeasePriceHistoryQueryVariables = {
  id: string,
};

export type GetLeasePriceHistoryQuery = {
  getLeasePriceHistory?:  {
    __typename: "LeasePriceHistory",
    id: string,
    previousTotalRentalPrice: number,
    previousTotalMonthlyCharge: number,
    totalRentalPrice: number,
    optimalTotalRentalPrice?: number | null,
    totalMonthlyCharges: number,
    optimalTotalMonthlyCharges?: number | null,
    applicationDate: string,
    status: LeaseAmountUpdateStatus,
    comment?: string | null,
    amountDetails:  Array< {
      __typename: "LeasePriceHistoryDetail",
      unitId: string,
      indexationBaseIndex?: number | null,
      indexationNewIndex?: number | null,
      previousRentalPrice: number,
      newRentalPrice: number,
      rentalStatus: LeasePriceHistoryStatus,
      previousMonthlyChargesPrice: number,
      newMonthlyChargesPrice: number,
      monthlyChargesStatus: LeasePriceHistoryStatus,
      monthlyChargesType?: LeaseMonthlyChargesType | null,
      optimalNewRentalPrice?: number | null,
      optimalNewMonthlyChargesPrice?: number | null,
      epb?: string | null,
      epbIssueDate?: string | null,
      oldestIndexForCorrectionFactorCalculation?: number | null,
      newestIndexForCorrectionFactorCalculation?: number | null,
      rentIndexedPriceAfterNewEpbReduction?: number | null,
      chargesIndexedPriceAfterNewEpbReduction?: number | null,
      correctionFactor?: number | null,
      reductionPercentage?: number | null,
      rentDifference?: number | null,
      rentReduction?: number | null,
      indexedRentAfterReduction?: number | null,
      chargesDifference?: number | null,
      chargesReduction?: number | null,
      indexedChargesAfterReduction?: number | null,
      previousYearIndexationNewIndex?: number | null,
      previousYearIndexedRent?: number | null,
      previousYearIndexedCharges?: number | null,
    } >,
    type: LeasePriceHistoryType,
    communicationId?: string | null,
    communication?:  {
      __typename: "Communication",
      id: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      blockedRecipients?:  Array< {
        __typename: "CommunicationBlockedRecipient",
        recipient: string,
        bounceType: BounceType,
      } > | null,
      body: string,
      buildingId?: string | null,
      channel: CommunicationChannel,
      communicationTemplateId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        amount: number,
        bankAccountId: string,
        communicationId?: string | null,
        createdAt: string,
        creditNote?: boolean | null,
        creditNoteLabel?: string | null,
        dueDate?: string | null,
        invoiceDate: string,
        invoiceNumber?: string | null,
        leaseId?: string | null,
        paid: boolean,
        receiptNumber?: string | null,
        reminderDates?: Array< string > | null,
        remittanceInformation?: string | null,
        statementId?: string | null,
        type: InvoiceType,
        unitId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      invoiceId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      leasePriceHistoryId?: string | null,
      leasePriceHistory?:  {
        __typename: "LeasePriceHistory",
        id: string,
        previousTotalRentalPrice: number,
        previousTotalMonthlyCharge: number,
        totalRentalPrice: number,
        optimalTotalRentalPrice?: number | null,
        totalMonthlyCharges: number,
        optimalTotalMonthlyCharges?: number | null,
        applicationDate: string,
        status: LeaseAmountUpdateStatus,
        comment?: string | null,
        type: LeasePriceHistoryType,
        communicationId?: string | null,
        leaseId: string,
        createdAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      linkedCommunicationsIds?: Array< string > | null,
      recipient?: string | null,
      recipientInBcc?: string | null,
      recipientInCc?: string | null,
      sender?: string | null,
      sentAt?: string | null,
      externalId?: string | null,
      signatureDocumentId?: string | null,
      signatureDocument?:  {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      snoozedDate?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId?: string | null,
      status: CommunicationStatus,
      subject: string,
      toSend: boolean,
      type: CommunicationType,
      _ttl?: number | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLeasePriceHistoriesQueryVariables = {
  filter?: ModelLeasePriceHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLeasePriceHistoriesQuery = {
  listLeasePriceHistories?:  {
    __typename: "ModelLeasePriceHistoryConnection",
    items:  Array< {
      __typename: "LeasePriceHistory",
      id: string,
      previousTotalRentalPrice: number,
      previousTotalMonthlyCharge: number,
      totalRentalPrice: number,
      optimalTotalRentalPrice?: number | null,
      totalMonthlyCharges: number,
      optimalTotalMonthlyCharges?: number | null,
      applicationDate: string,
      status: LeaseAmountUpdateStatus,
      comment?: string | null,
      amountDetails:  Array< {
        __typename: "LeasePriceHistoryDetail",
        unitId: string,
        indexationBaseIndex?: number | null,
        indexationNewIndex?: number | null,
        previousRentalPrice: number,
        newRentalPrice: number,
        rentalStatus: LeasePriceHistoryStatus,
        previousMonthlyChargesPrice: number,
        newMonthlyChargesPrice: number,
        monthlyChargesStatus: LeasePriceHistoryStatus,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        optimalNewRentalPrice?: number | null,
        optimalNewMonthlyChargesPrice?: number | null,
        epb?: string | null,
        epbIssueDate?: string | null,
        oldestIndexForCorrectionFactorCalculation?: number | null,
        newestIndexForCorrectionFactorCalculation?: number | null,
        rentIndexedPriceAfterNewEpbReduction?: number | null,
        chargesIndexedPriceAfterNewEpbReduction?: number | null,
        correctionFactor?: number | null,
        reductionPercentage?: number | null,
        rentDifference?: number | null,
        rentReduction?: number | null,
        indexedRentAfterReduction?: number | null,
        chargesDifference?: number | null,
        chargesReduction?: number | null,
        indexedChargesAfterReduction?: number | null,
        previousYearIndexationNewIndex?: number | null,
        previousYearIndexedRent?: number | null,
        previousYearIndexedCharges?: number | null,
      } >,
      type: LeasePriceHistoryType,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLeasePriceHistoriesQueryVariables = {
  filter?: ModelLeasePriceHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLeasePriceHistoriesQuery = {
  syncLeasePriceHistories?:  {
    __typename: "ModelLeasePriceHistoryConnection",
    items:  Array< {
      __typename: "LeasePriceHistory",
      id: string,
      previousTotalRentalPrice: number,
      previousTotalMonthlyCharge: number,
      totalRentalPrice: number,
      optimalTotalRentalPrice?: number | null,
      totalMonthlyCharges: number,
      optimalTotalMonthlyCharges?: number | null,
      applicationDate: string,
      status: LeaseAmountUpdateStatus,
      comment?: string | null,
      amountDetails:  Array< {
        __typename: "LeasePriceHistoryDetail",
        unitId: string,
        indexationBaseIndex?: number | null,
        indexationNewIndex?: number | null,
        previousRentalPrice: number,
        newRentalPrice: number,
        rentalStatus: LeasePriceHistoryStatus,
        previousMonthlyChargesPrice: number,
        newMonthlyChargesPrice: number,
        monthlyChargesStatus: LeasePriceHistoryStatus,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        optimalNewRentalPrice?: number | null,
        optimalNewMonthlyChargesPrice?: number | null,
        epb?: string | null,
        epbIssueDate?: string | null,
        oldestIndexForCorrectionFactorCalculation?: number | null,
        newestIndexForCorrectionFactorCalculation?: number | null,
        rentIndexedPriceAfterNewEpbReduction?: number | null,
        chargesIndexedPriceAfterNewEpbReduction?: number | null,
        correctionFactor?: number | null,
        reductionPercentage?: number | null,
        rentDifference?: number | null,
        rentReduction?: number | null,
        indexedRentAfterReduction?: number | null,
        chargesDifference?: number | null,
        chargesReduction?: number | null,
        indexedChargesAfterReduction?: number | null,
        previousYearIndexationNewIndex?: number | null,
        previousYearIndexedRent?: number | null,
        previousYearIndexedCharges?: number | null,
      } >,
      type: LeasePriceHistoryType,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLeaseInventoryQueryVariables = {
  id: string,
};

export type GetLeaseInventoryQuery = {
  getLeaseInventory?:  {
    __typename: "LeaseInventory",
    id: string,
    leaseId: string,
    inventoryType: LeaseInventoryType,
    status: LeaseStatus,
    createdAt: string,
    signatureDate?: string | null,
    note?: string | null,
    encodings?:  {
      __typename: "ModelLeaseInventoryEncodingConnection",
      items:  Array< {
        __typename: "LeaseInventoryEncoding",
        id: string,
        leaseInventoryId: string,
        structureId: string,
        state?: LeaseInventoryStructureStateType | null,
        note?: string | null,
        furnitureQuantity?: number | null,
        furniturePrice?: number | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    fileId?: string | null,
    file?:  {
      __typename: "File",
      id: string,
      bucket: string,
      createdAt?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        entity?: string | null,
        fileCategory: FileCategory,
        parentId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categoryId: string,
      foreignKey: string,
      foreignTableName: string,
      key: string,
      mimeType?: string | null,
      region: string,
      signaturePositions?:  Array< {
        __typename: "SignaturePosition",
        top: number,
        left: number,
        page: number,
        width?: number | null,
        height?: number | null,
        contactId: string,
      } > | null,
      size?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    signatureMethods?: Array< SignatureType > | null,
    additionalMessage?: string | null,
    connectionPlugs?: Array< ConnectionPlugsType > | null,
    keys?:  Array< {
      __typename: "LeaseInventoryUnitKey",
      keyType: LeaseInventoryUnitKeyType,
      keyId?: string | null,
    } > | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLeaseInventoriesQueryVariables = {
  filter?: ModelLeaseInventoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLeaseInventoriesQuery = {
  listLeaseInventories?:  {
    __typename: "ModelLeaseInventoryConnection",
    items:  Array< {
      __typename: "LeaseInventory",
      id: string,
      leaseId: string,
      inventoryType: LeaseInventoryType,
      status: LeaseStatus,
      createdAt: string,
      signatureDate?: string | null,
      note?: string | null,
      encodings?:  {
        __typename: "ModelLeaseInventoryEncodingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      fileId?: string | null,
      file?:  {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      signatureMethods?: Array< SignatureType > | null,
      additionalMessage?: string | null,
      connectionPlugs?: Array< ConnectionPlugsType > | null,
      keys?:  Array< {
        __typename: "LeaseInventoryUnitKey",
        keyType: LeaseInventoryUnitKeyType,
        keyId?: string | null,
      } > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLeaseInventoriesQueryVariables = {
  filter?: ModelLeaseInventoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLeaseInventoriesQuery = {
  syncLeaseInventories?:  {
    __typename: "ModelLeaseInventoryConnection",
    items:  Array< {
      __typename: "LeaseInventory",
      id: string,
      leaseId: string,
      inventoryType: LeaseInventoryType,
      status: LeaseStatus,
      createdAt: string,
      signatureDate?: string | null,
      note?: string | null,
      encodings?:  {
        __typename: "ModelLeaseInventoryEncodingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      fileId?: string | null,
      file?:  {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      signatureMethods?: Array< SignatureType > | null,
      additionalMessage?: string | null,
      connectionPlugs?: Array< ConnectionPlugsType > | null,
      keys?:  Array< {
        __typename: "LeaseInventoryUnitKey",
        keyType: LeaseInventoryUnitKeyType,
        keyId?: string | null,
      } > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLeaseInventoryCheckedItemQueryVariables = {
  id: string,
};

export type GetLeaseInventoryCheckedItemQuery = {
  getLeaseInventoryCheckedItem?:  {
    __typename: "LeaseInventoryCheckedItem",
    id: string,
    custom: boolean,
    leaseInventoryEncodingId: string,
    totalDefects?: number | null,
    customItemId?: string | null,
    customItem?:  {
      __typename: "InventoryCustomItem",
      id: string,
      type: InventoryItemType,
      value: string,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    value?: string | null,
    type?: InventoryItemType | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLeaseInventoryCheckedItemsQueryVariables = {
  filter?: ModelLeaseInventoryCheckedItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLeaseInventoryCheckedItemsQuery = {
  listLeaseInventoryCheckedItems?:  {
    __typename: "ModelLeaseInventoryCheckedItemConnection",
    items:  Array< {
      __typename: "LeaseInventoryCheckedItem",
      id: string,
      custom: boolean,
      leaseInventoryEncodingId: string,
      totalDefects?: number | null,
      customItemId?: string | null,
      customItem?:  {
        __typename: "InventoryCustomItem",
        id: string,
        type: InventoryItemType,
        value: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      value?: string | null,
      type?: InventoryItemType | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLeaseInventoryCheckedItemsQueryVariables = {
  filter?: ModelLeaseInventoryCheckedItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLeaseInventoryCheckedItemsQuery = {
  syncLeaseInventoryCheckedItems?:  {
    __typename: "ModelLeaseInventoryCheckedItemConnection",
    items:  Array< {
      __typename: "LeaseInventoryCheckedItem",
      id: string,
      custom: boolean,
      leaseInventoryEncodingId: string,
      totalDefects?: number | null,
      customItemId?: string | null,
      customItem?:  {
        __typename: "InventoryCustomItem",
        id: string,
        type: InventoryItemType,
        value: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      value?: string | null,
      type?: InventoryItemType | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLeaseInventoryEncodingQueryVariables = {
  id: string,
};

export type GetLeaseInventoryEncodingQuery = {
  getLeaseInventoryEncoding?:  {
    __typename: "LeaseInventoryEncoding",
    id: string,
    leaseInventoryId: string,
    structureId: string,
    state?: LeaseInventoryStructureStateType | null,
    note?: string | null,
    checkedItems?:  {
      __typename: "ModelLeaseInventoryCheckedItemConnection",
      items:  Array< {
        __typename: "LeaseInventoryCheckedItem",
        id: string,
        custom: boolean,
        leaseInventoryEncodingId: string,
        totalDefects?: number | null,
        customItemId?: string | null,
        value?: string | null,
        type?: InventoryItemType | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    furnitureQuantity?: number | null,
    furniturePrice?: number | null,
    furnitureExitIssues?:  Array< {
      __typename: "LeaseInventoryFurnitureExitIssue",
      quantity: number,
      issue: LeaseInventoryFurnitureExitIssueType,
      action: LeaseInventoryFurnitureExitIssueAction,
      penalty?: number | null,
    } > | null,
    medias?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLeaseInventoryEncodingsQueryVariables = {
  filter?: ModelLeaseInventoryEncodingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLeaseInventoryEncodingsQuery = {
  listLeaseInventoryEncodings?:  {
    __typename: "ModelLeaseInventoryEncodingConnection",
    items:  Array< {
      __typename: "LeaseInventoryEncoding",
      id: string,
      leaseInventoryId: string,
      structureId: string,
      state?: LeaseInventoryStructureStateType | null,
      note?: string | null,
      checkedItems?:  {
        __typename: "ModelLeaseInventoryCheckedItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      furnitureQuantity?: number | null,
      furniturePrice?: number | null,
      furnitureExitIssues?:  Array< {
        __typename: "LeaseInventoryFurnitureExitIssue",
        quantity: number,
        issue: LeaseInventoryFurnitureExitIssueType,
        action: LeaseInventoryFurnitureExitIssueAction,
        penalty?: number | null,
      } > | null,
      medias?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLeaseInventoryEncodingsQueryVariables = {
  filter?: ModelLeaseInventoryEncodingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLeaseInventoryEncodingsQuery = {
  syncLeaseInventoryEncodings?:  {
    __typename: "ModelLeaseInventoryEncodingConnection",
    items:  Array< {
      __typename: "LeaseInventoryEncoding",
      id: string,
      leaseInventoryId: string,
      structureId: string,
      state?: LeaseInventoryStructureStateType | null,
      note?: string | null,
      checkedItems?:  {
        __typename: "ModelLeaseInventoryCheckedItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      furnitureQuantity?: number | null,
      furniturePrice?: number | null,
      furnitureExitIssues?:  Array< {
        __typename: "LeaseInventoryFurnitureExitIssue",
        quantity: number,
        issue: LeaseInventoryFurnitureExitIssueType,
        action: LeaseInventoryFurnitureExitIssueAction,
        penalty?: number | null,
      } > | null,
      medias?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLeaseVariousOperationQueryVariables = {
  id: string,
};

export type GetLeaseVariousOperationQuery = {
  getLeaseVariousOperation?:  {
    __typename: "LeaseVariousOperation",
    id: string,
    amount: number,
    contactId: string,
    createdAt: string,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    endDate: string,
    label: string,
    lastInvoiceDate?: string | null,
    leaseId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseVariousOperationHistories?:  Array< {
      __typename: "LeaseVariousOperationHistory",
      periodFrom: string,
      periodTo: string,
      amount: number,
    } > | null,
    operationType: LeaseVariousOperationType,
    paymentFrequency: LeasePaymentFrequency,
    paymentInvoicePeriod: LeasePaymentInvoicePeriod,
    paymentInvoicePeriodCustomDay?: number | null,
    reason?: string | null,
    separateInvoice: boolean,
    startDate: string,
    type: VariousOperationType,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId: string,
    vatRate?: number | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLeaseVariousOperationsQueryVariables = {
  filter?: ModelLeaseVariousOperationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLeaseVariousOperationsQuery = {
  listLeaseVariousOperations?:  {
    __typename: "ModelLeaseVariousOperationConnection",
    items:  Array< {
      __typename: "LeaseVariousOperation",
      id: string,
      amount: number,
      contactId: string,
      createdAt: string,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      endDate: string,
      label: string,
      lastInvoiceDate?: string | null,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseVariousOperationHistories?:  Array< {
        __typename: "LeaseVariousOperationHistory",
        periodFrom: string,
        periodTo: string,
        amount: number,
      } > | null,
      operationType: LeaseVariousOperationType,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      reason?: string | null,
      separateInvoice: boolean,
      startDate: string,
      type: VariousOperationType,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId: string,
      vatRate?: number | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLeaseVariousOperationsQueryVariables = {
  filter?: ModelLeaseVariousOperationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLeaseVariousOperationsQuery = {
  syncLeaseVariousOperations?:  {
    __typename: "ModelLeaseVariousOperationConnection",
    items:  Array< {
      __typename: "LeaseVariousOperation",
      id: string,
      amount: number,
      contactId: string,
      createdAt: string,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      endDate: string,
      label: string,
      lastInvoiceDate?: string | null,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseVariousOperationHistories?:  Array< {
        __typename: "LeaseVariousOperationHistory",
        periodFrom: string,
        periodTo: string,
        amount: number,
      } > | null,
      operationType: LeaseVariousOperationType,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      reason?: string | null,
      separateInvoice: boolean,
      startDate: string,
      type: VariousOperationType,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId: string,
      vatRate?: number | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMortgageQueryVariables = {
  id: string,
};

export type GetMortgageQuery = {
  getMortgage?:  {
    __typename: "Mortgage",
    id: string,
    lender: string,
    durationInYears: number,
    rateType: MortgageRateType,
    interestRate: number,
    aprRate: number,
    startDate: string,
    amount: number,
    monthlyPayment: number,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMortgagesQueryVariables = {
  filter?: ModelMortgageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMortgagesQuery = {
  listMortgages?:  {
    __typename: "ModelMortgageConnection",
    items:  Array< {
      __typename: "Mortgage",
      id: string,
      lender: string,
      durationInYears: number,
      rateType: MortgageRateType,
      interestRate: number,
      aprRate: number,
      startDate: string,
      amount: number,
      monthlyPayment: number,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      buildingId?: string | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      writers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMortgagesQueryVariables = {
  filter?: ModelMortgageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMortgagesQuery = {
  syncMortgages?:  {
    __typename: "ModelMortgageConnection",
    items:  Array< {
      __typename: "Mortgage",
      id: string,
      lender: string,
      durationInYears: number,
      rateType: MortgageRateType,
      interestRate: number,
      aprRate: number,
      startDate: string,
      amount: number,
      monthlyPayment: number,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      buildingId?: string | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      writers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    createdBy: NotificationCreation,
    type?: NotificationType | null,
    message?: string | null,
    popupDate: string,
    expirationDate?: string | null,
    status: NotificationStatus,
    createdAt?: string | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    technicId?: string | null,
    technic?:  {
      __typename: "Technic",
      id: string,
      type: TechnicType,
      contractNumber?: string | null,
      communicationSettingsProfileId: string,
      maintenanceHistory?:  Array< {
        __typename: "TechnicMaintenanceHistory",
        date: string,
        contactId?: string | null,
        fileId?: string | null,
      } > | null,
      utilityType?: UtilityType | null,
      utilityEanCode?: string | null,
      utilityMeterNumber?: string | null,
      utilityDistributorId?: string | null,
      utilityDistributor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      utilityHistory?:  Array< {
        __typename: "UtilityHistory",
        date: string,
        reason?: UtilityReadingReason | null,
        reading: number,
        secondReading?: number | null,
      } > | null,
      PebValidityDate?: string | null,
      PebEnergeticPerformance?: string | null,
      PebReportNumber?: string | null,
      PebIssueDate?: string | null,
      detectorType?: DetectorType | null,
      detectorSerialNumber?: string | null,
      detectorBrand?: string | null,
      detectorLastControlDate?: string | null,
      detectorUnitInventoryId?: string | null,
      heatingBrand?: string | null,
      heatingModel?: string | null,
      heatingInstallationDate?: string | null,
      heatingType?: HeatingType | null,
      heatingLastControlDate?: string | null,
      tankType?: TankType | null,
      tankCapacity?: number | null,
      tankState?: TankState | null,
      tankLastControlDate?: string | null,
      tankLeakDetection?: boolean | null,
      tankEncuvement?: boolean | null,
      tankWallType?: TankWallType | null,
      tankInstallationDate?: string | null,
      chimneyLastControlDate?: string | null,
      chimneyUnitInventoryId?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildingId?: string | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      originalTechnicId?: string | null,
      readers?: Array< string > | null,
      writers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ticketId?: string | null,
    ticket?:  {
      __typename: "Ticket",
      id: string,
      assignedAt?: string | null,
      assignedTo?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      assignedToId?: string | null,
      attachment?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      buildingId?: string | null,
      closedAt?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId: string,
      content: string,
      conversations?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      emailMessageId?: string | null,
      emailReferences?: Array< string | null > | null,
      parentId?: string | null,
      plannedAt?: string | null,
      referenceCode?: string | null,
      sourceType: TicketSourceType,
      status?: TicketStatus | null,
      subject: string,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unread: boolean,
      updatedAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      createdBy: NotificationCreation,
      type?: NotificationType | null,
      message?: string | null,
      popupDate: string,
      expirationDate?: string | null,
      status: NotificationStatus,
      createdAt?: string | null,
      buildingId?: string | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      technicId?: string | null,
      technic?:  {
        __typename: "Technic",
        id: string,
        type: TechnicType,
        contractNumber?: string | null,
        communicationSettingsProfileId: string,
        utilityType?: UtilityType | null,
        utilityEanCode?: string | null,
        utilityMeterNumber?: string | null,
        utilityDistributorId?: string | null,
        PebValidityDate?: string | null,
        PebEnergeticPerformance?: string | null,
        PebReportNumber?: string | null,
        PebIssueDate?: string | null,
        detectorType?: DetectorType | null,
        detectorSerialNumber?: string | null,
        detectorBrand?: string | null,
        detectorLastControlDate?: string | null,
        detectorUnitInventoryId?: string | null,
        heatingBrand?: string | null,
        heatingModel?: string | null,
        heatingInstallationDate?: string | null,
        heatingType?: HeatingType | null,
        heatingLastControlDate?: string | null,
        tankType?: TankType | null,
        tankCapacity?: number | null,
        tankState?: TankState | null,
        tankLastControlDate?: string | null,
        tankLeakDetection?: boolean | null,
        tankEncuvement?: boolean | null,
        tankWallType?: TankWallType | null,
        tankInstallationDate?: string | null,
        chimneyLastControlDate?: string | null,
        chimneyUnitInventoryId?: string | null,
        buildingId?: string | null,
        unitId?: string | null,
        leaseId?: string | null,
        contactId?: string | null,
        originalTechnicId?: string | null,
        readers?: Array< string > | null,
        writers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ticketId?: string | null,
      ticket?:  {
        __typename: "Ticket",
        id: string,
        assignedAt?: string | null,
        assignedToId?: string | null,
        buildingId?: string | null,
        closedAt?: string | null,
        contactId: string,
        content: string,
        createdAt: string,
        emailMessageId?: string | null,
        emailReferences?: Array< string | null > | null,
        parentId?: string | null,
        plannedAt?: string | null,
        referenceCode?: string | null,
        sourceType: TicketSourceType,
        status?: TicketStatus | null,
        subject: string,
        unitId?: string | null,
        unread: boolean,
        updatedAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncNotificationsQuery = {
  syncNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      createdBy: NotificationCreation,
      type?: NotificationType | null,
      message?: string | null,
      popupDate: string,
      expirationDate?: string | null,
      status: NotificationStatus,
      createdAt?: string | null,
      buildingId?: string | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      technicId?: string | null,
      technic?:  {
        __typename: "Technic",
        id: string,
        type: TechnicType,
        contractNumber?: string | null,
        communicationSettingsProfileId: string,
        utilityType?: UtilityType | null,
        utilityEanCode?: string | null,
        utilityMeterNumber?: string | null,
        utilityDistributorId?: string | null,
        PebValidityDate?: string | null,
        PebEnergeticPerformance?: string | null,
        PebReportNumber?: string | null,
        PebIssueDate?: string | null,
        detectorType?: DetectorType | null,
        detectorSerialNumber?: string | null,
        detectorBrand?: string | null,
        detectorLastControlDate?: string | null,
        detectorUnitInventoryId?: string | null,
        heatingBrand?: string | null,
        heatingModel?: string | null,
        heatingInstallationDate?: string | null,
        heatingType?: HeatingType | null,
        heatingLastControlDate?: string | null,
        tankType?: TankType | null,
        tankCapacity?: number | null,
        tankState?: TankState | null,
        tankLastControlDate?: string | null,
        tankLeakDetection?: boolean | null,
        tankEncuvement?: boolean | null,
        tankWallType?: TankWallType | null,
        tankInstallationDate?: string | null,
        chimneyLastControlDate?: string | null,
        chimneyUnitInventoryId?: string | null,
        buildingId?: string | null,
        unitId?: string | null,
        leaseId?: string | null,
        contactId?: string | null,
        originalTechnicId?: string | null,
        readers?: Array< string > | null,
        writers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ticketId?: string | null,
      ticket?:  {
        __typename: "Ticket",
        id: string,
        assignedAt?: string | null,
        assignedToId?: string | null,
        buildingId?: string | null,
        closedAt?: string | null,
        contactId: string,
        content: string,
        createdAt: string,
        emailMessageId?: string | null,
        emailReferences?: Array< string | null > | null,
        parentId?: string | null,
        plannedAt?: string | null,
        referenceCode?: string | null,
        sourceType: TicketSourceType,
        status?: TicketStatus | null,
        subject: string,
        unitId?: string | null,
        unread: boolean,
        updatedAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPostingQueryVariables = {
  id: string,
};

export type GetPostingQuery = {
  getPosting?:  {
    __typename: "Posting",
    id: string,
    createdAt?: string | null,
    class: number,
    customLabel?: string | null,
    topClass: number,
    type: PostingType,
    totalAmount: number,
    periodFrom: string,
    periodTo: string,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    invoiceId?: string | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      creditNote?: boolean | null,
      creditNoteLabel?: string | null,
      dueDate?: string | null,
      invoiceDate: string,
      invoiceNumber?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      paid: boolean,
      receiptNumber?: string | null,
      reminderDates?: Array< string > | null,
      remittanceInformation?: string | null,
      statementId?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      type: InvoiceType,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    chargeId?: string | null,
    charge?:  {
      __typename: "Charge",
      id: string,
      status: ChargeStatus,
      invoiceDate?: string | null,
      paymentDueDate?: string | null,
      archivedDate?: string | null,
      periodFrom?: string | null,
      periodTo?: string | null,
      totalAmount?: number | null,
      source: ChargeSource,
      draftFromOcr?: boolean | null,
      accountingDocumentId?: string | null,
      paid: boolean,
      remittanceInformation?: string | null,
      note?: string | null,
      createdAt: string,
      creatorEmail?: string | null,
      createdById?: string | null,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contractorId?: string | null,
      contractor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedContractors?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      recipientId?: string | null,
      recipient?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      proposedRecipients?:  Array< {
        __typename: "ProposedValue",
        id: string,
        confidence?: number | null,
      } > | null,
      documentId: string,
      document?:  {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      postings?:  {
        __typename: "ModelPostingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    transactionId?: string | null,
    transaction?:  {
      __typename: "Transaction",
      id: string,
      status: TransactionStatus,
      sequence?: number | null,
      statementDate: string,
      operationDate?: string | null,
      createdAt?: string | null,
      remittanceInformationType?: RemittanceType | null,
      remittanceInformation?: string | null,
      comment?: string | null,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      counterpartName?: string | null,
      counterpartAccountNumber?: string | null,
      manualEntry?: boolean | null,
      proposedLeaseId?: string | null,
      proposedUnitId?: string | null,
      proposedContractorId?: string | null,
      proposedMatch?: number | null,
      links?:  Array< {
        __typename: "TransactionLink",
        amount: number,
        linkId: string,
        linkType: TransactionLinkType,
      } > | null,
      postings?:  {
        __typename: "ModelPostingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    bankAccountId?: string | null,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    accountLabelId?: string | null,
    accountLabel?:  {
      __typename: "AccountLabel",
      id: string,
      labels?:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } > | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      class: number,
      topClass: number,
      customUsage?: AccountLabelCustomUsage | null,
      disabled?: boolean | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseVariousOperationId?: string | null,
    leaseVariousOperation?:  {
      __typename: "LeaseVariousOperation",
      id: string,
      amount: number,
      contactId: string,
      createdAt: string,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      endDate: string,
      label: string,
      lastInvoiceDate?: string | null,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseVariousOperationHistories?:  Array< {
        __typename: "LeaseVariousOperationHistory",
        periodFrom: string,
        periodTo: string,
        amount: number,
      } > | null,
      operationType: LeaseVariousOperationType,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      reason?: string | null,
      separateInvoice: boolean,
      startDate: string,
      type: VariousOperationType,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId: string,
      vatRate?: number | null,
      readId: string,
      readers?: Array< string > | null,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId?: string | null,
    amountVatExcluded?: number | null,
    vatAmount?: number | null,
    vatRate?: number | null,
    foreignId?: string | null,
    foreignType?: PostingForeignType | null,
    invoicePostingId?: string | null,
    reconciledAt?: string | null,
    status?: PostingStatus | null,
    chargeOwnerRepartition?: number | null,
    chargeRepartitionMetric?: string | null,
    chargeRepartitionValue?: number | null,
    chargeSplitNumber?: number | null,
    chargeType?: PostingChargeType | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPostingsQueryVariables = {
  filter?: ModelPostingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPostingsQuery = {
  listPostings?:  {
    __typename: "ModelPostingConnection",
    items:  Array< {
      __typename: "Posting",
      id: string,
      createdAt?: string | null,
      class: number,
      customLabel?: string | null,
      topClass: number,
      type: PostingType,
      totalAmount: number,
      periodFrom: string,
      periodTo: string,
      buildingId?: string | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      invoiceId?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        amount: number,
        bankAccountId: string,
        communicationId?: string | null,
        createdAt: string,
        creditNote?: boolean | null,
        creditNoteLabel?: string | null,
        dueDate?: string | null,
        invoiceDate: string,
        invoiceNumber?: string | null,
        leaseId?: string | null,
        paid: boolean,
        receiptNumber?: string | null,
        reminderDates?: Array< string > | null,
        remittanceInformation?: string | null,
        statementId?: string | null,
        type: InvoiceType,
        unitId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      chargeId?: string | null,
      charge?:  {
        __typename: "Charge",
        id: string,
        status: ChargeStatus,
        invoiceDate?: string | null,
        paymentDueDate?: string | null,
        archivedDate?: string | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        totalAmount?: number | null,
        source: ChargeSource,
        draftFromOcr?: boolean | null,
        accountingDocumentId?: string | null,
        paid: boolean,
        remittanceInformation?: string | null,
        note?: string | null,
        createdAt: string,
        creatorEmail?: string | null,
        createdById?: string | null,
        contractorId?: string | null,
        recipientId?: string | null,
        documentId: string,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transactionId?: string | null,
      transaction?:  {
        __typename: "Transaction",
        id: string,
        status: TransactionStatus,
        sequence?: number | null,
        statementDate: string,
        operationDate?: string | null,
        createdAt?: string | null,
        remittanceInformationType?: RemittanceType | null,
        remittanceInformation?: string | null,
        comment?: string | null,
        amount: number,
        bankAccountId: string,
        counterpartName?: string | null,
        counterpartAccountNumber?: string | null,
        manualEntry?: boolean | null,
        proposedLeaseId?: string | null,
        proposedUnitId?: string | null,
        proposedContractorId?: string | null,
        proposedMatch?: number | null,
        updatedAt: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      accountLabelId?: string | null,
      accountLabel?:  {
        __typename: "AccountLabel",
        id: string,
        bankAccountId?: string | null,
        contactId?: string | null,
        class: number,
        topClass: number,
        customUsage?: AccountLabelCustomUsage | null,
        disabled?: boolean | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseVariousOperationId?: string | null,
      leaseVariousOperation?:  {
        __typename: "LeaseVariousOperation",
        id: string,
        amount: number,
        contactId: string,
        createdAt: string,
        endDate: string,
        label: string,
        lastInvoiceDate?: string | null,
        leaseId: string,
        operationType: LeaseVariousOperationType,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        reason?: string | null,
        separateInvoice: boolean,
        startDate: string,
        type: VariousOperationType,
        unitId: string,
        vatRate?: number | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId?: string | null,
      amountVatExcluded?: number | null,
      vatAmount?: number | null,
      vatRate?: number | null,
      foreignId?: string | null,
      foreignType?: PostingForeignType | null,
      invoicePostingId?: string | null,
      reconciledAt?: string | null,
      status?: PostingStatus | null,
      chargeOwnerRepartition?: number | null,
      chargeRepartitionMetric?: string | null,
      chargeRepartitionValue?: number | null,
      chargeSplitNumber?: number | null,
      chargeType?: PostingChargeType | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPostingsQueryVariables = {
  filter?: ModelPostingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPostingsQuery = {
  syncPostings?:  {
    __typename: "ModelPostingConnection",
    items:  Array< {
      __typename: "Posting",
      id: string,
      createdAt?: string | null,
      class: number,
      customLabel?: string | null,
      topClass: number,
      type: PostingType,
      totalAmount: number,
      periodFrom: string,
      periodTo: string,
      buildingId?: string | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      invoiceId?: string | null,
      invoice?:  {
        __typename: "Invoice",
        id: string,
        amount: number,
        bankAccountId: string,
        communicationId?: string | null,
        createdAt: string,
        creditNote?: boolean | null,
        creditNoteLabel?: string | null,
        dueDate?: string | null,
        invoiceDate: string,
        invoiceNumber?: string | null,
        leaseId?: string | null,
        paid: boolean,
        receiptNumber?: string | null,
        reminderDates?: Array< string > | null,
        remittanceInformation?: string | null,
        statementId?: string | null,
        type: InvoiceType,
        unitId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      chargeId?: string | null,
      charge?:  {
        __typename: "Charge",
        id: string,
        status: ChargeStatus,
        invoiceDate?: string | null,
        paymentDueDate?: string | null,
        archivedDate?: string | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        totalAmount?: number | null,
        source: ChargeSource,
        draftFromOcr?: boolean | null,
        accountingDocumentId?: string | null,
        paid: boolean,
        remittanceInformation?: string | null,
        note?: string | null,
        createdAt: string,
        creatorEmail?: string | null,
        createdById?: string | null,
        contractorId?: string | null,
        recipientId?: string | null,
        documentId: string,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      transactionId?: string | null,
      transaction?:  {
        __typename: "Transaction",
        id: string,
        status: TransactionStatus,
        sequence?: number | null,
        statementDate: string,
        operationDate?: string | null,
        createdAt?: string | null,
        remittanceInformationType?: RemittanceType | null,
        remittanceInformation?: string | null,
        comment?: string | null,
        amount: number,
        bankAccountId: string,
        counterpartName?: string | null,
        counterpartAccountNumber?: string | null,
        manualEntry?: boolean | null,
        proposedLeaseId?: string | null,
        proposedUnitId?: string | null,
        proposedContractorId?: string | null,
        proposedMatch?: number | null,
        updatedAt: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      accountLabelId?: string | null,
      accountLabel?:  {
        __typename: "AccountLabel",
        id: string,
        bankAccountId?: string | null,
        contactId?: string | null,
        class: number,
        topClass: number,
        customUsage?: AccountLabelCustomUsage | null,
        disabled?: boolean | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseVariousOperationId?: string | null,
      leaseVariousOperation?:  {
        __typename: "LeaseVariousOperation",
        id: string,
        amount: number,
        contactId: string,
        createdAt: string,
        endDate: string,
        label: string,
        lastInvoiceDate?: string | null,
        leaseId: string,
        operationType: LeaseVariousOperationType,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        reason?: string | null,
        separateInvoice: boolean,
        startDate: string,
        type: VariousOperationType,
        unitId: string,
        vatRate?: number | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId?: string | null,
      amountVatExcluded?: number | null,
      vatAmount?: number | null,
      vatRate?: number | null,
      foreignId?: string | null,
      foreignType?: PostingForeignType | null,
      invoicePostingId?: string | null,
      reconciledAt?: string | null,
      status?: PostingStatus | null,
      chargeOwnerRepartition?: number | null,
      chargeRepartitionMetric?: string | null,
      chargeRepartitionValue?: number | null,
      chargeSplitNumber?: number | null,
      chargeType?: PostingChargeType | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetRepartitionKeyQueryVariables = {
  id: string,
};

export type GetRepartitionKeyQuery = {
  getRepartitionKey?:  {
    __typename: "RepartitionKey",
    id: string,
    name: string,
    type: RepartitionKeyType,
    accountLabelId: string,
    accountLabel?:  {
      __typename: "AccountLabel",
      id: string,
      labels?:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } > | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      class: number,
      topClass: number,
      customUsage?: AccountLabelCustomUsage | null,
      disabled?: boolean | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitRepartition?:  Array< {
      __typename: "RepartitionValues",
      unitId: string,
      value: number,
    } > | null,
    ownerRepartition: number,
    tenantRepartition: number,
    repartitionMetric: string,
    technics?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListRepartitionKeysQueryVariables = {
  filter?: ModelRepartitionKeyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRepartitionKeysQuery = {
  listRepartitionKeys?:  {
    __typename: "ModelRepartitionKeyConnection",
    items:  Array< {
      __typename: "RepartitionKey",
      id: string,
      name: string,
      type: RepartitionKeyType,
      accountLabelId: string,
      accountLabel?:  {
        __typename: "AccountLabel",
        id: string,
        bankAccountId?: string | null,
        contactId?: string | null,
        class: number,
        topClass: number,
        customUsage?: AccountLabelCustomUsage | null,
        disabled?: boolean | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitRepartition?:  Array< {
        __typename: "RepartitionValues",
        unitId: string,
        value: number,
      } > | null,
      ownerRepartition: number,
      tenantRepartition: number,
      repartitionMetric: string,
      technics?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncRepartitionKeysQueryVariables = {
  filter?: ModelRepartitionKeyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncRepartitionKeysQuery = {
  syncRepartitionKeys?:  {
    __typename: "ModelRepartitionKeyConnection",
    items:  Array< {
      __typename: "RepartitionKey",
      id: string,
      name: string,
      type: RepartitionKeyType,
      accountLabelId: string,
      accountLabel?:  {
        __typename: "AccountLabel",
        id: string,
        bankAccountId?: string | null,
        contactId?: string | null,
        class: number,
        topClass: number,
        customUsage?: AccountLabelCustomUsage | null,
        disabled?: boolean | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitRepartition?:  Array< {
        __typename: "RepartitionValues",
        unitId: string,
        value: number,
      } > | null,
      ownerRepartition: number,
      tenantRepartition: number,
      repartitionMetric: string,
      technics?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetRepaymentStatementQueryVariables = {
  id: string,
};

export type GetRepaymentStatementQuery = {
  getRepaymentStatement?:  {
    __typename: "RepaymentStatement",
    id: string,
    delaysCleared: number,
    expectedRent: number,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId: string,
    rentsCollected: number,
    repaymentStatementDetails:  Array< {
      __typename: "RepaymentStatementDetail",
      class: number,
      invoiceId: string,
      invoicePostingId: string,
      periodFrom: string,
      periodTo: string,
      totalAmount: number,
      transactionId?: string | null,
      type: RepaymentStatementDetailType,
    } >,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId: string,
    totalReceivedInJointAccount: number,
    totalExpectedInOwnerAccount: number,
    unpaidRents: number,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListRepaymentStatementsQueryVariables = {
  filter?: ModelRepaymentStatementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRepaymentStatementsQuery = {
  listRepaymentStatements?:  {
    __typename: "ModelRepaymentStatementConnection",
    items:  Array< {
      __typename: "RepaymentStatement",
      id: string,
      delaysCleared: number,
      expectedRent: number,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId: string,
      rentsCollected: number,
      repaymentStatementDetails:  Array< {
        __typename: "RepaymentStatementDetail",
        class: number,
        invoiceId: string,
        invoicePostingId: string,
        periodFrom: string,
        periodTo: string,
        totalAmount: number,
        transactionId?: string | null,
        type: RepaymentStatementDetailType,
      } >,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId: string,
      totalReceivedInJointAccount: number,
      totalExpectedInOwnerAccount: number,
      unpaidRents: number,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncRepaymentStatementsQueryVariables = {
  filter?: ModelRepaymentStatementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncRepaymentStatementsQuery = {
  syncRepaymentStatements?:  {
    __typename: "ModelRepaymentStatementConnection",
    items:  Array< {
      __typename: "RepaymentStatement",
      id: string,
      delaysCleared: number,
      expectedRent: number,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId: string,
      rentsCollected: number,
      repaymentStatementDetails:  Array< {
        __typename: "RepaymentStatementDetail",
        class: number,
        invoiceId: string,
        invoicePostingId: string,
        periodFrom: string,
        periodTo: string,
        totalAmount: number,
        transactionId?: string | null,
        type: RepaymentStatementDetailType,
      } >,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      statementId: string,
      totalReceivedInJointAccount: number,
      totalExpectedInOwnerAccount: number,
      unpaidRents: number,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSendingSourceQueryVariables = {
  id: string,
};

export type GetSendingSourceQuery = {
  getSendingSource?:  {
    __typename: "SendingSource",
    id: string,
    createdAt?: string | null,
    createdById?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dnsRecords?:  Array< {
      __typename: "DNSRecord",
      name: string,
      type: string,
      value: string,
    } > | null,
    emailAddress: string,
    issuesEmail: string,
    chargesEmail: string,
    errorMessage?: SendingSourceErrorType | null,
    name: string,
    lastReminderDate?: string | null,
    status: SendingSourceStatus,
    statusLastVerification?: string | null,
    verifiedType: SendingSourceVerifiedType,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSendingSourcesQueryVariables = {
  filter?: ModelSendingSourceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSendingSourcesQuery = {
  listSendingSources?:  {
    __typename: "ModelSendingSourceConnection",
    items:  Array< {
      __typename: "SendingSource",
      id: string,
      createdAt?: string | null,
      createdById?: string | null,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dnsRecords?:  Array< {
        __typename: "DNSRecord",
        name: string,
        type: string,
        value: string,
      } > | null,
      emailAddress: string,
      issuesEmail: string,
      chargesEmail: string,
      errorMessage?: SendingSourceErrorType | null,
      name: string,
      lastReminderDate?: string | null,
      status: SendingSourceStatus,
      statusLastVerification?: string | null,
      verifiedType: SendingSourceVerifiedType,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSendingSourcesQueryVariables = {
  filter?: ModelSendingSourceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSendingSourcesQuery = {
  syncSendingSources?:  {
    __typename: "ModelSendingSourceConnection",
    items:  Array< {
      __typename: "SendingSource",
      id: string,
      createdAt?: string | null,
      createdById?: string | null,
      createdBy?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      dnsRecords?:  Array< {
        __typename: "DNSRecord",
        name: string,
        type: string,
        value: string,
      } > | null,
      emailAddress: string,
      issuesEmail: string,
      chargesEmail: string,
      errorMessage?: SendingSourceErrorType | null,
      name: string,
      lastReminderDate?: string | null,
      status: SendingSourceStatus,
      statusLastVerification?: string | null,
      verifiedType: SendingSourceVerifiedType,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSettingQueryVariables = {
  id: string,
};

export type GetSettingQuery = {
  getSetting?:  {
    __typename: "Setting",
    id: string,
    name: SettingType,
    value: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSettingsQueryVariables = {
  filter?: ModelSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSettingsQuery = {
  listSettings?:  {
    __typename: "ModelSettingConnection",
    items:  Array< {
      __typename: "Setting",
      id: string,
      name: SettingType,
      value: string,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSettingsQueryVariables = {
  filter?: ModelSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSettingsQuery = {
  syncSettings?:  {
    __typename: "ModelSettingConnection",
    items:  Array< {
      __typename: "Setting",
      id: string,
      name: SettingType,
      value: string,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSignatureDocumentQueryVariables = {
  id: string,
};

export type GetSignatureDocumentQuery = {
  getSignatureDocument?:  {
    __typename: "SignatureDocument",
    id: string,
    additionalMessage?: string | null,
    communicationBody?: string | null,
    createdAt?: string | null,
    expirationDate?: string | null,
    fileIds?: Array< string > | null,
    foreignKey: string,
    foreignTableName: string,
    sentAt?: string | null,
    signatureMethods: Array< SignatureType >,
    signatureResults:  Array< {
      __typename: "SignatureDocumentResult",
      openedDate?: string | null,
      signedDate?: string | null,
      signed?: boolean | null,
      signatureLink?: string | null,
      rejectReason?: string | null,
      lastSignInviteSentDate?: string | null,
      trackingProvider?: TrackingProvider | null,
      trackingCode?: string | null,
      contactId: string,
    } >,
    status: SignatureDocumentStatus,
    externalStatus?: string | null,
    type: SignatureDocumentType,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSignatureDocumentsQueryVariables = {
  filter?: ModelSignatureDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSignatureDocumentsQuery = {
  listSignatureDocuments?:  {
    __typename: "ModelSignatureDocumentConnection",
    items:  Array< {
      __typename: "SignatureDocument",
      id: string,
      additionalMessage?: string | null,
      communicationBody?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      fileIds?: Array< string > | null,
      foreignKey: string,
      foreignTableName: string,
      sentAt?: string | null,
      signatureMethods: Array< SignatureType >,
      signatureResults:  Array< {
        __typename: "SignatureDocumentResult",
        openedDate?: string | null,
        signedDate?: string | null,
        signed?: boolean | null,
        signatureLink?: string | null,
        rejectReason?: string | null,
        lastSignInviteSentDate?: string | null,
        trackingProvider?: TrackingProvider | null,
        trackingCode?: string | null,
        contactId: string,
      } >,
      status: SignatureDocumentStatus,
      externalStatus?: string | null,
      type: SignatureDocumentType,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSignatureDocumentsQueryVariables = {
  filter?: ModelSignatureDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSignatureDocumentsQuery = {
  syncSignatureDocuments?:  {
    __typename: "ModelSignatureDocumentConnection",
    items:  Array< {
      __typename: "SignatureDocument",
      id: string,
      additionalMessage?: string | null,
      communicationBody?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      fileIds?: Array< string > | null,
      foreignKey: string,
      foreignTableName: string,
      sentAt?: string | null,
      signatureMethods: Array< SignatureType >,
      signatureResults:  Array< {
        __typename: "SignatureDocumentResult",
        openedDate?: string | null,
        signedDate?: string | null,
        signed?: boolean | null,
        signatureLink?: string | null,
        rejectReason?: string | null,
        lastSignInviteSentDate?: string | null,
        trackingProvider?: TrackingProvider | null,
        trackingCode?: string | null,
        contactId: string,
      } >,
      status: SignatureDocumentStatus,
      externalStatus?: string | null,
      type: SignatureDocumentType,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetStatementQueryVariables = {
  id: string,
};

export type GetStatementQuery = {
  getStatement?:  {
    __typename: "Statement",
    id: string,
    amounts?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    chargeStatements?:  {
      __typename: "ModelChargeStatementConnection",
      items:  Array< {
        __typename: "ChargeStatement",
        id: string,
        chargeId: string,
        statementId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    closingStatement: boolean,
    periodFrom: string,
    periodTo: string,
    source: StatementSource,
    status: StatementStatus,
    sentDate?: string | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    agencyRateStatements?:  {
      __typename: "ModelAgencyRateStatementConnection",
      items:  Array< {
        __typename: "AgencyRateStatement",
        id: string,
        agencyRateId: string,
        statementId: string,
        totalAmountRateable: number,
        totalFixedAmount: number,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    repaymentStatements?:  {
      __typename: "ModelRepaymentStatementConnection",
      items:  Array< {
        __typename: "RepaymentStatement",
        id: string,
        delaysCleared: number,
        expectedRent: number,
        leaseId: string,
        rentsCollected: number,
        statementId: string,
        totalReceivedInJointAccount: number,
        totalExpectedInOwnerAccount: number,
        unpaidRents: number,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    types?: Array< StatementType > | null,
    unitIds?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListStatementsQueryVariables = {
  filter?: ModelStatementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStatementsQuery = {
  listStatements?:  {
    __typename: "ModelStatementConnection",
    items:  Array< {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncStatementsQueryVariables = {
  filter?: ModelStatementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncStatementsQuery = {
  syncStatements?:  {
    __typename: "ModelStatementConnection",
    items:  Array< {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetStatementSettingQueryVariables = {
  id: string,
};

export type GetStatementSettingQuery = {
  getStatementSetting?:  {
    __typename: "StatementSetting",
    id: string,
    type: StatementSettingType,
    custom: boolean,
    disabled?: boolean | null,
    monthFrequency: number,
    startMonth?: string | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ownerId?: string | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListStatementSettingsQueryVariables = {
  filter?: ModelStatementSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStatementSettingsQuery = {
  listStatementSettings?:  {
    __typename: "ModelStatementSettingConnection",
    items:  Array< {
      __typename: "StatementSetting",
      id: string,
      type: StatementSettingType,
      custom: boolean,
      disabled?: boolean | null,
      monthFrequency: number,
      startMonth?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncStatementSettingsQueryVariables = {
  filter?: ModelStatementSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncStatementSettingsQuery = {
  syncStatementSettings?:  {
    __typename: "ModelStatementSettingConnection",
    items:  Array< {
      __typename: "StatementSetting",
      id: string,
      type: StatementSettingType,
      custom: boolean,
      disabled?: boolean | null,
      monthFrequency: number,
      startMonth?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTeamQueryVariables = {
  id: string,
};

export type GetTeamQuery = {
  getTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    parentId?: string | null,
    children?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        parentId?: string | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users?:  {
      __typename: "ModelUserTeamConnection",
      items:  Array< {
        __typename: "UserTeam",
        id: string,
        userId: string,
        teamId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTeamsQueryVariables = {
  filter?: ModelTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamsQuery = {
  listTeams?:  {
    __typename: "ModelTeamConnection",
    items:  Array< {
      __typename: "Team",
      id: string,
      name: string,
      parentId?: string | null,
      children?:  {
        __typename: "ModelTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTeamsQueryVariables = {
  filter?: ModelTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTeamsQuery = {
  syncTeams?:  {
    __typename: "ModelTeamConnection",
    items:  Array< {
      __typename: "Team",
      id: string,
      name: string,
      parentId?: string | null,
      children?:  {
        __typename: "ModelTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTechnicQueryVariables = {
  id: string,
};

export type GetTechnicQuery = {
  getTechnic?:  {
    __typename: "Technic",
    id: string,
    type: TechnicType,
    contractNumber?: string | null,
    communicationSettingsProfileId: string,
    maintenanceHistory?:  Array< {
      __typename: "TechnicMaintenanceHistory",
      date: string,
      contactId?: string | null,
      fileId?: string | null,
    } > | null,
    utilityType?: UtilityType | null,
    utilityEanCode?: string | null,
    utilityMeterNumber?: string | null,
    utilityDistributorId?: string | null,
    utilityDistributor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    utilityHistory?:  Array< {
      __typename: "UtilityHistory",
      date: string,
      reason?: UtilityReadingReason | null,
      reading: number,
      secondReading?: number | null,
    } > | null,
    PebValidityDate?: string | null,
    PebEnergeticPerformance?: string | null,
    PebReportNumber?: string | null,
    PebIssueDate?: string | null,
    detectorType?: DetectorType | null,
    detectorSerialNumber?: string | null,
    detectorBrand?: string | null,
    detectorLastControlDate?: string | null,
    detectorUnitInventoryId?: string | null,
    heatingBrand?: string | null,
    heatingModel?: string | null,
    heatingInstallationDate?: string | null,
    heatingType?: HeatingType | null,
    heatingLastControlDate?: string | null,
    tankType?: TankType | null,
    tankCapacity?: number | null,
    tankState?: TankState | null,
    tankLastControlDate?: string | null,
    tankLeakDetection?: boolean | null,
    tankEncuvement?: boolean | null,
    tankWallType?: TankWallType | null,
    tankInstallationDate?: string | null,
    chimneyLastControlDate?: string | null,
    chimneyUnitInventoryId?: string | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    originalTechnicId?: string | null,
    readers?: Array< string > | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTechnicsQueryVariables = {
  filter?: ModelTechnicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTechnicsQuery = {
  listTechnics?:  {
    __typename: "ModelTechnicConnection",
    items:  Array< {
      __typename: "Technic",
      id: string,
      type: TechnicType,
      contractNumber?: string | null,
      communicationSettingsProfileId: string,
      maintenanceHistory?:  Array< {
        __typename: "TechnicMaintenanceHistory",
        date: string,
        contactId?: string | null,
        fileId?: string | null,
      } > | null,
      utilityType?: UtilityType | null,
      utilityEanCode?: string | null,
      utilityMeterNumber?: string | null,
      utilityDistributorId?: string | null,
      utilityDistributor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      utilityHistory?:  Array< {
        __typename: "UtilityHistory",
        date: string,
        reason?: UtilityReadingReason | null,
        reading: number,
        secondReading?: number | null,
      } > | null,
      PebValidityDate?: string | null,
      PebEnergeticPerformance?: string | null,
      PebReportNumber?: string | null,
      PebIssueDate?: string | null,
      detectorType?: DetectorType | null,
      detectorSerialNumber?: string | null,
      detectorBrand?: string | null,
      detectorLastControlDate?: string | null,
      detectorUnitInventoryId?: string | null,
      heatingBrand?: string | null,
      heatingModel?: string | null,
      heatingInstallationDate?: string | null,
      heatingType?: HeatingType | null,
      heatingLastControlDate?: string | null,
      tankType?: TankType | null,
      tankCapacity?: number | null,
      tankState?: TankState | null,
      tankLastControlDate?: string | null,
      tankLeakDetection?: boolean | null,
      tankEncuvement?: boolean | null,
      tankWallType?: TankWallType | null,
      tankInstallationDate?: string | null,
      chimneyLastControlDate?: string | null,
      chimneyUnitInventoryId?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildingId?: string | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      originalTechnicId?: string | null,
      readers?: Array< string > | null,
      writers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTechnicsQueryVariables = {
  filter?: ModelTechnicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTechnicsQuery = {
  syncTechnics?:  {
    __typename: "ModelTechnicConnection",
    items:  Array< {
      __typename: "Technic",
      id: string,
      type: TechnicType,
      contractNumber?: string | null,
      communicationSettingsProfileId: string,
      maintenanceHistory?:  Array< {
        __typename: "TechnicMaintenanceHistory",
        date: string,
        contactId?: string | null,
        fileId?: string | null,
      } > | null,
      utilityType?: UtilityType | null,
      utilityEanCode?: string | null,
      utilityMeterNumber?: string | null,
      utilityDistributorId?: string | null,
      utilityDistributor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      utilityHistory?:  Array< {
        __typename: "UtilityHistory",
        date: string,
        reason?: UtilityReadingReason | null,
        reading: number,
        secondReading?: number | null,
      } > | null,
      PebValidityDate?: string | null,
      PebEnergeticPerformance?: string | null,
      PebReportNumber?: string | null,
      PebIssueDate?: string | null,
      detectorType?: DetectorType | null,
      detectorSerialNumber?: string | null,
      detectorBrand?: string | null,
      detectorLastControlDate?: string | null,
      detectorUnitInventoryId?: string | null,
      heatingBrand?: string | null,
      heatingModel?: string | null,
      heatingInstallationDate?: string | null,
      heatingType?: HeatingType | null,
      heatingLastControlDate?: string | null,
      tankType?: TankType | null,
      tankCapacity?: number | null,
      tankState?: TankState | null,
      tankLastControlDate?: string | null,
      tankLeakDetection?: boolean | null,
      tankEncuvement?: boolean | null,
      tankWallType?: TankWallType | null,
      tankInstallationDate?: string | null,
      chimneyLastControlDate?: string | null,
      chimneyUnitInventoryId?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildingId?: string | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      originalTechnicId?: string | null,
      readers?: Array< string > | null,
      writers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTemplateQueryVariables = {
  id: string,
};

export type GetTemplateQuery = {
  getTemplate?:  {
    __typename: "Template",
    id: string,
    language: string,
    subject?: string | null,
    subjectRawFormat?: string | null,
    body: string,
    bodyRawFormat?: string | null,
    type: TemplateType,
    subType: TemplateSubType,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTemplatesQueryVariables = {
  filter?: ModelTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemplatesQuery = {
  listTemplates?:  {
    __typename: "ModelTemplateConnection",
    items:  Array< {
      __typename: "Template",
      id: string,
      language: string,
      subject?: string | null,
      subjectRawFormat?: string | null,
      body: string,
      bodyRawFormat?: string | null,
      type: TemplateType,
      subType: TemplateSubType,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTemplatesQueryVariables = {
  filter?: ModelTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTemplatesQuery = {
  syncTemplates?:  {
    __typename: "ModelTemplateConnection",
    items:  Array< {
      __typename: "Template",
      id: string,
      language: string,
      subject?: string | null,
      subjectRawFormat?: string | null,
      body: string,
      bodyRawFormat?: string | null,
      type: TemplateType,
      subType: TemplateSubType,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTicketQueryVariables = {
  id: string,
};

export type GetTicketQuery = {
  getTicket?:  {
    __typename: "Ticket",
    id: string,
    assignedAt?: string | null,
    assignedTo?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    assignedToId?: string | null,
    attachment?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    closedAt?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId: string,
    content: string,
    conversations?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        assignedAt?: string | null,
        assignedToId?: string | null,
        buildingId?: string | null,
        closedAt?: string | null,
        contactId: string,
        content: string,
        createdAt: string,
        emailMessageId?: string | null,
        emailReferences?: Array< string | null > | null,
        parentId?: string | null,
        plannedAt?: string | null,
        referenceCode?: string | null,
        sourceType: TicketSourceType,
        status?: TicketStatus | null,
        subject: string,
        unitId?: string | null,
        unread: boolean,
        updatedAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    emailMessageId?: string | null,
    emailReferences?: Array< string | null > | null,
    parentId?: string | null,
    plannedAt?: string | null,
    referenceCode?: string | null,
    sourceType: TicketSourceType,
    status?: TicketStatus | null,
    subject: string,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unread: boolean,
    updatedAt?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTicketsQueryVariables = {
  id?: string | null,
  filter?: ModelTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTicketsQuery = {
  listTickets?:  {
    __typename: "ModelTicketConnection",
    items:  Array< {
      __typename: "Ticket",
      id: string,
      assignedAt?: string | null,
      assignedTo?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      assignedToId?: string | null,
      attachment?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      buildingId?: string | null,
      closedAt?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId: string,
      content: string,
      conversations?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      emailMessageId?: string | null,
      emailReferences?: Array< string | null > | null,
      parentId?: string | null,
      plannedAt?: string | null,
      referenceCode?: string | null,
      sourceType: TicketSourceType,
      status?: TicketStatus | null,
      subject: string,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unread: boolean,
      updatedAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTicketsQueryVariables = {
  filter?: ModelTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTicketsQuery = {
  syncTickets?:  {
    __typename: "ModelTicketConnection",
    items:  Array< {
      __typename: "Ticket",
      id: string,
      assignedAt?: string | null,
      assignedTo?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      assignedToId?: string | null,
      attachment?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      buildingId?: string | null,
      closedAt?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId: string,
      content: string,
      conversations?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      emailMessageId?: string | null,
      emailReferences?: Array< string | null > | null,
      parentId?: string | null,
      plannedAt?: string | null,
      referenceCode?: string | null,
      sourceType: TicketSourceType,
      status?: TicketStatus | null,
      subject: string,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unread: boolean,
      updatedAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTransactionQueryVariables = {
  id: string,
};

export type GetTransactionQuery = {
  getTransaction?:  {
    __typename: "Transaction",
    id: string,
    status: TransactionStatus,
    sequence?: number | null,
    statementDate: string,
    operationDate?: string | null,
    createdAt?: string | null,
    remittanceInformationType?: RemittanceType | null,
    remittanceInformation?: string | null,
    comment?: string | null,
    amount: number,
    bankAccountId: string,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    counterpartName?: string | null,
    counterpartAccountNumber?: string | null,
    manualEntry?: boolean | null,
    proposedLeaseId?: string | null,
    proposedUnitId?: string | null,
    proposedContractorId?: string | null,
    proposedMatch?: number | null,
    links?:  Array< {
      __typename: "TransactionLink",
      amount: number,
      linkId: string,
      linkType: TransactionLinkType,
    } > | null,
    postings?:  {
      __typename: "ModelPostingConnection",
      items:  Array< {
        __typename: "Posting",
        id: string,
        createdAt?: string | null,
        class: number,
        customLabel?: string | null,
        topClass: number,
        type: PostingType,
        totalAmount: number,
        periodFrom: string,
        periodTo: string,
        buildingId?: string | null,
        unitId?: string | null,
        invoiceId?: string | null,
        chargeId?: string | null,
        transactionId?: string | null,
        leaseId?: string | null,
        bankAccountId?: string | null,
        accountLabelId?: string | null,
        contactId?: string | null,
        leaseVariousOperationId?: string | null,
        statementId?: string | null,
        amountVatExcluded?: number | null,
        vatAmount?: number | null,
        vatRate?: number | null,
        foreignId?: string | null,
        foreignType?: PostingForeignType | null,
        invoicePostingId?: string | null,
        reconciledAt?: string | null,
        status?: PostingStatus | null,
        chargeOwnerRepartition?: number | null,
        chargeRepartitionMetric?: string | null,
        chargeRepartitionValue?: number | null,
        chargeSplitNumber?: number | null,
        chargeType?: PostingChargeType | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTransactionsQueryVariables = {
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTransactionsQuery = {
  listTransactions?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      status: TransactionStatus,
      sequence?: number | null,
      statementDate: string,
      operationDate?: string | null,
      createdAt?: string | null,
      remittanceInformationType?: RemittanceType | null,
      remittanceInformation?: string | null,
      comment?: string | null,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      counterpartName?: string | null,
      counterpartAccountNumber?: string | null,
      manualEntry?: boolean | null,
      proposedLeaseId?: string | null,
      proposedUnitId?: string | null,
      proposedContractorId?: string | null,
      proposedMatch?: number | null,
      links?:  Array< {
        __typename: "TransactionLink",
        amount: number,
        linkId: string,
        linkType: TransactionLinkType,
      } > | null,
      postings?:  {
        __typename: "ModelPostingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTransactionsQueryVariables = {
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTransactionsQuery = {
  syncTransactions?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      status: TransactionStatus,
      sequence?: number | null,
      statementDate: string,
      operationDate?: string | null,
      createdAt?: string | null,
      remittanceInformationType?: RemittanceType | null,
      remittanceInformation?: string | null,
      comment?: string | null,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      counterpartName?: string | null,
      counterpartAccountNumber?: string | null,
      manualEntry?: boolean | null,
      proposedLeaseId?: string | null,
      proposedUnitId?: string | null,
      proposedContractorId?: string | null,
      proposedMatch?: number | null,
      links?:  Array< {
        __typename: "TransactionLink",
        amount: number,
        linkId: string,
        linkType: TransactionLinkType,
      } > | null,
      postings?:  {
        __typename: "ModelPostingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTransactionRuleQueryVariables = {
  id: string,
};

export type GetTransactionRuleQuery = {
  getTransactionRule?:  {
    __typename: "TransactionRule",
    id: string,
    remittanceInformation?: string | null,
    counterpartName?: string | null,
    counterpartAccountNumber?: string | null,
    usedFields: string,
    exclude: boolean,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contractorId?: string | null,
    contractor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTransactionRulesQueryVariables = {
  filter?: ModelTransactionRuleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTransactionRulesQuery = {
  listTransactionRules?:  {
    __typename: "ModelTransactionRuleConnection",
    items:  Array< {
      __typename: "TransactionRule",
      id: string,
      remittanceInformation?: string | null,
      counterpartName?: string | null,
      counterpartAccountNumber?: string | null,
      usedFields: string,
      exclude: boolean,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contractorId?: string | null,
      contractor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTransactionRulesQueryVariables = {
  filter?: ModelTransactionRuleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTransactionRulesQuery = {
  syncTransactionRules?:  {
    __typename: "ModelTransactionRuleConnection",
    items:  Array< {
      __typename: "TransactionRule",
      id: string,
      remittanceInformation?: string | null,
      counterpartName?: string | null,
      counterpartAccountNumber?: string | null,
      usedFields: string,
      exclude: boolean,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contractorId?: string | null,
      contractor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUnitQueryVariables = {
  id: string,
};

export type GetUnitQuery = {
  getUnit?:  {
    __typename: "Unit",
    id: string,
    acquisitionAdditionalCostsValue?: number | null,
    acquisitionAdvertisedValue?: number | null,
    acquisitionValue?: number | null,
    advertisedMonthlyCharges?: number | null,
    advertisedRentalPrice?: number | null,
    cadastralIncome?: number | null,
    createdAt?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    floor?: number | null,
    jointOwnershipId?: string | null,
    managementDate: string,
    name: string,
    predictedPrice?: string | null,
    propertyTax?: number | null,
    publishAddress?: boolean | null,
    surface?: number | null,
    type: UnitType,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owners?:  {
      __typename: "ModelUnitOwnerConnection",
      items:  Array< {
        __typename: "UnitOwner",
        id: string,
        disabled?: boolean | null,
        ownerId: string,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leases?:  {
      __typename: "ModelUnitLeaseConnection",
      items:  Array< {
        __typename: "UnitLease",
        id: string,
        allowChargeAdjustment?: boolean | null,
        authorizeProfessionalActivity?: boolean | null,
        chargesIndexation?: boolean | null,
        createdAt?: string | null,
        endDate: string,
        furnishedRental?: boolean | null,
        furnishedRentalInsuranceByTenant?: boolean | null,
        furnishedRentalRentalPricePercentage?: number | null,
        initialMonthlyCharges: number,
        initialRentalPrice: number,
        indexation?: boolean | null,
        advancedIndexation?: boolean | null,
        indexType?: IndexType | null,
        baseYear?: number | null,
        baseIndexType?: BaseIndexType | null,
        baseIndexDate?: string | null,
        leaseId: string,
        mainUnit: boolean,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        monthlyCharges: number,
        rentalPrice: number,
        signatureDate?: string | null,
        startDate: string,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelUnitEventConnection",
      items:  Array< {
        __typename: "UnitEvent",
        id: string,
        unitId: string,
        type: UnitEventType,
        startDate: string,
        endDate: string,
        totalCost?: number | null,
        note?: string | null,
        writers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    unitAdvertisementId?: string | null,
    unitInventories?:  {
      __typename: "ModelUnitInventoryConnection",
      items:  Array< {
        __typename: "UnitInventory",
        id: string,
        privateBatthRoom?: boolean | null,
        createdAt?: string | null,
        deletedAt?: string | null,
        kitchenEquipment?: KitchenEquipment | null,
        roomName?: string | null,
        roomType: UnitInventoryRoomType,
        surface?: number | null,
        showSurface?: boolean | null,
        unitId: string,
        inside: boolean,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUnitsQueryVariables = {
  filter?: ModelUnitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUnitsQuery = {
  listUnits?:  {
    __typename: "ModelUnitConnection",
    items:  Array< {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUnitsQueryVariables = {
  filter?: ModelUnitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUnitsQuery = {
  syncUnits?:  {
    __typename: "ModelUnitConnection",
    items:  Array< {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUnitEventQueryVariables = {
  id: string,
};

export type GetUnitEventQuery = {
  getUnitEvent?:  {
    __typename: "UnitEvent",
    id: string,
    unitId: string,
    type: UnitEventType,
    startDate: string,
    endDate: string,
    totalCost?: number | null,
    note?: string | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUnitEventsQueryVariables = {
  filter?: ModelUnitEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUnitEventsQuery = {
  listUnitEvents?:  {
    __typename: "ModelUnitEventConnection",
    items:  Array< {
      __typename: "UnitEvent",
      id: string,
      unitId: string,
      type: UnitEventType,
      startDate: string,
      endDate: string,
      totalCost?: number | null,
      note?: string | null,
      writers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUnitEventsQueryVariables = {
  filter?: ModelUnitEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUnitEventsQuery = {
  syncUnitEvents?:  {
    __typename: "ModelUnitEventConnection",
    items:  Array< {
      __typename: "UnitEvent",
      id: string,
      unitId: string,
      type: UnitEventType,
      startDate: string,
      endDate: string,
      totalCost?: number | null,
      note?: string | null,
      writers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUnitInventoryQueryVariables = {
  id: string,
};

export type GetUnitInventoryQuery = {
  getUnitInventory?:  {
    __typename: "UnitInventory",
    id: string,
    privateBatthRoom?: boolean | null,
    createdAt?: string | null,
    deletedAt?: string | null,
    kitchenEquipment?: KitchenEquipment | null,
    roomName?: string | null,
    roomType: UnitInventoryRoomType,
    surface?: number | null,
    showSurface?: boolean | null,
    unitId: string,
    inside: boolean,
    structures?:  {
      __typename: "ModelUnitInventoryStructureConnection",
      items:  Array< {
        __typename: "UnitInventoryStructure",
        id: string,
        createdAt?: string | null,
        deletedAt?: string | null,
        unitInventoryId: string,
        type: UnitInventoryStructureType,
        name: string,
        brand?: string | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUnitInventoriesQueryVariables = {
  filter?: ModelUnitInventoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUnitInventoriesQuery = {
  listUnitInventories?:  {
    __typename: "ModelUnitInventoryConnection",
    items:  Array< {
      __typename: "UnitInventory",
      id: string,
      privateBatthRoom?: boolean | null,
      createdAt?: string | null,
      deletedAt?: string | null,
      kitchenEquipment?: KitchenEquipment | null,
      roomName?: string | null,
      roomType: UnitInventoryRoomType,
      surface?: number | null,
      showSurface?: boolean | null,
      unitId: string,
      inside: boolean,
      structures?:  {
        __typename: "ModelUnitInventoryStructureConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUnitInventoriesQueryVariables = {
  filter?: ModelUnitInventoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUnitInventoriesQuery = {
  syncUnitInventories?:  {
    __typename: "ModelUnitInventoryConnection",
    items:  Array< {
      __typename: "UnitInventory",
      id: string,
      privateBatthRoom?: boolean | null,
      createdAt?: string | null,
      deletedAt?: string | null,
      kitchenEquipment?: KitchenEquipment | null,
      roomName?: string | null,
      roomType: UnitInventoryRoomType,
      surface?: number | null,
      showSurface?: boolean | null,
      unitId: string,
      inside: boolean,
      structures?:  {
        __typename: "ModelUnitInventoryStructureConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUnitInventoryStructureQueryVariables = {
  id: string,
};

export type GetUnitInventoryStructureQuery = {
  getUnitInventoryStructure?:  {
    __typename: "UnitInventoryStructure",
    id: string,
    createdAt?: string | null,
    deletedAt?: string | null,
    unitInventoryId: string,
    encodings?:  {
      __typename: "ModelLeaseInventoryEncodingConnection",
      items:  Array< {
        __typename: "LeaseInventoryEncoding",
        id: string,
        leaseInventoryId: string,
        structureId: string,
        state?: LeaseInventoryStructureStateType | null,
        note?: string | null,
        furnitureQuantity?: number | null,
        furniturePrice?: number | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    type: UnitInventoryStructureType,
    name: string,
    brand?: string | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUnitInventoryStructuresQueryVariables = {
  filter?: ModelUnitInventoryStructureFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUnitInventoryStructuresQuery = {
  listUnitInventoryStructures?:  {
    __typename: "ModelUnitInventoryStructureConnection",
    items:  Array< {
      __typename: "UnitInventoryStructure",
      id: string,
      createdAt?: string | null,
      deletedAt?: string | null,
      unitInventoryId: string,
      encodings?:  {
        __typename: "ModelLeaseInventoryEncodingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      type: UnitInventoryStructureType,
      name: string,
      brand?: string | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUnitInventoryStructuresQueryVariables = {
  filter?: ModelUnitInventoryStructureFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUnitInventoryStructuresQuery = {
  syncUnitInventoryStructures?:  {
    __typename: "ModelUnitInventoryStructureConnection",
    items:  Array< {
      __typename: "UnitInventoryStructure",
      id: string,
      createdAt?: string | null,
      deletedAt?: string | null,
      unitInventoryId: string,
      encodings?:  {
        __typename: "ModelLeaseInventoryEncodingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      type: UnitInventoryStructureType,
      name: string,
      brand?: string | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUnitLeaseQueryVariables = {
  id: string,
};

export type GetUnitLeaseQuery = {
  getUnitLease?:  {
    __typename: "UnitLease",
    id: string,
    allowChargeAdjustment?: boolean | null,
    authorizeProfessionalActivity?: boolean | null,
    chargesIndexation?: boolean | null,
    createdAt?: string | null,
    endDate: string,
    furnishedRental?: boolean | null,
    furnishedRentalInsuranceByTenant?: boolean | null,
    furnishedRentalRentalPricePercentage?: number | null,
    initialMonthlyCharges: number,
    initialRentalPrice: number,
    indexation?: boolean | null,
    advancedIndexation?: boolean | null,
    indexType?: IndexType | null,
    baseYear?: number | null,
    baseIndexType?: BaseIndexType | null,
    baseIndexDate?: string | null,
    leaseId: string,
    mainUnit: boolean,
    monthlyChargesType?: LeaseMonthlyChargesType | null,
    monthlyCharges: number,
    rentalPrice: number,
    signatureDate?: string | null,
    startDate: string,
    unitId: string,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUnitLeasesQueryVariables = {
  filter?: ModelUnitLeaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUnitLeasesQuery = {
  listUnitLeases?:  {
    __typename: "ModelUnitLeaseConnection",
    items:  Array< {
      __typename: "UnitLease",
      id: string,
      allowChargeAdjustment?: boolean | null,
      authorizeProfessionalActivity?: boolean | null,
      chargesIndexation?: boolean | null,
      createdAt?: string | null,
      endDate: string,
      furnishedRental?: boolean | null,
      furnishedRentalInsuranceByTenant?: boolean | null,
      furnishedRentalRentalPricePercentage?: number | null,
      initialMonthlyCharges: number,
      initialRentalPrice: number,
      indexation?: boolean | null,
      advancedIndexation?: boolean | null,
      indexType?: IndexType | null,
      baseYear?: number | null,
      baseIndexType?: BaseIndexType | null,
      baseIndexDate?: string | null,
      leaseId: string,
      mainUnit: boolean,
      monthlyChargesType?: LeaseMonthlyChargesType | null,
      monthlyCharges: number,
      rentalPrice: number,
      signatureDate?: string | null,
      startDate: string,
      unitId: string,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUnitLeasesQueryVariables = {
  filter?: ModelUnitLeaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUnitLeasesQuery = {
  syncUnitLeases?:  {
    __typename: "ModelUnitLeaseConnection",
    items:  Array< {
      __typename: "UnitLease",
      id: string,
      allowChargeAdjustment?: boolean | null,
      authorizeProfessionalActivity?: boolean | null,
      chargesIndexation?: boolean | null,
      createdAt?: string | null,
      endDate: string,
      furnishedRental?: boolean | null,
      furnishedRentalInsuranceByTenant?: boolean | null,
      furnishedRentalRentalPricePercentage?: number | null,
      initialMonthlyCharges: number,
      initialRentalPrice: number,
      indexation?: boolean | null,
      advancedIndexation?: boolean | null,
      indexType?: IndexType | null,
      baseYear?: number | null,
      baseIndexType?: BaseIndexType | null,
      baseIndexDate?: string | null,
      leaseId: string,
      mainUnit: boolean,
      monthlyChargesType?: LeaseMonthlyChargesType | null,
      monthlyCharges: number,
      rentalPrice: number,
      signatureDate?: string | null,
      startDate: string,
      unitId: string,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUnitOwnerQueryVariables = {
  id: string,
};

export type GetUnitOwnerQuery = {
  getUnitOwner?:  {
    __typename: "UnitOwner",
    id: string,
    disabled?: boolean | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ownerId: string,
    periodFrom?: string | null,
    periodTo?: string | null,
    stake: number,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId: string,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUnitOwnersQueryVariables = {
  filter?: ModelUnitOwnerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUnitOwnersQuery = {
  listUnitOwners?:  {
    __typename: "ModelUnitOwnerConnection",
    items:  Array< {
      __typename: "UnitOwner",
      id: string,
      disabled?: boolean | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ownerId: string,
      periodFrom?: string | null,
      periodTo?: string | null,
      stake: number,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId: string,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUnitOwnersQueryVariables = {
  filter?: ModelUnitOwnerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUnitOwnersQuery = {
  syncUnitOwners?:  {
    __typename: "ModelUnitOwnerConnection",
    items:  Array< {
      __typename: "UnitOwner",
      id: string,
      disabled?: boolean | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      ownerId: string,
      periodFrom?: string | null,
      periodTo?: string | null,
      stake: number,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId: string,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    cognitoSub?: string | null,
    googleSub?: string | null,
    facebookSub?: string | null,
    rootUser?: boolean | null,
    refreshToken?: boolean | null,
    teams?:  {
      __typename: "ModelUserTeamConnection",
      items:  Array< {
        __typename: "UserTeam",
        id: string,
        userId: string,
        teamId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userRoleId?: string | null,
    userRole?:  {
      __typename: "UserRole",
      id: string,
      name: string,
      userRights?:  Array< {
        __typename: "UserRight",
        access?: Array< UserRightAccessType > | null,
        section: UserRightSection,
      } > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status: UserStatus,
    readId: string,
    clientId: string,
    _ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoSub?: string | null,
      googleSub?: string | null,
      facebookSub?: string | null,
      rootUser?: boolean | null,
      refreshToken?: boolean | null,
      teams?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userRoleId?: string | null,
      userRole?:  {
        __typename: "UserRole",
        id: string,
        name: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status: UserStatus,
      readId: string,
      clientId: string,
      _ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoSub?: string | null,
      googleSub?: string | null,
      facebookSub?: string | null,
      rootUser?: boolean | null,
      refreshToken?: boolean | null,
      teams?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userRoleId?: string | null,
      userRole?:  {
        __typename: "UserRole",
        id: string,
        name: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status: UserStatus,
      readId: string,
      clientId: string,
      _ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserRoleQueryVariables = {
  id: string,
};

export type GetUserRoleQuery = {
  getUserRole?:  {
    __typename: "UserRole",
    id: string,
    name: string,
    userRights?:  Array< {
      __typename: "UserRight",
      access?: Array< UserRightAccessType > | null,
      section: UserRightSection,
    } > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserRolesQueryVariables = {
  filter?: ModelUserRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserRolesQuery = {
  listUserRoles?:  {
    __typename: "ModelUserRoleConnection",
    items:  Array< {
      __typename: "UserRole",
      id: string,
      name: string,
      userRights?:  Array< {
        __typename: "UserRight",
        access?: Array< UserRightAccessType > | null,
        section: UserRightSection,
      } > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserRolesQueryVariables = {
  filter?: ModelUserRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserRolesQuery = {
  syncUserRoles?:  {
    __typename: "ModelUserRoleConnection",
    items:  Array< {
      __typename: "UserRole",
      id: string,
      name: string,
      userRights?:  Array< {
        __typename: "UserRight",
        access?: Array< UserRightAccessType > | null,
        section: UserRightSection,
      } > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserTeamQueryVariables = {
  id: string,
};

export type GetUserTeamQuery = {
  getUserTeam?:  {
    __typename: "UserTeam",
    id: string,
    userId: string,
    teamId: string,
    team?:  {
      __typename: "Team",
      id: string,
      name: string,
      parentId?: string | null,
      children?:  {
        __typename: "ModelTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoSub?: string | null,
      googleSub?: string | null,
      facebookSub?: string | null,
      rootUser?: boolean | null,
      refreshToken?: boolean | null,
      teams?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userRoleId?: string | null,
      userRole?:  {
        __typename: "UserRole",
        id: string,
        name: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status: UserStatus,
      readId: string,
      clientId: string,
      _ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserTeamsQueryVariables = {
  filter?: ModelUserTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserTeamsQuery = {
  listUserTeams?:  {
    __typename: "ModelUserTeamConnection",
    items:  Array< {
      __typename: "UserTeam",
      id: string,
      userId: string,
      teamId: string,
      team?:  {
        __typename: "Team",
        id: string,
        name: string,
        parentId?: string | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        cognitoSub?: string | null,
        googleSub?: string | null,
        facebookSub?: string | null,
        rootUser?: boolean | null,
        refreshToken?: boolean | null,
        userRoleId?: string | null,
        contactId?: string | null,
        status: UserStatus,
        readId: string,
        clientId: string,
        _ttl?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserTeamsQueryVariables = {
  filter?: ModelUserTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserTeamsQuery = {
  syncUserTeams?:  {
    __typename: "ModelUserTeamConnection",
    items:  Array< {
      __typename: "UserTeam",
      id: string,
      userId: string,
      teamId: string,
      team?:  {
        __typename: "Team",
        id: string,
        name: string,
        parentId?: string | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        email: string,
        firstName?: string | null,
        lastName?: string | null,
        cognitoSub?: string | null,
        googleSub?: string | null,
        facebookSub?: string | null,
        rootUser?: boolean | null,
        refreshToken?: boolean | null,
        userRoleId?: string | null,
        contactId?: string | null,
        status: UserStatus,
        readId: string,
        clientId: string,
        _ttl?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetValuationQueryVariables = {
  id: string,
};

export type GetValuationQuery = {
  getValuation?:  {
    __typename: "Valuation",
    id: string,
    sourceType?: ValuationSourceType | null,
    valorization: number,
    date: string,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListValuationsQueryVariables = {
  filter?: ModelValuationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListValuationsQuery = {
  listValuations?:  {
    __typename: "ModelValuationConnection",
    items:  Array< {
      __typename: "Valuation",
      id: string,
      sourceType?: ValuationSourceType | null,
      valorization: number,
      date: string,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      buildingId?: string | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      writers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncValuationsQueryVariables = {
  filter?: ModelValuationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncValuationsQuery = {
  syncValuations?:  {
    __typename: "ModelValuationConnection",
    items:  Array< {
      __typename: "Valuation",
      id: string,
      sourceType?: ValuationSourceType | null,
      valorization: number,
      date: string,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      buildingId?: string | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      writers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetVariousOperationQueryVariables = {
  id: string,
};

export type GetVariousOperationQuery = {
  getVariousOperation?:  {
    __typename: "VariousOperation",
    id: string,
    amount: number,
    labels?:  Array< {
      __typename: "CustomLabel",
      language: string,
      label: string,
    } > | null,
    numberOfMonths?: number | null,
    operationType: LeaseVariousOperationType,
    paymentFrequency: LeasePaymentFrequency,
    type: VariousOperationType,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListVariousOperationsQueryVariables = {
  filter?: ModelVariousOperationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVariousOperationsQuery = {
  listVariousOperations?:  {
    __typename: "ModelVariousOperationConnection",
    items:  Array< {
      __typename: "VariousOperation",
      id: string,
      amount: number,
      labels?:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } > | null,
      numberOfMonths?: number | null,
      operationType: LeaseVariousOperationType,
      paymentFrequency: LeasePaymentFrequency,
      type: VariousOperationType,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncVariousOperationsQueryVariables = {
  filter?: ModelVariousOperationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncVariousOperationsQuery = {
  syncVariousOperations?:  {
    __typename: "ModelVariousOperationConnection",
    items:  Array< {
      __typename: "VariousOperation",
      id: string,
      amount: number,
      labels?:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } > | null,
      numberOfMonths?: number | null,
      operationType: LeaseVariousOperationType,
      paymentFrequency: LeasePaymentFrequency,
      type: VariousOperationType,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetVatRateQueryVariables = {
  id: string,
};

export type GetVatRateQuery = {
  getVatRate?:  {
    __typename: "VatRate",
    id: string,
    country: string,
    label: string,
    value?: number | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListVatRatesQueryVariables = {
  filter?: ModelVatRateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVatRatesQuery = {
  listVatRates?:  {
    __typename: "ModelVatRateConnection",
    items:  Array< {
      __typename: "VatRate",
      id: string,
      country: string,
      label: string,
      value?: number | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncVatRatesQueryVariables = {
  filter?: ModelVatRateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncVatRatesQuery = {
  syncVatRates?:  {
    __typename: "ModelVatRateConnection",
    items:  Array< {
      __typename: "VatRate",
      id: string,
      country: string,
      label: string,
      value?: number | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateAddressSubscriptionVariables = {
  filter?: ModelSubscriptionAddressFilterInput | null,
};

export type OnCreateAddressSubscription = {
  onCreateAddress?:  {
    __typename: "Address",
    id: string,
    status?: AddressStatus | null,
    street: string,
    number?: string | null,
    box?: string | null,
    postalCode?: string | null,
    city?: string | null,
    region?: string | null,
    country?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    latitude?: number | null,
    longitude?: number | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAddressSubscriptionVariables = {
  filter?: ModelSubscriptionAddressFilterInput | null,
};

export type OnUpdateAddressSubscription = {
  onUpdateAddress?:  {
    __typename: "Address",
    id: string,
    status?: AddressStatus | null,
    street: string,
    number?: string | null,
    box?: string | null,
    postalCode?: string | null,
    city?: string | null,
    region?: string | null,
    country?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    latitude?: number | null,
    longitude?: number | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAddressSubscriptionVariables = {
  filter?: ModelSubscriptionAddressFilterInput | null,
};

export type OnDeleteAddressSubscription = {
  onDeleteAddress?:  {
    __typename: "Address",
    id: string,
    status?: AddressStatus | null,
    street: string,
    number?: string | null,
    box?: string | null,
    postalCode?: string | null,
    city?: string | null,
    region?: string | null,
    country?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    latitude?: number | null,
    longitude?: number | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBankAccountSubscriptionVariables = {
  filter?: ModelSubscriptionBankAccountFilterInput | null,
};

export type OnCreateBankAccountSubscription = {
  onCreateBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    number: string,
    bicNumber?: string | null,
    bankName?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    disabled?: boolean | null,
    integrations?: Array< IntegrationName > | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBankAccountSubscriptionVariables = {
  filter?: ModelSubscriptionBankAccountFilterInput | null,
};

export type OnUpdateBankAccountSubscription = {
  onUpdateBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    number: string,
    bicNumber?: string | null,
    bankName?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    disabled?: boolean | null,
    integrations?: Array< IntegrationName > | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBankAccountSubscriptionVariables = {
  filter?: ModelSubscriptionBankAccountFilterInput | null,
};

export type OnDeleteBankAccountSubscription = {
  onDeleteBankAccount?:  {
    __typename: "BankAccount",
    id: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    number: string,
    bicNumber?: string | null,
    bankName?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    disabled?: boolean | null,
    integrations?: Array< IntegrationName > | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBuildingSubscriptionVariables = {
  filter?: ModelSubscriptionBuildingFilterInput | null,
};

export type OnCreateBuildingSubscription = {
  onCreateBuilding?:  {
    __typename: "Building",
    id: string,
    name: string,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    acquisitionValue?: number | null,
    acquisitionAdditionalCostsValue?: number | null,
    acquisitionAdvertisedValue?: number | null,
    amountOfFacades?: number | null,
    amountOfFloors?: number | null,
    amountOfHousings?: number | null,
    buildingState?: BuildingState | null,
    cadastralIncome?: number | null,
    constructionYear?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    janitor?: boolean | null,
    jointOwnershipId?: string | null,
    owners?:  {
      __typename: "ModelBuildingOwnerConnection",
      items:  Array< {
        __typename: "BuildingOwner",
        id: string,
        buildingId: string,
        contactId: string,
        disabled?: boolean | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    propertyTax?: number | null,
    residenceName?: string | null,
    units?:  {
      __typename: "ModelUnitConnection",
      items:  Array< {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelBuildingEventConnection",
      items:  Array< {
        __typename: "BuildingEvent",
        id: string,
        buildingId: string,
        type: string,
        startDate: string,
        endDate: string,
        totalCost?: number | null,
        note?: string | null,
        writers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    teams?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBuildingSubscriptionVariables = {
  filter?: ModelSubscriptionBuildingFilterInput | null,
};

export type OnUpdateBuildingSubscription = {
  onUpdateBuilding?:  {
    __typename: "Building",
    id: string,
    name: string,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    acquisitionValue?: number | null,
    acquisitionAdditionalCostsValue?: number | null,
    acquisitionAdvertisedValue?: number | null,
    amountOfFacades?: number | null,
    amountOfFloors?: number | null,
    amountOfHousings?: number | null,
    buildingState?: BuildingState | null,
    cadastralIncome?: number | null,
    constructionYear?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    janitor?: boolean | null,
    jointOwnershipId?: string | null,
    owners?:  {
      __typename: "ModelBuildingOwnerConnection",
      items:  Array< {
        __typename: "BuildingOwner",
        id: string,
        buildingId: string,
        contactId: string,
        disabled?: boolean | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    propertyTax?: number | null,
    residenceName?: string | null,
    units?:  {
      __typename: "ModelUnitConnection",
      items:  Array< {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelBuildingEventConnection",
      items:  Array< {
        __typename: "BuildingEvent",
        id: string,
        buildingId: string,
        type: string,
        startDate: string,
        endDate: string,
        totalCost?: number | null,
        note?: string | null,
        writers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    teams?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBuildingSubscriptionVariables = {
  filter?: ModelSubscriptionBuildingFilterInput | null,
};

export type OnDeleteBuildingSubscription = {
  onDeleteBuilding?:  {
    __typename: "Building",
    id: string,
    name: string,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    acquisitionValue?: number | null,
    acquisitionAdditionalCostsValue?: number | null,
    acquisitionAdvertisedValue?: number | null,
    amountOfFacades?: number | null,
    amountOfFloors?: number | null,
    amountOfHousings?: number | null,
    buildingState?: BuildingState | null,
    cadastralIncome?: number | null,
    constructionYear?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    janitor?: boolean | null,
    jointOwnershipId?: string | null,
    owners?:  {
      __typename: "ModelBuildingOwnerConnection",
      items:  Array< {
        __typename: "BuildingOwner",
        id: string,
        buildingId: string,
        contactId: string,
        disabled?: boolean | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    propertyTax?: number | null,
    residenceName?: string | null,
    units?:  {
      __typename: "ModelUnitConnection",
      items:  Array< {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelBuildingEventConnection",
      items:  Array< {
        __typename: "BuildingEvent",
        id: string,
        buildingId: string,
        type: string,
        startDate: string,
        endDate: string,
        totalCost?: number | null,
        note?: string | null,
        writers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    teams?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBuildingEventSubscriptionVariables = {
  filter?: ModelSubscriptionBuildingEventFilterInput | null,
};

export type OnCreateBuildingEventSubscription = {
  onCreateBuildingEvent?:  {
    __typename: "BuildingEvent",
    id: string,
    buildingId: string,
    type: string,
    startDate: string,
    endDate: string,
    totalCost?: number | null,
    note?: string | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBuildingEventSubscriptionVariables = {
  filter?: ModelSubscriptionBuildingEventFilterInput | null,
};

export type OnUpdateBuildingEventSubscription = {
  onUpdateBuildingEvent?:  {
    __typename: "BuildingEvent",
    id: string,
    buildingId: string,
    type: string,
    startDate: string,
    endDate: string,
    totalCost?: number | null,
    note?: string | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBuildingEventSubscriptionVariables = {
  filter?: ModelSubscriptionBuildingEventFilterInput | null,
};

export type OnDeleteBuildingEventSubscription = {
  onDeleteBuildingEvent?:  {
    __typename: "BuildingEvent",
    id: string,
    buildingId: string,
    type: string,
    startDate: string,
    endDate: string,
    totalCost?: number | null,
    note?: string | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateBuildingOwnerSubscriptionVariables = {
  filter?: ModelSubscriptionBuildingOwnerFilterInput | null,
};

export type OnCreateBuildingOwnerSubscription = {
  onCreateBuildingOwner?:  {
    __typename: "BuildingOwner",
    id: string,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId: string,
    disabled?: boolean | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    periodFrom?: string | null,
    periodTo?: string | null,
    stake: number,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBuildingOwnerSubscriptionVariables = {
  filter?: ModelSubscriptionBuildingOwnerFilterInput | null,
};

export type OnUpdateBuildingOwnerSubscription = {
  onUpdateBuildingOwner?:  {
    __typename: "BuildingOwner",
    id: string,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId: string,
    disabled?: boolean | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    periodFrom?: string | null,
    periodTo?: string | null,
    stake: number,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBuildingOwnerSubscriptionVariables = {
  filter?: ModelSubscriptionBuildingOwnerFilterInput | null,
};

export type OnDeleteBuildingOwnerSubscription = {
  onDeleteBuildingOwner?:  {
    __typename: "BuildingOwner",
    id: string,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId: string,
    disabled?: boolean | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    periodFrom?: string | null,
    periodTo?: string | null,
    stake: number,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateChargeSubscriptionVariables = {
  filter?: ModelSubscriptionChargeFilterInput | null,
};

export type OnCreateChargeSubscription = {
  onCreateCharge?:  {
    __typename: "Charge",
    id: string,
    status: ChargeStatus,
    invoiceDate?: string | null,
    paymentDueDate?: string | null,
    archivedDate?: string | null,
    periodFrom?: string | null,
    periodTo?: string | null,
    totalAmount?: number | null,
    source: ChargeSource,
    draftFromOcr?: boolean | null,
    accountingDocumentId?: string | null,
    paid: boolean,
    remittanceInformation?: string | null,
    note?: string | null,
    createdAt: string,
    creatorEmail?: string | null,
    createdById?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contractorId?: string | null,
    contractor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    proposedContractors?:  Array< {
      __typename: "ProposedValue",
      id: string,
      confidence?: number | null,
    } > | null,
    recipientId?: string | null,
    recipient?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    proposedRecipients?:  Array< {
      __typename: "ProposedValue",
      id: string,
      confidence?: number | null,
    } > | null,
    documentId: string,
    document?:  {
      __typename: "File",
      id: string,
      bucket: string,
      createdAt?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        entity?: string | null,
        fileCategory: FileCategory,
        parentId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categoryId: string,
      foreignKey: string,
      foreignTableName: string,
      key: string,
      mimeType?: string | null,
      region: string,
      signaturePositions?:  Array< {
        __typename: "SignaturePosition",
        top: number,
        left: number,
        page: number,
        width?: number | null,
        height?: number | null,
        contactId: string,
      } > | null,
      size?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    postings?:  {
      __typename: "ModelPostingConnection",
      items:  Array< {
        __typename: "Posting",
        id: string,
        createdAt?: string | null,
        class: number,
        customLabel?: string | null,
        topClass: number,
        type: PostingType,
        totalAmount: number,
        periodFrom: string,
        periodTo: string,
        buildingId?: string | null,
        unitId?: string | null,
        invoiceId?: string | null,
        chargeId?: string | null,
        transactionId?: string | null,
        leaseId?: string | null,
        bankAccountId?: string | null,
        accountLabelId?: string | null,
        contactId?: string | null,
        leaseVariousOperationId?: string | null,
        statementId?: string | null,
        amountVatExcluded?: number | null,
        vatAmount?: number | null,
        vatRate?: number | null,
        foreignId?: string | null,
        foreignType?: PostingForeignType | null,
        invoicePostingId?: string | null,
        reconciledAt?: string | null,
        status?: PostingStatus | null,
        chargeOwnerRepartition?: number | null,
        chargeRepartitionMetric?: string | null,
        chargeRepartitionValue?: number | null,
        chargeSplitNumber?: number | null,
        chargeType?: PostingChargeType | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateChargeSubscriptionVariables = {
  filter?: ModelSubscriptionChargeFilterInput | null,
};

export type OnUpdateChargeSubscription = {
  onUpdateCharge?:  {
    __typename: "Charge",
    id: string,
    status: ChargeStatus,
    invoiceDate?: string | null,
    paymentDueDate?: string | null,
    archivedDate?: string | null,
    periodFrom?: string | null,
    periodTo?: string | null,
    totalAmount?: number | null,
    source: ChargeSource,
    draftFromOcr?: boolean | null,
    accountingDocumentId?: string | null,
    paid: boolean,
    remittanceInformation?: string | null,
    note?: string | null,
    createdAt: string,
    creatorEmail?: string | null,
    createdById?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contractorId?: string | null,
    contractor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    proposedContractors?:  Array< {
      __typename: "ProposedValue",
      id: string,
      confidence?: number | null,
    } > | null,
    recipientId?: string | null,
    recipient?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    proposedRecipients?:  Array< {
      __typename: "ProposedValue",
      id: string,
      confidence?: number | null,
    } > | null,
    documentId: string,
    document?:  {
      __typename: "File",
      id: string,
      bucket: string,
      createdAt?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        entity?: string | null,
        fileCategory: FileCategory,
        parentId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categoryId: string,
      foreignKey: string,
      foreignTableName: string,
      key: string,
      mimeType?: string | null,
      region: string,
      signaturePositions?:  Array< {
        __typename: "SignaturePosition",
        top: number,
        left: number,
        page: number,
        width?: number | null,
        height?: number | null,
        contactId: string,
      } > | null,
      size?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    postings?:  {
      __typename: "ModelPostingConnection",
      items:  Array< {
        __typename: "Posting",
        id: string,
        createdAt?: string | null,
        class: number,
        customLabel?: string | null,
        topClass: number,
        type: PostingType,
        totalAmount: number,
        periodFrom: string,
        periodTo: string,
        buildingId?: string | null,
        unitId?: string | null,
        invoiceId?: string | null,
        chargeId?: string | null,
        transactionId?: string | null,
        leaseId?: string | null,
        bankAccountId?: string | null,
        accountLabelId?: string | null,
        contactId?: string | null,
        leaseVariousOperationId?: string | null,
        statementId?: string | null,
        amountVatExcluded?: number | null,
        vatAmount?: number | null,
        vatRate?: number | null,
        foreignId?: string | null,
        foreignType?: PostingForeignType | null,
        invoicePostingId?: string | null,
        reconciledAt?: string | null,
        status?: PostingStatus | null,
        chargeOwnerRepartition?: number | null,
        chargeRepartitionMetric?: string | null,
        chargeRepartitionValue?: number | null,
        chargeSplitNumber?: number | null,
        chargeType?: PostingChargeType | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteChargeSubscriptionVariables = {
  filter?: ModelSubscriptionChargeFilterInput | null,
};

export type OnDeleteChargeSubscription = {
  onDeleteCharge?:  {
    __typename: "Charge",
    id: string,
    status: ChargeStatus,
    invoiceDate?: string | null,
    paymentDueDate?: string | null,
    archivedDate?: string | null,
    periodFrom?: string | null,
    periodTo?: string | null,
    totalAmount?: number | null,
    source: ChargeSource,
    draftFromOcr?: boolean | null,
    accountingDocumentId?: string | null,
    paid: boolean,
    remittanceInformation?: string | null,
    note?: string | null,
    createdAt: string,
    creatorEmail?: string | null,
    createdById?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contractorId?: string | null,
    contractor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    proposedContractors?:  Array< {
      __typename: "ProposedValue",
      id: string,
      confidence?: number | null,
    } > | null,
    recipientId?: string | null,
    recipient?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    proposedRecipients?:  Array< {
      __typename: "ProposedValue",
      id: string,
      confidence?: number | null,
    } > | null,
    documentId: string,
    document?:  {
      __typename: "File",
      id: string,
      bucket: string,
      createdAt?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        entity?: string | null,
        fileCategory: FileCategory,
        parentId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categoryId: string,
      foreignKey: string,
      foreignTableName: string,
      key: string,
      mimeType?: string | null,
      region: string,
      signaturePositions?:  Array< {
        __typename: "SignaturePosition",
        top: number,
        left: number,
        page: number,
        width?: number | null,
        height?: number | null,
        contactId: string,
      } > | null,
      size?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    postings?:  {
      __typename: "ModelPostingConnection",
      items:  Array< {
        __typename: "Posting",
        id: string,
        createdAt?: string | null,
        class: number,
        customLabel?: string | null,
        topClass: number,
        type: PostingType,
        totalAmount: number,
        periodFrom: string,
        periodTo: string,
        buildingId?: string | null,
        unitId?: string | null,
        invoiceId?: string | null,
        chargeId?: string | null,
        transactionId?: string | null,
        leaseId?: string | null,
        bankAccountId?: string | null,
        accountLabelId?: string | null,
        contactId?: string | null,
        leaseVariousOperationId?: string | null,
        statementId?: string | null,
        amountVatExcluded?: number | null,
        vatAmount?: number | null,
        vatRate?: number | null,
        foreignId?: string | null,
        foreignType?: PostingForeignType | null,
        invoicePostingId?: string | null,
        reconciledAt?: string | null,
        status?: PostingStatus | null,
        chargeOwnerRepartition?: number | null,
        chargeRepartitionMetric?: string | null,
        chargeRepartitionValue?: number | null,
        chargeSplitNumber?: number | null,
        chargeType?: PostingChargeType | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCommunicationSubscriptionVariables = {
  filter?: ModelSubscriptionCommunicationFilterInput | null,
};

export type OnCreateCommunicationSubscription = {
  onCreateCommunication?:  {
    __typename: "Communication",
    id: string,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    blockedRecipients?:  Array< {
      __typename: "CommunicationBlockedRecipient",
      recipient: string,
      bounceType: BounceType,
    } > | null,
    body: string,
    buildingId?: string | null,
    channel: CommunicationChannel,
    communicationTemplateId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    createdAt?: string | null,
    expirationDate?: string | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      creditNote?: boolean | null,
      creditNoteLabel?: string | null,
      dueDate?: string | null,
      invoiceDate: string,
      invoiceNumber?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      paid: boolean,
      receiptNumber?: string | null,
      reminderDates?: Array< string > | null,
      remittanceInformation?: string | null,
      statementId?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      type: InvoiceType,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    invoiceId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    leasePriceHistoryId?: string | null,
    leasePriceHistory?:  {
      __typename: "LeasePriceHistory",
      id: string,
      previousTotalRentalPrice: number,
      previousTotalMonthlyCharge: number,
      totalRentalPrice: number,
      optimalTotalRentalPrice?: number | null,
      totalMonthlyCharges: number,
      optimalTotalMonthlyCharges?: number | null,
      applicationDate: string,
      status: LeaseAmountUpdateStatus,
      comment?: string | null,
      amountDetails:  Array< {
        __typename: "LeasePriceHistoryDetail",
        unitId: string,
        indexationBaseIndex?: number | null,
        indexationNewIndex?: number | null,
        previousRentalPrice: number,
        newRentalPrice: number,
        rentalStatus: LeasePriceHistoryStatus,
        previousMonthlyChargesPrice: number,
        newMonthlyChargesPrice: number,
        monthlyChargesStatus: LeasePriceHistoryStatus,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        optimalNewRentalPrice?: number | null,
        optimalNewMonthlyChargesPrice?: number | null,
        epb?: string | null,
        epbIssueDate?: string | null,
        oldestIndexForCorrectionFactorCalculation?: number | null,
        newestIndexForCorrectionFactorCalculation?: number | null,
        rentIndexedPriceAfterNewEpbReduction?: number | null,
        chargesIndexedPriceAfterNewEpbReduction?: number | null,
        correctionFactor?: number | null,
        reductionPercentage?: number | null,
        rentDifference?: number | null,
        rentReduction?: number | null,
        indexedRentAfterReduction?: number | null,
        chargesDifference?: number | null,
        chargesReduction?: number | null,
        indexedChargesAfterReduction?: number | null,
        previousYearIndexationNewIndex?: number | null,
        previousYearIndexedRent?: number | null,
        previousYearIndexedCharges?: number | null,
      } >,
      type: LeasePriceHistoryType,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    linkedCommunicationsIds?: Array< string > | null,
    recipient?: string | null,
    recipientInBcc?: string | null,
    recipientInCc?: string | null,
    sender?: string | null,
    sentAt?: string | null,
    externalId?: string | null,
    signatureDocumentId?: string | null,
    signatureDocument?:  {
      __typename: "SignatureDocument",
      id: string,
      additionalMessage?: string | null,
      communicationBody?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      fileIds?: Array< string > | null,
      foreignKey: string,
      foreignTableName: string,
      sentAt?: string | null,
      signatureMethods: Array< SignatureType >,
      signatureResults:  Array< {
        __typename: "SignatureDocumentResult",
        openedDate?: string | null,
        signedDate?: string | null,
        signed?: boolean | null,
        signatureLink?: string | null,
        rejectReason?: string | null,
        lastSignInviteSentDate?: string | null,
        trackingProvider?: TrackingProvider | null,
        trackingCode?: string | null,
        contactId: string,
      } >,
      status: SignatureDocumentStatus,
      externalStatus?: string | null,
      type: SignatureDocumentType,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    snoozedDate?: string | null,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId?: string | null,
    status: CommunicationStatus,
    subject: string,
    toSend: boolean,
    type: CommunicationType,
    _ttl?: number | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCommunicationSubscriptionVariables = {
  filter?: ModelSubscriptionCommunicationFilterInput | null,
};

export type OnUpdateCommunicationSubscription = {
  onUpdateCommunication?:  {
    __typename: "Communication",
    id: string,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    blockedRecipients?:  Array< {
      __typename: "CommunicationBlockedRecipient",
      recipient: string,
      bounceType: BounceType,
    } > | null,
    body: string,
    buildingId?: string | null,
    channel: CommunicationChannel,
    communicationTemplateId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    createdAt?: string | null,
    expirationDate?: string | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      creditNote?: boolean | null,
      creditNoteLabel?: string | null,
      dueDate?: string | null,
      invoiceDate: string,
      invoiceNumber?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      paid: boolean,
      receiptNumber?: string | null,
      reminderDates?: Array< string > | null,
      remittanceInformation?: string | null,
      statementId?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      type: InvoiceType,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    invoiceId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    leasePriceHistoryId?: string | null,
    leasePriceHistory?:  {
      __typename: "LeasePriceHistory",
      id: string,
      previousTotalRentalPrice: number,
      previousTotalMonthlyCharge: number,
      totalRentalPrice: number,
      optimalTotalRentalPrice?: number | null,
      totalMonthlyCharges: number,
      optimalTotalMonthlyCharges?: number | null,
      applicationDate: string,
      status: LeaseAmountUpdateStatus,
      comment?: string | null,
      amountDetails:  Array< {
        __typename: "LeasePriceHistoryDetail",
        unitId: string,
        indexationBaseIndex?: number | null,
        indexationNewIndex?: number | null,
        previousRentalPrice: number,
        newRentalPrice: number,
        rentalStatus: LeasePriceHistoryStatus,
        previousMonthlyChargesPrice: number,
        newMonthlyChargesPrice: number,
        monthlyChargesStatus: LeasePriceHistoryStatus,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        optimalNewRentalPrice?: number | null,
        optimalNewMonthlyChargesPrice?: number | null,
        epb?: string | null,
        epbIssueDate?: string | null,
        oldestIndexForCorrectionFactorCalculation?: number | null,
        newestIndexForCorrectionFactorCalculation?: number | null,
        rentIndexedPriceAfterNewEpbReduction?: number | null,
        chargesIndexedPriceAfterNewEpbReduction?: number | null,
        correctionFactor?: number | null,
        reductionPercentage?: number | null,
        rentDifference?: number | null,
        rentReduction?: number | null,
        indexedRentAfterReduction?: number | null,
        chargesDifference?: number | null,
        chargesReduction?: number | null,
        indexedChargesAfterReduction?: number | null,
        previousYearIndexationNewIndex?: number | null,
        previousYearIndexedRent?: number | null,
        previousYearIndexedCharges?: number | null,
      } >,
      type: LeasePriceHistoryType,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    linkedCommunicationsIds?: Array< string > | null,
    recipient?: string | null,
    recipientInBcc?: string | null,
    recipientInCc?: string | null,
    sender?: string | null,
    sentAt?: string | null,
    externalId?: string | null,
    signatureDocumentId?: string | null,
    signatureDocument?:  {
      __typename: "SignatureDocument",
      id: string,
      additionalMessage?: string | null,
      communicationBody?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      fileIds?: Array< string > | null,
      foreignKey: string,
      foreignTableName: string,
      sentAt?: string | null,
      signatureMethods: Array< SignatureType >,
      signatureResults:  Array< {
        __typename: "SignatureDocumentResult",
        openedDate?: string | null,
        signedDate?: string | null,
        signed?: boolean | null,
        signatureLink?: string | null,
        rejectReason?: string | null,
        lastSignInviteSentDate?: string | null,
        trackingProvider?: TrackingProvider | null,
        trackingCode?: string | null,
        contactId: string,
      } >,
      status: SignatureDocumentStatus,
      externalStatus?: string | null,
      type: SignatureDocumentType,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    snoozedDate?: string | null,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId?: string | null,
    status: CommunicationStatus,
    subject: string,
    toSend: boolean,
    type: CommunicationType,
    _ttl?: number | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCommunicationSubscriptionVariables = {
  filter?: ModelSubscriptionCommunicationFilterInput | null,
};

export type OnDeleteCommunicationSubscription = {
  onDeleteCommunication?:  {
    __typename: "Communication",
    id: string,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    blockedRecipients?:  Array< {
      __typename: "CommunicationBlockedRecipient",
      recipient: string,
      bounceType: BounceType,
    } > | null,
    body: string,
    buildingId?: string | null,
    channel: CommunicationChannel,
    communicationTemplateId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    createdAt?: string | null,
    expirationDate?: string | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invoice?:  {
      __typename: "Invoice",
      id: string,
      amount: number,
      bankAccountId: string,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      creditNote?: boolean | null,
      creditNoteLabel?: string | null,
      dueDate?: string | null,
      invoiceDate: string,
      invoiceNumber?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      paid: boolean,
      receiptNumber?: string | null,
      reminderDates?: Array< string > | null,
      remittanceInformation?: string | null,
      statementId?: string | null,
      statement?:  {
        __typename: "Statement",
        id: string,
        closingStatement: boolean,
        periodFrom: string,
        periodTo: string,
        source: StatementSource,
        status: StatementStatus,
        sentDate?: string | null,
        leaseId?: string | null,
        ownerId?: string | null,
        types?: Array< StatementType > | null,
        unitIds?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      type: InvoiceType,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    invoiceId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    leasePriceHistoryId?: string | null,
    leasePriceHistory?:  {
      __typename: "LeasePriceHistory",
      id: string,
      previousTotalRentalPrice: number,
      previousTotalMonthlyCharge: number,
      totalRentalPrice: number,
      optimalTotalRentalPrice?: number | null,
      totalMonthlyCharges: number,
      optimalTotalMonthlyCharges?: number | null,
      applicationDate: string,
      status: LeaseAmountUpdateStatus,
      comment?: string | null,
      amountDetails:  Array< {
        __typename: "LeasePriceHistoryDetail",
        unitId: string,
        indexationBaseIndex?: number | null,
        indexationNewIndex?: number | null,
        previousRentalPrice: number,
        newRentalPrice: number,
        rentalStatus: LeasePriceHistoryStatus,
        previousMonthlyChargesPrice: number,
        newMonthlyChargesPrice: number,
        monthlyChargesStatus: LeasePriceHistoryStatus,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        optimalNewRentalPrice?: number | null,
        optimalNewMonthlyChargesPrice?: number | null,
        epb?: string | null,
        epbIssueDate?: string | null,
        oldestIndexForCorrectionFactorCalculation?: number | null,
        newestIndexForCorrectionFactorCalculation?: number | null,
        rentIndexedPriceAfterNewEpbReduction?: number | null,
        chargesIndexedPriceAfterNewEpbReduction?: number | null,
        correctionFactor?: number | null,
        reductionPercentage?: number | null,
        rentDifference?: number | null,
        rentReduction?: number | null,
        indexedRentAfterReduction?: number | null,
        chargesDifference?: number | null,
        chargesReduction?: number | null,
        indexedChargesAfterReduction?: number | null,
        previousYearIndexationNewIndex?: number | null,
        previousYearIndexedRent?: number | null,
        previousYearIndexedCharges?: number | null,
      } >,
      type: LeasePriceHistoryType,
      communicationId?: string | null,
      communication?:  {
        __typename: "Communication",
        id: string,
        addressId?: string | null,
        body: string,
        buildingId?: string | null,
        channel: CommunicationChannel,
        communicationTemplateId?: string | null,
        contactId?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        invoiceId?: string | null,
        leaseId?: string | null,
        leasePriceHistoryId?: string | null,
        linkedCommunicationsIds?: Array< string > | null,
        recipient?: string | null,
        recipientInBcc?: string | null,
        recipientInCc?: string | null,
        sender?: string | null,
        sentAt?: string | null,
        externalId?: string | null,
        signatureDocumentId?: string | null,
        snoozedDate?: string | null,
        statementId?: string | null,
        status: CommunicationStatus,
        subject: string,
        toSend: boolean,
        type: CommunicationType,
        _ttl?: number | null,
        unitId?: string | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId: string,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    linkedCommunicationsIds?: Array< string > | null,
    recipient?: string | null,
    recipientInBcc?: string | null,
    recipientInCc?: string | null,
    sender?: string | null,
    sentAt?: string | null,
    externalId?: string | null,
    signatureDocumentId?: string | null,
    signatureDocument?:  {
      __typename: "SignatureDocument",
      id: string,
      additionalMessage?: string | null,
      communicationBody?: string | null,
      createdAt?: string | null,
      expirationDate?: string | null,
      fileIds?: Array< string > | null,
      foreignKey: string,
      foreignTableName: string,
      sentAt?: string | null,
      signatureMethods: Array< SignatureType >,
      signatureResults:  Array< {
        __typename: "SignatureDocumentResult",
        openedDate?: string | null,
        signedDate?: string | null,
        signed?: boolean | null,
        signatureLink?: string | null,
        rejectReason?: string | null,
        lastSignInviteSentDate?: string | null,
        trackingProvider?: TrackingProvider | null,
        trackingCode?: string | null,
        contactId: string,
      } >,
      status: SignatureDocumentStatus,
      externalStatus?: string | null,
      type: SignatureDocumentType,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    snoozedDate?: string | null,
    statement?:  {
      __typename: "Statement",
      id: string,
      amounts?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      chargeStatements?:  {
        __typename: "ModelChargeStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      closingStatement: boolean,
      periodFrom: string,
      periodTo: string,
      source: StatementSource,
      status: StatementStatus,
      sentDate?: string | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      agencyRateStatements?:  {
        __typename: "ModelAgencyRateStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ownerId?: string | null,
      owner?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      repaymentStatements?:  {
        __typename: "ModelRepaymentStatementConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      types?: Array< StatementType > | null,
      unitIds?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    statementId?: string | null,
    status: CommunicationStatus,
    subject: string,
    toSend: boolean,
    type: CommunicationType,
    _ttl?: number | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCommunicationSettingsProfileSubscriptionVariables = {
  filter?: ModelSubscriptionCommunicationSettingsProfileFilterInput | null,
};

export type OnCreateCommunicationSettingsProfileSubscription = {
  onCreateCommunicationSettingsProfile?:  {
    __typename: "CommunicationSettingsProfile",
    id: string,
    communicationsSettings:  Array< {
      __typename: "CommunicationSettings",
      channel: CommunicationChannel,
      communicationType: CommunicationType,
      daysBeforeDueDate?: number | null,
      daysOverdue?: number | null,
      frequency?:  {
        __typename: "CommunicationFrequencySetting",
        dateFrom?: CommunicationFrequencySettingPeriodDateFrom | null,
        periodInterval: number,
        periodType: CommunicationFrequencySettingPeriodType,
      } | null,
      sendingRule: CommunicationSendingRule,
      recipients: Array< CommunicationSettingRecipient >,
      recipientsInBcc: Array< CommunicationSettingRecipient >,
      recipientsInCopy: Array< string >,
    } >,
    createdAt?: string | null,
    defaultName?: string | null,
    isDefaultSetting: boolean,
    referent: CommunicationSettingsProfileReferent,
    replacementId?: string | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCommunicationSettingsProfileSubscriptionVariables = {
  filter?: ModelSubscriptionCommunicationSettingsProfileFilterInput | null,
};

export type OnUpdateCommunicationSettingsProfileSubscription = {
  onUpdateCommunicationSettingsProfile?:  {
    __typename: "CommunicationSettingsProfile",
    id: string,
    communicationsSettings:  Array< {
      __typename: "CommunicationSettings",
      channel: CommunicationChannel,
      communicationType: CommunicationType,
      daysBeforeDueDate?: number | null,
      daysOverdue?: number | null,
      frequency?:  {
        __typename: "CommunicationFrequencySetting",
        dateFrom?: CommunicationFrequencySettingPeriodDateFrom | null,
        periodInterval: number,
        periodType: CommunicationFrequencySettingPeriodType,
      } | null,
      sendingRule: CommunicationSendingRule,
      recipients: Array< CommunicationSettingRecipient >,
      recipientsInBcc: Array< CommunicationSettingRecipient >,
      recipientsInCopy: Array< string >,
    } >,
    createdAt?: string | null,
    defaultName?: string | null,
    isDefaultSetting: boolean,
    referent: CommunicationSettingsProfileReferent,
    replacementId?: string | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCommunicationSettingsProfileSubscriptionVariables = {
  filter?: ModelSubscriptionCommunicationSettingsProfileFilterInput | null,
};

export type OnDeleteCommunicationSettingsProfileSubscription = {
  onDeleteCommunicationSettingsProfile?:  {
    __typename: "CommunicationSettingsProfile",
    id: string,
    communicationsSettings:  Array< {
      __typename: "CommunicationSettings",
      channel: CommunicationChannel,
      communicationType: CommunicationType,
      daysBeforeDueDate?: number | null,
      daysOverdue?: number | null,
      frequency?:  {
        __typename: "CommunicationFrequencySetting",
        dateFrom?: CommunicationFrequencySettingPeriodDateFrom | null,
        periodInterval: number,
        periodType: CommunicationFrequencySettingPeriodType,
      } | null,
      sendingRule: CommunicationSendingRule,
      recipients: Array< CommunicationSettingRecipient >,
      recipientsInBcc: Array< CommunicationSettingRecipient >,
      recipientsInCopy: Array< string >,
    } >,
    createdAt?: string | null,
    defaultName?: string | null,
    isDefaultSetting: boolean,
    referent: CommunicationSettingsProfileReferent,
    replacementId?: string | null,
    readId: string,
    readers?: Array< string > | null,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateContactSubscriptionVariables = {
  filter?: ModelSubscriptionContactFilterInput | null,
};

export type OnCreateContactSubscription = {
  onCreateContact?:  {
    __typename: "Contact",
    id: string,
    createdAt?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    companyName?: string | null,
    commercialName?: string | null,
    communicationSettingsProfileId?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    businessNumber?: string | null,
    types: Array< ContactType >,
    vat?: string | null,
    email?: string | null,
    birthDate?: string | null,
    birthPlace?: string | null,
    nationalRegister?: string | null,
    civility?: CivilityType | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    phoneNumbers?:  Array< {
      __typename: "PhoneNumber",
      countryCode: string,
      number: string,
    } > | null,
    language?: string | null,
    jointOwners?:  Array< {
      __typename: "JointOwner",
      contactId: string,
      contactPerson?: boolean | null,
    } > | null,
    agencyRateDisabled?: boolean | null,
    status?: ContactStatus | null,
    leases?:  {
      __typename: "ModelLeaseContactConnection",
      items:  Array< {
        __typename: "LeaseContact",
        id: string,
        leaseId: string,
        contactId: string,
        contactRole: ContactType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildings?:  {
      __typename: "ModelBuildingOwnerConnection",
      items:  Array< {
        __typename: "BuildingOwner",
        id: string,
        buildingId: string,
        contactId: string,
        disabled?: boolean | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    units?:  {
      __typename: "ModelUnitOwnerConnection",
      items:  Array< {
        __typename: "UnitOwner",
        id: string,
        disabled?: boolean | null,
        ownerId: string,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sendInvitation: boolean,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateContactSubscriptionVariables = {
  filter?: ModelSubscriptionContactFilterInput | null,
};

export type OnUpdateContactSubscription = {
  onUpdateContact?:  {
    __typename: "Contact",
    id: string,
    createdAt?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    companyName?: string | null,
    commercialName?: string | null,
    communicationSettingsProfileId?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    businessNumber?: string | null,
    types: Array< ContactType >,
    vat?: string | null,
    email?: string | null,
    birthDate?: string | null,
    birthPlace?: string | null,
    nationalRegister?: string | null,
    civility?: CivilityType | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    phoneNumbers?:  Array< {
      __typename: "PhoneNumber",
      countryCode: string,
      number: string,
    } > | null,
    language?: string | null,
    jointOwners?:  Array< {
      __typename: "JointOwner",
      contactId: string,
      contactPerson?: boolean | null,
    } > | null,
    agencyRateDisabled?: boolean | null,
    status?: ContactStatus | null,
    leases?:  {
      __typename: "ModelLeaseContactConnection",
      items:  Array< {
        __typename: "LeaseContact",
        id: string,
        leaseId: string,
        contactId: string,
        contactRole: ContactType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildings?:  {
      __typename: "ModelBuildingOwnerConnection",
      items:  Array< {
        __typename: "BuildingOwner",
        id: string,
        buildingId: string,
        contactId: string,
        disabled?: boolean | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    units?:  {
      __typename: "ModelUnitOwnerConnection",
      items:  Array< {
        __typename: "UnitOwner",
        id: string,
        disabled?: boolean | null,
        ownerId: string,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sendInvitation: boolean,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteContactSubscriptionVariables = {
  filter?: ModelSubscriptionContactFilterInput | null,
};

export type OnDeleteContactSubscription = {
  onDeleteContact?:  {
    __typename: "Contact",
    id: string,
    createdAt?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    companyName?: string | null,
    commercialName?: string | null,
    communicationSettingsProfileId?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    businessNumber?: string | null,
    types: Array< ContactType >,
    vat?: string | null,
    email?: string | null,
    birthDate?: string | null,
    birthPlace?: string | null,
    nationalRegister?: string | null,
    civility?: CivilityType | null,
    addressId?: string | null,
    address?:  {
      __typename: "Address",
      id: string,
      status?: AddressStatus | null,
      street: string,
      number?: string | null,
      box?: string | null,
      postalCode?: string | null,
      city?: string | null,
      region?: string | null,
      country?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      latitude?: number | null,
      longitude?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    phoneNumbers?:  Array< {
      __typename: "PhoneNumber",
      countryCode: string,
      number: string,
    } > | null,
    language?: string | null,
    jointOwners?:  Array< {
      __typename: "JointOwner",
      contactId: string,
      contactPerson?: boolean | null,
    } > | null,
    agencyRateDisabled?: boolean | null,
    status?: ContactStatus | null,
    leases?:  {
      __typename: "ModelLeaseContactConnection",
      items:  Array< {
        __typename: "LeaseContact",
        id: string,
        leaseId: string,
        contactId: string,
        contactRole: ContactType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildings?:  {
      __typename: "ModelBuildingOwnerConnection",
      items:  Array< {
        __typename: "BuildingOwner",
        id: string,
        buildingId: string,
        contactId: string,
        disabled?: boolean | null,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    units?:  {
      __typename: "ModelUnitOwnerConnection",
      items:  Array< {
        __typename: "UnitOwner",
        id: string,
        disabled?: boolean | null,
        ownerId: string,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sendInvitation: boolean,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCustomFieldSubscriptionVariables = {
  filter?: ModelSubscriptionCustomFieldFilterInput | null,
};

export type OnCreateCustomFieldSubscription = {
  onCreateCustomField?:  {
    __typename: "CustomField",
    id: string,
    entity: string,
    fieldName: string,
    fieldType: CustomFieldType,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCustomFieldSubscriptionVariables = {
  filter?: ModelSubscriptionCustomFieldFilterInput | null,
};

export type OnUpdateCustomFieldSubscription = {
  onUpdateCustomField?:  {
    __typename: "CustomField",
    id: string,
    entity: string,
    fieldName: string,
    fieldType: CustomFieldType,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCustomFieldSubscriptionVariables = {
  filter?: ModelSubscriptionCustomFieldFilterInput | null,
};

export type OnDeleteCustomFieldSubscription = {
  onDeleteCustomField?:  {
    __typename: "CustomField",
    id: string,
    entity: string,
    fieldName: string,
    fieldType: CustomFieldType,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCustomSubscriptionSubscriptionVariables = {
  filter?: ModelSubscriptionCustomSubscriptionFilterInput | null,
  userId?: string | null,
};

export type OnCreateCustomSubscriptionSubscription = {
  onCreateCustomSubscription?:  {
    __typename: "CustomSubscription",
    id: string,
    name: CustomSubscriptionType,
    value: string,
    createdAt?: string | null,
    userId?: string | null,
    _ttl: number,
    updatedAt: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCustomSubscriptionSubscriptionVariables = {
  filter?: ModelSubscriptionCustomSubscriptionFilterInput | null,
  userId?: string | null,
};

export type OnUpdateCustomSubscriptionSubscription = {
  onUpdateCustomSubscription?:  {
    __typename: "CustomSubscription",
    id: string,
    name: CustomSubscriptionType,
    value: string,
    createdAt?: string | null,
    userId?: string | null,
    _ttl: number,
    updatedAt: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCustomSubscriptionSubscriptionVariables = {
  filter?: ModelSubscriptionCustomSubscriptionFilterInput | null,
  userId?: string | null,
};

export type OnDeleteCustomSubscriptionSubscription = {
  onDeleteCustomSubscription?:  {
    __typename: "CustomSubscription",
    id: string,
    name: CustomSubscriptionType,
    value: string,
    createdAt?: string | null,
    userId?: string | null,
    _ttl: number,
    updatedAt: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDocumentCategorySubscriptionVariables = {
  filter?: ModelSubscriptionDocumentCategoryFilterInput | null,
};

export type OnCreateDocumentCategorySubscription = {
  onCreateDocumentCategory?:  {
    __typename: "DocumentCategory",
    id: string,
    entity?: string | null,
    fileCategory: FileCategory,
    labels:  Array< {
      __typename: "CustomLabel",
      language: string,
      label: string,
    } >,
    parentId?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDocumentCategorySubscriptionVariables = {
  filter?: ModelSubscriptionDocumentCategoryFilterInput | null,
};

export type OnUpdateDocumentCategorySubscription = {
  onUpdateDocumentCategory?:  {
    __typename: "DocumentCategory",
    id: string,
    entity?: string | null,
    fileCategory: FileCategory,
    labels:  Array< {
      __typename: "CustomLabel",
      language: string,
      label: string,
    } >,
    parentId?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDocumentCategorySubscriptionVariables = {
  filter?: ModelSubscriptionDocumentCategoryFilterInput | null,
};

export type OnDeleteDocumentCategorySubscription = {
  onDeleteDocumentCategory?:  {
    __typename: "DocumentCategory",
    id: string,
    entity?: string | null,
    fileCategory: FileCategory,
    labels:  Array< {
      __typename: "CustomLabel",
      language: string,
      label: string,
    } >,
    parentId?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFileSubscriptionVariables = {
  filter?: ModelSubscriptionFileFilterInput | null,
};

export type OnCreateFileSubscription = {
  onCreateFile?:  {
    __typename: "File",
    id: string,
    bucket: string,
    createdAt?: string | null,
    category?:  {
      __typename: "DocumentCategory",
      id: string,
      entity?: string | null,
      fileCategory: FileCategory,
      labels:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } >,
      parentId?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categoryId: string,
    foreignKey: string,
    foreignTableName: string,
    key: string,
    mimeType?: string | null,
    region: string,
    signaturePositions?:  Array< {
      __typename: "SignaturePosition",
      top: number,
      left: number,
      page: number,
      width?: number | null,
      height?: number | null,
      contactId: string,
    } > | null,
    size?: number | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFileSubscriptionVariables = {
  filter?: ModelSubscriptionFileFilterInput | null,
};

export type OnUpdateFileSubscription = {
  onUpdateFile?:  {
    __typename: "File",
    id: string,
    bucket: string,
    createdAt?: string | null,
    category?:  {
      __typename: "DocumentCategory",
      id: string,
      entity?: string | null,
      fileCategory: FileCategory,
      labels:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } >,
      parentId?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categoryId: string,
    foreignKey: string,
    foreignTableName: string,
    key: string,
    mimeType?: string | null,
    region: string,
    signaturePositions?:  Array< {
      __typename: "SignaturePosition",
      top: number,
      left: number,
      page: number,
      width?: number | null,
      height?: number | null,
      contactId: string,
    } > | null,
    size?: number | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFileSubscriptionVariables = {
  filter?: ModelSubscriptionFileFilterInput | null,
};

export type OnDeleteFileSubscription = {
  onDeleteFile?:  {
    __typename: "File",
    id: string,
    bucket: string,
    createdAt?: string | null,
    category?:  {
      __typename: "DocumentCategory",
      id: string,
      entity?: string | null,
      fileCategory: FileCategory,
      labels:  Array< {
        __typename: "CustomLabel",
        language: string,
        label: string,
      } >,
      parentId?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categoryId: string,
    foreignKey: string,
    foreignTableName: string,
    key: string,
    mimeType?: string | null,
    region: string,
    signaturePositions?:  Array< {
      __typename: "SignaturePosition",
      top: number,
      left: number,
      page: number,
      width?: number | null,
      height?: number | null,
      contactId: string,
    } > | null,
    size?: number | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateInventoryCustomItemSubscriptionVariables = {
  filter?: ModelSubscriptionInventoryCustomItemFilterInput | null,
};

export type OnCreateInventoryCustomItemSubscription = {
  onCreateInventoryCustomItem?:  {
    __typename: "InventoryCustomItem",
    id: string,
    type: InventoryItemType,
    value: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateInventoryCustomItemSubscriptionVariables = {
  filter?: ModelSubscriptionInventoryCustomItemFilterInput | null,
};

export type OnUpdateInventoryCustomItemSubscription = {
  onUpdateInventoryCustomItem?:  {
    __typename: "InventoryCustomItem",
    id: string,
    type: InventoryItemType,
    value: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteInventoryCustomItemSubscriptionVariables = {
  filter?: ModelSubscriptionInventoryCustomItemFilterInput | null,
};

export type OnDeleteInventoryCustomItemSubscription = {
  onDeleteInventoryCustomItem?:  {
    __typename: "InventoryCustomItem",
    id: string,
    type: InventoryItemType,
    value: string,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLeaseSubscriptionVariables = {
  filter?: ModelSubscriptionLeaseFilterInput | null,
};

export type OnCreateLeaseSubscription = {
  onCreateLease?:  {
    __typename: "Lease",
    id: string,
    balance?: number | null,
    canBeExtended?: boolean | null,
    communicationSettingsProfileId: string,
    createdAt?: string | null,
    endDate: string,
    includeRealEstateInLeaseContract?: boolean | null,
    invoiceSenderId: string,
    invoiceSender?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inventoryOfFixturesExpert?: string | null,
    inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
    inventoryOfFixturesType?: InventoryOfFixturesType | null,
    ipiNumber?: string | null,
    language: Language,
    lastIndexationProcessDate?: string | null,
    lastInvoiceDate?: string | null,
    legalCompetentAuthority?: string | null,
    name: string,
    paymentFrequency: LeasePaymentFrequency,
    paymentInvoicePeriod: LeasePaymentInvoicePeriod,
    paymentInvoicePeriodCustomDay?: number | null,
    realEstateAgency?: string | null,
    realEstateTax?: number | null,
    remittanceInformation?: string | null,
    rentalGuarantyAmount?: number | null,
    rentalGuarantyFinancialInstitution?: string | null,
    rentalGuarantyType?: LeaseRentalGuarantyType | null,
    signatureDate?: string | null,
    startDate: string,
    status: LeaseStatus,
    technicCommunicationSettingsProfileId: string,
    totalInitialMonthlyCharges: number,
    totalInitialRentalPrice: number,
    totalMonthlyCharges: number,
    totalRentalPrice: number,
    type: LeaseType,
    vatRateCharge?: number | null,
    vatRateRent?: number | null,
    additionalClauses?:  {
      __typename: "ModelLeaseAdditionalClauseConnection",
      items:  Array< {
        __typename: "LeaseAdditionalClause",
        id: string,
        leaseId: string,
        title?: string | null,
        body: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bankAccountId?: string | null,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contacts?:  {
      __typename: "ModelLeaseContactConnection",
      items:  Array< {
        __typename: "LeaseContact",
        id: string,
        leaseId: string,
        contactId: string,
        contactRole: ContactType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leaseActionHistories?:  {
      __typename: "ModelLeaseActionHistoryConnection",
      items:  Array< {
        __typename: "LeaseActionHistory",
        id: string,
        action: LeaseAction,
        comment?: string | null,
        contactId: string,
        createdAt?: string | null,
        leaseId: string,
        signatureDocumentId?: string | null,
        status?: LeaseActionHistoryStatus | null,
        communicationChannel?: CommunicationChannel | null,
        communicationSendDate?: string | null,
        communicationByPlatform?: boolean | null,
        endReason?: LeaseActionEndReason | null,
        endReasonOwnUseRelation?: string | null,
        endReasonOwnUseName?: string | null,
        initiator?: LeaseActionEndInitiator | null,
        totalAmount?: number | null,
        totalAmountLabel?: string | null,
        automatic?: boolean | null,
        hasLeaseTypeChanged?: boolean | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leaseInventories?:  {
      __typename: "ModelLeaseInventoryConnection",
      items:  Array< {
        __typename: "LeaseInventory",
        id: string,
        leaseId: string,
        inventoryType: LeaseInventoryType,
        status: LeaseStatus,
        createdAt: string,
        signatureDate?: string | null,
        note?: string | null,
        fileId?: string | null,
        signatureMethods?: Array< SignatureType > | null,
        additionalMessage?: string | null,
        connectionPlugs?: Array< ConnectionPlugsType > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leasePriceHistories?:  {
      __typename: "ModelLeasePriceHistoryConnection",
      items:  Array< {
        __typename: "LeasePriceHistory",
        id: string,
        previousTotalRentalPrice: number,
        previousTotalMonthlyCharge: number,
        totalRentalPrice: number,
        optimalTotalRentalPrice?: number | null,
        totalMonthlyCharges: number,
        optimalTotalMonthlyCharges?: number | null,
        applicationDate: string,
        status: LeaseAmountUpdateStatus,
        comment?: string | null,
        type: LeasePriceHistoryType,
        communicationId?: string | null,
        leaseId: string,
        createdAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    signatureDocuments?:  {
      __typename: "ModelSignatureDocumentConnection",
      items:  Array< {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    units?:  {
      __typename: "ModelUnitLeaseConnection",
      items:  Array< {
        __typename: "UnitLease",
        id: string,
        allowChargeAdjustment?: boolean | null,
        authorizeProfessionalActivity?: boolean | null,
        chargesIndexation?: boolean | null,
        createdAt?: string | null,
        endDate: string,
        furnishedRental?: boolean | null,
        furnishedRentalInsuranceByTenant?: boolean | null,
        furnishedRentalRentalPricePercentage?: number | null,
        initialMonthlyCharges: number,
        initialRentalPrice: number,
        indexation?: boolean | null,
        advancedIndexation?: boolean | null,
        indexType?: IndexType | null,
        baseYear?: number | null,
        baseIndexType?: BaseIndexType | null,
        baseIndexDate?: string | null,
        leaseId: string,
        mainUnit: boolean,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        monthlyCharges: number,
        rentalPrice: number,
        signatureDate?: string | null,
        startDate: string,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    variousOperations?:  {
      __typename: "ModelLeaseVariousOperationConnection",
      items:  Array< {
        __typename: "LeaseVariousOperation",
        id: string,
        amount: number,
        contactId: string,
        createdAt: string,
        endDate: string,
        label: string,
        lastInvoiceDate?: string | null,
        leaseId: string,
        operationType: LeaseVariousOperationType,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        reason?: string | null,
        separateInvoice: boolean,
        startDate: string,
        type: VariousOperationType,
        unitId: string,
        vatRate?: number | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLeaseSubscriptionVariables = {
  filter?: ModelSubscriptionLeaseFilterInput | null,
};

export type OnUpdateLeaseSubscription = {
  onUpdateLease?:  {
    __typename: "Lease",
    id: string,
    balance?: number | null,
    canBeExtended?: boolean | null,
    communicationSettingsProfileId: string,
    createdAt?: string | null,
    endDate: string,
    includeRealEstateInLeaseContract?: boolean | null,
    invoiceSenderId: string,
    invoiceSender?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inventoryOfFixturesExpert?: string | null,
    inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
    inventoryOfFixturesType?: InventoryOfFixturesType | null,
    ipiNumber?: string | null,
    language: Language,
    lastIndexationProcessDate?: string | null,
    lastInvoiceDate?: string | null,
    legalCompetentAuthority?: string | null,
    name: string,
    paymentFrequency: LeasePaymentFrequency,
    paymentInvoicePeriod: LeasePaymentInvoicePeriod,
    paymentInvoicePeriodCustomDay?: number | null,
    realEstateAgency?: string | null,
    realEstateTax?: number | null,
    remittanceInformation?: string | null,
    rentalGuarantyAmount?: number | null,
    rentalGuarantyFinancialInstitution?: string | null,
    rentalGuarantyType?: LeaseRentalGuarantyType | null,
    signatureDate?: string | null,
    startDate: string,
    status: LeaseStatus,
    technicCommunicationSettingsProfileId: string,
    totalInitialMonthlyCharges: number,
    totalInitialRentalPrice: number,
    totalMonthlyCharges: number,
    totalRentalPrice: number,
    type: LeaseType,
    vatRateCharge?: number | null,
    vatRateRent?: number | null,
    additionalClauses?:  {
      __typename: "ModelLeaseAdditionalClauseConnection",
      items:  Array< {
        __typename: "LeaseAdditionalClause",
        id: string,
        leaseId: string,
        title?: string | null,
        body: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bankAccountId?: string | null,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contacts?:  {
      __typename: "ModelLeaseContactConnection",
      items:  Array< {
        __typename: "LeaseContact",
        id: string,
        leaseId: string,
        contactId: string,
        contactRole: ContactType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leaseActionHistories?:  {
      __typename: "ModelLeaseActionHistoryConnection",
      items:  Array< {
        __typename: "LeaseActionHistory",
        id: string,
        action: LeaseAction,
        comment?: string | null,
        contactId: string,
        createdAt?: string | null,
        leaseId: string,
        signatureDocumentId?: string | null,
        status?: LeaseActionHistoryStatus | null,
        communicationChannel?: CommunicationChannel | null,
        communicationSendDate?: string | null,
        communicationByPlatform?: boolean | null,
        endReason?: LeaseActionEndReason | null,
        endReasonOwnUseRelation?: string | null,
        endReasonOwnUseName?: string | null,
        initiator?: LeaseActionEndInitiator | null,
        totalAmount?: number | null,
        totalAmountLabel?: string | null,
        automatic?: boolean | null,
        hasLeaseTypeChanged?: boolean | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leaseInventories?:  {
      __typename: "ModelLeaseInventoryConnection",
      items:  Array< {
        __typename: "LeaseInventory",
        id: string,
        leaseId: string,
        inventoryType: LeaseInventoryType,
        status: LeaseStatus,
        createdAt: string,
        signatureDate?: string | null,
        note?: string | null,
        fileId?: string | null,
        signatureMethods?: Array< SignatureType > | null,
        additionalMessage?: string | null,
        connectionPlugs?: Array< ConnectionPlugsType > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leasePriceHistories?:  {
      __typename: "ModelLeasePriceHistoryConnection",
      items:  Array< {
        __typename: "LeasePriceHistory",
        id: string,
        previousTotalRentalPrice: number,
        previousTotalMonthlyCharge: number,
        totalRentalPrice: number,
        optimalTotalRentalPrice?: number | null,
        totalMonthlyCharges: number,
        optimalTotalMonthlyCharges?: number | null,
        applicationDate: string,
        status: LeaseAmountUpdateStatus,
        comment?: string | null,
        type: LeasePriceHistoryType,
        communicationId?: string | null,
        leaseId: string,
        createdAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    signatureDocuments?:  {
      __typename: "ModelSignatureDocumentConnection",
      items:  Array< {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    units?:  {
      __typename: "ModelUnitLeaseConnection",
      items:  Array< {
        __typename: "UnitLease",
        id: string,
        allowChargeAdjustment?: boolean | null,
        authorizeProfessionalActivity?: boolean | null,
        chargesIndexation?: boolean | null,
        createdAt?: string | null,
        endDate: string,
        furnishedRental?: boolean | null,
        furnishedRentalInsuranceByTenant?: boolean | null,
        furnishedRentalRentalPricePercentage?: number | null,
        initialMonthlyCharges: number,
        initialRentalPrice: number,
        indexation?: boolean | null,
        advancedIndexation?: boolean | null,
        indexType?: IndexType | null,
        baseYear?: number | null,
        baseIndexType?: BaseIndexType | null,
        baseIndexDate?: string | null,
        leaseId: string,
        mainUnit: boolean,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        monthlyCharges: number,
        rentalPrice: number,
        signatureDate?: string | null,
        startDate: string,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    variousOperations?:  {
      __typename: "ModelLeaseVariousOperationConnection",
      items:  Array< {
        __typename: "LeaseVariousOperation",
        id: string,
        amount: number,
        contactId: string,
        createdAt: string,
        endDate: string,
        label: string,
        lastInvoiceDate?: string | null,
        leaseId: string,
        operationType: LeaseVariousOperationType,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        reason?: string | null,
        separateInvoice: boolean,
        startDate: string,
        type: VariousOperationType,
        unitId: string,
        vatRate?: number | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLeaseSubscriptionVariables = {
  filter?: ModelSubscriptionLeaseFilterInput | null,
};

export type OnDeleteLeaseSubscription = {
  onDeleteLease?:  {
    __typename: "Lease",
    id: string,
    balance?: number | null,
    canBeExtended?: boolean | null,
    communicationSettingsProfileId: string,
    createdAt?: string | null,
    endDate: string,
    includeRealEstateInLeaseContract?: boolean | null,
    invoiceSenderId: string,
    invoiceSender?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    inventoryOfFixturesExpert?: string | null,
    inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
    inventoryOfFixturesType?: InventoryOfFixturesType | null,
    ipiNumber?: string | null,
    language: Language,
    lastIndexationProcessDate?: string | null,
    lastInvoiceDate?: string | null,
    legalCompetentAuthority?: string | null,
    name: string,
    paymentFrequency: LeasePaymentFrequency,
    paymentInvoicePeriod: LeasePaymentInvoicePeriod,
    paymentInvoicePeriodCustomDay?: number | null,
    realEstateAgency?: string | null,
    realEstateTax?: number | null,
    remittanceInformation?: string | null,
    rentalGuarantyAmount?: number | null,
    rentalGuarantyFinancialInstitution?: string | null,
    rentalGuarantyType?: LeaseRentalGuarantyType | null,
    signatureDate?: string | null,
    startDate: string,
    status: LeaseStatus,
    technicCommunicationSettingsProfileId: string,
    totalInitialMonthlyCharges: number,
    totalInitialRentalPrice: number,
    totalMonthlyCharges: number,
    totalRentalPrice: number,
    type: LeaseType,
    vatRateCharge?: number | null,
    vatRateRent?: number | null,
    additionalClauses?:  {
      __typename: "ModelLeaseAdditionalClauseConnection",
      items:  Array< {
        __typename: "LeaseAdditionalClause",
        id: string,
        leaseId: string,
        title?: string | null,
        body: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    bankAccountId?: string | null,
    bankAccount?:  {
      __typename: "BankAccount",
      id: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      number: string,
      bicNumber?: string | null,
      bankName?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      disabled?: boolean | null,
      integrations?: Array< IntegrationName > | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contacts?:  {
      __typename: "ModelLeaseContactConnection",
      items:  Array< {
        __typename: "LeaseContact",
        id: string,
        leaseId: string,
        contactId: string,
        contactRole: ContactType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leaseActionHistories?:  {
      __typename: "ModelLeaseActionHistoryConnection",
      items:  Array< {
        __typename: "LeaseActionHistory",
        id: string,
        action: LeaseAction,
        comment?: string | null,
        contactId: string,
        createdAt?: string | null,
        leaseId: string,
        signatureDocumentId?: string | null,
        status?: LeaseActionHistoryStatus | null,
        communicationChannel?: CommunicationChannel | null,
        communicationSendDate?: string | null,
        communicationByPlatform?: boolean | null,
        endReason?: LeaseActionEndReason | null,
        endReasonOwnUseRelation?: string | null,
        endReasonOwnUseName?: string | null,
        initiator?: LeaseActionEndInitiator | null,
        totalAmount?: number | null,
        totalAmountLabel?: string | null,
        automatic?: boolean | null,
        hasLeaseTypeChanged?: boolean | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leaseInventories?:  {
      __typename: "ModelLeaseInventoryConnection",
      items:  Array< {
        __typename: "LeaseInventory",
        id: string,
        leaseId: string,
        inventoryType: LeaseInventoryType,
        status: LeaseStatus,
        createdAt: string,
        signatureDate?: string | null,
        note?: string | null,
        fileId?: string | null,
        signatureMethods?: Array< SignatureType > | null,
        additionalMessage?: string | null,
        connectionPlugs?: Array< ConnectionPlugsType > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leasePriceHistories?:  {
      __typename: "ModelLeasePriceHistoryConnection",
      items:  Array< {
        __typename: "LeasePriceHistory",
        id: string,
        previousTotalRentalPrice: number,
        previousTotalMonthlyCharge: number,
        totalRentalPrice: number,
        optimalTotalRentalPrice?: number | null,
        totalMonthlyCharges: number,
        optimalTotalMonthlyCharges?: number | null,
        applicationDate: string,
        status: LeaseAmountUpdateStatus,
        comment?: string | null,
        type: LeasePriceHistoryType,
        communicationId?: string | null,
        leaseId: string,
        createdAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    signatureDocuments?:  {
      __typename: "ModelSignatureDocumentConnection",
      items:  Array< {
        __typename: "SignatureDocument",
        id: string,
        additionalMessage?: string | null,
        communicationBody?: string | null,
        createdAt?: string | null,
        expirationDate?: string | null,
        fileIds?: Array< string > | null,
        foreignKey: string,
        foreignTableName: string,
        sentAt?: string | null,
        signatureMethods: Array< SignatureType >,
        status: SignatureDocumentStatus,
        externalStatus?: string | null,
        type: SignatureDocumentType,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    units?:  {
      __typename: "ModelUnitLeaseConnection",
      items:  Array< {
        __typename: "UnitLease",
        id: string,
        allowChargeAdjustment?: boolean | null,
        authorizeProfessionalActivity?: boolean | null,
        chargesIndexation?: boolean | null,
        createdAt?: string | null,
        endDate: string,
        furnishedRental?: boolean | null,
        furnishedRentalInsuranceByTenant?: boolean | null,
        furnishedRentalRentalPricePercentage?: number | null,
        initialMonthlyCharges: number,
        initialRentalPrice: number,
        indexation?: boolean | null,
        advancedIndexation?: boolean | null,
        indexType?: IndexType | null,
        baseYear?: number | null,
        baseIndexType?: BaseIndexType | null,
        baseIndexDate?: string | null,
        leaseId: string,
        mainUnit: boolean,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        monthlyCharges: number,
        rentalPrice: number,
        signatureDate?: string | null,
        startDate: string,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    variousOperations?:  {
      __typename: "ModelLeaseVariousOperationConnection",
      items:  Array< {
        __typename: "LeaseVariousOperation",
        id: string,
        amount: number,
        contactId: string,
        createdAt: string,
        endDate: string,
        label: string,
        lastInvoiceDate?: string | null,
        leaseId: string,
        operationType: LeaseVariousOperationType,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        reason?: string | null,
        separateInvoice: boolean,
        startDate: string,
        type: VariousOperationType,
        unitId: string,
        vatRate?: number | null,
        readId: string,
        readers?: Array< string > | null,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLeaseContactSubscriptionVariables = {
  filter?: ModelSubscriptionLeaseContactFilterInput | null,
};

export type OnCreateLeaseContactSubscription = {
  onCreateLeaseContact?:  {
    __typename: "LeaseContact",
    id: string,
    leaseId: string,
    contactId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactRole: ContactType,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLeaseContactSubscriptionVariables = {
  filter?: ModelSubscriptionLeaseContactFilterInput | null,
};

export type OnUpdateLeaseContactSubscription = {
  onUpdateLeaseContact?:  {
    __typename: "LeaseContact",
    id: string,
    leaseId: string,
    contactId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactRole: ContactType,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLeaseContactSubscriptionVariables = {
  filter?: ModelSubscriptionLeaseContactFilterInput | null,
};

export type OnDeleteLeaseContactSubscription = {
  onDeleteLeaseContact?:  {
    __typename: "LeaseContact",
    id: string,
    leaseId: string,
    contactId: string,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactRole: ContactType,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLeaseInventorySubscriptionVariables = {
  filter?: ModelSubscriptionLeaseInventoryFilterInput | null,
};

export type OnCreateLeaseInventorySubscription = {
  onCreateLeaseInventory?:  {
    __typename: "LeaseInventory",
    id: string,
    leaseId: string,
    inventoryType: LeaseInventoryType,
    status: LeaseStatus,
    createdAt: string,
    signatureDate?: string | null,
    note?: string | null,
    encodings?:  {
      __typename: "ModelLeaseInventoryEncodingConnection",
      items:  Array< {
        __typename: "LeaseInventoryEncoding",
        id: string,
        leaseInventoryId: string,
        structureId: string,
        state?: LeaseInventoryStructureStateType | null,
        note?: string | null,
        furnitureQuantity?: number | null,
        furniturePrice?: number | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    fileId?: string | null,
    file?:  {
      __typename: "File",
      id: string,
      bucket: string,
      createdAt?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        entity?: string | null,
        fileCategory: FileCategory,
        parentId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categoryId: string,
      foreignKey: string,
      foreignTableName: string,
      key: string,
      mimeType?: string | null,
      region: string,
      signaturePositions?:  Array< {
        __typename: "SignaturePosition",
        top: number,
        left: number,
        page: number,
        width?: number | null,
        height?: number | null,
        contactId: string,
      } > | null,
      size?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    signatureMethods?: Array< SignatureType > | null,
    additionalMessage?: string | null,
    connectionPlugs?: Array< ConnectionPlugsType > | null,
    keys?:  Array< {
      __typename: "LeaseInventoryUnitKey",
      keyType: LeaseInventoryUnitKeyType,
      keyId?: string | null,
    } > | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLeaseInventorySubscriptionVariables = {
  filter?: ModelSubscriptionLeaseInventoryFilterInput | null,
};

export type OnUpdateLeaseInventorySubscription = {
  onUpdateLeaseInventory?:  {
    __typename: "LeaseInventory",
    id: string,
    leaseId: string,
    inventoryType: LeaseInventoryType,
    status: LeaseStatus,
    createdAt: string,
    signatureDate?: string | null,
    note?: string | null,
    encodings?:  {
      __typename: "ModelLeaseInventoryEncodingConnection",
      items:  Array< {
        __typename: "LeaseInventoryEncoding",
        id: string,
        leaseInventoryId: string,
        structureId: string,
        state?: LeaseInventoryStructureStateType | null,
        note?: string | null,
        furnitureQuantity?: number | null,
        furniturePrice?: number | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    fileId?: string | null,
    file?:  {
      __typename: "File",
      id: string,
      bucket: string,
      createdAt?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        entity?: string | null,
        fileCategory: FileCategory,
        parentId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categoryId: string,
      foreignKey: string,
      foreignTableName: string,
      key: string,
      mimeType?: string | null,
      region: string,
      signaturePositions?:  Array< {
        __typename: "SignaturePosition",
        top: number,
        left: number,
        page: number,
        width?: number | null,
        height?: number | null,
        contactId: string,
      } > | null,
      size?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    signatureMethods?: Array< SignatureType > | null,
    additionalMessage?: string | null,
    connectionPlugs?: Array< ConnectionPlugsType > | null,
    keys?:  Array< {
      __typename: "LeaseInventoryUnitKey",
      keyType: LeaseInventoryUnitKeyType,
      keyId?: string | null,
    } > | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLeaseInventorySubscriptionVariables = {
  filter?: ModelSubscriptionLeaseInventoryFilterInput | null,
};

export type OnDeleteLeaseInventorySubscription = {
  onDeleteLeaseInventory?:  {
    __typename: "LeaseInventory",
    id: string,
    leaseId: string,
    inventoryType: LeaseInventoryType,
    status: LeaseStatus,
    createdAt: string,
    signatureDate?: string | null,
    note?: string | null,
    encodings?:  {
      __typename: "ModelLeaseInventoryEncodingConnection",
      items:  Array< {
        __typename: "LeaseInventoryEncoding",
        id: string,
        leaseInventoryId: string,
        structureId: string,
        state?: LeaseInventoryStructureStateType | null,
        note?: string | null,
        furnitureQuantity?: number | null,
        furniturePrice?: number | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    fileId?: string | null,
    file?:  {
      __typename: "File",
      id: string,
      bucket: string,
      createdAt?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        entity?: string | null,
        fileCategory: FileCategory,
        parentId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      categoryId: string,
      foreignKey: string,
      foreignTableName: string,
      key: string,
      mimeType?: string | null,
      region: string,
      signaturePositions?:  Array< {
        __typename: "SignaturePosition",
        top: number,
        left: number,
        page: number,
        width?: number | null,
        height?: number | null,
        contactId: string,
      } > | null,
      size?: number | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    signatureMethods?: Array< SignatureType > | null,
    additionalMessage?: string | null,
    connectionPlugs?: Array< ConnectionPlugsType > | null,
    keys?:  Array< {
      __typename: "LeaseInventoryUnitKey",
      keyType: LeaseInventoryUnitKeyType,
      keyId?: string | null,
    } > | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLeaseInventoryCheckedItemSubscriptionVariables = {
  filter?: ModelSubscriptionLeaseInventoryCheckedItemFilterInput | null,
};

export type OnCreateLeaseInventoryCheckedItemSubscription = {
  onCreateLeaseInventoryCheckedItem?:  {
    __typename: "LeaseInventoryCheckedItem",
    id: string,
    custom: boolean,
    leaseInventoryEncodingId: string,
    totalDefects?: number | null,
    customItemId?: string | null,
    customItem?:  {
      __typename: "InventoryCustomItem",
      id: string,
      type: InventoryItemType,
      value: string,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    value?: string | null,
    type?: InventoryItemType | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLeaseInventoryCheckedItemSubscriptionVariables = {
  filter?: ModelSubscriptionLeaseInventoryCheckedItemFilterInput | null,
};

export type OnUpdateLeaseInventoryCheckedItemSubscription = {
  onUpdateLeaseInventoryCheckedItem?:  {
    __typename: "LeaseInventoryCheckedItem",
    id: string,
    custom: boolean,
    leaseInventoryEncodingId: string,
    totalDefects?: number | null,
    customItemId?: string | null,
    customItem?:  {
      __typename: "InventoryCustomItem",
      id: string,
      type: InventoryItemType,
      value: string,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    value?: string | null,
    type?: InventoryItemType | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLeaseInventoryCheckedItemSubscriptionVariables = {
  filter?: ModelSubscriptionLeaseInventoryCheckedItemFilterInput | null,
};

export type OnDeleteLeaseInventoryCheckedItemSubscription = {
  onDeleteLeaseInventoryCheckedItem?:  {
    __typename: "LeaseInventoryCheckedItem",
    id: string,
    custom: boolean,
    leaseInventoryEncodingId: string,
    totalDefects?: number | null,
    customItemId?: string | null,
    customItem?:  {
      __typename: "InventoryCustomItem",
      id: string,
      type: InventoryItemType,
      value: string,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    value?: string | null,
    type?: InventoryItemType | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLeaseInventoryEncodingSubscriptionVariables = {
  filter?: ModelSubscriptionLeaseInventoryEncodingFilterInput | null,
};

export type OnCreateLeaseInventoryEncodingSubscription = {
  onCreateLeaseInventoryEncoding?:  {
    __typename: "LeaseInventoryEncoding",
    id: string,
    leaseInventoryId: string,
    structureId: string,
    state?: LeaseInventoryStructureStateType | null,
    note?: string | null,
    checkedItems?:  {
      __typename: "ModelLeaseInventoryCheckedItemConnection",
      items:  Array< {
        __typename: "LeaseInventoryCheckedItem",
        id: string,
        custom: boolean,
        leaseInventoryEncodingId: string,
        totalDefects?: number | null,
        customItemId?: string | null,
        value?: string | null,
        type?: InventoryItemType | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    furnitureQuantity?: number | null,
    furniturePrice?: number | null,
    furnitureExitIssues?:  Array< {
      __typename: "LeaseInventoryFurnitureExitIssue",
      quantity: number,
      issue: LeaseInventoryFurnitureExitIssueType,
      action: LeaseInventoryFurnitureExitIssueAction,
      penalty?: number | null,
    } > | null,
    medias?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLeaseInventoryEncodingSubscriptionVariables = {
  filter?: ModelSubscriptionLeaseInventoryEncodingFilterInput | null,
};

export type OnUpdateLeaseInventoryEncodingSubscription = {
  onUpdateLeaseInventoryEncoding?:  {
    __typename: "LeaseInventoryEncoding",
    id: string,
    leaseInventoryId: string,
    structureId: string,
    state?: LeaseInventoryStructureStateType | null,
    note?: string | null,
    checkedItems?:  {
      __typename: "ModelLeaseInventoryCheckedItemConnection",
      items:  Array< {
        __typename: "LeaseInventoryCheckedItem",
        id: string,
        custom: boolean,
        leaseInventoryEncodingId: string,
        totalDefects?: number | null,
        customItemId?: string | null,
        value?: string | null,
        type?: InventoryItemType | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    furnitureQuantity?: number | null,
    furniturePrice?: number | null,
    furnitureExitIssues?:  Array< {
      __typename: "LeaseInventoryFurnitureExitIssue",
      quantity: number,
      issue: LeaseInventoryFurnitureExitIssueType,
      action: LeaseInventoryFurnitureExitIssueAction,
      penalty?: number | null,
    } > | null,
    medias?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLeaseInventoryEncodingSubscriptionVariables = {
  filter?: ModelSubscriptionLeaseInventoryEncodingFilterInput | null,
};

export type OnDeleteLeaseInventoryEncodingSubscription = {
  onDeleteLeaseInventoryEncoding?:  {
    __typename: "LeaseInventoryEncoding",
    id: string,
    leaseInventoryId: string,
    structureId: string,
    state?: LeaseInventoryStructureStateType | null,
    note?: string | null,
    checkedItems?:  {
      __typename: "ModelLeaseInventoryCheckedItemConnection",
      items:  Array< {
        __typename: "LeaseInventoryCheckedItem",
        id: string,
        custom: boolean,
        leaseInventoryEncodingId: string,
        totalDefects?: number | null,
        customItemId?: string | null,
        value?: string | null,
        type?: InventoryItemType | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    furnitureQuantity?: number | null,
    furniturePrice?: number | null,
    furnitureExitIssues?:  Array< {
      __typename: "LeaseInventoryFurnitureExitIssue",
      quantity: number,
      issue: LeaseInventoryFurnitureExitIssueType,
      action: LeaseInventoryFurnitureExitIssueAction,
      penalty?: number | null,
    } > | null,
    medias?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    id: string,
    createdBy: NotificationCreation,
    type?: NotificationType | null,
    message?: string | null,
    popupDate: string,
    expirationDate?: string | null,
    status: NotificationStatus,
    createdAt?: string | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    technicId?: string | null,
    technic?:  {
      __typename: "Technic",
      id: string,
      type: TechnicType,
      contractNumber?: string | null,
      communicationSettingsProfileId: string,
      maintenanceHistory?:  Array< {
        __typename: "TechnicMaintenanceHistory",
        date: string,
        contactId?: string | null,
        fileId?: string | null,
      } > | null,
      utilityType?: UtilityType | null,
      utilityEanCode?: string | null,
      utilityMeterNumber?: string | null,
      utilityDistributorId?: string | null,
      utilityDistributor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      utilityHistory?:  Array< {
        __typename: "UtilityHistory",
        date: string,
        reason?: UtilityReadingReason | null,
        reading: number,
        secondReading?: number | null,
      } > | null,
      PebValidityDate?: string | null,
      PebEnergeticPerformance?: string | null,
      PebReportNumber?: string | null,
      PebIssueDate?: string | null,
      detectorType?: DetectorType | null,
      detectorSerialNumber?: string | null,
      detectorBrand?: string | null,
      detectorLastControlDate?: string | null,
      detectorUnitInventoryId?: string | null,
      heatingBrand?: string | null,
      heatingModel?: string | null,
      heatingInstallationDate?: string | null,
      heatingType?: HeatingType | null,
      heatingLastControlDate?: string | null,
      tankType?: TankType | null,
      tankCapacity?: number | null,
      tankState?: TankState | null,
      tankLastControlDate?: string | null,
      tankLeakDetection?: boolean | null,
      tankEncuvement?: boolean | null,
      tankWallType?: TankWallType | null,
      tankInstallationDate?: string | null,
      chimneyLastControlDate?: string | null,
      chimneyUnitInventoryId?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildingId?: string | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      originalTechnicId?: string | null,
      readers?: Array< string > | null,
      writers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ticketId?: string | null,
    ticket?:  {
      __typename: "Ticket",
      id: string,
      assignedAt?: string | null,
      assignedTo?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      assignedToId?: string | null,
      attachment?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      buildingId?: string | null,
      closedAt?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId: string,
      content: string,
      conversations?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      emailMessageId?: string | null,
      emailReferences?: Array< string | null > | null,
      parentId?: string | null,
      plannedAt?: string | null,
      referenceCode?: string | null,
      sourceType: TicketSourceType,
      status?: TicketStatus | null,
      subject: string,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unread: boolean,
      updatedAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    id: string,
    createdBy: NotificationCreation,
    type?: NotificationType | null,
    message?: string | null,
    popupDate: string,
    expirationDate?: string | null,
    status: NotificationStatus,
    createdAt?: string | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    technicId?: string | null,
    technic?:  {
      __typename: "Technic",
      id: string,
      type: TechnicType,
      contractNumber?: string | null,
      communicationSettingsProfileId: string,
      maintenanceHistory?:  Array< {
        __typename: "TechnicMaintenanceHistory",
        date: string,
        contactId?: string | null,
        fileId?: string | null,
      } > | null,
      utilityType?: UtilityType | null,
      utilityEanCode?: string | null,
      utilityMeterNumber?: string | null,
      utilityDistributorId?: string | null,
      utilityDistributor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      utilityHistory?:  Array< {
        __typename: "UtilityHistory",
        date: string,
        reason?: UtilityReadingReason | null,
        reading: number,
        secondReading?: number | null,
      } > | null,
      PebValidityDate?: string | null,
      PebEnergeticPerformance?: string | null,
      PebReportNumber?: string | null,
      PebIssueDate?: string | null,
      detectorType?: DetectorType | null,
      detectorSerialNumber?: string | null,
      detectorBrand?: string | null,
      detectorLastControlDate?: string | null,
      detectorUnitInventoryId?: string | null,
      heatingBrand?: string | null,
      heatingModel?: string | null,
      heatingInstallationDate?: string | null,
      heatingType?: HeatingType | null,
      heatingLastControlDate?: string | null,
      tankType?: TankType | null,
      tankCapacity?: number | null,
      tankState?: TankState | null,
      tankLastControlDate?: string | null,
      tankLeakDetection?: boolean | null,
      tankEncuvement?: boolean | null,
      tankWallType?: TankWallType | null,
      tankInstallationDate?: string | null,
      chimneyLastControlDate?: string | null,
      chimneyUnitInventoryId?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildingId?: string | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      originalTechnicId?: string | null,
      readers?: Array< string > | null,
      writers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ticketId?: string | null,
    ticket?:  {
      __typename: "Ticket",
      id: string,
      assignedAt?: string | null,
      assignedTo?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      assignedToId?: string | null,
      attachment?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      buildingId?: string | null,
      closedAt?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId: string,
      content: string,
      conversations?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      emailMessageId?: string | null,
      emailReferences?: Array< string | null > | null,
      parentId?: string | null,
      plannedAt?: string | null,
      referenceCode?: string | null,
      sourceType: TicketSourceType,
      status?: TicketStatus | null,
      subject: string,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unread: boolean,
      updatedAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    id: string,
    createdBy: NotificationCreation,
    type?: NotificationType | null,
    message?: string | null,
    popupDate: string,
    expirationDate?: string | null,
    status: NotificationStatus,
    createdAt?: string | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    technicId?: string | null,
    technic?:  {
      __typename: "Technic",
      id: string,
      type: TechnicType,
      contractNumber?: string | null,
      communicationSettingsProfileId: string,
      maintenanceHistory?:  Array< {
        __typename: "TechnicMaintenanceHistory",
        date: string,
        contactId?: string | null,
        fileId?: string | null,
      } > | null,
      utilityType?: UtilityType | null,
      utilityEanCode?: string | null,
      utilityMeterNumber?: string | null,
      utilityDistributorId?: string | null,
      utilityDistributor?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      utilityHistory?:  Array< {
        __typename: "UtilityHistory",
        date: string,
        reason?: UtilityReadingReason | null,
        reading: number,
        secondReading?: number | null,
      } > | null,
      PebValidityDate?: string | null,
      PebEnergeticPerformance?: string | null,
      PebReportNumber?: string | null,
      PebIssueDate?: string | null,
      detectorType?: DetectorType | null,
      detectorSerialNumber?: string | null,
      detectorBrand?: string | null,
      detectorLastControlDate?: string | null,
      detectorUnitInventoryId?: string | null,
      heatingBrand?: string | null,
      heatingModel?: string | null,
      heatingInstallationDate?: string | null,
      heatingType?: HeatingType | null,
      heatingLastControlDate?: string | null,
      tankType?: TankType | null,
      tankCapacity?: number | null,
      tankState?: TankState | null,
      tankLastControlDate?: string | null,
      tankLeakDetection?: boolean | null,
      tankEncuvement?: boolean | null,
      tankWallType?: TankWallType | null,
      tankInstallationDate?: string | null,
      chimneyLastControlDate?: string | null,
      chimneyUnitInventoryId?: string | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildingId?: string | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      leaseId?: string | null,
      lease?:  {
        __typename: "Lease",
        id: string,
        balance?: number | null,
        canBeExtended?: boolean | null,
        communicationSettingsProfileId: string,
        createdAt?: string | null,
        endDate: string,
        includeRealEstateInLeaseContract?: boolean | null,
        invoiceSenderId: string,
        inventoryOfFixturesExpert?: string | null,
        inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
        inventoryOfFixturesType?: InventoryOfFixturesType | null,
        ipiNumber?: string | null,
        language: Language,
        lastIndexationProcessDate?: string | null,
        lastInvoiceDate?: string | null,
        legalCompetentAuthority?: string | null,
        name: string,
        paymentFrequency: LeasePaymentFrequency,
        paymentInvoicePeriod: LeasePaymentInvoicePeriod,
        paymentInvoicePeriodCustomDay?: number | null,
        realEstateAgency?: string | null,
        realEstateTax?: number | null,
        remittanceInformation?: string | null,
        rentalGuarantyAmount?: number | null,
        rentalGuarantyFinancialInstitution?: string | null,
        rentalGuarantyType?: LeaseRentalGuarantyType | null,
        signatureDate?: string | null,
        startDate: string,
        status: LeaseStatus,
        technicCommunicationSettingsProfileId: string,
        totalInitialMonthlyCharges: number,
        totalInitialRentalPrice: number,
        totalMonthlyCharges: number,
        totalRentalPrice: number,
        type: LeaseType,
        vatRateCharge?: number | null,
        vatRateRent?: number | null,
        bankAccountId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      originalTechnicId?: string | null,
      readers?: Array< string > | null,
      writers?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ticketId?: string | null,
    ticket?:  {
      __typename: "Ticket",
      id: string,
      assignedAt?: string | null,
      assignedTo?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      assignedToId?: string | null,
      attachment?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      buildingId?: string | null,
      closedAt?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId: string,
      content: string,
      conversations?:  {
        __typename: "ModelTicketConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      emailMessageId?: string | null,
      emailReferences?: Array< string | null > | null,
      parentId?: string | null,
      plannedAt?: string | null,
      referenceCode?: string | null,
      sourceType: TicketSourceType,
      status?: TicketStatus | null,
      subject: string,
      unit?:  {
        __typename: "Unit",
        id: string,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        acquisitionValue?: number | null,
        advertisedMonthlyCharges?: number | null,
        advertisedRentalPrice?: number | null,
        cadastralIncome?: number | null,
        createdAt?: string | null,
        floor?: number | null,
        jointOwnershipId?: string | null,
        managementDate: string,
        name: string,
        predictedPrice?: string | null,
        propertyTax?: number | null,
        publishAddress?: boolean | null,
        surface?: number | null,
        type: UnitType,
        buildingId: string,
        unitAdvertisementId?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      unitId?: string | null,
      unread: boolean,
      updatedAt?: string | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSendingSourceSubscriptionVariables = {
  filter?: ModelSubscriptionSendingSourceFilterInput | null,
};

export type OnCreateSendingSourceSubscription = {
  onCreateSendingSource?:  {
    __typename: "SendingSource",
    id: string,
    createdAt?: string | null,
    createdById?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dnsRecords?:  Array< {
      __typename: "DNSRecord",
      name: string,
      type: string,
      value: string,
    } > | null,
    emailAddress: string,
    issuesEmail: string,
    chargesEmail: string,
    errorMessage?: SendingSourceErrorType | null,
    name: string,
    lastReminderDate?: string | null,
    status: SendingSourceStatus,
    statusLastVerification?: string | null,
    verifiedType: SendingSourceVerifiedType,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSendingSourceSubscriptionVariables = {
  filter?: ModelSubscriptionSendingSourceFilterInput | null,
};

export type OnUpdateSendingSourceSubscription = {
  onUpdateSendingSource?:  {
    __typename: "SendingSource",
    id: string,
    createdAt?: string | null,
    createdById?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dnsRecords?:  Array< {
      __typename: "DNSRecord",
      name: string,
      type: string,
      value: string,
    } > | null,
    emailAddress: string,
    issuesEmail: string,
    chargesEmail: string,
    errorMessage?: SendingSourceErrorType | null,
    name: string,
    lastReminderDate?: string | null,
    status: SendingSourceStatus,
    statusLastVerification?: string | null,
    verifiedType: SendingSourceVerifiedType,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSendingSourceSubscriptionVariables = {
  filter?: ModelSubscriptionSendingSourceFilterInput | null,
};

export type OnDeleteSendingSourceSubscription = {
  onDeleteSendingSource?:  {
    __typename: "SendingSource",
    id: string,
    createdAt?: string | null,
    createdById?: string | null,
    createdBy?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    dnsRecords?:  Array< {
      __typename: "DNSRecord",
      name: string,
      type: string,
      value: string,
    } > | null,
    emailAddress: string,
    issuesEmail: string,
    chargesEmail: string,
    errorMessage?: SendingSourceErrorType | null,
    name: string,
    lastReminderDate?: string | null,
    status: SendingSourceStatus,
    statusLastVerification?: string | null,
    verifiedType: SendingSourceVerifiedType,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSignatureDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionSignatureDocumentFilterInput | null,
};

export type OnCreateSignatureDocumentSubscription = {
  onCreateSignatureDocument?:  {
    __typename: "SignatureDocument",
    id: string,
    additionalMessage?: string | null,
    communicationBody?: string | null,
    createdAt?: string | null,
    expirationDate?: string | null,
    fileIds?: Array< string > | null,
    foreignKey: string,
    foreignTableName: string,
    sentAt?: string | null,
    signatureMethods: Array< SignatureType >,
    signatureResults:  Array< {
      __typename: "SignatureDocumentResult",
      openedDate?: string | null,
      signedDate?: string | null,
      signed?: boolean | null,
      signatureLink?: string | null,
      rejectReason?: string | null,
      lastSignInviteSentDate?: string | null,
      trackingProvider?: TrackingProvider | null,
      trackingCode?: string | null,
      contactId: string,
    } >,
    status: SignatureDocumentStatus,
    externalStatus?: string | null,
    type: SignatureDocumentType,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSignatureDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionSignatureDocumentFilterInput | null,
};

export type OnUpdateSignatureDocumentSubscription = {
  onUpdateSignatureDocument?:  {
    __typename: "SignatureDocument",
    id: string,
    additionalMessage?: string | null,
    communicationBody?: string | null,
    createdAt?: string | null,
    expirationDate?: string | null,
    fileIds?: Array< string > | null,
    foreignKey: string,
    foreignTableName: string,
    sentAt?: string | null,
    signatureMethods: Array< SignatureType >,
    signatureResults:  Array< {
      __typename: "SignatureDocumentResult",
      openedDate?: string | null,
      signedDate?: string | null,
      signed?: boolean | null,
      signatureLink?: string | null,
      rejectReason?: string | null,
      lastSignInviteSentDate?: string | null,
      trackingProvider?: TrackingProvider | null,
      trackingCode?: string | null,
      contactId: string,
    } >,
    status: SignatureDocumentStatus,
    externalStatus?: string | null,
    type: SignatureDocumentType,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSignatureDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionSignatureDocumentFilterInput | null,
};

export type OnDeleteSignatureDocumentSubscription = {
  onDeleteSignatureDocument?:  {
    __typename: "SignatureDocument",
    id: string,
    additionalMessage?: string | null,
    communicationBody?: string | null,
    createdAt?: string | null,
    expirationDate?: string | null,
    fileIds?: Array< string > | null,
    foreignKey: string,
    foreignTableName: string,
    sentAt?: string | null,
    signatureMethods: Array< SignatureType >,
    signatureResults:  Array< {
      __typename: "SignatureDocumentResult",
      openedDate?: string | null,
      signedDate?: string | null,
      signed?: boolean | null,
      signatureLink?: string | null,
      rejectReason?: string | null,
      lastSignInviteSentDate?: string | null,
      trackingProvider?: TrackingProvider | null,
      trackingCode?: string | null,
      contactId: string,
    } >,
    status: SignatureDocumentStatus,
    externalStatus?: string | null,
    type: SignatureDocumentType,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTeamSubscriptionVariables = {
  filter?: ModelSubscriptionTeamFilterInput | null,
};

export type OnCreateTeamSubscription = {
  onCreateTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    parentId?: string | null,
    children?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        parentId?: string | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users?:  {
      __typename: "ModelUserTeamConnection",
      items:  Array< {
        __typename: "UserTeam",
        id: string,
        userId: string,
        teamId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTeamSubscriptionVariables = {
  filter?: ModelSubscriptionTeamFilterInput | null,
};

export type OnUpdateTeamSubscription = {
  onUpdateTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    parentId?: string | null,
    children?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        parentId?: string | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users?:  {
      __typename: "ModelUserTeamConnection",
      items:  Array< {
        __typename: "UserTeam",
        id: string,
        userId: string,
        teamId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTeamSubscriptionVariables = {
  filter?: ModelSubscriptionTeamFilterInput | null,
};

export type OnDeleteTeamSubscription = {
  onDeleteTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    parentId?: string | null,
    children?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        parentId?: string | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    users?:  {
      __typename: "ModelUserTeamConnection",
      items:  Array< {
        __typename: "UserTeam",
        id: string,
        userId: string,
        teamId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTechnicSubscriptionVariables = {
  filter?: ModelSubscriptionTechnicFilterInput | null,
};

export type OnCreateTechnicSubscription = {
  onCreateTechnic?:  {
    __typename: "Technic",
    id: string,
    type: TechnicType,
    contractNumber?: string | null,
    communicationSettingsProfileId: string,
    maintenanceHistory?:  Array< {
      __typename: "TechnicMaintenanceHistory",
      date: string,
      contactId?: string | null,
      fileId?: string | null,
    } > | null,
    utilityType?: UtilityType | null,
    utilityEanCode?: string | null,
    utilityMeterNumber?: string | null,
    utilityDistributorId?: string | null,
    utilityDistributor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    utilityHistory?:  Array< {
      __typename: "UtilityHistory",
      date: string,
      reason?: UtilityReadingReason | null,
      reading: number,
      secondReading?: number | null,
    } > | null,
    PebValidityDate?: string | null,
    PebEnergeticPerformance?: string | null,
    PebReportNumber?: string | null,
    PebIssueDate?: string | null,
    detectorType?: DetectorType | null,
    detectorSerialNumber?: string | null,
    detectorBrand?: string | null,
    detectorLastControlDate?: string | null,
    detectorUnitInventoryId?: string | null,
    heatingBrand?: string | null,
    heatingModel?: string | null,
    heatingInstallationDate?: string | null,
    heatingType?: HeatingType | null,
    heatingLastControlDate?: string | null,
    tankType?: TankType | null,
    tankCapacity?: number | null,
    tankState?: TankState | null,
    tankLastControlDate?: string | null,
    tankLeakDetection?: boolean | null,
    tankEncuvement?: boolean | null,
    tankWallType?: TankWallType | null,
    tankInstallationDate?: string | null,
    chimneyLastControlDate?: string | null,
    chimneyUnitInventoryId?: string | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    originalTechnicId?: string | null,
    readers?: Array< string > | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTechnicSubscriptionVariables = {
  filter?: ModelSubscriptionTechnicFilterInput | null,
};

export type OnUpdateTechnicSubscription = {
  onUpdateTechnic?:  {
    __typename: "Technic",
    id: string,
    type: TechnicType,
    contractNumber?: string | null,
    communicationSettingsProfileId: string,
    maintenanceHistory?:  Array< {
      __typename: "TechnicMaintenanceHistory",
      date: string,
      contactId?: string | null,
      fileId?: string | null,
    } > | null,
    utilityType?: UtilityType | null,
    utilityEanCode?: string | null,
    utilityMeterNumber?: string | null,
    utilityDistributorId?: string | null,
    utilityDistributor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    utilityHistory?:  Array< {
      __typename: "UtilityHistory",
      date: string,
      reason?: UtilityReadingReason | null,
      reading: number,
      secondReading?: number | null,
    } > | null,
    PebValidityDate?: string | null,
    PebEnergeticPerformance?: string | null,
    PebReportNumber?: string | null,
    PebIssueDate?: string | null,
    detectorType?: DetectorType | null,
    detectorSerialNumber?: string | null,
    detectorBrand?: string | null,
    detectorLastControlDate?: string | null,
    detectorUnitInventoryId?: string | null,
    heatingBrand?: string | null,
    heatingModel?: string | null,
    heatingInstallationDate?: string | null,
    heatingType?: HeatingType | null,
    heatingLastControlDate?: string | null,
    tankType?: TankType | null,
    tankCapacity?: number | null,
    tankState?: TankState | null,
    tankLastControlDate?: string | null,
    tankLeakDetection?: boolean | null,
    tankEncuvement?: boolean | null,
    tankWallType?: TankWallType | null,
    tankInstallationDate?: string | null,
    chimneyLastControlDate?: string | null,
    chimneyUnitInventoryId?: string | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    originalTechnicId?: string | null,
    readers?: Array< string > | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTechnicSubscriptionVariables = {
  filter?: ModelSubscriptionTechnicFilterInput | null,
};

export type OnDeleteTechnicSubscription = {
  onDeleteTechnic?:  {
    __typename: "Technic",
    id: string,
    type: TechnicType,
    contractNumber?: string | null,
    communicationSettingsProfileId: string,
    maintenanceHistory?:  Array< {
      __typename: "TechnicMaintenanceHistory",
      date: string,
      contactId?: string | null,
      fileId?: string | null,
    } > | null,
    utilityType?: UtilityType | null,
    utilityEanCode?: string | null,
    utilityMeterNumber?: string | null,
    utilityDistributorId?: string | null,
    utilityDistributor?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    utilityHistory?:  Array< {
      __typename: "UtilityHistory",
      date: string,
      reason?: UtilityReadingReason | null,
      reading: number,
      secondReading?: number | null,
    } > | null,
    PebValidityDate?: string | null,
    PebEnergeticPerformance?: string | null,
    PebReportNumber?: string | null,
    PebIssueDate?: string | null,
    detectorType?: DetectorType | null,
    detectorSerialNumber?: string | null,
    detectorBrand?: string | null,
    detectorLastControlDate?: string | null,
    detectorUnitInventoryId?: string | null,
    heatingBrand?: string | null,
    heatingModel?: string | null,
    heatingInstallationDate?: string | null,
    heatingType?: HeatingType | null,
    heatingLastControlDate?: string | null,
    tankType?: TankType | null,
    tankCapacity?: number | null,
    tankState?: TankState | null,
    tankLastControlDate?: string | null,
    tankLeakDetection?: boolean | null,
    tankEncuvement?: boolean | null,
    tankWallType?: TankWallType | null,
    tankInstallationDate?: string | null,
    chimneyLastControlDate?: string | null,
    chimneyUnitInventoryId?: string | null,
    files?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    buildingId?: string | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    leaseId?: string | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    originalTechnicId?: string | null,
    readers?: Array< string > | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTicketSubscriptionVariables = {
  filter?: ModelSubscriptionTicketFilterInput | null,
};

export type OnCreateTicketSubscription = {
  onCreateTicket?:  {
    __typename: "Ticket",
    id: string,
    assignedAt?: string | null,
    assignedTo?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    assignedToId?: string | null,
    attachment?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    closedAt?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId: string,
    content: string,
    conversations?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        assignedAt?: string | null,
        assignedToId?: string | null,
        buildingId?: string | null,
        closedAt?: string | null,
        contactId: string,
        content: string,
        createdAt: string,
        emailMessageId?: string | null,
        emailReferences?: Array< string | null > | null,
        parentId?: string | null,
        plannedAt?: string | null,
        referenceCode?: string | null,
        sourceType: TicketSourceType,
        status?: TicketStatus | null,
        subject: string,
        unitId?: string | null,
        unread: boolean,
        updatedAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    emailMessageId?: string | null,
    emailReferences?: Array< string | null > | null,
    parentId?: string | null,
    plannedAt?: string | null,
    referenceCode?: string | null,
    sourceType: TicketSourceType,
    status?: TicketStatus | null,
    subject: string,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unread: boolean,
    updatedAt?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTicketSubscriptionVariables = {
  filter?: ModelSubscriptionTicketFilterInput | null,
};

export type OnUpdateTicketSubscription = {
  onUpdateTicket?:  {
    __typename: "Ticket",
    id: string,
    assignedAt?: string | null,
    assignedTo?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    assignedToId?: string | null,
    attachment?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    closedAt?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId: string,
    content: string,
    conversations?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        assignedAt?: string | null,
        assignedToId?: string | null,
        buildingId?: string | null,
        closedAt?: string | null,
        contactId: string,
        content: string,
        createdAt: string,
        emailMessageId?: string | null,
        emailReferences?: Array< string | null > | null,
        parentId?: string | null,
        plannedAt?: string | null,
        referenceCode?: string | null,
        sourceType: TicketSourceType,
        status?: TicketStatus | null,
        subject: string,
        unitId?: string | null,
        unread: boolean,
        updatedAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    emailMessageId?: string | null,
    emailReferences?: Array< string | null > | null,
    parentId?: string | null,
    plannedAt?: string | null,
    referenceCode?: string | null,
    sourceType: TicketSourceType,
    status?: TicketStatus | null,
    subject: string,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unread: boolean,
    updatedAt?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTicketSubscriptionVariables = {
  filter?: ModelSubscriptionTicketFilterInput | null,
};

export type OnDeleteTicketSubscription = {
  onDeleteTicket?:  {
    __typename: "Ticket",
    id: string,
    assignedAt?: string | null,
    assignedTo?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    assignedToId?: string | null,
    attachment?:  {
      __typename: "ModelFileConnection",
      items:  Array< {
        __typename: "File",
        id: string,
        bucket: string,
        createdAt?: string | null,
        categoryId: string,
        foreignKey: string,
        foreignTableName: string,
        key: string,
        mimeType?: string | null,
        region: string,
        size?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    buildingId?: string | null,
    closedAt?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId: string,
    content: string,
    conversations?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        assignedAt?: string | null,
        assignedToId?: string | null,
        buildingId?: string | null,
        closedAt?: string | null,
        contactId: string,
        content: string,
        createdAt: string,
        emailMessageId?: string | null,
        emailReferences?: Array< string | null > | null,
        parentId?: string | null,
        plannedAt?: string | null,
        referenceCode?: string | null,
        sourceType: TicketSourceType,
        status?: TicketStatus | null,
        subject: string,
        unitId?: string | null,
        unread: boolean,
        updatedAt?: string | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    emailMessageId?: string | null,
    emailReferences?: Array< string | null > | null,
    parentId?: string | null,
    plannedAt?: string | null,
    referenceCode?: string | null,
    sourceType: TicketSourceType,
    status?: TicketStatus | null,
    subject: string,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId?: string | null,
    unread: boolean,
    updatedAt?: string | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUnitSubscriptionVariables = {
  filter?: ModelSubscriptionUnitFilterInput | null,
};

export type OnCreateUnitSubscription = {
  onCreateUnit?:  {
    __typename: "Unit",
    id: string,
    acquisitionAdditionalCostsValue?: number | null,
    acquisitionAdvertisedValue?: number | null,
    acquisitionValue?: number | null,
    advertisedMonthlyCharges?: number | null,
    advertisedRentalPrice?: number | null,
    cadastralIncome?: number | null,
    createdAt?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    floor?: number | null,
    jointOwnershipId?: string | null,
    managementDate: string,
    name: string,
    predictedPrice?: string | null,
    propertyTax?: number | null,
    publishAddress?: boolean | null,
    surface?: number | null,
    type: UnitType,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owners?:  {
      __typename: "ModelUnitOwnerConnection",
      items:  Array< {
        __typename: "UnitOwner",
        id: string,
        disabled?: boolean | null,
        ownerId: string,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leases?:  {
      __typename: "ModelUnitLeaseConnection",
      items:  Array< {
        __typename: "UnitLease",
        id: string,
        allowChargeAdjustment?: boolean | null,
        authorizeProfessionalActivity?: boolean | null,
        chargesIndexation?: boolean | null,
        createdAt?: string | null,
        endDate: string,
        furnishedRental?: boolean | null,
        furnishedRentalInsuranceByTenant?: boolean | null,
        furnishedRentalRentalPricePercentage?: number | null,
        initialMonthlyCharges: number,
        initialRentalPrice: number,
        indexation?: boolean | null,
        advancedIndexation?: boolean | null,
        indexType?: IndexType | null,
        baseYear?: number | null,
        baseIndexType?: BaseIndexType | null,
        baseIndexDate?: string | null,
        leaseId: string,
        mainUnit: boolean,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        monthlyCharges: number,
        rentalPrice: number,
        signatureDate?: string | null,
        startDate: string,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelUnitEventConnection",
      items:  Array< {
        __typename: "UnitEvent",
        id: string,
        unitId: string,
        type: UnitEventType,
        startDate: string,
        endDate: string,
        totalCost?: number | null,
        note?: string | null,
        writers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    unitAdvertisementId?: string | null,
    unitInventories?:  {
      __typename: "ModelUnitInventoryConnection",
      items:  Array< {
        __typename: "UnitInventory",
        id: string,
        privateBatthRoom?: boolean | null,
        createdAt?: string | null,
        deletedAt?: string | null,
        kitchenEquipment?: KitchenEquipment | null,
        roomName?: string | null,
        roomType: UnitInventoryRoomType,
        surface?: number | null,
        showSurface?: boolean | null,
        unitId: string,
        inside: boolean,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUnitSubscriptionVariables = {
  filter?: ModelSubscriptionUnitFilterInput | null,
};

export type OnUpdateUnitSubscription = {
  onUpdateUnit?:  {
    __typename: "Unit",
    id: string,
    acquisitionAdditionalCostsValue?: number | null,
    acquisitionAdvertisedValue?: number | null,
    acquisitionValue?: number | null,
    advertisedMonthlyCharges?: number | null,
    advertisedRentalPrice?: number | null,
    cadastralIncome?: number | null,
    createdAt?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    floor?: number | null,
    jointOwnershipId?: string | null,
    managementDate: string,
    name: string,
    predictedPrice?: string | null,
    propertyTax?: number | null,
    publishAddress?: boolean | null,
    surface?: number | null,
    type: UnitType,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owners?:  {
      __typename: "ModelUnitOwnerConnection",
      items:  Array< {
        __typename: "UnitOwner",
        id: string,
        disabled?: boolean | null,
        ownerId: string,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leases?:  {
      __typename: "ModelUnitLeaseConnection",
      items:  Array< {
        __typename: "UnitLease",
        id: string,
        allowChargeAdjustment?: boolean | null,
        authorizeProfessionalActivity?: boolean | null,
        chargesIndexation?: boolean | null,
        createdAt?: string | null,
        endDate: string,
        furnishedRental?: boolean | null,
        furnishedRentalInsuranceByTenant?: boolean | null,
        furnishedRentalRentalPricePercentage?: number | null,
        initialMonthlyCharges: number,
        initialRentalPrice: number,
        indexation?: boolean | null,
        advancedIndexation?: boolean | null,
        indexType?: IndexType | null,
        baseYear?: number | null,
        baseIndexType?: BaseIndexType | null,
        baseIndexDate?: string | null,
        leaseId: string,
        mainUnit: boolean,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        monthlyCharges: number,
        rentalPrice: number,
        signatureDate?: string | null,
        startDate: string,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelUnitEventConnection",
      items:  Array< {
        __typename: "UnitEvent",
        id: string,
        unitId: string,
        type: UnitEventType,
        startDate: string,
        endDate: string,
        totalCost?: number | null,
        note?: string | null,
        writers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    unitAdvertisementId?: string | null,
    unitInventories?:  {
      __typename: "ModelUnitInventoryConnection",
      items:  Array< {
        __typename: "UnitInventory",
        id: string,
        privateBatthRoom?: boolean | null,
        createdAt?: string | null,
        deletedAt?: string | null,
        kitchenEquipment?: KitchenEquipment | null,
        roomName?: string | null,
        roomType: UnitInventoryRoomType,
        surface?: number | null,
        showSurface?: boolean | null,
        unitId: string,
        inside: boolean,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUnitSubscriptionVariables = {
  filter?: ModelSubscriptionUnitFilterInput | null,
};

export type OnDeleteUnitSubscription = {
  onDeleteUnit?:  {
    __typename: "Unit",
    id: string,
    acquisitionAdditionalCostsValue?: number | null,
    acquisitionAdvertisedValue?: number | null,
    acquisitionValue?: number | null,
    advertisedMonthlyCharges?: number | null,
    advertisedRentalPrice?: number | null,
    cadastralIncome?: number | null,
    createdAt?: string | null,
    customValues?:  Array< {
      __typename: "CustomValue",
      name: string,
      value: string,
    } > | null,
    floor?: number | null,
    jointOwnershipId?: string | null,
    managementDate: string,
    name: string,
    predictedPrice?: string | null,
    propertyTax?: number | null,
    publishAddress?: boolean | null,
    surface?: number | null,
    type: UnitType,
    buildingId: string,
    building?:  {
      __typename: "Building",
      id: string,
      name: string,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      acquisitionValue?: number | null,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      amountOfFacades?: number | null,
      amountOfFloors?: number | null,
      amountOfHousings?: number | null,
      buildingState?: BuildingState | null,
      cadastralIncome?: number | null,
      constructionYear?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      janitor?: boolean | null,
      jointOwnershipId?: string | null,
      owners?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      propertyTax?: number | null,
      residenceName?: string | null,
      units?:  {
        __typename: "ModelUnitConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelBuildingEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      teams?: Array< string > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    owners?:  {
      __typename: "ModelUnitOwnerConnection",
      items:  Array< {
        __typename: "UnitOwner",
        id: string,
        disabled?: boolean | null,
        ownerId: string,
        periodFrom?: string | null,
        periodTo?: string | null,
        stake: number,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    leases?:  {
      __typename: "ModelUnitLeaseConnection",
      items:  Array< {
        __typename: "UnitLease",
        id: string,
        allowChargeAdjustment?: boolean | null,
        authorizeProfessionalActivity?: boolean | null,
        chargesIndexation?: boolean | null,
        createdAt?: string | null,
        endDate: string,
        furnishedRental?: boolean | null,
        furnishedRentalInsuranceByTenant?: boolean | null,
        furnishedRentalRentalPricePercentage?: number | null,
        initialMonthlyCharges: number,
        initialRentalPrice: number,
        indexation?: boolean | null,
        advancedIndexation?: boolean | null,
        indexType?: IndexType | null,
        baseYear?: number | null,
        baseIndexType?: BaseIndexType | null,
        baseIndexDate?: string | null,
        leaseId: string,
        mainUnit: boolean,
        monthlyChargesType?: LeaseMonthlyChargesType | null,
        monthlyCharges: number,
        rentalPrice: number,
        signatureDate?: string | null,
        startDate: string,
        unitId: string,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    events?:  {
      __typename: "ModelUnitEventConnection",
      items:  Array< {
        __typename: "UnitEvent",
        id: string,
        unitId: string,
        type: UnitEventType,
        startDate: string,
        endDate: string,
        totalCost?: number | null,
        note?: string | null,
        writers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    unitAdvertisementId?: string | null,
    unitInventories?:  {
      __typename: "ModelUnitInventoryConnection",
      items:  Array< {
        __typename: "UnitInventory",
        id: string,
        privateBatthRoom?: boolean | null,
        createdAt?: string | null,
        deletedAt?: string | null,
        kitchenEquipment?: KitchenEquipment | null,
        roomName?: string | null,
        roomType: UnitInventoryRoomType,
        surface?: number | null,
        showSurface?: boolean | null,
        unitId: string,
        inside: boolean,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUnitEventSubscriptionVariables = {
  filter?: ModelSubscriptionUnitEventFilterInput | null,
};

export type OnCreateUnitEventSubscription = {
  onCreateUnitEvent?:  {
    __typename: "UnitEvent",
    id: string,
    unitId: string,
    type: UnitEventType,
    startDate: string,
    endDate: string,
    totalCost?: number | null,
    note?: string | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUnitEventSubscriptionVariables = {
  filter?: ModelSubscriptionUnitEventFilterInput | null,
};

export type OnUpdateUnitEventSubscription = {
  onUpdateUnitEvent?:  {
    __typename: "UnitEvent",
    id: string,
    unitId: string,
    type: UnitEventType,
    startDate: string,
    endDate: string,
    totalCost?: number | null,
    note?: string | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUnitEventSubscriptionVariables = {
  filter?: ModelSubscriptionUnitEventFilterInput | null,
};

export type OnDeleteUnitEventSubscription = {
  onDeleteUnitEvent?:  {
    __typename: "UnitEvent",
    id: string,
    unitId: string,
    type: UnitEventType,
    startDate: string,
    endDate: string,
    totalCost?: number | null,
    note?: string | null,
    writers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUnitInventorySubscriptionVariables = {
  filter?: ModelSubscriptionUnitInventoryFilterInput | null,
};

export type OnCreateUnitInventorySubscription = {
  onCreateUnitInventory?:  {
    __typename: "UnitInventory",
    id: string,
    privateBatthRoom?: boolean | null,
    createdAt?: string | null,
    deletedAt?: string | null,
    kitchenEquipment?: KitchenEquipment | null,
    roomName?: string | null,
    roomType: UnitInventoryRoomType,
    surface?: number | null,
    showSurface?: boolean | null,
    unitId: string,
    inside: boolean,
    structures?:  {
      __typename: "ModelUnitInventoryStructureConnection",
      items:  Array< {
        __typename: "UnitInventoryStructure",
        id: string,
        createdAt?: string | null,
        deletedAt?: string | null,
        unitInventoryId: string,
        type: UnitInventoryStructureType,
        name: string,
        brand?: string | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUnitInventorySubscriptionVariables = {
  filter?: ModelSubscriptionUnitInventoryFilterInput | null,
};

export type OnUpdateUnitInventorySubscription = {
  onUpdateUnitInventory?:  {
    __typename: "UnitInventory",
    id: string,
    privateBatthRoom?: boolean | null,
    createdAt?: string | null,
    deletedAt?: string | null,
    kitchenEquipment?: KitchenEquipment | null,
    roomName?: string | null,
    roomType: UnitInventoryRoomType,
    surface?: number | null,
    showSurface?: boolean | null,
    unitId: string,
    inside: boolean,
    structures?:  {
      __typename: "ModelUnitInventoryStructureConnection",
      items:  Array< {
        __typename: "UnitInventoryStructure",
        id: string,
        createdAt?: string | null,
        deletedAt?: string | null,
        unitInventoryId: string,
        type: UnitInventoryStructureType,
        name: string,
        brand?: string | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUnitInventorySubscriptionVariables = {
  filter?: ModelSubscriptionUnitInventoryFilterInput | null,
};

export type OnDeleteUnitInventorySubscription = {
  onDeleteUnitInventory?:  {
    __typename: "UnitInventory",
    id: string,
    privateBatthRoom?: boolean | null,
    createdAt?: string | null,
    deletedAt?: string | null,
    kitchenEquipment?: KitchenEquipment | null,
    roomName?: string | null,
    roomType: UnitInventoryRoomType,
    surface?: number | null,
    showSurface?: boolean | null,
    unitId: string,
    inside: boolean,
    structures?:  {
      __typename: "ModelUnitInventoryStructureConnection",
      items:  Array< {
        __typename: "UnitInventoryStructure",
        id: string,
        createdAt?: string | null,
        deletedAt?: string | null,
        unitInventoryId: string,
        type: UnitInventoryStructureType,
        name: string,
        brand?: string | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUnitInventoryStructureSubscriptionVariables = {
  filter?: ModelSubscriptionUnitInventoryStructureFilterInput | null,
};

export type OnCreateUnitInventoryStructureSubscription = {
  onCreateUnitInventoryStructure?:  {
    __typename: "UnitInventoryStructure",
    id: string,
    createdAt?: string | null,
    deletedAt?: string | null,
    unitInventoryId: string,
    encodings?:  {
      __typename: "ModelLeaseInventoryEncodingConnection",
      items:  Array< {
        __typename: "LeaseInventoryEncoding",
        id: string,
        leaseInventoryId: string,
        structureId: string,
        state?: LeaseInventoryStructureStateType | null,
        note?: string | null,
        furnitureQuantity?: number | null,
        furniturePrice?: number | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    type: UnitInventoryStructureType,
    name: string,
    brand?: string | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUnitInventoryStructureSubscriptionVariables = {
  filter?: ModelSubscriptionUnitInventoryStructureFilterInput | null,
};

export type OnUpdateUnitInventoryStructureSubscription = {
  onUpdateUnitInventoryStructure?:  {
    __typename: "UnitInventoryStructure",
    id: string,
    createdAt?: string | null,
    deletedAt?: string | null,
    unitInventoryId: string,
    encodings?:  {
      __typename: "ModelLeaseInventoryEncodingConnection",
      items:  Array< {
        __typename: "LeaseInventoryEncoding",
        id: string,
        leaseInventoryId: string,
        structureId: string,
        state?: LeaseInventoryStructureStateType | null,
        note?: string | null,
        furnitureQuantity?: number | null,
        furniturePrice?: number | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    type: UnitInventoryStructureType,
    name: string,
    brand?: string | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUnitInventoryStructureSubscriptionVariables = {
  filter?: ModelSubscriptionUnitInventoryStructureFilterInput | null,
};

export type OnDeleteUnitInventoryStructureSubscription = {
  onDeleteUnitInventoryStructure?:  {
    __typename: "UnitInventoryStructure",
    id: string,
    createdAt?: string | null,
    deletedAt?: string | null,
    unitInventoryId: string,
    encodings?:  {
      __typename: "ModelLeaseInventoryEncodingConnection",
      items:  Array< {
        __typename: "LeaseInventoryEncoding",
        id: string,
        leaseInventoryId: string,
        structureId: string,
        state?: LeaseInventoryStructureStateType | null,
        note?: string | null,
        furnitureQuantity?: number | null,
        furniturePrice?: number | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    type: UnitInventoryStructureType,
    name: string,
    brand?: string | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUnitLeaseSubscriptionVariables = {
  filter?: ModelSubscriptionUnitLeaseFilterInput | null,
};

export type OnCreateUnitLeaseSubscription = {
  onCreateUnitLease?:  {
    __typename: "UnitLease",
    id: string,
    allowChargeAdjustment?: boolean | null,
    authorizeProfessionalActivity?: boolean | null,
    chargesIndexation?: boolean | null,
    createdAt?: string | null,
    endDate: string,
    furnishedRental?: boolean | null,
    furnishedRentalInsuranceByTenant?: boolean | null,
    furnishedRentalRentalPricePercentage?: number | null,
    initialMonthlyCharges: number,
    initialRentalPrice: number,
    indexation?: boolean | null,
    advancedIndexation?: boolean | null,
    indexType?: IndexType | null,
    baseYear?: number | null,
    baseIndexType?: BaseIndexType | null,
    baseIndexDate?: string | null,
    leaseId: string,
    mainUnit: boolean,
    monthlyChargesType?: LeaseMonthlyChargesType | null,
    monthlyCharges: number,
    rentalPrice: number,
    signatureDate?: string | null,
    startDate: string,
    unitId: string,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUnitLeaseSubscriptionVariables = {
  filter?: ModelSubscriptionUnitLeaseFilterInput | null,
};

export type OnUpdateUnitLeaseSubscription = {
  onUpdateUnitLease?:  {
    __typename: "UnitLease",
    id: string,
    allowChargeAdjustment?: boolean | null,
    authorizeProfessionalActivity?: boolean | null,
    chargesIndexation?: boolean | null,
    createdAt?: string | null,
    endDate: string,
    furnishedRental?: boolean | null,
    furnishedRentalInsuranceByTenant?: boolean | null,
    furnishedRentalRentalPricePercentage?: number | null,
    initialMonthlyCharges: number,
    initialRentalPrice: number,
    indexation?: boolean | null,
    advancedIndexation?: boolean | null,
    indexType?: IndexType | null,
    baseYear?: number | null,
    baseIndexType?: BaseIndexType | null,
    baseIndexDate?: string | null,
    leaseId: string,
    mainUnit: boolean,
    monthlyChargesType?: LeaseMonthlyChargesType | null,
    monthlyCharges: number,
    rentalPrice: number,
    signatureDate?: string | null,
    startDate: string,
    unitId: string,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUnitLeaseSubscriptionVariables = {
  filter?: ModelSubscriptionUnitLeaseFilterInput | null,
};

export type OnDeleteUnitLeaseSubscription = {
  onDeleteUnitLease?:  {
    __typename: "UnitLease",
    id: string,
    allowChargeAdjustment?: boolean | null,
    authorizeProfessionalActivity?: boolean | null,
    chargesIndexation?: boolean | null,
    createdAt?: string | null,
    endDate: string,
    furnishedRental?: boolean | null,
    furnishedRentalInsuranceByTenant?: boolean | null,
    furnishedRentalRentalPricePercentage?: number | null,
    initialMonthlyCharges: number,
    initialRentalPrice: number,
    indexation?: boolean | null,
    advancedIndexation?: boolean | null,
    indexType?: IndexType | null,
    baseYear?: number | null,
    baseIndexType?: BaseIndexType | null,
    baseIndexDate?: string | null,
    leaseId: string,
    mainUnit: boolean,
    monthlyChargesType?: LeaseMonthlyChargesType | null,
    monthlyCharges: number,
    rentalPrice: number,
    signatureDate?: string | null,
    startDate: string,
    unitId: string,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lease?:  {
      __typename: "Lease",
      id: string,
      balance?: number | null,
      canBeExtended?: boolean | null,
      communicationSettingsProfileId: string,
      createdAt?: string | null,
      endDate: string,
      includeRealEstateInLeaseContract?: boolean | null,
      invoiceSenderId: string,
      invoiceSender?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      inventoryOfFixturesExpert?: string | null,
      inventoryOfFixturesMethod?: InventoryOfFixturesMethod | null,
      inventoryOfFixturesType?: InventoryOfFixturesType | null,
      ipiNumber?: string | null,
      language: Language,
      lastIndexationProcessDate?: string | null,
      lastInvoiceDate?: string | null,
      legalCompetentAuthority?: string | null,
      name: string,
      paymentFrequency: LeasePaymentFrequency,
      paymentInvoicePeriod: LeasePaymentInvoicePeriod,
      paymentInvoicePeriodCustomDay?: number | null,
      realEstateAgency?: string | null,
      realEstateTax?: number | null,
      remittanceInformation?: string | null,
      rentalGuarantyAmount?: number | null,
      rentalGuarantyFinancialInstitution?: string | null,
      rentalGuarantyType?: LeaseRentalGuarantyType | null,
      signatureDate?: string | null,
      startDate: string,
      status: LeaseStatus,
      technicCommunicationSettingsProfileId: string,
      totalInitialMonthlyCharges: number,
      totalInitialRentalPrice: number,
      totalMonthlyCharges: number,
      totalRentalPrice: number,
      type: LeaseType,
      vatRateCharge?: number | null,
      vatRateRent?: number | null,
      additionalClauses?:  {
        __typename: "ModelLeaseAdditionalClauseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      bankAccountId?: string | null,
      bankAccount?:  {
        __typename: "BankAccount",
        id: string,
        contactId: string,
        number: string,
        bicNumber?: string | null,
        bankName?: string | null,
        disabled?: boolean | null,
        integrations?: Array< IntegrationName > | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contacts?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      files?:  {
        __typename: "ModelFileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseActionHistories?:  {
        __typename: "ModelLeaseActionHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leaseInventories?:  {
        __typename: "ModelLeaseInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leasePriceHistories?:  {
        __typename: "ModelLeasePriceHistoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      signatureDocuments?:  {
        __typename: "ModelSignatureDocumentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      variousOperations?:  {
        __typename: "ModelLeaseVariousOperationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUnitOwnerSubscriptionVariables = {
  filter?: ModelSubscriptionUnitOwnerFilterInput | null,
};

export type OnCreateUnitOwnerSubscription = {
  onCreateUnitOwner?:  {
    __typename: "UnitOwner",
    id: string,
    disabled?: boolean | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ownerId: string,
    periodFrom?: string | null,
    periodTo?: string | null,
    stake: number,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId: string,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUnitOwnerSubscriptionVariables = {
  filter?: ModelSubscriptionUnitOwnerFilterInput | null,
};

export type OnUpdateUnitOwnerSubscription = {
  onUpdateUnitOwner?:  {
    __typename: "UnitOwner",
    id: string,
    disabled?: boolean | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ownerId: string,
    periodFrom?: string | null,
    periodTo?: string | null,
    stake: number,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId: string,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUnitOwnerSubscriptionVariables = {
  filter?: ModelSubscriptionUnitOwnerFilterInput | null,
};

export type OnDeleteUnitOwnerSubscription = {
  onDeleteUnitOwner?:  {
    __typename: "UnitOwner",
    id: string,
    disabled?: boolean | null,
    owner?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    ownerId: string,
    periodFrom?: string | null,
    periodTo?: string | null,
    stake: number,
    unit?:  {
      __typename: "Unit",
      id: string,
      acquisitionAdditionalCostsValue?: number | null,
      acquisitionAdvertisedValue?: number | null,
      acquisitionValue?: number | null,
      advertisedMonthlyCharges?: number | null,
      advertisedRentalPrice?: number | null,
      cadastralIncome?: number | null,
      createdAt?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      floor?: number | null,
      jointOwnershipId?: string | null,
      managementDate: string,
      name: string,
      predictedPrice?: string | null,
      propertyTax?: number | null,
      publishAddress?: boolean | null,
      surface?: number | null,
      type: UnitType,
      buildingId: string,
      building?:  {
        __typename: "Building",
        id: string,
        name: string,
        addressId?: string | null,
        acquisitionValue?: number | null,
        acquisitionAdditionalCostsValue?: number | null,
        acquisitionAdvertisedValue?: number | null,
        amountOfFacades?: number | null,
        amountOfFloors?: number | null,
        amountOfHousings?: number | null,
        buildingState?: BuildingState | null,
        cadastralIncome?: number | null,
        constructionYear?: string | null,
        janitor?: boolean | null,
        jointOwnershipId?: string | null,
        propertyTax?: number | null,
        residenceName?: string | null,
        readers?: Array< string > | null,
        teams?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      owners?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      leases?:  {
        __typename: "ModelUnitLeaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      events?:  {
        __typename: "ModelUnitEventConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      unitAdvertisementId?: string | null,
      unitInventories?:  {
        __typename: "ModelUnitInventoryConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    unitId: string,
    readers?: Array< string > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    cognitoSub?: string | null,
    googleSub?: string | null,
    facebookSub?: string | null,
    rootUser?: boolean | null,
    refreshToken?: boolean | null,
    teams?:  {
      __typename: "ModelUserTeamConnection",
      items:  Array< {
        __typename: "UserTeam",
        id: string,
        userId: string,
        teamId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userRoleId?: string | null,
    userRole?:  {
      __typename: "UserRole",
      id: string,
      name: string,
      userRights?:  Array< {
        __typename: "UserRight",
        access?: Array< UserRightAccessType > | null,
        section: UserRightSection,
      } > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status: UserStatus,
    readId: string,
    clientId: string,
    _ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    cognitoSub?: string | null,
    googleSub?: string | null,
    facebookSub?: string | null,
    rootUser?: boolean | null,
    refreshToken?: boolean | null,
    teams?:  {
      __typename: "ModelUserTeamConnection",
      items:  Array< {
        __typename: "UserTeam",
        id: string,
        userId: string,
        teamId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userRoleId?: string | null,
    userRole?:  {
      __typename: "UserRole",
      id: string,
      name: string,
      userRights?:  Array< {
        __typename: "UserRight",
        access?: Array< UserRightAccessType > | null,
        section: UserRightSection,
      } > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status: UserStatus,
    readId: string,
    clientId: string,
    _ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null,
    cognitoSub?: string | null,
    googleSub?: string | null,
    facebookSub?: string | null,
    rootUser?: boolean | null,
    refreshToken?: boolean | null,
    teams?:  {
      __typename: "ModelUserTeamConnection",
      items:  Array< {
        __typename: "UserTeam",
        id: string,
        userId: string,
        teamId: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userRoleId?: string | null,
    userRole?:  {
      __typename: "UserRole",
      id: string,
      name: string,
      userRights?:  Array< {
        __typename: "UserRight",
        access?: Array< UserRightAccessType > | null,
        section: UserRightSection,
      } > | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    contactId?: string | null,
    contact?:  {
      __typename: "Contact",
      id: string,
      createdAt?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      companyName?: string | null,
      commercialName?: string | null,
      communicationSettingsProfileId?: string | null,
      customValues?:  Array< {
        __typename: "CustomValue",
        name: string,
        value: string,
      } > | null,
      businessNumber?: string | null,
      types: Array< ContactType >,
      vat?: string | null,
      email?: string | null,
      birthDate?: string | null,
      birthPlace?: string | null,
      nationalRegister?: string | null,
      civility?: CivilityType | null,
      addressId?: string | null,
      address?:  {
        __typename: "Address",
        id: string,
        status?: AddressStatus | null,
        street: string,
        number?: string | null,
        box?: string | null,
        postalCode?: string | null,
        city?: string | null,
        region?: string | null,
        country?: string | null,
        latitude?: number | null,
        longitude?: number | null,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      phoneNumbers?:  Array< {
        __typename: "PhoneNumber",
        countryCode: string,
        number: string,
      } > | null,
      language?: string | null,
      jointOwners?:  Array< {
        __typename: "JointOwner",
        contactId: string,
        contactPerson?: boolean | null,
      } > | null,
      agencyRateDisabled?: boolean | null,
      status?: ContactStatus | null,
      leases?:  {
        __typename: "ModelLeaseContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      buildings?:  {
        __typename: "ModelBuildingOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      units?:  {
        __typename: "ModelUnitOwnerConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sendInvitation: boolean,
      readers?: Array< string > | null,
      readId: string,
      clientId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    status: UserStatus,
    readId: string,
    clientId: string,
    _ttl?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserRoleSubscriptionVariables = {
  filter?: ModelSubscriptionUserRoleFilterInput | null,
};

export type OnCreateUserRoleSubscription = {
  onCreateUserRole?:  {
    __typename: "UserRole",
    id: string,
    name: string,
    userRights?:  Array< {
      __typename: "UserRight",
      access?: Array< UserRightAccessType > | null,
      section: UserRightSection,
    } > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserRoleSubscriptionVariables = {
  filter?: ModelSubscriptionUserRoleFilterInput | null,
};

export type OnUpdateUserRoleSubscription = {
  onUpdateUserRole?:  {
    __typename: "UserRole",
    id: string,
    name: string,
    userRights?:  Array< {
      __typename: "UserRight",
      access?: Array< UserRightAccessType > | null,
      section: UserRightSection,
    } > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserRoleSubscriptionVariables = {
  filter?: ModelSubscriptionUserRoleFilterInput | null,
};

export type OnDeleteUserRoleSubscription = {
  onDeleteUserRole?:  {
    __typename: "UserRole",
    id: string,
    name: string,
    userRights?:  Array< {
      __typename: "UserRight",
      access?: Array< UserRightAccessType > | null,
      section: UserRightSection,
    } > | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserTeamSubscriptionVariables = {
  filter?: ModelSubscriptionUserTeamFilterInput | null,
};

export type OnCreateUserTeamSubscription = {
  onCreateUserTeam?:  {
    __typename: "UserTeam",
    id: string,
    userId: string,
    teamId: string,
    team?:  {
      __typename: "Team",
      id: string,
      name: string,
      parentId?: string | null,
      children?:  {
        __typename: "ModelTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoSub?: string | null,
      googleSub?: string | null,
      facebookSub?: string | null,
      rootUser?: boolean | null,
      refreshToken?: boolean | null,
      teams?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userRoleId?: string | null,
      userRole?:  {
        __typename: "UserRole",
        id: string,
        name: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status: UserStatus,
      readId: string,
      clientId: string,
      _ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserTeamSubscriptionVariables = {
  filter?: ModelSubscriptionUserTeamFilterInput | null,
};

export type OnUpdateUserTeamSubscription = {
  onUpdateUserTeam?:  {
    __typename: "UserTeam",
    id: string,
    userId: string,
    teamId: string,
    team?:  {
      __typename: "Team",
      id: string,
      name: string,
      parentId?: string | null,
      children?:  {
        __typename: "ModelTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoSub?: string | null,
      googleSub?: string | null,
      facebookSub?: string | null,
      rootUser?: boolean | null,
      refreshToken?: boolean | null,
      teams?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userRoleId?: string | null,
      userRole?:  {
        __typename: "UserRole",
        id: string,
        name: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status: UserStatus,
      readId: string,
      clientId: string,
      _ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserTeamSubscriptionVariables = {
  filter?: ModelSubscriptionUserTeamFilterInput | null,
};

export type OnDeleteUserTeamSubscription = {
  onDeleteUserTeam?:  {
    __typename: "UserTeam",
    id: string,
    userId: string,
    teamId: string,
    team?:  {
      __typename: "Team",
      id: string,
      name: string,
      parentId?: string | null,
      children?:  {
        __typename: "ModelTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      users?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      readId: string,
      clientId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName?: string | null,
      lastName?: string | null,
      cognitoSub?: string | null,
      googleSub?: string | null,
      facebookSub?: string | null,
      rootUser?: boolean | null,
      refreshToken?: boolean | null,
      teams?:  {
        __typename: "ModelUserTeamConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      userRoleId?: string | null,
      userRole?:  {
        __typename: "UserRole",
        id: string,
        name: string,
        readId: string,
        clientId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      contactId?: string | null,
      contact?:  {
        __typename: "Contact",
        id: string,
        createdAt?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        companyName?: string | null,
        commercialName?: string | null,
        communicationSettingsProfileId?: string | null,
        businessNumber?: string | null,
        types: Array< ContactType >,
        vat?: string | null,
        email?: string | null,
        birthDate?: string | null,
        birthPlace?: string | null,
        nationalRegister?: string | null,
        civility?: CivilityType | null,
        addressId?: string | null,
        language?: string | null,
        agencyRateDisabled?: boolean | null,
        status?: ContactStatus | null,
        sendInvitation: boolean,
        readers?: Array< string > | null,
        readId: string,
        clientId: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      status: UserStatus,
      readId: string,
      clientId: string,
      _ttl?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    readId: string,
    clientId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
