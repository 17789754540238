import { Grid, MenuItem, Typography } from '@material-ui/core';
import { getTranslatedVatRates, NOT_APPLICABLE, Setting } from '@rentguru/commons-utils';
import { CustomSimpleDialog } from '@up2rent/ui';
import { Form, Formik, FormikHelpers } from 'formik';
import isNil from 'lodash/isNil';
import React from 'react';
import { useIntl } from 'react-intl';
import { useVatRates } from 'src/hooks/VatRatesContext';
import FormikCustomizedSelect from '../FormikCustomizedSelect';

interface SelectVAtRateValue {
  vatRate: number | undefined | null;
}

interface VATSelectorDialogProps {
  originalVatSetting: Setting;
  open: boolean;
  title?: string;
  onClose: () => void;
  afterSubmit?: (vatRate: number | undefined | null) => void;
  updateSetting: (original: Setting, updates: Partial<Setting>) => Promise<Setting>;
}
const VATSelectorDialog: React.FC<VATSelectorDialogProps> = ({
  originalVatSetting,
  open,
  onClose,
  afterSubmit,
  title,
  updateSetting,
}) => {
  const { formatMessage } = useIntl();
  const { vatRates, vatRatesLoading } = useVatRates(true);

  const currentVatRate =
    !isNil(originalVatSetting.value) && !isNaN(Number(originalVatSetting.value))
      ? Number(originalVatSetting.value)
      : null;

  const handleSubmit = async (
    values: SelectVAtRateValue,
    { setSubmitting, setStatus }: FormikHelpers<SelectVAtRateValue>
  ) => {
    const { vatRate } = values;
    if (vatRate !== currentVatRate) {
      const newValue = !isNil(vatRate) ? String(vatRate) : NOT_APPLICABLE;
      await updateSetting(originalVatSetting, { value: newValue });
      if (afterSubmit) {
        afterSubmit(vatRate);
      }
    }
    setStatus(true);
    setSubmitting(false);
    onClose();
  };

  const country = 'BE';
  // TODO filter by correct country
  const vatRatesToPropose = getTranslatedVatRates(vatRates, formatMessage, country, true);
  return (
    <>
      {vatRatesLoading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography style={{ fontSize: 14 }}>{formatMessage({ id: 'loading' })}</Typography>
        </div>
      )}
      {!vatRatesLoading && (
        <Formik
          initialValues={
            {
              vatRate: currentVatRate,
            } as SelectVAtRateValue
          }
          onSubmit={handleSubmit}
        >
          {({ values, errors, isSubmitting, handleSubmit: onActionButtonClick }) => {
            return (
              <Form>
                <CustomSimpleDialog
                  open={open}
                  onClose={onClose}
                  onActionButtonClick={onActionButtonClick}
                  actionButtonLabel={formatMessage({ id: 'save' })}
                  actionButtonLoading={isSubmitting}
                  dividerBelowTitle
                  title={!isNil(title) ? title : formatMessage({ id: 'accounting.vat.selectDefault' })}
                  formatMessage={formatMessage}
                >
                  <Grid style={{ marginTop: 10 }}>
                    <FormikCustomizedSelect
                      label={formatMessage({ id: 'accounting.charge.affectCharge.vatRate' })}
                      fieldName="vatRate"
                      value={values.vatRate}
                      FormControlProps={{ required: true, style: { width: 520, marginLeft: 20 } }}
                      error={Boolean(errors.vatRate)}
                    >
                      {vatRatesToPropose.map((mi) => (
                        <MenuItem value={mi.value ?? mi.label ?? ''} key={mi.value}>
                          {mi.label}
                        </MenuItem>
                      ))}
                    </FormikCustomizedSelect>
                  </Grid>
                </CustomSimpleDialog>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default VATSelectorDialog;
