import { makeStyles } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';

export const commonTextStyles = {
  fontSize: 12,
  color: Colors.SLATE_GREY,
};

export const calculationStyles = makeStyles({
  table: {
    marginTop: 25,
    border: `1px solid ${Colors.GEYSER_GREY}`,
    borderRadius: 5,
    width: '100%',
    display: 'table',
    tableLayout: 'fixed',
  },
  header: {
    backgroundColor: Colors.PORCELAIN_GREY_3,
  },
  headerText: {
    ...commonTextStyles,
  },
  descriptionText: {
    ...commonTextStyles,
    fontWeight: 'normal',
    width: 'auto',
    textAlign: 'left',
  },
  calculationText: {
    ...commonTextStyles,
    fontWeight: 'normal',
    width: 'auto',
    textAlign: 'center',
  },
  resultText: {
    fontSize: 14,
    color: Colors.SLATE_GREY,
    fontWeight: 'bold',
    textAlign: 'right',
    width: 'auto',
  },
  formulaDenominatorGrid: {
    width: '100%',
  },
  formulaText: {
    fontSize: 14,
    color: Colors.SLATE_GREY,
    fontWeight: 'normal',
    width: '100%',
    textAlign: 'center',
  },
  formulaNumeratorGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
  },
});

export const unitHeaderStyles = makeStyles({
  mainGridTitle: {
    height: 75,
    paddingLeft: 30,
    display: 'flex',
    alignItems: 'center',
  },
  mainGridSecondaryTitle: {
    marginBottom: 10,
    paddingLeft: 30,
    display: 'flex',
    alignItems: 'center',
  },
  mainGridHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const unitGridStyles = makeStyles({
  mainGrid: {
    width: 640,
    borderRadius: 10,
    marginBottom: 20,
    boxShadow: '0 5px 25px 0 rgba(0, 0, 0, 0.15)',
  },
  mainGridBody: {
    padding: '10px 30px',
  },
});

export const leasePriceHistorySummaryStyles = makeStyles({
  summaryTitleButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  summaryTotalGrid: {
    backgroundColor: Colors.PORCELAIN_GREY_1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    paddingBottom: 10, // 20 will be set in Sum component
  },
  summaryTitle: {
    fontSize: 13,
    textAlign: 'left',
    paddingLeft: 30,
    marginTop: 20,
    marginBottom: 10,
  },
  textField: {
    backgroundColor: Colors.PORCELAIN_GREY_3,
    fontSize: 14,
    fontWeight: 600,
    width: 580,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottom: `2px solid ${Colors.TOWER_GREY}`,
    marginLeft: 30,
    color: Colors.BLUE_GREY,
  },
  disabledTextField: {
    backgroundColor: Colors.PORCELAIN_GREY_1,
    fontSize: 14,
    fontWeight: 600,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    width: 580,
    borderBottom: `2px solid ${Colors.TOWER_GREY}`,
    marginLeft: 30,
    color: Colors.BLUE_GREY,
  },
});

export const headMenuStyles = makeStyles({
  mainContainer: {
    backgroundColor: Colors.CLASSICAL_WHITE,
    borderRadius: 10,
    width: 640,
    height: 75,
  },
  mainContainerButton: {
    width: '100%',
    height: '100%',
  },
});

export const datesMenuStyles = makeStyles({
  mainGrid: {
    width: 280,
    backgroundColor: 'white',
    padding: 20,
    maxHeight: 500,
    minHeight: 0,
    borderRadius: 10,
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 20,
    boxShadow: '0 5px 25px 0 rgba(0, 0, 0, 0.15)',
  },
  buttonGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  button: {
    width: '100%',
    display: 'flext',
    justifyContent: 'flex-start',
    height: 55,
    alignItems: 'center',
  },
  yearStyle: {
    height: 14,
    margin: '1px 0 3px 12px',
    fontSize: '13px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.08,
    letterSpacing: 1.3,
    color: Colors.TOWER_GREY,
  },
  date: {
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  subStepContainer: {
    width: 240,
    height: 50,
    borderRadius: '3px',
    border: `1px solid ${Colors.PORCELAIN_GREY_3}`,
    marginTop: 5,
    marginLeft: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 10,
    backgroundColor: 'red',
  },
  subStepContainerSelected: {
    backgroundColor: Colors.DODGER_BLUE,
    transition: 'all 0.4s ease-in-out',
    '&:hover': {
      backgroundColor: Colors.ROYAL_BLUE,
    },
  },
  subStepContainerUnSelected: {
    backgroundColor: Colors.CLASSICAL_WHITE,
    transition: 'all 0.4s ease-in-out',
    '&:hover': {
      backgroundColor: Colors.PORCELAIN_GREY_1,
    },
  },
  secondaryTitle: {
    fontSize: '10px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.2,
    textTransform: 'none',
    letterSpacing: '1px',
    position: 'relative',
    textAlign: 'left',
  },
  mainTitle: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 150,
    maxWidth: 150,
    display: 'flex',
    overflow: 'hidden',
    textAlign: 'left',
    alignItems: 'center',
  },
  editIcon: { paddingLeft: 5, marginBottom: 2, fill: Colors.CLASSICAL_BLACK },
  editIconSelected: { paddingLeft: 5, marginBottom: 2, fill: Colors.CLASSICAL_WHITE },
});

export const unitDetailBodyTitleStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  mainGridBody: {
    padding: '10px 30px',
  },
}));
