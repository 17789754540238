import { usePermissions } from 'src/hooks/utils/PermissionsContext';
import AuthorizedTimeFrames from './CommunicationSettings/AuthorizedTimeFrames';
import CommunicationsProfilesSettings from './CommunicationSettings/CommunicationsProfilesSettings';

const CommunicationsSettings = () => {
  const { settingsAutomationRead } = usePermissions();
  return (
    <>
      <CommunicationsProfilesSettings />
      {settingsAutomationRead && <AuthorizedTimeFrames />}
    </>
  );
};

export default CommunicationsSettings;
