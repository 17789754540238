import {
  getDateOfNextInvoice,
  Lease,
  LeaseMonthlyChargesType,
  LeasePriceHistory,
  LeasePriceHistoryDetail,
  LeasePriceHistoryStatus,
  LeasePriceHistoryType,
  UnitLease,
} from '@rentguru/commons-utils';
import { isAfter, isBefore, max, setYear, subMonths } from 'date-fns';
import { get, isEmpty } from 'lodash';

export interface IndexationSummaryFormValues {
  oldTotalRentValue: number;
  newTotalRentValue: number;
  oldTotalFixedChargesValue: number;
  newTotalFixedChargesValue: number;
  oldTotalMonthlyChargesValue: number;
  newTotalMonthlyChargesValue: number;
}
export interface IndexationFormValues extends IndexationSummaryFormValues {
  selectedId: string;
  leasePriceHistories: LeasePriceHistory[];
  applicationDate: Date | string | null;
  errorOcurred: boolean;
}

export enum ClickActionType {
  DRAFT,
  ACCEPT,
  REFUSE,
  NONE,
}

export enum PriceValueType {
  NEW_RENTAL_PRICE = 'newRentalPrice',
  NEW_MONTHLY_CHARGES_PRICE = 'newMonthlyChargesPrice',
  PREVIOUS_RENTAL_PRICE = 'previousRentalPrice',
  PREVIOUS_MONTHLY_CHARGES_PRICE = 'previousMonthlyChargesPrice',
}

export enum StatusValueType {
  RENTAL_STATUS = 'rentalStatus',
  MONTHLY_CHARGES_STATUS = 'monthlyChargesStatus',
}

export enum LeasePriceHistoryPriceTypeName {
  FIXED_PRICE = 'fixedPriceCharges',
  MONTHLY_PROVISIONED = 'monthlyProvisioned',
  RENT = 'rent',
}
export const INDEXED_STATUS = [LeasePriceHistoryStatus.INDEXED, LeasePriceHistoryStatus.INDEXED_WITH_CUSTOM_AMOUNT];

export const getNewRentalOrMonthlyChargeFieldName = (isRent: boolean): PriceValueType => {
  return isRent ? PriceValueType.NEW_RENTAL_PRICE : PriceValueType.NEW_MONTHLY_CHARGES_PRICE;
};

export const getLatestEpbIssueDate = (leasePriceHistoryFromFormikValues?: LeasePriceHistory) => {
  if (isEmpty(leasePriceHistoryFromFormikValues?.amountDetails)) {
    return new Date(0);
  }
  return leasePriceHistoryFromFormikValues?.amountDetails?.reduce((latestDate, amountDetail) => {
    if (amountDetail.epbIssueDate) {
      const epbDate = new Date(amountDetail.epbIssueDate);
      return isAfter(epbDate, latestDate) ? epbDate : latestDate;
    }
    return latestDate;
  }, new Date(0));
};

export const getLeasePriceHistoryStatus = (amountDetailFromValues: LeasePriceHistoryDetail, isRent: boolean) => {
  const statusKey = isRent ? StatusValueType.RENTAL_STATUS : StatusValueType.MONTHLY_CHARGES_STATUS;
  return get(amountDetailFromValues, statusKey) as LeasePriceHistoryStatus;
};

export const getTypeName = (isRent: boolean, unitLease: UnitLease) => {
  if (isRent) return LeasePriceHistoryPriceTypeName.RENT;
  if (unitLease.monthlyChargesType === LeaseMonthlyChargesType.FixedPrice)
    return LeasePriceHistoryPriceTypeName.FIXED_PRICE;
  return LeasePriceHistoryPriceTypeName.MONTHLY_PROVISIONED;
};

export const getMinAdjustmentOrIndexationDate = (
  clickedLeasePriceHistory: LeasePriceHistory,
  applicationDateFromFormikValues: Date,
  lease: Lease
) => {
  const today = new Date();
  const leaseStartDate = lease.startDate;
  const indexationAnniversary = new Date(leaseStartDate!);
  indexationAnniversary.setFullYear(today.getFullYear());

  const latestEpbIssueDate = getLatestEpbIssueDate(clickedLeasePriceHistory);

  const lastAnniversary = setYear(new Date(leaseStartDate!), today.getFullYear());
  if (isBefore(today, lastAnniversary)) {
    lastAnniversary.setFullYear(today.getFullYear() - 1);
  }
  const isIndexation = clickedLeasePriceHistory.type === LeasePriceHistoryType.INDEXATION;
  if (isIndexation) {
    return latestEpbIssueDate
      ? max([subMonths(today, 3), lastAnniversary, latestEpbIssueDate])
      : max([subMonths(today, 3), lastAnniversary]);
  }
  const isTermsChange = clickedLeasePriceHistory.type === LeasePriceHistoryType.TERMS_CHANGE;
  if (isTermsChange) {
    return applicationDateFromFormikValues;
  }

  const nextInvoiceDate = getDateOfNextInvoice(
    new Date(lease!.startDate),
    lease!.lastInvoiceDate,
    lease!.paymentFrequency,
    lease!.paymentInvoicePeriod,
    lease!.paymentInvoicePeriodCustomDay
  );

  if (isBefore(nextInvoiceDate, today)) {
    return today;
  }
  return nextInvoiceDate;
};

export const getApplicationDate = (minApplicationDate: Date, applicationDate: Date, isDraft: boolean) => {
  if (!isDraft) {
    return applicationDate;
  }

  if (isBefore(applicationDate, minApplicationDate)) {
    return minApplicationDate;
  }
  return applicationDate;
};
