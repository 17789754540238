import { Grid } from '@material-ui/core';
import { CustomSimpleDialog, TextDetailEditable } from '@up2rent/ui';
import React from 'react';
import { useIntl } from 'react-intl';

interface AddCommoditiesDialogProps {
  dialogOpen: boolean;
  handleCloseDialog: () => void;
  setNewCommodityName: (value: string) => void;
  newCommodityName: string;
  setNewCommodityDistance: (value: string) => void;
  newCommodityDistance: string;
  resetValues: () => void;
  addNewCommodity: () => void;
}

const AddCommoditiesDialog: React.FC<AddCommoditiesDialogProps> = ({
  dialogOpen,
  handleCloseDialog,
  setNewCommodityName,
  newCommodityName,
  setNewCommodityDistance,
  newCommodityDistance,
  resetValues,
  addNewCommodity,
}) => {
  const { formatMessage } = useIntl();

  return (
    <CustomSimpleDialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      onActionButtonClick={() => {
        if (newCommodityName !== '' && newCommodityDistance !== '' && !isNaN(Number(newCommodityDistance))) {
          addNewCommodity();
          handleCloseDialog();
          resetValues();
        }
      }}
      onSecondaryButtonClick={(e: React.MouseEvent) => {
        if (!(isNaN(Number(newCommodityDistance)) && newCommodityDistance !== '')) {
          e.stopPropagation();
          handleCloseDialog();
          resetValues();
        }
      }}
      actionButtonLabel={formatMessage({ id: 'appButton.done' })}
      title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.addCommodity.add' })}
      dividerBelowTitle
      formatMessage={formatMessage}
    >
      <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <TextDetailEditable
          title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.addCommodity.name' })}
          editMode={true}
          selectable={true}
          name="addComodityName"
          type="text"
          style={{ width: '100%', maxWidth: 'none', marginBottom: 15 }}
          onChangeObserver={(event) => {
            setNewCommodityName(event.target.value);
          }}
          forcedValue={newCommodityName}
        />
      </Grid>
      <Grid style={{ display: 'flex', justifyContent: 'center' }}>
        <TextDetailEditable
          title={formatMessage({ id: 'rentalUnit.editUnit.advertisement.addCommodity.distance' })}
          editMode={true}
          selectable={true}
          name="addComodityDistance"
          endAdornment="km"
          type="text"
          style={{ width: '100%', maxWidth: 'none', marginTop: 0 }}
          onChangeObserver={(event) => {
            setNewCommodityDistance(event.target.value);
          }}
          forcedValue={newCommodityDistance}
          error={Boolean(isNaN(Number(newCommodityDistance)) && newCommodityDistance !== '')}
        />
      </Grid>
    </CustomSimpleDialog>
  );
};

export default AddCommoditiesDialog;
