import { Grid } from '@material-ui/core';
import { UnitInventoryRoomType } from '@rentguru/commons-utils';
import { CustomSimpleDialog, TextDetailEditable } from '@up2rent/ui';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { resolveMainUnitAndSubUnitIndexAndPrefix } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import FormikComboBox from 'src/components/ui/ComboBox/FormikTextComboBox';
import { UnitInventoriesFormValues, UnitStructure } from 'src/components/ui/Forms/FormField/UnitStructureFields';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

export interface AddUnitInventoryFormValues {
  roomName?: string;
  roomType: UnitInventoryRoomType;
}

export const AddUnitInventorySchema = Yup.object()
  .shape({
    roomType: Yup.string().required(),
    roomName: Yup.string().notRequired(),
  })
  .required();

interface AddRoomFormDialogProps {
  open: boolean;
  onClose: () => void;
  unitId: string;
  inside: boolean;
  onlyOther?: boolean;
  fieldName?: string;
}

const initialValues: AddUnitInventoryFormValues = {
  roomType: UnitInventoryRoomType.ENTRANCE,
  roomName: '',
};

const AddRoomFormDialog: React.FC<AddRoomFormDialogProps> = ({
  open,
  unitId,
  inside,
  onClose,
  onlyOther = false,
  fieldName = '',
}) => {
  const { formatMessage } = useIntl();
  const { values: parentValues, setFieldValue: setParentFieldValue } = useFormikContext<UnitInventoriesFormValues>();

  const { prefixFieldName: completeFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(
    parentValues,
    unitId,
    fieldName
  );

  const roomTypesInside = [
    { value: UnitInventoryRoomType.ENTRANCE, primary: formatMessage({ id: 'leaseInventory.roomType.entrance' }) },
    { value: UnitInventoryRoomType.LIVING, primary: formatMessage({ id: 'leaseInventory.roomType.living' }) },
    {
      value: UnitInventoryRoomType.LIVING_WITH_OPEN_KITCHEN,
      primary: formatMessage({ id: 'leaseInventory.roomType.living_with_open_kitchen' }),
    },
    { value: UnitInventoryRoomType.DINING, primary: formatMessage({ id: 'leaseInventory.roomType.dining' }) },
    { value: UnitInventoryRoomType.KITCHEN, primary: formatMessage({ id: 'leaseInventory.roomType.kitchen' }) },
    { value: UnitInventoryRoomType.OFFICE, primary: formatMessage({ id: 'leaseInventory.roomType.office' }) },
    { value: UnitInventoryRoomType.BEDROOM, primary: formatMessage({ id: 'leaseInventory.roomType.bedroom' }) },
    { value: UnitInventoryRoomType.BATHROOM, primary: formatMessage({ id: 'leaseInventory.roomType.bathroom' }) },
    { value: UnitInventoryRoomType.SHOWER, primary: formatMessage({ id: 'leaseInventory.roomType.shower' }) },
    { value: UnitInventoryRoomType.TOILET, primary: formatMessage({ id: 'leaseInventory.roomType.toilet' }) },
    { value: UnitInventoryRoomType.OTHER, primary: formatMessage({ id: 'leaseInventory.roomType.other' }) },
  ];
  const roomTypesOutside = [
    { value: UnitInventoryRoomType.CELLAR, primary: formatMessage({ id: 'leaseInventory.roomType.cellar' }) },
    { value: UnitInventoryRoomType.ATTIC, primary: formatMessage({ id: 'leaseInventory.roomType.attic' }) },
    { value: UnitInventoryRoomType.GARDEN, primary: formatMessage({ id: 'leaseInventory.roomType.garden' }) },
    { value: UnitInventoryRoomType.TERRACE, primary: formatMessage({ id: 'leaseInventory.roomType.terrace' }) },
    { value: UnitInventoryRoomType.PATIO, primary: formatMessage({ id: 'leaseInventory.roomType.patio' }) },
    { value: UnitInventoryRoomType.GARAGE, primary: formatMessage({ id: 'leaseInventory.roomType.garage' }) },
    { value: UnitInventoryRoomType.PARKING, primary: formatMessage({ id: 'leaseInventory.roomType.parking' }) },
    { value: UnitInventoryRoomType.OTHER, primary: formatMessage({ id: 'leaseInventory.roomType.other' }) },
  ];

  const handleCreate = async (
    values: AddUnitInventoryFormValues,
    { setSubmitting, setStatus }: FormikHelpers<AddUnitInventoryFormValues>
  ) => {
    const { roomType, roomName } = values;
    const inputInventory = {
      id: `NEW-${uuidv4()}`,
      roomName: `${roomName}`,
      roomType: onlyOther ? UnitInventoryRoomType.OTHER : roomType,
      unitId,
      inside,
      clientId: '',
      readId: '',
    };
    let hasBeenInserted = false;
    const newUnitStructures = (get(parentValues, completeFieldName, []) as UnitStructure[]).reduce(
      (acc: UnitStructure[], current: UnitStructure) => {
        // Try to add to existing type if it exists
        if (current.roomType === inputInventory.roomType) {
          hasBeenInserted = true;
          current.unitInventories.push(inputInventory);
        }
        acc.push(current);
        return acc;
      },
      [] as UnitStructure[]
    );
    if (!hasBeenInserted) {
      newUnitStructures.push({
        roomType: inputInventory.roomType as UnitInventoryRoomType,
        unitInventories: [inputInventory],
      });
    }
    setParentFieldValue(completeFieldName, newUnitStructures);
    setStatus(true);
    setSubmitting(false);
    onClose();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddUnitInventorySchema}
      onSubmit={handleCreate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ errors, touched, values, isSubmitting, handleSubmit }) => (
        <Form>
          <CustomSimpleDialog
            open={open}
            onClose={onClose}
            onActionButtonClick={handleSubmit}
            actionButtonLabel={formatMessage({
              id: 'bankAccount.addBankAccount.submitLabel',
            })}
            actionButtonLoading={isSubmitting}
            title={formatMessage({ id: 'leaseInventory.addRoom.title' })}
            dividerBelowTitle
            formatMessage={formatMessage}
          >
            <Grid style={{ marginTop: 15 }}>
              {!onlyOther && (
                <FormikComboBox
                  label={formatMessage({
                    id: 'leaseInventory.addRoom.customRoomType',
                  })}
                  name="roomType"
                  inputStyle={{ width: '100%', maxWidth: 'none' }}
                  data={inside ? roomTypesInside : roomTypesOutside}
                  error={Boolean(errors.roomType && touched.roomType)}
                />
              )}
              {(onlyOther || values.roomType === UnitInventoryRoomType.OTHER) && (
                <TextDetailEditable
                  title={formatMessage({ id: 'leaseInventory.addRoom.roomName' })}
                  editMode={true}
                  name="roomName"
                  type="text"
                  style={{ width: '100%', maxWidth: 'none' }}
                  error={Boolean(errors.roomName && touched.roomName)}
                />
              )}
            </Grid>
          </CustomSimpleDialog>
        </Form>
      )}
    </Formik>
  );
};

export default AddRoomFormDialog;
