/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import { InputLabelProps, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors, getSafeValueInArray, getSafeValueInObject, LooseObject } from '@rentguru/commons-utils';
import { FormikProps, useFormikContext } from 'formik';
import isNil from 'lodash/isNil';
import React from 'react';

const useStyles = makeStyles({
  underline: {
    '&$error:before': {
      borderBottomColor: `red`,
    },
    '&$error:after': {
      borderBottomColor: `red !important`,
    },
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  focused: {},
  disabled: { backgroundColor: Colors.PORCELAIN_GREY_1 },
  error: {},
});

export interface TextFieldAreaProps {
  name?: string;
  value?: any;
  className?: string | null;
  title?: string | null;
  idInArray?: string;
  arrayName?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  inputProps?: LooseObject;
  InputProps?: LooseObject;
  InputLabelProps?: Partial<InputLabelProps>;
  error?: boolean;
  onChange?: (value: string) => void;
  EndAdornment?: React.ReactNode;
  [key: string]: any;
}

const TextFieldArea: React.FC<TextFieldAreaProps> = ({
  name,
  value,
  title = null,
  className,
  idInArray,
  arrayName,
  placeholder,
  style = {},
  inputProps = {},
  InputProps = {},
  InputLabelProps = {},
  error = false,
  onChange,
  EndAdornment,
  ...rest
}) => {
  const classes = useStyles();
  const { values, handleChange, handleBlur, setFieldValue }: FormikProps<any> = useFormikContext();
  const textFieldClassName = !isNil(className) ? className : '';
  if (!isNil(name)) {
    return (
      <TextField
        label={title}
        id={name}
        name={name}
        style={{ width: '100%', ...style }}
        InputProps={{
          ...(!isNil(EndAdornment) && {
            endAdornment: EndAdornment,
          }),
          ...InputProps,
          classes: {
            underline: classes.underline,
            root: textFieldClassName,
            focused: classes.focused,
            disabled: rest.disabled ? classes.disabled : '',
          },
        }}
        inputProps={inputProps}
        InputLabelProps={InputLabelProps}
        placeholder={placeholder}
        multiline
        value={
          isNil(arrayName) || isNil(idInArray)
            ? isNil(value)
              ? getSafeValueInObject(values, name)
              : value
            : getSafeValueInArray(values[arrayName], idInArray, name)
        }
        onChange={
          isNil(arrayName) || isNil(idInArray)
            ? handleChange
            : (e) => {
                setFieldValue(
                  arrayName,
                  values[arrayName].map((o: any) => {
                    if (o.id === idInArray) {
                      return { ...o, [name]: e.target.value };
                    }
                    return o;
                  })
                );
              }
        }
        onBlur={handleBlur}
        error={error}
        {...rest}
      />
    );
  }
  if (!isNil(onChange)) {
    return (
      <TextField
        label={title}
        style={{ width: '100%', ...style }}
        InputProps={{
          ...(!isNil(EndAdornment) && {
            endAdornment: EndAdornment,
          }),
          ...InputProps,
          classes: {
            underline: classes.underline,
            root: textFieldClassName,
            focused: classes.focused,
          },
        }}
        inputProps={inputProps}
        InputLabelProps={InputLabelProps}
        multiline
        value={value}
        onChange={(event) => onChange(event.target.value)}
        error={error}
        {...rest}
      />
    );
  }
  return null;
};

export default TextFieldArea;
