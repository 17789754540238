import { Divider, Grid, Typography } from '@material-ui/core';
import { Colors } from '@rentguru/commons-utils';
import React from 'react';
import { calculationStyles } from '../../../leasePriceHistoryStyles';
import { ReactComponent as MultiplyIcon } from '../../../../../../icons/icon-multiply.svg';

interface IndexationFormulaProps {
  multipliedNumber: number | string;
  divider: number | string;
  multiplier: number | string;
}

const IndexationFormula: React.FC<IndexationFormulaProps> = ({ multipliedNumber, multiplier, divider }) => {
  const styles = calculationStyles();

  return (
    <Grid style={{ textAlign: 'center', width: '100%' }}>
      <Grid className={styles.formulaNumeratorGrid}>
        <Typography className={styles.calculationText}>{multipliedNumber}</Typography>
        <MultiplyIcon style={{ width: 32 }} />
        <Typography className={styles.calculationText}>{multiplier}</Typography>
      </Grid>
      <Divider style={{ color: Colors.SEPARATOR_GREY }} />
      <Grid className={styles.formulaDenominatorGrid}>
        <Typography className={styles.calculationText}>{divider}</Typography>
      </Grid>
    </Grid>
  );
};

export default IndexationFormula;
