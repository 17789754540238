import { TableCell, TableRow, useMediaQuery } from '@material-ui/core';
import { Colors, hasLeaseAlreadyBeenEnded } from '@rentguru/commons-utils';
import { StyledCheckbox } from '@up2rent/ui';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { isConsolidatedPaymentRequestReportActivatedForLease, LeaseRowProps } from 'src/components/Leases/Leases';
import { useLeaseActionHistories } from 'src/hooks/LeaseActionHistoriesContext';
import { LeaseExtendedForTable } from 'src/hooks/LeasesContext';
import LeaseBalanceColumn from './LeaseBalanceColumn';
import LeaseDatesColumn from './LeaseDatesColumn';
import LeaseMainUnitColumn from './LeaseMainUnitColumn';
import LeaseTenantsColumn from './LeaseTenantsColumn';
import LeaseTypeColumn from './LeaseTypeColumn';

const LeaseActiveRow: React.FC<LeaseRowProps> = ({
  isSelected,
  handleDetail,
  selectable = false,
  handleSelect,
  ...lease
}) => {
  const { id, name, balance, type, tenants, units, startDate, endDate } = lease;
  const [openTenants, setOpenTenants] = useState<boolean>(false);
  const [openBalance, setOpenBalance] = useState<boolean>(false);
  const [unitListAnchorEl, setUnitListAnchorEl] = useState<HTMLElement | null>(null);
  const closeUnitListPopper = () => {
    setUnitListAnchorEl(null);
  };
  const veryLargeSpace = useMediaQuery('(min-width:1500px)');
  const { getLeaseActionHistoriesOfLease } = useLeaseActionHistories();

  const leaseActionHistories = getLeaseActionHistoriesOfLease(id);
  const leaseHasBeenEnded = hasLeaseAlreadyBeenEnded(leaseActionHistories);

  return (
    <TableRow
      hover
      aria-checked={isSelected}
      tabIndex={-1}
      key={id}
      selected={isSelected}
      style={{ paddingTop: 10 }}
      onClick={() => {
        if (!isNil(handleDetail)) {
          handleDetail(id);
        }
      }}
    >
      {selectable && (
        <TableCell
          component="th"
          scope="row"
          padding="none"
          style={{ paddingLeft: 5 }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <StyledCheckbox
            checked={isSelected}
            disabled={!isConsolidatedPaymentRequestReportActivatedForLease(lease as LeaseExtendedForTable)}
            onClick={(e) => {
              if (handleSelect) handleSelect(e, id);
            }}
          />
        </TableCell>
      )}
      <TableCell
        component="th"
        scope="row"
        padding="normal"
        style={{ fontWeight: 600, color: Colors.CLASSICAL_BLACK, padding: 0, height: 80, paddingLeft: 30 }}
      >
        {name}
      </TableCell>
      <TableCell
        padding="normal"
        style={{ padding: 0, height: 80 }}
        onMouseEnter={(e) => setUnitListAnchorEl(e.currentTarget)}
        onMouseLeave={closeUnitListPopper}
      >
        <LeaseMainUnitColumn
          unitLeases={units ?? []}
          anchorEl={unitListAnchorEl}
          open={!isNil(unitListAnchorEl)}
          handleClose={closeUnitListPopper}
        />
      </TableCell>
      <TableCell padding="none" style={{ padding: 0, height: 80 }}>
        <LeaseDatesColumn startDate={startDate} endDate={endDate} hasLeaseAlreadyBeenEnded={leaseHasBeenEnded} />
      </TableCell>
      <TableCell padding="normal" style={{ padding: 0, height: 80 }}>
        <LeaseTypeColumn type={type} />
      </TableCell>
      <TableCell
        padding="normal"
        style={{ padding: 0, height: 80, ...(!veryLargeSpace ? { maxWidth: 150 } : {}) }}
        onMouseEnter={() => setOpenTenants(true)}
        onMouseLeave={() => setOpenTenants(false)}
      >
        <LeaseTenantsColumn tenants={tenants} startDate={startDate} endDate={endDate} open={openTenants} />
      </TableCell>
      <TableCell
        padding="none"
        style={{
          padding: 0,
          height: 80,
          paddingRight: 30,
        }}
        onMouseEnter={() => setOpenBalance(true)}
        onMouseLeave={() => setOpenBalance(false)}
      >
        <LeaseBalanceColumn leaseId={id} balance={balance} open={openBalance} />
      </TableCell>
    </TableRow>
  );
};

export default LeaseActiveRow;
