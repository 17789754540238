import { Divider, FormControlLabel, FormGroup, Grid, makeStyles, Typography } from '@material-ui/core';
import { ContentHeader, ElevatedPaper, LoaderButton, StyledCheckbox } from '@up2rent/ui';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useClient } from 'src/hooks/ClientContext';
import { useUser } from 'src/hooks/UserContext';
import * as Yup from 'yup';

import AccountDeletionConfirmDialog from './AccountDeletionConfirmDialog';

const AccountDeletionSchema = Yup.object()
  .shape({
    deletionAgreement: Yup.bool().required().default(false),
    preTtlAgreement: Yup.bool().required().default(false),
    postTtlAgreement: Yup.bool().required().default(false),
  })
  .required();

const useStyles = makeStyles({
  container: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  controlLabel: {
    marginBottom: 10,
    textAlign: 'left',
    alignItems: 'start',
  },
  checkboxTypography: {
    marginBottom: 10,
  },
  divider: { marginTop: 20, marginBottom: 20 },
  footer: {
    marginBottom: 20,
    marginRight: 30,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});

interface AccountDeletionFormValues {
  deletionAgreement: boolean;
  preTtlAgreement: boolean;
  postTtlAgreement: boolean;
}

const AccountDeletionForm = () => {
  const { formatMessage } = useIntl();
  const [lastWarningDialogOpen, setLastWarningDialogOpen] = useState<boolean>(false);
  const classes = useStyles();
  const { disableClient } = useClient();
  const { clientId, logout } = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    setLastWarningDialogOpen(true);
  };

  const initialValues: AccountDeletionFormValues = {
    deletionAgreement: false,
    preTtlAgreement: false,
    postTtlAgreement: false,
  };

  const onCloseDialog = () => {
    setLastWarningDialogOpen(false);
  };

  const onConfirmDialog = async () => {
    enqueueSnackbar(  formatMessage({ id: 'settings.deleteAccount.snackbar' }), {
      variant: 'info',
    });
    disableClient(clientId!);
    await logout();
    setLastWarningDialogOpen(false);
  };

  return (
    <ElevatedPaper style={{ margin: 20, maxWidth: 640, paddingBottom: 0 }}>
      <ContentHeader title={formatMessage({ id: 'settings.deleteAccount.title' })} />
      <Divider className={classes.divider} />
      <Formik initialValues={initialValues} validationSchema={AccountDeletionSchema} onSubmit={handleSubmit}>
        {({ values, setFieldValue, status }) => (
          <Form>
            <Grid style={{ paddingLeft: 30, paddingRight: 30 }}>
              <FormGroup>
                <FormControlLabel
                  className={classes.controlLabel}
                  control={
                    <StyledCheckbox onChange={() => setFieldValue('deletionAgreement', !values.deletionAgreement)} />
                  }
                  label={
                    <>
                      <Typography className={classes.checkboxTypography}>
                        {formatMessage({ id: 'settings.deleteAccount.deletionAgreement1' })}
                      </Typography>
                      <Typography className={classes.checkboxTypography}>
                        {formatMessage({ id: 'settings.deleteAccount.deletionAgreement2' })}
                      </Typography>
                    </>
                  }
                />
                <FormControlLabel
                  className={classes.controlLabel}
                  control={
                    <StyledCheckbox onChange={() => setFieldValue('preTtlAgreement', !values.preTtlAgreement)} />
                  }
                  label={
                    <>
                      <Typography className={classes.checkboxTypography}>
                        {formatMessage({ id: 'settings.deleteAccount.preTtlAgreement1' })}
                      </Typography>
                      <Typography className={classes.checkboxTypography}>
                        {formatMessage({ id: 'settings.deleteAccount.preTtlAgreement2' })}
                      </Typography>
                    </>
                  }
                />
                <FormControlLabel
                  className={classes.controlLabel}
                  control={
                    <StyledCheckbox onChange={() => setFieldValue('postTtlAgreement', !values.postTtlAgreement)} />
                  }
                  label={
                    <Typography className={classes.checkboxTypography}>
                      {formatMessage({ id: 'settings.deleteAccount.postTtlAgreement' })}
                    </Typography>
                  }
                />
              </FormGroup>
            </Grid>

            <Divider className={classes.divider} />
            <Grid className={classes.footer}>
              <LoaderButton
                success={status}
                disabled={!values.deletionAgreement || !values.preTtlAgreement || !values.postTtlAgreement}
              >
                {formatMessage({ id: 'delete' })}
              </LoaderButton>
            </Grid>
            <AccountDeletionConfirmDialog
              open={lastWarningDialogOpen}
              onClose={onCloseDialog}
              onActionButtonClick={onConfirmDialog}
            />
          </Form>
        )}
      </Formik>
    </ElevatedPaper>
  );
};

export default AccountDeletionForm;
