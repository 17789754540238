import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { CustomizedSwitch } from '@up2rent/ui';
import { isNil } from 'lodash';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    marginTop: 20,
  },
});

interface FormSubHeaderWithSwitchProps {
  title: string;
  checkedSwitch: boolean;
  setCheckedSwitch: (checked: boolean) => void;
  subHeaderStyle?: React.CSSProperties;
  switchOnText?: string;
  switchOffText?: string;
  onChange?: () => void;
}
const FormSubHeaderWithSwitch: React.FC<FormSubHeaderWithSwitchProps> = ({
  title,
  checkedSwitch,
  setCheckedSwitch,
  subHeaderStyle = {},
  switchOnText,
  switchOffText,
  onChange,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root} style={subHeaderStyle}>
      <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
      <CustomizedSwitch
        checked={checkedSwitch}
        onChange={() => {
          if (!isNil(onChange)) {
            onChange();
          }
          setCheckedSwitch(!checkedSwitch);
        }}
        aria-label="Collapse"
        color="primary"
        disableRipple
        switchOnText={switchOnText}
        switchOffText={switchOffText}
      />
    </div>
  );
};

export default FormSubHeaderWithSwitch;
