import { Grid, InputAdornment, Typography } from '@material-ui/core';
import { TextDetailEditable } from '@up2rent/ui';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { AddLeaseFormValues } from 'src/components/Leases/AddLease/AddLeaseForm';
import { resolveMainUnitAndSubUnitIndexAndPrefix } from 'src/components/Leases/AddLease/useAddEditLeaseUtils';
import HelpIconPopover from '../../HelpIconPopover';

interface RentalPriceProps {
  unitId: string;
}

const RentalPrice: React.FC<RentalPriceProps> = ({ unitId }) => {
  const { formatMessage } = useIntl();
  const { values, errors, touched } = useFormikContext<AddLeaseFormValues>();
  const { prefixFieldName } = resolveMainUnitAndSubUnitIndexAndPrefix(values, unitId);

  return (
    <Grid>
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle2" style={{ fontWeight: 'bold', textAlign: 'left', marginLeft: 30 }}>
          {formatMessage({ id: 'lease.addLease.rent' })}
        </Typography>
        <HelpIconPopover
          helperText={formatMessage({ id: 'lease.addLease.rentInfo' })}
          iconStyle={{ marginLeft: 10 }}
          paperStyle={{ maxWidth: 400 }}
        />
      </Grid>
      <TextDetailEditable
        editMode
        title={formatMessage({ id: `lease.addLease.${values.applyVat ? 'rentalPriceNoVat' : 'rentalPrice'}` })}
        name={`${prefixFieldName}rentalPrice`}
        type="number"
        style={{ width: 580 }}
        error={Boolean(get(errors, `${prefixFieldName}rentalPrice`) && get(touched, `${prefixFieldName}rentalPrice`))}
        min={0}
        endAdornment={<InputAdornment position="end">EUR</InputAdornment>}
        typeNumberNoEndArrow
        noMaxWidth
        data-test="rentalPriceDataTest"
      />
    </Grid>
  );
};

export default RentalPrice;
