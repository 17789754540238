/* eslint-disable no-console */
import { CustomLabel, Language } from '@rentguru/commons-utils';
import { ActionButtonSecondProps, TextDetailEditable, TextDetailEditableProps } from '@up2rent/ui';
import React from 'react';
import { useIntl } from 'react-intl';
import { ImportAndTranslateDialogProps } from 'src/components/ui/Dialogs/TranslateDialog';
import TranslateWrapper from './TranslateWrapper';

export interface TranslateDialogBundle {
  texts: CustomLabel[];
  afterSubmit: (newTexts: CustomLabel[]) => void;
}
interface TranslateTextDetailEditableProps {
  textsToTranslate: TranslateDialogBundle | null;
  languageSelected: Language;
  onLanguageChange: (language: Language) => void;
  onDialogClose: () => void;
  actionButtonProps?: ActionButtonSecondProps;
  translateDialogProps?: Omit<ImportAndTranslateDialogProps, 'onClose' | 'open' | 'texts'>;
  textDetailEditableProps: TextDetailEditableProps;
}

const TranslateTextDetailEditable: React.FC<TranslateTextDetailEditableProps> = ({
  textsToTranslate,
  languageSelected,
  onDialogClose,
  onLanguageChange,
  actionButtonProps,
  translateDialogProps,
  textDetailEditableProps,
}) => {
  const { formatMessage } = useIntl();

  return (
    <TranslateWrapper
      textsToTranslate={textsToTranslate}
      languageSelected={languageSelected}
      translateDialogProps={{
        onDialogClose: onDialogClose,
        titleText: translateDialogProps?.entity ?? formatMessage({ id: 'accounting.charges' }, { value: 1 }),
        helperText: translateDialogProps?.theEntity ?? formatMessage({ id: 'accounting.charge.theCharge' }),
        warningText: translateDialogProps?.ofTheEntity ?? formatMessage({ id: 'accounting.charge.ofTheCharge' }),
      }}
      onLanguageChange={onLanguageChange}
      actionButtonProps={actionButtonProps}
    >
      <TextDetailEditable type="text" {...textDetailEditableProps} />
    </TranslateWrapper>
  );
};

export default TranslateTextDetailEditable;
