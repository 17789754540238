/* eslint-disable @typescript-eslint/no-shadow */
import { Grid, Typography } from '@material-ui/core';
import { getSafeValueInObject } from '@rentguru/commons-utils';
import { CustomizedSwitch } from '@up2rent/ui';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useIntl } from 'react-intl';

interface TitledSwitchProps {
  titleMessageId: string;
  fieldName: string;
  onChange?: (checked: boolean) => void;
  switchOnTextId?: string;
  switchOffTextId?: string;
}

const TitledSwitch: FC<TitledSwitchProps> = ({
  titleMessageId,
  fieldName,
  onChange,
  switchOnTextId,
  switchOffTextId,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, setFieldValue, handleBlur, handleChange } = useFormikContext<any>();
  const { formatMessage } = useIntl();

  const checked = getSafeValueInObject(values, fieldName);
  return (
    <Grid
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
        {formatMessage({ id: titleMessageId })}
      </Typography>
      <Grid style={{ position: 'relative', right: -10 }}>
        <CustomizedSwitch
          checked={checked}
          name={fieldName}
          onChange={(event, checked) => {
            setFieldValue(event.currentTarget.name, event.currentTarget.checked);
            if (onChange) {
              onChange(checked);
            }
          }}
          aria-label="Collapse"
          color="primary"
          disableRipple
          switchOnText={switchOnTextId ? formatMessage({ id: switchOnTextId }) : ''}
          switchOffText={switchOffTextId ? formatMessage({ id: switchOffTextId }) : ''}
          onBlur={handleBlur}
          handleChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};

export default TitledSwitch;
