import { type LooseObject, getAuthorizationTokenForLambdaAuthorizer } from '@rentguru/commons-utils';
import { fetchAuthSession } from 'aws-amplify/auth';
import { isEqual } from 'lodash';
import { FORBIDDEN_OBJECT_KEYS } from './constants';

export const recordWasUpdated = (
  oldRecord: LooseObject,
  newRecord: LooseObject,
  additionalUpdateComparator?: (oldRecord: LooseObject, newRecord: LooseObject) => boolean
) => {
  // Custom comparator => Necessary for complex objects comparisons
  if (additionalUpdateComparator && additionalUpdateComparator(oldRecord, newRecord)) return true;
  for (const [key, value] of Object.entries(newRecord)) {
    // Classic comparator between record and its subscription
    if (key in oldRecord && !FORBIDDEN_OBJECT_KEYS.includes(key) && !isEqual(oldRecord[key], value)) {
      return true;
    }
  }
  return false;
};

export const getFilters = (indexName?: string, indexValue?: string, additionalFilter?: LooseObject) => {
  if (!indexName && !indexValue) {
    return additionalFilter ?? null;
  }

  const filter = { and: [{ [indexName!]: { eq: indexValue } }] };
  if (additionalFilter) {
    filter.and.push(additionalFilter);
  }

  return filter;
};

export const getFilterFieldNameForIndex = (
  indexName:
    | 'byClientId'
    | 'byBuilding'
    | 'byContact'
    | 'byUnit'
    | 'byUnitId'
    | 'byOwner'
    | 'byParent'
    | 'byEmail'
    | 'byLeaseId'
    | 'byForeignKey'
    | 'byCategory'
    | 'byEntity'
) => {
  if (indexName === 'byBuilding') return 'buildingId';
  if (indexName === 'byContact') return 'contactId';
  if (indexName === 'byUnit' || indexName === 'byUnitId') return 'unitId';
  if (indexName === 'byOwner') return 'ownerId';
  if (indexName === 'byParent') return 'parentId';
  if (indexName === 'byEmail') return 'email';
  if (indexName === 'byLeaseId') return 'leaseId';
  if (indexName === 'byForeignKey') return 'foreignKey';
  if (indexName === 'byCategory') return 'categoryId';
  if (indexName === 'byEntity') return 'entity';

  return 'clientId';
};

export const getGraphQlMethodName = (query: string, type: 'mutation' | 'query') => {
  const startingPosition = type === 'query' ? 6 : 9;
  const startPos = query.indexOf(`${type} `) + startingPosition;
  const endPos = query.indexOf('(');
  const queryString = query.substring(startPos, endPos);
  const method = queryString.charAt(0).toLowerCase() + queryString.slice(1);
  return method;
};

export const getLambdaAuthorizerTokenParam = async (lambdaAuthorizer: boolean) => {
  if (!lambdaAuthorizer) {
    return null;
  }

  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  const sessionPayload = idToken?.toString() ?? '';
  const token = getAuthorizationTokenForLambdaAuthorizer(sessionPayload);
  return token;
};

export const cleanMixedDatastoreAndAPISubscription = (subscriptionQuery: string) =>
  subscriptionQuery.replaceAll(/(updatedAt|createdAt)/gm, '');

export const cleanSyncQueries = (syncQuery: string) => syncQuery.replaceAll(/\b\w+\s*{[^{}]*}\s*/gs, '');

export const removeTechnicObject = (query: string) =>
  query.replaceAll(/\btechnic\b\s*{(?:[^{}]*{[^{}]*}[^{}]*)*}/g, '');

export const removeAddressObject = (query: string) => query.replaceAll(/address\s*{[^}]*}/g, '');

// export const removeParametersFromQuery = (query: string, parameter: string) => {
//   const regex = new RegExp(`${parameter}\\s*{(?:[^{}]*|{(?:[^{}]*|{[^{}]*})*})*}`, 'g');
//   return query.replace(regex, '');
// };

export const removeParametersFromQuery = (query: string, parameter: string) =>
  query.replaceAll(new RegExp(String.raw`/${parameter}\s*{[^}]*\{[^}]*\}[^}]*}|${parameter}\s*{[^}]*}/g`, 'g'), '');
